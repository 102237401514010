export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  settingsId: 'demo-config',
  pouchDBName: 'demonrem', // database name
  couchURL: 'https://demodb.gabriel-telemed.org/', // make sure to include the trailing slash '/'
  sharelinksCouchURL: 'https://demodbshared.gabriel-telemed.org/', // make sure to include the trailing slash '/'
  paymentServer: 'https://payment.gabriel.health-e-net.org',  // payment server api
  pacs_servers: [{
    name: 'gabrielpacs', // pacs server name
    apiUrl: '/dicom/dicom-web/'
  }],
  appURL: 'https://demo.gabriel-telemed.org/',
  ES_API: {
    apiUrl: 'https://sumctestes.gabriel-telemed.org/client_list/_search'
  },
  pathToPDFs: 'pdf/', // path to PDFs
  WHO_API: {
    apiUrl: 'https://id.who.int/icd/release/11/2019-04/mms/search',
    tokenEndpoint: '/connect/token',
    grant_type: 'client_credentials',
    scope: 'icdapi_access',
  }
};
