import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppMaterialModule } from '../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';

import { AttachmentModal, SuccessDialogComponent } from './attachment-modal';
import { AttachmentsComponent, DeleteAttachmentDialogComponent, SwitchAttachmentDialogComponent } from './attachments.component';
import { PipesModule } from '../pipes/pipes.module';

import { CropperComponent, CancelCropperDialogComponent } from './cropper/cropper.component';

import { DicomSearchComponent } from './dicom/search/dicom-search.component';
import { DicomUploadComponent } from './dicom/upload/dicom-upload.component';
import { CornerstoneModalComponent } from './dicom/modal/cornerstone-modal';
import { DicomModalComponent } from './dicom/modal/dicom-modal.component';

import { CornerstoneDirective } from '../directives/cornerstone.directive';
import { PdfViewerComponent } from './pdf/pdf-viewer.component';
import { PdfThumbnailComponent } from './pdf/pdf-thumbnail.component';
import { ImagelistEditComponent, DeleteImageDialogComponent, CancelImageDialogComponent,
        CopyMoveImageDialogComponent } from './imagelist/imagelist-edit.component';
import { ImagelistViewComponent } from './imagelist/imagelist-view.component';
import { DicomViewerComponent } from './dicom/viewer/dicom-viewer.component';
import { ValidationModule } from '../validation/validation.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    FlexLayoutModule,
    MomentModule,
    PipesModule,
    RouterModule,
    ValidationModule
  ],
  declarations: [
    AttachmentModal,
    SuccessDialogComponent,
    AttachmentsComponent,
    CornerstoneDirective,
    DeleteAttachmentDialogComponent,
    CornerstoneModalComponent,
    CropperComponent,
    PdfViewerComponent,
    PdfThumbnailComponent,
    ImagelistEditComponent,
    ImagelistViewComponent,
    DicomSearchComponent,
    DicomUploadComponent,
    DicomModalComponent,
    CancelCropperDialogComponent,
    DeleteImageDialogComponent,
    CancelImageDialogComponent,
    CopyMoveImageDialogComponent,
    SwitchAttachmentDialogComponent,
    DicomViewerComponent
  ],
  entryComponents: [
    AttachmentModal,
    SuccessDialogComponent,
    DeleteAttachmentDialogComponent,
    CornerstoneModalComponent,
    CancelCropperDialogComponent,
    DeleteImageDialogComponent,
    CancelImageDialogComponent,
    CopyMoveImageDialogComponent,
    SwitchAttachmentDialogComponent
  ],
  exports: [
    AttachmentModal,
    SuccessDialogComponent,
    AttachmentsComponent,
    CornerstoneModalComponent,
    DicomModalComponent,
    ImagelistViewComponent,
    ImagelistEditComponent,
    DicomViewerComponent
  ]
})
export class AttachmentsModule { }
