import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import * as PDFJS from 'pdfjs-dist/build/pdf';
PDFJS.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.js';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'psoc-pdf-viewer',
    templateUrl: 'pdf-viewer.component.html'
})

export class PdfViewerComponent implements OnInit {
    pdfDoc: any;
    totalPages: number;
    pageNumber: number;
    pages: any[] = [];

    @ViewChild('pdfCanvas') pdfCanvasRef: ElementRef;
    pdfCanvas: any;

    @Output() editPdf: EventEmitter<any> = new EventEmitter<any>();

    @Input() set pdfSrc(pdfSrc: any) { this.loadPdf(pdfSrc); };

    constructor(private snackBar: MatSnackBar, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.pdfCanvas = this.pdfCanvasRef.nativeElement;
    }

    loadPdf(pdfSrc: any) {
        this.setPdfDoc(pdfSrc).then(pdfDoc => {
            this.pdfDoc = pdfDoc;
            this.pageNumber = 1;
            this.totalPages = this.pdfDoc.pdfInfo.numPages;
            this.generateView(this.pageNumber);
            this.generateThumbnails();
        }).catch(e => {
            console.log(e);
            this.snackBar.open('Unable to load pdf document', 'Error', { duration: 6000 });
        });
    }

    setPdfDoc(pdfSrc) {
        return new Promise((resolve, reject) => {
            if (!pdfSrc) { reject('PDF url is null, Please specify pdf url'); }

            PDFJS.getDocument(pdfSrc).then((pdfDoc) => {
                resolve(pdfDoc);
            }, (error) => {
                reject(error);
            });

        });

    }

    generateThumbnails() {
        this.pages = [];

        for (let i = 0; i < this.totalPages; i++) {
            this.pdfDoc.getPage(i)
                .then((thumbnail) => { this.pages.push(thumbnail) }).catch(e => { console.log('Err getting page', e); })
        }
    }


    /**
     * Convert given page to image and send it to cropper
     */
    editPage() {
        const srcUrl = this.pdfCanvas.toDataURL('image/jpeg', 0.2);
        const pdfSrc = this.sanitizer.bypassSecurityTrustUrl(srcUrl);
        this.editPdf.emit(pdfSrc);
    }

    /**
     * Generate page view for selected page
     */
    generateView(pageNumber) {
        this.pdfDoc.getPage(pageNumber).then((page) => {
            const scale = 1;
            const viewport = page.getViewport(scale);
            const context = this.pdfCanvas.getContext('2d');
            this.pdfCanvas.height = viewport.height;
            this.pdfCanvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            page.render(renderContext);
        });
    }

    /**
     * Select previous page of PDF document
     */
    changePagePrevious() {
        if (this.pageNumber !== 1) {
            this.generateView(--this.pageNumber);
        }
    }

    /**
     * Select next page of PDF document
     */
    changePageNext() {
        if (this.pageNumber !== this.totalPages) {
            this.generateView(++this.pageNumber);
        }
    }

    /**
     * Select next page of PDF document
     */
    changePage(pageNumber: number) {
        this.pageNumber = pageNumber + 1; // allow for zero index array
        this.generateView(this.pageNumber);
    }

}
