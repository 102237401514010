import { Component, Input, Output, EventEmitter, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { ObstetricBlockEditComponent } from '../block-edit.component';
import { FoetalLimbsBlock } from '../../../../models/obstetric-narrative';
import { GestationAgeByFemurLength } from '../../../../models/narrative-types';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { ValidationService } from '../../../../validation/validation.service';

@Component({
  selector: 'psoc-foetal-limbs-nurse-edit',
  templateUrl: './foetal-limbs-nurse-edit.component.html'
})

export class FoetalLimbsNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
  _blockData: FoetalLimbsBlock
  @Input() get blockData() { return this._blockData }
  set blockData(blockData: FoetalLimbsBlock) {
    if (blockData) { this._blockData = blockData } else { this._blockData = new FoetalLimbsBlock(); }
    this.blockDataChange.emit(this._blockData);
    this.getAttachmentFilesCount(this._blockData.attachmentIds);
  }
  @Output() blockDataChange: EventEmitter<FoetalLimbsBlock> = new EventEmitter<FoetalLimbsBlock>();

  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];

  dialogRef: MatDialogRef<any>;
  @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService,
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

    this.form = this.fb.group({
      blockStatus: ['', [Validators.required]],
      femurLength: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByFemurLength))],
      nurseNotes: ['']
    });
  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
  }

}
