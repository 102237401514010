import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

import * as rawPouchDB from 'pouchdb';
import * as rawCryptoPouch from 'crypto-pouch';
import * as rawPouchDBAuthentication from 'pouchdb-authentication';

const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
const PouchDBAuthentication: PouchDB.Plugin = (rawPouchDBAuthentication as any).default;
const CryptoPouch: PouchDB.Plugin = (rawCryptoPouch as any);

import { User } from '../models/user';
import { Facility } from '../models/facility';

declare let emit: Function;
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export enum AuthEvent {
  loggedIn,
  loggedOut
}

export interface AuthObserver {
  notifyAuthEvent(event: AuthEvent): void
}

@Injectable()
export class AuthenticationService {
  private remoteAuthDb: any;
  private remoteFacilitiesDb: any;
  private localUserDb: any; // encrypted with username & password, contains location encryption key
  private userModel: User;
  public isRoot: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  userRoles = {
    adminUser: 'Admin',
    mediatorUser: 'Mediator',
    specialistUser: 'Specialist',
  }

  userRoleOptions = [
    this.userRoles.adminUser,
    this.userRoles.mediatorUser,
    this.userRoles.specialistUser
  ];

  private authObservers: Array<AuthObserver> = [];

  constructor(private globals: Globals, private httpClient: HttpClient) {
    this.remoteAuthDb = new PouchDB(environment.couchURL + environment.pouchDBName, { skip_setup: true });
    this.remoteFacilitiesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-facilities', { skip_setup: true });
    PouchDB.plugin(PouchDBAuthentication);
    PouchDB.plugin(CryptoPouch);
  }

  registerAuthObserver(authObserver: AuthObserver): void {
    if (!this.authObservers.includes(authObserver)) {
      this.authObservers.push(authObserver);
    }
  }

  notifyAuthObserver(authEvent: AuthEvent): void {
    this.authObservers.forEach((authObserver: AuthObserver) => authObserver.notifyAuthEvent(authEvent));
  }

  setIsRoot(isRoot: boolean = true) {
    this.isRoot.next(isRoot);
  }

  isLoggedIn(): boolean {
    return this.getUser() !== undefined && this.getUser().username !== '';
  }
  getUser(): User {
    return this.userModel;
  }
  setUser(newUser: User) {
    this.userModel = newUser;
    this.globals.currentUser.next(newUser);
  }
  destroyUser() {
    this.userModel = this.getNullUser();
    this.globals.currentUser.next(this.userModel);
  }
  getNullUser(): User {
    return new User({
      _id: '',
      id: '',
      _rev: '',
      username: '',
      emailAddress: '',
      boardnumber: '',
      qualificatio: '',
      password: '',
      facility: '',
      approved: '',
      userRole: '',
      cadre: '',
      dateAdded: ''
    });
  }

  // return true if user is logged in and is an admin
  isAdmin(): boolean {
    if (!this.isLoggedIn) { return false; }
    return (this.userModel.userRole === this.userRoles.adminUser);
  }

  // login a user
  userLogin(username: string, password: string, firstTimeLogin: boolean = false): Promise<any> {
    // set auth headers for login
    const ajaxOpts = {
      ajax: {
        headers: {
          Authorization: 'Basic ' + window.btoa(username + ':' + password)
        }
      }
    };

    return new Promise((resolve, reject) => {
      this.remoteAuthDb.login(username, password, ajaxOpts).then(response => {

        if (response['ok'] || response['ok'] === true) {
          // check if root couch user
          this.setIsRoot(response['roles'].includes('_admin'));
          this.notifyAuthObserver(AuthEvent.loggedIn);
          resolve(response);
        } else {
          // try and give a useful error on failed login
          let errMsg: String;
          if (response['message'] === 'ETIMEDOUT') {
            errMsg = firstTimeLogin ? 'Online login failed: Connection timed out. Please check your internet connection' :
              'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored';
          } else if (response['message']) {
            errMsg = response['message'];
          } else if (response['status'] === 0) {
            errMsg = firstTimeLogin ? 'Online login failed: Connection failed. Please check your internet connection' :
              'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored'
          }
          reject(errMsg);
        }

      })
        .catch(error => {
          if (error.code === 'ETIMEDOUT') {
            console.log('Connection timed out. Please check your internet connection');
            const errMsg = firstTimeLogin ? 'Online login failed: Connection timed out. Please check your internet connection' :
              'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored';
            reject(errMsg);
          } else {
            reject(error);
          }
        });
    });
  }

  // change users password for a user
  userChangePassword(username: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      console.log('change password for ' + username + ' to ' + password);
      this.remoteAuthDb.changePassword(username, password)
        .then(res => {
          resolve(res);
        }).catch(err => {
          console.log('An error occurred', err);
          reject(err);
        });
    });
  }

  // change the profile of a user
  userChangeProfile(userModel: User): Promise<User> {
    return new Promise((resolve, reject) => {

      this.remoteAuthDb.putUser(userModel.username, {
        metadata: {
          id: userModel.id,
          emailAddress: userModel.emailAddress,
          facility: userModel.facility,
          approved: userModel.approved,
          userRole: userModel.userRole,
          dateAdded: userModel.dateAdded,
          firstName: userModel.firstName,
          lastName: userModel.lastName,
          boardnumber: userModel.boardnumber,
          qualification : userModel.qualification,
          cadre: userModel.cadre,
          phone: userModel.phone
        },
        roles: [this.getRole(userModel)]
      }).then(response => {
        if (response['ok'] || response['ok'] === true) {
          userModel._rev = response.rev;
          resolve(userModel);
        } else {
          console.log('Error updating user: ', response['message']);
          reject(response['message']);
        }
      }).catch(err => {
        console.log('Error updating user: ', err)
        reject(err);
      });

    });
  }

  /**
   * define role based user's userRole
   */
  getRole(user: User) {
    return user.userRole === 'Admin' ? 'admin': 'mediator'
  }

  // register a user
  addUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.remoteAuthDb.signup(user.username, user.password, {
        metadata: {
          id: ((new Date().getTime())),
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          boardnumber: user.boardnumber,
          qualification : user.qualification,
          emailAddress: user.emailAddress,
          facility: user.facility,
          approved: user.approved,
          userRole: user.userRole,
          cadre: user.cadre,
          dateAdded: new Date()
        },
        roles: [this.getRole(user)]
      }).then(response => {
        if (response['ok'] || response['ok'] === true) {
          user._rev = response.rev;
          user._id = response.id;
          resolve(user);
        } else if (response['status'] === 409) {
          reject('This username already exists');
        }
      }).catch(function (error) {
        reject(error);
      });

    });

  }

  // check the session of the current user with couch, tells us who is logged in to couch
  // response.userCtx.name is the current user
  checkUserSession(): Promise<any> {
    return this.remoteAuthDb.getSession().then(function (res) {
      return res;
    }).catch(function (err) {
      return err;
    });
  }

  // if logged in get profile else return error
  // loads user document of current user, stores in local storage as curent user
  loadRemoteUser(username: String): Promise<User> {

    return new Promise((resolve, reject) => {

      this.remoteAuthDb.getUser(username).then(result => {

        if (result['status'] === 404 || result['error'] === 'not found') {
          this.makeGlobalsFalse();
          reject('Sorry, your profile cannot be found');
        } else if (result['error'] || result['error'] === true || result['status'] === 400) {
          this.makeGlobalsFalse();
          reject('Error: Please ensure that your account is valid');
        } else if (result['code'] === 'ETIMEDOUT') {
          reject('Profile loading timed out');
        } else {
          console.log('Loaded remote user: ' + JSON.stringify(result));
          // once the user is logged in,lets store the details in local storage
          const newUser = new User({
            _id: result['_id'],
            id: result['id'],
            _rev: result['_rev'],
            username: result['name'],
            firstName: result['firstName'],
            lastName: result['lastName'],
            emailAddress: result['emailAddress'],
            phone: result['phone'],
            cadre: result['cadre'],
            facility: result['facility'],
            approved: result['approved'],
            userRole: result['userRole'],
            dateAdded: result['dateAdded']
          });
          this.setUser(newUser);
          resolve(newUser);
        }

      }).catch(err => {
        resolve(err);
      });

    });
  }

  // load data for a specific user from remote db
  getDbUser(username: String): Promise<User> {
    return new Promise((resolve, reject) => {

      this.remoteAuthDb.getUser(username).then(result => {

        if (result['status'] === 404 || result['error'] === 'not found') {
          reject('Sorry, your profile cannot be found');
        } else if (result['error'] || result['error'] === true || result['status'] === 400) {
          reject('Error: Please ensure that your account is valid');
        } else if (result['code'] === 'ETIMEDOUT') {
          reject('Profile loading timed out');
        } else {
          console.log('Loaded remote user: ' + JSON.stringify(result));
          const newUser = new User({
            _id: result['_id'],
            id: result['id'],
            _rev: result['_rev'],
            username: result['name'],
            emailAddress: result['emailAddress'],
            facility: result['facility'],
            boardnumber: result['boardnumber'],
            qualification: result['qualification'],
            approved: result['approved'],
            userRole: result['userRole'],
            dateAdded: result['dateAdded'],
            phone: result['phone'],
            cadre: result['cadre'],
            firstname: result['firstname'],
            lastname: result['lastname'],
          });
          resolve(newUser);
        }

      }).catch(err => {
        resolve(err);
      });

    });
  }

  // get client ipAddress function
  getClientIp(): Promise<string> {
    return new Promise((resolve, reject) => {
      // grab public ipaddress
      this.httpClient.get('https://api.ipify.org?format=json')
        .toPromise()
        .then((data: any) => {
          resolve(data.ip);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  // log the user out
  userSignOut(): Promise<User> {
    return this.remoteAuthDb.logout().then(function (res) {
      this.notifyAuthObserver(AuthEvent.loggedOut);
      return res;
    }).catch(function (err) {
      return err;
    });
  }

  // create database for user and save encryption key to it
  // encrypt new database with username and password
  createUserDb(user: User, username: string, password: string, key: string): Promise<String> {
    return new Promise((resolve, reject) => {
      this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
      this.localUserDb.crypto(username + password);

      const userData: any = {
        _id: 'key',
        encryptionKey: key,
        user: user
      };
      this.localUserDb.get('key')
        .then(doc => {
          if (doc._rev) { userData._rev = doc._rev }
        })
        .then(() => {
          this.localUserDb.put(userData)
            .then((response: any) => {
              resolve('success');
            }).catch(error => {
              console.log('Error occurred writing to userdata to localUserDb: ', error);
              reject(error);
            });
        })
        .catch((error) => {
          console.log('Getting key from localUserDb failed, attempting to write user data: ', error);
          console.log(userData);

          this.localUserDb.put(userData)
            .then((response2: any) => {
              resolve('success');
            })
            .catch(error2 => {
              console.log('Error occurred writing to userdata to localUserDb: ', error2);
              reject(error2);
            });
        })
    });
  }

  // check if local user db exists
  checkLocalUserDb(username: string, password: string): Promise<any> {
    this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
    return new Promise((resolve, reject) => {
      this.localUserDb.info()
        .then((details) => {

          if (details.doc_count === 0 && details.update_seq === 0) {
            // local user database not created
            reject('user db does not exist');
            this.localUserDb.destroy();
          } else {
            // local user database exists
            resolve('user db exists');
          }
        })
        .catch(e => {
          // Error
          console.log(e);
        });
    });
  }

  // load encryption key for facility from local user db
  loadFacilityKeyLocal(username: string, password: string) {
    this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
    this.localUserDb.crypto(username + password);
    return new Promise((resolve, reject) => {
      this.localUserDb.get('key')
        .then((result: any) => {
          console.log('loadFacilityKeyLocal: ' + JSON.stringify(result));
          resolve(result);
        }).catch(err => reject(err));
    });
  }

  // load encryption key for facility from remote db
  loadFacilityKeyRemote(facility: string): Promise<String> {
    return new Promise((resolve, reject) => {
      this.remoteFacilitiesDb.find({
        selector: { type: Facility.type, code: facility },
        limit: 1
      }).then((result: any) => {
        resolve(result.docs[0].encryptionKey);
      }).catch(err => reject(err));
    });
  }

  // this is due to getting a user error or internet connection
  // note, this logs out the user
  makeGlobalsFalse() {
    // this applies for the sharelinks
    this.globals.showShareLinkPatientId.emit(+(''));
    this.destroyUser();
  }

  // this function currently runs once on initial load of app
  // succeeds if the user still has valid session with server and valid profile
  refreshLoggedInUserDetails(): Promise<any> {
    return new Promise((resolve, reject) => {

      // check who is logged in
      this.checkUserSession()
        .then(response => {
          // if logged in response will be ok and response['userCtx'].name will be valid name
          if (response['ok'] || response['ok'] === true) {
            this.loadRemoteUser(response['userCtx'].name)
              .then(result => {

                console.log('refreshLoggedInUserDetails: loadRemoteUser result', result);
                if (result['status'] === 404 || result['error'] === 'not found') {
                  this.makeGlobalsFalse();
                  reject('Sorry, Your profile cannot be found');
                } else if (result['error'] || result['error'] === true || result['status'] === 400) {
                  this.makeGlobalsFalse(); // do we need to disable access?
                  reject('Your session with the server has expired, please log in to enable sync');
                } else if (result['code'] === 'ETIMEDOUT') {
                  // timed out, do nothing?
                  resolve('Attempt to check user session timed out.');
                } else {
                  // logged in
                  const currentUser = new User({
                    _id: result['_id'],
                    id: result['id'],
                    _rev: result['_rev'],
                    username: result['name'],
                    emailAddress: result['emailAddress'],
                    facility: result['facility'],
                    approved: result['approved'],
                    userRole: result['userRole'],
                    cadre: result['cadre'],
                    dateAdded: result['dateAdded']
                  });
                  this.setUser(currentUser);

                  console.log('logged in and emitted');
                  resolve('Successfully logged in');
                }

              })
              .catch(error => {
                console.log('refreshLoggedInUserDetails error: ', error);
                this.makeGlobalsFalse();
                reject('Sorry, error getting profile');
              });

          } else {
            this.makeGlobalsFalse();
            reject('Sorry, error getting profile (response not OK)');
          }
        })
        .catch(error => {
          this.makeGlobalsFalse();
          reject('Sorry, Error getting user session. Please log in' + error)
        });

    });
  }

}
