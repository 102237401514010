import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Globals } from '../../services/globals';

import { User } from '../../models/user';
import { Patient } from '../../models/patient';
import { AppSettings } from '../../models/config';
import { Attachment } from '../../models/attachment';
import { ShareLinks } from '../../models/sharelinks';
import { NarrativeEnumTypes } from '../../models/narrative-types';
import { PatientService } from '../../services/patient.service';
import { NarrativeService } from '../../services/narrative.service';
import { AttachmentService } from '../../services/attachment.service';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import { RepositoryService } from '../../services/repository.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Narrative, ReferralStatus } from '../../models/narrative';
import { environment } from '../../../environments/environment';
import { AppSettingsService } from '../../services/settings.service';

@Component({
  selector: 'psoc-shared-links',
  templateUrl: 'shared-links.component.html'
})

export class SharedLinksComponent implements OnInit, AfterViewChecked {
  public patientId: number;
  public sharedLinkUrl: string;
  public sharedLinkPatientId: string;
  patient: Patient;
  currentUser: User;
  narrative;
  narrativeTypes = NarrativeEnumTypes;
  attachments: Attachment[];

  env = environment;
  appSettings: AppSettings;
  loadingAppSettings: boolean;

  // update UI let user know we are loading data
  loadingSharelink: boolean = false;
  loadingAttachments: boolean = false;
  loadingNarrative: boolean = false;
  loadingDatabases: boolean = false;
  publishedSharelinks: ShareLinks[];
  publishedNarratives = new Array<ObstetricNarrative>();

  showEditSpecialistInfo: boolean = false;

  shareLinksLoadingError: boolean = false;
  shareLinks404Error: boolean = false;
  isDataValid: boolean;
  sharelinkUpdating: boolean;

  shareLink: ShareLinks;

  manual = [];

  sonographerForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private globals: Globals,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private repository: RepositoryService,
    private patientService: PatientService,
    private changeDetector: ChangeDetectorRef,
    private narrativeService: NarrativeService,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private appSettingsService: AppSettingsService,
  ) {
    this.sonographerForm = this.fb.group({
      sonographerName: ['', [Validators.required]],
      sonographerAffiliation: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    // load connection to sharelinks dbs
    this.repository.loadSpecialistDb(this.route.snapshot.params['id']);
    this.loadingDatabases = true;
    setTimeout(() => {
      this.checkSpecialistDbs();  // call function to check missing dbs
    }, 5000);

    // call function to load settings file
    this.loadAppSettings();

    // get sharelink data
    this.getShareLinkData();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
  * Load app settings
  */
  loadAppSettings(): void {
    this.loadingAppSettings = true;
    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        this.loadingAppSettings = false;
      }).catch(error => {
        this.loadingAppSettings = false;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /** check if all databases have been loaded */
  checkSpecialistDbs() {
    console.log('Loaded Dbs::', this.repository.loadedSharedDbs.length);
    if (this.repository.loadedSharedDbs.length === 0) { this.loadingDatabases = false }
    if (this.repository.loadedSharedDbs.length < 5) {
      console.log('Error: Failed to load', this.repository.failedSharedDbs.toString(),
        'database, please ensure replication service has run');

      const message = `Error: Failed to load ${this.repository.failedSharedDbs.toString()} database,
        This sharelink may have been expired or disabled Please contact health-e-net`;
      const snackBarRef = this.snackBar.open(message, 'Retry', {});
      snackBarRef.onAction().subscribe(() => this.checkSpecialistDbs());
    } else { this.loadingDatabases = false }
  }



  /** update the sharelink */
  updateShareLink(shareLink: ShareLinks, published: Boolean = false) {
    this.sharelinkUpdating = true;
    this.patientService.updateShareLink(shareLink)
      .then(updatedSharelink => {
        this.shareLink = updatedSharelink;
        this.sharelinkUpdating = false;
        this.showEditSpecialistInfo = false;

        if (published) {
          // add an event of report saved draft
          this.repository.addEvents('obUSGReport', this.shareLink._id, 'published', this.authService.getUser().username,
            this.authService.getUser().ipAddress);
        }
        this.snackBar.open('Successfully saved the report.', 'Success', { duration: 2000 });
      })
      .catch(error => {
        console.log('Error updating sharelink:', error);
        this.snackBar.open('Error updating report.', 'Error', { duration: 2000 });
      });
  }

  /**
   * function to navigate back to editing report
   */
  editReport() {
    this.router.navigate(['/' + this.shareLink._id + '/scans']);
  }

  /**
   * function to publish a report sharelink
   */
  publishReport() {
    this.shareLink.datePublished = new Date();
    this.shareLink.published = true;
    this.updateShareLink(this.shareLink, true);
  }

  /**
   * function to update referral status and status history
   */
  updateNarrative(narr, status: string, note: string) {
    if (narr.referralStatus === ReferralStatus.referred || narr.referralStatus === ReferralStatus.shared) {
      console.log('Updating Narrative Status::');
      narr.referralStatus = status;
      this.narrativeService.updateStatusHistory(narr, status, note);
      this.narrativeService.updateNarrative(narr);
      // add event status changed
      this.repository.addEvents(narr.type, narr._id, 'statusUpdated', this.authService.getUser().username,
        this.authService.getUser().ipAddress);
    } else {
      console.log('Case status: ' + narr.referralStatus + ', case already viewed::');
    }
  }

  /**
   * get patient narrative
   */
  getShareLinkData(): void {
    // first get the share links data
    this.sharedLinkUrl = this.route.snapshot.params['id'];

    this.patientService.getSingleSharedLinkData(this.sharedLinkUrl)
      .then(sharelink => {
        this.shareLink = sharelink;
        this.loadingSharelink = false;

        if (sharelink.intent === NarrativeEnumTypes.obstetric) {
          this.getAllPatientSharelinks(sharelink.patientId);
        }

        // show sonographer details form if there info missing
        if (!sharelink.specialistInfo || !sharelink.specialistInfo.name || !sharelink.specialistInfo.affiliations) {
          this.showEditSpecialistInfo = true;
        }

        // update current global patient id
        this.patient = this.shareLink.patient;
        this.sharedLinkPatientId = String(sharelink.patientId);
        this.patientId = +sharelink.patientId;
        this.globals.showShareLinkPatientId.emit(+(this.sharedLinkPatientId));

        // get the narrative and facility data
        this.loadingNarrative = true;
        this.narrativeService.getSingleNarrative(+(sharelink.narrativeId))
          .then(narrative => {
            // successfully loaded narrative
            console.log('loaded narrrative.id: ' + narrative._id);
            this.narrative = narrative;
            this.loadingNarrative = false;
            // update narrative
            this.updateNarrative(this.narrative, ReferralStatus.caseViewed, 'Case has been accessed');
          }).catch(error => {
            console.log('getSingleNarrative: ', error);
            this.snackBar.open(`Error while loading narrative data for this sharelink,
            Please try again in a few minutes or contact health-e-net if the problem persists`, 'Error');
          });

        if (sharelink.intent !== NarrativeEnumTypes.obstetric) {
          // load attachments for this patient
          this.getPatientAttachments(this.patientId);
        }

        // get user info to set global
        this.getSharelinkUser(sharelink);
      }).catch(error => {
        this.loadingSharelink = false;
        console.log('getSingleSharedLinkData:', error);
        if (error.status === 404) {
          this.shareLinks404Error = true;
          this.snackBar.open(
            `Sharelink data not found, An error occured while loading this sharelink.
            Please try again in a few minutes or contact health-e-net if the problem persists.`,
            'Error');
        } else {
          this.shareLinksLoadingError = true;
          const snackBarRef = this.snackBar.open('Error while loading sharelink data', 'Retry');
          snackBarRef.onAction().subscribe(() => {
            console.log('Retrying');
            this.getShareLinkData()
          });
        }
      })
  }

  // get user details for this sharelink
  getSharelinkUser(sharelink: ShareLinks) {
    // create user from sharelink info
    const user = new User({
      username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
      facility: sharelink.createFacility,
      ipAddress: '',
      userRole: 'specialist'
    })

    // setting the specialist details to the auth
    this.authService.getClientIp()
      .then(ip => user.ipAddress = ip)
      .catch(err => console.log('Error getting ip address: ' + err));

    this.authService.setUser(user);
    this.globals.currentUser.subscribe(u => this.currentUser = u);
    // save an event of sharelink accessed
    // add event case accessed
    this.repository.addEvents(sharelink.type, sharelink._id, 'accessed', this.authService.getUser().username,
      this.authService.getUser().ipAddress);
  }

  // get all attachments
  getPatientAttachments(patientId: number): void {
    this.loadingAttachments = true;

    this.attachmentService.getPatientAttachments(patientId).then(
      attachments => {
        this.attachments = attachments;
        this.loadingAttachments = false;
      }).catch(error => {
        this.loadingAttachments = false;
        console.log('Error getting sharelink attachments', error);
        this.snackBar.open('Error while loading attachments data for this patient', 'Error');
      });
  }

  /**
   * get the patient sharelinks and filter for the published sharelinks
   * @param patientId patient id number
   */
  getAllPatientSharelinks(patientId: number) {
    this.patientService.getPatientShareLinks(patientId)
      .then((sharelinks: ShareLinks[]) => {
        // get the published sharelinks
        this.publishedSharelinks = sharelinks.filter(s => s.published === true);

        this.publishedSharelinks.forEach((sharelink: ShareLinks, index) => {
          // get the narrative
          this.narrativeService.getSingleNarrative(sharelink.narrativeId)
            .then((narrative: any) => {
              this.publishedNarratives[index] = narrative;
            })
            .catch(error => {
              console.log('Error getting narrative:', sharelink.narrativeId, ' Error:', error);
            });
        });

      })
      .catch(error => {
        console.log('Error getting sharelinks for:', patientId, ' Error:', error);
      });
  }
}
