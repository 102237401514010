import {
  Component, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, OnInit, AfterViewInit, ChangeDetectorRef, Inject
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { Patient } from '../../../models/patient';
import { NarrativeEnumTypes } from '../../../models/narrative-types';
import { NarrativeService } from '../../../services/narrative.service';
import {
  ObstetricNarrative, BlocksOrderEnum, NumberOfFoetusBlock, MaternalAnatomyBlock, FoetalLieBlock, FoetalPresentationBlock,
  HeartBlock, PlacentaBlock, AmnioticFluidBlock, FoetalLimbsBlock, FoetalAbdomenBlock, HeadAndSpineBlock, FoetusBlocks, scanningIndication
} from '../../../models/obstetric-narrative';
import { FoetalLieNurseEditComponent } from '../blocks/foetal-lie/foetal-lie-nurse-edit.component';
import { FoetalPresentationNurseEditComponent } from '../blocks/foetal-presentation/foetal-presentation-nurse-edit.component';
import { NumberOfFoetusNurseEditComponent } from '../blocks/number-foetus/number-foetus-nurse-edit.component';
import { UltrasoundRecordNurseEditComponent } from '../blocks/ultrasound-record/ultrasound-record-nurse-edit.component';
import { HeartNurseEditComponent } from '../blocks/foetal-heart/heart-nurse-edit.component';
import { MaternalAnatomyNurseEditComponent } from '../blocks/maternal-anatomy/maternal-anatomy-nurse-edit.component';
import { PlacentaNurseEditComponent } from '../blocks/foetal-placenta/placenta-nurse-edit.component';
import { AmnioticFluidNurseEditComponent } from '../blocks/foetal-amniotic-fluid/amniotic-fluid-nurse-edit.component';
import { FoetalLimbsNurseEditComponent } from '../blocks/foetal-limbs/foetal-limbs-nurse-edit.component'
import { FoetalAbdomenNurseEditComponent } from '../blocks/foetal-abdomen/foetal-abdomen-nurse-edit.component';
import { HeadAndSpineNurseEditComponent } from '../blocks/foetal-head-spine/head-spine-nurse-edit.component';
import { ShareLinks } from '../../../models/sharelinks';
import { PatientService } from '../../../services/patient.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ShareNarrativeDialogComponent } from '../view/obstetric-narrative-view.component';
import { MidSonoService } from '../../../services/midsono.service';
import { Attachment } from '../../../models/attachment';
import { Facility } from '../../../models/facility';

@Component({
  selector: 'psoc-delete-obstetric-narrative',
  template: `
    <h2>Confirm Discard Obstetric Narrative</h2>
    <p>The changes you made will be discarded</p>
    <p><b>Notice:</b> This action cannot be undone</p>
    <p>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Discard</button>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">Cancel</button>
    </p>`
})

export class ConfirmDiscardObstetricNarrativeComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-confirm-load-pending-images',
  template: `
    <h2>There are unsaved images for this <br/>patient in {{blockName}}</h2>
    <p>Would you like to load them?</p>
    <p>
      <button type="button" mat-raised-button color="primary" class="uk-margin-right" (click)="dialogRef.close(true)">Yes</button>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Discard</button>
    </p>`
})

export class ConfirmLoadPendingImagesComponent {
  blockName: string;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.blockName = data.blockName;
  }
}

@Component({
  selector: 'psoc-obstetric-narrative-edit',
  templateUrl: 'obstetric-narrative-edit.component.html'
})

export class ObstetricNarrativeEditComponent implements OnInit, AfterViewInit {
  @Input() patient: Patient;
  _sharelinks: ShareLinks[];
  narrativeSharelinks: ShareLinks[];
  @Input() set sharelinks(sharelinks: ShareLinks[]) {
    this._sharelinks = sharelinks;
    if (this.obstetricNarrative) { this.filterSharelinks(); }
  } get sharelinks(): ShareLinks[] {
    return this._sharelinks;
  };
  @Output() sharelinksChange: EventEmitter<ShareLinks[]> = new EventEmitter<ShareLinks[]>();

  isNew: Boolean = false;
  firstTrimester: Boolean = false;
  narrativeBackup: ObstetricNarrative;
  _obstetricNarrative: ObstetricNarrative;
  @Input() set obstetricNarrative(obstetricNarrative: ObstetricNarrative) {
    this.trimesterChange(obstetricNarrative.scanIndication);

    // check for missing blocks and add them
    if (!obstetricNarrative.sonographyBlocks.numberOfFoetusBlock) {
      obstetricNarrative.sonographyBlocks.numberOfFoetusBlock = new NumberOfFoetusBlock({ numberOfFoetus: 1 });
    }
    if (!obstetricNarrative.sonographyBlocks.maternalAnatomyBlock) {
      obstetricNarrative.sonographyBlocks.maternalAnatomyBlock =
        new MaternalAnatomyBlock();
    }
    if (!Array.isArray(obstetricNarrative.sonographyBlocks.foetuses) || obstetricNarrative.sonographyBlocks.foetuses.length === 0) {
      obstetricNarrative.sonographyBlocks.foetuses = [];
      for (let i = 0; i < obstetricNarrative.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus; i++) {
        obstetricNarrative.sonographyBlocks.foetuses.push(new FoetusBlocks());
      }

      // mark this as a new block so we can show the correct block
      this.isNew = true;
    }
    for (let i = 0; i < obstetricNarrative.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus; i++) {

      if (!obstetricNarrative.sonographyBlocks.foetuses[i].foetalLieBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].foetalLieBlock = new FoetalLieBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].foetalPresentationBlock && !this.firstTrimester) {
        obstetricNarrative.sonographyBlocks.foetuses[i].foetalPresentationBlock = new FoetalPresentationBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].heartBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].heartBlock = new HeartBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].placentaBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].placentaBlock = new PlacentaBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].amnioticFluidBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].amnioticFluidBlock = new AmnioticFluidBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].foetalLimbsBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].foetalLimbsBlock = new FoetalLimbsBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].foetalAbdomenBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].foetalAbdomenBlock = new FoetalAbdomenBlock();
      }
      if (!obstetricNarrative.sonographyBlocks.foetuses[i].headAndSpineBlock) {
        obstetricNarrative.sonographyBlocks.foetuses[i].headAndSpineBlock = new HeadAndSpineBlock();
      }
    }
    this.narrativeBackup = this.narrativeService.deepCopy(obstetricNarrative); // make backup copy of the narrative
    this._obstetricNarrative = obstetricNarrative;
    if (this.sharelinks) { this.filterSharelinks(); }
  }
  get obstetricNarrative(): ObstetricNarrative { return this._obstetricNarrative; }
  @Input() facilities: Facility[];
  @Output() obstetricNarrativeChange: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
  @Output() viewNarrative = new EventEmitter();
  @Output() viewRecord = new EventEmitter();

  // track blocks for next toggle
  @ViewChild(UltrasoundRecordNurseEditComponent) ultraSoundRecordComponent: UltrasoundRecordNurseEditComponent;
  @ViewChild(NumberOfFoetusNurseEditComponent) numberOfFoetusComponent: NumberOfFoetusNurseEditComponent;
  @ViewChildren(FoetalLieNurseEditComponent) foetalLieComponents: QueryList<FoetalLieNurseEditComponent>;
  @ViewChildren(FoetalPresentationNurseEditComponent) foetalPresentationComponents: QueryList<FoetalPresentationNurseEditComponent>;
  @ViewChildren(HeartNurseEditComponent) heartComponents: QueryList<HeartNurseEditComponent>;
  @ViewChildren(PlacentaNurseEditComponent) placentaComponents: QueryList<PlacentaNurseEditComponent>;
  @ViewChildren(AmnioticFluidNurseEditComponent) amnioticFluidComponents: QueryList<AmnioticFluidNurseEditComponent>;
  @ViewChildren(FoetalLimbsNurseEditComponent) foetalLimbs: QueryList<FoetalLimbsNurseEditComponent>;
  @ViewChildren(FoetalAbdomenNurseEditComponent) foetalAbdomenComponents: QueryList<FoetalAbdomenNurseEditComponent>;
  @ViewChildren(HeadAndSpineNurseEditComponent) headAndSpineComponents: QueryList<HeadAndSpineNurseEditComponent>;
  @ViewChild(MaternalAnatomyNurseEditComponent) maternalComponent: MaternalAnatomyNurseEditComponent;


  @Output() formSaved: EventEmitter<Boolean> = new EventEmitter();
  @Input() obstetricEditForm: FormGroup;
  @Output() obstetricEditFormChanged: EventEmitter<FormGroup> = new EventEmitter();
  blocksOrderEnum = BlocksOrderEnum;
  selectedFoetus: number = 0;
  isDataValid: boolean = false;
  hasAttachmentChanges: boolean = false; // keep track of the attachment changes
  scanAttachment: Attachment;
  currentBlock: number;
  currentFoetus: number;
  isSocketConnected: boolean = true;
  attachmentList = new Attachment({ _attachments: {} }); // where we put attachments as they are loaded from middleware

  dialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private narrativeService: NarrativeService,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private midSonoService: MidSonoService
  ) {
    // listen to socket server and deal with results
    midSonoService.messages.subscribe(
      (msg: any) => {
        console.log('Response from websocket: ', msg);
        switch (msg.task) {
          case 'connectDefaultWifiResponse':
            console.log('Connect to default WiFi reponse:', msg.message);
            this.snackBar.open('Connect to default WiFi response from GabrielUSG app ' + msg.message, 'Success', { duration: 6000 });
            break;
        }
      },
      (error) => {
        console.log('Error from socket', error);
        this.isSocketConnected = false;
        this.snackBar.open('Sorry, unable to connect to WirelessUSG. Please run the GabrielUSG app', 'Error', { duration: 6000 });
      },
      () => {
        console.log('completed');
        this.isSocketConnected = false;
      }
    );

  }

  ngOnInit(): void {
    this.formSaved.emit(false);
    if (this.obstetricNarrative.imagesList && this.obstetricNarrative.imagesList.length > 0) { this.showImagelistDialog(); }
    this.ref.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.isNew) {
      this.numberOfFoetusComponent.toggleBlock(true, this.obstetricNarrative.sonographyBlocks.numberOfFoetusBlock.attachmentIds);
      this.ref.detectChanges();
    }

    this.currentBlock = +this.activatedRoute.snapshot.queryParamMap.get('block');
    this.currentFoetus = +this.activatedRoute.snapshot.queryParamMap.get('foetus');
    if (this.currentFoetus) { this.selectedFoetus = this.currentFoetus; }
    if (this.currentBlock) {
      this.nextBlock(this.currentBlock - 1);
      this.ref.detectChanges();
    }
  }

  /**
   * function to validate the saving/discarding of attachments/changes,
   * user has made some changes to an attachment
   * @param changed
   */
  setHasAttachmentChanges(changed: boolean) {
    this.hasAttachmentChanges = changed;
    this.ref.detectChanges();
  }

  /** onbly show sharelinks for current narrative */
  filterSharelinks() {
    this.narrativeSharelinks = this.sharelinks.filter(sharelink => sharelink.narrativeId === this.obstetricNarrative.id);
  }

  /** If child block has updated save the narrative */
  blockUpdated(narrative: ObstetricNarrative) {
    this._obstetricNarrative = narrative;
    this.updateNarrative().then(
      () => { this.ref.detectChanges(); }
    ).catch(err => {
      console.log('Error updating narrative', err);
    });
  }

  /**
   * update the blocks when the trimester changes
   * @param e changed value
   */
  trimesterChange(e: string) {
    if (e === scanningIndication[0]) { this.firstTrimester = true } else { this.firstTrimester = false }
  }

  /**
   * function to toggle next block
   * @param number number of block to open
   */
  nextBlock(currentBlock: BlocksOrderEnum) {
    console.log('current block is', BlocksOrderEnum[currentBlock]);
    this.updateNarrative().then(() => {
      console.log('narrative updated, opening next');
      this.ref.detectChanges();
      this.toggleBlocks(currentBlock);
    }).catch(err => {
      console.log('Error updating narrative', err);
    }); // save the narrative every time next is clicked
  }

  /**
   * Toggle narrative next block by passing current block
   */
  toggleBlocks(currentBlock: number, openImagelist: boolean = false, imagelist: any = null) {
    switch (currentBlock) {
      case this.blocksOrderEnum.ultrasoundRecordBlock:
        console.log('show numberOfFoetusComponent');
        this.numberOfFoetusComponent.toggleBlock(true, this.obstetricNarrative.sonographyBlocks.numberOfFoetusBlock.attachmentIds);
        if (openImagelist) { this.numberOfFoetusComponent.newScan(imagelist) }
        break;
      case this.blocksOrderEnum.numberOfFoetusBlock:
        console.log('show foetalLieComponents', this.selectedFoetus);
        this.foetalLieComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].foetalLieBlock.attachmentIds);
        if (openImagelist) { this.foetalLieComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.foetalLieBlock:
        console.log('show presentationComponents', this.selectedFoetus);
        if (this.firstTrimester) {
          this.heartComponents.toArray()[this.selectedFoetus].toggleBlock(true,
            this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].heartBlock.attachmentIds); return
        }
        this.foetalPresentationComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].foetalPresentationBlock.attachmentIds);
        if (openImagelist) { this.foetalPresentationComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.foetalPresentationBlock:
        console.log('show heartComponents', this.selectedFoetus);
        this.heartComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].heartBlock.attachmentIds);
        if (openImagelist) { this.heartComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.heartBlock:
        console.log('show headAndSpineComponents', this.selectedFoetus);
        this.headAndSpineComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].headAndSpineBlock.attachmentIds);
        if (openImagelist) { this.headAndSpineComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.headAndSpineBlock:
        console.log('show abdomenComponents', this.selectedFoetus);
        this.foetalAbdomenComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].foetalAbdomenBlock.attachmentIds);
        if (openImagelist) { this.foetalAbdomenComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.foetalAbdomenBlock:
        console.log('show foetalLimbs', this.selectedFoetus);
        this.foetalLimbs.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].foetalLimbsBlock.attachmentIds);
        if (openImagelist) { this.foetalLimbs.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.foetalLimbsBlock:
        console.log('show placentaComponents', this.selectedFoetus);
        this.placentaComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].placentaBlock.attachmentIds);
        if (openImagelist) { this.placentaComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.placentaBlock:
        console.log('show amnioticFluidComponents', this.selectedFoetus);
        this.amnioticFluidComponents.toArray()[this.selectedFoetus].toggleBlock(true,
          this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].amnioticFluidBlock.attachmentIds);
        if (openImagelist) { this.amnioticFluidComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        break;
      case this.blocksOrderEnum.amnioticFluidBlock:
        if ((this.selectedFoetus + 1) === this.obstetricNarrative.sonographyBlocks.foetuses.length) {
          console.log('show maternalComponent');
          this.maternalComponent.toggleBlock(true,
            this.obstetricNarrative.sonographyBlocks.maternalAnatomyBlock.attachmentIds);
          if (openImagelist) { this.maternalComponent.newScan(imagelist) }
        } else {
          this.selectedFoetus++;
          console.log('show foetalLieComponents', this.selectedFoetus);
          this.foetalLieComponents.toArray()[this.selectedFoetus].toggleBlock(true,
            this.obstetricNarrative.sonographyBlocks.foetuses[this.selectedFoetus].foetalLieBlock.attachmentIds);
          if (openImagelist) { this.foetalLieComponents.toArray()[this.selectedFoetus].newScan(imagelist) }
        }
        break;
      default:
        break;
    }
  }

  /**
   * function to check for data validity of the narrative
   */
  dataValid() {
    if (!this.ultraSoundRecordComponent) { return }
    let blocksValid = true;
    this.obstetricNarrative.sonographyBlocks.foetuses.forEach((foetus, index) => {
      blocksValid = blocksValid && this.isFoetusValid(index);
    });

    this.isDataValid = this.ultraSoundRecordComponent.blockValid() && this.numberOfFoetusComponent.blockValid() &&
      this.maternalComponent.blockValid() && blocksValid;

    return !this.isDataValid;
  }

  /**
   * function to validate individual foetuses blocks
   * @param foetusIndex foetus index
   */
  isFoetusValid(foetusIndex: number): boolean {
    if (!this.foetalLieComponents) { return }
    let blocksValid =
      this.foetalLieComponents.toArray()[foetusIndex] &&
      this.foetalLieComponents.toArray()[foetusIndex].blockValid() &&
      this.heartComponents.toArray()[foetusIndex] &&
      this.heartComponents.toArray()[foetusIndex].blockValid() &&
      this.placentaComponents.toArray()[foetusIndex] &&
      this.placentaComponents.toArray()[foetusIndex].blockValid() &&
      this.amnioticFluidComponents.toArray()[foetusIndex] &&
      this.amnioticFluidComponents.toArray()[foetusIndex].blockValid() &&
      this.foetalLimbs.toArray()[foetusIndex] &&
      this.foetalLimbs.toArray()[foetusIndex].blockValid() &&
      this.foetalAbdomenComponents.toArray()[foetusIndex] &&
      this.foetalAbdomenComponents.toArray()[foetusIndex].blockValid() &&
      this.headAndSpineComponents.toArray()[foetusIndex] &&
      this.headAndSpineComponents.toArray()[foetusIndex].blockValid();

    if (!this.firstTrimester) {
      blocksValid = blocksValid && this.foetalPresentationComponents.toArray()[foetusIndex] &&
        this.foetalPresentationComponents.toArray()[foetusIndex].blockValid();
    }

    return blocksValid;
  }

  /**
   * function to append child form to parent form
   * @param name name of the form
   * @param form the form to be added to parent form
   */
  initializeForm(name: string, form: FormGroup) {
    this.obstetricEditForm.setControl(name, form);
  }

  /**
   * function to change to view mode
   */
  showNarrativeView(narrative: ObstetricNarrative) {
    // let parent controller know we want to view
    this.viewNarrative.emit(narrative);

    // change the url path
    this.router.navigate(['/patient/' + this.patient._id + '/narrative/' + this.obstetricNarrative._id +
      '/view/' + this.obstetricNarrative.narrativeType]);
  }

  /**
   * function to save narrative and create sharelink
   */
  saveAndShareNarrative() {

    this.updateNarrative().then(narrative => {
      this.formSaved.emit(true);   // mark form as saved

      this.dialogRef = this.dialog.open(ShareNarrativeDialogComponent);
      event.preventDefault();
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (!this.isSocketConnected) {
            this.snackBar.open('Sorry, unable to connect to WirelessUSG service. Please run the GabrielUSG app.',
              'Error', { duration: 6000 });
          }
          this.midSonoService.requestRestoreDefaultConnection();

          // create sharelink
          const shareLink: ShareLinks = new ShareLinks();
          shareLink.urgent = result.urgentCase;
          shareLink.comments = `Auto_${this.obstetricNarrative.narrativeType}`;
          const scanFacilityName = this.facilities.find(f => f.code === this.obstetricNarrative.scanFacility).name;

          this.patientService.saveShareLink(this.obstetricNarrative, this.patient, shareLink, scanFacilityName)
            .then(savedSharelink => {
              // add to sharelinks
              this.sharelinks.push(savedSharelink);
              this.filterSharelinks();
              this.sharelinksChange.emit(this.sharelinks);

              // notify user
              this.snackBar.open('Share link generated and saved.', 'Success', { duration: 6000 });

              // show narrative view
              this.showNarrativeView(narrative);
            }).catch(error => {
              this.snackBar.open('An error occured while creating the sharelink.', 'Error', { duration: 6000 });
            })

        } else {
          // when either cancel or nothing is selected
        }
        this.dialogRef = null;
      });

    }).catch(err => {
      console.log('Error:', err);
      this.snackBar.open('An error occured while saving this narrative.', 'Error', { duration: 6000 });
    });

  }

  /**
   * function to save narrative
   */
  saveAndViewNarrative() {
    this.updateNarrative().then(narrative => {
      this.formSaved.emit(true);  // mark form as saved
      if (!this.isSocketConnected) {
        this.snackBar.open('Sorry, unable to connect to WirelessUSG service. Please run the GabrielUSG app.',
          'Error', { duration: 6000 });
      }

      this.midSonoService.requestRestoreDefaultConnection();
      this.showNarrativeView(narrative);
      this.hasAttachmentChanges = false;
    }).catch(err => {
      console.log('Error:', err);
    });
  }

  /**
   * discard changes made to a narrative
   */
  confirmCancelNarrative() {
    if (this.obstetricEditForm.dirty || this.hasAttachmentChanges) {
      // prompt
      this.dialogRef = this.dialog.open(ConfirmDiscardObstetricNarrativeComponent);
      // if discard - discard and redirect to list of narratives
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (!this.isSocketConnected) {
            this.snackBar.open('Sorry, unable to connect to WirelessUSG service. Please run the GabrielUSG app.',
              'Error', { duration: 6000 });
          }
          this.formSaved.emit(true);
          this.midSonoService.requestRestoreDefaultConnection();
          this.obstetricNarrative = new ObstetricNarrative(this.narrativeBackup);
          this.viewNarrative.emit(this.obstetricNarrative);
          this.router.navigate(['/patient/' + this.patient._id + '/narrative/' + this.obstetricNarrative._id +
            '/view/' + this.obstetricNarrative.narrativeType]);
          this.hasAttachmentChanges = false;
        } else {
          // if cancel - do nothing
        }
      });
    } else {
      // redirect to list of narratives
      this.midSonoService.requestRestoreDefaultConnection();
      this.viewNarrative.emit(this.obstetricNarrative);
      this.router.navigate(['/patient/' + this.patient._id + '/narrative/' + this.obstetricNarrative._id +
        '/view/' + this.obstetricNarrative.narrativeType]);
    }
  }

  /**
   * Function to update sharelink object to db
   */
  updateNarrative(): Promise<ObstetricNarrative> {
    return new Promise((resolve, reject) => {

      // add id if we have a new record
      if (!this.obstetricNarrative._id) {
        const narrId: number = new Date().getTime();
        this.obstetricNarrative._id = String(narrId);
        this.obstetricNarrative.id = narrId;
        this.obstetricNarrative.type = ObstetricNarrative.type;
        this.obstetricNarrative.narrativeType = NarrativeEnumTypes.obstetric;
        this.obstetricNarrative.patientId = +this.patient._id;
      }
      this.obstetricNarrative.completed = this.isDataValid;
      // update the narrative object
      this.narrativeService.updateObstetricNarrative(this.obstetricNarrative)
        .then((narrative: ObstetricNarrative) => {
          // success
          // console.log('updated narrative:', narrative);
          this._obstetricNarrative = narrative;
          // update the patients narratives list
          if (this.narrativeService.patientNarratives) {
            this.narrativeService.patientNarratives = this.narrativeService.patientNarratives.filter(n => n._id !== narrative._id);
            this.narrativeService.patientNarratives.push(this._obstetricNarrative);
          }
          resolve(narrative);
        }).catch(err => {
          // error
          console.log('Error:', err);
          reject(err);
        });

    });
  }

  /**
   * Return calculateGestationAge function
   */
  getGestationAge() {
    return () => this.narrativeService.calculateGestationAge(this.obstetricNarrative, this.obstetricNarrative.sonographyBlocks);
  }

  /** function show dialog to confirm load or discard images for the narrative */
  showImagelistDialog() {
    this.dialogRef = this.dialog.open(ConfirmLoadPendingImagesComponent, {
      data: { blockName: this.obstetricNarrative.imagesList[0].name.split('_')[1] },
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadImages();
      } else {
        this.discardImages();
      }
    });
  }

  /** function call function to load images from gabrielUSG */
  loadImages() {
    let block: string;
    let imagelist: string[];
    let thumbNails: string[];
    this.obstetricNarrative.imagesList.forEach(list => {
      this.selectedFoetus = +list.name.split('_')[0];
      block = list.name.split('_')[1];
      imagelist = list.pathList;
      thumbNails = list.thumbNails;
    });

    this.toggleBlocks(BlocksOrderEnum[block] - 1, true, { images: imagelist, thumbnails: thumbNails });
  }

  /** function discard unsaved images */
  discardImages() {
    if (!this.isSocketConnected) {
      console.log('Websocket is not connected: please run the GabrielUSG app');
      this.snackBar.open('Sorry, unable to connect to WirelessUSG. Please run the GabrielUSG app', 'Error', { duration: 6000 });
      return;
    }

    // list of images to delete
    const delList = this.obstetricNarrative.imagesList[0].pathList.concat(this.obstetricNarrative.imagesList[0].thumbNails);

    // remove list from narrative
    this.obstetricNarrative.imagesList = [];
    this.updateNarrative().then(narrative => {
      this.obstetricNarrative = narrative;
      // send request to delete images from file manager
      delList.forEach(path => {
        this.midSonoService.requestDeleteFile(path);
      });
    }).catch(error => {
      console.log('Error: Updating narrative');
    });
  }
}
