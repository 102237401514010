import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ObstetricNarrative, scanningIndication } from '../../../models/obstetric-narrative';
import { Patient } from '../../../models/patient';
import { Facility } from '../../../models/facility';
import { ShareLinks } from '../../../models/sharelinks';
import { PatientService } from '../../../services/patient.service';
import { UltrasoundRecordNurseViewComponent } from '../blocks/ultrasound-record/ultrasound-record-nurse-view.component';
import { NumberOfFoetusNurseViewComponent } from '../blocks/number-foetus/number-foetus-nurse-view.component';
import { MaternalAnatomyNurseViewComponent } from '../blocks/maternal-anatomy/maternal-anatomy-nurse-view.component';
import { FoetalLieNurseViewComponent } from '../blocks/foetal-lie/foetal-lie-nurse-view.component';
import { FoetalPresentationNurseViewComponent } from '../blocks/foetal-presentation/foetal-presentation-nurse-view.component';
import { HeartNurseViewComponent } from '../blocks/foetal-heart/heart-nurse-view.component';
import { PlacentaNurseViewComponent } from '../blocks/foetal-placenta/placenta-nurse-view.component';
import { AmnioticFluidNurseViewComponent } from '../blocks/foetal-amniotic-fluid/amniotic-fluid-nurse-view.component';
import { FoetalLimbsNurseViewComponent } from '../blocks/foetal-limbs/foetal-limbs-nurse-view.component';
import { FoetalAbdomenNurseViewComponent } from '../blocks/foetal-abdomen/foetal-abdomen-nurse-view.component';
import { HeadAndSpineNurseViewComponent } from '../blocks/foetal-head-spine/head-spine-nurse-view.component';

@Component({
  template: `
  <div>
    <h2>You are about to share this case</h2>
    Are you sure you would like share this case?
    <p>
      <button type="button" mat-raised-button color="primary" [mat-dialog-close]='userData'>Ok</button>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()" class="uk-margin-left">Cancel</button>
    </p>
    <mat-checkbox [(ngModel)]='userData.urgentCase'>This case requires urgent attention</mat-checkbox>
  </div>`
})

export class ShareNarrativeDialogComponent {
  userData = { urgentCase: false }
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-obstetric-narrative-view',
  templateUrl: 'obstetric-narrative-view.component.html'
})

export class ObstetricNarrativeViewComponent implements OnInit {
  firstTrimester: Boolean = false;
  _obstetricNarrative: ObstetricNarrative;
  @Input() set obstetricNarrative(obstetricNarrative: ObstetricNarrative) {
    if (obstetricNarrative.scanIndication === scanningIndication[0]) { this.firstTrimester = true }
    this._obstetricNarrative = obstetricNarrative;
    if (this.sharelinks) { this.filterSharelinks(); }
  } get obstetricNarrative(): ObstetricNarrative {
    return this._obstetricNarrative
  }
  @Input() patient: Patient;
  _sharelinks: ShareLinks[];
  narrativeSharelinks: ShareLinks[];
  narrativePublishedSharelinks: ShareLinks[];
  @Input() set sharelinks(sharelinks: ShareLinks[]) {
    this._sharelinks = sharelinks;
    if (this.obstetricNarrative.id) { this.filterSharelinks(); };
  } get sharelinks(): ShareLinks[] {
    return this._sharelinks;
  };
  @Output() sharelinksChange: EventEmitter<ShareLinks[]> = new EventEmitter<ShareLinks[]>();
  @Output() editNarrative = new EventEmitter();
  @Input() facilities: Facility[];

  @ViewChild(UltrasoundRecordNurseViewComponent) ultraSoundRecordComponent: UltrasoundRecordNurseViewComponent;
  @ViewChild(NumberOfFoetusNurseViewComponent) numberOfFoetusComponent: NumberOfFoetusNurseViewComponent;
  @ViewChildren(FoetalLieNurseViewComponent) foetalLieComponents: QueryList<FoetalLieNurseViewComponent>;
  @ViewChildren(FoetalPresentationNurseViewComponent) foetalPresentationComponents: QueryList<FoetalPresentationNurseViewComponent>;
  @ViewChildren(HeartNurseViewComponent) heartComponents: QueryList<HeartNurseViewComponent>;
  @ViewChildren(PlacentaNurseViewComponent) placentaComponents: QueryList<PlacentaNurseViewComponent>;
  @ViewChildren(AmnioticFluidNurseViewComponent) amnioticFluidComponents: QueryList<AmnioticFluidNurseViewComponent>;
  @ViewChildren(FoetalLimbsNurseViewComponent) foetalLimbs: QueryList<FoetalLimbsNurseViewComponent>;
  @ViewChildren(FoetalAbdomenNurseViewComponent) foetalAbdomenComponents: QueryList<FoetalAbdomenNurseViewComponent>;
  @ViewChildren(HeadAndSpineNurseViewComponent) headAndSpineComponents: QueryList<HeadAndSpineNurseViewComponent>;
  @ViewChild(MaternalAnatomyNurseViewComponent) maternalComponent: MaternalAnatomyNurseViewComponent;

  selectedFoetus: number = 0;
  dialogRef: MatDialogRef<any>;
  isDataValid: boolean = false;

  constructor(
    private router: Router,
    private patientService: PatientService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.ref.detectChanges();
  }

  /**
   * function to filter sharelinks for this narrative
   */
  filterSharelinks() {
    this.narrativeSharelinks = this.sharelinks.filter(sharelink => sharelink.narrativeId === this.obstetricNarrative.id);
    this.narrativePublishedSharelinks = this.narrativeSharelinks.filter(sharelink => sharelink.published === true);
  }

  /**
   * function to check for data validity of the narrative
   */
  dataValid() {
    if (!this.ultraSoundRecordComponent) { return !this.isDataValid }
    this.isDataValid = this.ultraSoundRecordComponent.blockValid() &&
      this.numberOfFoetusComponent.blockValid() &&
      this.foetalLieComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.heartComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.placentaComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.amnioticFluidComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.foetalLimbs.toArray()[this.selectedFoetus].blockValid() &&
      this.foetalAbdomenComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.headAndSpineComponents.toArray()[this.selectedFoetus].blockValid() &&
      this.maternalComponent.blockValid();

    if (!this.firstTrimester) {
      this.isDataValid = this.isDataValid && this.foetalPresentationComponents.toArray()[this.selectedFoetus].blockValid();
    }

    return !this.isDataValid;
  }

  /**
   * function to show the edit form
   */
  showEditNarrative() {
    this.editNarrative.emit();

    // change the url path
    this.router.navigate(['/patient/' + this.patient._id + '/narrative/' + this.obstetricNarrative._id +
      '/edit/' + this.obstetricNarrative.narrativeType]);
  }

  /**
   * function to share case / generrate sharelink
   */
  shareNarrative() {
    this.dialogRef = this.dialog.open(ShareNarrativeDialogComponent);
    event.preventDefault();
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // call function to generate sharelink
        this.createSharelink(result.urgentCase);
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

  /**
   * Function to create a new sharelink
   */
  createSharelink(urgent: boolean) {

    // create sharelink
    const shareLink: ShareLinks = new ShareLinks();
    shareLink.urgent = urgent;
    shareLink.comments = `Auto_${this.obstetricNarrative.narrativeType}`;
    const scanFacilityName = this.facilities.find(f => f.code === this.obstetricNarrative.scanFacility).name;

    this.patientService.saveShareLink(this.obstetricNarrative, this.patient, shareLink, scanFacilityName)
      .then(savedSharelink => {
        console.log('Obstetric Narrative Sharelink Created', savedSharelink)

        // add to sharelinks
        this.sharelinks.push(savedSharelink);
        this.filterSharelinks();
        this.sharelinksChange.emit(this.sharelinks);

        // notify user
        this.snackBar.open('Share link generated and saved.', 'Success', { duration: 6000 });

      }).catch(error => {
        this.snackBar.open('An error occured while creating the sharelink.', 'Error', { duration: 6000 });
      });

  }

  /**
   * function to navigate to view sonograpgher pdf-report page
   * @param sharelinkId sharelink id
   */
  viewSonographerReport(sharelink: ShareLinks) {
    // navigate to view report of this sharelink
    this.router.navigate(['/patient/' + sharelink.patientId + '/narrative/' + sharelink.narrativeId + '/sharelink/'
      + sharelink._id + '/report']);
  }
}
