import { Component, OnInit, Input } from '@angular/core';
import { FoetalLieBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-foetal-lie-nurse-view',
  templateUrl: './foetal-lie-nurse-view.component.html'
})

export class FoetalLieNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  _foetalLie: FoetalLieBlock;
  @Input()
  set foetalLie(foetalLie: FoetalLieBlock) {
    this._foetalLie = foetalLie;
    this.getAttachmentFilesCount(foetalLie.attachmentIds);
  }
  get foetalLie(): FoetalLieBlock {
    return this._foetalLie;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.foetalLie && this.foetalLie.blockStatus) ? true : false;
  }
}
