import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockMaternalAnatomy } from '../../../../models/obstetric-sharelink';
import { MaternalAnatomyBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-maternal-anatomy-sonographer-view',
  templateUrl: 'maternal-anatomy-sonographer-view.component.html'
})

export class MaternalAnatomySonographerViewComponent {
  @Input() nurseData: MaternalAnatomyBlock;
  @Input() sonographerData: SonographerBlockMaternalAnatomy;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   * trigger edit current block
   */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.sonographerData.blockStatus ?  true : false;
  }
}
