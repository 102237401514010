import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Globals } from './globals';
import { User } from '../models/user';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  private currentUser: User;

  isLoggedIn(): boolean {
    return (this.currentUser !== undefined && this.currentUser.username !== undefined && this.currentUser.username !== '')
  }

  constructor(private router: Router, private globals: Globals) {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // check we are logged in
    if (!this.isLoggedIn()) {
      // not logged in so redirect to login page
      console.log('AuthGuard: The user is not logged in and can\'t navigate...');

      const block = route.queryParamMap.get('block');
      const foetus = route.queryParamMap.get('foetus');

      if (block || foetus) {
        const returnUrl = state.url.split('?')[0];
        const queryParams = { block: block, foetus: foetus, returnUrl: returnUrl };
        console.log('return url and params', returnUrl, queryParams);
        this.router.navigate(['/login'], { queryParams: queryParams });
      } else {
        const queryParams = { returnUrl: state.url };
        this.router.navigate(['/login'], { queryParams: queryParams });
      }

      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // check we are logged in
    if (!this.isLoggedIn()) {
      // not logged in so redirect to login page
      console.log('AuthGuard: The user is not logged in and can\'t navigate to child...');

      const block = route.queryParamMap.get('block');
      const foetus = route.queryParamMap.get('foetus');

      if (block || foetus) {
        const returnUrl = state.url.split('?')[0];
        const queryParams = { block: block, foetus: foetus, returnUrl: returnUrl };
        console.log('return url and params', returnUrl, queryParams);
        this.router.navigate(['/login'], { queryParams: queryParams });
      } else {
        const queryParams = { returnUrl: state.url };
        this.router.navigate(['/login'], { queryParams: queryParams });
      }

      return false;
    }
    return true;
  }
}

    // DISABLED: Because kicking out user when logged in offline, but log in online not finished processing
    // https://gitlab.com/HEN/psoc3/issues/139
    // if (navigator.onLine) {
    //   // if online, check we still have session with couch server
    //   this.authService.checkUserSession()
    //     .then(response => {
    //       console.log('checkUserSession: ' + JSON.stringify(response));
    //       if (response.userCtx && response.userCtx.name === null) {
    //         console.log('checkUserSession: Session not found');
    //         this.authService.makeGlobalsFalse();
    //         this.router.navigate(['/login']);
    //       }
    //     })
    //     .catch(error => {
    //       console.log('CheckUserSession error: ' + error);
    //     });
    // }
