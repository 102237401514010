import { Component, OnInit } from '@angular/core';
import { Globals } from '../../services/globals';
import { MatSnackBar } from '@angular/material';
import { NarrativeBlock } from '../../models/narrative-block';
import { NarrativeService } from '../../services/narrative.service';

@Component({
  selector: 'narrative-blocks',
  templateUrl: 'narrative-blocks.component.html'
})

export class NarrativeBlocksComponent implements OnInit {

  blocks: NarrativeBlock[];
  narrativeBlock = new NarrativeBlock();
  isNew: boolean = false;
  constructor(
    private narrativeService: NarrativeService,
    private snackBar: MatSnackBar,
    private globals: Globals
  ) {
    // set current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }

  getNarrativeBlocks(): void {
    this.narrativeService.getNarrativeBlocks().then(blocks => this.blocks = blocks);
  }

  /**
   * save/update narrative block 
   */
  updatedNarrativeBlock() {
    // new narrative block, add id
    if (!this.narrativeBlock._id) {
      this.isNew = true;
      const timestamp = new Date().getTime();
      this.narrativeBlock._id = String(timestamp);
      this.narrativeBlock.id = timestamp;
      this.narrativeBlock.blockid = timestamp;
    }
    // update narrative block
    this.narrativeService.updateNarrativeBlock(this.narrativeBlock).then((narBlock) => {
      this.blocks.push(narBlock);
      this.blocks = this.isNew ? this.blocks.filter(j => j) : this.blocks.filter(h => h !== narBlock);
      this.narrativeBlock = new NarrativeBlock();
      this.isNew ? this.snackBar.open('Narrative block added', 'Success', { duration: 6000 }) : this.snackBar.open('Narrative block Updated.', 'Success', { duration: 6000 });
    }).catch(error => {
      this.isNew ? this.snackBar.open('Error adding narrative block', 'Error', { duration: 6000 }) : this.snackBar.open('Error updating narrative block', 'Error', { duration: 6000 });
    });
  }

  /**
   * edit has been clicked; edit block 
   */
  editNarrativeBlock(narBlock: NarrativeBlock): void {
    this.narrativeBlock = narBlock;
  }

  /**
   * cancel has been clicked; restore block 
   */
  cancelEditing(uneditedNarrativeBlock: NarrativeBlock) {
    if (this.narrativeBlock._id) {
      // remove old one from list
      const index: number = this.blocks.indexOf(this.narrativeBlock);
      if (index > -1) { this.blocks.splice(index, 1); }
      // restore original block
      this.narrativeBlock = uneditedNarrativeBlock;
      // add restored block to blocks list
      this.blocks.push(this.narrativeBlock);
      this.blocks = this.blocks.filter(b => b);
    }
    this.narrativeBlock = new NarrativeBlock();
  }

  deleteNarrativeBlock(narrativeBlock: NarrativeBlock): void {
    this.narrativeService
      .deleteNarrativeBlock(narrativeBlock)
      .then(() => {
        this.blocks = this.blocks.filter(h => h !== narrativeBlock);
        this.snackBar.open('Block Deleted!', 'Success', { duration: 6000 })
      });
  }

  ngOnInit(): void {
    this.getNarrativeBlocks();
  }
}
