import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const sharedLinksRoutes: Routes = [
  //   { path: 'view' },
  //   { path: 'edit' },
  //   { path: 'demographics' },
  //   { path: 'attachments' },
];
@NgModule({
  imports: [
    RouterModule.forChild(sharedLinksRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SharedLinksRoutingModule { }
