import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { NotificationStatus } from '../models/notification-status';

@Injectable()
export class NotificationStatusService {
  private statuses: NotificationStatus[];
  private statusLoaded: boolean = false;

  constructor(private repository: RepositoryService) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === NotificationStatus.type) {
      this.statusLoaded = false;
    }
  }

  /** Return list of statuses, will return cached list if available, or call loadAllPatients */
  getAllNotificationStatus(useCache = true): Promise<NotificationStatus[]> {
    return new Promise((resolve, reject) => {
      if (this.statusLoaded && useCache) {
        console.log('already loaded statuses')
        resolve(this.statuses);
      } else {
        this.loadAllStatuses()
          .then((allStatuses) => {
            this.statuses = allStatuses;
            this.statusLoaded = true;
            resolve(this.statuses);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          })
      }
    });
  }

  /** Returns all statuses from DB */
  loadAllStatuses(): Promise<NotificationStatus[]> {
    return new Promise((resolve, reject) => {

      this.repository.fetchObjects(NotificationStatus.type, Databases.eventsDb)
        .then((result) => {
          const statuses: NotificationStatus[] = result.docs.map((doc: any) => this.mapObjectToPatient(doc));
          resolve(statuses as NotificationStatus[]);
        }).catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });

    });
  }

  private mapObjectToPatient(object: any): NotificationStatus {
    let status: NotificationStatus = new NotificationStatus();
    return status = { ...object };
  }
}
