import { Component, EventEmitter, Output, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FoetusBlocks, ObstetricNarrative } from '../../../models/obstetric-narrative';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { NarrativeService } from '../../../services/narrative.service';
import { AttachmentService } from './../../../services/attachment.service';
import { Attachment } from '../../../models/attachment';
import { Patient } from '../../../models/patient';
import { ObstetricBlockViewComponent } from './block-view.component';
import moment from 'moment';

@Component({
    template: 'edit-block'
})

export class ObstetricBlockEditComponent extends ObstetricBlockViewComponent {
    @Input() foetuses: FoetusBlocks[];
    @Input() patient: Patient;
    @Input() foetusIndex: number;
    @Input() narrativeData: ObstetricNarrative;
    @Input() blockData: any;
    @Output() blockDataChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() firstTrimester: Boolean;
    @Output() narrativeDataChange: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
    @Output() attachmentChanged: EventEmitter<Boolean> = new EventEmitter();
    @Output() foetusChanged: EventEmitter<FoetusBlocks> = new EventEmitter<FoetusBlocks>();
    @Output() openNextBlock: EventEmitter<any> = new EventEmitter;
    @Output() formUpdated: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;
    @Input() getGestationAge: any;

    form: FormGroup;
    showBlock = false;
    showEditor: boolean = false;
    currentAttachment: Attachment;
    blockImageList: string[];
    blockThumbNails: string[];

    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        public narrativeService: NarrativeService,
        public attachmentService: AttachmentService
    ) {
        super(dialog, snackBar, sanitizer, attachmentService);
    }

    /** return the table min and max values */
    returnMinMaxRange(table: any): number[] {
        const tableKeys = Object.keys(table).map(s => parseFloat(s)).sort((a, b) => (a - b));
        return [tableKeys[0] * 10, tableKeys[tableKeys.length - 1] * 10];
    }

    /**
     * function to toggle the current block
     */
    toggleBlock(showBlock: boolean, attachmentIds: string[] = []): void {
        // get attachment files count on open of current block
        if (attachmentIds.length !== 0 || attachmentIds === undefined) {
            this.getAttachmentFilesCount(attachmentIds);
        }

        if (showBlock) {
            this.showBlock = true;
        } else {
            if (this.showBlock) {
                this.showBlock = false;
            } else {
                this.showBlock = true;
            }
        }
    }

    /**
     * function to append block form to main form
     */
    updateForm() {
        this.formUpdated.emit(this.form); // append/update parent form with child form
    }

    /**
    * function to open the next block
    */
    nextBlock() {
        const attachmentIds = []
        this.toggleBlock(false, attachmentIds);
        this.openNextBlock.emit();
    }

    /**
     * Function to save attachments to current block
     */
    saveAttachment(newAttachment: Attachment, blockData: any): Promise<string> {
        return new Promise((resolve, reject) => {
            this.attachmentService.updateAttachment(newAttachment)
                .then((attachment: Attachment) => {
                    resolve(attachment._id);
                })
                .catch(error => {
                    reject('Error: Unable to save new attachment for this block!');
                    console.log('Error: Unable to save new attachment for block', error);
                });
        })
    }

    /** attachment editor has updated the attachment */
    attachmentUpdated(updatedAttachment: Attachment, blockData: any) {
        this.showEditor = false;
        this.currentAttachment = updatedAttachment;
        this.saveAttachment(updatedAttachment, blockData)
            .then((updatedAttachmentId) => {
                let block;
                switch (blockData.blockType) {
                    case 'numberOfFoetus':
                        block = this.narrativeData.sonographyBlocks.numberOfFoetusBlock;
                        break;
                    case 'maternalAnatomy':
                        block = this.narrativeData.sonographyBlocks.maternalAnatomyBlock;
                        break;
                    case 'foetalLie':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].foetalLieBlock;
                        break;
                    case 'foetalPresentation':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].foetalPresentationBlock;
                        break;
                    case 'heart':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].heartBlock;
                        break;
                    case 'placenta':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].placentaBlock;
                        break;
                    case 'amnioticFluid':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].amnioticFluidBlock;
                        break;
                    case 'foetalLimbs':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].foetalLimbsBlock;
                        break;
                    case 'foetalAbdomen':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].foetalAbdomenBlock;
                        break;
                    case 'headAndSpine':
                        block = this.narrativeData.sonographyBlocks.foetuses[this.foetusIndex].headAndSpineBlock;
                        break;
                    default:
                        break;
                }
                // add attachment id to to list for this narrative
                block.attachmentIds = block.attachmentIds ? block.attachmentIds : [];

                if (block.attachmentIds.indexOf(updatedAttachmentId) === -1) {
                    block.attachmentIds.push(updatedAttachmentId);
                }
                this.attachmentFilesCount[updatedAttachmentId] = Object.keys(updatedAttachment._attachments).length;
                if (this.narrativeData.imagesList.length > 0) { this.updateImageList(null); }
            })
            .catch(error => console.log('Error saving attachment', error));
    }

    /**
     * pass the attachmentChanged to the parent component
     * keep track of the attachment changes from the imagelist editor
     * @param changed
     */
    onAttachmentChanged(changed: boolean) {
        this.attachmentChanged.emit(changed);
    }

    /**
     * Function that triggers scanning
     */
    newScan(imageList: any = null) {
        if (imageList) { this.blockImageList = imageList }
        if (this.blockData.attachmentIds.length > 0) { this.openAttachmentEditor(this.blockData.attachmentIds[0]); return; }
        this.currentAttachment = new Attachment({
            dateOnAttachment: new Date(),
            attachmentTitle: 'WirelessUSG Scan',
            attachmentFileName: 'W_USG_Scan'
        });
        this.showEditor = true;
    }

    /** user has cancelled out of editing in the viewer */
    cancelEdit(originalAttachment: Attachment) {
        this.showEditor = false;
        this.currentAttachment = originalAttachment;
    }

    /**
     * Load attachments object and display editor
     */
    openAttachmentEditor(attachmentId: string) {

        this.attachmentService.getAttachment(attachmentId)
            .then(attachment => {
                this.currentAttachment = attachment;
                this.showEditor = true;
            }).catch(error => {
                console.log('Error loading attachment', error);
                const snackBarRef = this.snackBar.open('Error loading attachment object, please try again', 'Retry');
                snackBarRef.onAction().subscribe(() => {
                    this.openAttachmentEditor(attachmentId);
                });
            });

    }

    /**
     * function save images list to the narrative
     */
    updateImageList(imgsList: any) {
        if (!imgsList) {
            this.narrativeData.imagesList = [];
        } else {
            let blockKey;
            const foetus = this.foetusIndex ? this.foetusIndex : 0;
            if (this.blockData.blockType === 'numberOfFoetus' || this.blockData.blockType === 'maternalAnatomy') {
                blockKey = this.blockData.blockType === 'numberOfFoetus' ? 'numberOfFoetusBlock' : 'maternalAnatomyBlock';
            } else {
                const foetusObjects = this.narrativeData.sonographyBlocks.foetuses[foetus];
                blockKey = Object.keys(foetusObjects).find(key => foetusObjects[key] === this.blockData);
            }
            const imageListObj = { name: foetus + '_' + blockKey, pathList: imgsList.images, thumbNails: imgsList.thumbnails }

            this.narrativeData.imagesList = this.narrativeData.imagesList ? this.narrativeData.imagesList : [];
            const existingList = this.narrativeData.imagesList.find(o => o.name === imageListObj.name);
            if (existingList) {
                // existing block image list
                existingList.pathList = existingList.pathList.concat(imageListObj.pathList);
                existingList.thumbNails = existingList.thumbNails.concat(imageListObj.thumbNails);
                existingList.pathList = existingList.pathList.filter((val, i) => existingList.pathList.indexOf(val) === i);
                existingList.thumbNails = existingList.thumbNails.filter((val, i) => existingList.thumbNails.indexOf(val) === i);
            } else {
                // new block image list
                this.narrativeData.imagesList.push(imageListObj);
                this.narrativeData.imagesList = this.narrativeData.imagesList
                    .filter((val, i) => this.narrativeData.imagesList.indexOf(val) === i);
            }
        }

        // save narrative
        this.narrativeService.updateObstetricNarrative(this.narrativeData)
            .then(response => {
                this.narrativeData = response;
                // update the patients narratives list
                if (!imgsList) { this.narrativeDataChange.emit(this.narrativeData); }
                if (this.narrativeService.patientNarratives) {
                    this.narrativeService.patientNarratives = this.narrativeService.patientNarratives.filter(n => n._id !== response._id);
                    this.narrativeService.patientNarratives.push(this.narrativeData);
                }
            }).catch(error => {
                console.log('Images List Update error:', error);
                this.snackBar.open('Error updating narrative, Image list not saved');
            });
    }

    /**
     * function to get the average gestation age
     */
    getGestationAgeByScan() {
        const avgGestationAge = this.getGestationAge();
        if (isNaN(avgGestationAge.avg)) { // check if gestation age is a number
            this.narrativeData.dateOfDeliveryFrom = '';
            this.narrativeData.dateOfDeliveryByScan = null;
            this.narrativeData.actualGestationAge = null;
            return;
        }

        // EDD = (40.0 - avgGestationAge) + new Date();
        const remainingWeeks = (40.0 - avgGestationAge.avg);
        const today = moment(this.narrativeData.dateAdded); // TODO: Using dateAdded may not be efficient
        const edd = today.add(remainingWeeks, 'weeks').format();

        // save edd and gestation age to narrative object
        this.narrativeData.dateOfDeliveryFrom = avgGestationAge.title;
        this.narrativeData.fetalWeight = avgGestationAge.fetalWeight;
        this.narrativeData.dateOfDeliveryByScan = new Date(edd);
        this.narrativeData.actualGestationAge = avgGestationAge.weeks + ' weeks ' + avgGestationAge.days + 'days';
    }

}
