import { Component, OnInit, ViewChild } from '@angular/core';

import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient';
import { ShareLinks } from '../models/sharelinks';
import { MatPaginator } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-records-active-links',
  templateUrl: 'records-active-links.component.html'
})
export class RecordsActiveLinks implements OnInit {

  displayedColumns = ['firstname', 'lastname', 'dateOfBirth', 'sex', 'dateAdded'];
  patientsWithActiveLinksDatabase = new PatientWithActiveLinksDatabase(this.patientService);
  dataSource: PatientsWithActiveLinksDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private patientService: PatientService
  ) { }

  ngOnInit() {
    this.dataSource = new PatientsWithActiveLinksDataSource(this.patientsWithActiveLinksDatabase, this.paginator);
  }
}

export class PatientWithActiveLinksDatabase {
  patient: Patient;
  patients: Patient[];
  allActiveLinks = new Array<ShareLinks>();
  allExpiredLinks = new Array<ShareLinks>();
  patientsIdWithActiveLinks = new Array<Number>();
  patientsWithActiveLinks = new Array<Patient>();
  dataChange: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  get data(): Patient[] { return this.dataChange.value; }
  sharelinks: ShareLinks[];

  constructor(
    private patientService: PatientService
  ) {
    this.getAllShareLinks();
  }

  // get all the saharelinks function
  getAllShareLinks() {
    this.patientService.getAllShareLinks().then((sharelinks: Array<ShareLinks>) => {
      this.sharelinks = sharelinks;

      // loop all sharelinks
      for (let i = 0; i <= this.sharelinks.length; i++) {
        if (this.sharelinks[i]) {
          if (this.sharelinks[i].expired === false) {
            // count active sharelinks
            this.allActiveLinks.push(this.sharelinks[i]);
            // console.log(JSON.stringify(this.allActiveLinks));
          } else {
            // count expired sharelinks
            this.allExpiredLinks.push(this.sharelinks[i]);
          }
        }
      }

      // get list of unique patient
      this.allActiveLinks.forEach((sharelink: ShareLinks) => {
        if (!this.patientsIdWithActiveLinks.includes(sharelink.patientId)) {
          this.patientsIdWithActiveLinks.push(sharelink.patientId);
        };
      });

      // load patient info for each
      this.patientsIdWithActiveLinks.forEach((patientid: number) => {
        this.patientService.getSinglePatient(patientid).then((patient: Patient) => {
          this.patient = patient;

          // push this patient this patient to array
          if (patient !== undefined) {
            this.patientsWithActiveLinks.push(patient)
          }

          this.dataChange.next(this.patientsWithActiveLinks);
        });
      });
    });
  }
}

export class PatientsWithActiveLinksDataSource extends DataSource<Patient> {
  constructor(private _patientsWithActiveLinksDatabase: PatientWithActiveLinksDatabase, private _paginator: MatPaginator) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Patient[]> {
    const displayPatientChange = [
      this._patientsWithActiveLinksDatabase.dataChange,
      this._paginator.page
    ];
    return merge(...displayPatientChange).pipe(map(() => {
      const data = this._patientsWithActiveLinksDatabase.data.slice();

      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() { }
}
