import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { NarrativeBlock } from '../../../models/narrative-block';

@Component({
  selector: 'narrative-block-list',
  templateUrl: 'narrative-block-list.component.html',
})

export class NarrativeBlockListComponent {
  @Input() narrativeBlocks: NarrativeBlock;
  @Output() deleteNarrativeBlock: EventEmitter<NarrativeBlock> = new EventEmitter<NarrativeBlock>();
  @Output() editNarrativeBlock: EventEmitter<NarrativeBlock> = new EventEmitter<NarrativeBlock>();

  editBlock(narrativeBlock: NarrativeBlock) {
    this.editNarrativeBlock.emit(narrativeBlock);
  }

  deleteBlock(narrativeBlock: NarrativeBlock) {
    this.deleteNarrativeBlock.emit(narrativeBlock);
  }

}
