import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Attachment } from '../models/attachment';
import { Patient } from '../models/patient';
import { AttachmentService } from '../services/attachment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import * as moment from 'moment';

import { DicomModalComponent } from './dicom/modal/dicom-modal.component';
import { ImagelistEditComponent } from './imagelist/imagelist-edit.component';

@Component({
  selector: 'psoc-delete-attachment-dialog',
  template: `
      <h2>Confirm Delete Attachment</h2>
      <p>This attachment will be Deleted</p>
      <p><b>Notice:</b> This action cannot be undone</p>
      <p><button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
        <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </p>`
})
export class DeleteAttachmentDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-switch-attachment-dialog-component',
  template: `<h2>Confirm Discard All Changes </h2>
  <p>All Changes to this attachment will be lost.</p>
  <p><b>Notice:</b> This action cannot be undone</p>
  <p><button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
  </p>`
})

export class SwitchAttachmentDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) {}
}

@Component({
  selector: 'psoc-attachments',
  templateUrl: 'attachments.component.html'
})

export class AttachmentsComponent {
  _attachments: Attachment[];
  @Input() set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
    this.sortAttachments();
  }
  get attachments() { return this._attachments; }
  @Input() patient: Patient;
  @Input() narrativeId: string;

  selectedAttachment: Attachment;
  showEdit = false; // toggle display of attachment details edit form
  sharedFacility: string[];
  attachmentSelected = true;
  imgSrc: SafeUrl;
  imgPreviewSrc: SafeUrl;

  @ViewChild(DicomModalComponent) public dicomModal: DicomModalComponent

  @ViewChild(ImagelistEditComponent) public imageListEdit: ImagelistEditComponent

  newAttachmentType: string = 'normal';

  dialogRef: MatDialogRef<any>;

  constructor(
    private snackBar: MatSnackBar,
    private attachmentService: AttachmentService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  // show empty form
  unselectAttachment() {
    this.selectedAttachment = null;
  }

  /**
   * Show edit mode
   */
  editAttachment() {
    this.showEdit = true;
  }

  /**
   * Cancel editing and restore unedited attachment
   */
  cancelEdit(originalAttachment: Attachment) {
    this.attachmentUpdated(originalAttachment);
  }

  // show selected attachment
  selectAttachment(attachment: Attachment): void {
    if (this.showEdit === true && attachment._id !== this.selectedAttachment._id) {
      console.log('>>> att select', this.selectedAttachment)
      this.dialogRef = this.dialog.open(SwitchAttachmentDialogComponent);

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.imageListEdit.cancelEdit()
          this.showEdit = false;
          this.selectedAttachment = attachment;
        }
        this.dialogRef = null;
      })
      return;
    }
    this.selectedAttachment = attachment;

    // set the date with the date format
    this.selectedAttachment.dateOnAttachment = new Date(attachment.dateOnAttachment)

    if (attachment.mediatype === 'application/dicom' && !attachment._attachments) {
      this.imgSrc = true;
      this.imgPreviewSrc = 'assets/images/placeholder-dicom.png'
    } else {
      // load first file
      console.log('here', attachment._id, Object.keys(attachment._attachments)[0]);
      const firstFileName = Object.keys(attachment._attachments)[0];
      this.attachmentService.getAttachmentFile(attachment._id, firstFileName).then((blob) => {
        const url = URL.createObjectURL(blob);
        this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
        this.imgPreviewSrc = this.imgSrc;
        if (attachment._attachments[firstFileName].content_type === 'application/pdf') {
          this.imgPreviewSrc = 'assets/images/placeholder-pdf.png';
          this.imgSrc = url; // pass pdf document to pdf viewer as objectURL
          return;
        }
      }).catch((err) => {
        this.imgSrc = null;
        this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
        console.log(err);
      });
    };
  }

  confirmDeleteAttachment(attachment: Attachment, attachmentIndex: number) {
    this.dialogRef = this.dialog.open(DeleteAttachmentDialogComponent);
    this.selectedAttachment = attachment
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.selectedAttachment = attachment;
        this.attachmentService.deleteAttachment(attachment);
        this._attachments.splice(attachmentIndex, 1);
        this.selectedAttachment = null;
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

  /**
   * Open dialog to view study
   */
  viewDicomDialog(studyUID: string) {
    this.dicomModal.showStudy(studyUID)
  }

  /**
   * Update the attachment metadata (filename, date on attachment)
   */
  updateAttachmentObject(): void {
    this.attachmentService.updateAttachment(this.selectedAttachment)
      .then(attachment => {
        const index: number = this._attachments.indexOf(this.selectedAttachment);
        if (index > -1) { this._attachments.splice(index, 1); }
        this._attachments.push(attachment);
        this.selectAttachment(attachment);
        this.sortAttachments();
        this.snackBar.open('Attachment was updated.', 'Success', { duration: 6000 });
      });
    this.showEdit = false;
  }

  /**
   * Sort attachments by date on attachment field
   */
  sortAttachments() {
    this._attachments.sort(function (a: Attachment, b: Attachment) {
      const ad = moment(a.dateOnAttachment).format('YYYY-MMM-DD');
      const bd = moment(b.dateOnAttachment).format('YYYY-MMM-DD');
      return ad > bd ? -1 : ad < bd ? 1 : 0;
    });
  }

  /**
   * Navigate through attachments in list
   */
  showNext() {
    this.selectAttachment(this._attachments[this.attachments.indexOf(this.selectedAttachment) + 1]);
  }
  showPrevious() {
    this.selectAttachment(this._attachments[this.attachments.indexOf(this.selectedAttachment) - 1]);
  }

  /**
   * Attachment has been updated, remove old from the list and add new
   */
  attachmentUpdated(updatedAttachment: Attachment) {
    const index: number = this._attachments.indexOf(this.selectedAttachment);
    if (index > -1) { this._attachments.splice(index, 1); }
    this.attachmentAdded(updatedAttachment);
  }

  /**
   * Add new attachment to the list
   */
  attachmentAdded(addedAttachment: Attachment) {
    this._attachments.push(addedAttachment);
    this.selectAttachment(addedAttachment);
    this.sortAttachments();
    this.showEdit = false;
  }
}
