import { TransactionApiService } from './../app/services/transaction-api.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppCanDeactivate } from '../app/services/offline-refresh.guard';

// singleton services
import { AuthGuard } from '../app/services/auth.guard';
import { MatSnackBar } from '@angular/material';
import { RepositoryService, AppTypes } from '../app/services/repository.service';
import { AppointmentService } from '../app/services/appointment.service';
import { AuthenticationService } from '../app/services/authentication.service';
import { PatientService } from '../app/services/patient.service';
import { DicomService } from '../app/services/dicom.service';
import { FacilityService } from '../app/services/facility.service';
import { EventsService } from '../app/services/events.service';
import { AttachmentService } from '../app/services/attachment.service';
import { ChatService } from '../app/services/chat.service';
import { NarrativeService } from '../app/services/narrative.service';
import { UsersService } from '../app/services/users.service';
import { AppSettingsService } from '../app/services/settings.service';

@Component({
  selector: 'psoc-app',
  templateUrl: './app-mediator.component.html',
  styleUrls: ['app.component.css'],
  providers: [AuthGuard, AuthenticationService, RepositoryService, MatSnackBar, PatientService, AppointmentService, FacilityService,
    AppSettingsService, EventsService, AttachmentService, ChatService, NarrativeService, UsersService, DicomService, TransactionApiService]
})

export class AppMediatorComponent implements AppCanDeactivate, OnInit {

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (!navigator.onLine) { return false; };
    return true; // TODO: Test what happens on tablet if user simply navigates away from page rather than refreshing
  }

  constructor(private repository: RepositoryService) { }

  ngOnInit() {
    this.repository.setApp(AppTypes.mediatorApp);
  }
}
