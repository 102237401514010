import { Component, OnInit, ViewChild } from '@angular/core';

import { PatientService } from '../services/patient.service';
import { ShareLinks } from '../models/sharelinks';
import { MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';

import { environment } from '../../environments/environment';

import * as rawPouchDB from 'pouchdb';
import * as rawPouchDBFind from 'pouchdb-find';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
const PouchDBFind: PouchDB.Plugin = (rawPouchDBFind as any).default;

@Component({
  selector: 'app-links-diagnostics',
  templateUrl: 'records-links-diagnostics.component.html'
})
export class RecordsLinksDiagnosticsComponent implements OnInit {

  displayedColumns = ['_id', 'patientId', 'expired', 'published', 'hasMainDb', 'hasReplication'];
  activeLinksDatabase = new ActiveLinksDatabase(this.patientService, this.snackBar, this.httpClient);
  dataSource: ActiveLinksDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  appDb: String;
  couchURL: String;
  pathOrigin: string;

  constructor(
    private patientService: PatientService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {
    PouchDB.plugin(PouchDBFind);
    this.appDb = environment.pouchDBName;
    this.couchURL = environment.couchURL;
    this.pathOrigin = window.location.origin;
  }

  ngOnInit() {
    this.dataSource = new ActiveLinksDataSource(this.activeLinksDatabase, this.paginator, this.sort);
    // this.dataSource = new ActiveLinksDataSource(this.activeLinksDatabase, this.sort);
  }
}

export class ActiveLinksDatabase {
  allActiveLinks = new Array<ShareLinks>();
  allExpiredLinks = new Array<ShareLinks>();
  dataChange: BehaviorSubject<ShareLinks[]> = new BehaviorSubject<ShareLinks[]>([]);
  get data(): ShareLinks[] { return this.dataChange.value; }
  public sharelinks: ShareLinks[];
  public replications: any[];

  loadingSharelinks: boolean = false;
  loadingReplications: boolean = false;

  constructor(
    private patientService: PatientService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {
    this.getAllShareLinks();
    this.getReplications();
  }

  // get all the sharelinks function
  getAllShareLinks() {
    this.loadingSharelinks = true;
    this.patientService.getAllShareLinks().then((sharelinks: Array<ShareLinks>) => {
      this.sharelinks = sharelinks.filter(sharelink => sharelink.expired !== true);
      // .filter(sharelink => sharelink.expired !== true); // .slice(0, 10); // todo remove limit put for debugging
      this.dataChange.next(this.sharelinks);

      // loop all sharelinks
      for (let i = 0; i <= this.sharelinks.length; i++) {
        if (this.sharelinks[i]) {
          this.checkRemoteDb(this.sharelinks[i]._id, '', i);
          this.checkRemoteDb(this.sharelinks[i]._id, '-chats', i);
          this.checkRemoteDb(this.sharelinks[i]._id, '-appointments', i);
          this.checkRemoteDb(this.sharelinks[i]._id, '-events', i);
          this.checkRemoteDb(this.sharelinks[i]._id, '-sharelink', i);
        }
        if (i >= this.sharelinks.length) {
          this.dataChange.next(this.sharelinks);
          this.loadingSharelinks = false;
        }
      }

    }).catch(error => {
      const snackBarRef = this.snackBar.open('Error loading sharelinks, please try again.', 'Retry');
      snackBarRef.onAction().subscribe(() => {
        console.log('Retrying');
        this.getAllShareLinks()
      })
      this.loadingSharelinks = false;
    });
  }

  // get all the current replications
  getReplications() {
    this.loadingReplications = true;
    const replicationsUrl: string = environment.couchURL + '_active_tasks';
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Basic ' + btoa('william:wamwalo'));

    this.httpClient.get(replicationsUrl, { headers: headers })
      .toPromise()
      .then(response => {
        this.loadingReplications = false;
        this.replications = response as any[]
        console.log(this.replications.length + ' replications loaded')
      })
      .catch(error => { console.log(error) });
  }

  checkRemoteDb(sharelinkId: string, dbSuffix: string, sharelinkIndex: number) {
    if (this.sharelinks[sharelinkIndex].replicationInfo === undefined) {
      this.sharelinks[sharelinkIndex].replicationInfo = new Array<any>();
    }
    if (this.sharelinks[sharelinkIndex].dbInfo === undefined) { this.sharelinks[sharelinkIndex].dbInfo = new Array<any>(); }

    const dbUrl = environment.sharelinksCouchURL + sharelinkId + dbSuffix;
    const dbName = (dbSuffix !== '' ? dbSuffix : '-main')
    const db = new PouchDB(dbUrl, { skip_setup: true });
    db.info()
      .then(result => {
        if (result.db_name) {
          this.updateSharelinkDbInfo(sharelinkIndex, dbUrl, dbName, true);
          this.updateReplicationInfo(sharelinkIndex,
            this.findReplication(environment.pouchDBName + dbSuffix, sharelinkId + dbSuffix, 'from ' + dbName, sharelinkId));
          this.updateReplicationInfo(sharelinkIndex,
            this.findReplication(sharelinkId + dbSuffix, environment.pouchDBName + dbSuffix, 'to ' + dbName, sharelinkId));
          // this.dataChange.next(this.sharelinks);
        }
        // console.log(result);
      })
      .catch(error => {
        // console.log(error);
        if (error.status === 404) { this.updateSharelinkDbInfo(sharelinkIndex, dbUrl, dbName, false) }
      });
  }

  findReplication(fromDb: string, toDb: string, dbName: string, sharelinkId: string) {
    let foundReplication: any = null;
    this.replications.forEach(replication => {
      // console.log('Searching for ' + fromDb + ' = ' + replication.source + ' to ' + toDb + ' = ' + replication.target + ' = ',
      // console.log('Searching for ' + sharelinkId,  replication.source.indexOf(fromDb) !== -1, replication.target.indexOf(toDb) !== -1);
      if (replication.source.indexOf(fromDb) !== -1) {
        if (replication.target.indexOf(toDb) !== -1) {
          console.log('Yes found while Searching for ' + fromDb + ' to ' + toDb + ' in rep ');
          foundReplication = replication;
        }
      }
    })
    // console.log('NOT found while Searching for ' + fromDb + ' to ' + toDb + ' in rep ');
    foundReplication = foundReplication === null ? { source: fromDb, target: toDb } : foundReplication;
    foundReplication.name = dbName;
    return foundReplication;
  }

  updateReplicationInfo(sharelinkIndex: number, replication: any) {
    this.sharelinks[sharelinkIndex].replicationInfo.push({ replication: replication });
  }

  updateSharelinkDbInfo(sharelinkIndex: number, dbUrl: string, dbName: string, dbExists: boolean) {
    this.sharelinks[sharelinkIndex].dbInfo.push({ dbName: dbName, dbExists: dbExists, dbUrl: dbUrl });
  }

}

export class ActiveLinksDataSource extends DataSource<ShareLinks> {
  constructor(private _activeLinksDatabase: ActiveLinksDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    // constructor(private _activeLinksDatabase: ActiveLinksDatabase, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ShareLinks[]> {
    const displaySharelinksChange = [
      this._activeLinksDatabase.dataChange,
      this._paginator.page,
      this._sort.sortChange
    ];
    return merge(...displaySharelinksChange).pipe(map(() => {
      // const data = this._activeLinksDatabase.data.slice();
      const data = this.getSortedData();

      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    }));
  }

  getSortedData(): ShareLinks[] {
    const data = this._activeLinksDatabase.data.slice();
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case '_id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'patientId': [propertyA, propertyB] = [a.patientId, b.patientId]; break;
        case 'expired': [propertyA, propertyB] = [a.expired.toString(), b.expired.toString()]; break;
        case 'published': [propertyA, propertyB] = [a.published.toString(), b.published.toString()]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

  disconnect() { }
}
