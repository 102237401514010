import { Component, OnInit, Input } from '@angular/core';
import { FoetalPresentationBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-foetal-presentation-nurse-view',
  templateUrl: './foetal-presentation-nurse-view.component.html'
})

export class FoetalPresentationNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  _foetalPresentation: FoetalPresentationBlock;
  @Input()
  set foetalPresentation(foetalPresentation: FoetalPresentationBlock) {
    this._foetalPresentation = foetalPresentation;
    this.getAttachmentFilesCount(foetalPresentation.attachmentIds);
  }
  get foetalPresentation(): FoetalPresentationBlock {
    return this._foetalPresentation;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.foetalPresentation && this.foetalPresentation.blockStatus) ? true : false;
  }
}
