/*
 * Example use
 *		Multidimensional Array Sort on single column: *ngFor="#todo of todoService.todos | orderByDate : ['-status']"
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderByDate', pure: false })
export class OrderByDate implements PipeTransform {

  static _orderByComparator(a: Date, b: Date): number {

    if (a < b) { return -1 };
    if (a > b) { return 1 };
    return 0; // equal each other

  }

  transform(input: any, [config = '+']): any {

    if (!Array.isArray(input)) { return input };

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';

      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? input.sort() : input.sort().reverse();
      } else {
        const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
          ? propertyToCheck.substr(1)
          : propertyToCheck;

        return input.sort(function (a: any, b: any) {
          return !desc
            ? OrderByDate._orderByComparator(a[property], b[property])
            : -OrderByDate._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      return input.sort(function (a: any, b: any) {
        for (let i: number = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const property = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
            ? config[i].substr(1)
            : config[i];

          const comparison = !desc
            ? OrderByDate._orderByComparator(a[property], b[property])
            : -OrderByDate._orderByComparator(a[property], b[property]);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) { return comparison; }
        }

        return 0; // equal each other
      });
    }
  }
}
