import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NumberOfFoetusBlock } from '../../../../models/obstetric-narrative';
import { SonographerBlockNumberOfFoetus } from '../../../../models/obstetric-sharelink';

@Component({
  selector: 'psoc-number-foetuses-sonographer-view',
  templateUrl: 'number-foetuses-sonographer-view.component.html'
})

export class NumberFoetusesSonographerViewComponent {
  @Input() nurseData: NumberOfFoetusBlock;
  @Input() sonographerData: SonographerBlockNumberOfFoetus;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() { }

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return (this.sonographerData.numberOfFoetus > 0 || this.sonographerData.cantConfirmNumberOfFoetus)
      && this.sonographerData.blockStatus ? true : false;
  }

}
