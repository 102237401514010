import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AmnioticFluidBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SonographerBlockAmnioticFluid } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-amniotic-fluid-sonographer-edit',
  templateUrl: 'amniotic-fluid-sonographer-edit.component.html'
})

export class AmnioticFluidSonographerEditComponent extends SonographerBlockEditComponent{
  @Input() firstTrimester: Boolean;
  @Input() nurseData: AmnioticFluidBlock;
  @Input() blockData: SonographerBlockAmnioticFluid;
  @Input() selectedFoetus: number;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;

  quadrant1Valid = false;
  quadrant2Valid = false;
  quadrant3Valid = false;
  quadrant4Valid = false;

  blockStatusOptions = ['Present', 'Absent', 'Not Clear'];
  form: FormGroup;

  constructor(
    private _fb: FormBuilder
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: [''],
      quadrant1: [''],
      quadrant2: [''],
      quadrant3: [''],
      quadrant4: [''],
      amnioticFluidIndex: [''],
      sonographerNotes: [''],
      cantConfirmQuadrant1: [''],
      cantConfirmQuadrant2: [''],
      cantConfirmQuadrant3: [''],
      cantConfirmQuadrant4: ['']
    })
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    let blockValid = this.blockData.blockStatus  ?  true : false;

    if (!this.firstTrimester) {
      this.quadrant1Valid = this.blockData.quadrant1 > 0 || this.blockData.cantConfirmQuadrant1;
      this.quadrant2Valid = this.blockData.quadrant2 > 0 || this.blockData.cantConfirmQuadrant2;
      this.quadrant3Valid = this.blockData.quadrant3 > 0 || this.blockData.cantConfirmQuadrant3;
      this.quadrant4Valid = this.blockData.quadrant4 > 0 || this.blockData.cantConfirmQuadrant4;

      blockValid = blockValid && (this.quadrant1Valid && this.quadrant2Valid && this.quadrant3Valid && this.quadrant4Valid);
    }

    return blockValid;
  }

  /**
   * Calculate the AFI conc=vert into cm
   */
  updateAFI() {
    this.blockData.amnioticFluidIndex = (this.blockData.quadrant1 + this.blockData.quadrant2 +
      this.blockData.quadrant3 + this.blockData.quadrant4) / 10;
  }

}
