import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SonographerBlockRecommendations } from '../../../../models/obstetric-sharelink';
import { BlocksOrderEnum } from '../../../../models/obstetric-narrative';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-recommendations-sonographer-edit',
  templateUrl: 'recommendations-sonographer-edit.component.html',
  styleUrls: ['recommendations-sonographer.component.css']
})

export class RecommendationsSonographerEditComponent extends SonographerBlockEditComponent {

  _blockData: SonographerBlockRecommendations;
  @Input() set blockData(blockData: SonographerBlockRecommendations) {
    this._blockData = blockData;
    this.rating = blockData.reviewRate;
  } get blockData(): SonographerBlockRecommendations { return this._blockData }

  form: FormGroup;
  blockStatusOptions = ['Routine follow-up', 'Repeat scan', 'Refer for Sonographer scan', 'Refer for Obstetrician review'];
  @Output() saveSonoDraft: EventEmitter<any> = new EventEmitter;
  @Output() previewSonoSharelink: EventEmitter<any> = new EventEmitter;
  @Input() shareLink;
  rating: number;

  constructor(private _fb: FormBuilder) {
    super();
    this.form = this._fb.group({
      blockStatus: [''],
      overallImpression: [''],
      reviewComment: ['']
    })
  }

  /**
   * function to trigger save of sharelink
   */
  updateSharelink() {
    this.saveSonoDraft.emit();
  }

  /**
   * function to trigger preview of sharelink
   */
  previewSharelink() {
    this.previewSonoSharelink.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.blockData.recommendations && this.blockData.overallImpression ?  true : false;
  }

  /**
   * update the review rate on click of a star
   * @param rate star number selected
   */
  rateTo(rate: number) {
    this.blockData.reviewRate = rate;
  }
}
