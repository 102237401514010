import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Covid19Screening } from '../../models/narrative';
import { AppSettings } from '../../models/config';

@Component({
  selector: 'psoc-covid-19-screening-edit',
  templateUrl: 'covid19-screening-edit.component.html'
})

export class Covid19ScreeningEditComponent implements OnInit {
  @Input() covid19EditForm: FormGroup;
  @Input() covid19Screening: Covid19Screening;
  showCovid19Block: boolean;
  @Input() set appSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    if (appSettings) {
      this.showCovid19Block = appSettings.narrativeSettings.openCovid19Block;
    }
  } get appSettings(): AppSettings { return this._appSettings }

  _appSettings: AppSettings;

  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.addFormControls();
  }

  /** add controls to the form */
  addFormControls() {
    this.covid19EditForm = this._fb.group({
      // symptoms
      covid19_fever: [''],
      covid19_dryCough: [''],
      covid19_difficultyBreathing: [''],
      covid19_anosmia: [''],
      covid19_sneezingRunningNose: [''],
      covid19_symptoms_other: [''],

      // social history
      covid19_contact_with_person_with_symptoms: [''],
      covid19_contact_with_confirmed_case: [''],
      covid19_recent_travel_outside_kenya: [''],
      covid19_participation_in_social_gatherings: [''],
      covid19_history_other: [''],


      // risk factors
      covid19_hypertension: [''],
      covid19_diabetes: [''],
      covid19_coronary_heart_disease: [''],
      covid19_cerebrovascular_disease: [''],
      covid19_respiratory_disease: [''],
      covid19_obesity: [''],
      covid19_hiv_immunosuppression: [''],
      covid19_pregnancy: [''],
      covid19_riskfactors_other: ['']
    })
  }

  /** open and close the covid block */
  toggleCovid19Block(showCovidBlock: boolean): void {
    if (showCovidBlock) {
      this.showCovid19Block = true;
    } else {
      if (this.showCovid19Block) {
        this.showCovid19Block = false;
      } else {
        this.showCovid19Block = true;
      }
    }
  }
}
