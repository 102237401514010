import { NarrativeBlock } from './narrative-block';
import { Attachment } from './attachment';
import { PouchObject } from './pouch-object';
import { NarrativeInterface, NarrativeEnumTypes, ReferralEnumTypes } from './narrative-types';

export class Narrative implements PouchObject, NarrativeInterface {
  public static type: string = 'narrative';
  public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'patientId', 'presumptiveDiagnosis', 'caseSummary', 'dateAdded',
    'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'sharedFacility', 'referral', 'consultation',
    'referralType', 'blocks', 'updates', 'referralStatus', 'questions', 'statusChangeHistory', 'covid19Screening'];
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  patientId: number;
  presumptiveDiagnosis: string;
  caseSummary: string;
  dateAdded: Date;
  dateUpdated: Date;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;
  sharedFacility: string[];
  referral: Referrals;
  consultation: Consultation;
  referralStatus: ReferralStatus;
  referralType: string;
  statusChangeHistory?: StatusChangeHistory[];

  covid19Screening: Covid19Screening;

  narrativeType: NarrativeEnumTypes = NarrativeEnumTypes.consultation;
  transactionStatus: string;

  blocks: NarrativeBlock[];
  updates?: Updates[];
  questions: Question[];

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  getSummary(patientDob: Date, patientCreateDate: Date): string {
    let summary = '';
    if (this.referralType) {
      if (this.referralType !== 'Consultation') {
        summary = this.referralType + ' Referral';
      }
      if (this.referralType === 'Consultation') {
        const age = new Date(patientCreateDate).getFullYear() - new Date(patientDob).getFullYear();
        if (age < 12) {
          summary = 'Paediatrics ' + this.referralType;
        } else {
          summary = this.referralType;
        }
      }
    } else {
      summary = this.narrativeType;
    }

    return summary;
  }

}

export class StatusChangeHistory {
  status: string;
  statusNote: string;
  dateUpdated: Date;
}

export enum ReferralStatus {
  referred = 'Referred',
  shared = 'Shared',
  caseViewed = 'Case viewed',
  contacted = 'Contacted',
  admitted = 'Admitted',
  dischargedFromOP = 'Discharged from OP',
  dischargedWithDS = 'Discharged with DS',
  dischargedWithoutDS = 'Discharged without DS',
  reported = 'Reported',
  // mentor(helpdesk)
  notArrived = 'Did not arrive',
  arrived = 'Arrived',
  referredOn = 'Referred on',
  noActionTaken = 'No action taken'
}

export class Question {
  question: string;
  answer: string;
  dateAdded: Date;
}

export class Covid19Screening {
  // symptoms
  covid19_fever: string;
  covid19_dryCough: string;
  covid19_difficultyBreathing: string;
  covid19_anosmia: string;
  covid19_sneezingRunningNose: string;
  covid19_symptoms_other: string;

  // social history
  covid19_contact_with_person_with_symptoms: string;
  covid19_contact_with_confirmed_case: string;
  covid19_recent_travel_outside_kenya: string;
  covid19_participation_in_social_gatherings: string;
  covid19_history_other: string;

  // risk factors
  covid19_hypertension: string;
  covid19_diabetes: string;
  covid19_coronary_heart_disease: string;
  covid19_cerebrovascular_disease: string;
  covid19_respiratory_disease: string;
  covid19_obesity: string;
  covid19_hiv_immunosuppression: string;
  covid19_pregnancy: string;
  covid19_riskfactors_other: string;
}

export class Consultation {
  // reasons section
  hivStatus: string;
  reasons_cardiovascular: string;
  reasons_ent: string;
  reasons_burns: string;
  reasons_centralNervousSystem: string;
  reasons_ophthalmological: string;
  reasons_rta: string;
  reasons_gastrointestinal: string;
  reasons_respiratory: string;
  reasons_stings: string;
  reasons_genitourinary: string;
  reasons_musculoskeletal: string;
  reasons_poisoning: string;
  reasons_endocrine: string;
  reasons_renal: string;
  reasons_cancer: string;
  reasons_diseases: string;
  reasons_sepsis: string;
  reasons_body: string;
  reasons_aids: string;
  reasons_other_details: string;

  // test section
  test_bp_100mm: string;
  test_hp: string;
  test_fever: string;
  test_bp_160mm: string;
  test_sugar_4mmol: string;
  test_seizures: string;
  test_sugar_11mmol: string;
  test_distress: string;
  test_diarrhoea: string;
  test_bleeding: string;
  test_vomiting: string;
  test_other: string;
}
export class Referrals {
  // type: string;
  // facility: string;
  // maternity or paediatrics
  referral_urgency: string;
  referral_transportation: string;

  // referral_details: string[];
  referral_details_facility_contacted: string;
  referral_details_letter_filled: string;
  referral_details_contacts_confirmed: string;
  referral_details_consent_share_info: string;

  referral_from: string;
  referral_facility: string;
  referral_specialist: string;

  // maternity referral
  referral_mat_hivStatus: string;
  referral_mat_vdrl: string;

  referral_mat_status: string;

  referral_mat_pregnancyStage: string;
  referral_mat_detailsG: string;
  referral_mat_detailsP: string;
  referral_mat_bloodGrp: string;
  referral_mat_rhFactor: string;

  // referral_mat_reasons: string[];
  referral_mat_reasons_breech: string;
  referral_mat_reasons_sepsis: string;
  referral_mat_reasons_pph: string;
  referral_mat_reasons_movement: string;
  referral_mat_reasons_dyscosia: string;
  referral_mat_reasons_cs: string;
  referral_mat_reasons_prom: string;
  referral_mat_reasons_prolonged: string;
  referral_mat_reasons_mult_pregnancy: string;
  referral_mat_reasons_preterm: string;
  referral_mat_reasons_pet: string;
  referral_mat_reasons_lfhr: string;
  referral_mat_reasons_abortion: string;
  referral_mat_reasons_gbv: string;
  referral_mat_reasons_aph: string;
  referral_mat_reasons_dm: string;
  referral_mat_reasons_severe_anaemia: string;
  referral_mat_reasons_diabetes: string;
  referral_mat_reasons_hist_miscarriage: string;
  referral_mat_reasons_others: string;

  // referral_mat_tests: string[];
  referral_mat_bp_100mm_100min: string;
  referral_mat_hp_7g: string;
  referral_mat_fever_38: string;
  referral_mat_bp_160mm_100min: string;
  referral_mat_lbs: string;
  referral_mat_seizures: string;
  referral_mat_respiratory_distress: string;
  referral_mat_tests_others: string;

  // referral_mat_management: string;
  referral_mat_cpr: string;
  referral_mat_magnesium: string;
  referral_mat_oxygen: string;
  referral_mat_iv_fluids: string;
  referral_mat_art: string;
  referral_mat_iv_glucose: string;
  referral_mat_iron: string;
  referral_mat_antibiotics: string;
  referral_mat_currmanagment_others: string;

  // paediatrics referral
  referral_paed_hivStatus: string;
  referral_paed_seroExposed: string;
  referral_paed_birthDetails: string;
  referral_paed_birthWeight: string;
  referral_paed_apgar1: string;
  referral_paed_apgar5: string;
  referral_paed_status: string;
  referral_paed_malnutritionWfa: string;
  referral_paed_malnutritionOedema: string;
  referral_paed_diarrhoea: string;
  referral_paed_dehydration: string;

  // referral_paed_reasons: string[];
  referral_paed_reasons_birth_asphyxia: string;
  referral_paed_reasons_menengitis: string;
  referral_paed_reasons_jaundice: string;
  referral_paed_reasons_sepsis: string;
  referral_paed_reasons_malaria: string;
  referral_paed_reasons_burns: string;
  referral_paed_reasons_other: string;

  // referral_paed_signs: string[];
  referral_paed_signs_cnatdrink: string;
  referral_paed_signs_convulsions: string;
  referral_paed_signs_vomiting: string;
  referral_paed_signs_unconcious: string;
  referral_paed_signs_stridor: string;
  referral_paed_signs_malnutrition: string;
  referral_paed_respiratory_distress: string;
  referral_paed_signs_other: string;

  // referral_paed_tests: [],
  referral_paed_test_lbs: string;
  referral_paed_test_hb: string;
  referral_paed_test_capill: string;
  referral_paed_test_other: string;

  // referral_paed_management: [],
  referral_paed_management_cpr: string;
  referral_paed_management_oxygen: string;
  referral_paed_management_ivfluids: string;
  referral_paed_management_ivglucose: string;
  referral_paed_management_other: string;

  // general referral
  referral_gen_hivStatus: string;
  referral_gen_levelOfConsciousness: string;
  referral_gen_bloodGroup: string;

  // referral_gen_reasons: string[];
  referral_gen_reasons_cardiovascular: string;
  referral_gen_reasons_ent: string;
  referral_gen_reasons_burns: string;
  referral_gen_reasons_centralNervousSystem: string;
  referral_gen_reasons_ophthalmological: string;
  referral_gen_reasons_rta: string;
  referral_gen_reasons_gastrointestinal: string;
  referral_gen_reasons_respiratory: string;
  referral_gen_reasons_stings: string;
  referral_gen_reasons_genitourinary: string;
  referral_gen_reasons_musculoskeletal: string;
  referral_gen_reasons_poisoning: string;
  referral_gen_reasons_endocrine: string;
  referral_gen_reasons_renal: string;
  referral_gen_reasons_cancer: string;
  referral_gen_reasons_diseases: string;
  referral_gen_reasons_sepsis: string;
  referral_gen_reasons_body: string;
  referral_gen_reasons_aids: string;
  referral_gen_reasons_details: string;

  // referral_gen_tests: [],
  referral_gen_test_bp_100mm: string;
  referral_gen_test_hp: string;
  referral_gen_test_fever: string;
  referral_gen_test_bp_160mm: string;
  referral_gen_test_sugar_4mmol: string;
  referral_gen_test_seizures: string;
  referral_gen_test_sugar_11mmol: string;
  referral_gen_test_distress: string;
  referral_gen_test_diarrhoea: string;
  referral_gen_test_bleeding: string;
  referral_gen_test_vomiting: string;
  referral_gen_test_other: string;

  // referral_gen_bloodGroup: string[];
  referral_gen_bloodGrp: string;
  referral_gen_rhFactor: string;

  // referral_gen_managment: string[];
  referral_gen_management_cpr: string;
  referral_gen_management_antibiotics: string;
  referral_gen_management_oxygen: string;
  referral_gen_management_ivfluids: string;
  referral_gen_management_art: string;
  referral_gen_management_ivglucose: string;
  referral_gen_management_transfusion: string;
  referral_gen_management_digoxin: string;
  referral_gen_management_antihypertensives: string;
  referral_gen_management_analgesics: string;
  referral_gen_management_nebulization: string;
  referral_gen_management_anticonvulsants: string;
  referral_gen_management_other: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class Updates {
  updateDate: Date;
  description: string;
  dateAdded?: Date;
  shareLinkId?: string;
  attachment?: Attachment;
  referralType?: string = 'Consultation'
}
