import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { Narrative, Question } from '../../../models/narrative';
import { NarrativeBlock } from '../../../models/narrative-block';
import { NarrativeService } from '../../../services/narrative.service';
import { Attachment } from '../../../models/attachment';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { RepositoryService } from '../../../services/repository.service';
import { AttachmentModal } from '../../../attachments/attachment-modal';
import { ShareLinks, LabTest } from '../../../models/sharelinks';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export interface DrugPrescription {
  drugName: string;
  drugClass: string;
  instructions: string;
}

@Component({
  selector: 'psoc-shared-links-prescription',
  templateUrl: 'shared-links-prescription.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SharedLinksPrescriptionComponent implements OnInit {

  @Input() sharedLinkPatientId: string;
  @Input() myNarrative: Narrative;
  @Input() mySharedLink: ShareLinks;
  @Output() updatedSharedLinksReport: EventEmitter<ShareLinks> = new EventEmitter<ShareLinks>();
  @Input() attachments: Attachment[];
  @Output() viewNarrative = new EventEmitter();
  form: FormGroup;
  sharelinkId: string;
  backupSharelink: ShareLinks;
  pdfTitle: string = 'Teleconsultation Prescription';
  disclaimer: string = 'This telemedicine prescription is provided by Strathmore University Medical Centre to the patient whose details are included below. The prescription is based solely on information obtained through electronic communication, which may not be as accurate or comprehensive as information that may be obtained from an in-person consultation. The patient/guardian has acknowledged that they have understood the benefits, risks, and alternatives, and has consented to a telemedicine consultation.';


  // attachments dialog
  dialogRef: MatDialogRef<AttachmentModal>;
  selectedAttachment: Attachment;

  private jsonURL = 'assets/data/';
  drugClasses: any[];

  drugList: any[];
  disableFilter: boolean;
  drugNames: string[];
  filteredDrugNames: Observable<string[]>;

  prescription: DrugPrescription = this.clearPrescription();
  displayedColumns: string[] = ['name', 'instructions'];
  dataSource = new MatTableDataSource();

  labTestGroups: any[];
  labtestInfo: LabTest = new LabTest();

  ngOnInit(): void {
    // get sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id']

    // set up databases
    this.repository.loadSpecialistDb(this.sharelinkId);

    // load sharelink data
    this.getSharelinkData();

    // get the list of classes from the file
    this.getDataFile('drug-classes.json')
      .then(res => {
        this.drugClasses = res;
      }).catch(error => this.drugClasses = []);

    // get list of labtest
    this.getDataFile('labtests.json')
      .then(res => {
        this.labTestGroups = res;
      }).catch(error => this.labTestGroups = []);
  }

  /**
   * function get the data in a file
   * @param fileName name of the file to get
   */
  getDataFile(fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.jsonURL}${fileName}`).subscribe((data: any[]) => {
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log('Error getting file:', fileName);
        reject(err);
      });
    });
  }

  /**
   * function detect when drug class selection has changed
   * @param e event changed
   */
  drugClassChanged(e) {
    // enable the filter field
    this.form['controls']['drugname'].enable();

    // get drug file and initialize the filter
    this.getDataFile(e.value + '.json')
      .then(res => {
        this.initDruglist(res);
      }).catch(error => console.log(error));
  }

  /** initialize the drug list in the filter */
  initDruglist(drugs) {
    this.drugNames = drugs.map((d: any) => d.drug);
    this.filteredDrugNames = this.form['controls']['drugname'].valueChanges
      .pipe(
        startWith(''),
        map(value => this.applyDrugNameFilter(value))
      );
  }

  /** search drug by name */
  applyDrugNameFilter(val: string = ''): string[] {
    const filterValue = val.toLowerCase();

    return this.drugNames.filter(n => n.toLowerCase().includes(filterValue));
  }

  /** toggle check box to enable drug name typing in */
  toggleCheck(e) {
    this.disableFilter = e.checked
    this.prescription = this.clearPrescription();
    if (e.checked) {
      this.form['controls']['drugname'].disable(e.checked);
      this.form['controls']['drugclass'].disable(e.checked);
    } else {
      this.form['controls']['drugclass'].enable();
    }
  }

  /** Add prescription to the table */
  addPrescription() {
    if (!this.mySharedLink.prescribedDrugs) { this.mySharedLink.prescribedDrugs = [] }
    this.mySharedLink.prescribedDrugs.push(this.prescription);
    this.dataSource = new MatTableDataSource(this.mySharedLink.prescribedDrugs);
    this.prescription = this.clearPrescription();
  }

  /** Add select labtest to narrative */
  addLabTest() {
    if (!this.mySharedLink.labtestInfo) { this.mySharedLink.labtestInfo = [] }
    this.mySharedLink.labtestInfo.push(this.labtestInfo);
    this.labtestInfo = new LabTest();
  }

  /** clear prescription object */
  clearPrescription() {
    return {
      drugName: '',
      drugClass: '',
      instructions: ''
    };
  }

  /** remove a prescription from the list */
  removeDrug(prescription) {
    const index = this.mySharedLink.prescribedDrugs.indexOf(prescription);
    this.mySharedLink.prescribedDrugs.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.mySharedLink.prescribedDrugs);
  }

  /** remove labtest from the list */
  removeLabtest(labtest: LabTest) {
    const index = this.mySharedLink.labtestInfo.indexOf(labtest);
    this.mySharedLink.labtestInfo.splice(index, 1);
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  cancelClicked() {
    this.mySharedLink = new ShareLinks(this.backupSharelink);
  }

  getSharelinkData() {
    console.log('attempt load sharelink for ' + this.sharelinkId);
    this.patientService.getSingleSharedLinkData(this.sharelinkId)
      .then(sharelink => {

        this.mySharedLink = sharelink;
        if (!this.mySharedLink.published) { this.displayedColumns.push('remove') }
        if (!this.mySharedLink.labtestInfo) { this.mySharedLink.labtestInfo = [] }
        if (!this.mySharedLink.prescribedDrugs) { this.mySharedLink.prescribedDrugs = [] }
        this.dataSource = this.mySharedLink.prescribedDrugs.length > 0 ? new MatTableDataSource(this.mySharedLink.prescribedDrugs) :
          new MatTableDataSource([]);
        this.backupSharelink = new ShareLinks(sharelink);
        this.refreshOrInitializeFormControls();

        // get user info to set global
        const user = new User({
          username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
          facility: sharelink.createFacility,
          userRole: 'specialist'
        })
        this.authService.setUser(user);
        // setting the specialist details to the auth
        this.authService.getClientIp()
          .then(ip => {
            user.ipAddress = ip;
            this.authService.setUser(user);
          })
          .catch(err => {
            console.log('Error getting ip address: ' + err);
          });

        // load narrative data
        this.getShareLinkNarrative(sharelink.narrativeId, navigator.onLine);
      })
      .catch(error => {
        console.log('error loading sharelink in sharelinks-my-report for ' + this.sharelinkId, error);
        this.snackBar.open('Error getting sharelink data!', 'Error');
      });
  }

  getShareLinkNarrative(narrativeId: number, online: boolean): void {
    this.narrativeService.getSingleNarrative(+(narrativeId))
      .then(narrative => {
        this.myNarrative = narrative;
        console.log('getSingleNarrative narr.id: ' + narrative._id);
        this.ref.detectChanges();
      }).catch(error => {
        console.log('getSingleNarrative: ', error)
        this.snackBar.open('Error while getting narrative data', 'Error', { duration: 3000 });
      });
  }

  // initializing the form
  refreshOrInitializeFormControls(): void {
    // fix for older sharelinks without questions block
    if (!this.mySharedLink.questions) {
      this.mySharedLink.questions = Array<Question>();
    }

    // fix for newer sharelinks without blocks block
    if (!this.mySharedLink.blocks) {
      this.mySharedLink.blocks = Array<NarrativeBlock>();
    }

    // set up blocks for old sharelinks without questions block
    this.mySharedLink.blocks.forEach(
      (po, blckidx) => {
        (<FormArray>this.form.controls['blocks']).push(this.createShareLinkBlocks(po));
        po.segments.forEach((narBlockSegment, segidx) => {
          this.form.controls['blocks']['controls'][blckidx].controls.segments.push(this.createShareLinkBlockSegments(narBlockSegment));
        });
      }
    );

    // set up blocks for new sharelinks with questions array property
    this.mySharedLink.questions.forEach(question => {
      (<FormArray>this.form.controls['questions']).push(this.createNarrativeQuestion(question));
    });

  }

  // create narrative question form controls
  createNarrativeQuestion(question: Question): FormGroup {
    return this._fb.group({
      question: [question.question, [Validators.required, Validators.minLength(5)]],
      answer: [question.answer, [Validators.required, Validators.minLength(5)]],
      dateAdded: [new Date(question.dateAdded)]
    });
  }

  // CREATING THE FORM GROUP FOR THE NARRATIVE BLOCKS
  createShareLinkBlocks(narBlock) {
    return this._fb.group({
      blockid: [narBlock.blockid],
      blockname: [narBlock.blockname, [Validators.required]],
      segments: this._fb.array([])
    });

  }

  // CREATING THE FORM GROUP FOR THE NARRATIVE BLOCKS SEGMENTS
  // NEEDS TO BE SCALED DOWN
  createShareLinkBlockSegments(narBlockSegment) {
    return this._fb.group({
      segmentName: [narBlockSegment.segmentName, [Validators.required, Validators.minLength(5)]],
      segmentAnswer: [narBlockSegment.segmentAnswer, [Validators.required, Validators.minLength(3)]],
      attachmentTitle: [narBlockSegment.attachment.attachmentTitle],
      dateOnAttachment: [narBlockSegment.attachment.dateOnAttachment],
      attachmentFileName: [narBlockSegment.attachment.attachmentFileName],
      attachmentFile: ['']
    });
  }

  // Saves a draft copy of the report, validation not required
  saveAsDraftForm(model: ShareLinks) {
    // set the username
    this.authService.getUser().username = model.specialistInfo.name ? model.specialistInfo.name : 'specialist';

    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;
        this.updatedSharedLinksReport.emit(this.mySharedLink);

        /* add an event of report published */
        this.repository.addEvents('report', sharedLink._id, 'draft', this.authService.getUser().username,
          this.authService.getUser().ipAddress);

        this.snackBar.open('Your report has been saved as a draft', 'Success', { duration: 6000 });
      }).catch(error => {
        console.log('saveAsDraftForm error: ', error);
        this.snackBar.open('Error saving the report', 'Error');
      })
  }

  /* publishes the form, adds a link to updates, form must be valid */
  saveAndPublishForm(model: ShareLinks, isValid: boolean) {
    if (!isValid) {
      this.snackBar.open('Please ensure that all required fields are correctly completed', 'Error', { duration: 6000 });
      return false;
    }

    const d = new Date();

    this.mySharedLink.datePublished = d;
    this.mySharedLink.published = true;

    this.myNarrative.updates.push({
      updateDate: d,
      description: 'A specialist report was filled by ' + this.mySharedLink.specialistInfo.name + '. The report is linked',
      dateAdded: new Date(),
      shareLinkId: this.mySharedLink._id,
      referralType: 'Consultation'
    });

    const index = this.displayedColumns.indexOf('remove');
    this.displayedColumns.splice(index, 1);
    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;
        this.snackBar.open('Your report has been saved and published.', 'Success', { duration: 6000 });
        this.addUpdatesToNarrative(this.myNarrative, navigator.onLine);

        /* add an event of report published */
        this.repository.addEvents('report', sharedLink._id, 'published', this.authService.getUser().username,
          this.authService.getUser().ipAddress);

      }).catch(error => {
        console.log('saveForm error: ', error);
        this.snackBar.open('Error saving the report', 'Error');
      })
  }

  addUpdatesToNarrative(narrative: Narrative, online: boolean): void {
    /* add the update that a report has been published */
    this.narrativeService.updateNarrative(this.myNarrative)
      .then(updatedNarrative => {
        this.myNarrative = updatedNarrative;
      },
        error => {
          console.log(error);
          this.snackBar.open('Error saving adding report update to narrative', 'Error');
        });
  }

  constructor(
    private _fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private repository: RepositoryService,
    private authService: AuthenticationService,
    private ref: ChangeDetectorRef
  ) {

    /* initialize form with empty FormArray for narBlocks */
    this.form = this._fb.group({
      clinicalImpressions: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
      additionalResources: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(1024)]],
      blocks: this._fb.array([]),
      questions: this._fb.array([]),
      drugname: new FormControl({ value: '', disabled: true }),
      drugclass: [''],
      keyinName: [false],
      drugnameKeyin: ['', [Validators.minLength(3), Validators.maxLength(255)]],
      instructions: ['', [Validators.minLength(3), Validators.maxLength(255)]],
      labtest: [''],
      labtestcomment: ['', [Validators.minLength(5), Validators.maxLength(255)]],
      specialistInfo: this._fb.group({
        name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
        specialization: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
        qualifications: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
        affiliations: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]]
      })
    });
  }
}
