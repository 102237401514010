import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { Facility } from '../models/facility';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class FacilityService {

    constructor(private repository: RepositoryService,
        private snackBar: MatSnackBar) { }

    addFacility(newFacility: Facility): Promise<Facility> {
        newFacility._id = newFacility.code;
        newFacility.encryptionKey = this.generateKey();

        return new Promise((resolve, reject) => {
            this.repository.updateObject(newFacility, Facility.type, Databases.facilitiesDb)
                .then((pouchObject) => {
                    const updatedFacility: Facility = JSON.parse(JSON.stringify(pouchObject));
                    resolve(updatedFacility);
                })
                .catch(error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });

    }

    updateFacility(facility: Facility): Promise<Facility> {
        return new Promise((resolve, reject) => {
            this.repository.updateObject(facility, Facility.type, Databases.facilitiesDb)
                .then((pouchObject) => {
                    const updatedFacility: Facility = JSON.parse(JSON.stringify(pouchObject));
                    resolve(updatedFacility);
                })
                .catch(error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    deleteFacility(facility: Facility): Promise<void> {
        return new Promise((resolve, reject) => {
            this.repository.deleteObject(facility, Databases.facilitiesDb)
                .then(() => { resolve(null); })
                .catch(error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    getFacilities(): Promise<Facility[]> {
        return new Promise((resolve, reject) => {
            this.repository.fetchObjects(Facility.type, Databases.facilitiesDb)
                .then((result) => {
                    const facilities: Facility[] = result.docs.map((doc: any) => this.mapObjectToFacility(doc));
                    resolve(facilities);
                })
                .catch(error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    getFacility(facilityId: string): Promise<Facility> {
        return new Promise((resolve, reject) => {
            this.repository.fetchObject(Facility.type, facilityId, Facility.fields, ['_id'], Databases.facilitiesDb)
                .then(result => {
                    const facility: Facility = result.docs.map((doc: any) => this.mapObjectToFacility(doc))[0];
                    resolve(facility);
                })
                .catch(error => {
                    this.snackBar.open('Error forwarding sharellink', 'Error', { duration: 6000 })
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    private generateKey() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 15; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    private mapObjectToFacility(object: any): Facility {
        let facility: Facility = new Facility();
        return facility = { ...object };
    }

}
