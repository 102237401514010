import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../material/app-material.module';
import { MatDatepickerModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { ValidationModule } from '../validation/validation.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ClipboardModule } from 'ngx-clipboard';

import { AppointmentsComponent } from './appointments.component';
import { AppointmentsSpecialistComponent } from './specialist/appointments-specialist.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MomentModule,
    ValidationModule,
    NgxMaterialTimepickerModule,
    ClipboardModule
  ],
  declarations: [
    AppointmentsComponent,
    AppointmentsSpecialistComponent
  ],
  exports: [
    AppointmentsSpecialistComponent
  ]
})

export class AppointmentsModule { }
