import { Pipe, PipeTransform } from '@angular/core';
import { ShareLinks } from '../models/sharelinks';
import { NarrativeStatusFilterOptions, NarrativeInterface, NarrativeEnumTypes } from '../models/narrative-types';

@Pipe({ name: 'getNarrativeStatus' })
export class GetNarrativeStatus implements PipeTransform {
  transform(narrative: NarrativeInterface, patientSharelinks: ShareLinks[]) {
    const sharelinks = patientSharelinks ? patientSharelinks.filter(s => s.narrativeId === +narrative._id) : [];
    let narrativeStatus = '';

    // find if sharelinks are greater than 0
    if (sharelinks.length > 0) {
      // patient has sharelinks

      // check if either of sharelink is published
      const publishedSharelinks = sharelinks.filter((sharelink) => sharelink.published);
      if (publishedSharelinks.length > 0) {
        // report has been published by a specialist status: Reported
        narrativeStatus = NarrativeStatusFilterOptions.reported;
      } else {
        // no report has been published, sort sharelinks and get the status: Shared || Shared (Urgent)
        const sortedSharelinks = sharelinks.sort((a, b) => (a.id > b.id) ? -1 : 1);
        if (sortedSharelinks[0].urgent) {
          // the latest sharelink is urgent status: Shared (Urgent)
          narrativeStatus = NarrativeStatusFilterOptions.sharedUrgent;
        } else {
          if (narrative.narrativeType !== NarrativeEnumTypes.obstetric) {
            narrativeStatus = narrative.referralStatus;
          } else {
            // the latest sharelink is just shared status: Shared
            narrativeStatus = NarrativeStatusFilterOptions.shared;
          }
        }
      }
    } else {
      // patient has no sharelinks status: Not Shared
      narrativeStatus = NarrativeStatusFilterOptions.notShared;
    }

    return narrativeStatus;  // return narrative status
  }
}
