export interface NarrativeInterface {
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean;
  type: string;
  patientId: number;
  narrativeType: NarrativeEnumTypes;
  transactionStatus: string;
  [propName: string]: any;
  getSummary(patientDob: Date, patientCreateDate: Date): string;
}

export enum NarrativeEnumTypes {
  obstetric = 'Obstetric',
  consultation = 'Consultation'
}

export enum ReferralEnumTypes {
  general = 'General',
  paediatrics = 'Paediatrics',
  maternity = 'Maternity',
  consultation = 'Consultation'
}

export enum NarrativeStatusFilterOptions {
  notShared = 'Not shared',
  shared = 'Shared',
  sharedUrgent = 'Shared (Urgent)', // for obUSG narrative only
  reported = 'Reported' // for obUSG narrative only
}

/** 
 * narrative urgency filter options
 */
export enum NarrativeUrgencyFilterOptions {
  notEntered = '--Not entered--', // !referral_urgency || referral_urgency not filled  || sharelink(obUSG) not marked as urgent
  urgent = 'Urgent',
  emergency = 'Emergency',
  routine = 'Routine'
}

/**
 * dictionary for calculating gestation age by scan (Using Crown-rump Length table)
 * link: http://www.glowm.com/section_view/heading/Assessment+of+Gestational+Age+by+Ultrasound/item/206
 * TABLE 3.Crown-Rump Length Measurements Relative to Gestational Age in Three Studies
 * Measurements are in CM
 */
export const GestationAgeByCrownrumpLength = {
  1.0: 7 + (5 / 7),
  1.1: 7 + (6 / 7),
  1.2: 8 + (0 / 7),
  1.3: 8 + (1 / 7),
  1.4: 8 + (1 / 7),
  1.5: 8 + (2 / 7),
  1.6: 8 + (3 / 7),
  1.7: 8 + (4 / 7),
  1.8: 8 + (5 / 7),
  1.9: 8 + (5 / 7),
  2.0: 8 + (6 / 7),
  2.1: 9 + (0 / 7),
  2.2: 9 + (1 / 7),
  2.3: 9 + (1 / 7),
  2.4: 9 + (2 / 7),
  2.5: 9 + (3 / 7),
  2.6: 9 + (4 / 7),
  2.7: 9 + (4 / 7),
  2.8: 9 + (5 / 7),
  2.9: 9 + (6 / 7),
  3.0: 9 + (6 / 7),
  3.1: 10 + (0 / 7),
  3.2: 10 + (1 / 7),
  3.3: 10 + (2 / 7),
  3.4: 10 + (2 / 7),
  3.5: 10 + (3 / 7),
  3.6: 10 + (4 / 7),
  3.7: 10 + (4 / 7),
  3.8: 10 + (5 / 7),
  3.9: 10 + (6 / 7),
  4.0: 10 + (6 / 7),
  4.1: 10 + (0 / 7),
  4.2: 11 + (1 / 7),
  4.3: 11 + (1 / 7),
  4.4: 11 + (2 / 7),
  4.5: 11 + (3 / 7),
  4.6: 11 + (3 / 7),
  4.7: 11 + (4 / 7),
  4.8: 11 + (5 / 7),
  4.9: 11 + (5 / 7),
  5.0: 11 + (6 / 7),
  5.1: 12 + (0 / 7),
  5.2: 12 + (0 / 7),
  5.3: 12 + (1 / 7),
  5.4: 12 + (1 / 7),
  5.5: 12 + (2 / 7),
  5.6: 12 + (3 / 7),
  5.7: 12 + (3 / 7),
  5.8: 12 + (4 / 7),
  5.9: 12 + (4 / 7),
  6.0: 12 + (5 / 7),
  6.1: 12 + (6 / 7),
  6.2: 12 + (6 / 7),
  6.3: 13 + (0 / 7),
  6.4: 13 + (0 / 7),
  6.5: 13 + (1 / 7),
  6.6: 13 + (2 / 7)
}

/**
 * dictionary for calculating gestation age by scan (Using Biparietal Diameter)
 * link: http://www.glowm.com/section_view/heading/Assessment+of+Gestational+Age+by+Ultrasound/item/206
 * TABLE 4. Biparietal Diameter Measurements Relative to Gestational Age
 */
export const GestationAgeByBPD = {
  2.6: 13.6,
  2.7: 13.8,
  2.8: 14,
  2.9: 14.2,
  3.0: 14.4,
  3.1: 14.7,
  3.2: 15,
  3.3: 15.2,
  3.4: 15.4,
  3.5: 15.7,
  3.6: 16,
  3.7: 16.3,
  3.8: 16.7,
  3.9: 17,
  4.0: 17.3,
  4.1: 17.7,
  4.2: 18,
  4.3: 18.3,
  4.4: 18.7,
  4.5: 19,
  4.6: 19.3,
  4.7: 19.7,
  4.8: 20,
  4.9: 20.3,
  5.0: 20.7,
  5.1: 21,
  5.2: 21.3,
  5.3: 21.6,
  5.4: 21.8,
  5.5: 22,
  5.6: 22.3,
  5.7: 22.7,
  5.8: 23,
  5.9: 23.3,
  6.0: 23.7,
  6.1: 24,
  6.2: 24.3,
  6.3: 24.7,
  6.4: 25,
  6.5: 25.3,
  6.6: 25.7,
  6.7: 26,
  6.8: 26.3,
  6.9: 26.7,
  7.0: 27,
  7.1: 27.3,
  7.2: 27.7,
  7.3: 28,
  7.4: 28.3,
  7.5: 28.7,
  7.6: 29,
  7.7: 29.4,
  7.8: 30,
  7.9: 30.4,
  8.0: 31,
  8.1: 31.4,
  8.2: 32,
  8.3: 32.3,
  8.4: 32.7,
  8.5: 33,
  8.6: 33.4,
  8.7: 34,
  8.8: 34.6,
  8.9: 35.3,
  9.0: 36,
  9.1: 36.4,
  9.2: 37.3,
  9.3: 37.7,
  9.4: 39,
  9.5: 40,
  9.6: 40.3,
  9.7: 40.7,
  9.8: 41,
  9.9: 41.7,
  10.0: 42.3
}

/**
 * dictionary for calculating gestation age by scan (Using Head Circumference)
 * link: http://www.glowm.com/section_view/heading/Assessment+of+Gestational+Age+by+Ultrasound/item/206
 * TABLE 5. Head Circumference Measurements Relative to Gestational Age
 */
export const GestationAgeByHC = {
  8.0: 13.4,
  8.5: 13.7,
  9.0: 14.0,
  9.5: 13.7,
  10.0: 14.6,
  10.5: 15.0,
  11.0: 15.3,
  11.5: 15.6,
  12.0: 15.9,
  12.5: 16.3,
  13.0: 16.6,
  13.5: 17.0,
  14.0: 17.3,
  14.5: 17.7,
  15.0: 18.1,
  15.5: 18.4,
  16.0: 18.8,
  16.5: 19.2,
  17.0: 19.6,
  17.5: 20.0,
  18.0: 20.4,
  18.5: 20.8,
  19.0: 21.2,
  19.5: 21.6,
  20.0: 22.1,
  20.5: 22.5,
  21.0: 23.0,
  21.5: 23.4,
  22.0: 23.9,
  22.5: 24.4,
  23.0: 24.9,
  23.5: 25.4,
  24.0: 25.9,
  24.5: 26.4,
  25.0: 26.9,
  25.5: 27.5,
  26.0: 28.0,
  26.5: 28.1,
  27.0: 29.2,
  27.5: 29.8,
  28.0: 30.3,
  28.5: 31.0,
  29.0: 31.6,
  29.5: 32.2,
  30.0: 32.8,
  30.5: 33.5,
  31.0: 34.2,
  31.5: 34.9,
  32.0: 35.5,
  32.5: 36.3,
  33.0: 37.0,
  33.5: 37.7,
  34.0: 38.5,
  34.5: 39.2,
  35.0: 40.0,
  35.5: 40.8,
  36.0: 41.6,
}

/**
 * dictionary for calculating gestation age by scan (Using Abdominal Circumference)
 * link: http://www.glowm.com/section_view/heading/Assessment+of+Gestational+Age+by+Ultrasound/item/206
 * TABLE 6. Abdominal Circumference Measurements Relative to Gestational Age
 */
export const GestationAgeByAC = {
  10.0: 15.6,
  10.5: 16.1,
  11.0: 16.5,
  11.5: 16.9,
  12.0: 17.3,
  12.5: 17.8,
  13.0: 18.2,
  13.5: 18.6,
  14.0: 19.1,
  14.5: 19.5,
  15.0: 20.0,
  15.5: 20.4,
  16.0: 20.8,
  16.5: 21.3,
  17.0: 21.7,
  17.5: 22.2,
  18.0: 22.6,
  18.5: 23.1,
  19.0: 23.6,
  19.5: 24.0,
  20.0: 24.5,
  20.5: 24.9,
  21.0: 25.4,
  21.5: 25.9,
  22.0: 26.3,
  22.5: 26.8,
  23.0: 27.3,
  23.5: 27.7,
  24.0: 28.2,
  24.5: 28.7,
  25.0: 29.2,
  25.5: 29.7,
  26.0: 30.1,
  26.5: 30.6,
  27.0: 31.1,
  27.5: 31.6,
  28.0: 32.1,
  28.5: 32.6,
  29.0: 33.1,
  29.5: 33.6,
  30.0: 34.1,
  30.5: 34.6,
  31.0: 35.1,
  31.5: 35.6,
  32.0: 36.1,
  32.5: 36.6,
  33.0: 37.1,
  33.5: 37.6,
  34.0: 38.1,
  34.5: 38.7,
  35.0: 39.2,
  35.5: 39.7,
  36.0: 40.2,
  36.5: 40.8
}

/**
 * dictionary for calculating gestation age by scan (Using Femur Length table)
 * link: http://www.glowm.com/section_view/heading/Assessment+of+Gestational+Age+by+Ultrasound/item/206
 * TABLE 8. Femur Length Measurements Relative to Gestational Age
 */
export const GestationAgeByFemurLength = {
  1.0: 12.8,
  1.1: 13.1,
  1.2: 13.4,
  1.3: 13.6,
  1.4: 13.9,
  1.5: 14.2,
  1.6: 14.5,
  1.7: 14.8,
  1.8: 15.1,
  1.9: 15.4,
  2.0: 15.7,
  2.1: 16.0,
  2.2: 16.3,
  2.3: 16.6,
  2.4: 16.9,
  2.5: 17.2,
  2.6: 17.6,
  2.7: 17.9,
  2.8: 18.2,
  2.9: 18.6,
  3.0: 18.9,
  3.1: 19.2,
  3.2: 19.6,
  3.3: 19.9,
  3.4: 20.3,
  3.5: 20.7,
  3.6: 21.0,
  3.7: 21.4,
  3.8: 21.8,
  3.9: 22.1,
  4.0: 22.5,
  4.1: 22.9,
  4.2: 23.3,
  4.3: 23.7,
  4.4: 24.1,
  4.5: 24.5,
  4.6: 24.9,
  4.7: 25.3,
  4.8: 25.7,
  4.9: 26.1,
  5.0: 26.5,
  5.1: 27.0,
  5.2: 27.4,
  5.3: 27.8,
  5.4: 28.2,
  5.5: 28.7,
  5.6: 29.1,
  5.7: 29.6,
  5.8: 30.0,
  5.9: 30.5,
  6.0: 30.9,
  6.1: 31.4,
  6.2: 31.9,
  6.3: 32.3,
  6.4: 32.8,
  6.5: 33.3,
  6.6: 33.8,
  6.7: 34.2,
  6.8: 34.7,
  6.9: 35.2,
  7.0: 35.7,
  7.1: 36.2,
  7.2: 36.7,
  7.3: 37.2,
  7.4: 37.7,
  7.5: 38.3,
  7.6: 38.8,
  7.7: 39.3,
  7.8: 39.8,
  7.9: 40.4
};
