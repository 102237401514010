import { Injectable } from '@angular/core';

import * as dicomParser from 'dicom-parser';
import * as cornerstone from 'cornerstone-core/dist/cornerstone.js';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader/dist/cornerstoneWADOImageLoader.js';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader/dist/cornerstoneWebImageLoader.js';
@Injectable()

export class CornerstoneService {

  constructor() {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser

    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      webWorkerPath : 'assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
      taskConfiguration: {
        'decodeTask' : {
          codecsPath: 'cornerstoneWADOImageLoaderCodecs.js'
        }
      }
    });
  }

  fetchDicomImage(url: string): Promise<any> {
    console.log(`fetching wado url ${url}`);
    return cornerstone.loadAndCacheImage(`wadouri:${url}`);
  }

  fetchNormalImage(url: string): Promise<any> {
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;

    console.log(`fetching web url ${url}`);
    return cornerstone.loadImage(url);
  }

  destroyCache() {
    cornerstone.imageCache.purgeCache()
  }
}
