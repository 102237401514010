import { Component } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { AttachmentService } from '../../../services/attachment.service';
import { Attachment } from '../../../models/attachment';

@Component({
  template: 'block pdf view'
})

export class BlockPdfReportViewComponent {
  imgSrcList: Array<any> = [];

  constructor(
    public sanitizer: DomSanitizer,
    public attachmentService: AttachmentService
  ) { }

  /**
   * function to fetch the block attachments files from db
   * @param nurseData nurse block data
   */
  getAttachments(nurseData) {
    nurseData.attachmentIds.forEach((attId: string, index: number) => {
      this.attachmentService.getAttachment(attId)
        .then((attachement: Attachment) => {
          this.attachmentService.getAttachmentFilesAsObjectUrls(attachement)
            .then((urlList: any) => {
              this.imgSrcList = urlList;
            }).catch(error => console.log('Error getting attachment urls:', error))
        }).catch(error => console.log('Error getting attachment:', error));
    })
  }

}
