import { Component, Input } from '@angular/core';
import { ShareLinks } from '../../../models/sharelinks';
import { Patient } from '../../../models/patient';

@Component({
    selector: 'psoc-shared-links-patient',
    templateUrl: 'shared-links-patient.component.html',
})

export class SharedLinksPatientInfoComponent {
    @Input() mySharedLink: ShareLinks;
    @Input() pdfTitle: string;
    @Input() disclaimer: string;
    @Input() presumptive: string;
}