import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../services/patient.service';
import { NarrativeService } from '../services/narrative.service';
import { RepositoryService } from '../services/repository.service';
import { ShareLinks } from '../models/sharelinks';
import { Narrative, ReferralStatus } from '../models/narrative';
import { NarrativeEnumTypes } from '../models/narrative-types';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { AppSettingsService } from '../services/settings.service';
import { AppSettings } from '../models/config';
import { Appointment } from '../models/appointment';
import { AppointmentService } from '../services/appointment.service';

@Component({
    selector: 'psoc-share-video-link-dialog',
    template: `
    <h2>Share video link with patient</h2>
    <p>Video link: <span style="background: #e0e0e0;padding: 3px;"
        (click)="openVideoLink()">{{videoLink}}</span>
        <button mat-button ngxClipboard [cbContent]="videoLink" (cbOnSuccess)="notifyLinkCopied()">
            <mat-icon>content_copy</mat-icon>
        </button>
    </p>
    <form [formGroup]="form">
        <mat-form-field class="width100">
            <input matInput type="text" formControlName="specialistName" placeholder="Enter Your Name"
            [(ngModel)]="sharelink.specialistInfo.name">
        </mat-form-field>

        <!-- date and time of appointment -->
        <mat-form-field class="width50 uk-margin-right">
            <input matInput [matDatepicker]="picker" [min]='minDate' formControlName="appointmentDate"
                placeholder="Choose a date (DD/MM/YYYY)" [(ngModel)]="chatAppointment.dateAppointment">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="width45">
            <input matInput [ngxTimepicker]="timePicker" formControlName="appointmentTime" placeholder="Choose Time"
                [(ngModel)]="chatAppointment.timeAppointment">
            <ngx-material-timepicker-toggle matSuffix [for]="timePicker"></ngx-material-timepicker-toggle>
        </mat-form-field>
        <ngx-material-timepicker #timePicker></ngx-material-timepicker>

        <mat-dialog-actions>
            <button type="button" mat-raised-button [disabled]="!form.valid" (click)="dialogRef.close(chatAppointment)">Share</button>
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button>
        </mat-dialog-actions>
    </form>
    `
})
export class ShareVideoLinkDialogComponent {
    videoLink: string;
    sharelink: ShareLinks;
    form: FormGroup;
    chatAppointment: any = {};
    minDate = new Date();
    constructor(
        public fb: FormBuilder,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.videoLink = this.data.videoURL;
        this.sharelink = this.data.sharelink;
        this.chatAppointment = { dateAppointment: '', timeAppointment: '', videoLink: this.videoLink };

        this.form = this.fb.group({
            specialistName: ['', [Validators.required]],
            appointmentDate: ['', [Validators.required]],
            appointmentTime: ['', [Validators.required]]
        });
    }

    /** function open video link */
    openVideoLink() {
        window.open(this.videoLink, '_blank');
    }

    /** Notify useer that link has been copied */
    notifyLinkCopied() {
        this.snackBar.open('Video link copied to clip board', '', { duration: 3000 })
    }
}
@Component({
    selector: 'psoc-update-case-status-dialog-component',
    template: `
    <h2 mat-dialog-title>Update Status</h2>
    <mat-dialog-content [formGroup]="narrativeStatusForm">

        <mat-form-field class="width100">
            <mat-label>Update case status</mat-label>
            <mat-select formControlName="status">
                <mat-option *ngFor="let status of referralStatuses" [value]="status.value"> {{status.value}} </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="width100">
            <mat-label>Add a note for this status change</mat-label>
            <textarea matInput type="text" formControlName="statusNote"></textarea>
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-raised-button [disabled]="!narrativeStatusForm.valid" (click)="save()">Save</button>
        <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button>
    </mat-dialog-actions>
    `
})
export class UpdateCaseStatusHistoryDialogComponent {
    narrative: Narrative;
    referralStatuses: ReferralStatus;
    narrativeStatusForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public repository: RepositoryService,
        public formBuilder: FormBuilder
    ) {
        this.narrative = this.data.currentNarrative;
        this.referralStatuses = this.data.referralStatuses;
        this.narrativeStatusForm = this.formBuilder.group({
            'status': ['', Validators.required],
            'statusNote': ['']
        });
    }

    save() {
        this.dialogRef.close(this.narrativeStatusForm.value);
    }
}

@Component({
    selector: 'psoc-top-nav-specialist',
    templateUrl: 'top-nav-specialist.component.html',
})
export class TopNavSpecialistComponent {
    currentSharelink: ShareLinks;
    currentNarrative: Narrative;
    narrativeTypes = NarrativeEnumTypes;
    dialogRef: MatDialogRef<any>;
    environment = environment;
    referralStatusEnum = ReferralStatus;
    appSettings: AppSettings;
    videoURL: string;

    constructor(
        public patientService: PatientService,
        private activatedRoute: ActivatedRoute,
        private narrativeService: NarrativeService,
        private authService: AuthenticationService,
        private repository: RepositoryService,
        private snackbar: MatSnackBar,
        public dialog: MatDialog,
        private appointmentService: AppointmentService,
        private appSettingsService: AppSettingsService,
    ) {
        this.patientService.hideNav = (this.activatedRoute.snapshot.queryParamMap.get('hidenav') === 'true');
        this.patientService.currentSharelink.subscribe(sharelink => this.currentSharelink = sharelink);
        this.narrativeService.narrative.subscribe(narrative => this.currentNarrative = narrative);
        this.appSettingsService.appSettings.subscribe(settings => this.appSettings = settings);

    }

    /** send the user to the video chat app */
    showVideoChat() {
        // add a dialog
        this.videoURL =
        `${environment.appURL}video/index.html?room=${this.currentNarrative._id}-${this.currentSharelink.patient.firstname}`;
        window.open(this.videoURL, '_blank');
        // this.openDialog('video');
    }

    /**
     * open up dialog to change the narrative status
     */
    openDialog(dialog: string = 'statusChange') {
        const dialogConfig = new MatDialogConfig();

        if (dialog === 'video') {
            dialogConfig.data = { videoURL: this.videoURL, sharelink: this.currentSharelink }
            this.dialogRef = this.dialog.open(ShareVideoLinkDialogComponent, dialogConfig);
            this.dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    const date = result.dateAppointment.format('MMMM Do YYYY');
                    const appointmentTime = date + ', ' + result.timeAppointment;
                    // add an event to notify patient
                    const appointment = new Appointment();
                    appointment.specialistEmail = this.currentSharelink.specialistInfo.email;
                    appointment.confirmedAppointmentDate = null;
                    appointment.timeAppointment = appointmentTime;
                    appointment.appointmentType = 'videochat';
                    appointment.patientId = this.currentSharelink.patientId;
                    appointment.sharelinkId = this.currentSharelink._id;
                    appointment.dateTimeSuggested = null;
                    appointment.assignedMediator = this.currentSharelink.assignedMediatorUsername;
                    appointment.confirmed = false;

                    // save appointment
                    this.appointmentService.addAppointment(appointment)
                        .then(savedAppointment => {
                            this.currentSharelink.videoLink = result.videoLink;
                            this.updateSharelink();
                            this.snackbar.open('Success adding appointment', 'Success', { duration: 6000 });
                        }).catch(error => this.snackbar.open('Error adding appointment', 'Error', { duration: 6000 }));
                    this.dialogRef = null;
                }
            });
        } else {
            dialogConfig.data = {
                currentNarrative: this.currentNarrative,
                // filter help desk statuses for mentor
                referralStatuses: this.repository.enumSelector(ReferralStatus)
                    .filter(({ value }) =>
                        value !== ReferralStatus.referred &&
                        value !== ReferralStatus.caseViewed &&
                        value !== ReferralStatus.admitted &&
                        value !== ReferralStatus.dischargedFromOP &&
                        value !== ReferralStatus.dischargedWithDS &&
                        value !== ReferralStatus.dischargedWithoutDS)
            }
            // pass current narrative to the dialog component
            this.dialogRef = this.dialog.open(UpdateCaseStatusHistoryDialogComponent, dialogConfig);

            this.dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    // Save the case status to the narrative
                    this.updateNarrativeStatusHistory(result);
                }
                this.dialogRef = null;
            });
        }
    }

    /**
     * Update narrative with the sselected status change
     * @param result narrative status result
     */
    updateNarrativeStatusHistory(result) {
        // update the referral status
        this.currentNarrative.referralStatus = result.status;

        this.narrativeService.updateStatusHistory(this.currentNarrative, result.status, result.statusNote);

        // add status change event
        const userName = this.authService.getUser().username ? this.authService.getUser().username : 'specialist';
        const ipAddress = this.authService.getUser().ipAddress ? this.authService.getUser().ipAddress : '';
        this.repository.addEvents(this.currentNarrative.type, this.currentNarrative._id, 'statusUpdated', userName,
            ipAddress, this.currentNarrative.referralStatus, this.currentNarrative.patientId);

        // update narrative
        this.narrativeService.updateNarrative(this.currentNarrative).then((updatedNarrative) => {
            this.snackbar.open('Narrative status updated', 'Success', { duration: 6000 });
        }).catch((err) => {
            console.log('Error updating narrative status::', err);
            this.snackbar.open('Error updating narrative status', 'Error');
        })
    }

    /** function update sharelink */
    updateSharelink() {
        this.patientService.updateShareLink(this.currentSharelink)
            .then(sharelink => {
                this.currentSharelink = sharelink;
            }).catch(error => {
                console.error(error);
                this.snackbar.open('Error updating sharelink');
            });
    }
}
