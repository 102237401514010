import { BlockSegment, NarrativeBlock } from './narrative-block';
import { PouchObject } from './pouch-object';
import { Patient } from './patient';
import { Facility } from './facility';
import { Question } from './narrative';
import { SonographerBlocks, UltrasoundRecord } from './obstetric-sharelink';
import { Diagnosis } from './diagnosis';

export class ShareLinks implements PouchObject {
  public static type: string = 'sharelinks';
  public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'patient', 'patientId', 'narrativeId', 'urgent',
    'comments', 'intent', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'sharedFacility',
    'assignedMediatorUsername', 'dateAdded', 'dateUpdated', 'expired', 'blocks',
    'clinicalImpressions', 'additionalResources', 'specialistInfo', 'published', 'datePublished', 'dateOfAdmission',
    'dateOfDischarge', 'dischargeFrom', 'dischargedBy', 'ward', 'clinic', 'clinicianInCharge', 'diagnosisOfDischarge',
    'clinicalManagementSummary', 'dischargeInstructions', 'dischargeMedication', 'additionalRes', 'questions', 'sonographerBlocks',
    'ultrasoundRecord', 'shortUrl', 'dbInfo', 'replicationInfo', 'replicationIds', 'scanFacilityCopy', 'prescribedDrugs', 'onlineDelivery',
    'labtestInfo', 'radiologyInvestigations', 'recommendation', 'segment', 'diagnoses'];
  // pouch object metadata fields
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;
  sharedFacility: string[];
  dateAdded: Date;
  dateUpdated: Date;

  // sharelink fields
  assignedMediatorUsername: string; // mediator sharelink is assigned to
  patient: Patient; // copy of patient information without user identifiers
  facilities: Facility[];
  patientId: number; // patient to which this sharelink belongs
  narrativeId: number; // narrative to which this sharelink belongs
  comments: string; // sharelink description field
  intent: string; // type of sharelink General/Paediatrics/Maternity/Consultation
  urgent: boolean; // indicate if case needs urgent attention
  diagnoses?: any;

  // sonographers data
  sonographerBlocks?: SonographerBlocks;
  // ultrasound record data
  ultrasoundRecord?: UltrasoundRecord; // copy of ultrasound record information

  scanFacilityCopy?: any;

  // report or discharge-summary fields
  published: boolean = false;
  datePublished?: Date;
  specialistInfo: SpecialistInfo;

  // discharge-summary fields
  dateOfAdmission: Date;
  dateOfDischarge: Date;
  dischargeFrom: string;
  dischargedBy: string;
  ward: string;
  clinic: string;
  clinicianInCharge: string;
  diagnosisOfDischarge: string;
  clinicalManagementSummary: string;
  dischargeInstructions: string;
  dischargeMedication: string;
  additionalRes: string;
  onlineDelivery?: boolean = true;
  prescribedDrugs?: any[] = [];
  labtestInfo?: LabTest[] = [];
  radiologyInvestigations?: Radiology[] = [];

  // my-report fields
  expired?: boolean = false;
  blocks: NarrativeBlock[]; // used be used to copy questions from specialists
  questions: Question[];
  clinicalImpressions: string;
  additionalResources: string;

  recommendation: Recommendation = new Recommendation();

  // short url version of link
  shortUrl?: string;
  videoLink?: string;

  // diagnostics
  dbInfo: any[];
  replicationInfo: any[];
  replicationIds: any[];

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class Radiology {
  radiologyScan: string;
  radiologyScanOther: string;
  areaOfInterest: string;
  comment: string;
}

export class LabTest {
  labtestGroup: string;
  labtest: string;
  labtestOther: string;
  comment: string;
}

export class Recommendation {
  recommendation: string;
  notes: string;
}
export class SpecialistInfo {

  name: string;
  qualifications: string;
  affiliations: string;
  email: string;
  boardNumber: string;
  phoneNumber: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class RequestShareLinks extends ShareLinks {
  blockId: string;
  segment: BlockSegment;
}
