import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../../services/authentication.service';
import { ShareLinks } from '../../models/sharelinks';
import { User } from '../../models/user';
import { Appointment } from '../../models/appointment';
import { RepositoryService } from '../../services/repository.service';
import { AppointmentService } from '../../services/appointment.service';
import { PatientService } from '../../services/patient.service';
import { DateFormatPipe } from 'ngx-moment';
import moment from 'moment'
import { ValidationService } from '../../validation/validation.service';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../models/config';
import { AppSettingsService } from '../../services/settings.service';

@Component({
  selector: 'psoc-specialist-appointments',
  templateUrl: 'appointments-specialist.component.html',
  providers: [DateFormatPipe]
})
export class AppointmentsSpecialistComponent implements OnInit {

  sharelink: ShareLinks;
  sharelinkId: string;
  patientId: number;
  videoURL: string;
  env = environment;

  appointmentForm: FormGroup;
  appointment = new Appointment();
  suggestedTimes = new Array<any>();

  loadingPatientData: boolean = false;
  suggetstedCheck: boolean = false;

  minDate = new Date();

  // all appointmens for this patient
  appointments = new Array<Appointment>();
  // all upcoming appointments
  upcomingAppointments = new Array<Appointment>();
  // all past appointments
  pastAppointments = new Array<Appointment>();
  // all expired appointments
  expiredAppointments = new Array<string>();
  // all suggested appointments
  suggestedAppointments = new Array<string>();

  appSettings: AppSettings = new AppSettings();

  constructor(
    private authService: AuthenticationService,
    private repositoryService: RepositoryService,
    private appointmentService: AppointmentService,
    private appSettingsService: AppSettingsService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.appSettingsService.appSettings.subscribe(settings => this.appSettings = settings);

    this.appointmentForm = this.formBuilder.group({
      'specialistEmail': ['', [Validators.maxLength(255), Validators.required]],
      'specialistName': ['', [Validators.maxLength(255), Validators.required]],
      'confirmedAppointmentDate': [null, [ValidationService.dateFormatValid, Validators.required]],
      'appointmentTime': ['', Validators.required]
    }, { validator: ValidationService.timeDateInFutureValidator('appointmentTime', 'confirmedAppointmentDate') });
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  // function to clear the form fields
  cancel() {
    this.appointment = new Appointment();
    this.appointmentForm.controls['confirmedAppointmentDate'].reset();
    this.appointmentForm.controls['appointmentTime'].reset();
  }

  // get the sharelink using url id
  getSharelinkData() {
    this.loadingPatientData = true;

    // sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id']

    this.patientService.getSingleSharedLinkData(this.sharelinkId).then(sharelink => {
      this.sharelink = sharelink

      // set video link
      this.videoURL = `${this.env.appURL}video/index.html?room=${this.sharelink.narrativeId}-${this.sharelink.patient.firstname}`;

      // check if video link is saved
      if (!this.sharelink.videoLink) {
        this.sharelink.videoLink = this.videoURL;
      }

      // get user info to set global
      const user = new User({
        username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
        facility: sharelink.createFacility,
        userRole: 'specialist'
      })
      // setting the specialist details to the auth
      this.authService.getClientIp()
        .then(ip => {
          user.ipAddress = ip;
          this.authService.setUser(user);
        })
        .catch(err => {
          console.log('Error getting ip address: ' + err)
          this.authService.setUser(user);
        });

      this.loadingPatientData = false;
    })
      .catch(error => {
        this.loadingPatientData = false;
        console.log('Error loading sharelink data in appointments-specialist component:', error);
        const snackBarRef = this.snackBar.open('Error loading sharelink data.', 'Retry')
        snackBarRef.onAction().subscribe(() => {
          this.getSharelinkData()
        });
      });
  }

  /**
   * Function to select multiple times for appointment
   * @param newDate selected date
   * @param newTime selected time slot
   */
  addSuggestedTimes(newDate: Date, newTime: string) {
    const date = moment(newDate);
    const selectedDate = moment(date.year() + '/' + (date.month() + 1) + '/' + date.date() + ' ' + newTime);
    this.suggestedTimes.push(selectedDate);
  }

  /**
   * remove the suggested time from the suggested list
   * @param index number
   */
  removeTime(index: number) {
    this.suggestedTimes.splice(index, 1);
  }

  /** functio to save an appointment */
  newAppointment() {
    /* assign the date and time new values */
    this.appointment.specialistName = this.sharelink.specialistInfo.name;
    this.appointment.specialistEmail = this.sharelink.specialistInfo.email;
    this.appointment.confirmedAppointmentDate = null;  /* clear date */
    this.appointment.timeAppointment = '';  /* clear time */
    this.appointment.patientId = this.sharelink.patientId;
    this.appointment.sharelinkId = this.sharelinkId;
    this.appointment.dateTimeSuggested = this.suggestedTimes;
    this.appointment.assignedMediator = this.sharelink.assignedMediatorUsername;
    this.appointment.confirmed = false;

    /* check if the sharelink is shared */
    if (this.sharelink.sharedFacility !== undefined) {
      this.appointment.sharedFacility = this.sharelink.sharedFacility.slice();
    }

    /* set the current username */
    const currentUser = this.authService.getUser();
    currentUser.username = this.sharelink.specialistInfo.name;

    // save appointment to db
    this.appointmentService.addAppointment(this.appointment)
      .then(appointment => {
        this.suggestedAppointments.push(...this.suggestedTimes);

        // empty suggested times array
        this.suggestedTimes = [];

        /* update sharelink with specilaist name */
        this.updateShareLink();

        /* clear appointment form */
        this.cancel();
        this.snackBar.open('Appointment added successfully.', 'Success', { duration: 6000 });
      })
      .catch(error => {
        this.snackBar.open('Error adding appointment', 'Error');
      })
  }

  /* update sharelink */
  updateShareLink() {
    this.patientService.updateShareLink(this.sharelink)
      .then(sharelink => this.sharelink = sharelink)
      .catch(err => console.log('Error updating sharelink: ' + err))
  }

  /** get appointments by tabs */
  getAppointmentsByTab() {
    const today = new Date();
    this.upcomingAppointments = this.appointments.filter(a => a.confirmed === true && new Date(a.confirmedAppointmentDate) > today);
    this.pastAppointments = this.appointments.filter(a => a.confirmed === true && new Date(a.confirmedAppointmentDate) < today);

    const notConfirmedAppointments = this.appointments.filter(a => a.confirmed !== true);
    notConfirmedAppointments.forEach(appointment => {
      if (!appointment.dateTimeSuggested) { appointment.dateTimeSuggested = [] }
      const expiredDates = appointment.dateTimeSuggested.filter(d => new Date(d) < today);
      const suggestedDates = appointment.dateTimeSuggested.filter(d => new Date(d) > today);
      this.expiredAppointments.push(...expiredDates);
      this.suggestedAppointments.push(...suggestedDates);
    });
  }

  // function to get sharelink's appointments.
  getAppointments() {
    // get all the appointmens through the service
    this.appointmentService.getShareLinkAppointments('sharelinkId', this.sharelinkId).then(appointments => {
      this.appointments = appointments;

      // get all the upcoming or past appoitments
      this.getAppointmentsByTab();
    })
      .catch(error => {
        console.log('Error loading appointments', error)
        const snackBarRef = this.snackBar.open('Error loading appointments', 'Retry')
        snackBarRef.onAction().subscribe(() => {
          this.getAppointments();
        });
      })
  }

  /** Notify useer that link has been copied */
  notifyLinkCopied() {
    this.snackBar.open('Video link copied to clipboard', '', { duration: 3000 });
  }

  ngOnInit() {
    /* call the db connection */
    this.repositoryService.loadSpecialistDb(this.route.snapshot.params['id']);

    /* load the sharelinks data */
    this.getSharelinkData();

    /* load the sharelinks appointments */
    this.getAppointments();
  }
}
