import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Attachment } from '../../../models/attachment';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'psoc-scan-viewer',
  templateUrl: './scan-viewer.modal.html'
})

export class ScanViewerModalComponent {
  attachment: Attachment;
  thumbnails: Array<SafeUrl>; // list of thumbnails for selected attachment
  sideThumbnails: Array<any>; // list of sidebar thumbnails for selected attachment

  constructor(
    @Inject(MAT_DIALOG_DATA) data) {

    this.attachment = data.attachment;
    this.thumbnails = data.thumbnails;
    this.sideThumbnails = data.sideThumbnails;
  }
}
