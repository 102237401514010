import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { Facility } from '../models/facility';
import { Globals } from '../services/globals';
import { Observable, of, fromEvent, merge, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RepositoryService, SyncStatus, AppTypes } from '../services/repository.service';
import { environment } from '../../environments/environment';
import { RepositoryObserver } from '../services/repository-observer';
import { ValidationService } from '../validation/validation.service';
import { FacilityService } from '../services/facility.service';

@Component({
  selector: 'profile',
  templateUrl: 'profile.component.html'
})
export class ProfileComponent implements OnInit, RepositoryObserver {

  changeProfile: boolean;
  changePassword: boolean;
  userEditForm: FormGroup;
  changePassForm: FormGroup;
  showMenu: boolean;
  error: string;
  userModel: User;
  online: Observable<boolean>;
  public syncStatusEnum = SyncStatus;
  public appTypes = AppTypes;
  appVersion: String;
  appDb: String;
  sessionInfo: any = 'Loading...';
  loadBackupStatus: String = '';
  refreshForm: FormGroup;
  isRoot: Boolean = false;
  facilities: Facility[];
  loadingFacilities: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private globals: Globals,
    public repository: RepositoryService,
    private facilityService: FacilityService,
    private ref: ChangeDetectorRef
  ) {

    // load facilities
    this.getFacilities();

    this.refreshForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    this.authService.isRoot.subscribe(isRoot => this.isRoot = isRoot);

    this.appVersion = environment.appVersion
    this.appDb = environment.pouchDBName;

    this.repository.registerObserver(this);

    // set current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));

    // boolean to detect if editing profile
    this.changeProfile = false;
    // boolean to detect if changing password
    this.changePassword = false;

    // the user edit profile form
    this.userEditForm = this.formBuilder.group({
      'emailAddress': ['', [Validators.required, ValidationService.emailValidator]]
    });

    // the change password form
    this.changePassForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    }, { validator: ValidationService.passwordConfirm('password', 'confirm_password') });

    // get online status
    this.online = new BehaviorSubject<boolean>(false);
    this.online = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    )

  }

  // support backup from files created by https://github.com/pouchdb-community/pouchdb-dump-cli/
  loadBackupFile(event) {
    if (event.target.files.length > 0) {

      console.log(event.target.files);

      Array.from(event.target.files).forEach((file: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {

          console.log('db backup, file loaded');
          this.repository.loadDatabaseDump(reader.result).then(() => {
            this.loadBackupStatus = this.loadBackupStatus + ' loaded ' + file + ', ';
            console.log('Successfully loaded ', file);
          }).catch(error => {
            console.log('Error loading backup', error);
            this.loadBackupStatus = 'Error loading backup' + error;
          });

        }
        reader.readAsText(file);

      });
    }

  }

  /**
   * function check unsynced objeccts if they exist in server and remove them from local
   */
  forceSync() {
    this.repository.checkUnsyncedIncouch()
      .then(response => {
        this.snackBar.open(response, '', { duration: 6000 });
      })
      .catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error getting document, please make sure you are connected to the internent', 'Error', { duration: 6000 });
      })
  }

  getFacilities() {
    this.loadingFacilities = true;
    this.facilityService.getFacilities()
      .then(response => {
        this.facilities = response;
        this.loadingFacilities = false;
      })
      .catch(error => {
        const snackBarRef = this.snackBar.open('Error loading facilities list, please try again.', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getFacilities()
        })
        this.loadingFacilities = false;
      });
  }

  getFacilityNameByCode(facilityCode: string) {
    if (this.facilities === undefined) { return null; }
    const facilityIndex = this.facilities.findIndex(facility => facility.code === facilityCode);
    if (facilityIndex !== -1) {
      return this.facilities[facilityIndex].name;
    } else {
      return '';
    }
  }

  refreshLogin() {
    const password: string = this.refreshForm.controls.password.value;
    const username: string = this.authService.getUser().username;

    this.authService.userLogin(username, password)
      .then(response => {

        // online auth successful, load profile
        this.loadUserProfile(username)
          .then(loadUserProfileSuccess => {
            const user = this.authService.getUser();
            this.authService.setUser(user);

            // profile loaded, get facility key
            this.authService.loadFacilityKeyRemote(user.facility)
              .then(encryptionKey => {
                const key = encryptionKey.toString()
                // load main database based on user location and begin sync
                this.repository.loadMainDb(user.facility, key);

                // get user ip
                this.authService.getClientIp()
                  .then(ip => {
                    user.ipAddress = ip;
                    this.authService.setUser(user);
                    this.authService.createUserDb(user, username, password, key);
                    this.loadSessionInfo();
                  }).catch(err => {
                    // error loading user ip, create offline db anyway
                    this.authService.createUserDb(user, username, password, key);
                    this.snackBar.open('Unable to load user ip' + err, 'Error', { duration: 6000 });
                  });
              }
              ).catch(
                err => {
                  // error loading encryption key
                  this.snackBar.open('Unable to load encryption key' + err, 'Error', { duration: 6000 });
                });
          });

      }).catch(err => {
        // error logging in to remote db
        this.snackBar.open('Error: ' + err, 'Error');
      });
  }

  loadUserProfile(name: string): Promise<User> {
    console.log('Attempting to load user profile for: ' + name);
    return new Promise((resolve, reject) => {

      this.authService.loadRemoteUser(name)
        .then(result => {
          this.snackBar.open('Successful Online login', 'Success', { duration: 6000 });
          resolve(result);
        })
        .catch(error => {
          const snackBarRef = this.snackBar.open('Sorry, error getting profile, please try again.', 'Retry');
          snackBarRef.onAction().subscribe(() => {
            console.log('Retrying');
            this.loadUserProfile(name)
          })
          reject(error);
        });
    });
  }

  notify(objectType: string): void {
    this.ref.detectChanges();
  }

  loadSessionInfo() {
    this.authService.checkUserSession()
      .then(response => {
        this.sessionInfo = response;
      })
      .catch(error => {
        this.sessionInfo = 'Error: ' + error;
        console.log(this.sessionInfo)
        const snackBarRef = this.snackBar.open('Sorry, error loading session, please try again.', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.loadSessionInfo()
        })
      });
  }

  ngOnInit() {
    this.error = '';
    this.userModel = this.authService.getUser();
    this.loadSessionInfo();
  }

  toggleProfileForm(showChangeProfile: boolean) {
    this.changeProfile = showChangeProfile;
  }

  togglePasswordForm(showChangePassword: boolean) {
    this.changePassword = showChangePassword;
  }

  editPassword(model: any) {
    if (!this.changePassForm.valid) {
      this.snackBar.open('Form is not valid.', 'Error');
      return;
    }

    // true|false there is an internet connection
    if (!navigator.onLine) {
      this.snackBar.open('Internet connection required to edit your profile.', 'Warning');
      this.changePassForm.reset();
      return;
    }

    if (model.password === model.confirm_password) {
      this.authService.userChangePassword(this.userModel.username, model.password)
        .then(response => {
          console.log('userChangePassword:', response);
          if (response['ok'] || response['ok'] === true) {
            this.snackBar.open('Successfully changed password.', 'Success', { duration: 6000 });
          } else {
            this.snackBar.open(response['message'], 'Warning');
          }

          this.changePassForm.reset();
          this.togglePasswordForm(false);

        })
        .catch(error => {
          // to be used later
          // this.error = error;
          this.snackBar.open(error['message'], 'Error');
        });
    } else {
      this.snackBar.open('Passwords do not match', 'Error');
    }

  }

  editProfile() {
    if (!navigator.onLine) {
      this.snackBar.open('Sorry. Internet connection required to change your profile.', 'Warning');
      return;
    }

    this.authService.userChangeProfile(this.userModel)
      .then(user => {
        this.userModel = user;
        this.snackBar.open('Successfully changed profile.', 'Success', { duration: 6000 });
        this.toggleProfileForm(false);
      })
      .catch(error => {
        const snackBarRef = this.snackBar.open('Error updating user profile, please try again.', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.editProfile()
        });
      });
  }

}
