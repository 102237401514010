import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from './../services/authentication.service';
import { PatientService } from './../services/patient.service';
import { Patient } from './../models/patient';
import { ShareLinks } from './../models/sharelinks';
import { ChatService } from './../services/chat.service';
import { Chat } from './../models/chat';

@Component({
  selector: 'psoc-app-chat',
  templateUrl: 'chat.component.html'
})
export class ChatComponent implements OnInit {

  patient: Patient;
  patientName: string;
  patientChats = new Array<Chat>();
  // chats = new Array<Chat>();
  sharelinks = new Array<ShareLinks>()
  sharelinksIds = new Array<any>();

  chatForm: FormGroup;
  newChat = new Chat();

  displayChat = null;

  constructor(
    public authService: AuthenticationService,
    private patientService: PatientService,
    private chatService: ChatService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.chatForm = this.formBuilder.group({
      'message': ['', [Validators.required, Validators.maxLength(255)]]
    });
  }

  // getting the selected patient data
  getPatientData() {
    this.patientService.getSinglePatient(+(this.route.snapshot.params['id']))
      .then(patient => {
        this.patient = patient;
        this.patientName = patient.firstname;

        // call getchats function
        this.getChats(+(this.route.snapshot.params['id']));
      })
      .catch(error => {
        console.log(error);
        this.snackBar.open('Error loading patient data', 'Error');
      });
  }

  // functon to reset the form
  cancel() {
    this.newChat = new Chat();
    this.chatForm.reset();
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.location.back();
    // if (this.route.snapshot.params['id']) {
    //   // navigate to the this patients narrative
    //   this.router.navigate(['/narrative/' + this.route.snapshot.params['id']]);
    // } else {
    //   // else navigate to all the patients list
    //   this.router.navigate(['/patients']);
    // }
  }

  // toggle between chats to display all chats for sharelink
  toggleChat(sharelinkId: string, toggle: boolean): void {
    if (this.displayChat === sharelinkId && toggle) {
      this.displayChat = null;
    } else {
      this.displayChat = sharelinkId;
    }
  }

  /* function to add the chat */
  addChat(sharelinkId: string) {
    /* set the chat field patientId to be the selected patient id */
    this.newChat.patientId = +(this.patient._id);
    this.newChat.sharelinkId = sharelinkId;

    /* check if the patient is shared */
    if (this.patient.sharedFacility !== undefined) {
      this.newChat.sharedFacility = this.patient.sharedFacility.slice();
    }

    /* add the new chat to the db */
    this.chatService.addChat(this.newChat)
      .then(newChat => {
        /* for display of the added chat to the ui */
        if (newChat.patientId === +(this.patient._id)) {
          this.patientChats.push(newChat);
        }
        /* clear the form to prevent edit of previous chat */
        this.cancel();
        this.snackBar.open('Message sent', 'Success', { duration: 6000 });
      })
      .catch(err => this.snackBar.open('Message Not sent', 'Error', { duration: 6000 }))
  }

  // get the chats
  getChats(patientId: number) {
    this.chatService.getPatientChats(patientId)
      .then(chats => {
        // this patients chats
        this.patientChats = chats;

        // loop each chat and get different sharelink
        this.patientChats.forEach((chat: Chat) => {
          // push every sharelink id in the array
          if (!this.sharelinksIds.includes(chat.sharelinkId)) {
            // add this id
            this.sharelinksIds.push(chat.sharelinkId)
          }
        })

        // call the getsharelinkdata function
        this.getSharelinksData()
      }).catch(error => {
        console.log('getChats error: ', error);
        this.snackBar.open('Error loading chat messages', 'Error');
      })
  }

  // get the sharelinks ids
  getSharelinksData() {
    // loop ShareLinks id to get the data
    this.sharelinksIds.forEach((_id: string) => {
      this.patientService.getSingleSharedLinkData(_id)
        .then(sharelink => {
          // push the sharelink data to array
          this.sharelinks.push(sharelink)
        })
        .catch(err => {
          console.log('Error loading sharelink data in chat component:', err);
          this.snackBar.open('Error getting sharelink', 'Error');
        })
    });
  }

  ngOnInit() {
    // get the patient data
    this.getPatientData();
  }
}
