import { Pipe, PipeTransform } from '@angular/core';
import { Mediator } from '../models/mediators';

@Pipe({ name: 'getUserNames' })
export class GetUserNames implements PipeTransform {
  transform(username: string, users: Mediator[]) {
    let currenrentUserName = username;
    if (!users) { return currenrentUserName } // consider specialist side dont have list of mediators
    if (users.length > 0 && (username !== '' || username !== null)) {
      const user = users.find(u => u.username === username);
      if (user) { currenrentUserName = user.firstName + ' ' + user.lastname; }
    }
    return currenrentUserName;
  }
}
