import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material';
import { RepositoryService } from '../../services/repository.service';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../models/patient';
import { ChatService } from '../../services/chat.service';
import { Chat } from '../../models/chat';
import { ShareLinks } from '../../models/sharelinks';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'psoc-specialist-chat',
  templateUrl: 'specialist-chat.component.html'
})
export class SpecialistChatComponent implements OnInit {

  sharelink: ShareLinks;
  patient: Patient;
  patientId: number;
  patientChat = new Array<Chat>();

  chatForm: FormGroup;
  newChat = new Chat();

  // sharelinkid
  sharelinkId: string;

  loadingPatientData: Boolean = false;

  constructor(
    private repositoryService: RepositoryService,
    private patientService: PatientService,
    private chatService: ChatService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    // get the sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id'];

    this.chatForm = this.formBuilder.group({
      'message': ['', [Validators.required, Validators.maxLength(255)]],
      'specialistName': ['', [Validators.required, Validators.maxLength(255)]],
      'specialistEmail': ['', [Validators.required, Validators.maxLength(255)]]
    });
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  /**
   * getting the selected patient data
   */
  getPatientData() {
    this.loadingPatientData = true

    // get the sharelink details
    this.patientService.getSingleSharedLinkData(this.sharelinkId).then(sharelink => {
      this.sharelink = sharelink;
      this.patientId = sharelink.patientId;

      // get user info to set global
      const user = new User({
        username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
        facility: sharelink.createFacility,
        userRole: 'specialist'
      })
      // setting the specialist details to the auth
      this.authService.getClientIp()
        .then(ip => {
          user.ipAddress = ip;
          this.authService.setUser(user);
        })
        .catch(err => {
          console.log('Error getting ip address: ' + err)
          this.authService.setUser(user);
        });

      // get chats for this sharelink
      this.getChats(this.sharelinkId);

    }).catch(error => {
      console.log('Error loading sharelink data in specialist-chat component:', error);
      this.loadingPatientData = false;
      this.snackBar.open('Error loading sharelink data!', 'Error');
    });
  }

  // functon to reset the form
  cancel() {
    this.newChat = new Chat();
    this.chatForm.controls['message'].reset();
  }

  /**
   * get chats for the provided sharelink
   * @param _id string: sharelink id
   */
  getChats(_id: string) {
    this.chatService.getSharelinkChats(_id)
      .then(chats => {
        this.patientChat = chats;
        this.loadingPatientData = false;
      })
      .catch(error => {
        console.log(error);
        this.loadingPatientData = true;
        this.snackBar.open('Error loading chat messages for this patient.', 'Error');
      });
  }

  /* function to save a chat */
  addChat() {
    this.newChat.patientId = this.patientId;
    this.newChat.sharelinkId = this.sharelinkId;
    this.newChat.userName = this.sharelink.assignedMediatorUsername;
    this.newChat.specialistuserName = this.sharelink.specialistInfo.name;
    this.newChat.specialistEmail = this.sharelink.specialistInfo.email;

    /* check if the patient is shared */
    if (this.sharelink.sharedFacility !== undefined) {
      this.newChat.sharedFacility = this.sharelink.sharedFacility.slice();
    }

    /* set current username */
    const currentUser = this.authService.getUser();
    currentUser.username = this.sharelink.specialistInfo.name;

    /* add the chat to the db */
    this.chatService.addChat(this.newChat)
      .then(res => {
        /* push the added chat to the display chat */
        this.patientChat.push(this.newChat);

        /* update sharelink with new specialist name */
        this.updateShareLink();

        /* clear the form after submit */
        this.cancel();

        this.snackBar.open('Message Sent', 'Success', { duration: 6000 });
      }).catch(err => {
        this.snackBar.open('Message Not Sent', 'Error', { duration: 6000 });
        console.log('Error' + err)
      })
  }

  // update sharelink
  updateShareLink() {
    this.patientService.updateShareLink(this.sharelink)
      .then(sharelink => this.sharelink = sharelink)
      .catch(err => console.log('Error Updating shareLink: ' + err))
  }

  ngOnInit() {
    // call the db connection
    this.repositoryService.loadSpecialistDb(this.sharelinkId);

    // get the patient data
    this.getPatientData();
  }
}
