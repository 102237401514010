import { Component, Input, ViewChild, ViewContainerRef, EventEmitter, Output, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { ObstetricBlockEditComponent } from '../block-edit.component';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { GestationAgeByCrownrumpLength, GestationAgeByBPD, GestationAgeByHC } from '../../../../models/narrative-types';
import { HeadAndSpineBlock, ObstetricNarrative } from '../../../../models/obstetric-narrative';
import { ValidationService } from '../../../../validation/validation.service';

@Component({
  selector: 'psoc-head-spine-nurse-edit',
  templateUrl: './head-spine-nurse-edit.component.html'
})

export class HeadAndSpineNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
  _blockData: HeadAndSpineBlock;
  @Input() firstTrimester: Boolean;
  @Output() blockDataChange: EventEmitter<HeadAndSpineBlock> = new EventEmitter<HeadAndSpineBlock>();
  @Input() get blockData() { return this._blockData }
  set blockData(blockData: HeadAndSpineBlock) {
    if (blockData) { this._blockData = blockData } else { this._blockData = new HeadAndSpineBlock(); }
    this.blockDataChange.emit(this._blockData);
    this.getAttachmentFilesCount(this._blockData.attachmentIds);
  };
  @Input() narrativeData: ObstetricNarrative;

  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
  dialogRef: MatDialogRef<any>;

  @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService,
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

    this.form = this.fb.group({
      blockStatus: ['', [Validators.required]],
      crownRumpLength: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByCrownrumpLength))],
      biparietalDiameter: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByBPD))],
      headCircumference: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByHC))],
      nurseNotes: ['']
    });

  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
  }

}
