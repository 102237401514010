import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { NarrativeService } from '../../../services/narrative.service';
import { RepositoryService } from '../../../services/repository.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { BlocksOrderEnum, FoetusBlocks, ObstetricNarrative, scanningIndication } from '../../../models/obstetric-narrative';
import { User } from '../../../models/user';
import { Patient } from '../../../models/patient';
import { ShareLinks } from '../../../models/sharelinks';
import {
  SonographerBlocks, SonographerBlockNumberOfFoetus, SonographerBlockMaternalAnatomy, SonographerFoetusBlock,
  SonographerBlockFoetalLie, SonographerBlockFoetalPresentation, SonographerBlockHeart, SonographerBlockPlacenta,
  SonographerBlockFoetalLimbs, SonographerBlockAmnioticFluid, SonographerBlockFoetalAbdomen, SonographerBlockHeadAndSpine,
  SonographerBlockRecommendations
} from '../../../models/obstetric-sharelink';

@Component({
  selector: 'psoc-scans-view',
  templateUrl: 'scans-view.component.html'
})

export class ScansViewComponent implements OnInit {
  narrative: ObstetricNarrative;
  sharelinkId: string;
  shareLink: ShareLinks;
  patient: Patient;
  shareLinkBackup: ShareLinks;
  foetuses: FoetusBlocks[];
  blocksOrder = BlocksOrderEnum;
  selectedFoetus: number = 0;
  showBlock: number = 1;
  firstTrimester: Boolean = false;
  foetusBlocks: number = 8;
  maternalBlockPosition: number = 2;
  sonographerBlockRecommendationsPosition: number = 3;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private repository: RepositoryService,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private authService: AuthenticationService
  ) {
    this.sharelinkId = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    // load connection to sharelinks dbs
    this.repository.loadSpecialistDb(this.sharelinkId);
    this.getSharelink();

    const block = this.route.snapshot.queryParamMap.get('block');
    const foetus = this.route.snapshot.queryParamMap.get('foetus');
    if (foetus) { this.selectedFoetus = +foetus; }
    if (block) { this.showBlock = +block }
  }

  /** function to initilise missing blocks of a sharelink */
  initSonographerBlocks() {
    if (!this.shareLink.sonographerBlocks) {
      this.shareLink.sonographerBlocks = new SonographerBlocks();
    }

    if (!this.shareLink.sonographerBlocks.numberOfFoetusBlock) {
      this.shareLink.sonographerBlocks.numberOfFoetusBlock = new SonographerBlockNumberOfFoetus();
    }

    if (!this.shareLink.sonographerBlocks.maternalAnatomyBlock) {
      this.shareLink.sonographerBlocks.maternalAnatomyBlock = new SonographerBlockMaternalAnatomy();
    }

    if (!this.shareLink.sonographerBlocks.sonographerBlockRecommendations) {
      this.shareLink.sonographerBlocks.sonographerBlockRecommendations = new SonographerBlockRecommendations();
    }

    if (!Array.isArray(this.shareLink.sonographerBlocks.foetuses) || this.shareLink.sonographerBlocks.foetuses.length === 0) {
      this.shareLink.sonographerBlocks.foetuses = [];
      for (let i = 0; i < this.narrative.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus; i++) {
        this.shareLink.sonographerBlocks.foetuses.push(new SonographerFoetusBlock());
      }

      for (let i = 0; i < this.narrative.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus; i++) {
        if (!this.shareLink.sonographerBlocks.foetuses[i].foetalLieBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].foetalLieBlock = new SonographerBlockFoetalLie();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].foetalPresentationBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].foetalPresentationBlock = new SonographerBlockFoetalPresentation();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].heartBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].heartBlock = new SonographerBlockHeart();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].placentaBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].placentaBlock = new SonographerBlockPlacenta();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].amnioticFluidBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].amnioticFluidBlock = new SonographerBlockAmnioticFluid();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].foetalLimbsBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].foetalLimbsBlock = new SonographerBlockFoetalLimbs();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].foetalAbdomenBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].foetalAbdomenBlock = new SonographerBlockFoetalAbdomen();
        }
        if (!this.shareLink.sonographerBlocks.foetuses[i].headAndSpineBlock) {
          this.shareLink.sonographerBlocks.foetuses[i].headAndSpineBlock = new SonographerBlockHeadAndSpine();
        }
      }

    }
  }

  /**
   * function to get single narrative
   */
  getSharelink() {
    this.patientService.getSingleSharedLinkData(this.sharelinkId)
      .then((shareLink: ShareLinks) => {
        if (shareLink.published) {
          // navigate to root path
          this.router.navigate(['/' + shareLink._id]);
          return;
        }

        this.shareLink = shareLink;
        this.patient = shareLink.patient;

        this.getNarrative(shareLink.narrativeId); // get narrative

        this.getCurrentUser(shareLink); // get user

        this.shareLinkBackup = this.narrativeService.deepCopy(shareLink); // keep a backup
      })
      .catch(err => {
        console.log('Error getting sharelink:', err);
        this.snackBar.open('Error fetching sharelink data.', 'Error', { duration: 6000 });
      });
  }

  /**
   * function to get single patient narrative
   * @param narrativeId narrative id
   */
  getNarrative(narrativeId) {
    this.narrativeService.getSingleNarrative(narrativeId)
      .then((narrative: any) => {
        if (narrative.scanIndication === scanningIndication[0]) {
          this.foetusBlocks = 7;
          this.firstTrimester = true;
          this.maternalBlockPosition = 3;
          this.sonographerBlockRecommendationsPosition = 4;
        }
        this.narrative = narrative;
        this.foetuses = this.narrative.sonographyBlocks.foetuses;
        this.initSonographerBlocks();
      })
      .catch(err => {
        console.log('Error getting Narrative:', err);
        this.snackBar.open('Error fetching narrative data.', 'Error', { duration: 6000 });
      });
  }

  /**
   * function to get current user
   */
  getCurrentUser(sharelink) {
    // get user info to set global
    const user = new User({
      username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
      facility: sharelink.createFacility,
      userRole: 'specialist'
    });

    // setting the specialist details to the auth
    this.authService.getClientIp()
      .then(ip => {
        user.ipAddress = ip;
        this.authService.setUser(user);
      })
      .catch(err => {
        console.log('Error getting ip address: ', err)
        this.authService.setUser(user);
      });
  }

  /**
   * function to navigate to previous block
   */
  previousBlock() {
    console.log('# of Foetuses', this.foetuses.length);
    this.updateSharelinkOnNavigation(); // call function to update as you go

    if (this.firstTrimester && (this.showBlock === BlocksOrderEnum.heartBlock)) {
      this.showBlock = BlocksOrderEnum.heartBlock - 1;
    }
    if (this.showBlock === BlocksOrderEnum.foetalLieBlock && this.foetuses.length > 1 && this.selectedFoetus > 0) {
      this.selectedFoetus = this.selectedFoetus - 1;
      this.showBlock = BlocksOrderEnum.maternalAnatomyBlock;
      return;
    } else if (this.showBlock === BlocksOrderEnum.numberOfFoetusBlock && this.selectedFoetus === 0) {
      return;
    }
    this.showBlock--;
  }

  /**
   * function to change/view blocks on selection change
   * @param blockIndex
   */
  blockSelectionChange(blockIndex: number) {
    console.log('Block Index', blockIndex);
    this.updateSharelinkOnNavigation(); // call function to update as you go

    if (blockIndex === BlocksOrderEnum.numberOfFoetusBlock) {
      this.showBlock = BlocksOrderEnum.numberOfFoetusBlock;
    } else if (blockIndex === ((this.foetuses.length * this.foetusBlocks) + this.maternalBlockPosition)) {
      this.showBlock = BlocksOrderEnum.maternalAnatomyBlock;
    } else if (blockIndex === ((this.foetuses.length * this.foetusBlocks) + this.sonographerBlockRecommendationsPosition)) {
      this.showBlock = BlocksOrderEnum.sonoRecommendationsBlock;
    } else if (this.foetuses.length === 1) {
      this.showBlock = blockIndex;
      this.selectedFoetus = 0;
    } else {
      this.showBlock = ((blockIndex - 2) % this.foetusBlocks) + 2;
      this.selectedFoetus = (blockIndex - (this.showBlock)) / this.foetusBlocks;
    }
  }

  /**
   * function to navigate to next block
   */
  nextBlock() {
    this.updateSharelinkOnNavigation(); // call function to update as you go

    if (this.firstTrimester && (this.showBlock === BlocksOrderEnum.foetalLieBlock)) {
      this.showBlock = BlocksOrderEnum.foetalLieBlock + 1;
    }
    if (this.showBlock === BlocksOrderEnum.amnioticFluidBlock && this.foetuses.length > 1
      && this.selectedFoetus !== this.foetuses.length - 1) {
      this.selectedFoetus = this.selectedFoetus + 1;
      this.showBlock = BlocksOrderEnum.foetalLieBlock;
      return;
    } else if (this.showBlock === BlocksOrderEnum.amnioticFluidBlock && this.selectedFoetus === this.foetuses.length - 1) {
      this.showBlock = BlocksOrderEnum.maternalAnatomyBlock;
      return;
    } else if (this.showBlock === BlocksOrderEnum.maternalAnatomyBlock && this.selectedFoetus === this.foetuses.length - 1) {
      this.showBlock = BlocksOrderEnum.sonoRecommendationsBlock
      return;
    } else if (this.showBlock === BlocksOrderEnum.sonoRecommendationsBlock && this.selectedFoetus === this.foetuses.length - 1) {
      return;
    }
    this.showBlock++;
  }

  /**
   * save as you go function, when user navigates to the next block
   */
  updateSharelinkOnNavigation() {
    // check if data has changed
    if (this.narrativeService.deepCompare(this.shareLink, this.shareLinkBackup)) { return; }

    // call sharelink update function
    this.updateSharelink();
  }

  /**
   * function to update sharelink
   */
  updateSharelink() {
    this.patientService.updateShareLink(this.shareLink)
      .then(sharelink => {
        this.shareLink = sharelink;
        this.shareLinkBackup = this.narrativeService.deepCopy(sharelink);  // update sharelink backup

        // add an event of report saved draft
        this.repository.addEvents('obUSGReport', sharelink._id, 'draft', this.authService.getUser().username,
          this.authService.getUser().ipAddress);
        this.snackBar.open('Draft Report saved successfully', 'Okay', { duration: 2000 });
      })
      .catch(error => {
        console.log('Error updating sharelink', error);
        if (error.status === 409) {
          this.snackBar.open('Error Updating report, Please refresh the page', 'Error', { duration: 6000 });
        } else {
          this.snackBar.open('Error Updating sharelink, check your internet connection', 'Error', { duration: 6000 });
        }
      });
  }

  /**
   * get the gestation age
  */
  getGestationAge() {
    return() => this.narrativeService.calculateGestationAge(this.shareLink.ultrasoundRecord, this.shareLink.sonographerBlocks);
  }

  /**
   * Take us back to the initial view
   */
  previewSharelink() {
    this.router.navigate(['/' + this.shareLink._id]);
  }
}
