import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../services/globals';

@Component({
  selector: 'recover',
  templateUrl: 'recover.component.html'
})
export class RecoverComponent implements OnInit {
  recoverForm: FormGroup;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private globals: Globals
  ) {

    // set current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }

  ngOnInit() {
    this.recoverForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    this.error = '';
  }

  recover() {
    if (this.recoverForm.valid) {
      // Accounts.forgotPassword({
      //   email: this.recoverForm.value.email
      // }, (err) => {
      //   if (err) {
      //     this.zone.run(() => {
      //       this.error = err;
      //     });
      //   } else {
      //     this.router.navigate(['/']);
      //   }
      // });
    }
  }
}
