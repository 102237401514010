import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { RepositoryObserver } from './repository-observer';
import { AuthenticationService } from './authentication.service';
import { Patient } from '../models/patient';
import { UltrasoundRecord } from '../models/obstetric-sharelink';
import { Mediator } from '../models/mediators';
import { RequestShareLinks, ShareLinks, SpecialistInfo } from '../models/sharelinks';
import { BehaviorSubject } from 'rxjs';
import { Narrative } from '../models/narrative';
import { UUID } from 'angular2-uuid';
import { Facility } from '../models/facility';
import { NarrativeInterface, NarrativeEnumTypes } from '../models/narrative-types';
import { ObstetricNarrative } from '../models/obstetric-narrative';
import { InsuranceClient } from '../models/insurance-client';
import { Enquiry } from '../models/enquiry';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BlockSegment } from '../models/narrative-block';

@Injectable()
export class PatientService implements RepositoryObserver {
  // store all patients
  private patients: Patient[];
  private patientsLoaded: boolean = false;

  private loadingPatients: boolean = false;

  private duplicatePatients: Patient[] = [];
  // store all client members
  private clients: InsuranceClient[];
  private clientsLoaded: boolean = false; // remove this cache if we start experiencing memory issues

  // store all enquiries
  private enquiries: Enquiry[];
  private enquiriesLoaded: boolean = false;

  // store specific patient
  public currentPatient: BehaviorSubject<Patient> = new BehaviorSubject(new Patient());
  private patientLoaded: boolean = false;

  // store specific sharelink
  public currentSharelink: BehaviorSubject<ShareLinks> = new BehaviorSubject(new ShareLinks());
  private currentSharelinkLoaded: boolean = false;

  // cache all sharelinks
  private sharelinks: ShareLinks[];
  private sharelinksLoaded: boolean = false;
  public hideNav: Boolean = false;

  private specialistLoaded: boolean = false;
  private specialistList: Mediator[];

  env = environment;

  constructor(
    private http: HttpClient,
    private repository: RepositoryService,
    private authService: AuthenticationService) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === Patient.type) {
      this.patientsLoaded = false;
      this.getAllPatients();
    } else if (objectType === Enquiry.type) {
      this.enquiriesLoaded = false;
      this.getAllEnquiries();
    } else if (objectType === ShareLinks.type) {
      this.sharelinksLoaded = false;
      this.getAllShareLinks();
    }
  }

  /** Return list of patients, will return cached list if available, or call loadAllPatients */
  getAllPatients(usePatientCache = true): Promise<Patient[]> {
    return new Promise((resolve, reject) => {
      if (this.patientsLoaded && usePatientCache) {
        console.log('already loaded patients')
        resolve(this.patients);
      } else {
        this.loadAllPatients()
          .then((allPatients) => {
            this.patients = allPatients;
            this.patientsLoaded = true;
            resolve(this.patients);
          })
          .catch(error => {
            console.error('An error occurred getting patients', error);
            reject(error);
          })
      }
    });
  }

  /**
   * Get the registration numbers duplicate patients
   */
  getDuplicatePatientIds(patients: Patient[]) {
    let duplicateIds: string[] = (patients
      .map(p => p.idnumber)
      .map((p, i, final) => final.indexOf(p) !== i && i)
      .filter(obj => patients[obj])
      .map(p => patients[p].idnumber))
      .reduce((unique, item) =>
        unique.includes(item) ? unique : [...unique, item], []);
    return duplicateIds;
  }

  /**
   * get the duplicate patients
   */
  loadDuplicatePatients(): Promise<Patient[]> {
    return new Promise((resolve, reject) => {
      this.loadAllPatients()
        .then(patients => {
          // loading all patients
          this.loadingPatients = false;

          // get the duplicate ids
          let duplicateIds: string[] = this.getDuplicatePatientIds(patients);

          // filter the duplicate patients
          let duplicates = patients.filter(patient => duplicateIds.includes(patient.idnumber));
          this.duplicatePatients = duplicates;

          resolve(this.duplicatePatients);
        }).catch(error => {
          // error fetching patients
          console.log('Error loading patients.', error);
          this.loadingPatients = false;
          resolve(error);
        });
    });
  }

  /** Returns all patients from DB */
  loadAllPatients(): Promise<Patient[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Patient.type)
        .then((result) => {
          const patients: Patient[] = result.docs.map((doc: any) => this.mapObjectToPatient(doc));
          resolve(patients as Patient[]);
        })
        .catch(error => {
          console.error('An error occurred loading patients', error);
          reject(error);
        });

    });
  }

  /** loads all patients objects from that have given registration number */
  findPatientsByRegNo(regNo: string): Promise<Patient[]> {
    return this.repository.fetchObjectsBy(Patient.type, Patient.fields, 'idnumber', regNo, ['_id'])
      .then((result) => {
        const attachments: Patient[] = result.docs.map((doc: any) => this.mapObjectToPatient(doc));
        return (attachments as Patient[]);
      })
  }

  private mapObjectToPatient(object: any): Patient {
    let patient: Patient = new Patient();
    return patient = { ...object };
  }

  /** Return list of enquiries, will return cached list if available, or call loadAllEnquiries */
  getAllEnquiries(useEnquiriesCache = true): Promise<Enquiry[]> {
    return new Promise((resolve, reject) => {
      if (this.enquiriesLoaded && useEnquiriesCache) {
        console.log('already loaded enquiries')
        resolve(this.enquiries);
      } else {
        this.loadAllEnquiries()
          .then((allEnquiries) => {
            this.enquiries = allEnquiries;
            this.enquiriesLoaded = true;
            resolve(this.enquiries);
          })
          .catch(error => {
            console.error('An error occurred loading enquiries', error);
            reject(error);
          })
      }
    });
  }

  /** Returns all enquiries from DB */
  loadAllEnquiries(): Promise<Enquiry[]> {
    return new Promise((resolve, reject) => {

      this.repository
        .fetchObjects(Enquiry.type)
        .then((result) => {
          const enquiries: Enquiry[] = result.docs.map((doc: any) => this.mapObjectToEnquiry(doc));
          resolve(enquiries as Enquiry[]);
        })
        .catch(error => {
          console.error('An error occurred loading enquiries', error);
          reject(error);
        });

    });
  }

  public mapObjectToEnquiry(object: any): Enquiry {
    let enquiry: Enquiry = new Enquiry();
    return enquiry = { ...object };
  }

  /** get an individual enquiry object from DB */
  getSingleEnquiry(_id: string): Promise<Enquiry> {
    return new Promise((resolve, reject) => {

      this.repository.fetchObject(Enquiry.type, _id, Enquiry.fields, ['_id'])
        .then(result => {
          const enquiry: Enquiry = result.docs.map((doc: any) => this.mapObjectToEnquiry(doc))[0];
          resolve(enquiry);
        }).catch(error => {
          console.error('An error occurred loading enquiry: ', error);
          reject(error);
        });

    });
  }

  updateEnquiry(enquiry: Enquiry): Promise<Enquiry> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(enquiry, Enquiry.type)
        .then((pouchObject) => {
          const updatedEnquiry: Enquiry = JSON.parse(JSON.stringify(pouchObject));
          this.enquiriesLoaded = false; // mark cache as invalid forcing the next request to load afresh
          resolve(updatedEnquiry);
        })
        .catch(error => {
          console.error('An error occurred updating enquiry', error);
          reject(error);
        });
    });
  }

  /** Return list of clients, will return cached list if available, or call loadAllClients */
  getAllClients(ES_API: string, useClientsCache = true): Promise<InsuranceClient[]> {
    return new Promise((resolve, reject) => {
      if (this.clientsLoaded && useClientsCache) {
        console.log('already loaded enquiries')
        resolve(this.clients);
      } else {
        this.loadAllClients(ES_API)
          .then((allClients) => {
            this.clients = allClients;
            this.clientsLoaded = true;
            resolve(this.clients);
          })
          .catch(error => {
            console.error('An error occurred loading enquiries', error);
            reject(error);
          })
      }
    });
  }

  /** Returns all insurance clients from DB via API */
  loadAllClients(ES_API: string): Promise<InsuranceClient[]> {
    return new Promise((resolve, reject) => {
      const headers = { 'Authorization': ES_API };
      const URL = this.env.ES_API.apiUrl + `?size=500000`;
      this.http.get(URL, { headers: headers }).subscribe((res: any) => {
        const clients: InsuranceClient[] = res.hits.hits.map(r => this.mapObjectToInsuranceClient(r._source.doc));
        resolve(clients);
      }, (error: HttpErrorResponse) => {
        console.log('Patient Service An error occurred:', error);
        reject('Error getting records');
      });
    });
  }

  /**
   * function get filtered list of clients by providing params
   * @param {string} firstName client first name
   * @param {string} lastName client second name
   * @param {string} otherName client other name 3rd name
   * @param {string} regNumber client registration number
   * @param {string} schemeName client scheme name
   */
  getFilteredClients(firstName: string = '', lastName: string = '', otherName: string = '', regNumber: string = '',
    schemeName: string = '', ES_API: string) {
    return new Promise((resolve, reject) => {
      const URL = this.env.ES_API.apiUrl + '?size=100';
      const headers = { 'Authorization': ES_API };
      const query = this.returnQuery(firstName, lastName, otherName, regNumber, schemeName);
      this.http.post(URL, query, { headers: headers }).subscribe((res: any) => {
        const max_score = regNumber !== '' ? res.hits.max_score : 1;
        const records = res.hits.hits.filter(r => r._score >= max_score).map(r => this.mapObjectToInsuranceClient(r._source.doc));
        resolve(records);
      }, (error: HttpErrorResponse) => {
        console.log('Patient Service An error occurred:', error);
        reject('Error getting records');
      });
    });
  }

  /**
   * return elastic search find query
   * @param {string} firstName client first name
   * @param {string} lastName client second name
   * @param {string} otherName client other name 3rd name
   * @param {string} regNumber client registration number
   * @param {string} schemeName client scheme name
   */
  returnQuery(firstName: string = '', lastName: string = '', otherName: string = '', regNumber: string = '', schemeName: string = '') {
    let query: any = {};
    if ((firstName !== '' && lastName !== '') || (firstName !== '' && otherName !== '') || (lastName !== '' && otherName !== '')) {
      query = {
        'query': {
          'bool': {
            'should': [
              {
                'match': {
                  'doc.firstName': firstName
                }
              },
              {
                'match': {
                  'doc.lastName': lastName
                }
              },
              {
                'match': {
                  'doc.otherNames': otherName
                }
              }
            ]
          }
        }
      }
    } else {
      query = {
        'query': {
          'bool': {
            'should': [
              {
                'multi_match': {
                  'query': firstName !== '' ? firstName : lastName !== '' ? lastName : otherName,
                  'fields': ['doc.firstName', 'doc.lastName', 'doc.otherNames']
                }
              }
            ]
          }
        }
      }
    }

    // registration number find query
    const regQuery = {
      'match_phrase_prefix': {
        'doc.registrationNumber': regNumber
      }
    };

    // scheme-name find query
    const schemeQuery = {
      'match_phrase_prefix': {
        'doc.schemeName': schemeName
      }
    }

    query.query.bool.should.push(regQuery, schemeQuery);

    return query;
  }

  /**
   * return a insurance client object
   */
  private mapObjectToInsuranceClient(object: any): InsuranceClient {
    let client: InsuranceClient = new InsuranceClient();
    return client = { ...object };
  }

  /**
   * get single patient from the database
   */
  getSinglePatient(id: number): Promise<Patient> {
    return new Promise((resolve, reject) => {

      if (this.patientLoaded && this.currentPatient.getValue().id === id) {
        console.log('Patient already loaded id: ' + this.currentPatient.getValue().id);
        resolve(this.currentPatient.getValue());
      } else {
        this.loadSinglePatient(id)
          .then((patient) => {
            this.currentPatient.next(patient);
            this.patientLoaded = true;
            resolve(this.currentPatient.getValue());
          })
          .catch(error => {
            console.error('An error occurred getting patient: ', error);
            this.patientLoaded = false;
            reject(error);
          });
      }

    });
  }

  loadSinglePatient(id: number): Promise<Patient> {
    return new Promise((resolve, reject) => {

      this.repository.fetchObject(Patient.type, String(id), Patient.fields, ['_id'])
        .then(result => {
          const patient: Patient = result.docs.map((doc: any) => this.mapObjectToPatient(doc))[0];
          resolve(patient);
        }).catch(error => {
          console.error('An error occurred loading patient: ', error);
          reject(error);
        });

    });
  }

  updatePatient(patient: Patient): Promise<Patient> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(patient, Patient.type)
        .then((pouchObject) => {
          const updatedPatient: Patient = JSON.parse(JSON.stringify(pouchObject));
          this.patientsLoaded = false; // mark cache as invalid forcing the next request to load afresh
          resolve(updatedPatient);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /* updating all the objects of a related case */
  referCase(patient: number, sharedFacility: string[]): Promise<string> {
    return new Promise((resolve, reject) => {
      this.updatePatientObjects('attachment', patient, sharedFacility).then(attachments => {
        this.updatePatientObjects('sharelinks', patient, sharedFacility, Databases.sharelinksDb).then(sharelinks => {
          this.updatePatientObjects('appointment', patient, sharedFacility, Databases.appointmentsDb).then(appointments => {
            this.updatePatientObjects('chat', patient, sharedFacility, Databases.chatsDb).then(chats => {
              resolve('Objects Updated successfully!');
            }).catch(err => reject('Error updating Chats'));
          }).catch(err => reject('Error updating Appointments'));
        }).catch(err => reject('Error updating sharelinks'));
      }).catch(err => reject('Error updating Attachments'));
    });
  }

  /* get all documents of patient and update them with shared facility */
  updatePatientObjects(objectType: string, patientId: number, sharedFacility, database = Databases.mainDb): Promise<string> {
    console.log('Updating... ' + objectType + ' for ' + patientId);
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(objectType, patientId, database)
        .then((result) => {
          console.log('Updating ' + result.docs.length + ' ' + objectType + ' for this patient')
          result.docs.forEach(element => {
            /* update the document */
            if (!element.sharedFacility) {

              /* updating the current doc with sharedFacility field */
              Object.assign(element, { sharedFacility: sharedFacility });

              /* save the object to the db */
              this.repository.updateObject(element, objectType, database)
                .then((pouchObject) => {
                  resolve(JSON.stringify(pouchObject));
                  console.log('Updated and Saved successfully');
                })
                .catch(err => {
                  console.log('An error occurred while Saving' + err)
                  reject(err);
                });
            } else {
              element.sharedFacility = sharedFacility;

              /* save the object to the db */
              this.repository.updateObject(element, objectType, database)
                .then((pouchObject) => {
                  resolve(JSON.stringify(pouchObject));
                  console.log('Updated and Saved successfully');
                })
                .catch(err => {
                  console.log('An error occurred while Saving' + err)
                  reject(err);
                });
              resolve('Updated Document');
            }
          });
          resolve('Patient ');
        }).catch(err => {
          console.error('An error occurred while updating ' + objectType, err);
          reject(err);
        });

    });
  }

  // deletes all patient related objects then deletes the patient objectw
  deletePatient(patient: Patient): Promise<string> {
    // delete all objects for this patient
    return new Promise((resolve, reject) => {

      this.deletePatientObjects('attachment', patient.id).then(attDeleted => {
        this.deletePatientObjects('narrative', patient.id).then(narrDeleted => {
          this.deletePatientObjects('sharelinks', patient.id, Databases.sharelinksDb).then(sharDeleted => {
            this.deletePatientObjects('chat', patient.id, Databases.chatsDb).then(chatDeleted => {
              this.deletePatientObjects('appointment', patient.id, Databases.appointmentsDb).then(apptDeleted => {

                // delete patient
                console.log('Deleting patient')
                this.repository.deleteObject(patient)
                  .then(() => { resolve('patient deleted') })
                  .catch(error => {
                    console.error('An error occurred deleting patient', error);
                    reject(error);
                  });

              })
            })
          })
        })
      }).catch(error => {
        console.error('An error occurred deleting patient objects', error);
        reject(error);
      });

    });
  }

  // helper function to delete objects of a given type for a specific patient
  deletePatientObjects(objectType: string, patientId: number, database = Databases.mainDb): Promise<string> {
    console.log('Deleting ' + objectType + ' for ' + patientId);
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(objectType, patientId, database)
        .then((result) => {
          console.log('Deleting ' + result.docs.length + ' ' + objectType + ' for this patient')
          result.docs.forEach(element => {
            this.repository.deleteObject(element, database);
          });
          resolve('Patient ');
        }).catch(error => {
          console.error('An error occurred while deleting ' + objectType, error);
          reject(error);
        });

    });
  }

  // Mediators
  addMediator(mediator: Mediator): Promise<Mediator> {
    return new Promise((resolve, reject) => {

      mediator._id = new Date().getTime().toString();
      delete (mediator._rev);

      this.repository.updateObject(mediator, Mediator.type, Databases.mediatorsDb)
        .then(pouchObject => {
          const updatedMediator: Mediator = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedMediator);
        })
        .catch(error => {
          console.error('An error occurred adding mediator', error);
          reject(error);
        });

    });

  }

  /*
  * get all mediators function
  */
  getAllMediators(): Promise<Mediator[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Mediator.type, Databases.mediatorsDb)
        .then((result) => {
          const mediators: Mediator[] = result.docs.map((doc: any) => this.mapObjectToMediator(doc));
          resolve(mediators);
        })
        .catch(error => {
          console.error('An error occurred getting all mediators', error);
          reject(error);
        });
    });
  }

  /** function get all users of type specialist */
  getSpecialists(useSpecialistCache: boolean = true): Promise<Mediator[]> {
    return new Promise((resolve, reject) => {
      if (this.specialistLoaded && useSpecialistCache) {
        console.log('Already Loaded specialist list');
        resolve(this.specialistList);
      } else {
        this.repository.fetchObjectsBy(Mediator.type, Mediator.fields, 'userRole', 'Specialist', ['_id'], Databases.mediatorsDb)
          .then(response => {
            const users: Mediator[] = response.docs.map((doc: any) => this.mapObjectToMediator(doc));
            this.specialistList = users;
            this.specialistLoaded = true;
            resolve(users);
          })
          .catch(error => reject(error));
      }
    });
  }

  /*
  * get single mediator function
  */
  getSingleMediator(userName): Promise<Mediator> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(Mediator.type, Mediator.fields, 'username', userName, ['_id'], Databases.mediatorsDb)
        .then((result) => {
          const mediator: Mediator = result.docs.map((doc: any) => this.mapObjectToMediator(doc))[0];
          resolve(mediator);
        })
        .catch(error => {
          console.error('An error occurred getting single mediator', error);
          reject(error);
        });
    });
  }

  private mapObjectToMediator(object: any): Mediator {
    let mediator: Mediator = new Mediator();
    return mediator = { ...object };
  }

  /**
   * Return list of sharelinks, will return cached list if available, or call loadAllPatients
   */
  getAllShareLinks(): Promise<ShareLinks[]> {
    return new Promise((resolve, reject) => {
      if (this.sharelinksLoaded) {
        console.log('already loaded sharelinks')
        resolve(this.sharelinks);
      } else {
        this.loadAllShareLinks()
          .then((allShareLinks) => {
            this.sharelinks = allShareLinks;
            this.sharelinksLoaded = true;
            resolve(this.sharelinks);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          })
      }
    });
  }

  /**
   *  function to load sharelinks from db
   */
  loadAllShareLinks(): Promise<ShareLinks[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(ShareLinks.type, Databases.sharelinksDb)
        .then((result) => {
          const shareLinks: ShareLinks[] = result.docs.map((doc: any) => this.mapObjectToShareLink(doc));
          resolve(shareLinks);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  public mapObjectToShareLink(object: any): ShareLinks {
    let shareLink: ShareLinks = new ShareLinks();
    return shareLink = { ...object };
  }

  // getting one patient's share links
  getPatientShareLinks(patientId: number): Promise<ShareLinks[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(ShareLinks.type, patientId, Databases.sharelinksDb)
        .then((result) => {
          const shareLinks: ShareLinks[] = result.docs.map((doc: any) => this.mapObjectToShareLink(doc));
          resolve(shareLinks as ShareLinks[]);
        })
        .catch(error => {
          console.error('An error occurred in sharelinks fetchObjects', error);
          reject(error);
        });

    });

  }

  // get patients' enquiries
  getPatientsEnquiries(patientIds: number[]): Promise<Enquiry[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatients(Enquiry.type, patientIds, Databases.mainDb)
        .then(result => {
          const enquiries: Enquiry[] = result.docs.map((doc: any) => this.mapObjectToEnquiry(doc));
          resolve(enquiries as Enquiry[]);
        }).catch(error => {
          console.error('An error occurred in patients enquiry fetchObjects', error);
          reject(error);
        });

    });
  }

  // get single share link by its id
  getSingleSharedLinkData(id: String): Promise<ShareLinks> {
    return new Promise((resolve, reject) => {
      if (this.currentSharelinkLoaded && this.currentSharelink.getValue()._id === id) {
        console.log('Sharelink already loaded id: ' + this.currentSharelink.getValue().id);
        resolve(this.currentSharelink.getValue());
      } else {
        this.loadSingleSharedLinkData(id)
          .then((sharelink) => {
            this.currentSharelink.next(sharelink);
            this.currentSharelinkLoaded = true;
            resolve(this.currentSharelink.getValue());
          })
          .catch(error => {
            console.error('An error occurred getting sharelink in patient-service', error);
            this.currentSharelinkLoaded = false;
            reject(error);
          });
      }
    });

  }

  // load data for a given sharelink (internal, prefer use of getSingleSharedLinkData)
  loadSingleSharedLinkData(id: String): Promise<ShareLinks> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObject(ShareLinks.type, String(id), ShareLinks.fields, ['_id'], Databases.sharelinksDb)
        .then(result => {
          const sharelink: ShareLinks = result.docs.map((doc: any) => this.mapObjectToShareLink(doc))[0];
          resolve(sharelink);
        })
        .catch(error => {
          console.error('An error occurred loading sharelink in patient-service', error);
          reject(error);
        });
    });
  }

  /**
   * Function to remove personal data from a patient
   * TODO: Remove idnumber and phonenumber
   *
   * @param patient Patient object whose properties should be anonymised
   * @returns the anonymised Patient object
   */
  anonymizePatient(patient: Patient): Patient {
    return new Patient({
      firstname: patient.firstname,
      dateOfBirth: patient.dateOfBirth,
      schemename: patient.schemename,
      age: patient.age,
      sex: patient.sex,
      city: patient.city,
      patientType: patient.patientType,
      idnumber: patient.idnumber,
      phonenumber: patient.phonenumber,
      phonenumber2: patient.phonenumber2
    });
  }

  /**
   * function to save a new sharelink object
   *
   * @param narrative narrative object to copy into sharelink
   * @param patient patient to link the sharelink to
   * @param sharelink sharelink information to be saved
   *
   * @returns promise with new sharelink on success
   */
  saveShareLink(narrative: NarrativeInterface, patient: Patient, sharelink: ShareLinks,
    scanFacilityName: string = ''): Promise<ShareLinks> {
    return new Promise((resolve, reject) => {
      const uuid = 'shared_' + UUID.UUID();

      // deal with different types of narrative, for now just OBS and OTHER
      if (narrative.narrativeType === NarrativeEnumTypes.obstetric) {
        const obsNarrative = narrative as ObstetricNarrative;
        sharelink.narrativeId = +(obsNarrative._id);
        sharelink.intent = obsNarrative.narrativeType;
        sharelink.scanFacilityCopy = {
          type: Facility.type,
          code: narrative.scanFacility,
          name: scanFacilityName
        };
      } else {
        const nonObsNarrative = narrative as Narrative;
        sharelink.narrativeId = +(nonObsNarrative._id);
        sharelink.intent =
          nonObsNarrative.referralType === NarrativeEnumTypes.consultation ? NarrativeEnumTypes.consultation : 'Referral';
        sharelink.questions = nonObsNarrative.questions;
      }

      sharelink.patientId = +(patient._id);
      sharelink.patient = this.anonymizePatient(patient);
      sharelink.ultrasoundRecord = this.addUltraSoundRecord(narrative);
      sharelink._id = String(uuid);
      sharelink.id = ((new Date().getTime()));
      sharelink.dateAdded = new Date();
      sharelink.clinicalImpressions = '';
      sharelink.additionalResources = '';
      sharelink.createFacility = patient.createFacility;
      sharelink.assignedMediatorUsername = this.authService.getUser().username;
      sharelink.specialistInfo = sharelink.specialistInfo ? sharelink.specialistInfo : new SpecialistInfo(sharelink.specialistInfo);

      this.updateShareLink(sharelink)
        .then(savedSharelink => {
          resolve(savedSharelink);
        })
        .catch(error => {
          console.log('Error in patientService.saveShareLink()', error);
          reject(error);
        });
    });
  }

  /**
   * Generate data request link
   * @param segment requesting data for segment
   * @returns resolve value
   */
  saveRequestShareLink(segment: BlockSegment, shareLink: RequestShareLinks, narrative: NarrativeInterface) {
    return new Promise((resolve, reject) => {
      const uuid = 'request_' + UUID.UUID();
      shareLink._id = String(uuid);
      shareLink.patientId = narrative.patientId;
      shareLink.narrativeId = +narrative._id;
      shareLink.segment = segment;
      shareLink.dateAdded = new Date();
      shareLink.intent = NarrativeEnumTypes.consultation;

      // save to db
      this.updateShareLink(shareLink).then(savedSharelink => {
        resolve(savedSharelink);
      }).catch(error => {
        console.log('Error in patientService.saveShareLink()', error);
        reject(error);
      });
    });
  }

  /**
  * make a copy of the ultrasound record details
  * return the ultrasound at publishing and save
 */
  addUltraSoundRecord(narrative): UltrasoundRecord {
    return new UltrasoundRecord({
      scanningClinician: narrative.scanningClinician,
      scanFacility: narrative.otherScanFacility ? narrative.otherScanFacility : narrative.scanFacility,
      dateAdded: narrative.dateAdded,
      scanIndication: narrative.scanIndication,
      gravida: narrative.gravida,
      para: narrative.para,
      dateOfLNMP: narrative.dateOfLNMP,
      gestationAge: narrative.gestationAge,
      actualGestationAge: narrative.actualGestationAge,
      fetalWeight: narrative.fetalWeight,
      dateOfDeliveryFrom: narrative.dateOfDeliveryFrom,
      dateOfDelivery: narrative.dateOfDelivery,
      dateOfDeliveryByScan: narrative.dateOfDeliveryByScan,
      dLMPUnknown: narrative.dLMPUnknown
    })
  }

  /**
   * Add or update sharelink object to database
   *
   * @param shareLink sharelink object to be updated
   */
  updateShareLink(shareLink: ShareLinks): Promise<ShareLinks> {
    // console.log(shareLink);
    return new Promise((resolve, reject) => {
      this.repository.updateObject(shareLink, ShareLinks.type, Databases.sharelinksDb)
        .then((updatedSharelink) => {
          resolve(updatedSharelink as ShareLinks);
        })
        .catch(error => {
          console.error('An error occurred updating sharelink', error);
          reject(error);
        });
    });
  }

  /** check if shareLink is a request link or not */
  isRequestLink(sharedLink: string): boolean {
    return sharedLink.split('_')[0] === 'request';
  }

}
