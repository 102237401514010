import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MaternalAnatomyBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SonographerBlockMaternalAnatomy } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-maternal-anatomy-sonographer-edit',
  templateUrl: 'maternal-anatomy-sonographer-edit.component.html'
})

export class MaternalAnatomySonographerEditComponent extends SonographerBlockEditComponent {
  @Input() nurseData: MaternalAnatomyBlock;
  @Input() blockData: SonographerBlockMaternalAnatomy;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;
  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
  form: FormGroup;

  constructor(
    private _fb: FormBuilder
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: [''],
      sonographerNotes: ['']
    })
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.blockData.blockStatus ?  true : false;
  }

  /**
   * function to trigger next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

}
