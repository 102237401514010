import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FoetalPresentationBlock } from '../../../../models/obstetric-narrative';
import { SonographerBlockFoetalPresentation } from '../../../../models/obstetric-sharelink';

@Component({
  selector: 'psoc-foetal-presentation-sonographer-view',
  templateUrl: './foetal-presentation-sonographer-view.component.html'
})

export class FoetalPresentationSonographerViewComponent {
  @Input() nurseData: FoetalPresentationBlock;
  @Input() sonographerData: SonographerBlockFoetalPresentation = new SonographerBlockFoetalPresentation();
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.sonographerData.blockStatus ?  true : false;
  }
}
