import { Component, Input } from '@angular/core';
import { Patient } from '../../models/patient';
import { Narrative } from '../../models/narrative';
import { MatTableDataSource } from '@angular/material';
import { ShareLinks } from '../../models/sharelinks';

@Component({
  selector: 'psoc-prescription-view',
  templateUrl: 'prescription-view.component.html'
})

export class PrescriptionViewComponent {
  @Input() set mySharedLink(mySharedLink: ShareLinks) {
    if (!mySharedLink.prescribedDrugs) { mySharedLink.prescribedDrugs = [] }
    this._mySharedLink = mySharedLink;
    if (!this._mySharedLink.labtestInfo) { this._mySharedLink.labtestInfo = [] }
    this.dataSource = mySharedLink.prescribedDrugs.length > 0 ? new MatTableDataSource(mySharedLink.prescribedDrugs) :
      new MatTableDataSource([]);
  } get mySharedLink(): ShareLinks { return this._mySharedLink }
  @Input() myNarrative: Narrative;
  @Input() patient: Patient;

  displayedColumns = ['name', 'instructions'];
  dataSource = new MatTableDataSource();
  _mySharedLink: ShareLinks;
  constructor() { }
}
