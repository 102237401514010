import { Injectable } from '@angular/core';
import { PouchObject } from '../models/pouch-object';
import { RepositoryObserver } from './repository-observer';
import { environment } from '../../environments/environment';
import { PouchArray } from '../models/pouch-array';

import * as rawPouchDB from 'pouchdb';
import * as rawPouchDBFind from 'pouchdb-find';
import * as rawCryptoPouch from 'crypto-pouch';
import * as rawPouchDBAuthentication from 'pouchdb-authentication';
import * as rawPouchDbLoad from 'pouchdb-load';

const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
const PouchDBFind: PouchDB.Plugin = (rawPouchDBFind as any).default;
const PouchDBAuthentication: PouchDB.Plugin = (rawPouchDBAuthentication as any).default;
const CryptoPouch: PouchDB.Plugin = (rawCryptoPouch as any);
const PouchDBLoad: PouchDB.Plugin = (rawPouchDbLoad as any);

import { AuthenticationService } from '../services/authentication.service';
import { Events } from '../models/events';
import { NarrativeBlock } from '../models/narrative-block';

declare let emit: Function;

export enum SyncStatus {
  error = 0,
  paused = 1,
  active = 2,
  change = 3,
  push = 4,
  pull = 5
}

export enum Databases {
  mainDb,
  eventsDb,
  facilitiesDb,
  narrativeBlocksDb,
  sharelinksDb,
  chatsDb,
  appointmentsDb,
  mediatorsDb,
  britamDb,
  usersDb
}

export enum AppTypes {
  mediatorApp,
  adminApp,
  specialistsApp
}

@Injectable()
export class RepositoryService {

  // databases
  private localMainDb: any;
  private remoteMainDb: any;
  private remoteUsersDb: any;
  private localFacilitiesDb: any;
  private remoteFacilitiesDb: any;
  private localEventsDb: any;
  private remoteEventsDb: any;
  private localChatsDb: any;
  private remoteChatsDb: any;
  private localAppointmentsDb: any;
  private remoteAppointmentsDb: any;
  private localNarrativeBlocksDb: any;
  private remoteNarrativeBlocksDb: any;
  private localMediatorsDb: any;
  private remoteMediatorsDb: any;
  private localSharelinksDb: any;
  private remoteSharelinksDb: any;
  private localBritamDb: any;
  private remoteBritamDb: any;

  // db tools
  public batchSize: number = 10;
  public batchLimit: number = 2;

  public loadedSharedDbs = [];
  public failedSharedDbs = [];

  private app: AppTypes;
  public loadSpecialistDbStatus: boolean = false;
  public pendingDown: number[] = [];
  public pendingDownSum: number = 0;
  public syncStatus: SyncStatus;
  public unsyncedObjects: Array<PouchObject> = [];
  private observer: Array<RepositoryObserver> = [];

  constructor(private authenticationService: AuthenticationService) {
    // load list of unsynced objects
    this.loadUnsynced();

    // PouchDB.debug.enable('*'); // debug mode for pouch db

    // Main databases
    this.remoteMainDb = new PouchDB(environment.couchURL + environment.pouchDBName, { skip_setup: true });
    this.remoteUsersDb = new PouchDB(environment.couchURL + '_users', { skip_setup: true });
    this.remoteFacilitiesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-facilities');
    this.remoteEventsDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-events', { skip_setup: true });
    this.remoteNarrativeBlocksDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-blocks', { skip_setup: true });
    this.remoteAppointmentsDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-appointments', { skip_setup: true });
    this.remoteChatsDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-chats', { skip_setup: true });
    this.remoteMediatorsDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-mediators', { skip_setup: true });
    this.remoteSharelinksDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-sharelinks', { skip_setup: true });
    this.remoteBritamDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-britamdata', { skip_setup: true });

    // Load pouch plugins
    PouchDB.plugin(PouchDBAuthentication);
    PouchDB.plugin(PouchDBFind);
    PouchDB.plugin(CryptoPouch);
    PouchDB.plugin(PouchDBLoad);
  }

  /**
   * Get enum value
   * @param definition
   */
  enumSelector(definition) {
    return Object.keys(definition)
      .map(key => ({ value: definition[key], title: key }));
  }

  // Set the current app, singleton
  setApp(app: AppTypes) {
    this.app = app;
  }
  getApp(): AppTypes {
    return this.app;
  }

  // load database dump file
  loadDatabaseDump(dbdump) {
    return new Promise((resolve, reject) => {
      this.localMainDb.load(dbdump, {
        proxy: environment.couchURL + environment.pouchDBName
      }).then(function () {
        resolve('success');
        // done loading!
      }).catch(function (err) {
        reject(err);
        // HTTP error or something like that
      });
    });
  }

  // Specialist app db setup
  loadSpecialistDb(sharelinkId: string) {
    // Connect to databases on sharelink server
    this.localMainDb = this.remoteMainDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId, { skip_setup: true });
    this.setupIndexes(this.localMainDb);
    this.getDbInfo(this.localMainDb, 'main');

    this.localNarrativeBlocksDb = this.remoteMainDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId + '-blocks', { skip_setup: true });
    this.setupIndexes(this.localNarrativeBlocksDb);

    this.localChatsDb = this.remoteChatsDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId + '-chats', { skip_setup: true });
    this.getDbInfo(this.localChatsDb, 'chats');

    this.localAppointmentsDb = this.remoteAppointmentsDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId + '-appointments', { skip_setup: true });
    this.getDbInfo(this.localAppointmentsDb, 'appointments');

    this.localEventsDb = this.remoteEventsDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId + '-events', { skip_setup: true });
    this.getDbInfo(this.localEventsDb, 'events');

    this.localSharelinksDb = this.remoteSharelinksDb
      = new PouchDB(environment.sharelinksCouchURL + sharelinkId + '-sharelink', { skip_setup: true });
    this.getDbInfo(this.localSharelinksDb, 'sharelink');
  }

  /**
   * Patient app database loading
   * @param shareLinkId string of sharelink id
   */
  loadPatientDb(shareLinkId: string) {
    console.log('Loading databases for:', shareLinkId);
    this.localMainDb = this.remoteMainDb
      = new PouchDB(environment.sharelinksCouchURL + shareLinkId, { skip_setup: true });
    this.getDbInfo(this.localMainDb, 'main');

    this.localEventsDb = this.remoteEventsDb
      = new PouchDB(environment.sharelinksCouchURL + shareLinkId + '-events', { skip_setup: true });
    this.getDbInfo(this.localEventsDb, 'events');

    this.localSharelinksDb = this.remoteSharelinksDb
      = new PouchDB(environment.sharelinksCouchURL + shareLinkId + '-sharelink', { skip_setup: true });
    this.getDbInfo(this.localSharelinksDb, 'sharelink');
  }

  /**
   * Check if the remote db exists
   */
  getDbInfo(db, dbName: string) {
    db.info().then(response => {
      if (!response.error && !this.loadedSharedDbs.includes(dbName)) {
        this.loadedSharedDbs.push(dbName);
      } else {
        this.failedSharedDbs.push(dbName);
      }
    }).catch(error => {
      console.log('Error getting database', dbName, 'info', error);
    });
  }

  // Load and start sync of main db
  loadMainDb(facility: string, key: string) {
    switch (this.app) {
      case AppTypes.mediatorApp:
        // Facilities database
        this.localFacilitiesDb = new PouchDB(environment.pouchDBName + '-facilities-' + facility);
        this.localFacilitiesDb.crypto(key, { ignore: ['createFacility', 'type', 'id', 'patientId', '_attachments'] });
        this.localFacilitiesDb.sync(this.remoteFacilitiesDb, { retry: true })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.facilitiesDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.facilitiesDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.facilitiesDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.facilitiesDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.facilitiesDb); });

        // Narrative Blocks Database
        this.localNarrativeBlocksDb = new PouchDB(environment.pouchDBName + '-blocks-' + facility);
        this.localNarrativeBlocksDb.crypto(key, { ignore: ['createFacility', 'type', 'id', 'patientId', '_attachments'] });
        this.localNarrativeBlocksDb.sync(this.remoteNarrativeBlocksDb, { retry: true })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.narrativeBlocksDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.narrativeBlocksDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.narrativeBlocksDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.narrativeBlocksDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.narrativeBlocksDb); });

        // Main database
        this.localMainDb = new PouchDB(environment.pouchDBName + '-' + facility, { auto_compaction: true });
        this.localMainDb.crypto(key, { ignore: ['createFacility', 'type', 'id', 'patientId', '_attachments'] });
        this.localMainDb.sync(this.remoteMainDb,
          {
            live: true, retry: true, continuous: true, filter: 'app/by_facility', query_params: { 'facility': facility },
            batch_size: this.batchSize, batch_limit: this.batchLimit
          })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.mainDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.mainDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.mainDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.mainDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.mainDb); });

        // Sharelinks Database
        this.localSharelinksDb = new PouchDB(environment.pouchDBName + '-sharelinks-' + facility);
        this.localSharelinksDb.sync(this.remoteSharelinksDb,
          {
            live: true, retry: true, continuous: true, filter: 'app/by_facility', query_params: { 'facility': facility },
            batch_size: this.batchSize, batch_limit: this.batchLimit
          })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.sharelinksDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.sharelinksDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.sharelinksDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.sharelinksDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.sharelinksDb); });

        // Chats Database
        this.localChatsDb = new PouchDB(environment.pouchDBName + '-chats-' + facility);
        this.localChatsDb.sync(this.remoteChatsDb,
          { live: true, retry: true, continuous: true, filter: 'app/by_facility', query_params: { 'facility': facility } })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.chatsDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.chatsDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.chatsDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.chatsDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.chatsDb); });

        // Mediators database
        this.localMediatorsDb = new PouchDB(environment.pouchDBName + '-mediators-' + facility);
        this.localMediatorsDb.crypto(key, { ignore: ['createFacility', 'type', 'id', 'patientId', '_attachments'] });
        this.localMediatorsDb.sync(this.remoteMediatorsDb,
          { retry: true, filter: 'app/by_facility', query_params: { 'facility': facility } })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.mediatorsDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.mediatorsDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.mediatorsDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.mediatorsDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.mediatorsDb); });

        // Events database
        this.localEventsDb = new PouchDB(environment.pouchDBName + '-events');
        this.localEventsDb.crypto(key, { ignore: ['createFacility', 'type', 'id', 'patientId', '_attachments'] });
        this.localEventsDb.replicate.to(this.remoteEventsDb, { live: true, retry: true });

        // Appointments Database
        this.localAppointmentsDb = new PouchDB(environment.pouchDBName + '-appointments-' + facility);
        this.localAppointmentsDb.sync(this.remoteAppointmentsDb,
          { live: true, retry: true, continuous: true, filter: 'app/by_facility', query_params: { 'facility': facility } })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.appointmentsDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.appointmentsDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.appointmentsDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.appointmentsDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.appointmentsDb); });

        // Insurance member data
        this.localBritamDb = this.remoteBritamDb;
        break;

      case AppTypes.adminApp:
        this.localMainDb = this.remoteMainDb;
        this.localChatsDb = this.remoteChatsDb;
        this.localAppointmentsDb = this.remoteAppointmentsDb;
        this.localEventsDb = this.remoteEventsDb;
        this.localSharelinksDb = this.remoteSharelinksDb;
        this.localFacilitiesDb = this.remoteFacilitiesDb;
        this.localMediatorsDb = this.remoteMediatorsDb;
        this.localNarrativeBlocksDb = this.remoteNarrativeBlocksDb;
        this.localBritamDb = this.remoteBritamDb;
        break;

      default:
        console.log('You are NOT in the Application')
        break;
    }

    // Set up indexes for queries using find api, only needs to run once for each db
    this.setupIndexes(this.localMainDb);
    this.setupIndexes(this.localChatsDb);
    this.setupIndexes(this.localAppointmentsDb);
    // this.setupIndexes(this.localEventsDb);
    this.setupIndexes(this.localSharelinksDb);
    this.setupIndexes(this.localFacilitiesDb);
    this.setupIndexes(this.localMediatorsDb);
    this.setupIndexes(this.localNarrativeBlocksDb);
  }

  // update the list of changes to be synced
  updateSync(change: any, event: any, dbIndex: Databases) {
    // console.log('pouch status - ' + change + ': ' + JSON.stringify(event));
    switch (change) {
      case 'change': {
        if (event.direction === 'push') {
          this.syncStatus = SyncStatus['push'];
          // remove successfully changed objects from unsyncedObjects
          event.change.docs.forEach((pouchObject: PouchObject) => {
            this.removeUnsynced(pouchObject);
          });
        } else if (event.direction === 'pull') {
          // notify listeners that new objects available
          event.change.docs.forEach((object: any) => {
            const type: string = object.__zone_symbol__value ? object.__zone_symbol__value.type : object.type;
            if (type !== undefined) { this.notifyObserver(type); }
          });

          // get the sum of all the pending down documents from server
          this.pendingDown[dbIndex] = event.change.pending;
          this.pendingDownSum = this.pendingDown.reduce((acc, val) => acc + val);

          this.syncStatus = SyncStatus['pull'];
        } else {
          this.syncStatus = SyncStatus['change'];
        }
        break;
      }
      case 'paused': {
        this.syncStatus = SyncStatus['paused'];
        break;
      }
      case 'active': {
        if (event.direction === 'push') {
          this.syncStatus = SyncStatus['push'];
        } else if (event.direction === 'pull') {
          this.syncStatus = SyncStatus['pull'];
        } else {
          this.syncStatus = SyncStatus['active'];
        }
        break;
      }
      case 'error': {
        this.syncStatus = SyncStatus['error'];
        break;
      }
    }
  }

  registerObserver(observer: RepositoryObserver): void {
    if (!this.observer.includes(observer)) {
      this.observer.push(observer);
    }
  }

  unregisterObserver(observer: RepositoryObserver): void {
    const index: number = this.observer.indexOf(observer);
    if (index > -1) {
      this.observer.splice(index, 1);
    }
  }

  notifyObserver(objectType: string): void {
    // console.log(objectType);
    this.observer.forEach((observer: RepositoryObserver) => observer.notify(objectType));
  }

  // add object to list of unsynced objects
  addUnsynced(pouchObject: PouchObject) {
    return new Promise((resolve, reject) => {
      // disable for admin app and specialist app, they have direct access to db
      if (this.getApp() !== AppTypes.mediatorApp) { resolve('Success: Item already in unsynced objects list'); return; }

      // check if its already in the list of unsynced objects, add it if not
      if ((this.unsyncedObjects.map((x) => { return x._id; }).indexOf(pouchObject._id)) >= 0) {
        this.removeUnsynced(pouchObject);  // remove existing to update the rev
      }

      // add shallow copy of unsynced object to list
      this.unsyncedObjects.push({
        _id: pouchObject._id,
        _rev: pouchObject._rev,
        _deleted: pouchObject._deleted,
        type: pouchObject.type,
        dateAdded: pouchObject.dateAdded,
        dateUpdated: pouchObject.dateUpdated,
        updatedBy: pouchObject.updatedBy,
        createdBy: pouchObject.createdBy,
        createFacility: pouchObject.createFacility,
        updateFacility: pouchObject.updateFacility
      });

      try {
        localStorage.setItem('unsyncedObjects', JSON.stringify(this.unsyncedObjects));
      } catch (e) {
        // Storage full, maybe notify user or do some clean-up
        console.log('Error adding object to unsynced objects list', e);
        reject('Error adding object to unsynced objects list');
      }

      resolve('Successfully added object to unsynced objects list');

    });
  }

  // remove object from list of unsynced objects
  removeUnsynced(pouchObject: PouchObject) {
    const elementPos = this.unsyncedObjects.map(function (x) { return x._id; }).indexOf(pouchObject._id);
    console.log('index of changed obj to be removed from unsynced list: ' + elementPos);
    this.unsyncedObjects.splice(elementPos, 1);
    localStorage.setItem('unsyncedObjects', JSON.stringify(this.unsyncedObjects));
  }

  // load list of unsynced objects from offline storage
  loadUnsynced() {
    const unsyncedObjects = JSON.parse(localStorage.getItem('unsyncedObjects'));

    if (!Array.isArray(unsyncedObjects)) {
      return;
    }

    unsyncedObjects.forEach((pouchObject: PouchObject) => {
      this.unsyncedObjects.push(pouchObject);
    });
  }

  /**
   * check if unsynced object exist in couch and delete them from local storage
   * Objects: patients, narratives, images,  sharelinks, chats, appointments
   */
  checkUnsyncedIncouch(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log('checking unsynced objects...');
      if (this.unsyncedObjects.length < 1) { resolve('There are no unsynced Objects'); }

      let remoteDB;
      let count = 0;
      this.unsyncedObjects.forEach(o => {
        if (o.type === 'patients' || o.type === 'narrative' || o.type === 'attachment') {
          remoteDB = this.remoteMainDb;
        } else if (o.type === 'sharelinks') {
          remoteDB = this.remoteSharelinksDb;
        } else if (o.type === 'chat') {
          remoteDB = this.remoteChatsDb;
        } else if (o.type === 'appointment') {
          remoteDB = this.remoteAppointmentsDb;
        }

        this.fetchObject(o.type, o._id, ['_id', '_rev'], ['_id'], remoteDB, true)
          .then(result => {
            const doc = result.docs[0];
            const fromCouch_rev = doc._rev.split('-')[0];
            const fromLocal_rev = o._rev.split('-')[0];

            if (doc._id === o._id && fromCouch_rev >= fromLocal_rev) {
              count++;
              this.removeUnsynced(doc);
            } else {
              // not synced
              console.log('Object not synced');
            }
          })
          .catch(error => {
            reject(error);
          });
      });

      // resolve with the count of synced objects
      resolve(`${count} Objects synced to server`);
    });
  }

  // Attachments

  // get pouchdb._attachment[filename] for any object
  getAttachmentFile(attachmentName: String, attachmentFileName: String = 'file') {
    console.log('Attempting to load attachment file: ' + attachmentName)
    return this.localMainDb.getAttachment(attachmentName, attachmentFileName);
  }

  // Pouch Object Update/Query/Delete

  // save or update object to pouch db, optionally specify database
  updateObject(pouchObject: PouchObject, type: string, database = Databases.mainDb, deleteObject: boolean = false): Promise<PouchObject> {
    return new Promise((resolve, reject) => {

      // select database
      const db = this.switchDatabase(database);

      // update type
      pouchObject.type = type;

      // metadata
      const user = this.authenticationService.getUser();
      let eventType = 'updated';
      // check for valid user
      if (user === undefined || user.username === '') { reject('Valid user required to update objects'); };

      // update timestamps
      const timestamp = new Date();
      if (!pouchObject._rev) {
        pouchObject.dateAdded = timestamp
        pouchObject.createdBy = user.username;
        pouchObject.createFacility = user.facility;

        // set the tye of event to added for a new document
        eventType = 'added';
      };
      pouchObject.dateUpdated = timestamp;
      pouchObject.updatedBy = user.username;
      pouchObject.updateFacility = user.facility;

      // process deletions
      if (deleteObject) {
        eventType = 'deleted';
        pouchObject._deleted = true;
      }

      // save object
      db.put(pouchObject)
        .then((response: any) => {
          console.log('updateObject updated: ' + JSON.stringify(pouchObject));
          pouchObject._rev = response.rev;

          // add to unsynced objects list
          this.addUnsynced(pouchObject).then(msg => console.log(msg)).catch(err => console.log(err));

          // log an event when an update to a document has been added to the db
          this.addEvents(type, pouchObject._id, eventType, user.username, user.ipAddress);

          // return object updated with new _rev
          resolve(pouchObject);
        })
        .catch(error => {
          console.log('Error Updating Pouch Object:', error);
          reject(error);
        });
    });
  }

  // function to save events into the localEvents db
  // add argument below inside brackets below e.g comment = 'status change'
  // include in the model of events plus inside this.localEventsDb
  addEvents(objectType: string, objId: string, eventType: string, username, ipAddress, message: string = null,
    patientId: number = null, extraDetails = {}) {
    const event: Events = new Events();

    event._id = String((new Date().getTime()));
    event.type = 'events';
    event.objectType = objectType;
    event.objectId = objId;
    event.event = eventType;
    event.userName = username;
    event.ipAddress = ipAddress;
    event.message = message;
    event.patientId = patientId;
    event.extraDetails = extraDetails;
    event.appVersion = environment.appVersion;
    event.dateAdded = new Date();

    // else save the event without a notification field
    this.localEventsDb.put(event)
      .then((response: any) => {
        // print out the response to the console if success
        console.log('Successfully added event - ' + JSON.stringify(response));
      }).catch((err) => {
        // print out error in the console if any
        console.log('Error adding event ' + JSON.stringify(err));
      });
  }

  // function to update existing event
  updateEvent(event: Events) {
    return new Promise((resolve, reject) => {
      this.localEventsDb.put(event).then((response: any) => {
        // print out the response to the console if success
        console.log('Successfully updated event - ' + JSON.stringify(response));
        resolve(response);
      }).catch((err) => {
        // print out error in the console if any
        console.log('Error updating event ' + JSON.stringify(err));
        reject(err);
      });
    });
  }

  // delete object in pouch, optionally specify database
  deleteObject(pouchObject: PouchObject, database = Databases.mainDb): Promise<PouchObject> {
    return this.updateObject(pouchObject, pouchObject.type, database, true);
  }

  // fetches objects of a given type filtered by a given patient
  fetchObjectsByPatient(type: String, patientId: number, database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.query('indexp_' + type, { key: +patientId, include_docs: true }).then(results => {
        const resolveObjects: any = [];
        resolveObjects.docs = results.rows.map(r => r.doc);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/indexp_' + type && error.status === 404) {
          console.log('Wait for design doc: _design/indexp_' + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  /**
   * fetches objects of a given type for several patients
   */
  fetchObjectsByPatients(type: String, patientIds: number[], database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.query('indexp_' + type, { keys: patientIds, include_docs: true }).then(results => {
        const resolveObjects: any = [];
        resolveObjects.docs = results.rows.map(r => r.doc);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/indexp_' + type && error.status === 404) {
          console.log('Wait for design doc: _design/indexp_' + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  // fetches objects of a given type, optionally specify database
  fetchObjects(type: String, database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.query('index_' + type).then((results: any) => {
        const resolveObjects: any = [];
        resolveObjects.docs = results.rows.map(r => r.key);
        if (type === NarrativeBlock.type) {
          resolveObjects.docs = resolveObjects.docs.sort((a, b) => a.blockIndexId - b.blockIndexId);
        } else {
          resolveObjects.docs = resolveObjects.docs.sort((a, b) => b._id - a._id);
        }
        console.log('fetchObjects returned ' + resolveObjects.docs.length + ' ' + type);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/index_' + type && error.status === 404) {
          console.log('Error loading ' + type + '(s) Wait for design doc: _design/index_' + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  // fetches a single object of a given type with given id, optionally specify database
  fetchObject(
    type: String,
    _id: String,
    fields: string[] = null,
    sort: Object[] = ['_id'],
    database = Databases.mainDb,
    queryCouch: boolean = false): Promise<PouchArray<Object>> {
    // select database
    const db = queryCouch ? database : this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.find({
        selector: { type: type, _id: _id },
        fields: fields,
        sort: sort,
        limit: 1,
        use_index: 'idx'
      }).then((result: any) => {
        if (JSON.parse(JSON.stringify(result.docs)).length === 0) {
          reject('fetchObject unable to load ' + type + ' object id ' + _id);
        } else {
          console.log('fetchObject returned 1 ' + type);
          resolve(result);
        }
      }).catch(reject);
    });
  }

  // fetches objects of a given type, option to search for, optionally specify database
  fetchObjectsBy(type: String, fields: string[] = null, ByFieldName: string, ByFieldValue: any,
    sort: Object[] = ['_id'], database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);
    console.log('Fetching all ' + type + ' where ' + ByFieldName + ' = ' + ByFieldValue + ' from ' + database);

    return new Promise((resolve, reject) => {
      if (ByFieldValue === undefined) { reject('Please specify field value') };
      db.find({
        selector: { type: type, [ByFieldName]: ByFieldValue, _id: { $gt: null } },
        fields: fields,
        sort: sort,
        use_index: 'indexBy_' + ByFieldName
      }).then((result: any) => {
        console.log('fetchObjectsBy returned ' + result.docs.length + ' ' + type);
        resolve(result);
      }).catch(reject);
    });
  }

  // helper to choose which database to use
  switchDatabase(database: Databases): any {
    switch (database) {
      case Databases.facilitiesDb: {
        return this.localFacilitiesDb;
      }
      case Databases.narrativeBlocksDb: {
        return this.localNarrativeBlocksDb;
      }
      case Databases.eventsDb: {
        return this.localEventsDb;
      }
      case Databases.chatsDb: {
        return this.localChatsDb;
      }
      case Databases.appointmentsDb: {
        return this.localAppointmentsDb;
      }
      case Databases.mediatorsDb: {
        return this.localMediatorsDb;
      }
      case Databases.sharelinksDb: {
        return this.localSharelinksDb;
      }
      case Databases.usersDb: {
        return this.remoteUsersDb;
      }
      case Databases.britamDb: {
        return this.remoteBritamDb;
      }
      default: {
        return this.localMainDb;
      }
    }
  }

  /** set up Persistent queries or design documents
    * TODO; consider removing this function and manually adding indices to db
    */
  public setupIndexes(db, fields: string[] = ['_id', 'type'], name: string = 'idxIdType', ddoc: string = 'idx') {
    // index by type
    db.createIndex({ index: { fields: fields, name: name, ddoc: ddoc } })
      .catch(function (err) { console.log('Error creating index: ', err); });
  }

}
