import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { Chat } from '../models/chat';

@Injectable()
export class ChatService {

  constructor(private repository: RepositoryService) { }

  // adding chats
  addChat(newChat: Chat): Promise<Chat> {
    newChat._id = new Date().getTime().toString();

    return new Promise((resolve, reject) => {
      this.repository.updateObject(newChat, Chat.type, Databases.chatsDb)
        .then((pouchObject) => {
          const updatedChat: Chat = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedChat);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });

  }

  /**
   * update chat 
   */
  updateChat(newChat: Chat): Promise<Chat> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(newChat, Chat.type, Databases.chatsDb)
        .then((pouchObject) => {
          const updatedChat: Chat = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedChat);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });

  }

  // getting all the chats
  getChats(): Promise<Chat[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Chat.type, Databases.chatsDb)
        .then((result) => {
          const chats: Chat[] = result.docs.map((doc: any) => this.mapObjectToChat(doc));
          resolve(chats);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /**
   * get all chats for the selected patient
   * @param patientId number: patient id
   */
  getPatientChats(patientId: number): Promise<Chat[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(Chat.type, patientId, Databases.chatsDb)
        .then((result) => {
          const chats: Chat[] = result.docs.map((doc: any) => this.mapObjectToChat(doc));
          resolve(chats);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /**
   * get chats that belongs to this sharelink
   * @param sharelink string: sharelink id
   */
  getSharelinkChats(sharelink: string): Promise<Chat[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(Chat.type, Chat.fields, 'sharelinkId', sharelink, ['_id'], Databases.chatsDb)
        .then((result) => {
          const chats: Chat[] = result.docs.map((doc: any) => this.mapObjectToChat(doc));
          resolve(chats);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  public mapObjectToChat(object: any): Chat {
    let chats: Chat = new Chat();
    return chats = { ...object };
  }

}
