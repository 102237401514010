import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentService } from '../../../services/attachment.service';
import { ScanViewerModalComponent } from '../viewer/scan-viewer.modal';
import { Attachment } from '../../../models/attachment';

@Component({
    template: 'view block'
})

export class ObstetricBlockViewComponent {
    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;
    attachmentFilesCount = {};
    sideThumbnails: any[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        public attachmentService: AttachmentService
    ) { }

    /** find out how many files each attachment has */
    getAttachmentFilesCount(attachmentIds: string[]) {
        attachmentIds.forEach((attachmentId, index) => {
            this.attachmentService.getAttachment(attachmentId)
                .then(attachment => {
                    this.attachmentFilesCount[attachmentId] = Object.keys(attachment._attachments).length;
                    // load the sidebar thumbnails
                    this.loadSidebarThumbnails(attachment);
                }).catch(error => {
                    console.log('Error loading attachment', error);
                });
        });
    }

    /** function to open modal with attachment viewer */
    openAttachmentViewer(attachmentId: string) {
        this.attachmentService.getAttachment(attachmentId)
            .then(attachment => {
                this.attachmentService.getAttachmentFilesAsObjectUrls(attachment)
                    .then(thumbnails => {
                        this.openModal(attachment, thumbnails);
                    })
                    .catch(error => {
                        console.log('Error loading attachment._attachment files', error);
                        this.snackBar.open('Error loading attachment object files', 'Error', { duration: 6000 });
                    });
            }).catch(error => {
                console.log('Error loading attachment', error);
                const snackBarRef = this.snackBar.open('Error loading attachment object, please try again', 'Retry');
                snackBarRef.onAction().subscribe(() => {
                    this.openAttachmentViewer(attachmentId);
                });
            });
    }

    /** open modal with viewer */
    openModal(attachment, thumbnails) {
        console.log('opening scan viewer modal');
        const config: MatDialogConfig = { width: '95vw', height: '95vh', viewContainerRef: this.dialogAnchor };
        config.data = { attachment: attachment, thumbnails: thumbnails, sideThumbnails: this.sideThumbnails }
        this.dialogRef = this.dialog.open(ScanViewerModalComponent, config);
    }

    loadSidebarThumbnails(attachment: Attachment) {
        Object.keys(attachment._attachments).forEach((attachmentFileName, i) => {
            // get the file index
            const fileIndexArray = attachmentFileName.split('');
            const fileIndex = fileIndexArray[fileIndexArray.length - 1];
            this.attachmentService.getAttachmentFile(attachment._id, attachmentFileName).then((blob) => {
                if (blob.type === 'application/dicom') {
                    // set the dicom thumbnails
                    const src = this.sanitizer.bypassSecurityTrustUrl(attachment.dicomThumbnails[fileIndex]
                        .changingThisBreaksApplicationSecurity);
                    this.sideThumbnails[i] = src;
                } else {
                    const url = URL.createObjectURL(blob);
                    const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
                    this.sideThumbnails[i] = imgSrc;
                }
            }).catch((err) => {
                this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
                console.log(err);
            });
        });
    }


}
