import { PouchObject } from './pouch-object';
import { ReferralStatus } from './narrative';

export class Patient implements PouchObject {
  public static type: string = 'patients';
  public static fields = ['_id', '_rev', 'id', 'type', 'firstname', 'lastname', 'othernames', 'idnumber', 'countryCode', 'phonenumber',
    'countryCode2', 'phonenumber2', 'emailAddress', 'dateOfBirth', 'age', 'sex', 'postalAddress', 'city', 'patientType', 'createdBy', 'dateAdded',
    'updatedBy', 'dateUpdated', 'createFacility', 'updateFacility', 'sharedFacility', 'dataSize', 'attachmentCount', 'schemename', 'duplicate'];
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  firstname: string; // firstName, required, 2-50 characters long
  lastname: string; // lastName, required, 2-50 characters long
  othernames: string; // otherNames, 2-50 characters long
  idnumber: string; // idNumber, alpha, required, 5-15 characters long
  phonenumber: string; // phoneNumber
  phonenumber2: string; // alternative phone number
  countryCode: string = '254'  // default code region KE
  countryCode2: string = '254'  // default code region KE
  emailAddress: string;
  dateOfBirth: Date; // required, must be in future
  age?: number; // optional
  sex: string; // required, "male", "female"
  postalAddress: string;
  city: string; // required
  patientType: string; // default:non-paying, required, "paying", "non-paying"
  sharedFacility: string[];
  schemename?: string;
  duplicate?: boolean = false;

  // pouchobject metadata
  dateAdded: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  createFacility: string;
  updateFacility: string;

  // metadata generated for display only
  dataSize?: number; // how much storage space is used by this patient in bytes
  attachmentCount?: number; // number of attachments

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  compareTo(other: Patient): number {
    return this.id.valueOf() - other.id.valueOf();
  }
}

/**
 * Temporarily add status to patient for displaying in list,
 * do not save status property to db, should be saved to narrative
 */
export class PatientWithStatus extends Patient {
  referralStatus: ReferralStatus;
  [propName: string]: any;
}

export let CountryCodes = [
  { 'code': '211', 'country': 'S.Sudan' },
  { 'code': '254', 'country': 'Kenya' },
  { 'code': '250', 'country': 'Rwanda' },
  { 'code': '255', 'country': 'Uganda' },
  { 'code': '256', 'country': 'Tanzania' }
];
