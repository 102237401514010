import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { RepositoryObserver } from './repository-observer';
import { Attachment } from '../models/attachment';
import * as ImageCompressor from 'image-compressor.js/dist/image-compressor.js';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class AttachmentService implements RepositoryObserver {
  private attachments: Attachment[];
  private attachmentsLoaded: boolean = false;
  private attachmentsPatientId: number; // keep track of which patient we have currently loaded attachments for

  constructor(public repository: RepositoryService, private sanitizer: DomSanitizer) {
    this.repository.registerObserver(this);
  }

  /** invalidate cache if new attachments loaded in pouch */
  notify(objectType: string): void {
    if (objectType === Attachment.type) {
      this.attachmentsLoaded = false;
    }
  }

  /** update attachment object to pouch, return updated object */
  updateAttachment(attachment: Attachment): Promise<Attachment> {
    if (attachment.file) {
      const file = { content_type: attachment.file.type, data: attachment.file }
      attachment._attachments = { file }
      delete attachment.file;
    }
    return new Promise((resolve, reject) => {
      this.repository.updateObject(attachment, Attachment.type)
        .then((pouchObject) => {
          const updatedAttachment: Attachment = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedAttachment);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });

  }

  /** delete attachment object from pouch */
  deleteAttachment(attachment: Attachment): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.repository.deleteObject(attachment)
        .then(() => {
          resolve(null);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /** get all attachment objects for a given patient, uses cache if available */
  getPatientAttachments(patientId: number): Promise<Attachment[]> {
    return new Promise((resolve, reject) => {
      if (this.attachmentsLoaded && this.attachmentsPatientId === patientId) {
        console.log('attachments already loaded');
        resolve(this.attachments);
      } else {
        this.loadPatientAttachments(patientId)
          .then((attachments) => {
            this.attachments = attachments;
            this.attachmentsLoaded = true;
            this.attachmentsPatientId = patientId;
            resolve(this.attachments);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          });
      }
    })
  }

  /** loads all attachment objects from pouch */
  loadPatientAttachments(patientId: number): Promise<Attachment[]> {
    return this.repository.fetchObjectsByPatient(Attachment.type, patientId)
      .then((result) => {
        const attachments: Attachment[] = result.docs.map((doc: any) => this.mapObjectToAttachment(doc));
        return (attachments as Attachment[]);
      })
  }

  /** get all attachment objects for a given narrative, uses cache if available */
  getNarrativeAttachments(narrativeId: number): Promise<Attachment[]> {
    return new Promise((resolve, reject) => {
      if (this.attachmentsLoaded && this.attachmentsPatientId === narrativeId) {
        console.log('attachments already loaded');
        resolve(this.attachments);
      } else {
        this.loadNarrativeAttachments(narrativeId)
          .then((attachments) => {
            this.attachments = attachments;
            this.attachmentsLoaded = true;
            this.attachmentsPatientId = narrativeId;
            resolve(this.attachments);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          });
      }
    })
  }

  /** loads all attachment objects from pouch */
  loadNarrativeAttachments(narrativeId: number): Promise<Attachment[]> {
    return this.repository.fetchObjectsBy(Attachment.type, Attachment.fields, 'narrativeId', narrativeId, ['_id'])
      .then((result) => {
        const attachments: Attachment[] = result.docs.map((doc: any) => this.mapObjectToAttachment(doc));
        return (attachments as Attachment[]);
      })
  }

  /** helper function to map pouch objects to attachment objects */
  public mapObjectToAttachment(object: any): Attachment {
    let attachment: Attachment = new Attachment();
    return attachment = { ...object };
  }

  /**
   * Get attachment object, attachment._attachments will still need to be loaded by get attachment file
   */
  getAttachment(_id: string): Promise<Attachment> {
    return new Promise((resolve, reject) => {

      this.repository.fetchObject(Attachment.type, _id, Attachment.fields, ['_id'])
        .then(result => {
          const attachment: Attachment = result.docs.map((doc: any) => this.mapObjectToAttachment(doc))[0];
          resolve(attachment);
        }).catch(error => {
          console.error('An error occurred loading attachment: ', error);
          reject(error);
        });

    });
  }

  /**
   * This function returns the actual file blob stored in the _attachments property of a pouch object
   */
  getAttachmentFile(attachmentName: String, attachmentFileName: String = 'file'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.repository.getAttachmentFile(attachmentName, attachmentFileName)
        .then(response => {
          resolve(response as Attachment[]);
        })
        .catch(error => {
          console.error('An error occurred loading the attachment file', error);
          reject(error);
        });
    });
  }

  /** this function returns files in the _attachments array as object url string array */
  getAttachmentFilesAsObjectUrls(attachment: Attachment) {
    return new Promise((resolve, reject) => {

      Promise.all(
        Object.keys(attachment._attachments).map(
          attachmentFileName => {
            return this.getAttachmentFile(attachment._id, attachmentFileName)
              .catch(error => {
                console.log('Error in getAttachmentFilesAsObjectUrls', error);
                reject(error);
              });
          }
        )).then(
          attachmentFileBlobs => {
            const urls = attachmentFileBlobs.map(blob => {
              if (blob === undefined) { return; }
              const url = URL.createObjectURL(blob);
              const imgSrc: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
              const attachmentObj = {'url': imgSrc, 'mediaType': blob.type}
              return attachmentObj;
            });
            resolve(urls);
          });

    });
  }

  /** function to compress attachments */
  compressFile(file, settings = null): Promise<any> {
    if (!settings) {
      settings = {
        quality: 0.3
      }
    }
    const imageCompressor = new ImageCompressor();
    // return a promise with compressed file
    return new Promise((resolve, reject) => {
      imageCompressor.compress(file, settings)
        .then((compressedFile) => {
          console.log('File compressed');
          resolve(compressedFile);
        })
        .catch((err) => {
          console.log('file not compressed', err);
          reject(err);
        });
    })
  }

  /** convert base64 to raw binary data held in a string */
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  /**
   * Convert number of bytes into human readable format
   *
   * @param integer bytes     Number of bytes to convert
   * @param integer precision Number of digits after the decimal separator
   * @return string
   */
  bytesToSize(bytes, precision) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
    const terabyte = gigabyte * 1024;

    if ((bytes >= 0) && (bytes < kilobyte)) {
      return bytes + ' B';

    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
      return (bytes / kilobyte).toFixed(precision) + ' KB';

    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
      return (bytes / megabyte).toFixed(precision) + ' MB';

    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
      return (bytes / gigabyte).toFixed(precision) + ' GB';

    } else if (bytes >= terabyte) {
      return (bytes / terabyte).toFixed(precision) + ' TB';

    } else {
      return bytes + ' B';
    }
  }

}
