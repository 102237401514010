import { Component } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Patient } from '../models/patient';
import { Attachment, PatientRequest } from '../models/attachment';
import { AppSettings } from '../models/config';
import { AttachmentService } from '../services/attachment.service';
import { RepositoryService } from '../services/repository.service';
import { ValidationService } from '../validation/validation.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  template: `
  <h3 style="margin: 10px 0; text-align: center;">Success</h3>
  <p class="uk-text-center">A request to upload data has been saved.</p>
  <p class="uk-text-center">A notification to the patient will be sent when the narrative is saved.</p>

  <button type="button" mat-raised-button class="width100 uk-text-contrast" style="background: #71C341;"
    (click)="dialogRef.close(true)">OK</button>
  `
})
export class SuccessDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
  ) { }
}

export enum AreasOfRequest {
  prescription = 'Prescription',
  labOrRadiologyReport = 'Lab or radiology report',
  consultationReport = 'Consultation report',
  injuryOrAffectedPart = 'Injury or affected part'
}
@Component({
  selector: 'psoc-attachment-modal',
  providers: [AttachmentService],
  templateUrl: 'attachment-modal.html',
  styleUrls: ['attachment-modal.css']
})
export class AttachmentModal {
  public attachments: Attachment[] = [];
  public selectedAttachment: Attachment;
  thumbnails: Array<SafeUrl>; // list of thumbnails for selected attachment
  public patient: Patient;
  public narrativeId: string
  public appSettings: AppSettings;

  attachmentForm: FormGroup;
  newAttachmentType: string = 'normal';
  selectForm: FormGroup;

  file: File;
  imgSrc: SafeUrl;
  imgPreviewSrc: SafeUrl;
  uploadingFile: boolean = false;

  editMode: boolean = false; // check if on edit mode.
  showEdit: boolean = false;
  public disableEditing = false;
  disableEditButton: boolean = false;

  uploadImage: boolean; // keep track if adding image from local upload
  requestFromPatient: boolean;  // keep track if requesting the patient for picture
  requestAttachmentForm: FormGroup;  // patient request form
  areasOfRequest = this.repo.enumSelector(AreasOfRequest);  // list of data types to request for
  patientRequest: PatientRequest;

  constructor(
    private snackBar: MatSnackBar,
    private repo: RepositoryService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<any>,
    private dialogRef2: MatDialogRef<any>,
    private dialog: MatDialog,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService
  ) {
    /* set up attachments form */
    this.attachmentForm = new FormGroup({
      attachmentTitle: new FormControl('', [Validators.required, Validators.minLength(2)]),
      dateOnAttachment: new FormControl(new Date(), [Validators.required, ValidationService.dateInPastValidator]),
      attachmentFile: new FormControl('', Validators.required),
      dicomSearchId: new FormControl('', [Validators.required, Validators.minLength(3)]),
      dicomSearchName: new FormControl('', [Validators.required, Validators.minLength(3)])
    });

    this.selectForm = new FormGroup({ selectAttachment: new FormControl('') })

    /* load image when select changes */
    this.selectForm.get('selectAttachment').valueChanges.subscribe(attachment => {
      this.setAttachment(attachment);
    })

    // set up the request patient form
    this.requestAttachmentForm = new FormGroup({
      requestDescription: new FormControl('', [Validators.required]),
      areaOfRequest: new FormControl('', [Validators.required])
    });
  }

  /**
   * User has clicked the done button, return the attachment
   */
  done() {
    this.dialogRef.close(this.selectedAttachment);
  }

  /**
   * opt out, cancel request to patient, return to the attachment modal (show request patient)
   */
  cancelRequest() {
    this.requestAttachmentForm.reset();
    this.requestFromPatient = false;
  }

  /**
   * notify nurse - success pop up and close the attachment modal
   * send patient request to the narrative edit component, for saving & adding 'patientRequest' events
   */
  onSubmitRequest() {
    // notify nurse - success pop up
    this.dialogRef2 = this.dialog.open(SuccessDialogComponent, { maxWidth: '400px' });
    this.dialogRef2.afterClosed().subscribe(result => {
      if (!result) {
        return; // do nothing
      } else {
        this.dialogRef2 = null; // close success dialog
        this.dialogRef.close(this.patientRequest);  // close the attachment dialog and send the patient request
      }
    });
  }

  showAttachment(attachment: Attachment): void {
    if (!attachment || !attachment._id) { return };

    // load files for attachment viewer
    this.loadAttachmentThumbnails(attachment);

    // load single preview image
    if (attachment.mediatype === 'application/dicom') {
      this.imgPreviewSrc = 'assets/images/placeholder-dicom.png'
    } else {
      // load first file
      const firstFileName = Object.keys(attachment._attachments)[0];
      this.attachmentService.getAttachmentFile(attachment._id, firstFileName).then((blob) => {
        const url = URL.createObjectURL(blob);
        this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
        this.imgPreviewSrc = this.imgSrc;
      }).catch((err) => {
        this.imgSrc = null;
        this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
        console.log(err);
      });
    };
  }

  loadAttachmentThumbnails(attachment: Attachment) {
    this.thumbnails = [];
    Object.keys(attachment._attachments).forEach(attachmentFileName => {
      this.attachmentService.getAttachmentFile(attachment._id, attachmentFileName).then((blob) => {
        const url = URL.createObjectURL(blob);
        const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
        const urlSrc = { 'url': imgSrc, 'mediaType': blob.type }
        this.thumbnails.push(urlSrc);
      }).catch((err) => {
        this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
        console.log(err);
      });

    });
  }

  /**
   * function set edit mode true or false
   * @param editMode set the value from the parent component
   */
  public setDisableEdit(editMode: boolean) {
    this.disableEditButton = editMode;
  }

  public setAttachment(attachment: Attachment): void {
    if (!attachment || !attachment._id) {
      this.selectedAttachment = attachment;
      return;
    };

    if (attachment.reportLink && attachment.attachmentType === 'report') {  // for reports attached to pdf url
      this.selectedAttachment = attachment;
    } else if (!attachment._attachments) {  // if we don't have _attachments, reload the attachment
      console.log('Missing _attachments, attempt to reload full attachment object');
      this.attachmentService.getAttachment(attachment._id)
        .then(fullAttachment => {
          this.setAttachment(fullAttachment);
        }).catch(error => {
          console.log('Error loading attachment', error);
          const snackBarRef = this.snackBar.open('Unable to load attachment object, please try again', 'Retry');
          snackBarRef.onAction().subscribe(() => {
            this.setAttachment(attachment);
          });
        });
    } else {
      this.selectedAttachment = attachment;
      this.showAttachment(this.selectedAttachment);
    }
  }

  public setPatient(patient: Patient): void {
    this.patient = patient;
  }

  public setNarrativeId(narrativeId: string): void {
    this.narrativeId = narrativeId;
  }

  /**
   * set up the patient request, current block id and the current segment
   */
  public setPatientRequest(patientRequest: PatientRequest): void {
    this.patientRequest = patientRequest;
  }

  /** function set app settings */
  public setSettings(settings: AppSettings): void {
    this.appSettings = settings;
  }

  public setServices(service: AttachmentService, authService: AuthenticationService, repo: RepositoryService = this.repo) {
    this.attachmentService = service;
    this.repo = repo;
    this.authService.setUser(authService.getUser());
  }
  /**
   * Upload attachment file for processing
   */
  uploadAttachment(): void {
    this.uploadingFile = true;

    // compress the attachment
    this.attachmentService.compressFile(this.file)
      .then(file => {

        // new attachment object
        let attachment = new Attachment({
          _id: String((new Date().getTime())),
          id: ((new Date().getTime())),
          attachmentTitle: '',
          attachmentFileName: '',
          dateOnAttachment: '',
          mediatype: '',
          size: ''
        });


        // add compressed file to attachment object & set new attachment attributes
        attachment.file = file;
        attachment.size = this.attachmentService.bytesToSize(file.size, 2);
        const attachmentTitle = this.attachmentForm.controls['attachmentTitle'].value;
        const dateOnAttachment = this.attachmentForm.controls['dateOnAttachment'].value;
        const attachmentFileName = this.file.name;
        const mediaType = this.file.type;
        const sharedFacility: string[] = (this.patient.sharedFacility ? this.patient.sharedFacility : []);

        // save attachments to the attachments model
        attachment = new Attachment({
          _id: String((new Date().getTime())),
          id: ((new Date().getTime())),
          attachmentTitle: attachmentTitle,
          dateOnAttachment: dateOnAttachment,
          attachmentFileName: attachmentFileName,
          mediatype: mediaType,
          patientId: +this.patient._id,
          narrativeId: +this.narrativeId,
          sharedFacility: sharedFacility,
          file: file
        });

        // save new attachment object to db
        this.saveAttachment(attachment);

      }).catch((err) => {
        console.log('Error while compressing...' + err);
      });

  };

  fileSelected(event: EventTarget) {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    this.file = files[0];
  }

  unlinkAttachment() {
    this.selectedAttachment = null;
    this.editMode = true;
  }

  /**
  * Save the attachment to pouch db
  */
  saveAttachment(attachment: Attachment) {
    this.attachmentService.updateAttachment(attachment)
      .then(updatedAttachment => {
        this.uploadingFile = false;
        this.attachments.push(updatedAttachment);
        this.selectedAttachment = updatedAttachment;
        this.attachmentForm.reset();

        if (updatedAttachment.mediatype === 'application/dicom') {
          const message = `Dicom Study ${updatedAttachment.attachmentTitle} attached successfully`
          this.snackBar.open(`${message}`, 'Success', { duration: 6000 })
        } else {
          this.snackBar.open('Attachment was uploaded.', 'Success', { duration: 6000 });
        }
      }).catch(error => {
        this.uploadingFile = false;
        console.log('Error updating attachment: ', error);
        this.snackBar.open('Error updating attachment: ', 'Error', { duration: 6000 })
      });
  }

  editAttachment() {
    this.showEdit = true;
  }

  cancelEdit(originalAttachment: Attachment) {
    if (originalAttachment) {
      this.attachmentUpdated(originalAttachment);
      this.showEdit = false;
    } else {
      this.uploadImage = false;
      this.editMode = false;
    }
  }

  attachmentUpdated(updatedAttachment: Attachment) {
    this.showEdit = false;
    const index: number = this.attachments.indexOf(this.selectedAttachment);
    if (index > -1) { this.attachments.splice(index, 1); }
    this.attachmentAdded(updatedAttachment);
  }

  /**
   * Add new attachment to the list
   */
  attachmentAdded(addedAttachment: Attachment) {
    this.selectedAttachment = addedAttachment;
    this.loadAttachmentThumbnails(addedAttachment);
    this.attachments.push(addedAttachment);
  }

}
