import { Component, Input } from '@angular/core';
import { HeartBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-heart-nurse-view',
  templateUrl: './heart-nurse-view.component.html'
})

export class HeartNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  _heartBlock: HeartBlock;
  @Input()
  set heartBlock(heartBlock: HeartBlock) {
    this._heartBlock = heartBlock;
    this.getAttachmentFilesCount(heartBlock.attachmentIds);
  }
  get heartBlock(): HeartBlock {
    return this._heartBlock;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.heartBlock && this.heartBlock.blockStatus) ? true : false;
  }
}
