import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FoetalPresentationBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockEditComponent } from '../block-edit.component';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';

@Component({
  selector: 'psoc-foetal-presentation-nurse-edit',
  templateUrl: './foetal-presentation-nurse-edit.component.html'
})

export class FoetalPresentationNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
  _blockData: FoetalPresentationBlock;
  @Output() blockDataChange: EventEmitter<FoetalPresentationBlock> = new EventEmitter<FoetalPresentationBlock>();
  @Input() get blockData() { return this._blockData }
  set blockData(blockData: FoetalPresentationBlock) {
    if (blockData) { this._blockData = blockData } else { this._blockData = new FoetalPresentationBlock(); }
    this.blockDataChange.emit(this._blockData);
    this.getAttachmentFilesCount(this._blockData.attachmentIds);
  };

  blockStatusOptions = ['Cephalic', 'Breech', 'Shoulder', 'Not clear'];
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService,
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

    this.form = this.fb.group({
      blockStatus: ['', [Validators.required]],
      nurseNotes: ['']
    });
  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
  }
}
