import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HeadAndSpineBlock, FoetusBlocks } from '../../../../models/obstetric-narrative';
import { SonographerBlockHeadAndSpine } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';
import { ValidationService } from '../../../../validation/validation.service';
import { GestationAgeByCrownrumpLength, GestationAgeByBPD, GestationAgeByHC } from '../../../../models/narrative-types';

@Component({
  selector: 'psoc-head-spine-sonographer-edit',
  templateUrl: 'head-spine-sonographer-edit.component.html'
})

export class HeadAndSpineSonographerEditComponent extends SonographerBlockEditComponent {
  @Input() firstTrimester: Boolean;
  @Input() nurseData: HeadAndSpineBlock;
  @Input() blockData: SonographerBlockHeadAndSpine;
  @Input() selectedFoetus: number;
  @Input() numberofFoetus: FoetusBlocks;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;
  form: FormGroup;
  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];

  crownRumpLengthValid = false;
  biparietalDiameterValid = false;
  headCircumferenceValid = false;

  constructor(
    private _fb: FormBuilder
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: [''],
      crownRumpLength: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByCrownrumpLength))],
      biparietalDiameter: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByBPD))],
      headCircumference: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByHC))],
      sonographerNotes: [''],
      cantConfirmCrownRumpLength: [''],
      cantConfirmHeadCircumference: [''],
      cantConfirmBiparietalDiameter: ['']
    });
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    let blockValid = this.blockData.blockStatus  ?  true : false;

    if (!this.firstTrimester) {
    this.biparietalDiameterValid = (this.blockData.biparietalDiameter > 0 || this.blockData.cantConfirmBiparietalDiameter);
    this.headCircumferenceValid = (this.blockData.headCircumference > 0 || this.blockData.cantConfirmHeadCircumference);
      blockValid = blockValid && (this.biparietalDiameterValid && this.headCircumferenceValid);
    } else {
      this.crownRumpLengthValid = this.blockData.crownRumpLength > 0 || this.blockData.cantConfirmCrownRumpLength;
      blockValid = blockValid && this.crownRumpLengthValid;
    }

    return blockValid;
  }

}
