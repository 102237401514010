import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Narrative } from '../../../models/narrative';
import { Attachment } from '../../../models/attachment';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { AttachmentModal } from '../../../attachments/attachment-modal';
import { AttachmentService } from '../../../services/attachment.service';
import { DicomModalComponent} from '../../../attachments/dicom/modal/dicom-modal.component';

@Component({
  selector: 'psoc-updates-block',
  templateUrl: './updates-block.component.html'
})
export class UpdatesBlockComponent {
  @Input() updates: Narrative;
  @Input() narrative: Narrative;
  myAttachmentsModel: Attachment;

  // Dialog
  dialogRef: MatDialogRef<any>;
  selectedAttachment: Attachment;
  config: MatDialogConfig = { width: '95vw', height: '95vh' };

  @ViewChild (DicomModalComponent) public dicomModal: DicomModalComponent

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private attachmentService: AttachmentService
  ) { }

  openAttachmentModal(attachment: Attachment): void {
    this.dialogRef = this.dialog.open(AttachmentModal, this.config);
    this.dialogRef.componentInstance.setServices(this.attachmentService);
    this.dialogRef.componentInstance.setAttachment(attachment);
    this.dialogRef.componentInstance.disableEditing = true;
  }

  mediatorViewSharedLinkReport(sharedLinkId: any): void {
    console.log(sharedLinkId);
    this.router.navigate(['narrative/report/' + sharedLinkId]);
  }

  mediatorViewSharedLinkDischargeSummary(sharedLinkId: any): void {
    console.log(sharedLinkId);
    this.router.navigate(['narrative/discharge-summary/' + sharedLinkId]);
  }

  /**
   *  Open dialog to view study
  */
  viewDicomDialog(studyUID: string) {
    this.dicomModal.showStudy(studyUID)
  }

}
