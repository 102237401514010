/**
 * Enquiry object stores patient enquiries from textit.in
 */
import { PouchObject } from './pouch-object';

export class Enquiry implements PouchObject {
  public static type: string = 'enquiry';
  public static fields = ['_id', '_rev', 'uuid', 'type', 'registrationNumber', 'schemeType', 'schemeName', 'name', 'phonenumber',
    'createdBy', 'dateAdded', 'updatedBy', 'dateUpdated', 'createFacility', 'updateFacility', 'patientId'];
  registrationNumber: any;
  schemeType?: string;
  schemeName?: string;
  name: string;
  uuid?: string;
  phonenumber?: string;
  insurer?: string;
  patientId?: number; // record patientId once matched in the system

  // pouchobject metadata
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  dateAdded: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  createFacility: string;
  updateFacility: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}
