import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Question } from '../../models/narrative';
import { Patient } from '../../models/patient';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'psoc-delete-question-dialog-component',
    template: `<h2>Would you like to delete this question segment?</h2>
                This question segment will be deleted
                <p>
                    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Ok</button>
                    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">Cancel</button>
                </p>`
})
export class DeleteQuestionDialogComponent {
    constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
    selector: 'psoc-questions-edit',
    templateUrl: './questions-edit.component.html',
})
export class QuestionsEditComponent implements OnInit {
    @Input() patient: Patient;
    @Input() questions: Question[];
    @Input() questionsForm: FormGroup;
    @Input() referralType: string;

    dialogRef: MatDialogRef<any>;

    // track validity of controls for UI
    questionsBlockValid: boolean = true;

    // track whether to display questions block or not
    showQuestionsBlock = true;

    // confirm dialog variables
    selectedQuestionToDelete: number = null;

    constructor(
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private ref: ChangeDetectorRef
    ) {
        // initialize empty questions model
        this.questions = new Array<Question>();
    }

    ngOnInit(): void {
        this.refreshOrInitializeQuestionsFormControls();
        // open showQuestionsBlock by default
        if (this.questions.length < 1) { this.addQuestionSegment(-1); }
    }

    // set up form controls for each question object
    refreshOrInitializeQuestionsFormControls(): void {
        this.questions.forEach(
            (questionBlockSegment) => {
                (<FormArray>this.questionsForm.controls['questions']).push(this.createNarrativeQuestion(questionBlockSegment));
            });
    }

    // create narrative question form controls
    createNarrativeQuestion(question: Question): FormGroup {
        return this._fb.group({
            question: [question.question, [Validators.required, Validators.minLength(5)]],
            answer: [question.answer],
            dateAdded: [new Date(question.dateAdded)]
        });
    }

    /** handles the adding of question segment */
    addQuestionSegmentFromForm(index: number) {
        this.addQuestionSegment(index);
    }

    /** Function add question segment */
    addQuestionSegment(index: number) {
        this.toggleQuestionsBlock(true);
        const emptyQuestionBlockSegment: Question = new Question();
        this.questions.splice(index + 1, 0, emptyQuestionBlockSegment);
        (<FormArray>this.questionsForm.controls['questions']).push(this.createNarrativeQuestion(emptyQuestionBlockSegment));
    }

    /** delete question segment dialog */
    confirmDeleteQuestion(index: number) {
        this.dialogRef = this.dialog.open(DeleteQuestionDialogComponent);

        this.dialogRef.afterClosed().subscribe(result => {
            // user selects confirm yes
            if (result === true) {
                this.selectedQuestionToDelete = index;
                this.questions.splice(index, 1);
                (<FormArray>this.questionsForm.controls['questions']).removeAt(index);
                this.selectedQuestionToDelete = null;
                this.ref.detectChanges();
            }
            this.dialogRef = null;
        });
    }

    // validate entire questions block
    isQuestionsBlockValid(): boolean {
        let fieldValid = true;
        (<FormArray>this.questionsForm.controls['questions']).controls.forEach(field => {
            if (!field.valid) { fieldValid = false }
            this.questionsBlockValid = fieldValid;
        });

        return this.questionsBlockValid;
    }

    // hide or show questions block
    toggleQuestionsBlock(showQuestionsBlock: boolean): void {
        if (showQuestionsBlock) {
            this.showQuestionsBlock = true;
        } else {
            if (this.showQuestionsBlock) {
                this.showQuestionsBlock = false;
            } else {
                this.showQuestionsBlock = true;
            }
        }
    }

}
