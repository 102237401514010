import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { Globals } from '../services/globals';
import { RepositoryService } from '../services/repository.service';

@Component({
  selector: 'login',
  templateUrl: 'login.component.web.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginModel: User;
  loginProcessing: Boolean = false;
  loginProcessingMessage: String;
  loadingProfileFailed: Boolean = false;
  returnUrl: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private repository: RepositoryService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private globals: Globals,
    private route: ActivatedRoute
  ) {
    this.loginModel = new User({
      username: '',
      password: ''
    });

    // set current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }

  ngOnInit() {
    // set up login form
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/patients';
  }

  login() {
    const username = this.loginModel.username, password = this.loginModel.password;

    // check if form valid
    if (!this.loginForm.valid) {
      this.snackBar.open('Form is invalid', 'Error', { duration: 6000 });
      return false;
    }

    // check if userdb exists
    this.loginProcessing = true; this.loginProcessingMessage = 'Checking for local user database';
    let key: string; // facility key
    this.authService.checkLocalUserDb(username, password)
      .then(checkLocalUserDbsSuccess => {
        // offline user db found, login offline
        // console.log('local db exists');
        this.loginProcessingMessage = 'Processing offline login';
        this.authService.loadFacilityKeyLocal(username, password)
          .then((result: any) => {
            // successfully logged in offline, allow user to work
            // console.log(result.user, result.encryptionKey);
            key = result.encryptionKey;
            this.snackBar.open('Successful Offline login', 'Success', { duration: 6000 });
            this.repository.loadMainDb(result.user.facility, key);
            this.authService.setUser(new User(result.user));

            console.log('Offline login OK, Attempt navigate to: ', [this.returnUrl]);

            const block = this.route.snapshot.queryParamMap.get('block');
            const foetus = this.route.snapshot.queryParamMap.get('foetus');

            if (block || foetus) {
              const queryParams = { block: block, foetus: foetus };
              console.log('return url and params', this.returnUrl, queryParams);
              this.router.navigate([this.returnUrl], { queryParams: queryParams });
            } else {
              this.router.navigate([this.returnUrl]);
            }

          })
          .then(() => {

            // allow user to work in background while we attempt online login
            if (navigator.onLine) {

              console.log('Offline login successful, Attempting online')
              this.authService.userLogin(username, password)
                .then(response => {
                  // online auth successful, load profile
                  this.loadUserProfile(username)
                    .then(result => {

                      const user = this.authService.getUser();
                      // get user ip
                      this.authService.getClientIp()
                        .then(ip => {
                          user.ipAddress = ip;
                          this.authService.setUser(user);
                          console.log('user IP: ' + user.ipAddress);

                          // encryption key loaded, create localUserDb
                          this.authService.createUserDb(user, username, password, key.toString());
                        })
                        .catch(err => {
                          // error loading user ip, create user db anyway
                          this.authService.setUser(user);
                          this.authService.createUserDb(user, username, password, key.toString());
                          this.snackBar.open('Unable to load user ip' + err, 'Error', { duration: 6000 });
                        });

                    }).catch(err => {
                      // error loading user profile
                      this.loadingProfileFailed = true;
                      this.snackBar.open('Error loading user profile: ' + err, 'Error', { duration: 6000 });
                    });

                  // check for unsynced objects
                  this.repository.checkUnsyncedIncouch()
                    .then(res => console.log(res))
                    .catch(error => console.log(error));

                }).catch(err => {
                  // error logging in to remote db
                  this.snackBar.open(err, 'Info', { duration: 6000 });
                });

            }

          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.loginProcessing = false;
            this.snackBar.open('Error with offline login: Please ensure you are using the same password you used '
              + '\on your last successful login for this user on this device.', 'Error');
          });
      })
      .catch(checkLocalUserDbError => {
        // no offline user db, login online
        console.log('local db does not exist, attempting online login');

        this.loginProcessing = true; this.loginProcessingMessage = 'Requesting authorization';

        this.authService.userLogin(username, password, true)
          .then(response => {
            // online auth successful, load profile
            this.loginProcessing = false;
            this.loginProcessingMessage = 'Access granted, Loading profile';
            this.loadUserProfile(username)
              .then(result => {
                const user = this.authService.getUser();
                this.authService.setUser(user);

                // profile loaded, get facility key
                this.authService.loadFacilityKeyRemote(user.facility)
                  .then(encryptionKey => {
                    key = encryptionKey.toString()
                    // load main database based on user location and begin sync
                    this.repository.loadMainDb(user.facility, key);
                    console.log('Online login OK, Attempt navigate to: ', [this.returnUrl]);
                    this.router.navigate([this.returnUrl]);

                    // get user ip
                    this.authService.getClientIp()
                      .then(ip => {
                        user.ipAddress = ip;
                        this.authService.setUser(user);

                        // encryption key loaded, create localUserDb
                        this.authService.createUserDb(user, username, password, key);
                      }).catch(
                        err => {
                          // error loading user ip, create offline db anyway
                          this.authService.createUserDb(user, username, password, key);
                          this.snackBar.open('Unable to load user ip' + err, 'Error', { duration: 6000 });
                        });
                  })
                  .catch(err => {
                    // error loading encryption key
                    this.snackBar.open('Unable to load encryption key' + err, 'Error', { duration: 6000 });
                  });
              })
              .catch(error => {
                // error loading remote user profile
                this.loadingProfileFailed = true;
                this.snackBar.open('Sorry, error getting profile' + error, 'Error', { duration: 6000 });
              });

          })
          .catch(error => {
            this.loginProcessing = false;
            console.log('Error in authService.userLogin', error);
            if (error['message'] === undefined) {
              const err = `An error occurred, if you are logging in with this username on this device for the first time
                please ensure that you are connected to the internet. If you have previously logged in with this username
                on this device please ensure that you have the correct username and password.`;
              this.snackBar.open(err, 'Error', { duration: 10000 });
            } else {
              this.snackBar.open(error['message'], 'Error', { duration: 6000 });
            }
            this.loginModel = new User({
              username: this.loginModel.username,
              password: ''
            });
          });


      });
  }

  retryLoadProfile() {
    this.loadingProfileFailed = false;
    this.loadUserProfile(this.loginModel.username);
  }

  loadUserProfile(name: string): Promise<User> {
    console.log('Attempting to load user profile for: ' + name);
    return new Promise((resolve, reject) => {

      this.authService.loadRemoteUser(name)
        .then(result => {
          this.snackBar.open('Successful Online login', 'Success', { duration: 6000 });
          resolve(result);
        })
        .catch(error => {
          this.loadingProfileFailed = true;
          this.snackBar.open('Sorry, error getting profile' + error, 'Error', { duration: 6000 });
          reject(error);
        });

    });

  }

}
