import { Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentService } from '../../../../services/attachment.service';
import { FoetalPresentationBlock } from '../../../../models/obstetric-narrative';
import { BlockPdfReportViewComponent } from '../block-pdf-report-view.component';
import { SonographerBlockFoetalPresentation } from '../../../../models/obstetric-sharelink';

@Component({
  selector: 'psoc-foetal-presentation-pdf-report-view',
  templateUrl: 'foetal-presentation-pdf-report-view.component.html'
})

export class FoetalPresentationPdfReportViewComponent extends BlockPdfReportViewComponent implements OnInit {
  @Input() nurseData: FoetalPresentationBlock;
  @Input() sonographerData: SonographerBlockFoetalPresentation;

  constructor(
    public sanitizer: DomSanitizer,
    public attachmentService: AttachmentService,
  ) {
    super(sanitizer, attachmentService);
  }

  ngOnInit() {
    this.getAttachments(this.nurseData);
  }
}
