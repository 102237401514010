import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NarrativeBlock } from '../../../models/narrative-block';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../../validation/validation.service';

@Component({
  selector: 'narrative-block-add',
  templateUrl: 'narrative-block-add.component.html'
})

export class NarrativeBlockAddComponent {
  _narrativeBlocks: NarrativeBlock[];
  @Input() set narrativeBlocks(narrativeBlocks: NarrativeBlock[]) {
    this._narrativeBlocks = narrativeBlocks;
    this.blocksIndexes = this.getBlocksIndexes(this.narrativeBlocks);
  } get narrativeBlocks(): NarrativeBlock[] { return this._narrativeBlocks; }
  @Output() updatedNarrativeBlock: EventEmitter<NarrativeBlock> = new EventEmitter<NarrativeBlock>();
  
  @Output() cancelEditing: EventEmitter<NarrativeBlock> = new EventEmitter<NarrativeBlock>();
  narrativeBlockForm: FormGroup;
  uneditedNarrativeBlock: NarrativeBlock; // store the original version of the narrative block
  _narrativeBlock: NarrativeBlock;
  @Input() set narrativeBlock(narrativeBlock: NarrativeBlock) {
    this.uneditedNarrativeBlock = new NarrativeBlock(narrativeBlock); // store the original version of the narrative block
    this._narrativeBlock = narrativeBlock;
    if (this._narrativeBlock._id) {
      this.blocksIndexes = this.getBlocksIndexes(this.narrativeBlocks);
    }
  } get narrativeBlock(): NarrativeBlock { return this._narrativeBlock; };
  blocksIndexes:Array<Number>;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.narrativeBlockForm = this.formBuilder.group({
      'blockname': ['', Validators.required],
      'blockIndex': ['', Validators.required],
      'showQuestion': [''],
      'showBlock': [''],
      'showAnswer': [''],
      'hasAttachments': [true],
    });
  }

  resetForm(action: string = null) {
    if (!action) { this.narrativeBlock = new NarrativeBlock(); };
    this.narrativeBlockForm.reset();
  }

  editCancel() {
    this.cancelEditing.emit(this.uneditedNarrativeBlock); // emit original block
    this.resetForm('cancelEdit');
  }

  /**
   * update narrative block 
   */
  addNarrativeBlock() {
    const blockname = this.narrativeBlock.blockname.trim();

    if (!blockname) {
      this.snackBar.open('Please enter a block name', 'Error', { duration: 6000 });
      return;
    }

    if (this.narrativeBlock.showBlock === undefined) { this.narrativeBlock.showBlock = false; }
    if (this.narrativeBlock.showQuestion === undefined) { this.narrativeBlock.showQuestion = false; }
    if (this.narrativeBlock.showAnswer === undefined) { this.narrativeBlock.showAnswer = false; }
    if (this.narrativeBlock.hasAttachments === undefined) { this.narrativeBlock.hasAttachments = false; }

    this.updatedNarrativeBlock.emit(this.narrativeBlock);
    this.resetForm();
  }

  /**
   * check the forms validity 
   */
  isNarrativeBlockValid(): boolean {
    return this.narrativeBlockForm.dirty && this.narrativeBlockForm.valid;
  }

  /**
   * get the unique blocks indices 
   */
  getBlocksIndexes(narrativeBlocks: NarrativeBlock[]) {
    if (this.narrativeBlock && this.narrativeBlock._id) {
      return narrativeBlocks ? (narrativeBlocks.filter(narBlock => narBlock._id !== this.narrativeBlock._id))
      .filter(block => typeof block.blockIndexId==='number')
      .map(block => block.blockIndexId) : [];
    } else {
      return narrativeBlocks ? narrativeBlocks
      .filter(block => typeof block.blockIndexId==='number')
      .map(block => block.blockIndexId) : [];
    }
  }

  /**
   * function to add `blockIndexValidation` validators to block index control; ensure unique indexes
   * @param blockIndex the block index entered for a new block
   */
  onKey(val: any) {
    if (val !== null) {
      this.narrativeBlockForm.controls['blockIndex'].setValidators([Validators.required, ValidationService.blockIndexValidation(this.blocksIndexes)]);
    }
  }
}
