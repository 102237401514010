// import { PouchObject } from './pouch-object';
// import { Patient } from './patient';

// export class ObstetricSharelink implements PouchObject {
//     public static type: string = 'obstetricSharelinks';
//     public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'updatedBy', 'createdBy', 'createFacility',
//     'updateFacility', 'sharedFacility', 'dateAdded', 'dateUpdated', 'patient', 'patientId', 'obstetricNarrativeID',
//     'obstetricNarrativeBlocks', 'sonographerInfo', 'intent', 'expired', 'published', 'datePublished',
//     'shortUrl', 'dbInfo', 'replicationInfo', 'replicationIds']

//     // pouch object metadata fields
//     id: number;
//     _id: string;
//     _rev: string;
//     _deleted: boolean = false;
//     type: string;
//     updatedBy: string;
//     createdBy: string;
//     createFacility: string;
//     updateFacility: string;
//     sharedFacility: string[];
//     dateAdded: Date;
//     dateUpdated: Date;
//     patient: Patient;
//     patientId: number;

//     obstetricNarrativeID: number;
//     obstetricNarrativeBlocks: ObstetricNarrativeBlocks[] = new Array<ObstetricNarrativeBlocks>();
//     sonographerInfo: SonographerInfo;

//     intent: string;
//     expired?: boolean = false;
//     published: boolean = false;
//     datePublished?: Date;

//     // short url version of link
//     shortUrl?: string

//      // diagnostics
//     dbInfo: any[];
//     replicationInfo: any[];

//      // replications
//     replicationIds: string[];

// }

export class SonographerBlock {
    blockType: string;
    blockStatus: string;
    sonographerComment: string;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class UltrasoundRecord {
    scanningClinician: string;
    scanFacility: string;
    dateAdded: Date;
    scanIndication: string;
    gravida: string;
    para: string;
    dateOfLNMP: string;
    gestationAge: number;
    fetalWeight: number;
    actualGestationAge: string;
    dateOfDeliveryFrom: string;
    dateOfDelivery: Date;
    dateOfDeliveryByScan: Date;
    dLMPUnknown: boolean;


    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class SonographerBlocks {
    numberOfFoetusBlock: SonographerBlockNumberOfFoetus = new SonographerBlockNumberOfFoetus();
    foetuses: SonographerFoetusBlock[] = new Array<SonographerFoetusBlock>();
    maternalAnatomyBlock: SonographerBlockMaternalAnatomy = new SonographerBlockMaternalAnatomy();
    sonographerBlockRecommendations: SonographerBlockRecommendations = new SonographerBlockRecommendations();

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class SonographerBlockNumberOfFoetus extends SonographerBlock {
    blockType: string = 'numberOfFoetus';
    numberOfFoetus: number;
    cantConfirmNumberOfFoetus: boolean;
}

export class SonographerFoetusBlock {
    foetalLieBlock: SonographerBlockFoetalLie = new SonographerBlockFoetalLie();
    foetalPresentationBlock: SonographerBlockFoetalPresentation = new SonographerBlockFoetalPresentation();
    heartBlock: SonographerBlockHeart = new SonographerBlockHeart();
    placentaBlock: SonographerBlockPlacenta = new SonographerBlockPlacenta();
    amnioticFluidBlock: SonographerBlockAmnioticFluid = new SonographerBlockAmnioticFluid();
    foetalLimbsBlock: SonographerBlockFoetalLimbs = new SonographerBlockFoetalLimbs();
    foetalAbdomenBlock: SonographerBlockFoetalAbdomen = new SonographerBlockFoetalAbdomen();
    headAndSpineBlock: SonographerBlockHeadAndSpine = new SonographerBlockHeadAndSpine();
}

export class SonographerBlockFoetalLie extends SonographerBlock {
    blockType: string = 'foetalLie';
}

export class SonographerBlockFoetalPresentation extends SonographerBlock {
    blockType: string = 'foetalPresentation';
}
export class SonographerBlockHeart extends SonographerBlock {
    blockType: string = 'heart';
    heartbeatFrequency: number;
    cantConfirmHeartbeatFrequency: boolean;
}

export class SonographerBlockPlacenta extends SonographerBlock {
    blockType: string = 'placenta';
}

export class SonographerBlockAmnioticFluid extends SonographerBlock {
    blockType: string = 'amnioticFluid';
    quadrant1: number;
    quadrant2: number;
    quadrant3: number;
    quadrant4: number;
    amnioticFluidIndex: number;
    cantConfirmQuadrant1: boolean;
    cantConfirmQuadrant2: boolean;
    cantConfirmQuadrant3: boolean;
    cantConfirmQuadrant4: boolean;
}

export class SonographerBlockFoetalLimbs extends SonographerBlock {
    blockType: string = 'foetalLimbs';
    femurLength: number;
    cantConfirmFemurLength: boolean;
}

export class SonographerBlockFoetalAbdomen extends SonographerBlock {
    blockType: string = 'foetalAbdomen';
    abdominalCircumference: number;
    cantConfirmAbdominalCircumference: boolean;
}

export class SonographerBlockHeadAndSpine extends SonographerBlock {
    blockType: string = 'headAndSpine';
    crownRumpLength: number;
    biparietalDiameter: number;
    headCircumference: number;
    cantConfirmCrownRumpLength: boolean;
    cantConfirmBiparietalDiameter: boolean;
    cantConfirmHeadCircumference: boolean;
}

export class SonographerBlockMaternalAnatomy extends SonographerBlock {
    blockType: string = 'maternalAnatomy';
}

export class SonographerBlockRecommendations {
    blockType: string = 'recommendations';
    recommendations: string;
    overallImpression: string;
    reviewRate: number;
    reviewComment: string;
}
