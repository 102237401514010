import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { Patient } from '../../../models/patient';
import { Attachment } from '../../../models/attachment';

import { AttachmentService } from '../../../services/attachment.service';
import { DicomService, Study } from '../../../services/dicom.service';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'psoc-dicom-upload',
  templateUrl: 'dicom-upload.component.html',
  styleUrls: ['dicom-upload.component.css'],
  providers: [DicomService]
})

export class DicomUploadComponent implements OnInit {
  @Input() attachments: Attachment[];
  @Input() patient: Patient;
  @Input() narrativeId: string;
  @Output() attachmentAdded: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  dicomUploadForm: FormGroup;
  dicomFiles: FileList;
  uploadingFiles: boolean = false;

  uploadedStudy = new Array<Study>();
  countSuccess: number = 0;
  countFail: number = 0;
  dicomUploadedSuccess: boolean = false;
  dicomUploadedFailed: boolean = false;

  deviceInfo = null;

  constructor(
    private snackBar: MatSnackBar,
    private dicomService: DicomService,
    private deviceService: DeviceDetectorService,
    private attachmentService: AttachmentService
  ) {
    this.dicomUploadForm = new FormGroup({
      attachmentFile: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    // get device info
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  dicomFileSelected(event: any) {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    this.dicomFiles = target.files;
  }

  /**
   * function to clear the selected files
   */
  removeFiles() {
    this.dicomFiles = null;
    this.dicomUploadForm.reset();
  }

  /**
  * Upload dicom files to orthanc & an instance of dicom files to couchdb
  */
  uploadDicomFiles(event: Event): void {
    event.preventDefault();
    this.countFail = 0;
    this.countSuccess = 0;
    this.uploadedStudy = [];
    this.uploadingFiles = true;

    // display error if no files selected
    if (this.dicomFiles.length < 1) {
      this.snackBar.open('You must select at least one dicom file to upload', 'Dismiss', { duration: 6000 });
      this.uploadingFiles = false;
    }

    Array.from(this.dicomFiles).forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {

        // check if uploading has been canceled
        if (!this.uploadingFiles) { return; }

        this.dicomService.storeStudies(reader.result)
          .then((study: Study) => {
            this.dicomUploadedSuccess = true;
            this.countSuccess++; // increment the counter

            /**TODO: * find out why the check of object in an array always returns false
                     * even if the study object are the same
                     * change this block of code
                     @ uploadedStudy.includes(study)        // returns false
                     @ uploadedStudy.indexOf(study) === -1  // returns false
            ************************************************************/
            // only add studies that are not identical
            this.uploadedStudy[study.StudyInstanceUID] = study;

            // check if uploading has been canceled
            if (!this.uploadingFiles) { return; }
            this.successUpload();
          })
          .catch(err => {
            console.log('Error uploading', err);
            this.countFail++;
            this.dicomUploadedFailed = true;
            // let errMessage = '';

            // switch (err.status) {
            //   case 504:
            //     errMessage = 'Error Uploading dicom file, System Timeout';
            //     break;

            //   case 500:
            //     errMessage = 'Error Uploading dicom file, Server Connection Interupted';
            //     break;

            //   case 0:
            //     errMessage = `Error Uploading dicom file, ${err.statusText}`;
            //     break;

            //   default:
            //     errMessage = `Error Uploading dicom file, ${err.error.OrthancError}`;
            //     break;
            // }

            this.successUpload();
            // display error to user
            // this.snackBar.open(errMessage, 'Error', { duration: 6000 });
          });
      }

      reader.readAsArrayBuffer(file);
      return false;
    });
  }

  /**
   * function to save study after succcess upload
   */
  successUpload() {
    // confirm that the loop and that all the files are saved in pacs
    if ((this.countSuccess + this.countFail) === this.dicomFiles.length) {

      // all files failed to upload
      if (Object.keys(this.uploadedStudy).length === 0 && this.countFail > 0) {
        this.dicomUploadedSuccess = false;
        this.snackBar.open(`${this.countFail} Files failed to upload`, 'Error', { duration: 6000 });
        this.uploadingFiles = false;
      }

      // save an instance of a study in our database
      Object.keys(this.uploadedStudy).forEach(uploadedStudyUid => {
        // push the study to save
        this.saveStudy(this.uploadedStudy[uploadedStudyUid], true);
      });
    } else {
      console.log('Not yet completed!!');
    }
  }

  /**
  * create an attachment to upload to database
  */
  saveStudy(study: Study, upload: boolean = false) {
    // get the attachments titles
    const attachmentStudyUuid: string[] = [];
    this.attachments.forEach((attachment: Attachment) => {
      if (attachment.mediatype === 'application/dicom') {
        attachmentStudyUuid.push(attachment.studyUuid);
      }
    })

    // check if attachent title already exist in the list of attachments
    if (!attachmentStudyUuid.includes(study.StudyInstanceUID)) {
      // save attachments to the attachments model
      const attachment = new Attachment({
        _id: String((new Date().getTime())),
        id: ((new Date().getTime())),
        attachmentTitle: study.dicomTitle,
        patientId: +this.patient._id,
        narrativeId: +this.narrativeId,
        sharedFacility: this.patient.sharedFacility ? this.patient.sharedFacility : '',
        dateOnAttachment: study.studyDate ? moment(study.studyDate, 'YYYYMMDD').toDate() : new Date('Jan 01, 1900'),
        attachmentFileName: study.patientName,
        mediatype: 'application/dicom',
        studyUuid: study.StudyInstanceUID
      });

      // call function to save the attachment
      this.addAttachment(attachment);

    } else {
      this.uploadingFiles = false;
      this.removeFiles(); // reset the upload form

      const message = `${this.countSuccess} Files uploaded and added to attachment ${study.dicomTitle}
                        ${this.countFail} Files Failed to upload`;
      this.snackBar.open(`${message}`, 'Ok', { duration: 6000 });
    }
  }


  /**
   * function to save attachment to the database
   * @param myAttachmentsModel the attachment to be saved to pouchdb
   * @param uploaded indicator if the dicom file to be saved was a search or uploaded
   */
  addAttachment(myAttachmentsModel): void {
    this.attachmentService.updateAttachment(myAttachmentsModel)
      .then(attachment => {

        this.uploadingFiles = false;
        this.removeFiles(); // reset the upload form
        this.attachmentAdded.emit(attachment);

        const message = `${this.countSuccess} Files uploaded and added to attachment ${attachment.attachmentTitle}
               ${this.countFail} Files Failed to upload`;
        this.snackBar.open(`${message}`, 'Success', { duration: 6000 });
      })
      .catch(err => {
        this.uploadingFiles = false;
        console.log('Error uploading attachment:', err);
        this.snackBar.open('ERROR: Attachment was Not uploaded', 'Error', { duration: 6000 });
      });
  }
}
