import { PouchObject } from './pouch-object';

export class Appointment implements PouchObject {
  public static type: string = 'appointment';
  public static fields = ['_id', '_rev', '_deleted', 'type', 'patientId', 'sharelinkId', 'assignedMediator', 'specialistName',
    'specialistEmail', 'confirmed', 'confirmedAppointmentDate', 'timeAppointment', 'dateTimeSuggested', 'dateAdded', 'dateUpdated',
    'createdBy', 'updatedBy', 'updateFacility', 'createFacility', 'sharedFacility', 'appointmentType'];

  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  patientId: number;
  sharelinkId: string;
  assignedMediator: string;
  specialistName: string;
  specialistEmail: string;
  confirmed: boolean;
  appointmentType?: string;
  confirmedAppointmentDate: string;
  timeAppointment: string;
  dateTimeSuggested: string[];
  dateAdded: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  updateFacility: string;
  createFacility: string;
  sharedFacility: string[];

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

// a class for time and date
export class SuggestedTimes {
  timestamp: string;

  constructor(timestamp: string) { }
}
