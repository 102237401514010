import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { Narrative } from '../../../models/narrative';
import { NarrativeService } from '../../../services/narrative.service';
import { MatSnackBar } from '@angular/material';
import { RepositoryService } from '../../../services/repository.service';
import { ShareLinks } from '../../../models/sharelinks';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'psoc-shared-links-radiology',
  templateUrl: 'shared-links-radiology.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SharedLinksRadiologyComponent implements OnInit {
  @Input() sharedLinkPatientId: string;
  @Input() myNarrative: Narrative;
  @Input() mySharedLink: ShareLinks;

  sharelinkId: string;
  backupSharelink: ShareLinks;
  pdfTitle: string = 'Teleconsultation Radiology Order';
  disclaimer: string = 'This telemedicine radiology request  is provided by Strathmore University Medical Centre to the patient whose details are included below. The request is based solely on information obtained through electronic communication, which may not be as accurate or comprehensive as information that may be obtained from an in-person consultation. The patient/guardian has acknowledged that they have understood the benefits, risks, and alternatives, and has consented to a telemedicine consultation.';

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private repository: RepositoryService,
    private authService: AuthenticationService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // get sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id']

    // set up databases
    this.repository.loadSpecialistDb(this.sharelinkId);

    // load sharelink data
    this.getSharelinkData();
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  /** function get sharelinks data */
  getSharelinkData() {
    console.log('attempt load sharelink for ' + this.sharelinkId);
    this.patientService.getSingleSharedLinkData(this.sharelinkId)
      .then(sharelink => {
        this.mySharedLink = sharelink;
        if (!this.mySharedLink.radiologyInvestigations) { this.mySharedLink.radiologyInvestigations = [] }
        this.backupSharelink = new ShareLinks(sharelink);

        // get user info to set global
        const user = new User({
          username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
          facility: sharelink.createFacility,
          userRole: 'specialist'
        })
        this.authService.setUser(user);
        // setting the specialist details to the auth
        this.authService.getClientIp()
          .then(ip => {
            user.ipAddress = ip;
            this.authService.setUser(user);
          })
          .catch(err => {
            console.log('Error getting ip address: ' + err);
          });

        // load narrative data
        this.getShareLinkNarrative(sharelink.narrativeId, navigator.onLine);
      })
      .catch(error => {
        console.log('error loading sharelink in sharelinks-my-report for ' + this.sharelinkId, error);
        this.snackBar.open('Error getting sharelink data!', 'Error');
      });
  }

  /** function get narrative data for the sharelink */
  getShareLinkNarrative(narrativeId: number, online: boolean): void {
    this.narrativeService.getSingleNarrative(+(narrativeId))
      .then(narrative => {
        this.myNarrative = narrative;
        console.log('getSingleNarrative narr.id: ' + narrative._id);
        this.ref.detectChanges();
      }).catch(error => {
        console.log('getSingleNarrative: ', error)
        this.snackBar.open('Error while getting narrative data', 'Error', { duration: 3000 });
      });
  }
}