import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Patient, PatientWithStatus, CountryCodes } from '../../models/patient';
import { ShareLinks } from '../../models/sharelinks';
import { AppSettings } from '../../models/config';
import { Narrative, ReferralStatus } from '../../models/narrative';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import { NarrativeInterface, NarrativeEnumTypes, ReferralEnumTypes } from '../../models/narrative-types';
import { NarrativeService } from '../../services/narrative.service';
import { PatientService } from '../../services/patient.service';
import { RepositoryService, AppTypes } from '../../services/repository.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TransactionApiService } from '../../services/transaction-api.service';
import { environment } from '../../../environments/environment';
import { AppSettingsService } from '../../services/settings.service';
import { FormatPhone } from '../../pipes/formatPhone';

@Component({
  selector: 'psoc-narrative-list-item',
  template: `
    <span style="color: green" *ngIf="myNarrative.transactionStatus === 'success'" (click)="payLater.emit()"> Payment received </span>

    <span *ngIf="myNarrative.transactionStatus === 'processing'">
      <button mat-raised-button (click)="getPaymentStatus()">Check Payment</button>
      <button mat-raised-button (click)="payLater.emit()">Pay Later</button>
    </span>
    <span *ngIf="!myNarrative.transactionStatus || myNarrative.transactionStatus === 'failed'">
      <button mat-raised-button (click)="payNow()">Pay Now</button>
      <button mat-raised-button (click)="payLater.emit()">Pay Later</button>
    </span>
   `
})
export class NarrativeListItemComponent {
  @Input() myNarrative: NarrativeInterface;
  @Output() updateNarrative: EventEmitter<any> = new EventEmitter<any>();
  @Input() patient: Patient;
  @Output() payLater: EventEmitter<any> = new EventEmitter();
  narrativeTypes = NarrativeEnumTypes;
  dialogRef: MatDialogRef<any>;

  constructor(
    private transactionApiService: TransactionApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  /**
   * function to open the payment confirmation dialog
   */
  payNow() {
    this.dialogRef = this.dialog.open(RequestPaymentDialogComponent, {
      width: '700px',
      data: {
        patientPhone: this.patient.phonenumber,
        countryCode: this.patient.countryCode ? this.patient.countryCode : '254'
      }
    });
    event.preventDefault();
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Format the phone number to m-pesa requirement
        const formattedNumber = FormatPhone.formatPhoneNumber(result.patientPhone, result.countryCode); // remove + & 0
        const validPhone = formattedNumber.countryCode + formattedNumber.phoneNumber;  // prefix the country code before

        // call function to request payment
        this.initiateTransaction(this.myNarrative._id, validPhone, result.payableAmount);
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

  /**
   * function to initialize payment in server
   * @param narrativeId narrative id
   * @param phoneNumber patient phone number
   * @param amount payable amount
   */
  initiateTransaction(narrativeId: string, phoneNumber: string, amount: string) {
    this.transactionApiService.initiateTransaction(narrativeId, phoneNumber, amount)
      .then(res => {
        // update narrative here
        this.myNarrative.transactionStatus = 'processing';
        this.updateNarrative.emit(this.myNarrative);
        this.snackBar.open('M-Pesa transaction Initiated', 'Success', { duration: 6000 });
      })
      .catch(err => {
        if (err.source === 'mpesa') {
          this.snackBar.open('Unable to access the M-Pesa service - ' + err.message, 'Error', { duration: 6000 });
        } else {
          this.snackBar.open('Error initiating M-Pesa transaction - ' + err.message, 'Error', { duration: 6000 });
        }
      });
  }

  /**
   * function to check the status of the payment from server
   */
  getPaymentStatus() {
    this.snackBar.open('Requesting payment status....', '', { duration: 6000 });
    this.transactionApiService.getTransaction(this.myNarrative._id)
      .then((payment: any) => {
        console.log('Payment details:', payment);
        if (payment.status === 'processing') {
          this.snackBar.open('Payment request still processing', '', { duration: 6000 });
          return;
        } else if (payment.status === 'success') {
          this.snackBar.open('Payment Received', '', { duration: 6000 });
        } else {
          this.snackBar.open('Payment Canceled or Wrong pin entered', '', { duration: 6000 });
        }

        // update payment record if status has changed
        this.myNarrative.transactionStatus = payment.status;
        this.updateNarrative.emit(this.myNarrative);
      })
      .catch(err => {
        this.snackBar.open('Error getting payment status - ' + err.message, 'Error', { duration: 6000 });
      });
  }
}

@Component({
  selector: 'psoc-confirm-referralstatus-dialog',
  template: `
  <h2>Confirm Referral Status Selection</h2>
  <div *ngIf= "patient.referralStatus === ReferralStatusEnum.arrived">
  <p>Please confirm the patient has <strong>ARRIVED</strong> at the facility.</p>
  </div>
  <div *ngIf= "patient.referralStatus === ReferralStatusEnum.notArrived">
  <p>Please confirm the patient DID NOT ARRIVE at the facility.</p>
  </div>
  <div *ngIf= "patient.referralStatus === ReferralStatusEnum.admitted">
  <p>Please confirm the patient has been <strong>ADMITTED</strong> at the facility.</p>
  </div>
  <div *ngIf= "patient.referralStatus === ReferralStatusEnum.dischargedFromOP ||
   patient.referralStatus === ReferralStatusEnum.dischargedWithDS ||
  patient.referralStatus === ReferralStatusEnum.dischargedWithoutDS">
  <p>Please ensure all necessary changes to the record have been made. <br />Once you Confirm,
   you will NOT be able to edit patient status or add updates.<br /> </p>
  </div>
  <p><button type="button" mat-raised-button (click)="dialogRef.close(true)">Confirm</button>
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button></p>`
})

export class ConfirmReferralStatusDialogueComponent {
  patient: PatientWithStatus;
  ReferralStatusEnum = ReferralStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
  ) { this.patient = this.data.patient; }
}

@Component({
  template: `
  <div>
    <h2>Confirm Payment Details</h2>
    <form [formGroup]="paymentForm">
      <div class='form-group'>
        <label class='title'><strong>Phone Number: </strong></label>
        <mat-form-field class="width20">
          <mat-select formControlName="countryCode" [(ngModel)]="paymentData.countryCode">
            <mat-option *ngFor="let code of countryCodes" [value]="code.code">+{{code.code}} - {{code.country}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="width40">
          <input matInput type="tel" (blur)="formatNumber()" [(ngModel)]='paymentData.patientPhone' formControlName="patientPhone"
          placeholder="Patient Phone Number">
          <mat-hint>Enter Phone number</mat-hint>
        </mat-form-field>
      </div>
      <div class='form-group'>
        <label class='title'><strong>Payable Amount: </strong></label>
        <mat-form-field class="width70">
          <input matInput type="number" [(ngModel)]='paymentData.payableAmount' formControlName="payableAmount" placeholder="Amount to pay">
        </mat-form-field>
      </div>
    </form>
    <p>
      <button type="button" mat-raised-button color="primary" [disabled]="!paymentForm.valid"
      [mat-dialog-close]="paymentData" cdkFocusInitial>Request Payment</button>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()" class="uk-margin-left">Cancel</button>
    </p>
  </div>`
})

export class RequestPaymentDialogComponent {
  paymentForm: FormGroup;
  countryCodes = CountryCodes;
  paymentData = { patientPhone: '', countryCode: '', payableAmount: '' }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>
  ) {
    this.paymentData.countryCode = this.data.countryCode;
    this.paymentData.patientPhone = this.data.patientPhone;

    this.paymentForm = this.formBuilder.group({
      'patientPhone': ['', [Validators.required, Validators.maxLength(13)]],
      'countryCode': [''],
      'payableAmount': ['', [Validators.required, Validators.maxLength(6)]]
    });
  }

  /** Format the phone number when user has left the input field */
  formatNumber() {
    const formattedNumber = FormatPhone.formatPhoneNumber(this.paymentData.patientPhone, this.paymentData.countryCode);
    this.paymentData.patientPhone = formattedNumber.phoneNumber;
    this.paymentData.countryCode = formattedNumber.countryCode;
  }
}

@Component({
  selector: 'psoc-patient-narrative-list',
  templateUrl: 'patient-narrative-list.component.html',
})

export class PatientNarrativeListComponent implements OnInit {
  patient: Patient;
  narratives: Array<NarrativeInterface>;
  patientId;
  narrativeTypes = NarrativeEnumTypes;
  referralTypes = ReferralEnumTypes;
  loadingPatientData: Boolean = false;
  loadingNarratives: Boolean = false;
  ReferralStatusEnum = ReferralStatus;
  referralStatuses = this.repository.enumSelector(ReferralStatus);
  dialogRef: MatDialogRef<any>;
  appTypes = AppTypes;
  patientSharelinks: ShareLinks[];
  appSettings: AppSettings;
  loadingAppSettings: Boolean = false;
  env = environment;

  constructor(
    public repository: RepositoryService,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private authService: AuthenticationService,
    private appSettingsService: AppSettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.patientId = this.route.snapshot.params['patientid'];
  }

  ngOnInit() {
    this.getPatient();  // get patient data
    this.getPatientNarratives();  // get the patient narratives
    this.getPatientShareLinks();  // get patient sharelinks
    this.getAppSettings();  // get the app settings file from db
  }

  /**
  * Load app settings
  */
  getAppSettings(): void {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        this.loadingAppSettings = false;
      }).catch(error => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /**
   * function to fetch the patient data
   */
  getPatient() {
    this.loadingPatientData = true;
    this.patientService.getSinglePatient(this.patientId)
      .then(patient => {
        this.loadingPatientData = false;
        this.patient = patient;
      })
      .catch(error => {
        this.loadingPatientData = false;
        console.log('ERROR:', error);
        this.snackBar.open('Error: Fetching patient information', 'Error', { duration: 1000 });
      })
  }

  /**
   * function to get all patients narratives
   */
  getPatientNarratives(): void {
    this.loadingNarratives = true;
    this.narrativeService.getPatientNarratives(+this.patientId)
      .then(narratives => {
        this.loadingNarratives = false;
        this.narratives = this.narrativeService.patientNarratives.map((narr: NarrativeInterface) => {
          if (narr.narrativeType === NarrativeEnumTypes.obstetric) {
            narr = new ObstetricNarrative(narr);
          } else {
            narr = new Narrative(narr);
          }
          return narr;
        });
      })
      .catch(error => {
        this.loadingNarratives = false;
        console.log('ERROR:', error);
        this.snackBar.open('Error: Fetching patients narratives', 'Error', { duration: 1000 });
      })
  }

  /** function to fetch all patients sharelinks */
  getPatientShareLinks(): void {
    this.patientService.getPatientShareLinks(+this.patientId)
      .then(sharelinks => {
        // loaded patients sharelinks
        this.patientSharelinks = sharelinks;
      })
      .catch(error => {
        // error fetching patients sharelinks
        console.log(error);
        this.snackBar.open('Error loading patients sharelinks', 'Error', { duration: 6000 });
      })
  }

  /** Function Show selected narrative from the list */
  showNarrative(narrative: any, viewNarrative: boolean = false): void {
    if (viewNarrative || narrative.transactionStatus === 'success' || !this.appSettings.narrativeSettings.paymentModule) {
      // check for narratives with no narrative type, default to consultation
      // TODO: better default based on if referral or not
      if (!narrative.narrativeType) { narrative.narrativeType = NarrativeEnumTypes.consultation }
      if (!narrative.scanIndication && narrative.narrativeType === NarrativeEnumTypes.obstetric) {
        this.router.navigate(['/patient/' + this.patientId + '/narrative/' + narrative.id + '/add/' + narrative.narrativeType]);
      } else if (narrative.narrativeType === NarrativeEnumTypes.consultation && !narrative.presumptiveDiagnosis) {
        this.router.navigate(['/patient/' + this.patientId + '/narrative/' + narrative.id + '/edit/' + narrative.narrativeType]);
      } else {
        this.router.navigate(['/patient/' + this.patientId + '/narrative/' + narrative.id + '/view/' + narrative.narrativeType]);
      }
    } else {
      // snackbar tell user payment is required or they can click on paylater
      this.snackBar.open('Payment for this patient is required, or click paylater', 'Info', { duration: 6000 });
    }
  }

  /**
   * Function add narrative to list
   * @param narrativeType narrative type
   */
  addNarrative(narrativeType: NarrativeEnumTypes) {
    const narrId: number = new Date().getTime();  // narrative id

    switch (narrativeType) {
      case NarrativeEnumTypes.obstetric:
        const obstetricNarrative = new ObstetricNarrative({
          _id: String(narrId),
          id: narrId,
          type: ObstetricNarrative.type,
          narrativeType: NarrativeEnumTypes.obstetric,
          patientId: +this.patient._id,
          gravida: this.getValue(this.narrativeService.patientNarratives, 'gravida'),
          para: this.getValue(this.narrativeService.patientNarratives, 'para'),
          dateOfLNMP: this.getValue(this.narrativeService.patientNarratives, 'dolmp')
        });

        // save narrative
        this.updateNarrative(obstetricNarrative);
        break;
      case NarrativeEnumTypes.consultation:
        if (this.appSettings.narrativeSettings.paymentModule) {
          // for paid narratives add narrative first
          const narrative = new Narrative({
            _id: String(narrId),
            id: narrId,
            type: Narrative.type,
            narrativeType: NarrativeEnumTypes.consultation,
            patientId: +this.patient._id,
            referralType: this.appSettings.narrativeSettings.defaultReferral
          });

          // save narrative
          this.updateNarrative(narrative);
        } else {
          // for non-paid narrative redirect to add page
          this.router.navigate(['/patient/' + this.patientId + '/narrative/add/' + narrativeType]);
        }
        break;

      default:
        this.snackBar.open('Adding narrative not supported')
        break;
    }
  }

  // update narrative with referralStatus
  updateNarrativeStatus(narrative: Narrative) {
    console.log('Update narrative status to ' + narrative.referralStatus)
    // if (narratives[0].referralStatus === 'dischargedFromOP' || narratives[0].referralStatus === 'dischargedWithDS' ||
    //   narratives[0].referralStatus === 'dischargedWithoutDS') {
    this.dialogRef = this.dialog.open(ConfirmReferralStatusDialogueComponent, {
      data: { patient: this.patient }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // update narrative status history
        this.narrativeService.updateStatusHistory(narrative, narrative.referralStatus, '');

        this.narrativeService.updateNarrative(narrative);
        console.log('Updated Narrative ' + narrative.referralStatus);
        this.repository.addEvents(Narrative.type, narrative._id, 'statusUpdated', this.authService.getUser().username,
          this.authService.getUser().ipAddress, narrative.referralStatus, narrative.patientId);
      } else {
        // patient.referralStatus = narrative.referralStatus;
        console.log('Cancel changing narrative status');
        return false;
      }
      this.dialogRef = null;
    });
  }

  /** narrative has been updated in list item, save changes to db */
  updateNarrative(narrative: any) {
    if (narrative.narrativeType === NarrativeEnumTypes.obstetric) {
      this.narrativeService.updateObstetricNarrative(narrative).then(updatedNarrative => {
        this.narratives = this.filterOutOldNarrative(this.narratives, updatedNarrative);
        this.narrativeService.patientNarratives = this.filterOutOldNarrative(this.narrativeService.patientNarratives, updatedNarrative);
      }).catch(err => console.log(err));
    } else {
      this.narrativeService.updateNarrative(narrative).then(updatedNarrative => {
        this.narratives = this.filterOutOldNarrative(this.narratives, updatedNarrative);
        this.narrativeService.patientNarratives = this.filterOutOldNarrative(this.narrativeService.patientNarratives, updatedNarrative);
      }).catch(err => console.log(err));
    }
  }

  /**
   * function to filter and get values form older narratives
   * @param narratives array of narratives to filter from
   * @param getValue the specific field to load data
   */
  getValue(narratives: any[], getValue: string): string {
    if (narratives.length < 1) { return undefined }

    // filter narratives list
    const narrative = narratives.filter(n => n.narrativeType === NarrativeEnumTypes.obstetric && n.dateOfLNMP !== undefined)[0];
    if (!narrative) { return undefined; }

    let value = '';
    switch (getValue) {
      case 'dolmp':
        value = narrative.dateOfLNMP;
        break;
      case 'gravida':
        value = narrative.gravida;
        break;
      case 'para':
        value = narrative.para;
        break;
      default:
        value = undefined;
        break;
    }

    return value;
  }

  /**
   * function to filter out older narrative from list
   * @param narrativeList list of narratives
   * @param updatedNarrative updated narrative
   */
  filterOutOldNarrative(narrativeList: any, updatedNarrative: any): any[] {
    const narr = narrativeList.filter((n: any) => n._id !== updatedNarrative._id);
    if (updatedNarrative.narrativeType === NarrativeEnumTypes.obstetric) {
      narr.push(new ObstetricNarrative(updatedNarrative));
    } else {
      narr.push(new Narrative(updatedNarrative));
    }

    return narr;
  }

}
