import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FoetalLieBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SonographerBlockFoetalLie } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-foetal-lie-sonographer-edit',
  templateUrl: './foetal-lie-sonographer-edit.component.html'
})

export class FoetalLieSonographerEditComponent extends SonographerBlockEditComponent {
  @Input() nurseData: FoetalLieBlock;
  @Input() blockData: SonographerBlockFoetalLie;
  @Input() selectedFoetus;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;

  blockStatusOptions = ['Longitudinal', 'Transverse', 'Oblique', 'Not clear'];
  form: FormGroup;

  constructor(
    private _fb: FormBuilder
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: ['', [Validators.required]],
      sonographerNotes: ['']
    })
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.blockData.blockStatus ?  true : false;
  }
}
