import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';

import { Patient } from '../../../models/patient';
import { Facility } from '../../../models/facility';
import { NarrativeEnumTypes } from '../../../models/narrative-types';
import { NarrativeService } from '../../../services/narrative.service';
import { ObstetricNarrative, NumberOfFoetusBlock, MaternalAnatomyBlock } from '../../../models/obstetric-narrative';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'psoc-obstetric-narrative-add',
  templateUrl: 'obstetric-narrative-add.component.html'
})

export class ObstetricNarrativeAddComponent implements OnInit {
  @Input() facilities: Facility[];
  @Input() patient: Patient;
  @Output() narrativeAdded: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
  @Output() viewRecord: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
  obstetricNarrative: ObstetricNarrative;
  narrativeId: number;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private narrativeService: NarrativeService
  ) {
    // get the narrative id from the url
    this.narrativeId = this.route.snapshot.params['narrativeid'];
   }

   ngOnInit() {
    //  get narrative
    this.getNarrative();
   }

   /**
    * Function to get the narrative from pouch
    */
   getNarrative() {
     this.narrativeService.getSingleNarrative(this.narrativeId)
      .then((narrative: any) => {
        this.obstetricNarrative = narrative;
        this.obstetricNarrative.scanningClinician = this.auth.getUser().username;
        this.obstetricNarrative.sonographyBlocks.numberOfFoetusBlock = new NumberOfFoetusBlock({ numberOfFoetus: 1 });
        this.obstetricNarrative.sonographyBlocks.foetuses = [];
        this.obstetricNarrative.sonographyBlocks.maternalAnatomyBlock = new MaternalAnatomyBlock();
      })
      .catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error: Getting the narrative', 'Error', { duration: 6000 });
      });
   }

  /**
   * function to save narrative
   */
  addNarrative() {
    const narrId: number = new Date().getTime();
    if (!this.obstetricNarrative._id) {
      this.obstetricNarrative._id = String(narrId);
      this.obstetricNarrative.id = narrId;
      this.obstetricNarrative.type = ObstetricNarrative.type;
      this.obstetricNarrative.narrativeType = NarrativeEnumTypes.obstetric;
      this.obstetricNarrative.patientId = +this.patient._id;
    }

    this.narrativeService.updateObstetricNarrative(this.obstetricNarrative)
      .then((newNarrative: ObstetricNarrative) => {
        // done saving
        const narrative: any = newNarrative;

        // remove older narrative from array
        this.narrativeService.patientNarratives = this.narrativeService.patientNarratives
          .filter(n => n._id !== this.obstetricNarrative._id);

        this.narrativeService.patientNarratives.push(narrative);  // add narrative to list of narratives
        this.narrativeAdded.emit(newNarrative);
      })
      .catch(err => {
        console.log('ERROR:', err);
        this.snackBar.open('Error: Adding narrative, try again', 'Error', { duration: 6000 });
      });
  }

  /**
   * discard a new obstetric narrative
   * @param obstetricNarrative
   */
  confirmDiscardNewRecord(obstetricNarrative: ObstetricNarrative) {
    console.log('navigate to patients narratives list', obstetricNarrative);
    this.obstetricNarrative = obstetricNarrative;
    this.viewRecord.emit(this.obstetricNarrative);
    // change the url path
    this.router.navigate(['/patient/' + this.patient._id + '/narratives/']);
  }
}
