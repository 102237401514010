import { Component, Input } from '@angular/core';
import { Covid19Screening } from '../../models/narrative';
@Component({
  selector: 'psoc-covid-19-screening-view',
  templateUrl: 'covid19-screening-view.component.html'
})
export class Covid19ScreeningViewComponent {
  @Input()
  covid19Screening: Covid19Screening;
  constructor() { }
}
