import { PouchObject } from './pouch-object';

export class Facility implements PouchObject {
    public static type: string = 'facility';
    public static fields = ['_id', '_rev', '_deleted', 'type', 'code', 'name', 'level', 'facilityType', 'ownership', 'regulatoryBody',
        'county', 'sub_county', 'latitude', 'longitude', 'contact', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility',
        'updateFacility', 'encryptionKey'];
    _id: string;
    _rev: string;
    _deleted: boolean = false;
    type: string;
    code: string;
    name: string;
    level: string;
    facilityType: string;
    ownership: string;
    regulatoryBody: string;
    county: string;
    sub_county: string;
    latitude?: Date;
    longitude?: boolean;
    contact: string[] = [];

    dateAdded: Date;
    dateUpdated: Date;
    updatedBy: String;
    createdBy: String;
    createFacility: string;
    updateFacility: string;

    encryptionKey: string; // used to encrypt offline database for each location

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}
