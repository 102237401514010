import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Patient } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AppTypes, RepositoryService } from '../../services/repository.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { InsuranceClient } from '../../models/insurance-client';
import { Enquiry } from '../../models/enquiry';
import { FormatPhone } from '../../pipes/formatPhone';
import { NarrativeEnumTypes, NarrativeInterface } from '../../models/narrative-types';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import { Narrative } from '../../models/narrative';
import { Attachment } from '../../models/attachment';
import { ShareLinks } from '../../models/sharelinks';
import { Appointment } from '../../models/appointment';
import { Chat } from '../../models/chat';
import { MergeFixPatientDialogComponent } from '../merge-fix-patient/merge-fix-patient.component';
import { NarrativeService } from '../../services/narrative.service';
import { ChatService } from '../../services/chat.service';
import { AppointmentService } from '../../services/appointment.service';
import { AttachmentService } from '../../services/attachment.service';
import { AppSettings } from '../../models/config';

@Component({
  selector: 'psoc-client-search-component',
  templateUrl: 'client-search.component.html'
})
export class ClientSearchComponent implements OnInit, AfterViewChecked {

  patient: Patient;
  enquiry: Enquiry;
  public loadingClients: Boolean = true;
  matchedPatients: Patient[] = [];
  selectedClient: InsuranceClient;
  selectedPatient: Patient;
  displayedColumns =
    ['registrationNumber', 'schemeType', 'schemename', 'name', 'dateOfBirth', 'sex', 'relationship', 'copay', 'usage', 'actions'];
  clientDatabase: ClientDatabase;
  dataSource: ClientsDataSource | null;
  appTypes = AppTypes;
  searchingExistingPatients: boolean = false;

  duplicatePatientsExist: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  firstNameFilter: string;
  lastNameFilter: string;
  otherNamesFilter: string;
  regNoFilter: string;
  schemeNameFilter: string;

  @ViewChild('filterByFirstName') filterByFirstName;
  @ViewChild('filterByLastName') filterByLastName;
  @ViewChild('filterByOtherNames') filterByOtherNames;
  @ViewChild('filterByRegistrationNumber') filterByRegistrationNumber;
  @ViewChild('filterBySchemeName') filterBySchemeName;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('mergeFixPatientDialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

  fromMatch: boolean = false;
  appSettings: AppSettings;

  patients: Patient[] = [];


  app: AppTypes;

  constructor(private dialogRef: MatDialogRef<any>,
    private dialogRef1: MatDialogRef<any>,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private appointmentService: AppointmentService,
    private chatService: ChatService,
    private narrativeService: NarrativeService,
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private repositoryService: RepositoryService,
    private ref: ChangeDetectorRef
  ) {
    // set up patients database
    this.clientDatabase = new ClientDatabase();
  }

  ngOnInit() {
    // set up patients dataSource
    this.dataSource = new ClientsDataSource(this.clientDatabase, this.paginator, this.sort);

    // filter by patient once data loaded
    let initial: number = 0;
    this.clientDatabase.dataChange.subscribe(() => {
      if (initial === 0) { initial += 1; this.filterByPatient(); }
    });

    // listen for changes on first name field
    fromEvent(this.filterByFirstName.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)  // if character length greater then 2
      , debounceTime(600)  // Time in milliseconds between key events
      , distinctUntilChanged()  // If previous query is different from current
    ).subscribe((text: string) => {
      // subscription for response
      this.findClient()
    });

    // listen for changes on last name field
    fromEvent(this.filterByLastName.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)  // if character length greater then 2
      , debounceTime(600)  // Time in milliseconds between key events
      , distinctUntilChanged()  // If previous query is different from current
    ).subscribe((text: string) => {
      // subscription for response
      this.findClient()
    });

    // listen for changes on other name field
    fromEvent(this.filterByOtherNames.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)  // if character length greater then 2
      , debounceTime(600)  // Time in milliseconds between key events
      , distinctUntilChanged()  // If previous query is different from current
    ).subscribe((text: string) => {
      // subscription for response
      this.findClient()
    });

    // listen for changes on other name field
    fromEvent(this.filterByRegistrationNumber.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)  // if character length greater then 2
      , debounceTime(600)  // Time in milliseconds between key events
      , distinctUntilChanged()  // If previous query is different from current
    ).subscribe((text: string) => {
      // subscription for response
      this.findClient()
    });

    // listen for changes on other name field
    fromEvent(this.filterBySchemeName.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)  // if character length greater then 2
      , debounceTime(600)  // Time in milliseconds between key events
      , distinctUntilChanged()  // If previous query is different from current
    ).subscribe((text: string) => {
      // subscription for response
      this.findClient()
    });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  public setApp(app: AppTypes) {
    this.app = app;
  }

  public setPatient(patient: Patient): void {
    this.patient = patient;
  }

  public setEnquiry(enquiry: Enquiry): void {
    this.enquiry = enquiry;
    if (this.enquiry) {
      this.enquiry.phonenumber = FormatPhone.formatPhoneNumber(this.enquiry.phonenumber).phoneNumber;
    }
  }

  public setClients(clients: InsuranceClient[]): void {
    this.clientDatabase.data = clients;
  }

  public setLoadingClients(loadingClients: boolean): void {
    this.loadingClients = loadingClients;
  }

  public setFromMatch(fromMatch: boolean, appSettings): void {
    this.fromMatch = fromMatch;
    this.appSettings = appSettings;
  }

  // check if any of selected patient phone numbers match enquiry phone number
  public phoneNumbersSame(): boolean {
    return FormatPhone.formatPhoneNumber(this.selectedPatient.phonenumber, this.selectedPatient.countryCode).longPhone
      === FormatPhone.formatPhoneNumber(this.enquiry.phonenumber).longPhone
      ||
      FormatPhone.formatPhoneNumber(this.selectedPatient.phonenumber2, this.selectedPatient.countryCode2).longPhone
      === FormatPhone.formatPhoneNumber(this.enquiry.phonenumber).longPhone;
  }

  /** close/hide the keyboard */
  hideKeyboard() {
    this.filterByFirstName.filterInput.nativeElement.blur();
    this.filterByLastName.filterInput.nativeElement.blur();
    this.filterByOtherNames.filterInput.nativeElement.blur();
    this.filterByRegistrationNumber.filterInput.nativeElement.blur();
    this.filterBySchemeName.filterInput.nativeElement.blur();
  }

  clearFirstNameFilter() {
    this.firstNameFilter = '';
    this.paginator.firstPage();
    this.findClient();
  }

  clearLastNameFilter() {
    this.lastNameFilter = '';
    this.paginator.firstPage();
    this.findClient();
  }

  clearOtherNamesFilter() {
    this.otherNamesFilter = '';
    this.paginator.firstPage();
    this.findClient();
  }

  clearRegNoFilter() {
    this.regNoFilter = '';
    this.paginator.firstPage();
    this.findClient();
  }

  clearSchemeNameFilter() {
    this.schemeNameFilter = '';
    this.paginator.firstPage();
    this.findClient();
  }

  clearFilters() {
    this.clearFirstNameFilter();
    this.clearLastNameFilter();
    this.clearOtherNamesFilter();
    this.clearRegNoFilter();
    this.clearSchemeNameFilter();
  }

  /**
   * find client from clients list db
   * @param {number} initial an indication if this is the first time we are calling find client
   */
  findClient(initial: number = 1): void {
    this.paginator.firstPage();
    if (initial === 0) { return; } // don't call api for first time to load the component
    this.patientService.getFilteredClients(this.firstNameFilter, this.lastNameFilter, this.otherNamesFilter, this.regNoFilter,
      this.schemeNameFilter, this.appSettings.narrativeSettings.eS_API_KEY).then((records: Array<InsuranceClient>) => {
        this.clientDatabase.data = records;  // update table list
      }).catch(error => {
        console.log('Error loading list of clients: ', error);
        const snackBarRef = this.snackBar.open('Error loading list of clients, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.findClient();
        });
      });
  }

  openMergeFixPatientModal() {
    // get the patients with the same reg no as the selected patient
    const duplicates: Patient[] = this.patients;

    // get the selected patient (oldest patient)
    const oldestPatient: Patient = new Patient(duplicates[duplicates.length - 1]);

    const config: MatDialogConfig = { height: '95vh', viewContainerRef: this.dialogAnchor };
    this.dialogRef1 = this.dialog.open(MergeFixPatientDialogComponent, config);
    this.dialogRef1.componentInstance.setServices(this.patientService, this.snackBar,
      this.appSettings, this.narrativeService, this.attachmentService, this.appointmentService, this.chatService, this.repositoryService);
    this.dialogRef1.componentInstance.setClient(this.selectedClient, this.fromMatch);
    this.dialogRef1.componentInstance.setOldestPatient(oldestPatient);
    this.dialogRef1.componentInstance.setData(duplicates);

    // set patient list narratives
    this.dialogRef1.afterClosed().subscribe(result => {
      if (result && result === 'navigate') {
        const newResult = {};
        newResult['action'] = 'navigate'
        newResult['oldestPatient'] = oldestPatient;
        this.dialogRef.close(newResult);
      }
    });
  }

  selectClient(client: InsuranceClient): void {
    this.selectedClient = client;
    this.searchingExistingPatients = true;

    // search patient records for patient with this reg no
    this.patientService.findPatientsByRegNo(client.registrationNumber)
      .then((patients) => {
        console.log('reg # search results', patients);
        this.searchingExistingPatients = false;
        this.matchedPatients = patients;
        if (patients.length < 1) {
          // no patient matches, return selected client
          this.returnClient(client);
        } else {
          // run this functionality
          if (this.fromMatch && this.app === AppTypes.adminApp && this.authService.isAdmin()) {
            // check if duplicate Patients exist; use this to determine
            // whether to run merging duplicates functionality or correct patient info
            this.duplicatePatientsExist = patients.length > 1 ||
              (patients.length === 1 && this.patient._id !== patients[0]._id);
            console.log('Duplicate patients exist ?', this.duplicatePatientsExist);
            if (this.duplicatePatientsExist) {
              this.patients = this.patient.idnumber !== client.registrationNumber ?
                patients.concat([this.patient]) : patients;
              // sort (latest to oldest)
              this.patients.sort((a, b) => (new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()));
            }
          }
        }
      })
      .catch(error => {
        console.error('An error occurred finding matching patients', error);
      });
  }

  unSelectPatient(patient: Patient) {
    this.selectedPatient = null;
  }

  selectPatient(patient: Patient) {
    this.selectedPatient = patient;
  }

  returnPatient(patient: Patient) {
    // return selected patient
    this.dialogRef.close(patient);
  }

  returnClient(client: InsuranceClient) {
    // return selected insurance client
    this.dialogRef.close(client);
  }

  cancel() {
    // return nothing
    this.dialogRef.close();
  }

  filterByPatient() {
    this.firstNameFilter = this.patient.firstname;
    this.lastNameFilter = this.patient.lastname;
    this.otherNamesFilter = this.patient.othernames;
    this.regNoFilter = this.patient.idnumber;
    this.schemeNameFilter = this.patient.schemename;
    this.findClient(0);
  }

}

// Database Object for the insurance client members table
export class ClientDatabase {
  dialogRef: MatDialogRef<any>;
  @ViewChild('filter') filter: ElementRef
  dataChange: BehaviorSubject<InsuranceClient[]> = new BehaviorSubject<InsuranceClient[]>([]);
  public loadingMembers: Boolean = false;
  get data(): InsuranceClient[] { return this.dataChange.value; }
  set data(clients: InsuranceClient[]) { this.dataChange.next(clients); }
  constructor() { }
}

// DataSource Object for the patients table
export class ClientsDataSource extends DataSource<InsuranceClient> {
  filteredData: InsuranceClient[] = [];
  renderedData: InsuranceClient[] = [];

  _filterFirstNameChange = new BehaviorSubject('');
  _filterLastNameChange = new BehaviorSubject('');
  _filterOtherNamesChange = new BehaviorSubject('');
  _filterRegistrationNumberChange = new BehaviorSubject('');
  _filterSchemeNameChange = new BehaviorSubject('');

  get firstNameFilter(): string { return this._filterFirstNameChange.value; }
  set firstNameFilter(filterFirst: string) { this._filterFirstNameChange.next(this.cleanFilter(filterFirst)); }
  get lastNameFilter(): string { return this._filterLastNameChange.value; }
  set lastNameFilter(filterLast: string) { this._filterLastNameChange.next(this.cleanFilter(filterLast)); }
  get otherNamesFilter(): string { return this._filterOtherNamesChange.value; }
  set otherNamesFilter(filterOther: string) { this._filterOtherNamesChange.next(this.cleanFilter(filterOther)); }
  get regNoFilter(): string { return this._filterRegistrationNumberChange.value; }
  set regNoFilter(filterReg: string) { this._filterRegistrationNumberChange.next(this.cleanFilter(filterReg)); }
  get schemeNameFilter(): string { return this._filterSchemeNameChange.value; }
  set schemeNameFilter(filterScheme: string) { this._filterSchemeNameChange.next(this.cleanFilter(filterScheme).replace('afyatele', '')); }

  constructor(private _clientDatabase: ClientDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /** remove non alphanumeric characters */
  cleanFilter(val: string) {
    if (!val) { return ''; }
    return val.toLowerCase().replace(/[^0-9a-z]/gi, '');
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<InsuranceClient[]> {
    const displayPatientDataChanges = [
      this._clientDatabase.dataChange,
      this._filterFirstNameChange,
      this._filterLastNameChange,
      this._filterOtherNamesChange,
      this._filterRegistrationNumberChange,
      this._filterSchemeNameChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return merge(...displayPatientDataChanges).pipe(map(() => {
      // filter by search terms
      if (!this.firstNameFilter && !this.lastNameFilter && !this.otherNamesFilter && !this.regNoFilter && !this.schemeNameFilter) {
        // no filters specified, bypass filtering
        this.filteredData = this._clientDatabase.data.slice();
      } else {
        // filter by search terms
        this.filteredData = this._clientDatabase.data.slice().filter((client: InsuranceClient) => {

          const search_names = this.cleanFilter('' + client.firstName + client.lastName + client.otherNames);
          const search_registrationNumber = this.cleanFilter(client.registrationNumber);
          const search_schemeName = this.cleanFilter(client.schemeName);

          const result_firstName = this.firstNameFilter ? search_names.indexOf(this.firstNameFilter) !== -1 : true;
          const result_lastName = this.lastNameFilter ? search_names.indexOf(this.lastNameFilter) !== -1 : true;
          const result_otherNames = this.otherNamesFilter ? search_names.indexOf(this.otherNamesFilter) !== -1 : true;
          const result_registrationNumber = this.regNoFilter ? search_registrationNumber.indexOf(this.regNoFilter) !== -1 : true;
          const result_schemeName = this.schemeNameFilter ? search_schemeName.indexOf(this.schemeNameFilter) !== -1 : true;

          return result_firstName && result_lastName && result_otherNames && result_registrationNumber && result_schemeName;
        });
        console.log('Found ' + this.filteredData.length + ' client records');
      }

      // sort by selected column
      const sortedData = this.getSortedData(this.filteredData.slice());

      // display current page of info
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize)
    }));

  }

  disconnect() { }

  getSortedData(data: InsuranceClient[]): InsuranceClient[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string | Date = '';
      let propertyB: number | string | Date = '';

      switch (this._sort.active) {
        case 'registrationNumber': [propertyA, propertyB] = [a.registrationNumber, b.registrationNumber]; break;
        case 'name': [propertyA, propertyB] = [a.lastName, b.lastName]; break;
        case 'dateOfBirth': [propertyA, propertyB] = [a.dateOfBirth, b.dateOfBirth]; break;
        case 'sex': [propertyA, propertyB] = [a.sex, b.sex]; break;
        case 'schemeName': [propertyA, propertyB] = [a.schemeName, b.schemeName]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
