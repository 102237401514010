import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockAmnioticFluid } from '../../../../models/obstetric-sharelink';
import { AmnioticFluidBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-amniotic-fluid-sonographer-view',
  templateUrl: 'amniotic-fluid-sonographer-view.component.html'
})

export class AmnioticFluidSonographerViewComponent {
  @Input() firstTrimester: Boolean;
  @Input() sonographerData: SonographerBlockAmnioticFluid;
  @Input() nurseData: AmnioticFluidBlock;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    let blockValid = this.sonographerData.blockStatus  ?  true : false;

    if (!this.firstTrimester) {
      const quadrant1Valid = this.sonographerData.quadrant1 > 0 || this.sonographerData.cantConfirmQuadrant1;
      const quadrant2Valid = this.sonographerData.quadrant2 > 0 || this.sonographerData.cantConfirmQuadrant2;
      const quadrant3Valid = this.sonographerData.quadrant3 > 0 || this.sonographerData.cantConfirmQuadrant3;
      const quadrant4Valid = this.sonographerData.quadrant4 > 0 || this.sonographerData.cantConfirmQuadrant4;

      blockValid = blockValid && (quadrant1Valid && quadrant2Valid && quadrant3Valid && quadrant4Valid);
    }

    return blockValid;
  }
}
