import { PouchObject } from './pouch-object';

export class User implements PouchObject {
  public static type: string = 'user';
  public static fields = ['id', '_id', '_rev', '_deleted', 'firstName', 'lastName', 'phone', 'username', 'emailAddress', 'password',
    'facility', 'approved', 'cadre', 'userRole', 'dateAdded', 'dateUpdated', 'type', 'ipAddress', 'boardnumber', 'qualification'];
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;

  firstName: string;
  lastName: string;
  phone: string;
  boardnumber: string;
  qualification: string;

  username: string;
  emailAddress?: string;
  password?: string;
  facility?: string;
  approved?: boolean;
  userRole?: string;
  cadre: string;
  dateAdded: Date;
  dateUpdated: Date;
  type: string;
  ipAddress: string;

  updatedBy: String;
  createdBy: String;
  createFacility: String;
  updateFacility: String;

  hasMediatorObject?: boolean;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export enum CadreOptions {
  MO = 'MO',
  CO = 'CO',
  Nurse = 'Nurse'
}
