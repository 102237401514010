// Type Client Name Member No. First name Other names Last name	Gender	Birth Date	Relationship	Usage	COPAYMENT
// Micro	SAMURA ENGINEERING LIMITED	11400000335174-00	JOSEPHAT	MUMO	MBALUTO	Male	11/12/1973	Principal		500
// Client name will be changed to “Scheme name”

export class InsuranceClient {
  public static type: string = 'insurance-client'; // TODO: change to insurance-client
  public static fields = ['type', 'registrationNumber', 'schemeType', 'schemeName', 'firstName', 'otherNames',
    'lastName', 'sex', 'dateOfBirth', 'relationship', 'copay', 'usage', 'insurer'];
  schemeType?: string;
  schemeName?: string;
  registrationNumber: any;
  firstName: string;
  lastName: string;
  otherNames?: string;
  sex: string;
  dateOfBirth?: string;
  relationship?: string;
  schemeUsage?: string;
  copay?: string;
  usage?: string;
  insurer?: string;
  patientId?: string; // record patientId once matched in the system

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}
