import { Component, Input } from '@angular/core';
import { MaternalAnatomyBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
    selector: 'psoc-maternal-anatomy-view',
    templateUrl: './maternal-anatomy-nurse-view.component.html'
})

export class MaternalAnatomyNurseViewComponent extends ObstetricBlockViewComponent {
    // data for this block
    _maternalAnatomy: MaternalAnatomyBlock;
    @Input()
    set maternalAnatomy(maternalAnatomy: MaternalAnatomyBlock) {
        this._maternalAnatomy = maternalAnatomy;
        this.getAttachmentFilesCount(maternalAnatomy.attachmentIds);
    }
    get maternalAnatomy(): MaternalAnatomyBlock {
        return this._maternalAnatomy;
    }

    /**
     * Check if data is valid for this block
     */
    blockValid() {
        return (this.maternalAnatomy && this.maternalAnatomy.blockStatus) ? true : false;
    }
}
