import { PouchObject } from './pouch-object';

export class Chat implements PouchObject {
    public static type: string = 'chat';
    public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'patientId', 'sharelinkId', 'userName', 'message',
        'createdBy', 'createFacility', 'updateFacility', 'sharedFacility', 'updatedBy', 'dateAdded', 'dateUpdated'];

    id: number;
    _id: string;
    _rev: string;
    _deleted: boolean = false;
    type: string;
    patientId: number;
    sharelinkId: string;
    userName: string;
    message: string;
    specialistuserName: string;
    specialistEmail: string;
    createdBy: String;
    createFacility: String;
    updateFacility: String;
    sharedFacility: string[];
    updatedBy: String;
    dateAdded: Date;
    dateUpdated: Date;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}
