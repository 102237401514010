import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '../models/patient';

@Pipe({ name: 'getPatientName' })
export class GetPatientName implements PipeTransform {
  transform(patientId: number, patients: Patient[]) {
    let currentPatient: Patient, currentPatientName = '';
    if (patients) {
      currentPatient = patients.find(p => +p._id === patientId);
      if (currentPatient) { currentPatientName = currentPatient.firstname; }
    }
    return currentPatientName;
  }
}

@Pipe({ name: 'getPatientReg' })
export class GetPatientReg implements PipeTransform {
  /** get user readable patient reg  */
  transform(patientId: number, patients: Patient[]) {
    let currentPatient: Patient, currentPatientId = '';
    if (patients) {
      currentPatient = patients.find(p => +p._id === patientId);
      if (currentPatient) { currentPatientId = currentPatient.idnumber; }
    }
    return currentPatientId;
  }
}


