import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { User } from '../models/user';
import { Mediator } from '../models/mediators';

@Injectable()
export class UsersService {

    constructor(private repository: RepositoryService) { }

    /**
     * delete user
     */
    deleteUser(user: User): Promise<any> {
        return new Promise((resolve, reject) => {
            user.type = User.type;

            this.repository.deleteObject(user, Databases.usersDb)
                .then((res) => {
                    resolve(null);
                })
                .catch((error) => {
                    resolve(error)
                })

        });
    }

    private mapObjectToMediator(object: any): Mediator {
        let mediator: Mediator = new Mediator();
        return mediator = { ...object };
    }

    /**
     * delete mediator object (copy of users for listing users)
     * @param mediator
     */
    deleteMediator(mediator: Mediator): Promise<any> {
        return new Promise((resolve, reject) => {
            mediator.type = Mediator.type;

            this.repository.fetchObjectsBy(Mediator.type, Mediator.fields,
                'username', mediator.username, ['_id'], Databases.mediatorsDb)
                .then((result) => {
                    const mediators: Mediator[] = result.docs.map((doc: any) => this.mapObjectToMediator(doc));
                    if (mediators[0]) {
                        this.repository.deleteObject(mediators[0], Databases.mediatorsDb)
                            .then((res) => {
                                resolve(null);
                            })
                            .catch((error) => {
                                resolve(error)
                            })
                    } else {
                        reject('Mediator object not found');
                    }
                    // resolve (mediators as Mediator[]);
                })
                .catch(error => {
                    console.error('An error occurred finding mediators', error);
                    reject(error);
                });

        });
    }
    /**
     * Get all users
     */
    getAllUsers(): Promise<User[]> {
        // return this.repository.fetchUsers()
        // const fieldsUser = Object.keys(User.prototype);
        return new Promise((resolve, reject) => {
            this.repository.fetchObjects(User.type, Databases.usersDb)
                .then(result => {
                    const users: User[] = result.docs.map((doc: any) => this.mapObjectToUser(doc));
                    // console.log('Users: ', users);
                    resolve(users);
                })
                .catch(error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    private mapObjectToUser(object: any): User {
        let user: User = new User();
        return user = { ...object };
    }

}
