import { Component, Input } from '@angular/core';
import { Attachment } from '../../../models/attachment';
import { MatSnackBar } from '@angular/material';
import { AttachmentService } from '../../../services/attachment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'psoc-images-viewer',
  templateUrl: './images-viewer.component.html'
})

export class ImagesViewerComponent {
  attachments: Attachment[];
  attachment: Attachment;
  @Input() set attachmentIds(attachmentIds: string[]) { this.loadAttachments(attachmentIds); };

  currentAttachmentIndex: number = 0;
  currentImageIndex: number = 0;
  currentAttachmentThumbnails: Array<SafeUrl> = []; // allow for thumbnails to be passed in
  fillDisplay = false; // boolean to track whether to fill display with viewer
  displayThumbnails = true; // track whether to display thumbnails or not
  sideThumbnails: any[] = [];

  constructor(private snackBar: MatSnackBar,
    private attachmentService: AttachmentService,
    private sanitizer: DomSanitizer) { }

  /**
   * Load attachment in the current block
   * @param attachmentIds array of attachment ids
   */
  loadAttachments(attachmentIds: string[]) {
    // reset attachments array
    this.attachments = [];

    // get the last attachment in the array
    const attachmentId = attachmentIds[attachmentIds.length - 1];

    this.attachmentService.getAttachment(attachmentId)
      .then(attachment => {
        this.attachment = attachment;

        this.loadThumbnails();
      })
      .catch(error => {
        console.log('Error loading attachment', error);
        const snackBarRef = this.snackBar.open('Error loading attachment object, please try again', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          this.loadAttachments(attachmentIds);
        });
      });
  }

  /**
   * Load side thumbnails to the ui array
   */
  loadThumbnails() {
    this.currentImageIndex = 0;
    this.currentAttachmentThumbnails = [];

    // load sidebar thumbnails
    Object.keys(this.attachment._attachments).forEach((attachmentFileName, i) => {
      // get the file index
      const fileIndexArray = attachmentFileName.split('');
      const fileIndex = fileIndexArray[fileIndexArray.length - 1];
      this.attachmentService.getAttachmentFile(this.attachment._id, attachmentFileName).then((blob) => {
        if (blob.type === 'application/dicom') {
          // set the dicom thumbnails
          const src = this.sanitizer.bypassSecurityTrustUrl(this.attachment.dicomThumbnails[fileIndex]
              .changingThisBreaksApplicationSecurity);
          this.sideThumbnails[i] = src;
        } else {
          const url = URL.createObjectURL(blob);
          const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
          this.sideThumbnails[i] = imgSrc;
        }

      }).catch((err) => {
          this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
          console.log(err);
      });

    });

    // load the attachment files
    this.attachmentService.getAttachmentFilesAsObjectUrls(this.attachment)
      .then((thumbnails: Array<SafeUrl>) => {
        this.currentAttachmentThumbnails = thumbnails
      })
      .catch(error => {
        this.snackBar.open('Unable to load file attachment URL', 'Error', { duration: 6000 });
        console.log(error);
    });
  }

  /**
   * Toggle display of thumbnails bar
   */
  toggleThumbnails() {
    this.displayThumbnails = !this.displayThumbnails;
  }

  /**
   * Display the image viewer in fullscreen mode
   */
  toggleFullScreen() {
    this.fillDisplay = !this.fillDisplay;
  }

  /**
   * Display selected thumbnail in main area
   */
  displaySelectImg(index) {
    this.currentImageIndex = index;
  }

  /**
   * Display next image in series
   */
  displayNextImage() {
    if (this.currentImageIndex === this.currentAttachmentThumbnails.length - 1) { return; }
    this.currentImageIndex++;
  }

  /**
   * Display previous image in series
   */
  displayPrevImage() {
    if (this.currentImageIndex === 0) { return; }
    this.currentImageIndex--;
  }


}
