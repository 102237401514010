import { PouchObject } from './pouch-object';

export class NarrativeBlock implements PouchObject {
  public static type: string = 'narrative_blocks';
  public static fields = ['_id', 'id', '_rev', 'blockid', 'type', '_deleted', 'blockname', 'showQuestion', 'showAnswer',
    'showBlock', 'hasAttachments', 'segments', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility'
    , 'blockIndexId'];
  _id: string;
  id: number;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  dateAdded: Date;
  dateUpdated: Date;
  updatedBy: String;
  createdBy: String;
  createFacility: string;
  updateFacility: string;

  blockid: number;
  blockname: string;
  blockIndexId: number;
  showQuestion?: boolean;
  showAnswer: boolean;
  showBlock: boolean;
  hasAttachments: boolean;
  segments: BlockSegment[];
  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class BlockSegment {
  _id: string;
  blockId: string;
  segmentName: string;
  segmentAnswer?: string;
  patientRequest?: string;
  attachment?: any;
  dateAdded?: Date;
}
