import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Globals } from '../../services/globals';
import { Patient, PatientWithStatus } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { AttachmentService } from '../../services/attachment.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NarrativeService } from '../../services/narrative.service'
import { RepositoryService, AppTypes } from '../../services/repository.service';
import { RepositoryObserver } from '../../services/repository-observer';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatDialogConfig } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge, fromEvent } from 'rxjs';
import { Narrative, ReferralStatus } from '../../models/narrative';
import { AppSettingsService } from '../../services/settings.service';

import { MatSnackBar } from '@angular/material';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../models/config';
import { InsuranceClient } from '../../models/insurance-client';

import { MergeFixPatientDialogComponent } from '../merge-fix-patient/merge-fix-patient.component';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import { NarrativeEnumTypes, NarrativeInterface } from '../../models/narrative-types';
import { Enquiry } from '../../models/enquiry';
import { Chat } from '../../models/chat';
import { Attachment } from '../../models/attachment';
import { ShareLinks } from '../../models/sharelinks';
import { Appointment } from '../../models/appointment';
import { ChatService } from '../../services/chat.service';
import { AppointmentService } from '../../services/appointment.service';

@Component({
  selector: 'psoc-patient-duplicates-list',
  templateUrl: 'patient-duplicates-list.component.html'
})

export class PatientDuplicatesListComponent implements OnInit {
  // displayedColumns = ['id', 'name', 'dateOfBirth', 'sex', 'dateAdded', 'status'];  // with column status
  adminColumn: string = '';
  displayedColumns = ['id', 'name', 'dateOfBirth', 'sex', 'dateAdded', 'merge'];
  patientDatabase: PatientDatabase;
  dataSource: PatientsDataSource | null;
  appTypes = AppTypes;
  ReferralStatusEnum = ReferralStatus;
  referralStatuses = this.repository.enumSelector(ReferralStatus);
  env = environment;
  // settings data
  appSettings: AppSettings;
  loadingAppSettings: boolean;

  // insurance member data
  clients: InsuranceClient[] = [];
  loadingClients: boolean;
  searchingPatients: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('mergeFixPatientDialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

  dialogRef: MatDialogRef<any>;
  patient: Patient;
  patients: PatientWithStatus[] = [];
  loadingPatients: boolean = false;

  ngOnInit() {
    // load app settings and add get clients(if appSettings.showInsurerClientSearch === true) & patients
    this.loadAppSettings();

    // set up patients database
    this.patientDatabase = new PatientDatabase(this.repository);

    // set up patients dataSource
    this.dataSource = new PatientsDataSource(this.patientDatabase, this.paginator, this.sort);

    // listen to keystrokes for search function
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged())
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  /**
   * Load app settings
   */
  loadAppSettings(appSettingsId: string = this.env.settingsId) {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(appSettingsId)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadingAppSettings = false;
        // load insurance member data
        if (this.repository.getApp() === AppTypes.adminApp && this.authService.isAdmin() && this.appSettings.showEnquiriesView) {
          this.displayedColumns.splice(1, 0, 'schemeName');
        }
        this.getPatients();
      })
      .catch(() => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings');
        this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /** get list of patients */
  getPatients(): void {
    this.loadingPatients = true;

    this.patientService.loadDuplicatePatients()
      .then((patients: Array<PatientWithStatus>) => {
        // console.log('Patients::', this.patients);
        this.patients = patients.map((patient: PatientWithStatus) => {
          patient = new PatientWithStatus(patient);
          patient = new PatientWIthDetails(patient);
          // for each duplicate patient id, get the duplicates, flag the oldest as oldest = true; else false;
          this.patientService.findPatientsByRegNo(patient.idnumber)
            .then((matchedPatients) => {
              // flag the oldest patient; use this to add a 'Merge' button
              patient.oldest = (patient._id === matchedPatients[0]._id);
            }).catch((err) => {
              console.log('Error getting patients', err);
            });
          return patient;
        });

        this.patientDatabase.data = this.patients;
        this.loadingPatients = false;
      }).catch(error => {
        this.loadingPatients = true;
        console.log('Error loading list of patients: ', error)
        const snackBarRef = this.snackBar.open('Error loading list of patients, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getPatients()
        });
      });
  }

  /**
   * merge fix patient dialog with demographic data and patients objects
   */
  openMergeFixPatientModal(patient: PatientWIthDetails) {
    // get the selected patient (oldest patient)
    this.patient = new Patient(patient);

    // get the patients with the same reg no as the selected patient
    const duplicates: Patient[] = this.patients.filter(p => p.idnumber === patient.idnumber)
      .sort((a, b) => (new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())); // sort (latest to oldest)

    const config: MatDialogConfig = { height: '80vh', viewContainerRef: this.dialogAnchor };
    this.dialogRef = this.dialog.open(MergeFixPatientDialogComponent, config);
    this.dialogRef.componentInstance.setServices(this.patientService, this.snackBar, this.appSettings, this.narrativeService,
      this.attachmentService, this.appointmentService, this.chatService, this.repository);
    this.dialogRef.componentInstance.setOldestPatient(this.patient);
    this.dialogRef.componentInstance.setData(duplicates);

    // set patient list narratives
    this.dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'navigate') {
        this.narrativeList(this.patient);
      }
      return;
    });
  }
  // show view Narrative
  narrativeList(patient: Patient): void {
    this.router.navigate(['/patient/' + patient.id + '/narratives']);
  }

  // show edit form for selected patient
  editDemographics(patient: Patient): void {
    this.router.navigate(['/patient/edit/', patient.id]);
  }

  /** apply filter for patient list */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // move to first page if searching
    this.paginator.firstPage();
  }

  constructor(
    private router: Router,
    private globals: Globals,
    public repository: RepositoryService,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private appointmentService: AppointmentService,
    private chatService: ChatService,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private appSettingsService: AppSettingsService
  ) {
    // update current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }
}

// Database Object for the patients table
export class PatientDatabase implements RepositoryObserver {
  // dialogRef: MatDialogRef<any>;
  @ViewChild('filter') filter: ElementRef
  // patient list
  dataChange: BehaviorSubject<PatientWithStatus[]> = new BehaviorSubject<PatientWithStatus[]>([]);
  get data(): PatientWithStatus[] { return this.dataChange.value; }
  set data(patients: PatientWithStatus[]) { this.dataChange.next(patients); }

  constructor(private repository: RepositoryService) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === Patient.type) {
      this.dataChange.next(this.data);
    }
  }

}

// DataSource Object for the patients table
export class PatientsDataSource extends DataSource<PatientWithStatus> {
  filteredData: PatientWithStatus[] = [];
  renderedData: PatientWithStatus[] = [];
  _filterPatientChange = new BehaviorSubject('');

  get filter(): string { return this._filterPatientChange.value; }
  set filter(filter: string) { this._filterPatientChange.next(filter); }

  constructor(private _patientDatabase: PatientDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   */
  connect(): Observable<PatientWithStatus[]> {
    const displayPatientDataChanges = [
      this._patientDatabase.dataChange,
      this._filterPatientChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return merge(...displayPatientDataChanges).pipe(map(() => {
      // filter by search terms
      this.filteredData = this._patientDatabase.data.slice().filter((item: PatientWithStatus) => {
        const searchStr = (item.firstname + ' ' + item.lastname).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // sort by selected column
      const sortedData = this.getSortedData(this.filteredData.slice());

      // display current page of info
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize)
    }));

  }

  disconnect() { }

  /**
   * function to sort data; default by idnumber
   */
  getSortedData(data: PatientWithStatus[]): PatientWithStatus[] {
    this._sort.active = 'id';
    this._sort.direction = 'asc';

    return data.sort((a, b) => {
      let propertyA: number | string | Date = '';
      let propertyB: number | string | Date = '';
      [propertyA, propertyB] = [a.idnumber, b.idnumber];

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1) * (this._sort.active === 'id' ? 1 : -1);
    });
  }
}

export class PatientWIthDetails extends PatientWithStatus {
  oldest?: boolean;
}
