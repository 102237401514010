import { PouchObject } from './pouch-object';
import { NarrativeInterface, NarrativeEnumTypes } from './narrative-types';

export class ObstetricNarrative implements PouchObject, NarrativeInterface {
  public static type: string = 'narrative';
  public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'narrativeType', 'patientId', 'transactionStatus', 'scanningClinician',
    'scanFacility', 'otherScanFacility', 'dateOfLNMP', 'fetalWeight', 'dLMPUnknown', 'gravida', 'para', 'scanIndication', 'gestationAge',
    'actualGestationAge', 'dateOfDelivery', 'dateOfDeliveryByScan', 'dateOfDeliveryFrom', 'nurseNotes', 'imagesList', 'sonographyBlocks',
    'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'completed'];

  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;

  narrativeType: NarrativeEnumTypes = NarrativeEnumTypes.obstetric;
  patientId: number;

  transactionStatus: string;

  // narrative record
  scanningClinician: string;
  scanFacility: string;
  otherScanFacility: string;
  dateOfLNMP: Date;
  scanIndication: string;
  gravida: string;
  para: string;
  gestationAge: number;
  actualGestationAge: string;
  dateOfDelivery: Date;
  dateOfDeliveryByScan: Date;
  dateOfDeliveryFrom: string;
  dLMPUnknown: boolean;
  fetalWeight: number;
  nurseNotes: string;

  // sonography blocks
  imagesList = new Array<any>();
  sonographyBlocks: SonographyBlocks = new SonographyBlocks();

  // narrative statuses
  completed: boolean = false;

  dateAdded: Date;
  dateUpdated: Date;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;
  sharedFacility: string[];

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  getSummary(patientDob: Date, patientCreateDate: Date): string {
    if (this.scanIndication) {
      return this.scanIndication;
    } else {
      return this.narrativeType + ' USG';
    }
  }

}

export enum BlocksOrderEnum {
  ultrasoundRecordBlock = 0,
  numberOfFoetusBlock = 1,
  foetalLieBlock = 2,
  foetalPresentationBlock = 3,
  heartBlock = 4,
  headAndSpineBlock = 5,
  foetalAbdomenBlock = 6,
  foetalLimbsBlock = 7,
  placentaBlock = 8,
  amnioticFluidBlock = 9,
  maternalAnatomyBlock = 10,
  sonoRecommendationsBlock = 11
}

export class SonographyBlock {
  blockType: string;
  nurseNotes: string;
  blockStatus: string;
  attachmentIds: string[] = [];

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class SonographyBlocks {
  numberOfFoetusBlock: NumberOfFoetusBlock = new NumberOfFoetusBlock();
  foetuses: FoetusBlocks[] = new Array<FoetusBlocks>();
  maternalAnatomyBlock: MaternalAnatomyBlock = new MaternalAnatomyBlock();

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}
export class NumberOfFoetusBlock extends SonographyBlock {
  blockType: string = 'numberOfFoetus';
  numberOfFoetus: number;
}

export class FoetusBlocks {
  foetalLieBlock: FoetalLieBlock = new FoetalLieBlock();
  foetalPresentationBlock: FoetalPresentationBlock = new FoetalPresentationBlock();
  heartBlock: HeartBlock = new HeartBlock();
  placentaBlock: PlacentaBlock = new PlacentaBlock();
  amnioticFluidBlock: AmnioticFluidBlock = new AmnioticFluidBlock();
  foetalLimbsBlock: FoetalLimbsBlock = new FoetalLimbsBlock();
  foetalAbdomenBlock: FoetalAbdomenBlock = new FoetalAbdomenBlock();
  headAndSpineBlock: HeadAndSpineBlock = new HeadAndSpineBlock();
}

// Foetus blocks
export class FoetalLieBlock extends SonographyBlock {
  blockType: string = 'foetalLie';
}

export class FoetalPresentationBlock extends SonographyBlock {
  blockType: string = 'foetalPresentation';
 }

export class HeartBlock extends SonographyBlock {
  blockType: string = 'heart';
  heartbeatFrequency: number;
}

export class PlacentaBlock extends SonographyBlock {
  blockType: string = 'placenta';
 }

export class AmnioticFluidBlock extends SonographyBlock {
  blockType: string = 'amnioticFluid';
  quadrant1: number;
  quadrant2: number;
  quadrant3: number;
  quadrant4: number;
  amnioticFluidIndex: number;
}

export class FoetalLimbsBlock extends SonographyBlock {
  blockType: string = 'foetalLimbs';
  femurLength: number = 0;
}
export class FoetalAbdomenBlock extends SonographyBlock {
  blockType: string = 'foetalAbdomen';
  abdominalCircumference: number;
}
export class HeadAndSpineBlock extends SonographyBlock {
  blockType: string = 'headAndSpine';
  crownRumpLength: number;
  headCircumference: number;
  biparietalDiameter: number;
}

export class MaternalAnatomyBlock extends SonographyBlock {
  blockType: string = 'maternalAnatomy';
}

export const scanningIndication = [
  '1st trimester scan',
  '2nd trimester scan',
  '3rd trimester scan'
];
