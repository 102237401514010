import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockPlacenta } from '../../../../models/obstetric-sharelink';
import { PlacentaBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-placenta-sonographer-view',
  templateUrl: 'placenta-sonographer-view.component.html'
})

export class PlacentaSonographerViewComponent {
  @Input() nurseData: PlacentaBlock;
  @Input() sonographerData: SonographerBlockPlacenta;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   *  trigger edit current block
   */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.sonographerData.blockStatus ?  true : false;
  }
}
