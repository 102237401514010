import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppMaterialModule } from '../../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from '../../pipes/pipes.module';

import { DndModule } from 'ng2-dnd';

import { SpecialistAttachmentsModule } from './attachments-view/attachments.module';
import { ValidationModule } from '../../validation/validation.module';

import { SharedLinksComponent } from './shared-links.component';
import { SharedLinksNarrativeViewComponent } from './narrative-view/shared-links-narrative-view.component';
import { SharedLinksMyReportComponent } from './my-report/shared-links-my-report.component';
import { SharedLinksSpecialistInfoComponent } from './my-report/shared-links-specialistInfo.component';
import { SharedLinksPharmaciesInfoComponent } from './my-report/shared-links-pharmaciesInfo.component';
import { SharedLinksPatientInfoComponent } from './my-report/shared-links-patient.component';
import { SharedLinksLabRequestComponent } from './my-report/shared-links-labrequest.component';
import { LabRequestViewComponent } from '../../narrative/shared-links/labrequest-view.component';
import { SharedLinksPrescriptionComponent } from './my-report/shared-links-prescription.component';
import { PrescriptionViewComponent } from '../../narrative/shared-links/prescription-view.component';
import { SharedLinksRadiologyComponent } from './my-report/shared-links-radiology.component';
import { SharedLinksDischargeSummaryComponent } from './discharge-summary/shared-links-discharge-summary.component'
import { SharedLinksRoutingModule } from './shared-links-routing.module';
import { AddShareLinksComponent, ExpireSharelinkDialogComponent } from '../../sharelinks/add/add-sharelinks.component';
import { NarrativeModule } from '../../narrative/narrative.module';
import { ReferralsModule } from '../../referrals/referrals.module';
import { ClipboardModule } from 'ngx-clipboard';
import { AttachmentsModule } from '../../attachments/attachments.module';
import { ScansViewComponent } from './scans-view/scans-view.component';
import { ObstetricViewComponent } from './obstetric-view/obstetric-view.component';
import { DeleteDiagnosisDialogComponent } from '../shared-links/my-report/shared-links-my-report.component';
import { DiagnosisService } from '../../services/diagnosis.service';

@NgModule({
  providers: [
    DiagnosisService
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    SharedLinksRoutingModule,
    SpecialistAttachmentsModule,
    MomentModule,
    ValidationModule,
    FlexLayoutModule,
    DndModule.forRoot(),
    PipesModule,
    NarrativeModule,
    ReferralsModule,
    ClipboardModule,
    AttachmentsModule
  ],
  exports: [DndModule, NarrativeModule],
  declarations: [
    SharedLinksComponent,
    SharedLinksNarrativeViewComponent,
    SharedLinksMyReportComponent,
    SharedLinksSpecialistInfoComponent,
    SharedLinksPharmaciesInfoComponent,
    SharedLinksPatientInfoComponent,
    SharedLinksLabRequestComponent,
    SharedLinksPrescriptionComponent,
    LabRequestViewComponent,
    PrescriptionViewComponent,
    SharedLinksDischargeSummaryComponent,
    AddShareLinksComponent,
    ExpireSharelinkDialogComponent,
    ScansViewComponent,
    ObstetricViewComponent,
    SharedLinksRadiologyComponent,
    DeleteDiagnosisDialogComponent
  ],
  entryComponents: [
    ExpireSharelinkDialogComponent,
    DeleteDiagnosisDialogComponent
  ]
})
export class SharedLinksModule { }
