import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';

import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient';
import { ShareLinks } from '../models/sharelinks';

@Component({
  selector: 'app-records',
  templateUrl: 'records.component.html'
})
export class RecordsComponent implements OnInit {

  patients: Patient[];
  sharelinks: ShareLinks[];

  form: FormGroup;
  maxDate = new Date();
  dateFrom = new Date('Sun Jan 01 2017 03:00:00 GMT+0300 (EAT)');
  dateTo = new Date();
  displayDateFrom;
  displayDateTo;
  filterRecords: number;

  allPatients;
  allSharelinks;
  allActiveLinks;
  allExpiredLinks;
  narrativeActveLinks;
  groupActiveLinksByNarrative = new Array<number>();
  groupExpiredLinksByNarrative = new Array<number>();
  groupActiveNum: number;
  groupExpiredNum: number;

  loadingPatients = false;
  loadingSharelinks = false;
  connectionErr = false;

  constructor(
    private patientService: PatientService,
    private snackBar: MatSnackBar
  ) {
    this.form = new FormGroup({
      startDate: new FormControl(this.dateFrom),
      endDate: new FormControl(new Date)
    });
  }

  // get all patients function
  getPatients() {
    this.loadingPatients = true
    this.loadingSharelinks = true;

    this.patientService.getAllPatients()
      .then(patients => {
        // all patients
        this.patients = patients;

        this.allPatients = this.patients.length; // number of all the records
        this.loadingPatients = false;

        this.filterRecords = 0;
        for (let p = 0; p <= this.patients.length; p++) {
          if (patients[p]) {

            this.displayDateFrom = this.dateFrom.getTime();
            this.displayDateTo = this.dateTo.getTime();
            const dateAdded = new Date(patients[p].dateAdded);

            if (dateAdded >= this.dateFrom && dateAdded <= this.dateTo) {
              this.filterRecords++;
            }
          }
        }
      })
      .catch(err => {
        console.log('error loading patient data.' + err)
        if (err === 'ETIMEDOUT') {
          this.snackBar.open('Internet connection error.', 'Error');
          this.loadingPatients = false
          this.connectionErr = true
        } else {
          console.log('Error loading patients data', 'Error')
          const snackBarRef = this.snackBar.open('Error loading patients data', 'Retry')
          snackBarRef.onAction().subscribe(() => {
            this.getPatients()
          });
          this.loadingPatients = false
          this.connectionErr = true
        }
      })
    // get the sharelinks
    this.getAllShareLinks();
  }

  // get all the saharelinks function
  getAllShareLinks() {
    this.patientService.getAllShareLinks().then(sharelinks => {
      this.sharelinks = sharelinks;
      this.allSharelinks = this.sharelinks.length;

      this.getShareLinks(); // call function to get the sharelinks
      this.loadingSharelinks = false
    }).catch(err => {
      console.log('error getting data!' + err);
      if (err === 'ETIMEDOUT') {
        this.snackBar.open('Internet connection error.', 'Error');
        this.loadingSharelinks = false
        this.connectionErr = true
      } else {
        const snackBarRef = this.snackBar.open('Error loading sharelink data', 'Retry')
        snackBarRef.onAction().subscribe(() => {
          this.getAllShareLinks()
        });
        this.loadingSharelinks = false
        this.connectionErr = true
      }
    })
  }

  // funtion to get active links fro the db
  getShareLinks() {
    this.allActiveLinks = 0;
    this.allExpiredLinks = 0;

    // loop of all the sharelinks
    try {
      for (let i = 0; i <= this.sharelinks.length; i++) {
        if (this.sharelinks[i]) {
          if (this.sharelinks[i].expired === false) {
            // count of all the live sharelinks
            this.allActiveLinks++;
            if (!this.groupActiveLinksByNarrative.includes(this.sharelinks[i].narrativeId)) {
              this.groupActiveLinksByNarrative.push(this.sharelinks[i].narrativeId);
            }
          } else {
            // count of all the expired sharelinks
            this.allExpiredLinks++;
            if (!this.groupExpiredLinksByNarrative.includes(this.sharelinks[i].narrativeId)) {
              this.groupExpiredLinksByNarrative.push(this.sharelinks[i].narrativeId);
            }
          }
        }
      }
      // get the count of both the live and expired sharelinks
      this.groupActiveNum = this.groupActiveLinksByNarrative.length;
      this.groupExpiredNum = this.groupExpiredLinksByNarrative.length;
    } catch (error) {
      const snackBarRef = this.snackBar.open('Error loading active sharelinks data', 'Retry')
      snackBarRef.onAction().subscribe(() => {
        this.getShareLinks()
      });
    }
  }

  // reload page
  reloadPage() {
    window.location.reload()
  }

  ngOnInit() {
    // get all the patients
    this.getPatients();
  }

  // filter data by date
  onSubmitFilter() {
    this.dateFrom = this.form.value.startDate;
    this.dateTo = this.form.value.endDate;

    this.filterRecords = 0;
    for (let p = 0; p <= this.patients.length; p++) {
      if (this.patients[p]) {

        this.displayDateFrom = this.dateFrom.getTime()
        this.displayDateTo = this.dateTo.getTime()
        const dateAdded = new Date(this.patients[p].dateAdded)

        if (dateAdded >= this.dateFrom && dateAdded <= this.dateTo) {
          this.filterRecords++;
        }
      }
    }
  }
}
