import { Component, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { HeartBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SonographerBlockHeart } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-heart-sonographer-edit',
  templateUrl: './heart-sonographer-edit.component.html'
})

export class HeartSonographerEditComponent extends SonographerBlockEditComponent implements AfterViewChecked {
  @Input() nurseData: HeartBlock;
  @Input() blockData: SonographerBlockHeart;
  @Input() selectedFoetus;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;
  heartBeatValid = false;

  blockStatusOptions = ['Present', 'Absent', 'Not seen', 'Not applicable'];
  form: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: [''],
      heartbeatFrequency: [''],
      cantConfirmHeartbeatFrequency: [''],
      sonographerNotes: ['']
    })
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    this.heartBeatValid = (this.blockData.heartbeatFrequency > 0 || this.blockData.cantConfirmHeartbeatFrequency);
    return this.blockData.blockStatus && this.heartBeatValid ? true : false;
  }
}
