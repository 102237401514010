import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockHeadAndSpine } from '../../../../models/obstetric-sharelink';
import { HeadAndSpineBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-head-spine-sonographer-view',
  templateUrl: 'head-spine-sonographer-view.component.html'
})

export class HeadAndSpineSonographerViewComponent {
  @Input() firstTrimester: Boolean;
  @Input() nurseData: HeadAndSpineBlock;
  @Input() sonographerData: SonographerBlockHeadAndSpine;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    let blockValid = this.sonographerData.blockStatus  ?  true : false;

    if (!this.firstTrimester) {
      const biparietalDiameter = this.sonographerData.biparietalDiameter > 0 || this.sonographerData.cantConfirmBiparietalDiameter;
      const headCircumference = this.sonographerData.headCircumference > 0 || this.sonographerData.cantConfirmHeadCircumference;
      blockValid = blockValid && (biparietalDiameter && headCircumference);
    } else {
      const crownRumpLengthValid = this.sonographerData.crownRumpLength > 0 || this.sonographerData.cantConfirmCrownRumpLength;
      blockValid = blockValid && crownRumpLengthValid;
    }

    return blockValid;
  }
}
