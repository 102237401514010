import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceClient } from '../models/insurance-client';
import { Patient, PatientWithStatus } from '../models/patient';

@Pipe({ name: 'checkPatientInClientDB' })
export class CheckPatientInClientDBPipe implements PipeTransform {
  transform(patient: PatientWithStatus, clients: InsuranceClient[]): string {
    return clients.find(c => c.registrationNumber === patient.idnumber
      && c.schemeName === patient.schemename) !== undefined ? 'Yes' : 'No';
  }
}
