import {
    Component, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef, AfterViewChecked
} from '@angular/core';

import { MatSnackBar, MatIconRegistry } from '@angular/material';
import { Attachment } from '../../models/attachment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AttachmentService } from '../../services/attachment.service';
import { DicomViewerComponent } from '../dicom/viewer/dicom-viewer.component';
import * as PDFJS from 'pdfjs-dist/build/pdf';
PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.js';

@Component({
    selector: 'psoc-imagelist-view',
    templateUrl: 'imagelist-view.component.html',
    styleUrls: ['imagelist.css']
})

export class ImagelistViewComponent implements AfterViewChecked {

    myAttachment: Attachment;
    currentIndex: number = 0; // index selected thumbnail
    fillDisplay = false; // boolean to track whether to fill display with viewer
    displayThumbnails = true; // track whether to display thumbnails or not
    measureToolEnabled: Boolean = false;
    ellipticalToolEnabled: Boolean = false;

    blob: any;
    pdfDoc: any;
    pdfCanvas: any;
    srcPdf: SafeUrl;
    pageRendered: any;
    files: File[] = [];
    totalPages: number;

    @Input() sonographerView: boolean = false;
    @Input() disableEditButton: boolean = false;
    @Input() autoLoadThumbnails: boolean = true;
    @Input() sideThumbnails: Array<any> = [];
    @Input() thumbnails: Array<any> = []; // allow for thumbnails to be passed in
    @Input() set attachment(attachment: Attachment) {
        this.myAttachment = attachment;

        // if we don't have _attachments, reload them
        if (this.myAttachment.reportLink) {
            this.thumbnails = [];
            this.loadPdf(this.myAttachment.reportLink);  // load pdf from url
        } else if (!this.myAttachment._attachments) {
            this.attachmentService.getAttachment(this.myAttachment._id)
                .then(fullAttachment => {
                    this.attachment = fullAttachment;
                }).catch(error => {
                    console.log('Error loading attachment', error);
                    const snackBarRef = this.snackBar.open('Unable to load attachment object, please try again', 'Retry');
                    snackBarRef.onAction().subscribe(() => {
                        this.attachment = attachment;
                    });
                });
        } else {
            this.loadThumbnails();
        }
    }

    @ViewChild('pdfCanvas') pdfCanvasRef: ElementRef;
    @Output() editAttachment = new EventEmitter();
    @ViewChild(DicomViewerComponent) dicomViewerComponent: DicomViewerComponent;
    @HostListener('mousewheel', ['$event']) onMousewheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        if (delta > 0) {
            this.displayNextImage();
        } else {
            this.displayPrevImage();
        }
    }

    constructor(
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
        matIconRegistry: MatIconRegistry,
        private attachmentService: AttachmentService,
    ) {
        matIconRegistry.addSvgIcon('ruler', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler.svg'));
        matIconRegistry.addSvgIcon('circle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/circle.svg'));
    }

    /** Fix for view changed errors */
    ngAfterViewChecked() {
        this.pdfCanvas = this.pdfCanvasRef.nativeElement;
    }

    showEditAttachment() {
        this.editAttachment.emit();
    }

    loadThumbnails() {
        this.currentIndex = 0;

        // don't load our own thumbnails, they will be passed in by parent
        if (!this.autoLoadThumbnails) { return; }

        this.thumbnails = [];

        Object.keys(this.myAttachment._attachments).forEach(attachmentFileName => {
            this.attachmentService.getAttachmentFile(this.myAttachment._id, attachmentFileName).then((blob) => {
                const url = URL.createObjectURL(blob);
                const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
                const attachmentObj = { 'url': imgSrc, 'mediaType': blob.type }
                this.thumbnails.push(attachmentObj);
            }).catch((err) => {
                this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
                console.log(err);
            });

        });
    }

    /**
     * Toggle display of thumbnails bar
     */
    toggleThumbnails() {
        this.displayThumbnails = !this.displayThumbnails;
    }

    /**
     * Display the image viewer in fullscreen mode
     */
    toggleFullScreen() {
        this.fillDisplay = !this.fillDisplay;
    }

    /**
     * Display selected thumbnail in main area
     */
    displaySelectImg(index) {
        this.currentIndex = index;
    }

    /**
     * Display next image in series
     */
    displayNextImage() {
        if (this.currentIndex === this.thumbnails.length - 1) { return; }
        this.currentIndex++;
        if (this.measureToolEnabled) { this.toggleMeasureTool(); } // deactivate measuretool if active
        if (this.ellipticalToolEnabled) { this.toggleEllipticalTool(); } // deactivate ellipticalTool if active
    }

    /**
     * Display previous image in series
     */
    displayPrevImage() {
        if (this.currentIndex === 0) { return; }
        this.currentIndex--;
        if (this.measureToolEnabled) { this.toggleMeasureTool(); } // deactivate measuretool if active
        if (this.ellipticalToolEnabled) { this.toggleEllipticalTool(); } // deactivate ellipticalTool if active
    }

    /**
     * Toggle on and off the dicom measuretool
     */
    toggleMeasureTool() {
        this.measureToolEnabled = !this.measureToolEnabled;
        this.dicomViewerComponent.activateLengthTool(this.measureToolEnabled);
    }

    /**
     * Toggle on and off the dicom ellipticaltool
     */
    toggleEllipticalTool() {
        this.ellipticalToolEnabled = !this.ellipticalToolEnabled;
        this.dicomViewerComponent.activateEllipticalTool(this.ellipticalToolEnabled);
    }

    /** load pdf file from a url */
    loadPdf(pdfSrc: any) {
        this.setPdfDoc(pdfSrc).then(pdfDoc => {
            this.pdfDoc = pdfDoc;
            this.totalPages = this.pdfDoc.numPages;
            console.log('Total Pages', this.totalPages)
            for (let i = 1; i <= this.totalPages; i++) {
                this.generateView(i);
            }
        }).catch(e => {
            console.log(e);
            this.snackBar.open('Unable to load pdf document', 'Error', { duration: 6000 });
        });
    }

    /**
     * load pdf and return pdf document object
    */
    setPdfDoc(pdfSrc) {
        return new Promise((resolve, reject) => {
            if (!pdfSrc) { reject('PDF url is null, Please specify pdf url'); }

            PDFJS.getDocument(pdfSrc).promise.then((pdfDoc) => {
                resolve(pdfDoc);
            }, (error) => {
                reject(error);
            });

        });
    }

    /**
     * Render page on the canvas.
     * @param pageNumber pdf file page number
     */
    generateView(pageNumber) {
        this.pdfDoc.getPage(pageNumber).then((page) => { // split the pdf into individual pages
            const viewport = page.getViewport({ scale: 1 });
            const context = this.pdfCanvas.getContext('2d');
            this.pdfCanvas.height = viewport.height;
            this.pdfCanvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };

            this.pageRendered = page.render(renderContext) // render page onto canvas
            this.pageRendered.promise.then(convertedPage => {
                console.log('Converted Page Number', pageNumber)
                this.addPage(this.pdfCanvas)
                if (pageNumber < this.totalPages) {
                    pageNumber++
                    this.generateView(pageNumber);
                }
            }).catch(error => {
                console.log('Problem encountered while converting image', error)
            })
        });
    }

    /** add canvas to the thumbnails list for display */
    addPage(pdfCanvas: any) {
        this.convertPage(pdfCanvas).then(blob => {
            this.blob = blob;
            this.files.push(this.blob)
            const srcUrl = URL.createObjectURL(this.blob)
            this.srcPdf = this.sanitizer.bypassSecurityTrustUrl(srcUrl);
            this.thumbnails.push({ 'url': this.srcPdf });
            this.currentIndex = 0;
        }).catch(e => {
            console.log(e);
            this.snackBar.open('Unable to load pdf document', 'Error', { duration: 6000 });
        });
    }

    /** convert individual pdf page to image. */
    convertPage(pdfCanvas) {
        return new Promise((resolve, reject) => {
            if (!pdfCanvas) { reject('Canvas not rendered with page'); }
            pdfCanvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 0.95)
        });
    }

}
