import { OnInit, Directive, ElementRef, Renderer } from '@angular/core';

// custom reusable directive that will focus the element as soon as it is created
@Directive({
  selector: '[focusMe]'
})
export class InputFocusDirective implements OnInit {

  constructor(private hostElement: ElementRef, private renderer: Renderer) { }

  // implementation of focusMe directive
  ngOnInit() {
    this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
  }
}
