import { Component, Input } from '@angular/core';
import { AmnioticFluidBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-amniotic-fluid-nurse-view',
  templateUrl: './amniotic-fluid-nurse-view.component.html'
})

export class AmnioticFluidNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  @Input() firstTrimester: Boolean = false;
  _blockData: AmnioticFluidBlock;
  @Input()
  set blockData(blockData: AmnioticFluidBlock) {
    this._blockData = blockData;
    this.getAttachmentFilesCount(blockData.attachmentIds);
  }
  get blockData(): AmnioticFluidBlock {
    return this._blockData;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }
}
