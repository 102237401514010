import { NgModule } from '@angular/core';
import { OrderBy } from './orderByPipe';
import { OrderByDate } from './orderByDatePipe';
import { RemoveUserPrefix } from './removeUserPrefixPipe';
import { GetNarrativeStatus } from './getNarrativeStatusPipe';
import { GetPatientName, GetPatientReg } from './getPatientDetailsPipe';
import { GetFacilityName } from './getFacilityNamePipe';
import { GetTimeDifference } from './getTimeDifference';
import { GetUserNames } from './getUserNames';
import { FormatPhone } from './formatPhone';
import { GetNarrativeUrgency } from './getNarrativeUrgencyPipe';
import { CheckPatientInClientDBPipe } from './patientInClientDBPipe';

@NgModule({
  declarations: [OrderBy, OrderByDate, RemoveUserPrefix, GetNarrativeStatus, GetNarrativeUrgency, GetPatientName, GetPatientReg,
    GetFacilityName, GetTimeDifference, GetUserNames, FormatPhone, CheckPatientInClientDBPipe],
  exports: [OrderBy, OrderByDate, RemoveUserPrefix, GetNarrativeStatus, GetNarrativeUrgency, GetPatientName, GetPatientReg, GetFacilityName,
    GetTimeDifference, GetUserNames, FormatPhone, CheckPatientInClientDBPipe]
})
export class PipesModule { }
