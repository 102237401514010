import { Component, Input } from '@angular/core';
import { SonographerBlockRecommendations } from '../../../../models/obstetric-sharelink';

@Component({
    selector: 'psoc-recommendations-sonographer-pdf-report-view',
    templateUrl: 'recommendations-sonographer-pdf-report-view.component.html'
})

export class RecommendationsSonographerPdfReportViewComponent {
    @Input() sonographerData: SonographerBlockRecommendations;
}
