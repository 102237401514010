export class Events {
    public static type: string = 'events';
    public static fields = ['_id', '_rev', 'type', 'event', 'objectId', 'objectType', 'userName', 'ipAddress', 'appVersion', 'dateAdded',
        'failed', 'processed'];
    _id: string;
    _rev: string;
    type: string;
    event: string; // any of 'added', 'updated', 'published', 'deleted', 'statusUpdated', 'draft', 'accessed'
    objectId: string;
    objectType: string;
    userName: string;
    ipAddress: string;
    appVersion: string;
    dateAdded: Date;

    message?: string; // used in referral status to show which status has been set
    extraDetails?: any; // extra details
    patientId?: number; // used when generating report
    processed?: boolean; // used by notifications app to mark event as processed
    failed?: boolean; // if event has failed it will be marked as failed
    reasonFailed?: string; // reason provided for processing failure
}
