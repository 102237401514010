import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockFoetalLie } from '../../../../models/obstetric-sharelink';
import { FoetalLieBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-foetal-lie-sonographer-view',
  templateUrl: './foetal-lie-sonographer-view.component.html'
})

export class FoetalLieSonographerViewComponent {
  @Input() sonographerData: SonographerBlockFoetalLie;
  @Input() nurseData: FoetalLieBlock;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() {}

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.sonographerData.blockStatus ?  true : false;
  }
}
