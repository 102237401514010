import { Injectable } from '@angular/core';
import { WebSocketService } from './websocket.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Patient } from '../models/patient';
import { BlocksOrderEnum } from '../models/obstetric-narrative';

const LOCAL_URL = 'ws://localhost:5000/live';

export interface Message {
  task: string,
  message?: string,
  data: any,
  thumbnails?: any,
  blockIndex?: string
}

@Injectable({
  providedIn: 'root'
})
export class MidSonoService {
  public messages: Subject<Message>;

  constructor(wsService: WebSocketService) {
    this.messages = <Subject<Message>>wsService
      .connect(LOCAL_URL)
      .pipe(
        map((response: MessageEvent): Message => {
          console.log('Socket Response:', response);
          const data = JSON.parse(response.data);
          return {
            task: data.task,
            message: data.message,
            data: data.data,
            thumbnails: data.thumbnails,
            blockIndex: data.block
          };
        })
      )
  }

  /** request scan from WirelessUSG */
  requestScan(patient: Patient, narrativeIndex: number, blockIndex: BlocksOrderEnum, foetusIndex: number = 0) {
    const message = {
      task: 'scan',
      data: {
        patientRecord: {
          patientId: patient.id.toString(),
          firstname: patient.firstname,
          sex: patient.sex,
          dateOfBirth: patient.dateOfBirth
        },
        foetusIndex: foetusIndex,
        blockIndex: blockIndex,
        narrativeIndex: narrativeIndex
      }
    }

    console.log('Requesting new scan from WirelessUSG: ', message);
    this.messages.next(message);
  }

  /** request middlware for list of images to load */
  requestFileList() {
    this.messages.next({ task: 'getImageList', data: {}, thumbnails: {} });
  }

  /** request middleware for a given image */
  requestFile(imagePath: String) {
    const message = {
      task: 'getImage',
      data: { imagePath: imagePath }
    }
    this.messages.next(message);
  }

  /** request thumbnail image from middleware */
  requestThumbnail(thumbnail: String) {
    const message = {
      task: 'getImageThumbnail',
      data: { imagePath: thumbnail }
    }
    this.messages.next(message);
  }

  /** request middleware to delete image from filesystem */
  requestDeleteFile(imagePath: String) {
    const message = {
      task: 'deleteImage',
      data: { imagePath: imagePath }
    }
    this.messages.next(message);
  }

  /** request middleware to restore device network state */
  requestRestoreDefaultConnection() {
    const message = {
      task: 'connectDefaultNetwork',
      data: {}
    }
    console.log('Requesting connection to default wifi network : ', message);
    this.messages.next(message);
  }

  /** ping middleware to see if the connection is working */
  pingMiddleware() {
    const message = {
      task: 'ping',
      data: {}
    }

    console.log('pinging middleware servive: ', message);
    this.messages.next(message);
  }

  /**
   * function to convert base64 to blob
   * @param b64 base64 string
   * @param contentType the blob type
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   */
  b64ToBlob(b64: string, contentType = 'image/jpeg', sliceSize = 512) {
    const byteCharacters = atob(b64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
