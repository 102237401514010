import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { PatientService } from '../services/patient.service';
import { RepositoryService } from '../services/repository.service';
import { Patient } from '../models/patient';
import { map } from 'rxjs/operators';

@Component({
  selector: 'psoc-app-records-patients',
  templateUrl: 'patients-list.component.html'
})
export class PatientListComponent implements OnInit {
  displayedColumns = ['firstname', 'lastname', 'dateOfBirth', 'sex', 'dateAdded'];
  patients: Patient[];
  patientDatabase = new PatientRecordsDatabase(this.patientService, this.snackBar);
  dataSource: PatientsRecordsDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private patientService: PatientService,
    private repositoryService: RepositoryService,
    private snackBar: MatSnackBar
    // private records: RecordsComponent
  ) { }

  ngOnInit() {
    this.dataSource = new PatientsRecordsDataSource(this.patientDatabase, this.paginator);
  }
}
export class PatientRecordsDatabase {
  selectedPatient: Patient[];
  patients: Patient[];
  dataChange: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  get data(): Patient[] { return this.dataChange.value; }

  constructor(
    private patientService: PatientService,
    private snackBar: MatSnackBar
  ) {
    this.getPatients();
  }

  // get all patients
  getPatients(): void {
    this.patientService.getAllPatients()
      .then((patients: Array<Patient>) => {
        this.dataChange.next(patients);
      })
      .catch(error => {
        console.log('Error loading patients');
        const snackBarRef = this.snackBar.open('Error loading patients', 'Retry')
        snackBarRef.onAction().subscribe(() => {
          this.getPatients()
        });
      });
  }
}

export class PatientsRecordsDataSource extends DataSource<Patient> {
  constructor(private _patientRecordsDatabase: PatientRecordsDatabase, private _paginator: MatPaginator) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Patient[]> {
    const displayPatientChange = [
      this._patientRecordsDatabase.dataChange,
      this._paginator.page
    ];
    return merge(...displayPatientChange).pipe(map(() => {
      const data = this._patientRecordsDatabase.data.slice();

      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() { }
}
