import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { AttachmentService } from '../../services/attachment.service';
import { RepositoryService } from '../../services/repository.service';
import { RepositoryObserver } from '../../services/repository-observer';
import { Router } from '@angular/router';
import { MatPaginator, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge, fromEvent } from 'rxjs';

import { MatSnackBar } from '@angular/material';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Enquiry } from '../../models/enquiry';

@Component({
  selector: 'psoc-enquiries-list',
  templateUrl: 'enquiries-list.component.html'
})

export class EnquiriesListComponent implements OnInit {
  displayedColumns = ['insurer', 'registrationNumber', 'name', 'schemeName', 'phonenumber', 'dateAdded', 'status'];
  enquiriesDatabase: EnquiriesDatabase;
  dataSource: EnquiriesDataSource | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.enquiriesDatabase =
      new EnquiriesDatabase(this.patientService, this.snackBar, this.attachmentService, this.repository);
    this.dataSource = new EnquiriesDataSource(this.enquiriesDatabase, this.paginator, this.sort, );

    // listen to keystrokes for search function
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged())
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  // show narratives list for this enquiry if available, else add patient
  showNarrativeList(enquiry: Enquiry): void {
    if (enquiry.patientId) {
      // enquiry has been processed, view narratives list
      this.router.navigate(['/patient/' + enquiry.patientId + '/narratives']);
    } else {
      // enquiry added from textitin, create new patient and edit
      this.router.navigate(['/patient/fromenquiry/' + enquiry._id]);
    }
  }

  /** apply filter for enquiries list */
  applyFilter(filterValue: string) {
    console.log('Filtering::');
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // move to first page if searching
    this.paginator.firstPage();
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    public repository: RepositoryService,
    private attachmentService: AttachmentService,
    private patientService: PatientService
  ) { }
}

// Database Object for the enquiries table
export class EnquiriesDatabase implements RepositoryObserver {
  @ViewChild('filter') filter: ElementRef
  dataChange: BehaviorSubject<Enquiry[]> = new BehaviorSubject<Enquiry[]>([]);
  get data(): Enquiry[] { return this.dataChange.value; }

  constructor(
    private patientService: PatientService,
    public snackBar: MatSnackBar,
    public attachmentService: AttachmentService,
    private repository: RepositoryService,
  ) {
    this.getEnquiries();
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === Enquiry.type) {
      this.getEnquiries();
    }
  }

  /** get list of enquiries and update their narrative status */
  getEnquiries(): void {
    this.patientService.getAllEnquiries()
      .then((enquiries: Array<Enquiry>) => {
        this.dataChange.next(enquiries);
      }).catch(error => {
        console.log('Error loading list of enquiries: ', error)
        const snackBarRef = this.snackBar.open('Error loading list of enquiries, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getEnquiries()
        });
      });
  }

}

// DataSource Object for the enquiries table
export class EnquiriesDataSource extends DataSource<Enquiry> {
  filteredData: Enquiry[] = [];
  renderedData: Enquiry[] = [];
  _filterEnquiriesChange = new BehaviorSubject('');

  get filter(): string { return this._filterEnquiriesChange.value; }
  set filter(filter: string) { this._filterEnquiriesChange.next(filter); }

  constructor(private _enquiriesDatabase: EnquiriesDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Enquiry[]> {
    const displayEnquiryDataChanges = [
      this._enquiriesDatabase.dataChange,
      this._filterEnquiriesChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return merge(...displayEnquiryDataChanges).pipe(map(() => {
      // filter by search terms
      this.filteredData = this._enquiriesDatabase.data.slice().filter((item: Enquiry) => {
        const searchStr = (item.name).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // sort by selected column
      const sortedData = this.getSortedData(this.filteredData.slice());

      // display current page of info
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize)
    }));

  }

  disconnect() { }

  getSortedData(data: Enquiry[]): Enquiry[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string | Date = '';
      let propertyB: number | string | Date = '';

      switch (this._sort.active) {
        case 'registrationNumber': [propertyA, propertyB] = [a.registrationNumber, b.registrationNumber]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
        case 'schemeType': [propertyA, propertyB] = [a.schemeType, b.schemeType]; break;
        case 'schemeName': [propertyA, propertyB] = [a.schemeName, b.schemeName]; break;
        case 'dateAdded': [propertyA, propertyB] = [a.dateAdded, b.dateAdded]; break;
        case 'patientId': [propertyA, propertyB] = [a.patientId, b.patientId]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
