import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CornerstoneModalComponent } from './cornerstone-modal';

@Component({
  selector: 'dicom-modal',
  template: '<b>{{title}}</b>'
})

export class DicomModalComponent {
  dialogRef: MatDialogRef<any>;

  title: string = '';

  constructor(private dialog: MatDialog) { }

  /**
   * Open dialog with cornerstone viewer
  */
  showStudy(studyUID: string) {
    this.dialogRef = this.dialog.open(CornerstoneModalComponent, { width: window.innerWidth + '%' });
    this.dialogRef.componentInstance.setPatientStudy(studyUID);
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.componentInstance = null;
      this.dialogRef = null;
    });
  }
}
