import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlacentaBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SonographerBlockPlacenta } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-placenta-sonographer-edit',
  templateUrl: 'placenta-sonographer-edit.component.html'
})

export class PlacentaSonographerEditComponent extends SonographerBlockEditComponent {
  @Input() nurseData: PlacentaBlock;
  @Input() blockData: SonographerBlockPlacenta;
  @Input() selectedFoetus;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;

  form: FormGroup;
  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
  @Output() updatedForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
    private _fb: FormBuilder,
  ) {
    super();
    this.form = this._fb.group({
      blockStatus: ['', [Validators.required]],
      sonographerNotes: ['']
    })
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.blockData.blockStatus ?  true : false;
  }
}
