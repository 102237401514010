import { Component, Input, ViewChild, ViewContainerRef, EventEmitter, Output, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { PlacentaBlock } from '../../../../models/obstetric-narrative';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { ObstetricBlockEditComponent } from '../block-edit.component';

@Component({
  selector: 'psoc-placenta-nurse-edit',
  templateUrl: './placenta-nurse-edit.component.html'
})

export class PlacentaNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
  _blockData: PlacentaBlock;
  @Output() blockDataChange: EventEmitter<PlacentaBlock> = new EventEmitter<PlacentaBlock>();
  @Input() get blockData() { return this._blockData }
  set blockData(blockData: PlacentaBlock) {
    if (blockData) { this._blockData = blockData } else { this._blockData = new PlacentaBlock(); }
    this.blockDataChange.emit(this._blockData);
    this.getAttachmentFilesCount(this._blockData.attachmentIds);
  };

  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
  dialogRef: MatDialogRef<any>;

  @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService,
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

    this.form = this.fb.group({
      blockStatus: ['', [Validators.required]],
      nurseNotes: ['']
    });

  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
  }

}
