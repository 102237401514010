import { Component, OnDestroy, ViewChild, Input } from '@angular/core';
import { MatSnackBar, MatIconRegistry } from '@angular/material';
import { CornerstoneService } from '../../../services/cornerstone.service';
import { DicomService } from '../../../services/dicom.service';
import { CornerstoneDirective } from '../../../directives/cornerstone.directive';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'psoc-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.css'],
  providers: [DicomService]
})

export class DicomViewerComponent implements OnDestroy {
  lengthToolEnabled: Boolean = false; // track whether measure tool is enabled
  ellipticalToolEnabled: Boolean = false; // track whether elliptical tool is enabled
  _dicomPath: string
  @Input() set dicomPath(dicomPath: string) {
    this.getImageData(dicomPath)
      .then(dicomImage => {
        this.cornerstoneDirective.addImage(dicomImage, 1);
      })
      .catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error loading dicom file');
      });
  } get dicomPath(): string { return this._dicomPath; }

  @ViewChild(CornerstoneDirective) cornerstoneDirective;

  constructor(
    private cornerstoneService: CornerstoneService,
    private snackBar: MatSnackBar,
    matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIcon('ruler', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler.svg'));
  }

  /**
   * Clear cache on close
   */
  ngOnDestroy() {
    this.cornerstoneService.destroyCache();
  }

  /**
   * Load a list of images into cornerstone
   */
  getImageData(imageUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {

      this.cornerstoneService.fetchDicomImage(imageUrl)
        .then(dicomImage => {
          resolve(dicomImage);
        }).catch(error => {
          console.log('Error fetching dicom image', error);
          reject(error);
        });

    });
  }

  /**
   * reset the cornerstone viewer size to fit its bounds
   */
  resizeDicom() {
    this.cornerstoneDirective.resize();
  }

  /**
    * Activate/deactivate the length tool
    */
  activateLengthTool(lengthTool: Boolean) {
    this.lengthToolEnabled = lengthTool;
    this.cornerstoneDirective.activateLengthTool(this.lengthToolEnabled)
  }

  /**
    * Activate/deactivate the length tool
    */
  activateEllipticalTool(ellipticalTool: Boolean) {
    this.ellipticalToolEnabled = ellipticalTool;
    this.cornerstoneDirective.activateEllipticalTool(this.ellipticalToolEnabled)
  }

  /**
   * Display previous instance
   */
  previous() {
    this.cornerstoneDirective.previousInstance()
  }

  /**
   * Display next instance
   */
  next() {
    this.cornerstoneDirective.nextInstance()
  }
}
