import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

import { Patient } from '../../../models/patient';
import { Narrative } from '../../../models/narrative';
import { Attachment } from '../../../models/attachment';

import { DicomService, Study } from '../../../services/dicom.service';
import { DicomModalComponent } from '../modal/dicom-modal.component';
import { AttachmentService } from '../../../services/attachment.service';

@Component({
  selector: 'psoc-dicom-search',
  templateUrl: 'dicom-search.component.html',
  providers: [DicomService]
})

export class DicomSearchComponent implements OnInit {
  @Input() attachments: Attachment[];
  @Input() patient: Patient;
  @Input() narrativeId: string;
  @Output() attachmentAdded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DicomModalComponent) public dicomModal: DicomModalComponent

  dicomSearchForm: FormGroup;
  dialogRef: MatDialogRef<any>;

  dicomSearchIdValue: string;
  dicomSearchNameValue: string;
  searchingFiles: boolean = false;
  dicomStudies = new Array<any>();
  dicomPreview: any;

  dicomStudiesSelected = new Array<boolean>(); // keep track of which studies have been selected
  studySelected: boolean; // track whether a study has been selected
  processingStudySave: string = null; // string to track processing of save study

  constructor(
    private snackBar: MatSnackBar,
    private dicomService: DicomService,
    private attachmentService: AttachmentService
  ) {
    this.dicomSearchForm = new FormGroup({
      dicomSearchId: new FormControl('', [Validators.required, Validators.minLength(3)]),
      dicomSearchName: new FormControl('', [Validators.required, Validators.minLength(3)])
    });
  }

  ngOnInit() {
    // get the first 3 chars of the patient name
    this.dicomSearchNameValue = '*' + (this.patient.firstname.substring(0, 3)).toUpperCase() + '*';
    this.searchDicomByName(); // pre-search dicom by patient name
  }

  /**
   * Toggle checked status of dicom study in list of dicom results
   */
  isChecked(i: number) {
    this.dicomStudiesSelected[i] = !this.dicomStudiesSelected[i];
    this.studySelected = true;
  }

  /**
   * Open dialog to view study
   */
  viewDicomDialog(studyUID: string) {
    this.dicomModal.showStudy(studyUID)
  }

  /**
   * Search dicom by patient id
   */
  searchDicomById() {
    this.clearDicomResults();
    this.loadDicomPatients('00100020', this.dicomSearchIdValue);
  }

  /**
   * Search dicom by patient name
   */
  searchDicomByName() {
    this.clearDicomResults();
    this.loadDicomPatients('00100010', this.dicomSearchNameValue);
  }

  /**
   * Cancel a search in progress
   */
  cancelDicomSearch() {
    this.searchingFiles = false;
  }

  /**
   * Clear current dicom search results
   */
  clearDicomResults() {
    this.dicomStudies = [];
    this.dicomStudiesSelected = new Array<boolean>();
    this.studySelected = false;
  }

  /**
   * Find Patients dicom / dicom search
   */
  loadDicomPatients(tagId: string, searchValue: string) {
    this.searchingFiles = true;

    this.dicomService.findStudies(tagId, searchValue)
      .then((studies: Array<any>) => {

        // if search has been cancelled, ignore results
        if (!this.searchingFiles) { return };

        this.searchingFiles = false;

        // check if the search result is empty
        if (studies.length !== 0) {
          this.dicomStudies = studies;
          for (let i = 0; i < studies.length; i++) {
            this.dicomStudiesSelected.push(false);
          }
        } else {
          this.dicomStudies = [];
          this.snackBar.open('No results found. Please check your search parameters and try again.', 'Notice', { duration: 6000 });
        }
      })
      .catch(err => {
        console.log('Error Getting Study!', err);
        let errMessage = '';
        switch (err.status) {

          case 504:
            // orthanc timeout
            errMessage = 'Error Getting Study, System Timeout';
            break;

          case 500:
            // orthanc timeout
            errMessage = 'Error Connection to server Interupted';
            break;

          default:
            // handle other errors during the search
            errMessage = 'Error Getting Study, Please try again';
            break;
        }

        const snackBarRef = this.snackBar.open(errMessage, 'Retry', { duration: 10000 });
        snackBarRef.onAction().subscribe(() => {
          this.loadDicomPatients(tagId, searchValue);
        });

        // disable search
        this.searchingFiles = false;
      });
  }

  /**
   * Load the thumbnail images for the current study for saving to attachment object
   */
  loadStudyThumbnail(studyUID: string) {

    // return a promise
    return new Promise((resolve, reject) => {

      // get first series for study
      this.processingStudySave = 'Requesting the first series of the study';
      this.dicomService.findSeriesByStudy(studyUID, [], [])
        .then((series: any[]) => {

          // get UID of first series in study
          const seriesUID = series[0]['0020000E'].Value['0'];

          // get first instance for series
          this.processingStudySave = 'Requesting the first instance of the series';
          this.dicomService.findInstancesByStudyAndSeries(studyUID, seriesUID, [], [])
            .then((instances: any[]) => {

              // load instance from pacs server
              this.processingStudySave = 'Loading the image for the first instance from PACS server';
              this.dicomService.findFirstInstanceOfSeries(studyUID, seriesUID, instances[0]['00080018'].Value['0']).then(blob => {
                const file = { content_type: 'image/jpeg', data: blob };
                console.log(file);
                resolve(file);
              }).catch(err => {
                console.log('Error', err);
                reject('getting first instance of series');
              })

            })
            .catch(err => {
              console.log('ERROR: getting instances for saving thumbnail', err);
              reject('getting instances for saving thumbnail');
            });

        })
        .catch(err => {
          console.log('ERROR: getting series for saving thumbnail', err);
          reject('getting series for saving thumbnail');
        });

    });

  }

  /**
   * link dicom images to attachment objects
   */
  linkDicomAsAttachment() {
    console.log('Linking Dicom....');

    // loop through all the selected studies
    this.dicomStudiesSelected.forEach((studySelected: boolean, i: number) => {
      if (studySelected) {
        // call function to save the attachment
        this.saveStudy(this.dicomStudies[i]);
      }
    });
  }

  /**
  * create an attachment to upload to database
  */
  saveStudy(study: Study) {

    // check if attachment already exists in the list of attachments
    this.processingStudySave = 'Checking if attachment already exists in list of attachments';
    const attachmentStudyUuid: string[] = [];
    this.attachments.forEach((attachment: Attachment) => {
      if (attachment.mediatype === 'application/dicom') { attachmentStudyUuid.push(attachment.studyUuid); }
    })

    if (attachmentStudyUuid.includes(study.StudyInstanceUID)) {
      this.dicomSearchForm.reset();
      this.processingStudySave = null;
      const message = `Dicom Study ${study.dicomTitle} already exists on this patient`;
      this.snackBar.open(`${message}`, 'Ok', { duration: 6000 });
      return;
    }

    // load thumbnail for this attachment
    this.processingStudySave = 'Loading thumbnail for attachment';
    this.loadStudyThumbnail(study.StudyInstanceUID).then(file => {

      // save attachment with preview
      this.addAttachment(study, file);

    }).catch(error => {
      this.snackBar.open('Error loading thumbnail: ' + error, 'Error', { duration: 6000 });
      this.processingStudySave = null;

      // save attachment with no preview
      this.addAttachment(study);
    });
  }

  // push the attachemnt object to pouch
  addAttachment(study, file = null) {
    // save attachments to the attachments model
    this.processingStudySave = 'Creating new thumbnail object';
    const attachment = new Attachment({
      _id: String((new Date().getTime())),
      id: ((new Date().getTime())),
      attachmentTitle: study.dicomTitle,
      patientId: +this.patient._id,
      narrativeId: +this.narrativeId,
      sharedFacility: this.patient.sharedFacility ? this.patient.sharedFacility : '',
      dateOnAttachment: study.studyDate ? moment(study.studyDate, 'YYYYMMDD').toDate() : new Date('Jan 01, 1900'),
      attachmentFileName: study.patientName,
      mediatype: 'application/dicom',
      studyUuid: study.StudyInstanceUID
    });

    // add thumbnail file to attachments
    if (file !== null) { attachment._attachments = { file }; }

    // call function to save the attachment
    this.processingStudySave = 'Emmitting new attachment to parent to be saved to pouch';
    this.saveAttachment(attachment)

    // we are done here
    this.processingStudySave = null;
  }


  /**
   * function to save attachment to the database
   * @param myAttachmentsModel the attachment to be saved to pouchdb
   * @param uploaded indicator if the dicom file to be saved was a search or uploaded
   */
  saveAttachment(myAttachmentsModel): void {
    this.attachmentService.updateAttachment(myAttachmentsModel)
      .then(attachment => {
        const message = `Dicom Study ${attachment.attachmentTitle} attached successfully`
        this.snackBar.open(`${message}`, 'Success', { duration: 6000 })
        this.attachmentAdded.emit(attachment);
      })
      .catch(err => {
        console.log('Error uploading attachment:', err);
        this.snackBar.open('ERROR: Attachment was Not uploaded', 'Error', { duration: 6000 })
      })
  }
}
