import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { User } from '../../models/user';
import { Patient } from '../../models/patient';
import { Facility } from '../../models/facility';
import { AppSettings } from '../../models/config';
import { Narrative, Consultation } from '../../models/narrative';
import { Mediator } from '../../models/mediators';
import { ReferralEnumTypes } from '../../models/narrative-types';

import { AuthenticationService } from '../../services/authentication.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'psoc-app-referral-edit',
  templateUrl: './referral-edit.component.html',
})

export class ReferralEditComponent implements OnInit {
  @Input() patient: Patient;
  @Input() narrative: Narrative;
  @Input() referralEditForm: FormGroup;
  @Input() facilities: Facility[];
  @Input() specialistList: Mediator[];
  @Input() set appSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    this.showConsultationFields = appSettings.narrativeSettings.consultationFields;
    if (appSettings) {
      this.showReferralsBlock = appSettings.narrativeSettings.openreferralblock;
    }
  } get appSettings(): AppSettings { return this.appSettings }

  _appSettings: AppSettings;
  showReferralsBlock: boolean;
  user: User;
  environment = environment;

  // facility objects
  referredFromFacility: Facility
  referredToFacility: Facility
  facility: Facility;
  filteredFacilities = new Array<Facility>();

  // options for referral radio button
  referralOptions = Object.values(ReferralEnumTypes);
  transportationOptions = ['Ambulance', 'Patient organized'];
  urgencyOptions = ['Emergency', 'Urgent', 'Routine'];

  // General Options
  levelOfConOptions = ['Alert', 'Voice', 'Pain', 'Unresponsive'];

  // Maternity Options
  hivStatusOptions = ['Positive', 'Negative', 'Unknown'];
  vdrlOptions = ['Positive', 'Negative', 'Unknown'];
  pregnancyStageOptions = ['1st Trimester', '2nd Trimester', '3rd Trimester', 'Labour', 'Delivered', 'LMP unknown'];
  detailsGOptions = ['1', '2', '3', '≥4'];
  detailsPOptions = ['0', '1', '2', '3', '≥4'];

  // blood group options
  bloodGrpOptions = ['O', 'A', 'B', 'AB'];
  rhFactorOptions = ['Positive', 'Negative', 'Unknown'];

  // paediatrics options
  seroExposedOptions = ['Yes', 'No', 'Unknown'];
  birthDetailsOptions = ['SVD', 'CS', 'Assisted'];
  birthWeightOptions = ['<1 kg', '1-1.5 kgs', '1.5-2.5 kgs', '>2.5 kgs'];
  apgar1Options = ['0-3', '4-6', '7-10'];
  apgar5Options = ['0-3', '4-6', '7-10'];
  statuAtReferralOptions = ['Alert', 'Voice', 'Pain', 'Unresponsive'];
  dehydrationPaedOptions = ['Severe', 'Some'];
  wfaPaedOptions = ['<60%', '60-80%', '>80%'];
  oedemaPaedOptions = ['Yes', 'No'];
  diarrhoeaPaedOptions = ['Bloody', 'Non-bloody'];

  showConsultationFields: boolean;

  // constructor
  constructor(
    private _fb: FormBuilder,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.filterFacilities();
    this.addFormControls();

    if (this.showConsultationFields && !this.narrative.consultation) {
      this.narrative.consultation = new Consultation();
    }
  }

  toggleReferralsBlock(showReferralsBlock: boolean): void {
    if (showReferralsBlock) {
      this.showReferralsBlock = true;
    } else {
      if (this.showReferralsBlock) {
        this.showReferralsBlock = false;
      } else {
        this.showReferralsBlock = true;
      }
    }
  }

  /** This property should be automatically appended to parent for but is not for some reason */
  dirty() {
    return this.referralEditForm.dirty;
  }

  /**
   * Filter the list of facilities based on facility selected to refer from
   */
  filterFacilities() {
    this.narrative.referral.referral_from =
      this.narrative.referral.referral_from ? this.narrative.referral.referral_from : this.user.facility;

    this.referredFromFacility = this.getFacility(this.narrative.referral.referral_from);
    this.filteredFacilities = this.facilities.filter((facility) => facility.level > this.referredFromFacility.level);
    this.referredToFacility = this.getFacility(this.narrative.referral.referral_facility);
  }

  /** validate Referral Block */
  isReferralBlockValid(): Boolean {
    if (this.narrative.referralType === ReferralEnumTypes.consultation && !this.showConsultationFields) { return true; }
    if (!this.narrative.referral.referral_from) { return false } // check if referral from is set
    if (!this.narrative.referral.referral_details_consent_share_info) { return false } // check if consent is recieved

    switch (this.narrative.referralType === ReferralEnumTypes.consultation) {
      case true:
        // return true; // valid, consultation type
        if (!this.narrative.referral.referral_specialist) {
          return false;
          break;
        }
        return true;
        break;
      case false:
        // check if referral to is set
        if (!this.narrative.referral.referral_facility) {
          return false;
          break;
        }
        // if no issues return valid
        return true;
        break;
      default:
        break;
    }
  }

  /**
   * get facility
   * @param currFacility
   */
  getFacility(currFacility: String): Facility {
    return this.facilities.filter((facility: Facility) => { return facility.code === currFacility })[0];
  }

  // add all the controlls for the referral form fields
  addFormControls() {
    // initialize referralsForm with controls for editing referral
    this.referralEditForm = this._fb.group({
      referral: ['Consultation'],
      referral_urgency: [''],
      referral_transportation: [''],

      // referral_details: [''],
      referral_details_facility_contacted: [''],
      referral_details_letter_filled: [''],
      referral_details_contacts_confirmed: [''],
      referral_details_consent_share_info: ['', Validators.required],

      // Add validators
      referral_from: ['', Validators.required],
      referral_facility: ['', Validators.required],
      referral_specialist: ['', Validators.required],

      referral_mat_hivStatus: [''],
      referral_mat_vdrl: [''],
      referral_mat_detailsG: [''],
      referral_mat_detailsP: [''],
      referral_mat_pregnancyStage: [''],
      referral_mat_bloodGrp: [''],
      referral_mat_rhFactor: [''],
      referral_mat_status: [''],

      // referral_mat_reasonsMat: [''],
      referral_mat_reasons_breech: [''],
      referral_mat_reasons_sepsis: [''],
      referral_mat_reasons_pph: [''],
      referral_mat_reasons_movement: [''],
      referral_mat_reasons_dyscosia: [''],
      referral_mat_reasons_cs: [''],
      referral_mat_reasons_prom: [''],
      referral_mat_reasons_prolonged: [''],
      referral_mat_reasons_mult_pregnancy: [''],
      referral_mat_reasons_preterm: [''],
      referral_mat_reasons_pet: [''],
      referral_mat_reasons_lfhr: [''],
      referral_mat_reasons_abortion: [''],
      referral_mat_reasons_gbv: [''],
      referral_mat_reasons_aph: [''],
      referral_mat_reasons_dm: [''],
      referral_mat_reasons_severe_anaemia: [''],
      referral_mat_reasons_diabetes: [''],
      referral_mat_reasons_hist_miscarriage: [''],
      referral_mat_reasons_others: [''],

      // referral_mat_tests: [''],
      referral_mat_bp_100mm_100min: [''],
      referral_mat_hp_7g: [''],
      referral_mat_fever_38: [''],
      referral_mat_bp_160mm_100min: [''],
      referral_mat_lbs: [''],
      referral_mat_seizures: [''],
      referral_mat_respiratory_distress: '',
      referral_mat_tests_others: [''],


      // referral_mat_management: [''],
      referral_mat_cpr: [''],
      referral_mat_magnesium: [''],
      referral_mat_oxygen: [''],
      referral_mat_iv_fluids: [''],
      referral_mat_art: [''],
      referral_mat_iv_glucose: [''],
      referral_mat_iron: [''],
      referral_mat_antibiotics: [''],
      referral_mat_currmanagment_others: [''],

      // paediatrics referral
      referral_paed_hivStatus: [''],
      referral_paed_seroExposed: [''],
      referral_paed_birthDetails: [''],
      referral_paed_birthWeight: [''],
      referral_paed_apgar1: [''],
      referral_paed_apgar5: [''],

      referral_paed_malnutritionWfa: [''],
      referral_paed_malnutritionOedema: [''],
      referral_paed_diarrhoea: [''],
      referral_paed_dehydration: [''],
      referral_paed_status: [''],

      // referral_paed_reasons: [],
      referral_paed_reasons_birth_asphyxia: [''],
      referral_paed_reasons_menengitis: [''],
      referral_paed_reasons_jaundice: [''],
      referral_paed_reasons_malaria: [''],
      referral_paed_reasons_sepsis: [''],
      referral_paed_reasons_burns: [''],
      referral_paed_reasons_other: [''],

      // referral_paed_signs: [],
      referral_paed_signs_cnatdrink: [''],
      referral_paed_signs_convulsions: [''],
      referral_paed_signs_vomiting: [''],
      referral_paed_signs_unconcious: [''],
      referral_paed_signs_stridor: [''],
      referral_paed_signs_malnutrition: [''],
      referral_paed_respiratory_distress: [''],
      referral_paed_signs_other: [''],

      // referral_paed_tests: [],
      referral_paed_test_lbs: [''],
      referral_paed_test_hb: [''],
      referral_paed_test_capill: [''],
      referral_paed_test_other: [''],

      // referral_paed_management: [],
      referral_paed_management_cpr: [''],
      referral_paed_management_oxygen: [''],
      referral_paed_management_ivfluids: [''],
      referral_paed_management_ivglucose: [''],
      referral_paed_management_other: [''],

      // general referral
      referral_gen_hivStatus: [''],
      referral_gen_levelOfConsciousness: [''],
      referral_gen_bloodGroup: [''],

      // referral_gen_reasons: string[];
      referral_gen_reasons_cardiovascular: [''],
      referral_gen_reasons_ent: [''],
      referral_gen_reasons_burns: [''],
      referral_gen_reasons_centralNervousSystem: [''],
      referral_gen_reasons_ophthalmological: [''],
      referral_gen_reasons_rta: [''],
      referral_gen_reasons_gastrointestinal: [''],
      referral_gen_reasons_respiratory: [''],
      referral_gen_reasons_stings: [''],
      referral_gen_reasons_genitourinary: [''],
      referral_gen_reasons_musculoskeletal: [''],
      referral_gen_reasons_poisoning: [''],
      referral_gen_reasons_endocrine: [''],
      referral_gen_reasons_renal: [''],
      referral_gen_reasons_cancer: [''],
      referral_gen_reasons_diseases: [''],
      referral_gen_reasons_sepsis: [''],
      referral_gen_reasons_body: [''],
      referral_gen_reasons_aids: [''],
      referral_gen_reasons_details: [''],

      // referral_gen_tests: [],
      referral_gen_test_bp_100mm: [''],
      referral_gen_test_hp: [''],
      referral_gen_test_fever: [''],
      referral_gen_test_bp_160mm: [''],
      referral_gen_test_sugar_4mmol: [''],
      referral_gen_test_seizures: [''],
      referral_gen_test_sugar_11mmol: [''],
      referral_gen_test_distress: [''],
      referral_gen_test_diarrhoea: [''],
      referral_gen_test_bleeding: [''],
      referral_gen_test_vomiting: [''],
      referral_gen_test_other: [''],

      // referral_gen_bloodGroup: string[];
      referral_gen_bloodGrp: [''],
      referral_gen_rhFactor: [''],

      // referral_gen_managment: string[];
      referral_gen_management_cpr: [''],
      referral_gen_management_antibiotics: [''],
      referral_gen_management_oxygen: [''],
      referral_gen_management_ivfluids: [''],
      referral_gen_management_art: [''],
      referral_gen_management_ivglucose: '',
      referral_gen_management_transfusion: [''],
      referral_gen_management_digoxin: [''],
      referral_gen_management_antihypertensives: [''],
      referral_gen_management_analgesics: [''],
      referral_gen_management_nebulization: [''],
      referral_gen_management_anticonvulsants: [''],
      referral_gen_management_other: [''],

      // consultation
      consultation_hivStatus: [''],
      consultation_reasons_cardiovascular: [''],
      consultation_reasons_ent: [''],
      consultation_reasons_burns: [''],
      consultation_reasons_centralNervousSystem: [''],
      consultation_reasons_ophthalmological: [''],
      consultation_reasons_rta: [''],
      consultation_reasons_gastrointestinal: [''],
      consultation_reasons_respiratory: [''],
      consultation_reasons_stings: [''],
      consultation_reasons_genitourinary: [''],
      consultation_reasons_musculoskeletal: [''],
      consultation_reasons_poisoning: [''],
      consultation_reasons_endocrine: [''],
      consultation_reasons_renal: [''],
      consultation_reasons_cancer: [''],
      consultation_reasons_diseases: [''],
      consultation_reasons_sepsis: [''],
      consultation_reasons_body: [''],
      consultation_reasons_aids: [''],
      consultation_reasons_other_details: [''],

      consultation_test_bp_100mm: [''],
      consultation_test_hp: [''],
      consultation_test_fever: [''],
      consultation_test_bp_160mm: [''],
      consultation_test_sugar_4mmol: [''],
      consultation_test_seizures: [''],
      consultation_test_sugar_11mmol: [''],
      consultation_test_distress: [''],
      consultation_test_diarrhoea: [''],
      consultation_test_bleeding: [''],
      consultation_test_vomiting: [''],
      consultation_test_other: [''],
    });

  }
}
