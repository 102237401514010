import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppMaterialModule } from '../../../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';

import { SpecialistAttachmentsComponent } from './attachments.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { AttachmentsModule } from '../../../attachments/attachments.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    FlexLayoutModule,
    MomentModule,
    PipesModule,
    AttachmentsModule
  ],
  declarations: [
    SpecialistAttachmentsComponent,
  ],
  exports: [
    SpecialistAttachmentsComponent,
  ]
})
export class SpecialistAttachmentsModule { }
