import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../material/app-material.module';
import { MomentModule } from 'ngx-moment';
import { ValidationModule } from '../validation/validation.module';

import { LoginComponent } from './login.component.web';
import { RecoverComponent } from './recover.component';
import { LocatorComponent } from './locator.component';
import { ProfileComponent } from './profile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    MomentModule,
    ValidationModule
  ],
  declarations: [
    LoginComponent,
    RecoverComponent,
    LocatorComponent,
    ProfileComponent
  ],
  exports: [
    LoginComponent,
    RecoverComponent,
    LocatorComponent,
    ProfileComponent
  ],
})

export class AuthenticationModule { }
