import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { GestationAgeByAC } from '../../../../models/narrative-types';
import { FoetalAbdomenBlock } from '../../../../models/obstetric-narrative';
import { AttachmentService } from '../../../../services/attachment.service';
import { NarrativeService } from '../../../../services/narrative.service';
import { ValidationService } from '../../../../validation/validation.service';
import { ObstetricBlockEditComponent } from '../block-edit.component';

@Component({
    selector: 'psoc-foetal-abdomen-nurse-edit',
    templateUrl: './foetal-abdomen-nurse-edit.component.html'
})

export class FoetalAbdomenNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
    _blockData: FoetalAbdomenBlock;
    @Input() get blockData() { return this._blockData }
    set blockData(blockData: FoetalAbdomenBlock) {
        if (blockData) { this._blockData = blockData } else { this._blockData = new FoetalAbdomenBlock(); }
        this.blockDataChange.emit(this._blockData);
        this.getAttachmentFilesCount(this._blockData.attachmentIds);
    };
    blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];

    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;


    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        public narrativeService: NarrativeService,
        public attachmentService: AttachmentService
    ) {
        super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

        // return the foetal abdomen form
        this.form = this.fb.group({
            blockStatus: ['', [Validators.required]],
            abdominalCircumference: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByAC))],
            nurseNotes: ['']
        });

    }

    /**
     * Check if data is valid for this block
     */
    public blockValid() {
        return (this.blockData && this.blockData.blockStatus) ? true : false;
    }

    ngOnInit(): void {
        this.updateForm();  // call function to update parent form with block form
    }
}
