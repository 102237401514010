import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'psoc-pdf-thumbnail',
    template: '<canvas #thumbCanvas id="thumbCanvas"></canvas>'
})

export class PdfThumbnailComponent  {
    @Input() set page(page: any) {
        console.log('Page sent to thumbnail viewer')
        this.makeThumb(page);
    }

    @ViewChild('thumbCanvas') thumbCanvasRef: ElementRef;
    thumbCanvas: any;

    /**
     * Create pdf thumbnails
     */
    makeThumb(page) {
        // draw page to fit into 96x96 canvas
        const vp = page.getViewport(1);
        this.thumbCanvas = this.thumbCanvasRef.nativeElement;
        this.thumbCanvas.width = this.thumbCanvas.height = 96;
        const scale = Math.min(this.thumbCanvas.width / vp.width, this.thumbCanvas.height / vp.height);
        page.render({ canvasContext: this.thumbCanvas.getContext('2d'), viewport: page.getViewport(scale) }).promise.catch((error) => {
            console.log('Error rendering thumbnail', error);
        });
    }


}
