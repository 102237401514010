import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PatientService } from '../../services/patient.service';
import { Patient } from '../../models/patient';








@Component({
  selector: 'app-records-created',
  templateUrl: 'records-created.component.html'
})
export class RecordsCreatedComponent implements OnInit {
  dateFrom;
  dateTo;

  recordsCreated = new Array<Patient>();

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) { }

  // gather the dae from and to from the url
  getDates() {
    this.dateFrom = this.route.snapshot.params['from']
    this.dateTo = this.route.snapshot.params['to']
  }

  // records created between the selected date
  getCreatedRecords() {
    // call function to get the dates
    this.getDates();

    // records created between the selected date
    this.patientService.getAllPatients()
      .then(patients => {
        patients.forEach((patient: Patient) => {

          const dateAdded = new Date(patient.dateAdded).getTime()

          if (dateAdded >= this.dateFrom && dateAdded <= this.dateTo) {
            this.recordsCreated.push(patient)
          }
        });
      })
      .catch(error => {
        console.log('Error loading patients');
      });
  }

  ngOnInit() {
    // call function to the records
    this.getCreatedRecords()
  }
}
