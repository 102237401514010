import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from './../../../../models/attachment';
import { NumberOfFoetusBlock, FoetusBlocks, ObstetricNarrative } from '../../../../models/obstetric-narrative';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { ObstetricBlockEditComponent } from '../block-edit.component';

@Component({
  selector: 'psoc-delete-foetus-dialog',
  template: `
  <h2>Confirm Delete Foetus</h2>
  <p>The last added foetus will be deleted</p>
  <p><b>Notice:</b> This action cannot be undone</p>
  <p><button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
</p>`
})

export class ConfirmDeleteFoetusDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-number-of-foetus-nurse-edit',
  templateUrl: './number-foetus-nurse-edit.component.html',
})

export class NumberOfFoetusNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
  _narrativeData: ObstetricNarrative;
  @Output() narrativeDataChange: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
  @Input() get narrativeData(): ObstetricNarrative { return this._narrativeData }
  set narrativeData(narrativeData: ObstetricNarrative) {
    if (narrativeData) { this._narrativeData = narrativeData } else { this._narrativeData = new ObstetricNarrative(); }
    if (!this._narrativeData.sonographyBlocks.numberOfFoetusBlock) {
      this._narrativeData.sonographyBlocks.numberOfFoetusBlock = new NumberOfFoetusBlock({ numberOfFoetus: 1 });
    }
    if (this._narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus < 1) {
      this._narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus = 1
    }
    if (!Array.isArray(this._narrativeData.sonographyBlocks.foetuses) || this._narrativeData.sonographyBlocks.foetuses.length === 0) {
      this._narrativeData.sonographyBlocks.foetuses = [];
      this._narrativeData.sonographyBlocks.foetuses.push(new FoetusBlocks());
    }
    this.getAttachmentFilesCount(this._narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds);
  };

  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService,
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

    this.form = this.fb.group({
      blockStatus: ['', [Validators.required]],
      numberOfFoetus: [{ value: '', disabled: true }, [Validators.required]],
      nurseNotes: ['']
    });
  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.narrativeData && this.narrativeData.sonographyBlocks.numberOfFoetusBlock.blockStatus
      && this.narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
  }

  /**
   * attachment editor has updated the attachment
   */
  attachmentUpdated(updatedAttachment: Attachment) {
    this.showEditor = false;
    this.currentAttachment = updatedAttachment;
    this.saveAttachment(updatedAttachment, this._narrativeData.sonographyBlocks.numberOfFoetusBlock)
      .then((updatedAttachmentId) => {
        // add attachment id to to list for this narrative
        if (this._narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds === undefined) {
          this._narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds = [];
        }

        if (this._narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds.indexOf(updatedAttachmentId) === -1) {
          this._narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds.push(updatedAttachmentId);
        }
        this.attachmentFilesCount[updatedAttachmentId] = Object.keys(updatedAttachment._attachments).length;
        if (this.narrativeData.imagesList.length > 0) {
          this.updateImageList(null);
        } else { this.narrativeDataChange.emit(this._narrativeData); }

      })
      .catch(error => console.log('Error saving attachment', error));
  }

  /**
   * function to add foetus to the foetus array
   */
  addFoetus() {
    if (this.narrativeData.sonographyBlocks.numberOfFoetusBlock.attachmentIds.length === 0) { return }
    this.narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus++;
    this.narrativeData.sonographyBlocks.foetuses.push(new FoetusBlocks());
  }

  /**
   * function to remove foetus to the foetus array
   */
  removeFoetus() {
    this.narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus--;
    this.narrativeData.sonographyBlocks.foetuses.pop();
  }

  /**
   * Confirm delete of foetus
   */
  confirmRemoveFoetus() {
    if (this.narrativeData.sonographyBlocks.numberOfFoetusBlock.numberOfFoetus === 1) { return }
    this.dialogRef = this.dialog.open(ConfirmDeleteFoetusDialogComponent);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.removeFoetus();
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

}
