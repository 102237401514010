import { Component, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatDialog, MatDialogRef } from '@angular/material';
import Cropper from 'cropperjs';

@Component({
  selector: 'psoc-cancel-cropper-dialog-component',
  template: `<h2>Confirm Exit Page Edit Mode</h2>
  <p>All changes made to this page will be lost.</p>
  <p><b>Notice:</b> This action cannot be undone.</p>
  <p>
    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close('discard')">Confirm</button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
  </p>`
})
export class CancelCropperDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'cropper',
  templateUrl: 'cropper.component.html',
  styleUrls: ['cropper.component.css']
})

export class CropperComponent {
  dialogRef: MatDialogRef<any>;

  @Output() cropperSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() cropperCancelled = new EventEmitter();

  imageChanged = false; // keep track of whether the image we were given has changed
  _tmpImgSrc: SafeUrl;
  set tmpImgSrc(tmpImgSrc: SafeUrl) {
    // if not setting image for first time, then we are changing it
    if (this._tmpImgSrc) { this.imageChanged = true; }
    this._tmpImgSrc = tmpImgSrc;
  }
  get tmpImgSrc(): SafeUrl { return this._tmpImgSrc }

  cropperWorking: boolean = false;
  @ViewChild('canvas') canvasRef: ElementRef;
  @ViewChild('tmpImage', { read: ElementRef }) tmpImage;

  // cropperjs
  cropperCropMode = false;
  cropperMaskMode = false;
  cropperjs;
  cropperOptions = {
    viewMode: 0,
    dragMode: <any>'move',
    autoCrop: false,
    autoCropArea: 0.8,
    restore: false,
    modal: true,
    guides: true,
    center: true,
    highlight: true,
    cropBoxMovable: true,
    cropBoxResizable: true,
    toggleDragModeOnDblclick: true,
  };

  cropperShow(imgSrcUrl: SafeUrl) {
    this.cropperWorking = true;
    this.tmpImgSrc = imgSrcUrl;
  }

  cropperMaskShow() {
    if (!this.cropperMaskMode) {
      this.cropperjs.crop();
      this.cropperMaskMode = true;
    } else {
      this.cropperjs.clear();
      this.cropperMaskMode = false;
    }
  }

  cropperMaskApply() {
    this.cropperWorking = true;
    this.cropperMaskMode = false;

    const canvas = this.canvasRef.nativeElement;
    const context: CanvasRenderingContext2D = canvas.getContext('2d');

    const maskWidth = this.cropperjs.getData().width;
    const maskHeight = this.cropperjs.getData().height;
    const maskTop = this.cropperjs.getData().y;
    const maskLeft = this.cropperjs.getData().x;

    const imageWidth = this.cropperjs.getImageData().naturalWidth;
    const imageHeight = this.cropperjs.getImageData().naturalHeight;
    const imageLeft = this.cropperjs.getImageData().left;
    const imageTop = this.cropperjs.getImageData().top;
    const imageAspect = this.cropperjs.getImageData().aspectRatio;

    canvas.width = imageWidth;
    canvas.height = imageHeight;

    context.imageSmoothingEnabled = true;
    context.drawImage(this.tmpImage.nativeElement, 0, 0, imageWidth, imageHeight);
    context.fillRect(maskLeft, maskTop, maskWidth, maskHeight);

    canvas.toBlob((blob) => {
      const imageSrcUrl = URL.createObjectURL(blob);
      this.tmpImgSrc = this.sanitizer.bypassSecurityTrustUrl(imageSrcUrl);
      this.cropperjs.destroy();
      this.ref.detectChanges();
    });
  }

  cropperMaskClear() {
    this.cropperjs.clear();
    this.cropperMaskMode = false;
  }

  cropperCropShow() {
    if (!this.cropperCropMode) {
      this.cropperjs.crop();
      this.cropperCropMode = true;
    } else {
      this.cropperjs.clear();
      this.cropperCropMode = false;
    }
  }

  cropperCropApply() {
    // replace cropper image with cropped canvas
    this.cropperWorking = true;
    this.cropperCropMode = false;
    this.cropperjs.getCroppedCanvas().toBlob((blob) => {
      const imageSrcUrl = URL.createObjectURL(blob);
      this.tmpImgSrc = this.sanitizer.bypassSecurityTrustUrl(imageSrcUrl);
      this.cropperjs.destroy();
      this.ref.detectChanges();
    });
  }

  imageLoaded() {
    if (this.cropperWorking) {
      this.cropperjs = new Cropper(this.tmpImage.nativeElement, this.cropperOptions);
      this.cropperWorking = false;
    }
  }

  cropperCropClear() {
    this.cropperjs.clear();
    this.cropperCropMode = false;
  }

  cropperZoomIn() {
    this.cropperjs.zoom(0.1);
  }

  cropperZoomOut() {
    this.cropperjs.zoom(-0.1);
  }

  cropperSave() {
    let imageSrcUrl;
    imageSrcUrl = this.cropperjs.getCroppedCanvas().toDataURL();
    this.imageChanged = true; // set imageChanged flag to true, when we save the cropper changes
    this.cropperSaved.emit(imageSrcUrl);
  }

  cropperCancel() {
    this.cropperjs.destroy();
    this.cropperCropMode = false;
    this.cropperMaskMode = false;
    this.cropperCancelled.emit();
  }

  /**
   * Display a confirmation dialog when user exits cropper
   */
  confirmCropperCancel() {
    // if nothing has changed, just cancel out
    if (!this.imageChanged) { this.cropperCancel(); return; }
    this.dialogRef = this.dialog.open(CancelCropperDialogComponent);

    // there are changes, ask user if they want to discard, save or do nothing
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'discard') {
        this.imageChanged = false; // discard cropper changes, set imageChanged flag to false
        this.cropperCancel()
      }
      this.dialogRef = null;
    })
  }

  destroyCropper() {
    this.cropperjs.destroy();
    this._tmpImgSrc = null;
  }

  cropperRotateLeft() {
    this.cropperjs.rotate(-90);
  }

  cropperRotateRight() {
    this.cropperjs.rotate(90);
  }

  constructor(
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    mdIconRegistry: MatIconRegistry,
    private dialog: MatDialog
  ) {
    // icons for image editing
    mdIconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
    mdIconRegistry.addSvgIcon('crop', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/crop.svg'));
    mdIconRegistry.addSvgIcon('done', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/done.svg'));
    mdIconRegistry.addSvgIcon('hand', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hand.svg'));
    mdIconRegistry.addSvgIcon('mask', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mask.svg'));
    mdIconRegistry.addSvgIcon('redo', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redo.svg'));
    mdIconRegistry.addSvgIcon('rotate-left', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rotate-left.svg'));
    mdIconRegistry.addSvgIcon('rotate-right', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rotate-right.svg'));
    mdIconRegistry.addSvgIcon('save', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/save.svg'));
    mdIconRegistry.addSvgIcon('undo', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/undo.svg'));
    mdIconRegistry.addSvgIcon('zoom-in', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom-in.svg'));
    mdIconRegistry.addSvgIcon('zoom-out', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom-out.svg'));
  }

}
