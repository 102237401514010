import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ElementRef, ViewContainerRef, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';

import { NarrativeService } from '../../services/narrative.service';
import { AttachmentService } from '../../services/attachment.service';
import { RepositoryService } from '../../services/repository.service';
import { PatientService } from '../../services/patient.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ReferralEditComponent } from '../../referrals/referral-edit/referral-edit.component';

import { Patient } from '../../models/patient';
import { Facility } from '../../models/facility';
import { AppSettings } from '../../models/config';
import { Mediator } from '../../models/mediators';
import { BlockSegment } from '../../models/narrative-block';
import { AttachmentModal } from '../../attachments/attachment-modal';
import { Attachment, PatientRequest } from '../../models/attachment';
import { RequestShareLinks, ShareLinks } from '../../models/sharelinks';
import { Narrative, Updates, Referrals, Question, Covid19Screening, Consultation } from '../../models/narrative';

import { environment } from '../../../environments/environment';
import moment from 'moment';

@Component({
  template: `
  <h2>Would you like to delete this block segment?</h2>
  This block segment will be deleted

  <p>  <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Ok</button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
</p>`
})

export class DeleteNarrativeDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  template: `
      <h2>WARNING: You have unsaved changes. </h2>
      <p> Press Cancel to go back and save these changes, or OK to lose these changes.</p>
      <p> Notice: This action cannot be undone. </p>

      <p>  <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Ok</button>
        <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </p>`
})
export class CancelNarrativeEditDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-narrative-edit',
  templateUrl: 'narrative-edit.component.html'
})
export class NarrativeEditComponent implements OnInit {

  env = environment;
  @Input() patient: Patient;
  @Input() appSettings: AppSettings;
  @Input() sharedLinkPatientId: string;
  @Input() narratives: Narrative[];
  @Input() set myNarrative(myNarrative: Narrative) {
    this._myNarrative = myNarrative;
    if (!this._myNarrative.covid19Screening) { this._myNarrative.covid19Screening = new Covid19Screening() }
  } get myNarrative(): Narrative { return this._myNarrative }
  @Input() attachments: Attachment[];
  @Output() viewNarrative = new EventEmitter();
  @Output() viewPatientRequests = new EventEmitter();
  @Input() sharelinks: ShareLinks[];
  @Input() facilities: Facility[];
  @Input() specialistList: Mediator[];

  _myNarrative: Narrative;
  backupNarrative: Narrative; // save backup for cancel button
  newNarrative: boolean = false; // track whether this is a new narrative we have just added

  narrativeEditForm: FormGroup;
  blocksErrors: any[]; // track which blocks have errors

  loadingBlocks: boolean = false;
  displayBlock = null; // track which block to show
  showReferral: String; // track which referral to show

  // attachments dialog
  dialogRef: MatDialogRef<any>;
  @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;
  selectedAttachment: Attachment;
  // patient request
  patientRequests: PatientRequest[] = []; // keep track of new patient requests

  @ViewChild('stepper') stepperRef: ElementRef;
  targetInput = 'case0';

  @ViewChild(ReferralEditComponent) referralEditComponent: ReferralEditComponent;

  constructor(
    private _fb: FormBuilder,
    private snackBar: MatSnackBar,
    private narrativeService: NarrativeService,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private repositoryService: RepositoryService,
    private dialog: MatDialog,
    private router: Router,
    private ref: ChangeDetectorRef,
    private patientService: PatientService
  ) {

    // initialize form with empty FormArray for narBlocks
    this.narrativeEditForm = this._fb.group({
      presumptiveDiagnosis: ['', [Validators.required, Validators.minLength(5)]],
      caseSummary: ['', [Validators.required, Validators.minLength(5)]],

      firstCtrl: ['', []],
      secondCtrl: ['', []],
      thirdCtrl: ['', []],
      fourthCtrl: ['', []],

      updates: this._fb.array([]),
      questions: this._fb.array([]),
      blocks: this._fb.array([]),
      referralForm: this._fb.group({}),
      covid19ScreeningForm: this._fb.group({})
    });

  }


  ngOnInit(): void {
    this.backupNarrative = this.narrativeService.deepCopy(this.myNarrative);
    this.setFocus();
    this.initializeNarrative();
  }

  /**
   * check if narrative is valid and return the output
   */
  isNarrativeValid() {
    return this.narrativeEditForm.valid && this.referralEditComponent.isReferralBlockValid() && this.myNarrative.questions.length > 0;
  }

  mergeSbarValues() {
    this.myNarrative.caseSummary = this.narrativeEditForm.controls['firstCtrl'].value +
      ' \n' + this.narrativeEditForm.controls['secondCtrl'].value + ' \n' +
      this.narrativeEditForm.controls['thirdCtrl'].value + ' \n' + this.narrativeEditForm.controls['fourthCtrl'].value;
    this.ref.detectChanges();
  }

  /**
  * Get the element by the id and set cursor.
  */
  // https://stackoverflow.com/questions/49011701/focus-input-after-going-to-the-next-step-in-matstepper
  private setFocus() {
    const targetElem = document.getElementById(this.targetInput);
    setTimeout(function waitTargetElem() {
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }

  onChange(event: any) {
    // this.setFocus();
    const index = String(event.selectedIndex);
    console.log('Selected Index', index)
    this.targetInput = 'case' + index;
    this.setFocus();
  }

  openAttachmentModal(blockNum: number, segmentNum: number): void {
    const config: MatDialogConfig = { viewContainerRef: this.dialogAnchor };
    this.dialogRef = this.dialog.open(AttachmentModal, config);
    let currentAttachment: Attachment = new Attachment();
    const patientRequest: PatientRequest = {
      type: 'patientRequest',
      blockId: this.myNarrative.blocks[blockNum]._id,
      segmentNum: segmentNum,
      requestDescription: this.myNarrative.blocks[blockNum].segments[segmentNum].segmentName !== null ?
        this.myNarrative.blocks[blockNum].segments[segmentNum].segmentName : '',
      areaOfRequest: this.myNarrative.blocks[blockNum].segments[segmentNum].patientRequest !== null ?
        this.myNarrative.blocks[blockNum].segments[segmentNum].patientRequest : ''
    };

    if (this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.attachmentTitle === '') {
      currentAttachment = null;
    } else {
      currentAttachment._id = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment._id;
      currentAttachment.attachmentTitle = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.attachmentTitle;
      currentAttachment.dateOnAttachment = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.dateOnAttachment;
      currentAttachment.attachmentFileName = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.attachmentFileName;
      currentAttachment.mediatype = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.mediatype;
      currentAttachment.size = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.size;
      currentAttachment.dateAdded = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.dateAdded;
      currentAttachment.studyUuid = this.myNarrative.blocks[blockNum].segments[segmentNum].attachment.studyUuid;
    }
    this.dialogRef.componentInstance.attachments = this.attachments;
    this.dialogRef.componentInstance.setServices(this.attachmentService, this.authService, this.repositoryService);
    this.dialogRef.componentInstance.setNarrativeId(this.myNarrative._id);
    this.dialogRef.componentInstance.setAttachment(currentAttachment);
    this.dialogRef.componentInstance.setPatient(this.patient);
    this.dialogRef.componentInstance.setSettings(this.appSettings);
    this.dialogRef.componentInstance.setPatientRequest(patientRequest);

    this.dialogRef.afterClosed().subscribe(result => {
      this.selectedAttachment = result;
      if (result === null) {
        this.deleteAttachment(blockNum, segmentNum);
      } else if (result === undefined) {
        // user has cancelled out, don't change anything
      } else if (result.type && result.type === 'patientRequest') {
        this.savePatientRequestToSegment(blockNum, segmentNum, result);
      } else {
        this.saveLinkToAttachment(blockNum, segmentNum, result);
      }
      this.dialogRef = null;
    });
  }

  // initialize Narrative
  initializeNarrative(): void {
    // console.log('Narrative patient ID ' + this.myNarrative.patientId);
    if (!this.myNarrative.blocks || this.myNarrative.blocks.length < 1) {
      // console.log('No narrative patient id, we are editing a new narrative object');
      this.newNarrative = true;
      this.loadingBlocks = true;
      this.narrativeService.getNarrativeBlocks()
        .then(blocksNew => {
          // this adds a segments to the blocks
          blocksNew.forEach(function (newJobItem) {
            newJobItem.segments = [];
          });

          // console.log('Adding ' + blocksNew.length + ' new blocks for patient id ' + this.patient.id);
          if (this.myNarrative._id) {
            this.myNarrative.caseSummary = '';
            this.myNarrative.presumptiveDiagnosis = '';
            this.myNarrative.blocks = blocksNew;
            this.myNarrative.referral = new Referrals();
          } else {
            const narrId: number = new Date().getTime();
            this.myNarrative = new Narrative({
              _id: String(narrId),
              id: narrId,
              patientId: this.patient.id,
              presumptiveDiagnosis: '',
              caseSummary: '',
              blocks: blocksNew,
              referralType: this.appSettings.narrativeSettings.defaultReferral,
              referral: new Referrals(),
              consultation: new Consultation()
            });

            this.addPastReports();  // check if patient has past published reports
          }

          // hide blocks loading spinner
          this.loadingBlocks = false;

        }).then(() => {
          this.refreshOrInitializeFormControls();

          // workaround for changes in promise not being loaded
          this.ref.detectChanges();
        });

    } else {
      this.refreshOrInitializeFormControls();

      // workaround for changes in promise not being loaded
      this.ref.detectChanges();
    }
  }

  // check if fields in a particular block are valid
  blockValid(i: number) {
    let fieldValid = true;
    this.narrativeEditForm.controls['blocks']['controls'][i].controls.segments.controls
      .forEach(field => {
        if (!field.valid) { fieldValid = false }
        this.blocksErrors[i] = fieldValid;
      });

    return this.blocksErrors[i];
  }

  /** function check if patient has past published reports and add them to narrative */
  addPastReports() {
    // check if patient has past reports
    this.sharelinks = this.sharelinks.filter(s => s._id.split('_')[0] !== 'request');
    this.sharelinks.filter(s => s.published).forEach((report, i) => {
      const reportNarrative = this.narratives.find(n => n._id === report.narrativeId.toString());
      // add to past medical history block as a segment
      const reportDate = moment(report.datePublished).format('ll');
      const diagnosis = reportNarrative.presumptiveDiagnosis;
      const pastMedicalBlock = this.myNarrative.blocks.find(b => b._id === '1499336017276');
      const blockSegment = {
        _id: String(new Date().getTime()),
        blockId: pastMedicalBlock._id,
        segmentName: `Past tele-consultation report from date ${reportDate} attached for provisional diagnosis "${diagnosis}"`,
        segmentAnswer: null,
        attachment: {
          attachmentTitle: 'provisional-diagnosis_' + diagnosis,
          dateOnAttachment: report.datePublished,
          attachmentFileName: report._id + '.pdf',
          patientId: report.patientId,
          mediatype: 'image/jpg',
          attachmentType: 'report',
          reportLink: this.env.appURL + 'pdfs/?s=' + report._id,
          dateAdded: report.datePublished
        }
      }
      this.myNarrative.blocks[this.myNarrative.blocks.indexOf(pastMedicalBlock)].segments.push(blockSegment);
      if (i === 0) { this.toggleBlock(this.myNarrative.blocks.indexOf(pastMedicalBlock), true); } // toggle block open
    });
  }

  refreshOrInitializeFormControls(): void {
    // set up errors block array
    this.blocksErrors = new Array<boolean>(this.myNarrative.blocks.length).fill(true);

    this.myNarrative.blocks.forEach(
      (po, blckidx) => {
        (<FormArray>this.narrativeEditForm.controls['blocks']).push(this.createNarrativeBlocks(po));
        po.segments.forEach((narBlockSegment) => {
          if ((narBlockSegment.patientRequest && narBlockSegment.patientRequest !== '') && narBlockSegment.segmentName !== '' &&
            narBlockSegment.attachment._id === undefined) {
            // disable the form
            this.narrativeEditForm.controls['blocks']['controls'][blckidx].controls.segments
              .push(this.createNarrativeBlockSegments(narBlockSegment, true));
          } else {
            this.narrativeEditForm.controls['blocks']['controls'][blckidx].controls.segments
              .push(this.createNarrativeBlockSegments(narBlockSegment));
          }
        });
      }
    );

    // fix for older narratives missing updates object
    if (!this.myNarrative.updates) {
      this.myNarrative.updates = Array<Updates>();
    }

    // fix for older narratives missing questions object
    if (!this.myNarrative.questions) {
      this.myNarrative.questions = Array<Question>();
    }

    // workaround for changes in promise not being loaded
    this.ref.detectChanges();
  }

  // show view mode
  cancelClicked(): void {
    if (this.narrativeEditForm.dirty || this.referralEditComponent.dirty() || this.patientRequests.length > 0) {
      // record has changes, confirm discard changes
      this.dialogRef = this.dialog.open(CancelNarrativeEditDialogComponent);
      this.dialogRef.afterClosed().subscribe(result => {
        // user agreed to discard changes
        if (result === true) {
          if (this.newNarrative) {
            // new narrative, go back to patient narrative list
            this.router.navigate(['/patient/' + this.myNarrative.patientId + '/narratives']);
          } else {
            // existing narrative, discard changes and view it
            this.myNarrative = new Narrative(this.backupNarrative);
            this.viewNarrative.emit(this.myNarrative);
          }
        }
        // user cancelled, do nothing
        this.dialogRef = null;
      })
    } else {
      // editing an existing record, no changes, view
      this.myNarrative = new Narrative(this.backupNarrative);
      this.viewNarrative.emit(this.myNarrative);
    }
    return;
  }

  /**
   * Toggle display of a given narrative block
   */
  toggleBlock(blockIndex: number, toggle: boolean): void {
    if (this.displayBlock === blockIndex && toggle) {
      this.displayBlock = null;
    } else {
      this.displayBlock = blockIndex;
    }
  }

  createNarrativeBlocks(narBlock): FormGroup {
    return this._fb.group({
      blockid: [narBlock.blockid, []],
      blockname: [narBlock.blockname, [Validators.required]],
      segments: this._fb.array([])
    });
  }

  createNarrativeBlockSegments(narBlockSegment, disable: boolean = false): FormGroup {
    return this._fb.group({
      segmentName: [{ value: narBlockSegment.segmentName, disabled: disable }, [Validators.required, Validators.minLength(5)]],
      segmentAnswer: [narBlockSegment.segmentAnswer],
      attachmentTitle: [narBlockSegment.attachment.attachmentTitle],
      dateOnAttachment: [narBlockSegment.attachment.dateOnAttachment],
      attachmentFileName: [narBlockSegment.attachment.attachmentFileName],
      studyUuid: [narBlockSegment.attachment.studyUuid],

      attachmentFile: ['']
    });
  }

  /**
   * Add a segment in a narrative block
   */
  addSegment(event: Event, blockIndex: number, j: number) {
    event.preventDefault(); // ensure this button doesn't try to submit the form

    this.toggleBlock(blockIndex, false);

    const att: Attachment = null;
    const a: string = null;

    const emptyNarBlockSegment: BlockSegment = {
      _id: String(new Date().getTime()),
      blockId: this.myNarrative.blocks[blockIndex]._id,
      segmentName: a,
      segmentAnswer: a,
      attachment: {
        attachmentTitle: '',
        dateOnAttachment: null,
        attachmentFileName: '',
        studyUuid: '',
        patientId: this.patient.id,
        mediatype: '',
        size: '',
        dateAdded: new Date()
      }
    };

    this.myNarrative.blocks[blockIndex].segments.splice(j + 1, 0, emptyNarBlockSegment);
    (<FormArray>this.narrativeEditForm.controls['blocks']['controls'][blockIndex].controls.segments)
      .push(this.createNarrativeBlockSegments(emptyNarBlockSegment));
  }

  /**
   * Delete a segment in a narrative block
   */
  confirmDeleteSegment(event: Event, blockIndex: number, segmentIndex: number) {
    this.dialogRef = this.dialog.open(DeleteNarrativeDialogComponent);
    event.preventDefault();
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // delete segments from both the model and the FormArray
        this.deleteAttachment(blockIndex, segmentIndex);
        this.myNarrative.blocks[blockIndex].segments.splice(segmentIndex, 1);
        this.narrativeEditForm.controls['blocks']['controls'][blockIndex].controls.segments.removeAt(segmentIndex);
        this.ref.detectChanges();
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

  onSubmitUpdate(isValid: boolean) {
    if (!isValid) {
      this.snackBar.open('Please ensure no text is empty.', 'Error', { duration: 6000 });
      return;
    }

    // process attachments to link/unlinked
    console.log('Processing ' + this.attachments.length + 'attachments to link/unlink', this.attachments);
    this.attachments.forEach((att: Attachment) => {
      if (att.file) {
        this.attachmentService.getAttachmentFile(att._id).then((result) => {
          if (result) { att.file = result };
          this.attachmentService.updateAttachment(att)
            .catch(error => {
              console.log('LinkAttachment', error);
            });
        });
      } else {
        this.attachmentService.updateAttachment(att)
          .catch(error => {
            console.log('LinkAttachment', error);
          });
      }
    });

    // the submitted form values are assigned to model but we shall use the myNarrative values
    this.narrativeService.updateNarrative(this.myNarrative)
      .then((updatedNarrative) => {
        this.myNarrative = updatedNarrative;
        // save events 'patientRequest' if there are new patientRequests
        if (this.patientRequests.length > 0) {
          let segment: BlockSegment;
          this.patientRequests.forEach(patientRequest => {
            segment = this.myNarrative.blocks.find(b => b._id === patientRequest.blockId).segments[patientRequest.segmentNum];

            // generate a shareLink
            const shareLink = new RequestShareLinks();
            shareLink.comments = `Auto_${this.myNarrative.referralType}`;
            this.patientService.saveRequestShareLink(segment, shareLink, this.myNarrative).then(savedShareLink => {
              this.snackBar.open('Request to upload data sent to patient.', 'Success', { duration: 6000 });
            });
          });
        }
        if (this.narrativeService.patientNarratives) {
          this.narrativeService.patientNarratives = this.narrativeService.patientNarratives.filter(n => n._id !== updatedNarrative._id);
          this.narrativeService.patientNarratives.push(updatedNarrative); // add narrative to list of patient narratives
        }
        this.snackBar.open('Narrative was updated.', 'Success', { duration: 6000 });
        this.viewPatientRequests.emit(this.patientRequests);
        this.viewNarrative.emit(updatedNarrative);
      })
      .catch(error => {
        console.log('Error occurred updating this narrative: ', error);
        this.snackBar.open('An error occurred while updating this narrative.', 'Error');
      });

  }

  // indexOf not working so custom function to find index of selected attachment
  findIndexByKeyValue(arraytosearch, key, valuetosearch) {
    for (let i = 0; i < arraytosearch.length; i++) {
      if (arraytosearch[i][key] === valuetosearch) {
        return i;
      }
    }
    return null;
  }

  /**
   * save the patient request to the block, segment
   */
  savePatientRequestToSegment(i: number, j: number, patientRequest: PatientRequest) {
    // assign the values returned to this segment in the narrative
    this.myNarrative.blocks[i].segments[j].segmentName = patientRequest.requestDescription;
    this.myNarrative.blocks[i].segments[j].patientRequest = patientRequest.areaOfRequest;

    // add the patient request to the patient request array
    this.patientRequests.push(patientRequest);
    this.ref.detectChanges();
  }

  // When user selects an attachment from list this is saved to model
  saveLinkToAttachment(i: number, j: number, attachSelected: Attachment): void {
    // assign the values returned to this segment in the narrative
    const attachmentIndex = this.findIndexByKeyValue(this.attachments, '_id', attachSelected._id);
    this.attachments[attachmentIndex].linked++;
    this.myNarrative.blocks[i].segments[j].attachment._id = attachSelected._id;
    this.myNarrative.blocks[i].segments[j].attachment.attachmentTitle = attachSelected.attachmentTitle;
    this.myNarrative.blocks[i].segments[j].attachment.dateOnAttachment = attachSelected.dateOnAttachment;
    this.myNarrative.blocks[i].segments[j].attachment.attachmentFileName = attachSelected.attachmentFileName;
    this.myNarrative.blocks[i].segments[j].attachment.mediatype = attachSelected.mediatype;
    this.myNarrative.blocks[i].segments[j].attachment.size = attachSelected.size;
    this.myNarrative.blocks[i].segments[j].attachment.dateAdded = new Date();
    this.myNarrative.blocks[i].segments[j].attachment.studyUuid = attachSelected.studyUuid;
    this.ref.detectChanges();
  }

  deleteAttachment(i: number, j: number) {
    this.attachments.forEach(att => { if (att._id === this.myNarrative.blocks[i].segments[j].attachment._id) { att.linked-- } });

    if (!this.myNarrative.blocks[i].segments[j].attachment._id) { console.log('No attachment to update'); return; }

    this.myNarrative.blocks[i].segments[j].attachment._id = null;
    this.myNarrative.blocks[i].segments[j].attachment.attachmentTitle = null;
    this.myNarrative.blocks[i].segments[j].attachment.dateOnAttachment = null;
    this.myNarrative.blocks[i].segments[j].attachment.attachmentFileName = null;
    this.myNarrative.blocks[i].segments[j].attachment.mediatype = null;
    this.myNarrative.blocks[i].segments[j].attachment.size = null;
    this.myNarrative.blocks[i].segments[j].attachment.dateAdded = null;
    this.myNarrative.blocks[i].segments[j].attachment.studyUuid = null;
    this.ref.detectChanges();
  }

}
