import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../material/app-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { TopNavComponent } from './top-nav.component';
import { NotFoundComponent } from './not-found.component';
import { TopNavRequestComponent } from './top-nav-request.component';
import {
  TopNavSpecialistComponent, UpdateCaseStatusHistoryDialogComponent,
  ShareVideoLinkDialogComponent
} from './top-nav-specialist.component';
import { MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    ClipboardModule
  ],
  declarations: [
    TopNavComponent,
    NotFoundComponent,
    TopNavRequestComponent,
    TopNavSpecialistComponent,
    ShareVideoLinkDialogComponent,
    UpdateCaseStatusHistoryDialogComponent
  ],
  exports: [
    TopNavComponent,
    NotFoundComponent,
    TopNavRequestComponent,
    TopNavSpecialistComponent
  ],
  entryComponents: [
    UpdateCaseStatusHistoryDialogComponent,
    ShareVideoLinkDialogComponent
  ]
})
export class NavigationModule { }
