import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar, MatSidenav, MatIconRegistry } from '@angular/material';
import { CornerstoneService } from '../../../services/cornerstone.service';
import { DicomService } from '../../../services/dicom.service';
import { CornerstoneDirective } from '../../../directives/cornerstone.directive';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './cornerstone-modal.html',
  styleUrls: ['cornerstone-modal.css'],
  providers: [DicomService]
})

export class CornerstoneModalComponent implements OnDestroy {
  imageList: Array<string>;

  @ViewChild(CornerstoneDirective) cornerstoneDirective;

  @ViewChild('snav') snav: MatSidenav;

  lengthToolEnabled: Boolean = false; // track whether measure tool is enabled

  dicomUnsupportedImage: any;

  previewInstances: Array<SafeUrl> = [];
  imageDataLoaded: boolean = false;
  imageHeaders: Array<string>;
  seriesList: Array<any> = [];
  studyUID: string;

  currentSeries: number = 0;
  loadingImages: boolean = false;

  constructor(
    private cornerstoneService: CornerstoneService,
    private dicomService: DicomService,
    private snackBar: MatSnackBar,
    matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIcon('ruler', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler.svg'));
  }

  /**
   * Clear cache on close
   */
  ngOnDestroy() {
    this.cornerstoneService.destroyCache();
  }

  /**
   * Load header information provided by the cornerstone directive reader
   * @param headerData
   */
  updateHeaders(headerData: Array<string>) {
    this.imageHeaders = headerData;
  }

  /**
   * Set the study to be displayed by cornerstone
   * @param studyUID UUID o study
   */
  public setPatientStudy(studyUID: string) {
    this.studyUID = studyUID;
    this.loadSeriesByStudy();
  }

  /**
   * Load the series for this study
   */
  loadSeriesByStudy() {
    this.loadingImages = true;
    this.dicomService.findSeriesByStudy(this.studyUID, [], [])
      .then((series: any[]) => {
        this.seriesList = series;

        // check if series list is empty
        if (this.seriesList.length === 0) {
          this.loadingImages = false;
          console.log('loadSeriesByStudy returned 0 series for this study');
          this.snackBar.open('The study you are requesting does not exist!', 'Error');
          return;
        }

        // sort series ascending order
        this.seriesList.sort((a, b) => { return a['00200011'].Value['0'] - b['00200011'].Value['0'] });

        this.loadFirstStudySeries();
        this.loadSeriesPreviews(); // load thumbnails for series list
        if (series.length > 1) { this.snav.open(); } // show nav if we have multiple series
      })
      .catch(err => {
        console.log('An error occured while loading series for this study', err);
        this.loadingImages = false;
        const snackBarRef = this.snackBar.open('Error loading series', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          this.loadSeriesByStudy();
        });
      });
  }

  /**
   * Load the preview images for all of the series that have been loaded
   */
  loadSeriesPreviews() {
    this.seriesList.forEach((series, index) => {
      const seriesUID = series['0020000E'].Value['0'];

      // load instances for series so we can take the first one
      this.dicomService.findInstancesByStudyAndSeries(this.studyUID, seriesUID, [], [])
        .then((instances: any[]) => {
          const instanceUID = instances[0]['00080018'].Value['0'];
          this.dicomService.findFirstInstanceOfSeries(this.studyUID, seriesUID, instanceUID)
            .then(blob => {
              const imageSrcUrl = URL.createObjectURL(blob);
              this.previewInstances[index] = this.sanitizer.bypassSecurityTrustUrl(imageSrcUrl);
            })
            .catch(error => {
              console.log('error loading preview:' + JSON.stringify(error), error.status);
              if (error.status === 400) {
                this.previewInstances[index] = 'assets/images/unsupported.png';
              } else {
                this.previewInstances[index] = 'assets/images/dicom-error.png';
              };
            });
        })
        .catch(err => {
          console.log('ERROR: getting instances for previews', err);
        });
    });
  }

  /**
   * Load the instances in the first series of a study
   */
  loadFirstStudySeries() {
    const seriesUID = this.seriesList['0']['0020000E'].Value['0'];
    const patientInstances: string[] = [];
    this.dicomService.findInstancesByStudyAndSeries(this.studyUID, seriesUID, [], [])
      .then((instances: any[]) => {
        // sort instances in ascending order
        instances.sort((a, b) => { return a['00200013'].Value['0'] - b['00200013'].Value['0'] });

        instances.forEach(instance => {
          // push all the series instances to the array
          patientInstances.push('/dicom/wado?requestType=WADO&studyUID=' + this.studyUID + '&seriesUID=' + seriesUID
            + '&objectUID=' + instance['00080018'].Value['0'] + '&contentType=application/dicom&transferSyntax=*');
        });

        this.loadDicomImages(patientInstances);
      })
      .catch(err => {
        console.log('An unknown error occurred while loading instances, please check your internet connection or try again', err);
        this.loadingImages = false;
      });
  }

  /**
   * Load images into cornerstone given a list of instances
   */
  loadDicomImages(instances) {
    // get the instances
    let loadedCount = 0;
    instances.forEach((imageUrl, index) => {
      this.getImageData(imageUrl, index).then(dicomImage => {
        this.cornerstoneDirective.addImage(dicomImage, index);
        this.imageDataLoaded = true;

        if (++loadedCount === instances.length) {
          this.loadingImages = false;
          this.resizeDicom();
        };
      }).catch(error => {

        console.log('loadDicomImages error', error, error.error.message);
        if (error.error && error.error.message === 'The file does not contain image data.') {
          this.snackBar.open('Unsupported dicom file', 'Error', { duration: 6000 });
          if (this.dicomUnsupportedImage) {
            this.cornerstoneDirective.addImage(this.dicomUnsupportedImage);
          } else {

            // load unsupported image
            this.cornerstoneService.fetchNormalImage('https://pacs.health-e-net.org/orthanc/app/images/unsupported.png')
              .then(normalImage => {
                this.dicomUnsupportedImage = normalImage;
                this.cornerstoneDirective.addImage(normalImage);
                this.loadingImages = false;
              }).catch(err => {
                console.log('error', err);
                this.snackBar.open('Unknown error occurred while loading the unsupported image', 'Error', { duration: 6000 });
                this.loadingImages = false;
              });

          };

        } else {
          this.snackBar.open('Unknown error occurred while loading a dicom file', 'Error', { duration: 6000 });
        }
      });
    });
  }

  /**
   * Load a list of images into cornerstone
   */
  getImageData(imageUrl: string, index: number): Promise<any> {
    return new Promise((resolve, reject) => {

      this.cornerstoneService.fetchDicomImage(imageUrl)
        .then(dicomImage => {
          resolve(dicomImage);
        }).catch(error => {
          console.log('Error fetching dicom image', error);
          reject(error);
        });

    });
  }

  /**
   * reset the cornerstone viewer size to fit its bounds
   */
  resizeDicom() {
    this.cornerstoneDirective.resize();
  }

  /**
   * loading instances of the clicked series
   */
  loadInstances(seriesIndex, series) {
    if (this.previewInstances[seriesIndex] === 'assets/images/unsupported.png') {return; }

    this.loadingImages = true;
    this.imageDataLoaded = false;
    this.imageHeaders['allImages'] = '';
    this.imageHeaders['currentImage'] = '';

    this.cornerstoneService.destroyCache();

    this.currentSeries = seriesIndex;
    const studyUID = series['0020000D'].Value['0'];
    const seriesUID = series['0020000E'].Value['0'];
    const seriesInstances = new Array<any>();

    this.cornerstoneDirective.clearDisplay();

    this.dicomService.findInstancesByStudyAndSeries(studyUID, seriesUID, [], [])
      .then((instances: any[]) => {
        instances.sort((a, b) => { return a['00200013'].Value['0'] - b['00200013'].Value['0'] });
        // sort instances in ascending order
        instances.forEach(instance => {
          seriesInstances.push('/dicom/wado?requestType=WADO&studyUID=' + studyUID + '&seriesUID='
            + seriesUID + '&objectUID=' + instance['00080018'].Value['0'] + '&contentType=application/dicom&transferSyntax=*');
        });

        // load images to the view
        this.loadDicomImages(seriesInstances);
      })
      .catch(err => {
        console.log('ERROR: loading instances', err);
        this.loadingImages = false;
        this.snackBar.open('Unknown error occurred while loading instances, please check your internet connection or try again', 'Error');
      })
  }

  /**
  * Activate/deactivate the length tool
  */
  activateLengthTool() {
    this.lengthToolEnabled = !this.lengthToolEnabled;
    this.cornerstoneDirective.activateLengthTool(this.lengthToolEnabled)
  }

  /**
   * Display previous instance
   */
  previous() {
    this.cornerstoneDirective.previousInstance()
  }

  /**
   * Display next instance
   */
  next() {
    this.cornerstoneDirective.nextInstance()
  }
}
