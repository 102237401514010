import { Component, OnInit, Input, HostListener, ViewChild, ViewContainerRef, Inject, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { Patient, CountryCodes } from '../../models/patient';
import { AppSettings } from '../../models/config';
import { AppSettingsService } from '../../services/settings.service';
import { RepositoryService } from '../../services/repository.service';
import { Globals } from '../../services/globals';
import { PatientService } from '../../services/patient.service';
import { ValidationService } from '../../validation/validation.service';
import { ComponentCanDeactivate } from '../../services/unsaved-changes.guard';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { ClientSearchComponent } from '../client-search/client-search.component';
import { InsuranceClient } from '../../models/insurance-client';
import { Enquiry } from '../../models/enquiry';
import { FormatPhone } from '../../pipes/formatPhone';

@Component({
  selector: 'psoc-patient-exist-component',
  template: `
    <h3>Patient with reg #{{patient.idnumber}} exist</h3>
    <p>Click “Cancel” to enter a different reg#, or “Go to existing patient” to see/add narratives.</p>

    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)" class="uk-align-right">
      Go to existing patient
    </button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(false)"class="uk-align-right uk-margin-right">
      Cancel
    </button>
  `
})
export class PatientExistDialogComponent {
  patient: Patient;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) { this.patient = this.data.patient }
}
@Component({
  templateUrl: 'patient-edit.component.html'
})

export class PatientEditComponent implements OnInit, ComponentCanDeactivate {

  genderOptions = [
    'Male',
    'Female'
  ];

  patientTypeOptions = [
    'Non-Paying',
    'Paying'
  ];

  countryCodes = CountryCodes;
  @Input() appSettings: AppSettings;
  loadingAppSettings: boolean = false;

  // insurance member data
  clients: InsuranceClient[] = [];
  public loadingClients: Boolean = true;

  @Input() patient: Patient;
  patients: Patient[];
  patientForm: FormGroup;
  processingSave: Boolean = false;
  newPatient: Boolean = false;
  newPatientFromEnquiry: Boolean = false;
  enquiry: Enquiry;
  disableAgeInput: boolean = false;
  ageInYears: number;
  disableDOBInput: boolean = false;
  showPatientRegNumber: boolean = false;
  env = environment;

  dialogRef: MatDialogRef<any>;
  dialogRef2: MatDialogRef<any>;
  @ViewChild('searchDialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;
  fromMatch: boolean = false;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (!this.processingSave && this.patientForm.dirty) { return false };
    return true;
  }

  constructor(
    private patientService: PatientService,
    private repository: RepositoryService,
    private appSettingsService: AppSettingsService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private globals: Globals,
    private dialog: MatDialog
  ) {
    this.loadAppSettings();

    this.patientForm = this.formBuilder.group({
      'firstname': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      'lastname': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'schemename': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'othernames': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'dateOfBirth': [null, [Validators.required, ValidationService.dateFormatValid, ValidationService.dateInPastValidator]],
      'age': [null, [Validators.minLength(1)]],
      'sex': ['', Validators.required],
      'idnumber': ['', [Validators.minLength(5), Validators.maxLength(50)]],
      'countryCode': ['254'],
      'countryCode2': ['254'],
      'phonenumber': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), ValidationService.phoneNumberValidation,
      Validators.pattern('[0-9]*')]],
      'phonenumber2': ['', [Validators.minLength(10), Validators.maxLength(10), ValidationService.phoneNumberValidation,
      Validators.pattern('[0-9]*')]],
      'emailAddress': ['', [ValidationService.emailValidator, Validators.minLength(4), Validators.maxLength(255)]],
      'postalAddress': ['', [Validators.maxLength(255)]],
      'city': ['', [Validators.required, Validators.maxLength(255)]],
      'patientType': ['Non-Paying'],
    });
    // update current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }

  ngOnInit(): void {
    if (/new/.test(this.route.toString())) {

      // create a new patient
      this.newPatient = true;
      this.patient = new Patient();
      this.patient.sex = 'Female';
      this.patient.patientType = 'Non-Paying';

    } else if (/fromenquiry/.test(this.route.toString())) {

      // create new patient from enquiry
      const enquiryId = this.route.snapshot.paramMap.get('enquiryId');
      this.patientService.getSingleEnquiry(enquiryId)
        .then((enquiry: Enquiry) => {
          // create new patient
          this.newPatient = true;
          this.newPatientFromEnquiry = true;
          this.patient = new Patient();
          this.patient.sex = 'Female';
          this.patient.patientType = 'Non-Paying';
          // copy in enquiry data
          this.patient.schemename = enquiry.schemeName;
          this.patient.firstname = enquiry.name.trim().split(' ')[0];
          this.patient.lastname = enquiry.name.trim().split(' ')[1];
          this.patient.othernames = enquiry.name.trim().slice((this.patient.firstname + ' ' + this.patient.lastname).length);
          this.patient.idnumber = enquiry.registrationNumber;
          // format phone number
          const formattedNumber = FormatPhone.formatPhoneNumber(enquiry.phonenumber);
          this.patient.phonenumber = formattedNumber.shortPhone;
          this.patient.countryCode = formattedNumber.countryCode;

          // load insurance member data
          if (this.appSettings.showInsurerClientSearch) { this.getClient(); }

          this.enquiry = enquiry;
        }).catch(error => { console.log('Error loading enquiry', error); });

    } else if (/from-match/.test(this.route.toString())) {
      this.fromMatch = true;

      // load selected patient
      const currentPatientId = +this.route.snapshot.paramMap.get('id');

      this.patientService.getSinglePatient(currentPatientId)
        .then((patient: Patient) => {
          patient.dateOfBirth = new Date(patient.dateOfBirth);
          this.patient = patient;

          // load insurance member data
          if (this.appSettings.showInsurerClientSearch) { this.getClient(); }

          if (!this.patient.countryCode) { this.patient.countryCode = '254' }
          if (!this.patient.countryCode2) { this.patient.countryCode2 = '254' }
          if (this.patient.age) { this.disableDOB(); }
          if (!this.patient.age) { this.disableAge(); }
          // update current global patient id
          this.globals.showShareLinkPatientId.emit(patient.id);
        }).catch(error => { console.log('Error loading patient', error); });
    } else {
      // load selected patient
      const currentPatientId = +this.route.snapshot.paramMap.get('id');

      this.patientService.getSinglePatient(currentPatientId)
        .then((patient: Patient) => {
          patient.dateOfBirth = new Date(patient.dateOfBirth);
          this.patient = patient;

          // load insurance member data
          if (this.appSettings.showInsurerClientSearch) { this.getClient(); }

          if (!this.patient.countryCode) { this.patient.countryCode = '254' }
          if (!this.patient.countryCode2) { this.patient.countryCode2 = '254' }
          if (this.patient.age) { this.disableDOB(); }
          if (!this.patient.age) { this.disableAge(); }
          // update current global patient id
          this.globals.showShareLinkPatientId.emit(patient.id);
        }).catch(error => { console.log('Error loading patient', error); });

    }

    // getPatientList
    this.getPatientList();
  }

  getPatientList() {
    this.patientService.getAllPatients()
      .then(patients => this.patients = patients).catch(error => console.log('Error getting patients list'))
  }

  /** calculate age */
  calculateAge(dateOfBirth: Date) {
    return Math.floor(moment(new Date()).diff(moment(dateOfBirth, 'DD/MM/YYYY'), 'years', true));
  }

  /** calculate DOB */
  calculateDOB(age: number) {
    return moment().subtract(age, 'years').toDate();
  }

  /**
  * detect when key is pressed
  * if null, enable DOB input && set errors to null
  * if not null, disable dob input and set DOB to null
  */
  keyAgeUp(e: any) {
    if (!e.target.value) {
      this.disableDOBInput = false;
      this.patientForm.controls['dateOfBirth'].enable();
      this.patient.dateOfBirth = null;
      this.disableAgeInput = (!this.disableDOBInput && this.patient.dateOfBirth !== null) ? true : false;
      this.patientForm.controls['age'].setErrors(null);
    } else {
      this.disableDOBInput = true;
      this.patient.dateOfBirth = this.calculateDOB(e.target.value);
      this.patientForm.controls['dateOfBirth'].disable();
    }
  }

  /**
   * detect when key is pressed
   * if null, enable age input && set errors to null
   * if not null, disable age input and set age to null
   */
  keyDateUp(e: any) {
    if (!e.target.value) {
      this.disableAgeInput = false;
      this.patient.dateOfBirth = null;
      this.patientForm.controls['age'].enable();
    } else {
      this.disableAgeInput = true;
      this.patient.age = null;
      this.ageInYears = this.calculateAge(e.target.value);
      this.patientForm.controls['age'].disable();
    }
  }

  /** disable age when dob is filled */
  disableAge() {
    if (this.patient.dateOfBirth) {
      this.disableAgeInput = true;
      this.ageInYears = this.calculateAge(this.patient.dateOfBirth);
      this.patientForm.controls['age'].disable();
    }
  }

  /** disable dob when age is filled */
  disableDOB() {
    if (this.patient.age) {
      this.disableDOBInput = true;
      this.patient.dateOfBirth = this.calculateDOB(this.patient.age);
      this.patientForm.controls['dateOfBirth'].disable();
    }
  }

  setPatientRegNum(e: any, show: boolean) {
    if (!e.target.value) {
      this.patient.idnumber = null;
    } else {
      if (!this.patient._id && show !== true && this.patientForm.controls['phonenumber'].valid) {
        this.patient.idnumber = this.patient.phonenumber;
        this.patientForm.controls['idnumber'].setErrors(null);
      } else if (this.patient._id && show !== true && this.patientForm.controls['phonenumber'].valid) {
        this.patientForm.controls['idnumber'].setErrors(null);
      }
    }
  }

  /** function to add / update patients demographics */
  updatePatient(): void {
    if (this.patientForm.valid) {
      if (!this.patient._id) {
        // check validity of form & existence of patient by reg #
        const patient = this.patients.find(p => (p.idnumber ? p.idnumber.trim().toLowerCase() : '') === this.patient.idnumber.trim().toLocaleLowerCase());
        if (patient) {
          // patient already exist, warn the user
          // display dialog
          this.dialogRef2 = this.dialog.open(PatientExistDialogComponent, { width: '500px', data: { patient: patient } });
          this.dialogRef2.afterClosed().subscribe(result => {
            if (result) {
              this.patientForm.reset();
              this.router.navigate(['/patient/' + patient._id + '/narratives']);
            } else {
              console.log('Continue editing');
            }
          });
        } else {
          // new patient, add id
          const timestamp = new Date().getTime();
          this.patient._id = String(timestamp);
          this.patient.id = timestamp;
          this.patient.type = Patient.type;
          if (!this.appSettings.patientFields.lastname.show) {
            this.patient.lastname = null;
          }
          this.savePatient();
        }
      } else {
        this.savePatient();
      }
    } else {
      this.snackBar.open('Please correct the form errors above', 'Error', { duration: 6000 })
    }
  }

  /**
   * Function if user already exist in database before saving
   */
  savePatient() {
    // add / update patient to the database
    this.patientService.updatePatient(this.patient)
      .then(() => {
        console.log('Patient updated id:', this.patient.id);
        this.processingSave = true;

        // new patient from enquiry, update enquiry with patient id
        if (this.newPatientFromEnquiry) {
          this.enquiry.patientId = this.patient.id;
          this.patientService.updateEnquiry(this.enquiry)
            .then(() => {
              console.log('Enquiry updated id:', this.enquiry._id);
            }).catch(error => {
              console.log('Error:', error);
              this.snackBar.open('Error updating the enquiry record, please try again', 'Error', { duration: 6000 });
            });
        }

        if (!this.newPatient) { this.goBack(); return }
        this.router.navigate(['/patient/' + this.patient.id + '/narratives']);
      })
      .catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error updating the patient record data, try again', 'Error', { duration: 6000 });
      });
  }

  /** display insurance client records to search for current record */
  openClientSearchModal() {
    if (this.clients.length < 1) { this.getClient(); }
    const config: MatDialogConfig = { width: '95vw', height: '95vh', viewContainerRef: this.dialogAnchor };
    this.dialogRef = this.dialog.open(ClientSearchComponent, config);
    this.dialogRef.componentInstance.setApp(this.repository.getApp());
    this.dialogRef.componentInstance.setPatient(this.patient);
    this.dialogRef.componentInstance.setEnquiry(this.enquiry);
    this.dialogRef.componentInstance.setClients(this.clients);
    this.dialogRef.componentInstance.setLoadingClients(this.loadingClients);
    this.dialogRef.componentInstance.setFromMatch(this.fromMatch, this.appSettings);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Search Modal closed - RESULT:', result.registrationNumber);
        if (result.action === 'navigate') {
          this.narrativeList(result.oldestPatient);
        } else {
          this.mergeClientData(result);
          this.cd.detectChanges();
        }
      }
    });
  }

  /** get client from list of clients */
  getClient(): void {
    this.loadingClients = true;
    this.patientService.getFilteredClients(this.patient.firstname, this.patient.lastname, this.patient.othernames, this.patient.idnumber,
      this.patient.schemename, this.appSettings.narrativeSettings.eS_API_KEY).then((records: Array<InsuranceClient>) => {
        this.loadingClients = false;
        this.clients = records;

        if (this.dialogRef) {
          this.dialogRef.componentInstance.setClients(this.clients);
          this.dialogRef.componentInstance.setLoadingClients(this.loadingClients);
        }
      }).catch(error => {
        console.log('Error loading list of clients: ', error)
        const snackBarRef = this.snackBar.open('Error loading list of clients, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getClient()
        });
      });
  }

  /** copy data from client records into current patient */
  mergeClientData(result: any) {
    console.log('Merge Insurance Client:', result.registrationNumber);
    // merge data
    if (result.type === Patient.type) {
      this.newPatientFromEnquiry = false;
      const existingPatient = <Patient>result;

      // select existing patient from enquiry, update enquiry with patient id
      this.enquiry.patientId = existingPatient.id;
      this.patientService.updateEnquiry(this.enquiry)
        .then(() => {
          console.log('Enquiry updated id:', this.enquiry._id);
          // check if we need to copy phone (enquiry phone not in patient numbers and patient no phone2)
          const hasSamePhoneNumber = FormatPhone.formatPhoneNumber(existingPatient.phonenumber, existingPatient.countryCode).longPhone
            === FormatPhone.formatPhoneNumber(this.enquiry.phonenumber).longPhone
            || FormatPhone.formatPhoneNumber(existingPatient.phonenumber2, existingPatient.countryCode2).longPhone
            === FormatPhone.formatPhoneNumber(this.enquiry.phonenumber).longPhone;

          if (!hasSamePhoneNumber && !existingPatient.phonenumber2) {

            // load patient and copy phone
            this.patientService.getSinglePatient(existingPatient.id).then(patient => {
              const formattedPhone = FormatPhone.formatPhoneNumber(this.enquiry.phonenumber);
              patient.phonenumber2 = formattedPhone.phoneNumber;
              patient.countryCode2 = formattedPhone.countryCode;

              // update patient
              this.patientService.updatePatient(patient)
                .then(updatedPatient => {
                  // navigate to updated patient
                  this.router.navigate(['/patient/' + updatedPatient.id + '/narratives']);
                }).catch(err => {
                  console.log('Error updating patient: ' + err);
                  this.snackBar.open('Error updating patient, please try again:' + err, 'Error', { duration: 300 });
                });

            }).catch(error => {
              console.log('Error loading patient to update', error);
              this.snackBar.open('Error updating the patient record, please try again', 'Error', { duration: 6000 });
            });

          } else {
            // don't need to copy phone, just navigate
            this.router.navigate(['/patient/' + existingPatient.id + '/narratives']);
          }

        }).catch(error => {
          console.log('Error:', error);
          this.snackBar.open('Error updating the enquiry record, please try again', 'Error', { duration: 6000 });
        });

    } else if (result.type === InsuranceClient.type) {
      const client = <InsuranceClient>result;
      this.patient.firstname = client.firstName;
      this.patient.lastname = client.lastName;
      this.patient.othernames = client.otherNames;
      this.patient.idnumber = client.registrationNumber;
      this.patient.schemename = client.schemeName;
      this.patient.sex = client.sex;
    }

    if (result.dateOfBirth) {
      this.disableAgeInput = true;
      this.patient.age = null;
      this.patientForm.controls['age'].disable();
      this.disableDOBInput = false;
      this.patientForm.controls['dateOfBirth'].enable();
      this.disableAgeInput = true;
      this.patientForm.controls['age'].setErrors(null);
      this.patient.dateOfBirth = moment(result.dateOfBirth, 'DD/MM/YYYY').toDate();
      this.ageInYears = this.calculateAge(this.patient.dateOfBirth);
    }
  }

  /**
   * navigate to the selected patients narrative list
   */
  narrativeList(patient: Patient): void {
    // return to patients narrative list
    this.router.navigate(['/patient/' + patient.id + '/narratives']);
  }
  /**
   * function to take the user back from previous route
   */
  goBack(): void {
    this.location.back();
  }

  loadAppSettings(appSettingsId: string = this.env.settingsId) {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(appSettingsId)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadingAppSettings = false;
        this.showPatientRegNumber = this.appSettings.patientFields.idnumber.show;
      })
      .catch(() => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings');
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /** Format the phone number when user has left the input field */
  formatNumber(number: number) {
    let formattedNumber;
    switch (number) {
      case 2:
        formattedNumber = FormatPhone.formatPhoneNumber(this.patient.phonenumber2, this.patient.countryCode2);
        this.patient.phonenumber2 = formattedNumber.shortPhone
        this.patient.countryCode2 = formattedNumber.countryCode;
        break;
      default:
        formattedNumber = FormatPhone.formatPhoneNumber(this.patient.phonenumber, this.patient.countryCode);
        this.patient.phonenumber = formattedNumber.shortPhone;
        this.patient.countryCode = formattedNumber.countryCode;
        break;
    }
  }
}
