import { Directive, ElementRef, HostListener, EventEmitter, OnInit, Output } from '@angular/core';

import * as Hammer from 'hammerjs';
import * as dicomParser from 'dicom-parser';
import * as cornerstone from 'cornerstone-core/dist/cornerstone.js';
import * as cornerstoneMath from 'cornerstone-math/dist/cornerstoneMath.js';
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.min.js';

cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();

@Directive({
  selector: '[psocCornerstoneDirective]',
})

export class CornerstoneDirective implements OnInit {

  element: any;
  currentIndex = 0;

  imageList = [];
  imageListId = [];
  headers: Array<string> = [];

  @Output() headersUpdated: EventEmitter<Array<String>> = new EventEmitter();

  constructor(public elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit() {
    // Retrieve the DOM element itself
    this.element = this.elementRef.nativeElement;

    // Enable the element with Cornerstone
    cornerstone.enable(this.element);
  }

  /**
   * Add Dicom Image
   */
  addImage(dicomImage: any, index: number) {
    if (dicomImage) {
      if (!this.imageList.filter(img => img.imageId === dicomImage.imageId).length) {
        this.imageList[index] = dicomImage;

        // this.imageList.sort((a, b) => { return a - b });
        this.imageListId[index] = dicomImage.imageId;
      }

      this.currentIndex = index;

      if (dicomImage.imageId) {
        this.displayImage(this.element, dicomImage, index);
      }
    }

  }

  /**
   * Clear list of images
   */
  clearDisplay() {
    this.imageList = [];
    this.imageListId = [];
  }

  /**
    * Activate the lengthtool on and deactivate zoom tool or vice versa
    */
  activateLengthTool(toggle) {
    if (toggle) {
      console.log('activating length tool...');
      // deactivate zoom and activate length
      cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 });

      this.deactivateTools(true);
    } else {
      console.log('deactivating length tool...');
      // activate zoom and deactivate length
      cornerstoneTools.setToolDisabled('Length', { mouseButtonMask: 1 });
      this.deactivateTools(false);
    }
  }

  /**
    * Activate the ellipticaltool and deactivate zoom tools or vice versa
    */
  activateEllipticalTool(toggle) {
    if (toggle) {
      console.log('activating elliptical tool...');
      // deactivate zoom and activate elliptical
      cornerstoneTools.setToolActive('EllipticalRoi', { mouseButtonMask: 1 });

      this.deactivateTools(true);
    } else {
      console.log('deactivating elliptical tool...');
      cornerstoneTools.setToolDisabled('EllipticalRoi', { mouseButtonMask: 1 });
      this.deactivateTools(false);
    }
  }

  deactivateTools(deactivate: Boolean) {
    if (deactivate) {
      // deactivate zoom and scroll
      cornerstoneTools.setToolDisabled('Zoom', { mouseButtonMask: 1 });
      cornerstoneTools.setToolDisabled('StackScrollMultiTouch', { mouseButtonMask: 1 });
    } else {
      // activate zoom and scroll
      cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
      cornerstoneTools.setToolActive('StackScrollMultiTouch', { mouseButtonMask: 1 });
    }
  }

  /**
   * Listen for window resize event and resize cornerstone viewer
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  /**
   * Resize the cornerstone canvas
   */
  resize() {
    cornerstone.resize(this.element, true);
  }

  /**
   * Listen for and handle user scrolling mousewheel
   */
  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event) {
    event.preventDefault();

    const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));

    if (delta > 0) {
      this.nextInstance();
    } else {
      this.previousInstance()
    }
  }

  /**
   * Listen for keyboard left and right keys to scroll through the instances
   */
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    const x = event.keyCode;
    if (x === 39) {
      // call function to next instance
      this.nextInstance();
    } else if (x === 37) {
      // call function to previous instance
      this.previousInstance();
    }
  }

  /**
   * Display next instance in list
   */
  nextInstance() {
    let nextIndex = this.currentIndex + 1;

    if (nextIndex > this.imageList.length) {
      nextIndex = this.imageList.length - 1;
    }

    // disable if the next image has not loaded
    if (!this.imageList[nextIndex]) { return };

    // set the image to the current scroll index
    this.currentIndex = nextIndex;
    this.displayImage(this.element, this.imageList[this.currentIndex], this.currentIndex);
  }

  /**
   * Display previous instance in list
   */
  previousInstance() {
    let prevIndex = this.currentIndex - 1;

    if (prevIndex < 0) {
      prevIndex = 0;
    }

    // disable if the previous image has not loaded
    if (!this.imageList[prevIndex]) { return };

    this.currentIndex = prevIndex;
    this.displayImage(this.element, this.imageList[this.currentIndex], this.currentIndex);
  }

  /**
   * Display image using cornerstone
   */
  displayImage(element, image, currentIndex) {
    // set the image to the current scroll index
    this.headers['currentImage'] = currentIndex;

    const stack = {
      currentImageIdIndex: currentIndex,
      imageIds: this.imageListId
    };

    // get metadata using DicomParser
    this.getImageHeaders(image);
    cornerstone.displayImage(element, image);

    // Set the stack as tool state
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', stack);

    // cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool, { configuration: { loop: true } });
    // cornerstoneTools.setToolActive('StackScrollMouseWheel', { configuration: { loop: true } });
    cornerstoneTools.addTool(cornerstoneTools.StackScrollMultiTouchTool);
    cornerstoneTools.setToolActive('StackScrollMultiTouch', { mouseButtonMask: 1 });

    // mouse
    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomTool, {
      // Optional configuration
      configuration: {
        invert: false,
        preventZoomOutsideImage: false,
        minScale: .1,
        maxScale: 20.0,
      }
    });
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 2 });
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });

    // measurements tools
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.EllipticalRoiTool);

    // touch / gesture
    cornerstoneTools.addTool(cornerstoneTools.ZoomTouchPinchTool); // - Pinch
    cornerstoneTools.addTool(cornerstoneTools.PanMultiTouchTool); // - - Multi (x2)
    cornerstoneTools.setToolActive('ZoomTouchPinch', { mouseButtonMask: 1 });
    cornerstoneTools.setToolActive('PanMultiTouch', { mouseButtonMask: 2 });

    // measurements tools
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.EllipticalRoiTool);
  }

  /**
   * Parse dicom data to extract header information
   * @param image
   */
  getImageHeaders(image) {
    try {
      // Parse the byte array to get a DataSet object that has the parsed contents
      const dataSet = dicomParser.parseDicom(image.data.byteArray/*, options */);
      this.headers['allImages'] = this.imageList.length;
      this.headers['currentImage'] = this.currentIndex;
      this.headers['currentInstance'] = dataSet.string('x00200013');
      this.headers['currentSeries'] = dataSet.string('x00200011');
      this.headers['patientName'] = dataSet.string('x00100010');
      this.headers['patientAge'] = dataSet.string('x00101010');
      this.headers['patientSex'] = dataSet.string('x00100040')
      this.headers['studyName'] = dataSet.string('x00081030');
      this.headers['studyDate'] = dataSet.string('x00080020');
      this.headers['seriesDescription'] = dataSet.string('x0008103e');
      this.headers['studyInstanceUid'] = dataSet.string('x0020000d');
      this.headers['bodyPartExamined'] = dataSet.string('x00180015');
      this.headers['institution​Name'] = dataSet.string('x00080080');
      this.headers['physicianName'] = dataSet.string('x00080090');

      this.headersUpdated.emit(this.headers);

    } catch (ex) {
      console.log('Error parsing byte stream', ex);
    }
  }
}
