import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class Study {
  studyDate: Date;
  modality: String;
  patientID: string;
  patientSex: String;
  dicomTitle: string;
  patientName: String;
  patientBirthDate: String;
  StudyInstanceUID: string;
  procedureDescription: String;
}

@Injectable()
export class DicomService {

  private apiUrl = environment.pacs_servers[0].apiUrl;
  dicomPatients = new Array<Study>();
  BOUNDARY = 'MESSAGEBOUNDARY';

  constructor(private httpClient: HttpClient) { }

  /*
   * store dicom files to orthanc server via the dicom-web api
  */
  storeStudies(image): Promise<any> {

    return new Promise((resolve, reject) => {

      // create a multipart body ruturns a unit8Array
      const body = this.constructMultipart(image, 'application/dicom');

      // set the dicom headers
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'multipart/related; type=application/dicom; boundary=' + this.BOUNDARY
      };

      // convert unit8Array back to buffer
      const convertedBody = body.buffer;

      // post thee dicom data to orthanc server
      this.httpClient.post(this.apiUrl + 'studies', convertedBody, { headers: headers, responseType: 'json' }).subscribe((res) => {
        // success saved to server
        const count = res['00081199'].Value.length;
        console.log('Upload was a success! ' + count + ' instance was uploaded.');

        // get the uploaded series url
        let url = res['00081199'].Value['0']['00081190'].Value['0'];

        // replace the http with https in the url
        url = url.replace(/^http:\/\//i, 'https://');

        // get metadata
        this.getMetaDataOfDicom(url)
          .then(instance => {
            const dicomPatient = new Study;

            // set the required fields for dicom
            dicomPatient.patientID = instance['00100020'].Value['0'];
            dicomPatient.studyDate = instance['00080020'].Value['0'];
            dicomPatient.patientName = instance['00100010'].Value['0'];
            dicomPatient.patientBirthDate = instance['00100030'].Value['0'];
            dicomPatient.StudyInstanceUID = instance['0020000D'].Value['0'];
            dicomPatient.modality = instance['00080060'].Value['0'];
            dicomPatient.procedureDescription = instance['00081030'].Value['0'];
            dicomPatient.dicomTitle = instance['00081030'].Value['0'] + ' ' + instance['00080060'].Value['0'];

            resolve(dicomPatient)
          })
      }, (err: HttpErrorResponse) => {
        reject(err)
      });
    });

  }

  stringToArrayBuffer(str) {
    // http://updates.html5rocks.com/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
    const bufView = new Uint8Array(str.length);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return bufView;
  }

  constructMultipart(body, contentType) {
    const headerStr = '--' + this.BOUNDARY + '\r\nContent-Type: ' + contentType + '\r\n\r\n';
    const trailerStr = '\r\n--' + this.BOUNDARY + '--\r\n';

    const header = this.stringToArrayBuffer(headerStr);
    const trailer = this.stringToArrayBuffer(trailerStr);

    // Concatenate the header, the body and the trailer
    // http://stackoverflow.com/a/14071518/881731
    const b = new Uint8Array(header.byteLength + body.byteLength + trailer.byteLength);
    b.set(header);
    b.set(new Uint8Array(body), header.byteLength);
    b.set(trailer, header.byteLength + body.byteLength);

    // returns a Unit8Array
    return b;
  }

  /*
   * find studies using the dicom-web apies
   * currently searching using dicom id or patient id
  */
  findStudies(dicomTag: string, searchValue: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      // return $this->doQidoRequest('studies', $attributes, $includes);
      this.dicomPatients = new Array<Study>();

      this.httpClient.get(this.apiUrl + 'studies?' + dicomTag + '=' + searchValue).subscribe((data: any[]) => {
        // check if array is empty
        if (data.length === 0) {
          console.log('Dicom Search returned empty data');
          resolve(data);
        }

        (<Array<any>>data).forEach(study => {
          const dicomPatient = new Study;

          // set the required fields for dicom
          dicomPatient.patientID = study['00100020'].Value['0'];
          dicomPatient.studyDate = study['00080020'].Value['0'];
          dicomPatient.patientName = study['00100010'].Value['0'];
          dicomPatient.patientBirthDate = study['00100030'].Value['0'];
          dicomPatient.StudyInstanceUID = study['0020000D'].Value['0'];
          dicomPatient.modality = study['00080061'].Value['0'];

          // get the series of this study
          this.findSeriesByStudy(study['0020000D'].Value['0'], [], [])
            .then((studySeries) => {
              const series = studySeries[0];
              //  get the series metadata
              this.getMetaDataOfDicom(this.apiUrl + 'studies/' + study['0020000D'].Value['0'] + '/series/' + series['0020000E'].Value['0'])
                .then((seriesMetaData) => {
                  dicomPatient.procedureDescription = seriesMetaData['00081030'].Value['0'];
                  dicomPatient.dicomTitle = seriesMetaData['00081030'].Value['0'] + ' ' + seriesMetaData['00080060'].Value['0'];

                  // push the dicom object to the array
                  this.dicomPatients.push(dicomPatient);

                  resolve(this.dicomPatients);
                }).catch(err => {
                  console.log('error:', err);
                })
            })
            .catch(err => {
              console.log('error:', err);
            })
        });
      }, (err: HttpErrorResponse) => {
        reject(err);
      });
    });
  }

  findSeriesByStudy(studyUID: string, attributes: Array<string>, includes: Array<string>): Promise<any[]> {
    // return $this->doQidoRequest(sprintf('studies/%s/series', $studyUID), $attributes, $includes);
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiUrl + 'studies/' + studyUID + '/series').subscribe((data: any[]) => {
        resolve(data)
      }, (err: HttpErrorResponse) => {
        console.log('ERROR:', err);
        reject(err)
      })
    });
  }

  findInstancesByStudyAndSeries(studyUID: string, seriesUID: string, attributes: Array<string>, includes: Array<string>): Promise<any[]> {
    // return $this->doQidoRequest(sprintf('studies/%s/series/%s/instances', $studyUID, $seriesUID), $attributes, $includes);
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiUrl + 'studies/' + studyUID + '/series/' + seriesUID + '/instances').subscribe((instances: any[]) => {
        resolve(instances);
      }, (err: HttpErrorResponse) => {
        console.log('ERROR:', err);
        reject(err)
      })
    });
  }

  /*
  * get a single instance metadata via dicom-web api
  * provide a path url
  */
  getMetaDataOfDicom(urlPath): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const headers = { 'Accept': '*/*', }

      // request the metadata
      this.httpClient.get(urlPath + '/metadata', { headers: headers })
        .subscribe((obj: any[]) => {
          // loop the array to get a single instance
          obj.forEach(dicomObj => {
            resolve(dicomObj)
          })
        }, (err: HttpErrorResponse) => {
          reject(err)
        })
    })
  }

  /**
   * Return a blob of the first frame of a given study => series => instance
   *
   * @returns Promise<any> containing blob of jpeg file for first instance of series
   */
  findFirstInstanceOfSeries(studyUID: string, seriesUID: string, instanceUID: string): Promise<any> {
    return new Promise((resolve, reject) => {

      this.httpClient.get(
        '/dicom/wado?requestType=WADO&studyUID=' + studyUID
        + '&seriesUID=' + seriesUID
        + '&objectUID=' + instanceUID
        + '&contentType=image/jpeg&transferSyntax=1.2.840.10008.1.2.4.50',
        { responseType: 'blob' })
        .subscribe((image: any) => {
          resolve(image);
        }, (err: HttpErrorResponse) => {
          console.log('Error loading required image for findFirstInstanceOfSeries:', err.status);
          reject(err);
        })

    })
  }

}
