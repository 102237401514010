import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    components: any = [];

    /** register components */
    registerComponent(component: ComponentCanDeactivate) {
        this.components.push(component);
    }

    /** deregister a component */
    deregisterComponent(component: ComponentCanDeactivate) {
        const index: number = this.components.indexOf(component);
        this.components.splice(index, 1);
    }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        return this.components
            .map(comp => { return comp.canDeactivate(); })
            .reduce((acc, val) => { return acc && val }, true) && component.canDeactivate() ?
            true :
            // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
            // when navigating away from your angular app, the browser will show a generic warning message
            // see http://stackoverflow.com/a/42207299/7307355
            confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    }
}
