import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSort, MatPaginator } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { Patient } from '../../models/patient';
import { Facility } from '../../models/facility';
import { AppSettings } from '../../models/config';
import { Narrative, ReferralStatus } from '../../models/narrative';
import { ShareLinks } from '../../models/sharelinks';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import {
  NarrativeInterface, NarrativeEnumTypes, NarrativeStatusFilterOptions, NarrativeUrgencyFilterOptions
} from '../../models/narrative-types';

import { PatientService } from '../../services/patient.service';
import { FacilityService } from '../../services/facility.service';
import { NarrativeService } from '../../services/narrative.service';
import { AppSettingsService } from '../../services/settings.service';
import { RepositoryService } from '../../services/repository.service';

import { GetFacilityName } from '../../pipes/getFacilityNamePipe';
import { GetNarrativeStatus } from '../../pipes/getNarrativeStatusPipe';
import { GetPatientName, GetPatientReg } from '../../pipes/getPatientDetailsPipe';
import { GetNarrativeUrgency } from '../../pipes/getNarrativeUrgencyPipe';

import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'psoc-narrative-list',
  templateUrl: 'narrative-list.component.html',
  providers: [GetPatientName, GetPatientReg, GetNarrativeStatus, GetFacilityName, GetNarrativeUrgency]
})

export class NarrativeListComponent implements OnInit {
  displayedColumns = ['name', 'regnumber', 'narrtype', 'status', 'urgency', 'scanfacility', 'scanby', 'date'];

  narratives: Array<NarrativeInterface>;
  sharelinks: ShareLinks[];
  mediatorsNames: string[];
  facilityNames: string[];
  facilities: Facility[];
  patients: Patient[];

  narrativeTypes = NarrativeEnumTypes;
  narrativeStatus = this.repository.enumSelector(NarrativeStatusFilterOptions);
  narrativeTypesArr = [
    { title: 'Obstetric USG', value: 'Obstetric' },
    { title: 'Consultation/Referral', value: 'Consultation' }
  ];
  urgencyOptionsArr = this.repository.enumSelector(NarrativeUrgencyFilterOptions);

  loadingNarratives: boolean;
  loadingSharelinks: boolean;
  loadingFacilities: boolean;
  loadingMediators: boolean;
  loadingPatients: boolean;

  caseUrgency: string = '';
  statusFilter: string;
  urgencyFilter: string;
  narrTypeFilter: string;
  nameFilter: string;
  regFilter: string;

  narrativeDatabase: NarrativeDatabase = new NarrativeDatabase();
  dataSource: NarrativeDataSource | null;

  userControl = new FormControl();
  facilityControl = new FormControl();
  filteredMediators: Observable<string[]>;
  filteredFacilities: Observable<string[]>;

  env = environment;
  loadingAppSettings: boolean;
  appSettings: AppSettings;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filterByName') filterByName;
  @ViewChild('filterByReg') filterByReg;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private getPatientReg: GetPatientReg,
    public repository: RepositoryService,
    private getPatientName: GetPatientName,
    private patientService: PatientService,
    private facilityService: FacilityService,
    private getFacilityName: GetFacilityName,
    private narrativeService: NarrativeService,
    private appSettingsService: AppSettingsService,
    private getNarrativesStatus: GetNarrativeStatus,
    private getNarrativeUrgency: GetNarrativeUrgency
  ) { }

  ngOnInit(): void {
    this.getAppSettings();  // load the app settings

    this.getAllShareLinks();  // get sharelinks
    this.getAllFacilities(); // get facilities
    this.getAllMediators();  // get mediators

    this.dataSource = new NarrativeDataSource(this.narrativeDatabase, this.paginator, this.sort);
    this.cd.detectChanges();
  }

  /**
  * Load app settings
  */
  getAppSettings(): void {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        if (this.appSettings.narrativeSettings.helpdesk) { this.displayedColumns.push('timeago') } // for help desk list add time ago column
        this.loadingAppSettings = false;
        this.getStatusByHelpdeskEnabled(); // get narrative status based on helpdesk enabled or disabled
      }).catch(error => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /** function get all narratives from the database */
  getAllNarratives() {
    this.loadingNarratives = true;
    this.narrativeService.getNarratives(false)
      .then(narratives => {
        if (!this.sharelinks || !this.patients || !this.facilities) {
          const snackBarRef = this.snackBar.open('Unable to load the all data', 'Retry', { duration: 6000 });
          snackBarRef.onAction().subscribe(() => {
            console.log('Retrying to get narratives');
            this.getAllNarratives();
          });
        }

        // loaded all narratives
        this.loadingNarratives = false;
        this.narratives = narratives.map((narr: NarrativeInterface) => {
          if (narr.narrativeType === NarrativeEnumTypes.obstetric) {
            narr = new ObstetricNarrative(narr);
            narr = new NarrativeWithDetails(narr);

            // apply pipes
            narr.patientReg = this.getPatientReg.transform(narr.patientId, this.patients);
            narr.patientName = this.getPatientName.transform(narr.patientId, this.patients);
            narr.narrativeStatus = this.getNarrativesStatus.transform(narr, this.sharelinks);
            narr.scanFacilityName = narr.otherScanFacility ? narr.otherScanFacility :
              this.getFacilityName.transform(narr.scanFacility, this.facilities);
            narr.caseUrgency = this.getNarrativeUrgency.transform(narr, this.sharelinks);
          } else {
            narr = new Narrative(narr);
            narr = new NarrativeWithDetails(narr);

            // apply pipes
            narr.patientReg = this.getPatientReg.transform(narr.patientId, this.patients);
            narr.patientName = this.getPatientName.transform(narr.patientId, this.patients);
            narr.narrativeStatus = this.getNarrativesStatus.transform(narr, this.sharelinks);
            narr.scanFacilityName = this.getFacilityName.transform(narr.createFacility, this.facilities);
            narr.caseUrgency = this.getNarrativeUrgency.transform(narr, this.sharelinks);
          }

          return narr;
        });

        this.narrativeDatabase.data = this.narratives;
      })
      .catch(error => {
        // error fetching narratives
        console.log(error);
        this.loadingNarratives = false;
        this.snackBar.open('Error loading narratives.', 'Error', { duration: 6000 });
      });
  }

  /**
   *  function to set up status based on helpdesk enabled or disabled
   */
  getStatusByHelpdeskEnabled() {
    if (this.appSettings.narrativeSettings.applyMultipleNarrativeFilter) {
      this.narrativeStatus = this.repository.enumSelector(NarrativeStatusFilterOptions)
        .filter(({ value }) =>
          value !== NarrativeStatusFilterOptions.shared &&
          value !== NarrativeStatusFilterOptions.sharedUrgent &&
          value !== NarrativeStatusFilterOptions.reported)
        .concat(this.repository.enumSelector(ReferralStatus).filter(({ value }) =>
          value !== ReferralStatus.admitted &&
          value !== ReferralStatus.dischargedFromOP &&
          value !== ReferralStatus.dischargedWithDS &&
          value !== ReferralStatus.dischargedWithoutDS));
    }
  }

  /** function get all sharelinks from the database */
  getAllShareLinks() {
    this.loadingSharelinks = true;
    this.patientService.getAllShareLinks()
      .then(sharelinks => {
        // loaded all sharelinks
        this.loadingSharelinks = false;
        this.sharelinks = sharelinks.filter(s => !this.patientService.isRequestLink(s._id));
      })
      .catch(error => {
        // error fetching sharelinks
        console.log(error);
        this.loadingSharelinks = false;
        this.snackBar.open('Error loading sharelinks.', 'Error', { duration: 6000 });
      });
  }

  /** function to get all patients from database */
  getAllPatients() {
    this.loadingPatients = true;
    this.patientService.getAllPatients(false)
      .then(patients => {
        // loaded all patients
        this.loadingPatients = false;
        this.patients = patients;
        this.getAllNarratives();  // get narratives
      })
      .catch(error => {
        // error fetching patients
        console.log(error);
        this.loadingPatients = false;
        this.snackBar.open('Error loading patients.', 'Error', { duration: 6000 });
      })
  }

  /** function to get all facilities from database*/
  getAllFacilities() {
    this.loadingFacilities = true;
    this.facilityService.getFacilities()
      .then(facilities => {
        // loaded all patients
        this.getAllPatients();  // get patients
        this.loadingFacilities = false;
        this.facilities = facilities;
        this.facilityNames = this.facilities.map(f => f.name);

        this.filteredFacilities = this.facilityControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.applyFacilityFilter(value))
          );
      })
      .catch(error => {
        // error fetching facilities
        console.log(error);
        this.loadingFacilities = false;
        this.snackBar.open('Error loading facilities.', 'Error', { duration: 6000 });
      });
  }

  /** function to get all mediators from database*/
  getAllMediators() {
    this.loadingMediators = true;
    this.patientService.getAllMediators()
      .then(mediators => {
        this.loadingMediators = false;
        this.mediatorsNames = mediators.map(m => m.username);

        this.filteredMediators = this.userControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.applyUserFilter(value))
          );
      })
      .catch(error => {
        // error fetching mediators
        console.log(error);
        this.loadingMediators = false;
        this.snackBar.open('Error loading mediators.', 'Error', { duration: 6000 });
      });
  }

  /** function navigate to view the narrative from the narratives list */
  viewNarrative(narrative: any): void {
    if (!narrative.scanIndication && narrative.narrativeType === NarrativeEnumTypes.obstetric) {
      this.router.navigate(['/patient/' + narrative.patientId + '/narrative/' + narrative.id + '/add/' + narrative.narrativeType]);
    } else if (narrative.narrativeType === NarrativeEnumTypes.consultation && !narrative.presumptiveDiagnosis) {
      this.router.navigate(['/patient/' + narrative.patientId + '/narrative/' + narrative.id + '/edit/' + narrative.narrativeType]);
    } else {
      this.router.navigate(['/patient/' + narrative.patientId + '/narrative/' + narrative.id + '/view/' + narrative.narrativeType]);
    }
  }

  /** search records by narrative status */
  applyStatusFilter(val: any) {
    if (!this.appSettings.narrativeSettings.applyMultipleNarrativeFilter) { this.dataSource.statusFilter = val; } else {
      if (val.length < 1) {
        this.dataSource.statusFilter = '';
        this.dataSource.statusFilterHelpDesk = [];
      } else if (val.length === 1 && val[0] === NarrativeStatusFilterOptions.notShared) {
        this.dataSource.statusFilter = val[0];
      } else {
        this.dataSource.statusFilter = '';
        this.dataSource.statusFilterHelpDesk = val;
      }
    }

    this.paginator.firstPage();
  }

  /** search by referral urgency */
  applyUrgencyFilter(val: any) {
    if (val.length < 1) {
      this.dataSource.urgencyFilterMultiple = [];
    } else {
      this.dataSource.urgencyFilterMultiple = val;
    }
    this.paginator.firstPage();
  }

  /** search records by narrative type */
  applyTypeFilter(val: string) {
    this.dataSource.typeFilter = val;
    this.paginator.firstPage();
  }

  /** search records by patient name */
  applyNameFilter(val: string) {
    this.dataSource.nameFilter = val;
    this.paginator.firstPage();
  }

  /** search records by patient rreg # */
  applyRegFilter(val: string) {
    this.dataSource.regFilter = val;
    this.paginator.firstPage();
  }

  /** search records by facility name */
  applyFacilityFilter(val: string = ''): string[] {
    const filterValue = val.toLowerCase();
    this.dataSource.facilityFilter = filterValue;
    this.paginator.firstPage();

    return this.facilityNames.filter(n => n.toLowerCase().includes(filterValue));
  }

  /** search records by patient rreg # */
  applyUserFilter(val: string = ''): string[] {
    const filterValue = val.toLowerCase();
    this.dataSource.userFilter = filterValue;
    this.paginator.firstPage();

    return this.mediatorsNames.filter(m => m.toLowerCase().includes(filterValue));
  }

  /** close/hide the keyboard */
  hideKeyboard() {
    this.filterByName.filterInput.nativeElement.blur();
    this.filterByReg.filterInput.nativeElement.blur();
  }

  /**
   * Function clear all filters in form
   */
  clearFilters() {
    // clear form data
    this.statusFilter = '';
    this.urgencyFilter = '';
    this.narrTypeFilter = '';
    this.regFilter = '';
    this.nameFilter = '';
    this.facilityControl.setValue('');
    this.userControl.setValue('');
    this.paginator.firstPage();

    // clear data filter
    this.dataSource.statusFilter = '';
    this.dataSource.typeFilter = '';
    this.dataSource.nameFilter = '';
    this.dataSource.regFilter = '';
    this.dataSource.statusFilterHelpDesk = [];
    this.dataSource.urgencyFilterMultiple = [];
    this.dataSource.facilityFilter = '';
    this.dataSource.userFilter = '';
  }
}

// Database Object for Narrative list
export class NarrativeDatabase {
  dataChange: BehaviorSubject<NarrativeInterface[]> = new BehaviorSubject<NarrativeInterface[]>([]);
  get data(): NarrativeInterface[] { return this.dataChange.value; }
  set data(narratives: NarrativeInterface[]) { this.dataChange.next(narratives); }

  constructor() { }
}

// DataSource Object for AnswerSheet
export class NarrativeDataSource extends DataSource<NarrativeInterface> {
  filteredData: NarrativeInterface[] = [];
  renderedData: NarrativeInterface[] = [];
  // captures changes
  _filterNarrativesDataChangeStatus = new BehaviorSubject('');
  _filterNarrativesDataChangeType = new BehaviorSubject('');
  _filterNarrativesDataChangeName = new BehaviorSubject('');
  _filterNarrativesDataChangeReg = new BehaviorSubject('');
  _filterNarrativesDataChangeFacility = new BehaviorSubject('');
  _filterNarrativesDataChangeUser = new BehaviorSubject('');
  _filterNarrativesDataChangeStatusHelpDesk = new BehaviorSubject([]);
  _filterNarrativesDataChangeUrgencyMultiple = new BehaviorSubject([]);

  get statusFilter(): string { return this._filterNarrativesDataChangeStatus.value; }
  set statusFilter(filter: string) { this._filterNarrativesDataChangeStatus.next(filter); }
  get urgencyFilterMultiple(): string[] { return this._filterNarrativesDataChangeUrgencyMultiple.value; }
  set urgencyFilterMultiple(filter: string[]) { this._filterNarrativesDataChangeUrgencyMultiple.next(filter); }
  get typeFilter(): string { return this._filterNarrativesDataChangeType.value; }
  set typeFilter(filter: string) { this._filterNarrativesDataChangeType.next(filter); }
  get nameFilter(): string { return this._filterNarrativesDataChangeName.value; }
  set nameFilter(filter: string) { this._filterNarrativesDataChangeName.next(filter); }
  get regFilter(): string { return this._filterNarrativesDataChangeReg.value; }
  set regFilter(filter: string) { this._filterNarrativesDataChangeReg.next(filter); }
  get statusFilterHelpDesk(): string[] { return this._filterNarrativesDataChangeStatusHelpDesk.value; }
  set statusFilterHelpDesk(filter: string[]) { this._filterNarrativesDataChangeStatusHelpDesk.next(filter); }

  get facilityFilter(): string { return this._filterNarrativesDataChangeFacility.value; }
  set facilityFilter(filter: string) { this._filterNarrativesDataChangeFacility.next(filter); }
  get userFilter(): string { return this._filterNarrativesDataChangeUser.value; }
  set userFilter(filter: string) { this._filterNarrativesDataChangeUser.next(filter); }


  constructor(private _SheetsDatabase: NarrativeDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<NarrativeInterface[]> {
    const displaySheetDataChange = [
      this._SheetsDatabase.dataChange,
      this._filterNarrativesDataChangeStatus,
      this._filterNarrativesDataChangeType,
      this._filterNarrativesDataChangeName,
      this._filterNarrativesDataChangeReg,
      this._filterNarrativesDataChangeFacility,
      this._filterNarrativesDataChangeUser,
      this._filterNarrativesDataChangeStatusHelpDesk,
      this._filterNarrativesDataChangeUrgencyMultiple,

      this._paginator.page,
      this._sort.sortChange
    ];

    return merge(...displaySheetDataChange).pipe(map(() => {
      if (!this.statusFilter && !this.typeFilter && !this.nameFilter && !this.regFilter && !this.facilityFilter && !this.userFilter
        && (!this.statusFilterHelpDesk || this.statusFilterHelpDesk.length < 1) &&
        (!this.urgencyFilterMultiple || this.urgencyFilterMultiple.length < 1)) {
        // no filters specified, bypass filtering
        this.filteredData = this._SheetsDatabase.data.slice();
      } else {
        // filter by search terms
        this.filteredData = this._SheetsDatabase.data.slice().filter((narrative: NarrativeWithDetails) => {

          const search_narrativeStatus = narrative.narrativeStatus ? narrative.narrativeStatus.toLowerCase() : '';
          const search_narrativeType = narrative.narrativeType ? narrative.narrativeType.toLowerCase() : '';
          const search_patientName = narrative.patientName ? narrative.patientName.toLowerCase() : '';
          const search_patientReg = narrative.patientReg ? narrative.patientReg.toLowerCase() : '';
          const search_scanFacility = narrative.scanFacilityName ? narrative.scanFacilityName.toLowerCase() : '';
          const search_scanUser = narrative.createdBy ? narrative.createdBy.toLowerCase() : '';
          const search_narrativeStatusHelpDesk = narrative.referralStatus ? narrative.referralStatus : '';
          const search_urgencyFilterMultiple = narrative.caseUrgency ? narrative.caseUrgency : '';

          const result_narrativeStatus = this.statusFilter ? search_narrativeStatus === this.statusFilter.toLowerCase() : true;
          const result_narrativeType = this.typeFilter ? search_narrativeType.indexOf(this.typeFilter.toLowerCase()) !== -1 : true;
          const result_patientName = this.nameFilter ? search_patientName.indexOf(this.nameFilter.toLowerCase()) !== -1 : true;
          const result_patientReg = this.regFilter ? search_patientReg.indexOf(this.regFilter.toLowerCase()) !== -1 : true;
          const result_scanFacility = this.facilityFilter ? search_scanFacility.indexOf(this.facilityFilter.toLowerCase()) !== -1 : true;
          const result_scanUser = this.userFilter ? search_scanUser.indexOf(this.userFilter.toLowerCase()) !== -1 : true;
          const result_narrativeStatusHelpDesk = (this.statusFilterHelpDesk && this.statusFilterHelpDesk.length > 0) ?
            this.statusFilterHelpDesk.includes(search_narrativeStatusHelpDesk) : true;
          const result_urgencyFilterMultiple = (this.urgencyFilterMultiple && this.urgencyFilterMultiple.length > 0) ?
            this.urgencyFilterMultiple.includes(search_urgencyFilterMultiple) : true;

          return result_narrativeStatus && result_narrativeType && result_patientName && result_patientReg && result_scanFacility
            && result_scanUser && result_narrativeStatusHelpDesk && result_urgencyFilterMultiple;
        });
        console.log('Found ' + this.filteredData.length + ' narratives');
      }

      const allData = this.getSortedData(this.filteredData.slice());

      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.renderedData = allData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() { }

  getSortedData(data: NarrativeInterface[]): NarrativeInterface[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string | Date = '';
      let propertyB: number | string | Date = '';

      switch (this._sort.active) {
        case 'name': [propertyA, propertyB] = [a.patientName, b.patientName]; break;
        case 'regnumber': [propertyA, propertyB] = [a.patientReg, b.patientReg]; break;
        case 'status': [propertyA, propertyB] = [a.narrativeStatus, b.narrativeStatus]; break;
        case 'urgency': [propertyA, propertyB] = [a.caseUrgency, b.caseUrgency]; break;
        case 'narrtype': [propertyA, propertyB] = [a.narrativeType, b.narrativeType]; break;
        case 'scanfacility': [propertyA, propertyB] = [a.scanFacilityName, b.scanFacilityName]; break;
        case 'scanby': [propertyA, propertyB] = [a.createdBy, b.createdBy]; break;
        case 'date': [propertyA, propertyB] = [a.dateAdded, b.dateAdded]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

export class NarrativeWithDetails extends Narrative {
  patientName: string;
  patientReg: string;
  narrativeStatus: string;
  caseUrgency: string;
  scanFacilityName: string;
}
