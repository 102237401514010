import { Component, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FoetalLimbsBlock } from '../../../../models/obstetric-narrative';
import { SonographerBlockFoetalLimbs } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';
import { GestationAgeByFemurLength } from '../../../../models/narrative-types';
import { ValidationService } from '../../../../validation/validation.service';

@Component({
  selector: 'psoc-foetal-limbs-sonographer-edit',
  templateUrl: 'foetal-limbs-sonographer-edit.component.html'
})

export class FoetalLimbsSonographerEditComponent extends SonographerBlockEditComponent implements AfterViewChecked {
  @Input() nurseData: FoetalLimbsBlock;
  @Input() blockData: SonographerBlockFoetalLimbs;
  @Input() selectedFoetus;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;
  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
  form: FormGroup;
  femurLengthValid = false;

  constructor(
    private _fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.form = this._fb.group({
      femurLength: ['', ValidationService.rangeCheck(this.returnMinMaxRange(GestationAgeByFemurLength))],
      blockStatus: [''],
      sonographerNotes: [''],
      cantConfirmFemurLength: ['']
    })
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Navigate to the next block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    this.femurLengthValid = (this.blockData.femurLength > 0 || this.blockData.cantConfirmFemurLength);
    return this.femurLengthValid && this.blockData.blockStatus ? true : false;
  }
}
