import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ObstetricNarrative, scanningIndication } from '../../../../models/obstetric-narrative';
import { ObstetricBlockEditComponent } from '../block-edit.component';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { Facility } from '../../../../models/facility';
import moment from 'moment';
import { ValidationService } from '../../../../validation/validation.service';

@Component({
  selector: 'psoc-delete-obstetric-ultrasound-record',
  template: `
    <h2>Confirm Delete Utrasound Record</h2>
    <p>This ultrasound record will be deleted</p>
    <p><b>Notice:</b> This action cannot be undone</p>
    <p>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Ok</button>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">Cancel</button>
    </p>`
})

export class ConfirmDeleteUltrasoundRecordComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-ultrasound-record-nurse-edit',
  templateUrl: 'ultrasound-record-nurse-edit.component.html'
})

export class UltrasoundRecordNurseEditComponent extends ObstetricBlockEditComponent implements OnInit, AfterViewChecked {
  _obstetricNarrative: ObstetricNarrative;
  newNarrative: boolean = false;
  @Input() set obstetricNarrative(obstetricNarrative: ObstetricNarrative) {
    if (!obstetricNarrative.scanIndication) { this.newNarrative = true; this.showBlock = true; }
    this._obstetricNarrative = obstetricNarrative;
    if (!obstetricNarrative.scanIndication && obstetricNarrative.dateOfLNMP) { this.calculateGestationAgeByDate() }
  }
  get obstetricNarrative(): ObstetricNarrative { return this._obstetricNarrative; }
  @Output() trimesterChanged: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() obstetricNarrativeChange: EventEmitter<ObstetricNarrative> = new EventEmitter<ObstetricNarrative>();
  @Output() recordUpdated: EventEmitter<any> = new EventEmitter;
  @Output() viewRecord: EventEmitter<any> = new EventEmitter;
  @Input() backupObstetricNarrative: ObstetricNarrative;
  @Input() facilities: Facility[];

  maxDate: Date = new Date();  // set maximum date to pick
  futureDate: boolean;
  otherScanFacility: boolean;

  scanningIndication = scanningIndication;
  gravidaOptions = ['1', '2', '3', '4+'];
  paraOptions = ['0', '1', '2', '3', '4+'];

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    public narrativeService: NarrativeService,
    public attachmentService: AttachmentService
  ) {
    super(dialog, snackBar, sanitizer, narrativeService, attachmentService);
    this.form = this.fb.group({
      scanningClinician: ['', [Validators.required]],
      scanFacility: ['', [Validators.required]],
      dateOfLNMP: ['', [ValidationService.dateFormatValid, ValidationService.dateInPastValidator]],
      dLMPUnknown: [''],
      scanIndication: ['', [Validators.required]],
      gravida: ['', [Validators.required]],
      para: ['', [Validators.required]],
      gestationAge: [''],
      actualGestationAge: [''],
      dateOfDelivery: [''],
      nurseNotes: ['']
    });
  }

  /**
   * Check if data is valid for this block
   */
  public blockValid() {
    return (this.obstetricNarrative
      && this.obstetricNarrative.scanIndication) ? true : false;
  }

  ngOnInit(): void {
    this.updateForm();  // call function to update parent form with block form
    this.checkDateField();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  /**
   * function to begin scanning
   */
  startScan() {
    this.recordUpdated.emit();
    this.nextBlock();
  }

  /**
   * discard changes made to an existing ultrasound record
  */
  confirmDiscardRecord() {
    if (this.form.dirty) {
      // prompt
      this.dialogRef = this.dialog.open(ConfirmDeleteUltrasoundRecordComponent);
      // if discard - discard and redirect to list of narratives
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.obstetricNarrative = new ObstetricNarrative(this.backupObstetricNarrative)
          this.viewRecord.emit(this.obstetricNarrative);
        } else {
          // if cancel - do nothing
        }
      })
    } else {
      // redirect to list of narratives
      this.obstetricNarrative = new ObstetricNarrative(this.backupObstetricNarrative)
      this.viewRecord.emit(this.obstetricNarrative);
    }
  }

  /**
   * discard changes made to a new ultrasound record
   */
  confirmDiscardNewRecord() {
    if (this.form.dirty) {
      // prompt
      this.dialogRef = this.dialog.open(ConfirmDeleteUltrasoundRecordComponent);
      // if discard - discard and redirect to list of narratives
      this.dialogRef.afterClosed().subscribe(result => {
        if (result = true) {
          this.obstetricNarrative = new ObstetricNarrative()
          this.viewRecord.emit(this.obstetricNarrative);
        } else {
          // if cancel - do nothing
        }
      });
    } else {
      this.obstetricNarrative = new ObstetricNarrative()
      this.viewRecord.emit(this.obstetricNarrative);
    }
  }

  /** detect when key is pressed and if null set errors to null */
  keyDateUp(e: any) {
    if (!e.target.value) { this.form.controls['dateOfLNMP'].setErrors(null); }
  }

  checkDateField() {
    if (!this.obstetricNarrative.dateOfLNMP) {
      this.form.controls['dateOfLNMP'].setValidators([Validators.nullValidator]);
    }
  }

  /**
   * function to calculate the gestation age by date
   */
  calculateGestationAgeByDate() {
    this.form.controls['dateOfLNMP'].setValidators([ValidationService.dateFormatValid, ValidationService.dateInPastValidator]);
    if (!this.obstetricNarrative.dateOfLNMP) {
      this.obstetricNarrative.gestationAge = null;
      this.obstetricNarrative.dateOfDelivery = null;
      return;
    }

    const today = moment(new Date());
    const dlnmp = moment(this.obstetricNarrative.dateOfLNMP);
    const weeks = +(today.diff(dlnmp, 'days') / 7).toFixed(1);

    this.obstetricNarrative.gestationAge = weeks;

    this.calculateDateOfDelivery(dlnmp);  // calculate DOD

    if (this.obstetricNarrative.dateOfLNMP > new Date()) { this.futureDate = true; return }
    this.futureDate = false;
  }

  /**
   * function to calculate date of delivery from DLNMP
   * @param dlnmp DLNMP
   */
  calculateDateOfDelivery(dlnmp) {
    const dod = dlnmp.add(280, 'days').format('dddd Do MMM YYYY');
    this.obstetricNarrative.dateOfDelivery = dod;
  }

}
