import { Pipe, PipeTransform } from '@angular/core';
import { Narrative } from '../models/narrative';
import { ShareLinks } from '../models/sharelinks';
import { NarrativeEnumTypes, NarrativeUrgencyFilterOptions, NarrativeInterface } from '../models/narrative-types';

@Pipe({ name: 'getNarrativeUrgency' })
export class GetNarrativeUrgency implements PipeTransform {
  transform(narrative: NarrativeInterface, patientSharelinks: ShareLinks[]) {
    const sharelinks = patientSharelinks.filter(s => s.narrativeId === +narrative._id);
    let caseUrgency = '';

    if (narrative.narrativeType !== NarrativeEnumTypes.obstetric) {
      // handle consultations && referrals
      let narr = new Narrative(narrative);

      if ((!narr.referral || !narr.referral.referral_urgency) || narr.referral.referral_urgency === '') {
        caseUrgency = NarrativeUrgencyFilterOptions.notEntered;
      } else {
        if (narr.referral.referral_urgency === NarrativeUrgencyFilterOptions.urgent) {
          caseUrgency = narr.referral.referral_urgency;
        } else if (narr.referral.referral_urgency === NarrativeUrgencyFilterOptions.emergency) {
          caseUrgency = NarrativeUrgencyFilterOptions.emergency;
        } else if (narr.referral.referral_urgency === NarrativeUrgencyFilterOptions.routine) {
          caseUrgency = NarrativeUrgencyFilterOptions.routine;
        }
      }
    } else {
      if (sharelinks.length > 0) {
        const sortedSharelinks = sharelinks.sort((a, b) => (a.id > b.id) ? -1 : 1);
        if (sortedSharelinks[0].urgent) {
          // the latest sharelink is urgent caseUrgency: Urgent
          caseUrgency = NarrativeUrgencyFilterOptions.urgent;
        } else {
          caseUrgency = NarrativeUrgencyFilterOptions.notEntered;
        }
      } else {
        caseUrgency = NarrativeUrgencyFilterOptions.notEntered;
      }
    }
    return caseUrgency;
  }
}
