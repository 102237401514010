import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CdkTableModule } from '@angular/cdk/table';

import { AppMaterialModule } from '../material/app-material.module';
import { MatTableModule } from '@angular/material';
import { MomentModule } from 'ngx-moment';

import { ValidationModule } from '../validation/validation.module';

import { PipesModule } from '../pipes/pipes.module';
import { PatientsComponent } from './patients.component';
import { PatientListComponent, DeletePatientDialogComponent } from './list/patient-list.component';
import { PatientEditComponent, PatientExistDialogComponent } from './edit/patient-edit.component';
import { PatientViewComponent } from './view/patient-view.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { EnquiriesListComponent } from './enquiries-list/enquiries-list.component';
import { PatientDuplicatesListComponent } from './duplicates/patient-duplicates-list.component';
import { MergeFixPatientDialogComponent, ConfirmMergeFixPatientDialogComponent } from './merge-fix-patient/merge-fix-patient.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MomentModule,
    PipesModule,
    ValidationModule,
    HttpClientModule,
    CdkTableModule,
    MatTableModule,
  ],
  declarations: [
    PatientsComponent,
    PatientListComponent,
    EnquiriesListComponent,
    PatientEditComponent,
    PatientViewComponent,
    DeletePatientDialogComponent,
    ClientSearchComponent,
    PatientExistDialogComponent,
    PatientDuplicatesListComponent,
    MergeFixPatientDialogComponent,
    ConfirmMergeFixPatientDialogComponent
  ],
  entryComponents: [
    DeletePatientDialogComponent,
    ClientSearchComponent,
    PatientExistDialogComponent,
    MergeFixPatientDialogComponent,
    ConfirmMergeFixPatientDialogComponent
  ],
  exports: [
    PatientsComponent,
    PatientListComponent,
    EnquiriesListComponent,
    PatientEditComponent,
    PatientViewComponent,
    PatientDuplicatesListComponent,
    DeletePatientDialogComponent
  ]
})
export class PatientsModule { }
