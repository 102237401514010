import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Globals } from '../../services/globals';
import { Patient, PatientWithStatus } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { AttachmentService } from '../../services/attachment.service';
import { AuthenticationService } from '../../services/authentication.service';
import { RepositoryService, AppTypes } from '../../services/repository.service';
import { RepositoryObserver } from '../../services/repository-observer';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge, fromEvent } from 'rxjs';
import { ReferralStatus } from '../../models/narrative';
import { AppSettingsService } from '../../services/settings.service';

import { MatSnackBar } from '@angular/material';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../models/config';
import { InsuranceClient } from '../../models/insurance-client';
import { CheckPatientInClientDBPipe } from '../../pipes/patientInClientDBPipe';

@Component({
  selector: 'psoc-delete-patient-dialog',
  template: `
  <h2>Confirm Patient Delete</h2>
  <p>All patient data will be deleted. <br /> Please note that this data cannot be recovered.</p>
  <p><button type="button" mat-raised-button (click)="dialogRef.close(true)">Delete</button>
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button>`
})
export class DeletePatientDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: 'psoc-patient-list',
  templateUrl: 'patient-list.component.html',
  providers: [CheckPatientInClientDBPipe]
})

export class PatientListComponent implements OnInit, RepositoryObserver {
  // displayedColumns = ['id', 'name', 'dateOfBirth', 'sex', 'dateAdded', 'status'];  // with column status
  adminColumn: string = '';
  displayedColumns = ['id', 'name', 'dateOfBirth', 'sex', 'dateAdded'];
  patientDatabase: PatientDatabase;
  dataSource: PatientsDataSource | null;
  appTypes = AppTypes;
  ReferralStatusEnum = ReferralStatus;
  referralStatuses = this.repository.enumSelector(ReferralStatus);
  env = environment;
  // settings data
  appSettings: AppSettings;
  loadingAppSettings: boolean = false;
  // patient data
  patients: PatientWithStatus[];
  loadingPatients: boolean;
  // insurance member data
  clients: InsuranceClient[] = [];
  loadingClients: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('filter') filter: ElementRef;

  @ViewChild(MatSort) sort: MatSort;

  dialogRef: MatDialogRef<any>;

  ngOnInit() {
    // Uncomment the lines below to allow admin functions in patient list
    // this.adminColumn = 'admin';
    // if (this.repository.getApp() === AppTypes.adminApp && this.authService.isAdmin() && this.adminColumn !== '') {
    //   this.displayedColumns.push(this.adminColumn);
    // }

    this.repository.registerObserver(this);

    // load app settings and add get clients(if appSettings.showInsurerClientSearch === true) & patients
    this.loadAppSettings();

    // set up patients database
    this.patientDatabase =
      new PatientDatabase(this.patientService, this.snackBar, this.attachmentService, this.repository);

    // set up patients dataSource
    this.dataSource = new PatientsDataSource(this.patientDatabase, this.paginator, this.sort);

    // listen to keystrokes for search function
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged())
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  /** check for updates in the database */
  notify(objectType: string): void {
    if (objectType === AppSettings.type) {
      this.loadAppSettings();
    }
    if (objectType === Patient.type) {
      this.getPatients();
    }
  }

  /**
   * Load app settings
   */
  loadAppSettings(appSettingsId: string = this.env.settingsId) {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(appSettingsId)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadingAppSettings = false;
        this.ref.detectChanges();
        // load insurance member data
        if (this.repository.getApp() === AppTypes.adminApp && this.authService.isAdmin() && this.appSettings.showInsurerClientSearch) {
          this.displayedColumns.includes('admin') && this.adminColumn !== '' ?
            this.displayedColumns.splice(this.displayedColumns.length - 1, 1, 'match', this.adminColumn)
            : this.displayedColumns.push('match');
          this.getClients();
        } else {
          this.getPatients();
        }
      }).catch(error => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }


  /** function delete patient */
  confirmDelete(patient: Patient, event: any): void {
    event.stopPropagation();
    this.dialogRef = this.dialog.open(DeletePatientDialogComponent);

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // this.patientDatabase.deletePatient(patient);
      }
      this.dialogRef = null;
    });
  }

  /** get list of clients and get the patients */
  getClients(): void {
    this.loadingClients = true;
    this.patientService.getAllClients(this.appSettings.narrativeSettings.eS_API_KEY)
      .then((clients: Array<InsuranceClient>) => {
        this.loadingClients = false;
        this.clients = clients;
        this.getPatients();
      }).catch(error => {
        console.log('Error loading list of clients: ', error);
        this.loadingClients = true;
        const snackBarRef = this.snackBar.open('Error loading list of clients, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getClients()
        });
      });
  }

  /** get list of patients and update their narrative status */
  getPatients(usePatientCache = true): void {
    this.loadingPatients = true;

    this.patientService.getAllPatients(usePatientCache)
      .then((patients: Array<PatientWithStatus>) => {
        this.patients = patients.map((patient: PatientWithStatus) => {
          patient = new PatientWithStatus(patient);
          patient = new PatientWIthDetails(patient);
          // apply the CheckPatientInClientDBPipe Pipe
          if (this.repository.getApp() === AppTypes.adminApp && this.authService.isAdmin() && this.appSettings.showInsurerClientSearch) {
            patient.matchInClientDB = this.checkPatientInClientDBPipe.transform(patient, this.clients);
          }
          return patient;
        });
        this.patientDatabase.data = this.patients;

        this.loadingPatients = false;
      }).catch(error => {
        this.loadingPatients = true;
        console.log('Error loading list of patients: ', error)
        const snackBarRef = this.snackBar.open('Error loading list of patients, please try again:', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getPatients()
        });
      });
  }

  // show view Narrative
  narrativeList(patient: Patient): void {
    this.router.navigate(['/patient/' + patient.id + '/narratives']);
  }

  // show edit form for selected patient
  editDemographics(patient: Patient, match?: string): void {
    // pass match to edit component
    if (match) {
      this.router.navigate(['/patient/from-match/' + patient.id]);
    } else {
      this.router.navigate(['/patient/edit/', patient.id]);
    }
  }

  /** apply filter for patient list */
  applyFilter(filterValue: string) {
    console.log('Filtering::');
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // move to first page if searching
    this.paginator.firstPage();
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    public repository: RepositoryService,
    private globals: Globals,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private patientService: PatientService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private appSettingsService: AppSettingsService,
    private checkPatientInClientDBPipe: CheckPatientInClientDBPipe
  ) {
    // update current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }
}

// Database Object for the patients table
export class PatientDatabase implements RepositoryObserver {
  dialogRef: MatDialogRef<any>;
  @ViewChild('filter') filter: ElementRef
  // patient list
  dataChange: BehaviorSubject<PatientWithStatus[]> = new BehaviorSubject<PatientWithStatus[]>([]);
  get data(): PatientWithStatus[] { return this.dataChange.value; }
  set data(patients: PatientWithStatus[]) { this.dataChange.next(patients); }

  constructor(
    private patientService: PatientService,
    public snackBar: MatSnackBar,
    public attachmentService: AttachmentService,
    private repository: RepositoryService,
  ) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === Patient.type) {
      this.dataChange.next(this.data);
    }
  }

  // delete a given patient
  deletePatient(patient: Patient): void {
    this.patientService
      .deletePatient(patient)
      .then(deleted => {
        this.dataChange.next(this.data.filter(h => h !== patient));
        this.snackBar.open('Patient Deleted!', 'Success', { duration: 6000 })
      });
  }

  getPatientSize(patient: Patient): void {
    this.attachmentService.getPatientAttachments(patient.id).then(
      (attachments) => {
        patient.attachmentCount = attachments.length;
        patient.dataSize = 0;
        attachments.forEach((attachment) => {
          // todo: Update this to load size for each file in _attachments
          this.attachmentService.getAttachmentFile(attachment._id, 'file').then((blob) => {
            patient.dataSize += blob.size;
            console.log('datasize: ' + patient.dataSize + ' blobsize: ' + blob.size);
            blob = null;
          })
        })
      });
  }
}

// DataSource Object for the patients table
export class PatientsDataSource extends DataSource<PatientWithStatus> {
  filteredData: PatientWithStatus[] = [];
  renderedData: PatientWithStatus[] = [];
  _filterPatientChange = new BehaviorSubject('');

  get filter(): string { return this._filterPatientChange.value; }
  set filter(filter: string) { this._filterPatientChange.next(filter); }

  constructor(private _patientDatabase: PatientDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<PatientWithStatus[]> {
    const displayPatientDataChanges = [
      this._patientDatabase.dataChange,
      this._filterPatientChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return merge(...displayPatientDataChanges).pipe(map(() => {
      // filter by search terms
      this.filteredData = this._patientDatabase.data.slice().filter((item: PatientWIthDetails) => {
        const searchStr = (item.firstname + ' ' + item.lastname).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // sort by selected column
      const sortedData = this.getSortedData(this.filteredData.slice());

      // display current page of info
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize)
    }));

  }

  disconnect() { }

  getSortedData(data: PatientWithStatus[]): PatientWithStatus[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string | Date = '';
      let propertyB: number | string | Date = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.idnumber, b.idnumber]; break;
        // case 'firstname': [propertyA, propertyB] = [a.firstname, b.firstname]; break;
        case 'name': [propertyA, propertyB] = [a.lastname, b.lastname]; break;
        case 'dateOfBirth': [propertyA, propertyB] = [a.dateOfBirth, b.dateOfBirth]; break;
        case 'sex': [propertyA, propertyB] = [a.sex, b.sex]; break;
        case 'dateAdded': [propertyA, propertyB] = [a.dateAdded, b.dateAdded]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

export class PatientWIthDetails extends PatientWithStatus {
  matchInClientDB?: boolean;
}
