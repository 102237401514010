import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppMaterialModule } from '../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from '../pipes/pipes.module';

import { DndModule } from 'ng2-dnd';

import { AttachmentsModule } from '../attachments/attachments.module';
import { AttachmentModal } from '../attachments/attachment-modal';
import { PatientsModule } from '../patients/patients.module';
import { ValidationModule } from '../validation/validation.module';
import { ReferralsModule } from '../referrals/referrals.module';

import { NarrativeComponent } from './narrative.component';
import { NarrativeListComponent } from './list/narrative-list.component';
import {
  PatientNarrativeListComponent, RequestPaymentDialogComponent,
  ConfirmReferralStatusDialogueComponent
} from './list/patient-narrative-list.component';
import { NarrativeListItemComponent } from './list/patient-narrative-list.component';
import {
  NarrativeEditComponent, DeleteNarrativeDialogComponent,
  CancelNarrativeEditDialogComponent
} from './edit/narrative-edit.component';
import { ReportViewComponent } from './shared-links/report-view.component';
import { SpecialistReportViewComponent } from './shared-links/specialist-report-view.component';
import { DischargeSummaryViewComponent } from './shared-links/discharge-summary-view.component';
import { NarrativeViewComponent, DialogConfirmReferOnComponent } from './view/narrative-view.component';
import { NarrativeBlocksComponent } from './blocks/narrative-blocks.component';
import { NarrativeBlockListComponent } from './blocks/list/narrative-block-list.component';
import { NarrativeBlockAddComponent } from './blocks/add/narrative-block-add.component';
import { NarrativeRoutingModule } from './narrative-routing.module';
import { UpdatesBlockComponent } from './updates/updates-block/updates-block.component';
import { UpdateEditComponent, DeleteUpdateDialogComponent } from './updates/update-edit/update-edit.component';
import { QuestionsViewComponent } from './questions/questions-view.component';
import { QuestionsEditComponent, DeleteQuestionDialogComponent } from './questions/questions-edit.component';
import { Covid19ScreeningEditComponent } from './covid-19/covid19-screening-edit.component';
import { Covid19ScreeningViewComponent } from './covid-19/covid19-screening-view.component';
import { RadiologyViewComponent } from './shared-links/radiology-view.component';

// obstetric narrative
import { ObstetricNarrativeAddComponent } from './obstetric/add/obstetric-narrative-add.component';
import { UltrasoundRecordNurseViewComponent } from './obstetric/blocks/ultrasound-record/ultrasound-record-nurse-view.component';
import {
  ObstetricNarrativeEditComponent,
  ConfirmLoadPendingImagesComponent,
  ConfirmDiscardObstetricNarrativeComponent
} from './obstetric/edit/obstetric-narrative-edit.component';
import { ObstetricNarrativeViewComponent } from './obstetric/view/obstetric-narrative-view.component';
import {
  UltrasoundRecordNurseEditComponent,
  ConfirmDeleteUltrasoundRecordComponent
} from './obstetric/blocks/ultrasound-record/ultrasound-record-nurse-edit.component';
import {
  NumberOfFoetusNurseEditComponent,
  ConfirmDeleteFoetusDialogComponent
} from './obstetric/blocks/number-foetus/number-foetus-nurse-edit.component';
import { NumberOfFoetusNurseViewComponent } from './obstetric/blocks/number-foetus/number-foetus-nurse-view.component';
import { NumberFoetusesSonographerEditComponent } from './obstetric/blocks/number-foetus/number-foetuses-sonographer-edit.component';
import { NumberFoetusesSonographerViewComponent } from './obstetric/blocks/number-foetus/number-foetuses-sonographer-view.component';
import { MaternalAnatomyNurseEditComponent } from './obstetric/blocks/maternal-anatomy/maternal-anatomy-nurse-edit.component';
import { MaternalAnatomyNurseViewComponent } from './obstetric/blocks/maternal-anatomy/maternal-anatomy-nurse-view.component';
import { MaternalAnatomySonographerEditComponent } from './obstetric/blocks/maternal-anatomy/maternal-anatomy-sonographer-edit.component';
import { MaternalAnatomySonographerViewComponent } from './obstetric/blocks/maternal-anatomy/maternal-anatomy-sonographer-view.component';
import { RecommendationsSonographerEditComponent } from './obstetric/blocks/recommendations/recommendations-sonographer-edit.component';
import { RecommendationsSonographerViewComponent } from './obstetric/blocks/recommendations/recommendations-sonographer-view.component';

// foetus blocks
import { FoetalLieNurseEditComponent } from './obstetric/blocks/foetal-lie/foetal-lie-nurse-edit.component';
import { FoetalLieNurseViewComponent } from './obstetric/blocks/foetal-lie/foetal-lie-nurse-view.component';
import { FoetalLieSonographerEditComponent } from './obstetric/blocks/foetal-lie/foetal-lie-sonographer-edit.component';
import { FoetalLieSonographerViewComponent } from './obstetric/blocks/foetal-lie/foetal-lie-sonographer-view.component';
import { FoetalPresentationNurseEditComponent } from './obstetric/blocks/foetal-presentation/foetal-presentation-nurse-edit.component';
import { FoetalPresentationNurseViewComponent } from './obstetric/blocks/foetal-presentation/foetal-presentation-nurse-view.component';
import {
  FoetalPresentationSonographerEditComponent
} from './obstetric/blocks/foetal-presentation/foetal-presentation-sonographer-edit.component';
import {
  FoetalPresentationSonographerViewComponent
} from './obstetric/blocks/foetal-presentation/foetal-presentation-sonographer-view.component';
import { HeartNurseEditComponent } from './obstetric/blocks/foetal-heart/heart-nurse-edit.component';
import { HeartNurseViewComponent } from './obstetric/blocks/foetal-heart/heart-nurse-view.component';
import { HeartSonographerEditComponent } from './obstetric/blocks/foetal-heart/heart-sonographer-edit.component';
import { HeartSonographerViewComponent } from './obstetric/blocks/foetal-heart/heart-sonographer-view.component';
import { PlacentaNurseEditComponent } from './obstetric/blocks/foetal-placenta/placenta-nurse-edit.component';
import { PlacentaNurseViewComponent } from './obstetric/blocks/foetal-placenta/placenta-nurse-view.component';
import { PlacentaSonographerEditComponent } from './obstetric/blocks/foetal-placenta/placenta-sonographer-edit.component';
import { PlacentaSonographerViewComponent } from './obstetric/blocks/foetal-placenta/placenta-sonographer-view.component';
import { AmnioticFluidNurseEditComponent } from './obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-nurse-edit.component';
import { AmnioticFluidNurseViewComponent } from './obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-nurse-view.component';
import { AmnioticFluidSonographerEditComponent } from './obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-sonographer-edit.component';
import { AmnioticFluidSonographerViewComponent } from './obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-sonographer-view.component';
import { FoetalLimbsNurseEditComponent } from './obstetric/blocks/foetal-limbs/foetal-limbs-nurse-edit.component';
import { FoetalLimbsNurseViewComponent } from './obstetric/blocks/foetal-limbs/foetal-limbs-nurse-view.component';
import { FoetalLimbsSonographerEditComponent } from './obstetric/blocks/foetal-limbs/foetal-limbs-sonographer-edit.component';
import { FoetalLimbsSonographerViewComponent } from './obstetric/blocks/foetal-limbs/foetal-limbs-sonographer-view.component';
import { FoetalAbdomenNurseEditComponent } from './obstetric/blocks/foetal-abdomen/foetal-abdomen-nurse-edit.component';
import { FoetalAbdomenNurseViewComponent } from './obstetric/blocks/foetal-abdomen/foetal-abdomen-nurse-view.component';
import { FoetalAbdomenSonographerEditComponent } from './obstetric/blocks/foetal-abdomen/foetal-abdomen-sonographer-edit.component';
import { FoetalAbdomenSonographerViewComponent } from './obstetric/blocks/foetal-abdomen/foetal-abdomen-sonographer-view.component';
import { HeadAndSpineNurseEditComponent } from './obstetric/blocks/foetal-head-spine/head-spine-nurse-edit.component';
import { HeadAndSpineNurseViewComponent } from './obstetric/blocks/foetal-head-spine/head-spine-nurse-view.component';
import { HeadAndSpineSonographerEditComponent } from './obstetric/blocks/foetal-head-spine/head-spine-sonographer-edit.component';
import { HeadAndSpineSonographerViewComponent } from './obstetric/blocks/foetal-head-spine/head-spine-sonographer-view.component';

import { ScanViewerModalComponent } from './obstetric/viewer/scan-viewer.modal';
import { ObstetricBlockEditComponent } from './obstetric/blocks/block-edit.component';
import { ObstetricBlockViewComponent } from './obstetric/blocks/block-view.component';
import { ShareNarrativeDialogComponent } from './obstetric/view/obstetric-narrative-view.component';
import { ImagesViewerComponent } from './obstetric/viewer/images-viewer.component';
import { BlockPdfReportViewComponent } from './obstetric/blocks/block-pdf-report-view.component';

import { SonographerReportViewComponent } from './obstetric/report/sonographer-report-view.component';
import { NumberFoetusesPdfReportViewComponent } from './obstetric/blocks/number-foetus/number-foetuses-pdf-report-view.component';
import { FoetalLiePdfReportViewComponent } from './obstetric/blocks/foetal-lie/foetal-lie-pdf-report-view.component';
import {
  FoetalPresentationPdfReportViewComponent
} from './obstetric/blocks/foetal-presentation/foetal-presentation-pdf-report-view.component';
import { HeartPdfReportViewComponent } from './obstetric/blocks/foetal-heart/heart-pdf-report-view.component';
import { PlacentaPdfReportViewComponent } from './obstetric/blocks/foetal-placenta/placenta-pdf-report-view.component';
import { AmnioticFluidPdfReportViewComponent } from './obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-pdf-report-view.component';
import { FoetalLimbsPdfReportViewComponent } from './obstetric/blocks/foetal-limbs/foetal-limbs-pdf-report-view.component';
import { FoetalAbdomenPdfReportViewComponent } from './obstetric/blocks/foetal-abdomen/foetal-abdomen-pdf-report-view.component';
import { HeadSpinePdfReportViewComponent } from './obstetric/blocks/foetal-head-spine/head-spine-pdf-report-view.component';
import { MaternalAnatomyPdfReportViewComponent } from './obstetric/blocks/maternal-anatomy/maternal-anatomy-pdf-report-view.component';
import {
  RecommendationsSonographerPdfReportViewComponent
} from './obstetric/blocks/recommendations/recommendations-sonographer-pdf-report-view.component';
import {
  UltrasoundRecordPdfReportViewComponent
} from './obstetric/blocks/ultrasound-record/ultrasound-record-pdf-report-view.component';
import { SonographerBlockEditComponent } from './obstetric/blocks/block-sonographer-edit.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    NarrativeRoutingModule,
    PatientsModule,
    AttachmentsModule,
    MomentModule,
    ValidationModule,
    FlexLayoutModule,
    DndModule.forRoot(),
    PipesModule,
    ReferralsModule
  ],
  exports: [
    DndModule,
    ScanViewerModalComponent,
    UltrasoundRecordNurseViewComponent,
    NumberFoetusesSonographerEditComponent,
    NumberFoetusesSonographerViewComponent,
    FoetalLieSonographerEditComponent,
    FoetalLieSonographerViewComponent,
    FoetalPresentationSonographerEditComponent,
    FoetalPresentationSonographerViewComponent,
    HeartSonographerEditComponent,
    HeartSonographerViewComponent,
    PlacentaSonographerEditComponent,
    PlacentaSonographerViewComponent,
    AmnioticFluidSonographerEditComponent,
    AmnioticFluidSonographerViewComponent,
    FoetalLimbsSonographerEditComponent,
    FoetalLimbsSonographerViewComponent,
    FoetalAbdomenSonographerEditComponent,
    FoetalAbdomenSonographerViewComponent,
    HeadAndSpineSonographerEditComponent,
    HeadAndSpineSonographerViewComponent,
    MaternalAnatomySonographerEditComponent,
    MaternalAnatomySonographerViewComponent,
    RecommendationsSonographerEditComponent,
    RecommendationsSonographerViewComponent,
    UltrasoundRecordPdfReportViewComponent,
    SonographerBlockEditComponent,
    SpecialistReportViewComponent,
    Covid19ScreeningViewComponent,
    RadiologyViewComponent
  ],
  declarations: [
    NarrativeComponent,
    NarrativeListComponent,
    PatientNarrativeListComponent,
    NarrativeListItemComponent,
    NarrativeEditComponent,
    DeleteNarrativeDialogComponent,
    CancelNarrativeEditDialogComponent,
    ReportViewComponent,
    DischargeSummaryViewComponent,
    NarrativeViewComponent,
    NarrativeBlocksComponent,
    NarrativeBlockAddComponent,
    NarrativeBlockListComponent,
    QuestionsViewComponent, QuestionsEditComponent, DeleteQuestionDialogComponent,
    UpdatesBlockComponent,
    UpdateEditComponent,
    DeleteUpdateDialogComponent,
    DialogConfirmReferOnComponent,
    Covid19ScreeningEditComponent,
    Covid19ScreeningViewComponent,
    // obstetric narrative
    ConfirmDiscardObstetricNarrativeComponent,
    ObstetricNarrativeAddComponent,
    ScanViewerModalComponent,
    ImagesViewerComponent,
    ObstetricBlockEditComponent,
    ObstetricBlockViewComponent,
    BlockPdfReportViewComponent,
    SonographerBlockEditComponent,
    ShareNarrativeDialogComponent,
    RequestPaymentDialogComponent,
    ObstetricNarrativeEditComponent,
    ObstetricNarrativeViewComponent,
    UltrasoundRecordNurseEditComponent, ConfirmDeleteUltrasoundRecordComponent,
    UltrasoundRecordNurseViewComponent,
    NumberOfFoetusNurseEditComponent,
    ConfirmDeleteFoetusDialogComponent,
    NumberOfFoetusNurseViewComponent,
    NumberFoetusesSonographerEditComponent,
    NumberFoetusesSonographerViewComponent,
    FoetalLieNurseEditComponent,
    FoetalLieNurseViewComponent,
    FoetalLieSonographerEditComponent,
    FoetalLieSonographerViewComponent,
    FoetalPresentationNurseEditComponent,
    FoetalPresentationNurseViewComponent,
    FoetalPresentationSonographerEditComponent,
    FoetalPresentationSonographerViewComponent,
    HeartNurseEditComponent,
    HeartNurseViewComponent,
    HeartSonographerEditComponent,
    HeartSonographerViewComponent,
    PlacentaNurseEditComponent,
    PlacentaNurseViewComponent,
    PlacentaSonographerEditComponent,
    PlacentaSonographerViewComponent,
    AmnioticFluidNurseEditComponent,
    AmnioticFluidNurseViewComponent,
    AmnioticFluidSonographerEditComponent,
    AmnioticFluidSonographerViewComponent,
    FoetalLimbsNurseEditComponent,
    FoetalLimbsNurseViewComponent,
    FoetalLimbsSonographerEditComponent,
    FoetalLimbsSonographerViewComponent,
    FoetalAbdomenNurseEditComponent,
    FoetalAbdomenNurseViewComponent,
    FoetalAbdomenSonographerEditComponent,
    FoetalAbdomenSonographerViewComponent,
    HeadAndSpineNurseEditComponent,
    HeadAndSpineNurseViewComponent,
    HeadAndSpineSonographerEditComponent,
    HeadAndSpineSonographerViewComponent,
    MaternalAnatomyNurseViewComponent,
    MaternalAnatomyNurseEditComponent,
    MaternalAnatomySonographerEditComponent,
    MaternalAnatomySonographerViewComponent,
    RecommendationsSonographerEditComponent,
    RecommendationsSonographerViewComponent,
    SonographerReportViewComponent,
    NumberFoetusesPdfReportViewComponent,
    FoetalLiePdfReportViewComponent,
    FoetalPresentationPdfReportViewComponent,
    HeartPdfReportViewComponent,
    PlacentaPdfReportViewComponent,
    AmnioticFluidPdfReportViewComponent,
    FoetalLimbsPdfReportViewComponent,
    FoetalAbdomenPdfReportViewComponent,
    HeadSpinePdfReportViewComponent,
    MaternalAnatomyPdfReportViewComponent,
    RecommendationsSonographerPdfReportViewComponent,
    UltrasoundRecordPdfReportViewComponent,
    ConfirmReferralStatusDialogueComponent,
    SpecialistReportViewComponent,
    RadiologyViewComponent,
    ConfirmLoadPendingImagesComponent
  ],
  entryComponents: [
    AttachmentModal,
    ScanViewerModalComponent,
    DeleteUpdateDialogComponent,
    DeleteNarrativeDialogComponent,
    CancelNarrativeEditDialogComponent,
    DeleteQuestionDialogComponent,
    DialogConfirmReferOnComponent,
    ConfirmDeleteFoetusDialogComponent,
    ShareNarrativeDialogComponent,
    RequestPaymentDialogComponent,
    ConfirmLoadPendingImagesComponent,
    ConfirmDeleteUltrasoundRecordComponent,
    ConfirmDiscardObstetricNarrativeComponent,
    ConfirmReferralStatusDialogueComponent
  ],
})
export class NarrativeModule { }
