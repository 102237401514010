import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../models/patient';
import { Narrative } from '../../models/narrative';
import { Attachment } from '../../models/attachment';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { AttachmentModal } from '../../attachments/attachment-modal';
import { ShareLinks } from '../../models/sharelinks';
import { NarrativeService } from '../../services/narrative.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'psoc-discharge-summary-view-component',
  templateUrl: 'discharge-summary-view.component.html',
  providers: [PatientService]
})

export class DischargeSummaryViewComponent implements OnInit {

  patient: Patient;
  sharedLinkId: string;
  patientId: number;
  sharedLinkPatientId: string;
  myNarrative: Narrative;
  mySharedLink: ShareLinks;
  attachments: Attachment[];

  // display loading spinners
  loadingNarratives: Boolean = true;
  loadingSharelink: Boolean = true;
  loadingPatientData: Boolean = true;

  // attachments dialog
  dialogRef: MatDialogRef<AttachmentModal>;
  selectedAttachment: Attachment;

  ngOnInit(): void {
    // get this patients data so we can get the patient
    this.getSharelinkData();
  }

  // back button clicked, return user to narrative view
  backClicked(e: Event): void {
    e.preventDefault();

    if (this.route.snapshot.url[0].path === 'narrative') {
      // back for mediator
      if (this.patientId) {
        this.router.navigate(['/narrative/' + this.patientId]);
      } else if (this.route.snapshot.params['id']) {
        this.router.navigate(['/narrative/' + this.route.snapshot.params['id']]);
      } else {
        this.location.back();
      }
    } else {
      // back for specialist
      this.router.navigate(['/shared/' + this.route.snapshot.params['id']]);
    }

  }

  // get Sharelink Data
  getSharelinkData() {
    this.sharedLinkId = this.route.snapshot.params['id'];
    this.patientService.getSingleSharedLinkData(this.sharedLinkId).then(
      sharedlink => {
        this.mySharedLink = sharedlink;
        this.sharedLinkPatientId = String(sharedlink.patientId);
        this.patientId = +sharedlink.patientId;

        this.loadingSharelink = false;

        // now that we have the patient id, load this patients data
        this.getPatientData(this.patientId);
        this.getPatientNarratives(this.mySharedLink.narrativeId);
      }
    ).catch(error => {
      console.log('error loading sharelink in discharge-summary component for ' + this.sharedLinkId, error);
      this.snackBar.open('Error getting sharelink data!', 'Error');
    });
  }

  // get patient's narrative
  getPatientData(patientId: number): void {
    this.patientService.getSinglePatient(patientId).then(
      patient => {
        this.patient = patient;
        this.loadingPatientData = false;
      })
      .catch(error => {
        console.log(error);
        this.snackBar.open('Error getting the patient data', 'Error', { duration: 3000 });
        this.loadingPatientData = false;
      });
  }

  // get patient's narrative
  getPatientNarratives(narrativeId: number): void {
    this.narrativeService.getSingleNarrative(narrativeId)
    .then(narrative => {
      this.myNarrative = narrative;
      this.loadingNarratives = false;
    })
    .catch(error => {
      console.log('Error: getting narrative', error);
      this.snackBar.open('Narrative does not exist', 'Error', { duration: 3000 });
    });
  }

  constructor(
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {  }

}
