import { Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentService } from '../../../../services/attachment.service';
import { FoetalLieBlock } from '../../../../models/obstetric-narrative';
import { BlockPdfReportViewComponent } from '../block-pdf-report-view.component';
import { SonographerBlockFoetalLie } from '../../../../models/obstetric-sharelink';

@Component({
  selector: 'psoc-foetal-lie-pdf-report-view',
  templateUrl: 'foetal-lie-pdf-report-view.component.html'
})

export class FoetalLiePdfReportViewComponent extends BlockPdfReportViewComponent implements OnInit {
  @Input() nurseData: FoetalLieBlock;
  @Input() sonographerData: SonographerBlockFoetalLie;

  constructor(
    public sanitizer: DomSanitizer,
    public attachmentService: AttachmentService,
  ) {
    super(sanitizer, attachmentService);
  }

  ngOnInit() {
    this.getAttachments(this.nurseData);
  }
}
