import { Component, ChangeDetectorRef } from '@angular/core';
import { Globals } from '../services/globals';
import { Router } from '@angular/router';
import { RepositoryService, SyncStatus, AppTypes } from '../services/repository.service';
import { RepositoryObserver } from '../services/repository-observer';
import { AppSettings } from '../models/config';
import { AppSettingsService } from '../services/settings.service';
import { AuthenticationService, AuthObserver, AuthEvent } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material';
import { Observable, of, fromEvent, merge, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
    selector: 'top-nav',
    templateUrl: 'top-nav.component.html',
})
export class TopNavComponent implements RepositoryObserver, AuthObserver {

    public syncStatusEnum = SyncStatus;
    public appTypes = AppTypes;
    appVersion: String;
    online: Observable<boolean>;
    showNavBarShareLinks: boolean;
    shareLinksPatientId: number;
    appSettings: AppSettings;
    loadingAppSettings: boolean = false;

    constructor(
        private globals: Globals,
        private ref: ChangeDetectorRef,
        private router: Router,
        public repository: RepositoryService,
        private appSettingsService: AppSettingsService,
        public authService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {
        this.appVersion = environment.appVersion;

        this.repository.registerObserver(this);
        this.authService.registerAuthObserver(this);

        // get online status
        this.online = new BehaviorSubject<boolean>(false);
        this.online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false))
        )

        // set current global patient id
        this.globals.showShareLinkPatientId.subscribe((id: number) => {
            this.shareLinksPatientId = id;
            this.ref.detectChanges();
        });

    }

    notifyAuthEvent(event: AuthEvent){
        if (event === AuthEvent.loggedIn) {
            this.loadAppSettings();
        }
    }

    /** load app settings from DB */
    loadAppSettings(appSettingsId: string = environment.settingsId) {
        this.loadingAppSettings = true;
        this.appSettingsService.loadAppSettings(appSettingsId)
          .then((loadedAppSettings) => {
            this.appSettings = new AppSettings(loadedAppSettings);
            this.loadingAppSettings = false;
              this.ref.detectChanges();
          })
          .catch(() => {
            console.log('Error loading app settings');
            this.loadingAppSettings = false;
            // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
          });
      }

    notify(objectType: string): void {
        if (objectType === AppSettings.type) {
            this.loadAppSettings();
        }
        this.ref.detectChanges();
    }

    userLogout() {
        this.authService.makeGlobalsFalse();
        this.router.navigate(['/login']);

        // signout the user from couchdb
        this.authService.userSignOut()
            .then(response => {
                if (response['ok'] || response['ok'] === true) {
                    this.snackBar.open('Successfully logged out.', 'Success', { duration: 6000 });
                } else {
                    // this.snackBar.open(response['message'], 'Warning', { duration: 6000 });
                }
            });

    }

}
