import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { RepositoryObserver } from './repository-observer';
import { NarrativeBlock } from '../models/narrative-block';
import { Narrative } from '../models/narrative';
import { BehaviorSubject } from 'rxjs';
import { ObstetricNarrative, scanningIndication } from '../models/obstetric-narrative';
import {
  NarrativeInterface, GestationAgeByCrownrumpLength, GestationAgeByBPD, GestationAgeByHC,
  GestationAgeByAC, GestationAgeByFemurLength
} from '../models/narrative-types';

@Injectable()
export class NarrativeService implements RepositoryObserver {
  public narrative: BehaviorSubject<Narrative> = new BehaviorSubject(new Narrative());

  narratives: NarrativeInterface[]; // all narratives in system
  narrativesLoaded: boolean = false; // check if we have cached version of all narratives

  narrativesPatientId: number; // keep track of which patient we have loaded narratives for

  patientNarratives: NarrativeInterface[]; // all narratives for a patient
  patientNarrativesLoaded: boolean = false; // track if we have cached narratives for narrativesPatientId

  narrativeLoaded: boolean = false; // track whether we have cached narrative for narrativesPatientId
  narrativeLoadedId: number; // track which narrative we currently have loaded

  constructor(private repository: RepositoryService) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === Narrative.type) {
      this.narrativeLoaded = false;
      this.narrativesLoaded = false;
    }
  }

  addNarrativeBlock(newBlock: NarrativeBlock): Promise<NarrativeBlock> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(newBlock, NarrativeBlock.type, Databases.narrativeBlocksDb)
        .then((pouchObject) => {
          const updatedNarrativeBlock: NarrativeBlock = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedNarrativeBlock);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  updateNarrativeBlock(narrativeBlock: NarrativeBlock): Promise<NarrativeBlock> {
    narrativeBlock.blockid = +narrativeBlock.blockid;
    return new Promise((resolve, reject) => {
      this.repository.updateObject(narrativeBlock, NarrativeBlock.type, Databases.narrativeBlocksDb)
        .then((pouchObject) => {
          const updatedNarrativeBlock: NarrativeBlock = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedNarrativeBlock);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /**
   * function to update the narrative statusChangeHistory
   * returns the narrative statusChangeHistory
   * @param narrative
   * @param referralStatus
   */
  updateStatusHistory(narrative, referralStatus: string, note: string) {
    narrative.statusChangeHistory = narrative.statusChangeHistory ? narrative.statusChangeHistory : [];
    return narrative.statusChangeHistory.push({
      status: referralStatus,
      statusNote: note || 'Auto: ' + referralStatus.toLowerCase(),
      dateUpdated: new Date()
    });
  }

  deleteNarrativeBlock(narrativeBlock: NarrativeBlock): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.deleteObject(narrativeBlock, Databases.narrativeBlocksDb)
        .then(() => { resolve(null); })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  getNarrativeBlocks(): Promise<NarrativeBlock[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(NarrativeBlock.type, Databases.narrativeBlocksDb)
        .then((result) => {
          const blocks: NarrativeBlock[] = result.docs.map((doc: any) => this.mapObjectToNarrativeBlock(doc));
          resolve(blocks);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  private mapObjectToNarrativeBlock(object: any): NarrativeBlock {
    let narBlock: NarrativeBlock = new NarrativeBlock();
    return narBlock = { ...object };
  }

  public mapObjectToNarrative(object: any): Narrative {
    let narrative: Narrative = new Narrative();
    return narrative = { ...object };
  }

  updateNarrative(narrative: Narrative): Promise<Narrative> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(narrative, Narrative.type)
        .then((pouchObject) => {
          const updatedNarrative: Narrative = JSON.parse(JSON.stringify(pouchObject));
          this.narrativeLoaded = false; this.narrativesLoaded = false;
          resolve(updatedNarrative);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  updateObstetricNarrative(narrative: ObstetricNarrative): Promise<ObstetricNarrative> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(narrative, Narrative.type)
        .then((pouchObject) => {
          const updatedNarrative: ObstetricNarrative = JSON.parse(JSON.stringify(pouchObject));
          this.narrativeLoaded = false; this.narrativesLoaded = false;
          resolve(updatedNarrative);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  getNarratives(useNarrativeCache = true): Promise<NarrativeInterface[]> {
    return new Promise((resolve, reject) => {
      if (this.narrativesLoaded && useNarrativeCache) {
        console.log('already loaded all narratives');
        resolve(this.narratives);
      } else {
        this.loadNarratives()
          .then((narratives) => {
            this.narratives = narratives;
            this.narrativesLoaded = true;
            resolve(this.narratives);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          })
      }
    });
  }

  loadNarratives(): Promise<NarrativeInterface[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Narrative.type)
        .then((result) => {
          const narratives: NarrativeInterface[] = result.docs.map((doc: any) => this.mapObjectToNarrative(doc));
          resolve(narratives);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }


  /**
   * Get narratives for a given patient, uses cached copy if available
   * @param patientId patient id to be loaded
   */
  getPatientNarratives(patientId: number): Promise<NarrativeInterface[]> {
    return new Promise((resolve, reject) => {
      if (this.patientNarratives && this.patientNarratives.length === 0) { this.patientNarrativesLoaded = false; }
      if (this.patientNarrativesLoaded && this.narrativesPatientId === patientId) {
        console.log('already loaded narratives for this patient');
        resolve(this.patientNarratives);
      } else {
        this.loadPatientNarratives(patientId)
          .then((narratives) => {
            this.patientNarratives = narratives;
            this.narrativesPatientId = patientId;
            this.patientNarrativesLoaded = true;
            resolve(this.patientNarratives);
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          });
      }
    });
  }

  /**
   * Load narratives for given patient
   */
  loadPatientNarratives(patientId: number): Promise<NarrativeInterface[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(Narrative.type, patientId)
        .then((result) => {
          const narratives: NarrativeInterface[] = result.docs.map((doc: any) => this.mapObjectToNarrative(doc));
          resolve(narratives);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  getSingleNarrative(id: number, useNarrativeCache: boolean = true): Promise<Narrative> {
    return new Promise((resolve, reject) => {
      if (this.narrativeLoaded && this.narrativeLoadedId === id && useNarrativeCache) {
        console.log('Already loaded this narrative')
        resolve(this.narrative.getValue());
      } else {
        this.loadSingleNarrative(id)
          .then((narrative) => {
            this.narrative.next(narrative);
            this.narrativeLoaded = true;
            this.narrativeLoadedId = id;
            resolve(this.narrative.getValue());
            if (this.patientNarratives) {
              this.patientNarratives = this.patientNarratives.filter(n => n._id !== narrative._id);
              this.patientNarratives.push(narrative);
            }
          })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          });
      }
    });
  }

  loadSingleNarrative(id: number): Promise<Narrative> {
    return new Promise((resolve, reject) => {
      const narrativeFields = Narrative.fields.concat(ObstetricNarrative.fields);

      this.repository.fetchObject(Narrative.type, String(id), narrativeFields, ['_id'])
        .then(result => {
          const narrative: Narrative = result.docs.map((doc: any) => this.mapObjectToNarrative(doc))[0];
          resolve(narrative);
        }).catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /** function to make a deep copy of all the fields */
  deepCopy(obj: any) {
    let copy: any = {};

    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' !== typeof obj) { return obj };

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }

      return copy;
    }
  }

  /**
   * Compare two objects is they are a like
   * @param a object
   * @param b object
   */
  deepCompare(a: Object, b: Object) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (JSON.stringify(a[propName]) !== JSON.stringify(b[propName])) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  /**
   * Calculate gestational age of the pregnancy by scans
   * @param narrative access the scan indication field for either the obsSharelink or obsNarrative
   * @param sonographyBlocks access the sonography blocks for either the obsSharelink or obsNarrative
   */
  calculateGestationAge(narrative, sonographyBlocks) {
    if (narrative.scanIndication === scanningIndication[0]) {
      const crownRumpLengths = [];

      // get gestation age for 1st trimester using GestationAgeByCrownrumpLength
      sonographyBlocks.foetuses.forEach(f => {
        crownRumpLengths.push([
          {
            'field': (GestationAgeByCrownrumpLength[Math.round((f.headAndSpineBlock.crownRumpLength / 10) * 10) / 10]) * 7, 'title': 'CRL',
            'val': f.headAndSpineBlock.crownRumpLength / 10
          }
        ]);
      });
      return this.getFieldsAvarage(crownRumpLengths);
    }

    const allFields = [];

    // check for foetuses
    sonographyBlocks.foetuses.forEach(f => {
      allFields.push([
        {
          'field': (GestationAgeByBPD[Math.round((f.headAndSpineBlock.biparietalDiameter / 10) * 10) / 10]) * 7, 'title': 'BPD',
          'val': f.headAndSpineBlock.biparietalDiameter / 10
        },
        {
          'field': (GestationAgeByHC[this.roundOffToNearest(Math.round((f.headAndSpineBlock.headCircumference / 10) * 10) / 10)]) * 7,
          'title': 'HC',
          'val': f.headAndSpineBlock.headCircumference / 10
        },
        {
          'field': (GestationAgeByAC[this.roundOffToNearest(Math.round((f.foetalAbdomenBlock.abdominalCircumference / 10) * 10) / 10)]) * 7,
          'title': 'AC',
          'val': f.foetalAbdomenBlock.abdominalCircumference / 10
        },
        {
          'field': (GestationAgeByFemurLength[Math.round((f.foetalLimbsBlock.femurLength / 10) * 10) / 10]) * 7, 'title': 'FL',
          'val': f.foetalLimbsBlock.femurLength / 10
        }
      ]);
    });
    return this.getFieldsAvarage(allFields);
  }

  /** round off the decimal number to the nearest number in table */
  roundOffToNearest(val: number) {
    const num = val - val % 1;
    const decNum = parseFloat((val % 1).toFixed(1));

    if (decNum < 0.8 && decNum > 0.2) {
      /** round off to 0.5 */
      return num + +(Math.round(decNum * 2) / 2).toFixed(1);
    } else {
      /** round off to whole whole number */
      return num + +Math.round(decNum);
    }
  }

  /**
   * return fields avarage
   * @param fieldsArray fields Array
   */
  getFieldsAvarage(fieldsArray: Array<any>) {
    let totalAvg = 0;
    const cleanArray = [];
    const fieldsTitles = [];

    fieldsArray.forEach(f => {
      // strip all undefined from array
      const array = f.filter(r => r.field !== NaN && r.field !== undefined && r.field > 0);
      if (array.length > 0) {
        cleanArray.push(array);
        totalAvg += this.getAvg(array);
      }
    });

    // get the calculated fields titles & fetal weight
    let fetalWeight = 0;
    cleanArray.forEach((fetus, i) => {
      const fTitles = fetus.map(t => t.title).toString();
      fieldsTitles.push(fTitles);
      fetalWeight += this.calculateFetalWeight(fTitles, fieldsArray[i]);
    });

    fetalWeight = (Math.floor((fetalWeight / fieldsArray.length) * 1000) / 1000); // convert to 2 decimal places

    console.log('Number.of.foetus=>', fieldsArray.length);
    console.log('Avg.Age=>', totalAvg / fieldsArray.length);
    console.log('Avg.Age.Wks=>', totalAvg / fieldsArray.length / 7);
    console.log('Avg.Fetal.Weight=>', fetalWeight);
    const weeks = totalAvg / fieldsArray.length / 7;
    const decimalWeeks = (weeks + '').split('.');
    const decimalPart = weeks - Math.floor(weeks);

    return {
      'avg': totalAvg / fieldsArray.length / 7, 'weeks': parseInt(decimalWeeks[0], 10), 'days': Math.round(decimalPart * 7),
      'title': fieldsTitles.toString(), 'fetalWeight': fetalWeight
    };
  }

  /** Get the avarage of an array of objects */
  getAvg(arr: Array<any>) {
    const { total, count } = arr.reduce((a, b) => {
      a.total += b.field;
      a.count++;
      return a;
    }, { total: 0, count: 0 });
    return (total / count);
  }

  /**
   * calculate fetal weight depending on the available fields
   * @param fields list of fileds to calculate from
   * @param fieldsArray array of data values
   */
  calculateFetalWeight(fields: string, fieldsArray: any[]) {
    let fw: any;
    switch (fields) {
      case 'BPD,AC':
        // Log 10 (weight) = -1.7492+ 0.166*BPD +0.046*AC - 2.646*(AC*BPD)/1,000
        fw = this.calculateFetalWeightBPDAC(fieldsArray[0].val, fieldsArray[2].val);
        break;

      case 'AC,FL':
        // Log 10 (weight) = 1.304+0.05281*Ac+0.1938*FL -0.004*AC*FL
        fw = this.calculateFetalWeightACFL(fieldsArray[2].val, fieldsArray[3].val);
        break;

      case 'BPD,AC,FL':
        // Log 10 (weight) = 1.335-0.0034*AC*FL+ 0.0316*BPD+0.0457*AC +0.1623*FL
        fw = this.calculateFetalWeightBPDACFL(fieldsArray[0].val, fieldsArray[2].val, fieldsArray[3].val);
        break;

      case 'HC,AC,FL':
        // Log 10 (weight) =1.326-0.00326 *AC*FL+0.0107*HC +0.0438*AC + 0.158*FL
        fw = this.calculateFetalWeightHCACFL(fieldsArray[1].val, fieldsArray[2].val, fieldsArray[3].val);
        break;

      case 'BPD,HC,AC,FL':
        // Log10 (weight) =1.3596 -0.00386* AC * FL+0.0064*HC+0.00061*BPD*AC+ 0.0424*AC+0.174*FL
        fw = this.calculateFetalWeightAllFields(fieldsArray[0].val, fieldsArray[1].val, fieldsArray[2].val, fieldsArray[3].val);
        break;

      default:
        fw = 'Cant calculate';
        break;
    }

    return fw;
  }

  calculateFetalWeightBPDAC(bpd: number, ac: number) {
    const fw = (-1.7492 + 0.166 * bpd + 0.046 * ac - 2.646 * ac * bpd / 1000);
    return Math.pow(10, fw);
  }

  calculateFetalWeightACFL(ac: number, fl: number) {
    const fw = (1.304 + 0.05281 * ac + 0.1938 * fl - 0.004 * ac * fl);
    return Math.pow(10, fw);
  }

  calculateFetalWeightBPDACFL(bpd: number, ac: number, fl: number) {
    const fw = (1.335 - 0.0034 * ac * fl + 0.0316 * bpd + 0.0457 * ac + 0.1623 * fl);
    return Math.pow(10, fw);
  }

  calculateFetalWeightHCACFL(hc: number, ac: number, fl: number) {
    const fw = (1.326 - 0.00326 * ac * fl + 0.0107 * hc + 0.0438 * ac + 0.158 * fl);
    return Math.pow(10, fw);
  }

  calculateFetalWeightAllFields(bpd: number, hc: number, ac: number, fl: number) {
    const fw = (1.3596 - (0.00386 * ac * fl) + (0.0064 * hc) + (0.00061 * bpd * ac) + (0.0424 * ac) + (0.174 * fl));
    return Math.pow(10, fw);
  }
}
