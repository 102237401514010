import { Component, Input } from '@angular/core';
import { FoetalLimbsBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
    selector: 'psoc-foetal-limbs-nurse-view',
    templateUrl: './foetal-limbs-nurse-view.component.html'
})

export class FoetalLimbsNurseViewComponent extends ObstetricBlockViewComponent {
    // data for this block
    _blockData: FoetalLimbsBlock;
    @Input()
    set blockData(blockData: FoetalLimbsBlock) {
        this._blockData = blockData;
        this.getAttachmentFilesCount(blockData.attachmentIds);
    }
    get blockData(): FoetalLimbsBlock {
        return this._blockData;
    }

    /**
     * Check if data is valid for this block
     */
    blockValid() {
        return (this.blockData && this.blockData.blockStatus) ? true : false;
    }
}

