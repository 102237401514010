import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
    selector: 'not-found',
    template: `404 not found. The object you are trying to load cannot be found.
    Please ensure that you have the correct URL`,
})
export class NotFoundComponent {
    constructor(
        private authService: AuthenticationService
    ) {
        this.authService.makeGlobalsFalse();
    }
}
