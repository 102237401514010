import { Component, Input } from '@angular/core';
import { ShareLinks } from '../../../../models/sharelinks';
import { ObstetricNarrative } from '../../../../models/obstetric-narrative';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'psoc-ultrasound-record-pdf-report-view',
    templateUrl: 'ultrasound-record-pdf-report-view.component.html'
})

export class UltrasoundRecordPdfReportViewComponent {
    @Input() shareLink: ShareLinks;

    // display previous reports on the block, sonographer view
    @Input() currentSharelinkId: string;
    @Input() sonographerReport: Boolean = false;
    @Input() publishedSharelinks: ShareLinks[]; // list of sharelinks published for a specific narrative
    @Input() publishedNarratives: ObstetricNarrative[]; // current narrative for the current sharelink

    pdfPathURL: string;

    constructor() {
        this.pdfPathURL = environment.appURL + environment.pathToPDFs;
        console.log('PDFURL:', this.pdfPathURL);
    }
}

