import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../material/app-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipes/pipes.module';

import { ReferralViewComponent } from './referral-view/referral-view.component';
import { ReferralEditComponent } from './referral-edit/referral-edit.component';
import { InputFocusDirective } from '../directives/input-focus.directive'

import { ValidationModule } from '../validation/validation.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    BrowserModule,
    RouterModule,
    FlexLayoutModule,
    ValidationModule,
    PipesModule
  ],
  declarations: [
    ReferralViewComponent,
    ReferralEditComponent,
    InputFocusDirective
  ],
  exports: [
    ReferralViewComponent,
    ReferralEditComponent,
    InputFocusDirective
  ]
})
export class ReferralsModule { }
