import { Pipe, PipeTransform } from '@angular/core';
import { CountryCodes } from '../models/patient';


@Pipe({ name: 'formatPhone' })
export class FormatPhone implements PipeTransform {
    /** function to remove spaces from phone numbers */
    static removePhoneSpaces(phoneNumber) {
        return phoneNumber.replace(/\s/g, '');
    }

    /** format phone number, remove + and 254 */
    static formatPhoneNumber(phoneNumber: string = '', countryCode: string = '254') {
        // remove spaces
        phoneNumber = phoneNumber.replace(/\s/g, '');

        // remove tel:
        phoneNumber = phoneNumber.replace(/tel:/, '');

        // remove the +
        if (phoneNumber.substr(0, 1) === '+') {
            phoneNumber = phoneNumber.slice(1);
        }

        // remove the country code from the phone number
        const countryCodes = CountryCodes.map(item => item.code);
        if (countryCodes.includes(phoneNumber.substr(0, 3))) {
            countryCode = phoneNumber.substr(0, 3);
            phoneNumber = phoneNumber.slice(3);
        }

        // remove 0 if full phone number like 0722123456 becomes 722123456
        if (phoneNumber.length > 9 && phoneNumber.substring(0, 1) === '0') {
            phoneNumber = phoneNumber.slice(1);
        }

        // add 0 to display phone if we have full number, 722123455 becomes 722123455, 2123456 becomes 02123456
        const shortPhone =
            phoneNumber.length === 9 && phoneNumber.substr(0, 1) === '7' ||
            phoneNumber.length === 9 && phoneNumber.substr(0, 1) === '1' ||
            phoneNumber.length === 7 && phoneNumber.substr(0, 1) === '2'
                ? '0' + phoneNumber
                : phoneNumber;

        return {
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            longPhone: '+' + countryCode + phoneNumber,
            shortPhone: shortPhone
        }
    }

    /** get phone number in display format */
    static displayPhoneNumber(phoneNumber: string, countryCode: string = '254', format: string = 'long'): string {
        const formattedNumber = FormatPhone.formatPhoneNumber(phoneNumber, countryCode);
        switch (format) {
            case 'long':
                return formattedNumber.longPhone;
                break;
            case 'short':
                return formattedNumber.shortPhone;
                break;
        }
    }

    /** use phone number formatter in pipe */
    transform(phoneNumber: string, countryCode: string = '254', format: string = 'long') {
        if (!phoneNumber) { return ''; }
        return FormatPhone.displayPhoneNumber(phoneNumber, countryCode, format);
    }

}
