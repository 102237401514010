import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class TransactionApiService {
  // HOSTAPI: string = 'http://localhost:3000';  // use local host while testing
  HOSTAPI: string = environment.paymentServer;

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  /** Call api to initiate a transaction */
  initiateTransaction(narrativeId: string, phoneNumber: string, amount: string): Promise<any> {
    return new Promise((resolve, reject) => {

      // set the request headers
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };

      const user = this.authenticationService.getUser();
      const paymentData = {
        narrativeId: narrativeId,
        mpesaNumber: phoneNumber,
        mpesaAmount: amount,
        user: {
          username: user.username,
          facility: user.facility
        }
      }

      this.httpClient.post(
        this.HOSTAPI + '/payments/' + narrativeId.slice(0, 10), paymentData, { headers: headers }).subscribe((res: any) => {
          if (res.status === 'error') {
            console.log('M-PESA ERROR:', res);
            if (res.error.address) {
              // database error
              reject({message: 'Error connecting to couch server, Please contact your administrator.'});
            } else if (res.error.error && res.error.error.errorCode !== '400.002.02') {
              reject({message: res.error.error.errorMessage, 'source': 'mpesa'});
            } else {
              reject({message: res.error.error.errorMessage});
            }
          } else {
            resolve(res.payment);
          }
        }, (err: HttpErrorResponse) => {
          console.log('SERVER ERROR:', err);
          reject({message: 'Unable to connect to payment server. Please try again later.'});
        });
    });
  }

  /** Call api to get status of a transaction */
  getTransaction(narrativeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.HOSTAPI + '/payments/' + narrativeId.slice(0, 10)).subscribe((res: any) => {
        if (res.status === 'error') {
          console.log('DATABASE ERROR:', res);
          reject({message: res.errorMessage});
        } else {
          resolve(res.payment);
        }
      }, (err: HttpErrorResponse) => {
        console.log('SERVER ERROR:', err);
        reject({message: 'Unable to connect to payment server. Please try again later.'});
      });
    });
  }
}
