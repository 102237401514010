import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockRecommendations } from '../../../../models/obstetric-sharelink';

@Component({
    selector: 'psoc-recommendations-sonographer-view',
    templateUrl: 'recommendations-sonographer-view.component.html'
})

export class RecommendationsSonographerViewComponent {
    @Input() sonographerData: SonographerBlockRecommendations
    @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;
    constructor() { }

    /**
     * function to check the validity of the recommendations block data
    */
    blockValid(): boolean {
        return this.sonographerData.recommendations && this.sonographerData.overallImpression ? true : false;
    }

    /**
     * function to trigger edit recommendations block
    */
   editBlock() {
       this.editCurrentBlock.emit();
   }

}
