import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient';
import { ShareLinks } from '../models/sharelinks';
import { MatPaginator } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-records-active-links',
  templateUrl: 'records-expired-links.component.html'
})
export class RecordsExpiredLinks implements OnInit {

  patient: Patient;
  sharelinks: ShareLinks[];
  allActiveLinks = new Array<ShareLinks>();
  allExpiredLinks = new Array<ShareLinks>();
  patientsIdWithExpiredLinks = new Array<Number>();
  patientsWithExpiredLinks = new Array<Patient>();

  // mat-table
  displayedColumns = ['firstname', 'lastname', 'dateOfBirth', 'sex', 'dateAdded'];
  patientsWithExpiredLinksDatabase = new PatientWithExpiredLinksDatabase(this.patientService);
  dataSource: PatientsWithExpiredLinksDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private patientService: PatientService
  ) { }

  ngOnInit() {
    this.dataSource = new PatientsWithExpiredLinksDataSource(this.patientsWithExpiredLinksDatabase, this.paginator);
  }
}

export class PatientWithExpiredLinksDatabase {
  allActiveLinks = new Array<ShareLinks>();
  allExpiredLinks = new Array<ShareLinks>();
  patientsIdWithExpiredLinks = new Array<Number>();
  patientsWithExpiredLinks = new Array<Patient>();
  dataChange: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  get data(): Patient[] { return this.dataChange.value; }
  sharelinks: ShareLinks[];

  constructor(
    private patientService: PatientService
  ) {
    this.getAllShareLinks();
  }

  // get all the saharelinks function
  getAllShareLinks() {
    this.patientService.getAllShareLinks().then((sharelinks: Array<ShareLinks>) => {
      this.sharelinks = sharelinks;

      // loop all sharelinks
      for (let i = 0; i <= this.sharelinks.length; i++) {
        if (this.sharelinks[i]) {
          if (this.sharelinks[i].expired === false) {
            // count active sharelinks
            this.allActiveLinks.push(this.sharelinks[i]);
            // console.log(JSON.stringify(this.allActiveLinks));
          } else {
            // count expired sharelinks
            this.allExpiredLinks.push(this.sharelinks[i]);
          }
        }
      }

      // get list of unique patient
      this.allExpiredLinks.forEach((sharelink: ShareLinks) => {
        if (!this.patientsIdWithExpiredLinks.includes(sharelink.patientId)) {
          this.patientsIdWithExpiredLinks.push(sharelink.patientId);
        };
      });

      // load patient info for each
      this.patientsIdWithExpiredLinks.forEach((patientid: number) => {
        this.patientService.getSinglePatient(patientid).then(patient => {
          // push this patient this patient to array
          console.log('Patient: ' + patient);
          this.patientsWithExpiredLinks.push(patient);

          // get count of sharelinks of this patient
          // var count = $filter('filter')(this.allExpiredLinks, {patientId: patientid}).length
          this.dataChange.next(this.patientsWithExpiredLinks);
        });
      });
    });
  }
}

export class PatientsWithExpiredLinksDataSource extends DataSource<Patient> {
  constructor(private _patientsWithExpiredLinksDatabase: PatientWithExpiredLinksDatabase, private _paginator: MatPaginator) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Patient[]> {
    const displayPatientChange = [
      this._patientsWithExpiredLinksDatabase.dataChange,
      this._paginator.page

    ];
    return merge(...displayPatientChange).pipe(map(() => {
      const data = this._patientsWithExpiredLinksDatabase.data.slice();
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() { }
}
