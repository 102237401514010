import { PouchObject } from './pouch-object';
import { truncateSync } from 'fs';

export class FieldSetting {
  show = false;
  required = false;
}

export class PatientFields {
  lastname: FieldSetting = new FieldSetting();
  schemename: FieldSetting = new FieldSetting();
  idnumber: FieldSetting = new FieldSetting();
  patientType: FieldSetting = new FieldSetting();
}

export class AppSettings implements PouchObject {
  public static type: string = 'settings';
  public static fields = ['_id', '_rev', '_deleted', 'type', 'dateAdded', 'dateUpdated', 'createdBy', 'updatedBy', 'createFacility',
    'showInsurerClientSearch', 'showEnquiriesView',
    'updateFacility', 'patientFields', 'narrativeSettings', 'specialistAppSettings', 'notificationSettings', 'autoExpireSharelinks'];

  _id: string;
  _rev: string;
  _deleted: boolean;
  type: string;
  dateAdded: Date;
  dateUpdated: Date;
  createdBy: String;
  updatedBy: String;
  createFacility: String;
  updateFacility: String;

  showInsurerClientSearch: boolean = false;
  showEnquiriesView: boolean = false;

  patientFields = new PatientFields();
  narrativeSettings = new NarrativeSettings();
  specialistAppSettings = new SpecialistAppSettings();
  notificationSettings = new NotificationSettings();

  autoExpireSharelinks = new AutoExpireSharelinks();

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class NarrativeSettings {
  eS_API_KEY: string;
  helpdesk: boolean = false;
  paymentModule: boolean = false;
  defaultReferral: string;
  openreferralblock: boolean;
  consultationFields: boolean;
  applyMultipleNarrativeFilter: boolean;
  obUSGNarrative = false;
  consultationNarrative = true;
  openCovid19Block: boolean;
}

export class SpecialistAppSettings {
  updateStatus: boolean = false;
  specialistReporting: boolean = true;
  dischargeReporting: boolean = false;
  enableVideoChat: boolean = false;
  clientId: string;
  clientSecret: string;
  allowNonICD10: boolean = false;
}

export class NotificationSettings {
  notifyPatientAdded = new NotificationSetting;
  notifyNarrativeStatusChanged = new NotificationSetting;
  notifySharelinkAdded = new NotificationSetting;
  notifyCaseNotActioned = new NotificationSetting;
  notifyReportPublished = new NotificationSetting;
  notifyAppointmentScheduled = new NotificationSetting;
}
export class NotificationSetting {
  recipientsByRole = {
    admins: new NotificationType,
    createFacilityMediators: new NotificationType,
    referralFacilityMediators: new NotificationType,
    consultationSpecialist: new NotificationType,
    patients: new NotificationType
  }
}
export class NotificationType {
  email: boolean;
  sms: boolean;
}

export class AutoExpireSharelinks {
  autoExpire: boolean = true;
  criteria: {
    checkHelpdeskCase: boolean;
  }
}
