import { Routes } from '@angular/router';
import { AddShareLinksComponent } from '../app/sharelinks/add/add-sharelinks.component';
import { PatientsComponent } from '../app/patients/patients.component';
import { PatientEditComponent } from '../app/patients/edit/patient-edit.component';
import { PatientViewComponent } from '../app/patients/view/patient-view.component';
import { NarrativeComponent } from '../app/narrative/narrative.component';
import { NarrativeListComponent } from '../app/narrative/list/narrative-list.component';
import { PatientNarrativeListComponent } from '../app/narrative/list/patient-narrative-list.component';

import { ReportViewComponent } from '../app/narrative/shared-links/report-view.component';
import { DischargeSummaryViewComponent } from '../app/narrative/shared-links/discharge-summary-view.component';
import { LoginComponent } from '../app/auth/login.component.web';
import { RecoverComponent } from '../app/auth/recover.component';
import { LocatorComponent } from '../app/auth/locator.component';
import { ProfileComponent } from '../app/auth/profile.component';
import { UnsavedChangesGuard } from '../app/services/unsaved-changes.guard';
import { ChatComponent } from '../app/chat/chat.component';
import { AppointmentsComponent } from '../app/appointments/appointments.component';
import { TopNavComponent } from '../app/navigation/top-nav.component';
import { AuthGuard } from '../app/services/auth.guard';
import { SonographerReportViewComponent } from '../app/narrative/obstetric/report/sonographer-report-view.component';
import { EnquiriesListComponent } from '../app/patients/enquiries-list/enquiries-list.component';

export const AppRoutingModule: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '',
    outlet: 'topnav',
    component: TopNavComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'locator/:contact',
    component: LocatorComponent
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'enquiries',
    component: EnquiriesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients',
    component: PatientsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/fromenquiry/:enquiryId',
    component: PatientEditComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/edit/:id',
    component: PatientEditComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/view/:id',
    component: PatientViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: ':patient/new',
    component: PatientEditComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/:patientid/narrative/:narrativeid',
    component: NarrativeComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/:patientid/narratives',
    component: PatientNarrativeListComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/:patientid/narrative/:action/:type',
    component: NarrativeComponent,
    // canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/:patientid/narrative/:narrativeid/:action/:type',
    component: NarrativeComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'patient/:patientid/narrative/:narrativeid/sharelink/:sharelinkid/report',
    component: SonographerReportViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'narratives',
    component: NarrativeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'narrative/report/:id',
    component: ReportViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'narrative/discharge-summary/:id',
    component: DischargeSummaryViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'narrative/discharge-summary/:id',
    component: DischargeSummaryViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'sharelinks/:id',
    component: AddShareLinksComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'report/:id',
    component: ReportViewComponent,
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'appointment/:id',
    component: AppointmentsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    component: LoginComponent
  },
]
