import { Component, ElementRef, ChangeDetectorRef, OnInit, AfterViewChecked, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatRadioChange,
  MatSnackBar, MatSort, MAT_DIALOG_DATA
} from '@angular/material';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { map } from 'rxjs/operators';

import { RepositoryService, Databases } from '../../services/repository.service';
import { PatientService } from '../../services/patient.service';

import { AppSettings } from '../../models/config';
import { Patient } from '../../models/patient';
import { Enquiry } from '../../models/enquiry';
import { NarrativeEnumTypes, NarrativeInterface } from '../../models/narrative-types';
import { Narrative } from '../../models/narrative';
import { ObstetricNarrative } from '../../models/obstetric-narrative';
import { Attachment } from '../../models/attachment';
import { ShareLinks } from '../../models/sharelinks';
import { Appointment } from '../../models/appointment';
import { Chat } from '../../models/chat';
import { NarrativeService } from '../../services/narrative.service';
import { AttachmentService } from '../../services/attachment.service';
import { AppointmentService } from '../../services/appointment.service';
import { ChatService } from '../../services/chat.service';
import { InsuranceClient } from '../../models/insurance-client';
import moment from 'moment';

@Component({
  selector: 'psoc-confirm-merge-fix-dialog-component',
  template: `<h2>Confirm Merge Records</h2> 
  <p>The selected data and narratives will be merged <br />
  into the oldest record for this patient. <br />
  Duplicate records will not be visible anymore. </p>
  <p><b>Notice:</b> This action is not reversible.</p>
  <p>
    <button type="button" mat-raised-button color="primary" class="uk-margin-right uk-align-right"
      (click)="dialogRef1.close('merge')">Confirm</button>
    <button type="button" mat-raised-button color="warn" class="uk-margin-right uk-align-right"
      (click)="dialogRef1.close()">Cancel</button>
  </p>`
})
export class ConfirmMergeFixPatientDialogComponent {
  constructor(public dialogRef1: MatDialogRef<any>) { }
}

export enum PatientValues {
  idnumber = 'Registration #',
  firstname = 'First Name',
  lastname = 'Last Name',
  othernames = 'Other Names',
  schemename = 'Scheme Name',
  age = 'Age',
  dateOfBirth = 'Date of Birth',
  sex = 'Sex',
  phonenumber = 'Phone Number',
  phonenumber2 = 'Phone Number2'
}

@Component({
  selector: 'psoc-merge-fix-patient-component',
  providers: [RepositoryService, PatientService],
  templateUrl: 'merge-fix-patient.component.html'
})

export class MergeFixPatientDialogComponent implements OnInit, AfterViewChecked {
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // snackBar: MatSnackBar;

  @ViewChild('confirmMergeFixPatientDialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;
  dialogRef1: MatDialogRef<any>;

  appSettings: AppSettings;
  // inputs from main component
  patient: Patient;
  patients: Patient[] = [];

  loadedDuplicates: boolean = false;

  filteredPatients: Patient[] = []; // add a tag duplicate = true;
  filteredEnquiries: Enquiry[] = [];
  filteredNarratives: Array<NarrativeInterface> = [];
  filteredAttachments: Attachment[] = [];
  filteredShareLinks: ShareLinks[] = [];
  filteredAppointments: Appointment[];
  filteredChats: Chat[] = [];

  enquiries: Enquiry[];
  loadingEnquiries: boolean;

  narratives: any[];
  loadingNarratives: boolean;

  attachments: Attachment[];
  loadingAttachments: boolean;

  shareLinks: ShareLinks[];
  loadingShareLinks: boolean;

  appointments: Appointment[];
  loadingAppointments: boolean;

  chats: Chat[];
  loadingChats: boolean;

  duplicatePatientsDatabase: DuplicatePatientsDatabase;
  dataSource: DuplicatePatientsDataSource | null;

  displayedColumns: string[];
  patientColumns = this.repositoryService.enumSelector(PatientValues);
  fixPatientForm: FormGroup;

  loadedOldestPatient: boolean = false;
  loadedPatientsData: boolean = false;

  patientIds: number[];

  updatingPatient: boolean = false;
  enquiryUpdated: boolean = false;
  narrativeUpdated: boolean = false;
  attachmentUpdated: boolean = false;
  shareLinkUpdated: boolean = false;
  appointmentUpdated: boolean = false;
  chatUpdated: boolean = false;

  duplicatePatientUpdated: boolean = false;
  patientsObjectsUpdated: boolean = false;
  mergingData: boolean = false;
  displayedSubHeaderColumns: string[];
  selectedClient: InsuranceClient;
  fromMatch: boolean = false;


  constructor(public dialogRef: MatDialogRef<any>,
    public dialog1: MatDialog,
    private repositoryService: RepositoryService,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private attachmentService: AttachmentService,
    private appointmentService: AppointmentService,
    private chatService: ChatService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef
  ) {
    // set up patients database
    this.duplicatePatientsDatabase = new DuplicatePatientsDatabase();

    this.fixPatientForm = this.formBuilder.group({
      'idnumber': ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      'firstname': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      'lastname': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'othernames': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'schemename': ['', [Validators.minLength(2), Validators.maxLength(255)]],
      'age': [null, [Validators.minLength(1)]],
      'dateOfBirth': [null],
      'sex': [''],
      'phonenumber': ['', [Validators.minLength(10), Validators.maxLength(10),
      Validators.pattern('[0-9]*')]],
      'phonenumber2': ['', [Validators.minLength(10), Validators.maxLength(10),
      Validators.pattern('[0-9]*')]]
    });
  }


  ngOnInit() {
    // set up patients data source
    this.dataSource = new DuplicatePatientsDataSource(this.duplicatePatientsDatabase);
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  public setServices(patientService: PatientService, snackBar: MatSnackBar, appSettings: AppSettings, narrativeService: NarrativeService,
    attachmentService: AttachmentService, appointmentService: AppointmentService, chatService: ChatService,
    repositoryService: RepositoryService) {
    this.patientService = patientService;
    this.appSettings = appSettings;
    this.snackBar = snackBar;
    this.narrativeService = narrativeService;
    this.attachmentService = attachmentService;
    this.appointmentService = appointmentService;
    this.chatService = chatService;
    this.repositoryService = repositoryService;

    if (!this.appSettings.patientFields.schemename) {
      this.patientColumns = this.patientColumns.filter(column => column.value === 'schemename');
    }
  }

  public setClient(client: InsuranceClient = null, fromMatch: boolean = false): void {
    this.selectedClient = client;
    this.fromMatch = fromMatch;
  }

  public setOldestPatient(patient: Patient): void {
    this.patient = patient;
    this.loadedOldestPatient = true;
    this.setFixPatientForm(this.patient, this.selectedClient, this.fromMatch);
  }

  public setData(patients: Patient[]): void {
    this.patients = patients;

    this.filteredPatients = patients.filter(item => item.id !== this.patient.id);

    this.patientIds = this.patients.map(patient => patient.id);

    this.getPatientsEnquiries(this.patientIds);
    this.getPatientNarratives(this.patientIds);
    this.getPatientsAttachments(this.patientIds);
    this.getPatientsShareLinks(this.patientIds);
    this.getPatientsAppointments(this.patientIds);
    this.getPatientsChats(this.patientIds);

    this.loadedDuplicates = true;
  }

  /**
   * get list of patients and their narratives 
   * and transform into columns
   * load the transformed data into the list
   */
  getPatientsData() {
    this.displayedColumns = ['Patient Id'].concat(this.patients.map(x => x._id));
    this.displayedSubHeaderColumns = ['Date Added'].concat(this.patients
      .map(p => moment(p.dateAdded).format('MMM DD YYYY, h:mm:ss a')));

    const pColumns = this.patientColumns.map(c => c.title);

    // get the unique indices of the patients narratives
    const nColumns = this.getUniqueIndices(this.patientIds, this.narratives)

    // transform the data (rows to columns)
    const patientData: any[] = pColumns.map(pColumn => this.formatInputRow(pColumn, this.patients, Patient.type));
    const narrativeData: any[] = nColumns.map(nColumn => this.formatInputRow(nColumn, this.narratives, Narrative.type));

    // concat the data(patients and respective narratives)
    const transformedData = patientData.concat(narrativeData);

    this.duplicatePatientsDatabase.data = transformedData;

    this.loadedPatientsData = true;
  }
  /**
    * get patients enquiries
    * @param {number[]} patientIds list of patients ids
    */
  getPatientsEnquiries(patientIds: number[]) {
    this.loadingEnquiries = true;
    this.getEnquiriesByPatientIds(patientIds)
      .then(enquiries => {
        this.enquiries = enquiries;
        this.filteredEnquiries = enquiries.filter(item => item.patientId !== this.patient.id);
        this.loadingEnquiries = false
      }).catch(error => {
        console.log('ERROR: Loading Enquiries list:', error);
        this.loadingEnquiries = true
      });
  }

  /**
   * get patients narratives
   * @param {number[]} patientIds list of patients ids
   */
  getPatientNarratives(patientIds: number[]): void {
    this.loadingNarratives = true;
    this.getNarrativesByPatientIds(patientIds)
      .then(narratives => {
        this.narratives = narratives.map((narrative: NarrativeInterface) => {
          if (narrative.narrativeType === NarrativeEnumTypes.obstetric) {
            narrative = new ObstetricNarrative(narrative);
          } else {
            narrative = new Narrative(narrative);
          }
          return narrative;
        });
        this.getPatientsData(); // transform the patients and their narratives into columns and load to the list
        this.filteredNarratives = this.narratives.filter(item => item.patientId !== this.patient.id);
        this.loadingNarratives = false;
      }).catch(error => {
        console.log('ERROR: Loading Narratives list', error);
        this.loadingNarratives = true
      });
  }

  /**
   * get patients attachments
   * @param {number[]} patientIds list of patients ids
   */
  getPatientsAttachments(patientIds: number[]) {
    this.loadingAttachments = true;
    this.getAttachmentsByPatientIds(patientIds)
      .then(attachments => {
        this.attachments = attachments;

        this.filteredAttachments = attachments.filter(item => item.patientId !== this.patient.id);
        this.loadingAttachments = false
      }).catch(error => {
        console.log('ERROR: Loading Attachments list', error);
        this.loadingAttachments = true
      });
  }

  /**
   * get patients shareLinks
   * @param {number[]} patientIds list of patients ids
   */
  getPatientsShareLinks(patientIds: number[]) {
    this.loadingShareLinks = true;
    this.getShareLinksByPatientIds(patientIds)
      .then(shareLinks => {
        this.shareLinks = shareLinks;

        this.filteredShareLinks = shareLinks.filter(item => item.patientId !== this.patient.id);
        this.loadingShareLinks = false
      }).catch(error => {
        console.log('ERROR: Loading ShareLinks list', error);
        this.loadingShareLinks = true
      });
  }

  /**
   * get patients appointments
   * @param {number[]} patientIds list of patients ids
   */
  getPatientsAppointments(patientIds: number[]) {
    this.loadingAppointments = true;
    this.getAppointmentsByPatientIds(patientIds)
      .then(appointments => {
        this.appointments = appointments;

        this.filteredAppointments = appointments.filter(item => item.patientId !== this.patient.id);
        this.loadingAppointments = false
      }).catch(error => {
        console.log('ERROR Loading Appointments list:', error);
        this.loadingAppointments = true
      });
  }

  /**
   * get patients chats
   * @param {number[]} patientIds list of patients ids
   */
  getPatientsChats(patientIds: number[]) {
    this.loadingChats = true;
    this.getChatsByPatientIds(patientIds)
      .then(chats => {
        this.chats = chats;
        this.filteredChats = chats.filter(item => item.patientId !== this.patient.id);
        this.loadingChats = false
      }).catch(error => {
        console.log('ERROR: Loading Chats list', error);
        this.loadingChats = true
      });
  }

  // get patients' enquiries
  getEnquiriesByPatientIds(patientIds: number[]): Promise<Enquiry[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(Enquiry.type, patientIds, Databases.mainDb)
        .then(result => {
          const enquiries: Enquiry[] = result.docs.map((doc: any) => this.patientService.mapObjectToEnquiry(doc));
          resolve(enquiries as Enquiry[]);
        }).catch(error => {
          console.error('An error occurred in patients enquiry fetchObjects', error);
          reject(error);
        });

    });
  }

  // get patients' narratives
  getNarrativesByPatientIds(patientIds: number[]): Promise<NarrativeInterface[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(Narrative.type, patientIds, Databases.mainDb)
        .then((result) => {
          const narratives: NarrativeInterface[] = result.docs.map((doc: any) => this.narrativeService.mapObjectToNarrative(doc));
          resolve(narratives as NarrativeInterface[]);
        }).catch(error => {
          console.error('An error occurred in patients narrative fetchObjects', error);
          reject(error);
        });
    });
  }

  // get patients' attachments
  getAttachmentsByPatientIds(patientIds: number[]): Promise<Attachment[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(Attachment.type, patientIds, Databases.mainDb).then(result => {
        const attachments: Attachment[] = result.docs.map((doc: any) => this.attachmentService.mapObjectToAttachment(doc));
        resolve(attachments as Attachment[]);
      }).catch(error => {
        console.error('An error occurred in patients narrative fetchObjects', error);
        reject(error);
      });
    });
  }

  // get patients' sharelinks
  getShareLinksByPatientIds(patientIds: number[]): Promise<ShareLinks[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(ShareLinks.type, patientIds, Databases.sharelinksDb)
        .then((result) => {
          const shareLinks: ShareLinks[] = result.docs.map((doc: any) => this.patientService.mapObjectToShareLink(doc));
          resolve(shareLinks as ShareLinks[]);
        }).catch(error => {
          console.error('An error occurred in patients shareLink fetchObjects', error);
          reject(error);
        });
    });
  }

  // get patients' appointments
  getAppointmentsByPatientIds(patientIds: number[]): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(Appointment.type, patientIds, Databases.appointmentsDb).then(result => {
        const appointments: Appointment[] = result.docs.map((doc: any) => this.appointmentService.mapObjectToAppointment(doc));
        resolve(appointments as Appointment[]);
      }).catch(error => {
        console.error('An error occurred in patients appointment fetchObjects', error);
        reject(error);
      });
    });
  }

  // get patients' chats
  getChatsByPatientIds(patientIds: number[]): Promise<Chat[]> {
    return new Promise((resolve, reject) => {
      this.repositoryService.fetchObjectsByPatients(Chat.type, patientIds, Databases.chatsDb).then(result => {
        const chats: Chat[] = result.docs.map((doc: any) => this.chatService.mapObjectToChat(doc));
        resolve(chats as Chat[]);
      }).catch(error => {
        console.error('An error occurred in patients chat fetchObjects', error);
        reject(error);
      });
    });
  }

  /**
   * function to set the patient form default selected oldest patient
   */
  setFixPatientForm(selectedPatient: Patient, selectedClient: InsuranceClient = null, fromMatch: boolean) {
    const selectedRegistrationNumber = fromMatch ? selectedClient.registrationNumber : selectedPatient.idnumber;
    this.fixPatientForm.patchValue({
      'idnumber': selectedRegistrationNumber,
      'firstname': selectedPatient.firstname,
      'lastname': selectedPatient.lastname,
      'othernames': selectedPatient.othernames,
      'schemename': selectedPatient.schemename,
      'age': selectedPatient.age,
      'dateOfBirth': selectedPatient.dateOfBirth,
      'sex': selectedPatient.sex,
      'phonenumber': selectedPatient.phonenumber,
      'phonenumber2': selectedPatient.phonenumber2
    });
  }

  /**
   * function used to ensure age and date of birth are selected from the same patient
   * @param {Event } e MatRadioChange event
   * @param {string} patientId patient id of the selected data
   */
  onSelectionChange(e: MatRadioChange, patientId: string) {
    const selectedPatient = this.patients.find(p => p._id === patientId)
    if (e.source.name === 'dateOfBirth') {
      this.fixPatientForm.patchValue({ 'age': selectedPatient.age });
    } else if (e.source.name === 'age') {
      this.fixPatientForm.patchValue({ 'dateOfBirth': selectedPatient.dateOfBirth });
    } else {
      return; // do nothing
    }
  }

  cancel() {
    // return nothing
    this.dialogRef.close();
  }

  updatePatient(): void {
    if (this.fixPatientForm.valid) {
      this.updatingPatient = true;
      // update the oldest duplicate patient to the database
      // save demographic data
      this.patientService.updatePatient(this.patient)
        .then((updatedPatient) => {
          // this.patient = updatedPatient;
          // merge patients data and tag duplicate patients with duplicate = true
          this.updatePatientObjects(updatedPatient);

          this.updatingPatient = false;
          this.mergingData = this.patientsObjectsUpdated;
          if (this.mergingData) {
            // close dialog and navigate to the patients duplicate list or the patients narrative list
            this.snackBar.open('Duplicate patients records merged', 'Success', { duration: 6000 });
            this.dialogRef.close('navigate');
          }
        }).catch(error => {
          console.log('Error:', error);
          this.updatingPatient = true;
          this.snackBar.open('Error updating the patient record data, try again', 'Error', { duration: 6000 });
        });
    } else {
      this.snackBar.open('Please correct the form errors above', 'Error', { duration: 6000 })
    }
  }

  /**
   * update every patients object with the oldest patients id
   * update one type of object at a time
   * @param {Patient} updatedPatient fixed patient object
   */
  updatePatientObjects(updatedPatient: Patient): void {
    // update enquiries
    const enquiryStatuses = this.filteredEnquiries.map(enquiry => {
      enquiry.patientId = updatedPatient.id;
      this.patientService.updateEnquiry(enquiry)
        .then(() => {
          this.enquiryUpdated = true;
          console.log('Enquiry updated id:', enquiry._id);
        }).catch(error => {
          console.log('Error:', error);
          this.enquiryUpdated = false;
          this.snackBar.open('Error updating the enquiry record, please try again', 'Error', { duration: 6000 });
        });
      return this.enquiryUpdated;
    });
    const enquiryStatus = this.filteredEnquiries.length > 0 ? enquiryStatuses.includes(false) : true;
    // update narratives;
    const narrativeStatuses = this.filteredNarratives.map(narrative => {
      narrative.patientId = updatedPatient.id;
      if (narrative.narrativeType === NarrativeEnumTypes.obstetric) {
        const newNarrative = new ObstetricNarrative(narrative);
        this.narrativeService.updateObstetricNarrative(newNarrative)
          .then(() => {
            this.narrativeUpdated = true;
            console.log('Narrative updated id:', narrative._id);
          }).catch(error => {
            console.log('Error:', error);
            this.narrativeUpdated = false;
            this.snackBar.open('Error updating the narrative record, please try again', 'Error', { duration: 6000 });
          });
      } else {
        const newNarrative = new Narrative(narrative);
        this.narrativeService.updateNarrative(newNarrative)
          .then(() => {
            console.log('Narrative updated id:', narrative._id);
          }).catch(error => {
            console.log('Error:', error);
            this.snackBar.open('Error updating the narrative record, please try again', 'Error', { duration: 6000 });
          });
      }
      return this.narrativeUpdated;
    });
    const narrativeStatus = this.filteredNarratives.length > 0 ? narrativeStatuses.includes(false) : true;

    // update attachments
    const attachmentStatuses = this.filteredAttachments.map(attachment => {
      attachment.patientId = updatedPatient.id;
      this.attachmentService.updateAttachment(attachment)
        .then(() => {
          this.attachmentUpdated = true;
          console.log('Attachment updated id:', attachment._id);
        }).catch(error => {
          console.log('Error:', error);
          this.attachmentUpdated = false;
          this.snackBar.open('Error updating the attachment record, please try again', 'Error', { duration: 6000 });
        });
      return this.attachmentUpdated;
    });
    const attachmentStatus = this.filteredAttachments.length > 0 ? attachmentStatuses.includes(false) : true;

    // update shareLinks
    const shareLinkStatuses = this.filteredShareLinks.map(shareLink => {
      shareLink.patientId = updatedPatient.id;
      this.patientService.updateShareLink(shareLink)
        .then(() => {
          this.shareLinkUpdated = true;
          console.log('ShareLink updated id:', shareLink._id);
        }).catch(error => {
          console.log('Error:', error);
          this.shareLinkUpdated = false;
          this.snackBar.open('Error updating the shareLink record, please try again', 'Error', { duration: 6000 });
        });
      return this.attachmentUpdated;
    });
    const shareLinkStatus = this.filteredShareLinks.length > 0 ? shareLinkStatuses.includes(false) : true;

    // update appointments
    const appointmentStatuses = this.filteredAppointments.map(appointment => {
      appointment.patientId = updatedPatient.id;
      this.appointmentService.updateAppointment(appointment)
        .then(() => {
          this.appointmentUpdated = true;
          console.log('Appointment updated id:', appointment._id);
        }).catch(error => {
          console.log('Error:', error);
          this.appointmentUpdated = false;
          this.snackBar.open('Error updating the appointment record, please try again', 'Error', { duration: 6000 });
        });
      return this.attachmentUpdated;
    });
    const appointmentStatus = this.filteredAppointments.length > 0 ? appointmentStatuses.includes(false) : true;

    // update chats
    const chatStatuses = this.filteredChats.map(chat => {
      chat.patientId = updatedPatient.id;
      this.chatService.updateChat(chat)
        .then(() => {
          this.chatUpdated = true;
          console.log('Chat updated id:', chat._id);
        }).catch(error => {
          console.log('Error:', error);
          this.chatUpdated = false;
          this.snackBar.open('Error updating the chat record, please try again', 'Error', { duration: 6000 });
        });
      return this.attachmentUpdated;
    });
    const chatStatus = this.filteredChats.length > 0 ? chatStatuses.includes(false) : true;

    const status = (enquiryStatus && narrativeStatus && attachmentStatus && shareLinkStatus && appointmentStatus && chatStatus);
    if (status) {
      const duplicatePatientsStatuses = this.filteredPatients.map((patient: Patient) => {
        patient.duplicate = true;
        this.patientService.updatePatient(patient)
          .then(() => {
            this.duplicatePatientUpdated = true;
            console.log('Duplicate patient updated id:', patient._id);
          }).catch((error) => {
            console.log('Error:', error);
            this.duplicatePatientUpdated = false;
            this.snackBar.open('Error updating the duplicate patient record, please try again', 'Error', { duration: 6000 });
          })
        return this.duplicatePatientUpdated;
      });

      const duplicatePatientsStatus = duplicatePatientsStatuses.includes(false);
      this.patientsObjectsUpdated = duplicatePatientsStatus && status;
    } else {
      this.patientsObjectsUpdated = status;
      const snackBarRef = this.snackBar.open('Unable to update patient objects, please try again', 'Retry');
      snackBarRef.onAction().subscribe(() => {
        this.updatePatientObjects(updatedPatient);
      });
    }
  }

  confirmMerge(model: any) {
    // const config: MatDialogConfig = { width: '95vw', height: '95vh', viewContainerRef: this.dialogAnchor };
    const config: MatDialogConfig = { viewContainerRef: this.dialogAnchor };
    this.dialogRef1 = this.dialog1.open(ConfirmMergeFixPatientDialogComponent, config);

    // set patient list narratives
    this.dialogRef1.afterClosed().subscribe(result => {
      // if result, save demographic data and merge patient data
      if (result === 'merge') {
        this.mergingData = true;
        this.patient = this.onSubmitMerge(model);
        this.updatePatient();
      }
      this.dialogRef1 = null;
    });
  }

  /** function submit merge results */
  onSubmitMerge(model: any): Patient {
    if (this.fromMatch) {
      this.patient.idnumber = this.selectedClient.registrationNumber ? this.selectedClient.registrationNumber : model.idnumber;
      this.patient.schemename = this.selectedClient.schemeName ? this.selectedClient.schemeName : model.schemename;
    } else {
      this.patient.idnumber = (this.patient.idnumber !== '' && model.idnumber === '') ? this.patient.idnumber : model.idnumber;
      this.patient.schemename = (this.patient.schemename !== '' && model.schemename === '') ? this.patient.schemename : model.schemename;
    }
    this.patient.firstname = (this.patient.firstname !== '' && model.firstname === '') ? this.patient.firstname : model.firstname;
    this.patient.lastname = (this.patient.lastname !== '' && model.lastname === '') ? this.patient.lastname : model.lastname;
    this.patient.othernames = (this.patient.othernames !== '' && model.othernames === '') ? this.patient.othernames : model.othernames;
    this.patient.age = (this.patient.age !== null && model.age === null) ? this.patient.age : model.age;
    this.patient.dateOfBirth = (this.patient.dateOfBirth !== null && model.dateOfBirth === null)
      ? this.patient.dateOfBirth : model.dateOfBirth;
    this.patient.sex = (this.patient.sex !== '' && model.sex === '') ? this.patient.sex : model.sex;
    this.patient.phonenumber = (this.patient.phonenumber !== '' && model.phonenumber === '')
      ? this.patient.phonenumber : model.phonenumber;
    this.patient.phonenumber2 = (this.patient.phonenumber2 !== '' && model.phonenumber2 === '')
      ? this.patient.phonenumber2 : model.phonenumber2;
    return this.patient;
  }

  /**
   * transpose row into columns
   */
  formatInputRow(row, data, type: string) {
    const output = {};

    output['title'] = row;
    output['type'] = type;

    if (type !== Patient.type) {

      if (row > 0) {
        output['Patient Id'] = '';
      } else {
        output['Patient Id'] = 'Narratives';
      }
      this.patientIds.forEach((patientId: number) => {
        // get the patient narratives
        const patientNarratives = data.filter(item => item.patientId === patientId);
        // find narrative whose index === row
        const narrative = patientNarratives.find((item, i) => i === row);
        // set up output (output[patientId] = element._id | '')
        output[patientId] = narrative !== undefined ? narrative._id : '';
      });
    } else {
      output['Patient Id'] = PatientValues[row];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        output[element._id] = element[row] !== undefined ? element[row] : '';
      }
    }
    return output;
  }

  /**
   * get the unique indices of the patients narratives
   */
  getUniqueIndices(patientIds: number[], data: NarrativeInterface[]): number[] {
    const result = {};
    patientIds.map(patientId => {
      result[patientId] = data.filter(n => n.patientId === patientId).map(n1 => { return n1._id });
    });

    const patientNarrativesIndices: number[] = [];
    Object.keys(result).forEach((patientId) => {
      const patientNarratives: string[] = result[patientId];
      const indices = patientNarratives.map((pn, i) => { return i });
      patientNarrativesIndices.push(...indices);
      return patientNarrativesIndices;
    });

    const uniqueIndices: number[] = patientNarrativesIndices.filter((v, i, a) => a.indexOf(v) === i);
    return uniqueIndices;
  }

}
export class DuplicatePatientsDatabase {
  dialogRef: MatDialogRef<any>;
  @ViewChild('filter') filter: ElementRef
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }
  set data(patients: any[]) { this.dataChange.next(patients); }
  constructor() { }
}

export class DuplicatePatientsDataSource extends DataSource<any> {
  renderedData: any[] = [];

  constructor(private _duplicatePatientsDataBase: DuplicatePatientsDatabase) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayPatientDataChanges = [
      this._duplicatePatientsDataBase.dataChange,
    ];

    return merge(...displayPatientDataChanges).pipe(map(() => {
      this.renderedData = this._duplicatePatientsDataBase.data.slice();
      return this.renderedData;
    }));

  }

  disconnect() { }
}
