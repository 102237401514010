import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../services/repository.service';
import { EventsService } from '../services/events.service';
import { Events } from '../models/events';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NotificationStatus } from '../models/notification-status';
import { NotificationStatusService } from '../services/notification-status.service';

@Component({
  selector: 'psoc-app-events',
  templateUrl: 'events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  events: Events[];
  displayedColumns = ['patientName', '_id', 'event', 'dateAdded', 'status', 'smsNotify', 'emailNotify'];
  eventsDatabase: EventsDatabase;
  dataSource: EventsDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filterByName') filterByName;

  eventsObjectId: string; // id of object for which we wish to load data
  maxDate: Date = new Date();
  startDate: Date = new Date();
  endDate: Date = new Date();

  eventsTypeFilter: string[] = ['added_patients', 'added_sharelinks', 'added_narrative', 'updated_narrative', 'statusUpdated_narrative',
    'published_report'];
  nameFilter: string;
  eventsStatusFilter: string;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private repositoryService: RepositoryService,
    private notificationStatusService: NotificationStatusService
  ) { }

  ngOnInit() {
    this.eventsObjectId = this.route.snapshot.params['id'] || null;

    this.eventsDatabase = new EventsDatabase(this.snackBar, this.eventsService, this.repositoryService, this.eventsObjectId,
      this.notificationStatusService);
    this.dataSource = new EventsDataSource(this.eventsDatabase, this.paginator);

    // if we've been asked to filter by a given element, filter
    if (this.eventsObjectId) {
      const filterValue = this.route.snapshot.params['id'];
      this.applyFilter(filterValue);
    } else {
      this.applyEventFilter(this.eventsTypeFilter);
    }

  }

  // mark current event as processed and create new copy with new metadata
  retryEvent(event: Events) {
    // mark current event as processed
    event.failed = false;
    event.processed = false;
    this.repositoryService.updateEvent(event).then(updated => {
      this.eventsDatabase.getEvents();
    }).catch(error => { console.log(error) })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /** function filter events by event type */
  applyEventFilter(val: string[]) {
    if (val.length < 1) {
      this.dataSource.filterEventsType = [];
    } else {
      this.dataSource.filterEventsType = val;
    }
  }

  /** function filter events by patient name */
  applyNameFilter(val: string) {
    val = val.trim().toLowerCase(); // Remove whitespace & covert to lowercase
    this.dataSource.filterEventsByPatientName = val;
  }

  /** function filter events by event status */
  applyEventStatusFilter(val: string) {
    val = val.trim().toLowerCase(); // Remove whitespace & covert to lowercase
    this.dataSource.filterEventsByStatus = val;
  }

  /** function filter by date range */
  applyDateFilter() {
    this.dataSource.filterStartDate = this.startDate ? this.startDate.toString() : new Date().toString();
    this.dataSource.filterEndDate = this.endDate ? this.endDate.toString() : new Date().toString();
    this.paginator.firstPage();
  }

  /** close/hide the keyboard */
  hideKeyboard() {
    this.filterByName.filterInput.nativeElement.blur();
  }

  /** Function clear all filters in form */
  clearFilters() {
    this.eventsTypeFilter = [];
    this.nameFilter = '';
    this.eventsStatusFilter = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.paginator.firstPage();

    // clear data filter
    this.dataSource.filterEventsType = [];
    this.dataSource.filterEventsByPatientName = '';
    this.dataSource.filterEventsByStatus = '';
    this.dataSource.filterStartDate = '';
    this.dataSource.filterEndDate = '';
  }
}

export class EventsDatabase {
  events: Events[];
  dataChange: BehaviorSubject<Events[]> = new BehaviorSubject<Events[]>([]);
  loading: boolean = false;
  loadingStatuses: boolean = false;
  notificationStatus: NotificationStatus[];

  get data(): Events[] { return this.dataChange.value; }

  constructor(
    private snackBar: MatSnackBar,
    private eventsService: EventsService,
    private repositoryService: RepositoryService,
    private objectId: string,
    private notificationStatusService: NotificationStatusService
  ) {
    this.getEvents();
  }

  getEvents(): void {
    console.log('getting events for object id', this.objectId);
    this.loading = true;
    this.loadingStatuses = true;

    // if we have an object id only load files for that object
    if (this.objectId) {
      this.eventsService.getEventsByObjectId(this.objectId).then((events: Array<Events>) => {
        this.dataChange.next(events);
        this.loading = false;
        this.notificationStatusService.getAllNotificationStatus()
          .then(statuses => {
            this.loadingStatuses = false;
            this.notificationStatus = statuses;

            // merge the events and notifications statuses
            const eventsWithStatuses = events.map((e: EventsWithStatuses) => {
              e.eventType = (e.event + '_' + e.objectType);
              const statusList = statuses.filter(s => s.eventId === e._id);
              const status = statusList[statusList.length - 1];
              e.patientName = status ? status.patientName : '';
              e.sms = status ? status.sms : [];
              e.emails = status ? status.emails : [];
              return e;
            });

            this.dataChange.next(eventsWithStatuses);
          }).catch(error => {
            console.log(error);
            this.loadingStatuses = false;
            this.snackBar.open('Error getting notifications status', 'Error', { duration: 6000 });
          });
      }).catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error loading object events', 'Error', { duration: 6000 });
      });

    } else {
      this.eventsService.getEvents().then((events: Array<Events>) => {
        this.loading = false;
        this.notificationStatusService.getAllNotificationStatus()
          .then(statuses => {
            this.loadingStatuses = false;
            this.notificationStatus = statuses;

            // merge the events and notifications statuses
            const eventsWithStatuses = events.map((e: EventsWithStatuses) => {
              e.eventType = (e.event + '_' + e.objectType);
              const status = statuses.find(s => s.eventId === e._id);
              e.patientName = status ? status.patientName : '';
              e.sms = status ? status.sms : [];
              e.emails = status ? status.emails : [];
              return e;
            });

            this.dataChange.next(eventsWithStatuses);
          }).catch(error => {
            console.log(error);
            this.loadingStatuses = false;
            this.snackBar.open('Error getting notifications status', 'Error', { duration: 6000 });
          });
      }).catch(error => {
        console.log('Error:', error);
        this.snackBar.open('Error loading all events', 'Error', { duration: 6000 });
      });
    }

  }
}

export class EventsDataSource extends DataSource<any> {
  filteredData: Events[] = [];
  _filterEndDate = new BehaviorSubject('');
  _filterStartDate = new BehaviorSubject('');
  _filterEventDataChange = new BehaviorSubject('');
  _filterEventTypeDataChange = new BehaviorSubject([]);
  _filterEventsByStatusDataChange = new BehaviorSubject('');
  _filterEventsByPatientNameDataChange = new BehaviorSubject('');

  constructor(private _eventsDatabase: EventsDatabase, private _paginator: MatPaginator) {
    super();
  }

  // filter functions for search
  get filter(): string { return this._filterEventDataChange.value; }
  set filter(filter: string) { this._filterEventDataChange.next(filter); }
  get filterEndDate(): string { return this._filterEndDate.value }
  set filterEndDate(filter: string) { this._filterEndDate.next(filter) }
  get filterStartDate(): string { return this._filterStartDate.value }
  set filterStartDate(filter: string) { this._filterStartDate.next(filter) }
  get filterEventsType(): string[] { return this._filterEventTypeDataChange.value; }
  set filterEventsType(filter: string[]) { this._filterEventTypeDataChange.next(filter); }
  get filterEventsByStatus(): string { return this._filterEventsByStatusDataChange.value }
  set filterEventsByStatus(filter: string) { this._filterEventsByStatusDataChange.next(filter); }
  get filterEventsByPatientName(): string { return this._filterEventsByPatientNameDataChange.value }
  set filterEventsByPatientName(filter: string) { this._filterEventsByPatientNameDataChange.next(filter); }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Events[]> {
    const displayEventsDataChanges = [
      this._paginator.page,
      this._filterStartDate,
      this._filterEndDate,
      this._eventsDatabase.dataChange,
      this._filterEventTypeDataChange,
      this._filterEventsByStatusDataChange,
      this._filterEventsByPatientNameDataChange
    ]

    return merge(...displayEventsDataChanges).pipe(map(() => {
      if ((!this.filterEventsType || this.filterEventsType.length < 1) && !this.filterEventsByPatientName && !this.filterEventsByStatus &&
        !this.filterStartDate && !this.filterEndDate) {
        this.filteredData = this._eventsDatabase.data.slice();  // no filters specified, bypass filtering
      } else {
        // filter by search terms
        this.filteredData = this._eventsDatabase.data.slice().filter((event: EventsWithStatuses) => {
          const searchStr = (event._id + ' ' + event.objectId).toLowerCase();
          const search_eventsType = event.eventType ? event.eventType : '';
          const search_eventsByPatient = event.patientName ? event.patientName.toLowerCase() : '';
          const search_eventsByStatus = this.getEventStatus(event);

          const result_searchStr = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          const result_eventsType = (this.filterEventsType && this.filterEventsType.length > 0) ?
            this.filterEventsType.includes(search_eventsType) : true;
          const result_eventsByPatient = this.filterEventsByPatientName ?
            search_eventsByPatient.indexOf(this.filterEventsByPatientName.toLowerCase()) !== -1 : true;
          const result_eventsByStatus = this.filterEventsByStatus ?
            search_eventsByStatus === this.filterEventsByStatus.toLowerCase() : true;
          const result_eventsByDate = (this.filterStartDate || this.filterEndDate) ?
            this.getEventsByDateRange(event, new Date(this.filterStartDate), new Date(this.filterEndDate)) : true;

          return result_eventsType && result_eventsByPatient && result_eventsByStatus && result_searchStr && result_eventsByDate;
        });
        console.log('Found ' + this.filteredData.length + ' events');
      }

      const allData = this.filteredData.slice();

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return allData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  /** return the status for each event on filter */
  getEventStatus(event: EventsWithStatuses) {
    if (event.processed) {
      return 'processed';
    } else if (event.failed) {
      return 'failed';
    } else {
      return 'pending';
    }
  }

  /** function return events within a date range */
  getEventsByDateRange(event: EventsWithStatuses, dateStart: Date, dateEnd: Date) {
    dateEnd = new Date(dateEnd.setDate(dateEnd.getDate() + 1));
    if (new Date(event.dateAdded) >= dateStart && new Date(event.dateAdded) <= dateEnd) {
      return event;
    }
  }

  disconnect() { }
}

interface EventsWithStatuses extends Events, NotificationStatus {
  eventType: string;
}
