import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { ValidationModule } from '../validation/validation.module';

import { ChatComponent } from './chat.component';
import { SpecialistChatComponent } from './specialist/specialist-chat.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    FlexLayoutModule,
    MomentModule,
    ValidationModule
  ],
  declarations: [
    ChatComponent,
    SpecialistChatComponent
  ],
  exports: [
    SpecialistChatComponent
  ]
})

export class ChatModule { }
