import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { Appointment } from '../models/appointment';

@Injectable()
export class AppointmentService {

  constructor(private repository: RepositoryService) { }

  addAppointment(newAppointment: Appointment): Promise<Appointment> {
    newAppointment._id = new Date().getTime().toString();

    return new Promise((resolve, reject) => {
      this.repository.updateObject(newAppointment, Appointment.type, Databases.appointmentsDb)
        .then((pouchObject) => {
          const updatedAppointment: Appointment = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedAppointment);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    })
  }

  /**
   * update appointment 
   */
  updateAppointment(appointment: Appointment): Promise<Appointment> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(appointment, Appointment.type, Databases.appointmentsDb)
        .then((pouchObject) => {
          const updatedAppointment: Appointment = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedAppointment);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    })
  }

  getAppointments(): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Appointment.type, Databases.appointmentsDb)
        .then((result) => {
          const appointments: Appointment[] = result.docs.map((doc: any) => this.mapObjectToAppointment(doc));
          resolve(appointments);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  // getting one patient's appointments
  getPatientAppointments(patientId: number): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsByPatient(Appointment.type, patientId, Databases.appointmentsDb)
        .then((result) => {
          const appointments: Appointment[] = result.docs.map((doc: any) => this.mapObjectToAppointment(doc));
          resolve(appointments);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }
  // getting sharelink's appointments
  getShareLinkAppointments(sharelinkField: string = 'sharelinkId', sharelinkId: string): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(Appointment.type, Appointment.fields, sharelinkField, sharelinkId, ['_id'], Databases.appointmentsDb)
        .then((result) => {
          const appointments: Appointment[] = result.docs.map((doc: any) => this.mapObjectToAppointment(doc));
          resolve(appointments);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  confirmAppointment(appointment: Appointment): Promise<Appointment> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(appointment, Appointment.type, Databases.appointmentsDb)
        .then((pouchObject) => {
          const updatedAppointment: Appointment = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedAppointment);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  public mapObjectToAppointment(object: any): Appointment {
    let appointments: Appointment = new Appointment();
    return appointments = { ...object };
  }
}
