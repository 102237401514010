import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': 'This field is required',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
      'maxlength': `Maximum length ${validatorValue.requiredLength}`,
      'invalidDateInPast': 'This date must be in the past',
      'invalidDateTimeInFuture': 'This date and time must be in the future',
      'invalidDateFormat': 'Invalid date format, please use DD/MM/YYYY',
      'invalidConfirmPassword': 'The passwords do not match',
      'rangeLength': `The length provided is out of range`,
      'invalidPhoneNumber': 'Phone number should start with 07.. or 01..',
      'invalidPhoneNumberString': 'Phone number should NOT contain alphabets A-z',
      'notUnique': 'The index provided is not unique',
      'notICD1O': 'ICD10 diagnosis required. Kindly select'
    };

    return config[validatorName];
  }

  static creditCardValidator(control: any) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // eslint-disable-next-line max-len
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      // eslint-disable-next-line max-len
      if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        return null;
      } else {
        return { 'invalidEmailAddress': true };
      }
    }
  }

  static passwordValidator(control: any) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  static dateInPastValidator(control: any) {
    // date of birth must be in past
    const now = new Date();
    const dob = new Date(control.value)
    if (dob < now) {
      return null;
    } else {
      return { 'invalidDateInPast': true };
    }
  }

  static dateFormatValid(dateControl: any) {
    const date = moment(dateControl.value);
    // value must be valid format
    if (moment(date, 'DD/MM/YYYY').isValid()) {
      return null;
    } else {
      return { 'invalidDateFormat': true };
    }
  }

  static timeDateInFutureValidator(timeControlName: any, dateControlName: any) {
    // combination of date and time controls must be in future

    return (group: FormGroup) => {

      const dateControl = group.controls[dateControlName];
      const timeControl = group.controls[timeControlName];

      const now = new Date();
      const dateVal = new Date(dateControl.value);
      const timeVal = this.parseTime(timeControl.value);

      if (timeVal === undefined) {
        return;
      }

      const dateAndTime = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), timeVal.getHours(), timeVal.getMinutes(), 0);
      if (dateAndTime > now) {
        return timeControl.setErrors(null);
      } else {
        return timeControl.setErrors({ 'invalidDateTimeInFuture': true });
      }

    };

  }

  static parseTime(t) {
    const d = new Date();
    if (!t) { return; }
    const time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    const ampm = t.slice(-2);
    d.setHours(parseInt(time[1]) + ((time[1] == '12' &&  ampm  == 'pm') || (time[1] !== '12' && ampm == 'am') ? 0 : 12));
    d.setMinutes(parseInt(time[2]) || 0);
    return d;
  }

  static passwordConfirm(passwordControl: any, confirmPasswordControl: any) {

    return (group: FormGroup) => {
      const password = group.controls[passwordControl], confirmPassword = group.controls[confirmPasswordControl];
      if (password.value !== confirmPassword.value) {
        return confirmPassword.setErrors({ 'invalidConfirmPassword': true })
      } else {
        return confirmPassword.setErrors(null);
      }
    }

  }

  static rangeCheck(rangeLength: Array<number>) {
    return (control: AbstractControl): { [key: string]: boolean } => {
      if (this.isPresent(Validators.required(control))) {
        return null;
      }

      const val: number = control.value;

      return val >= rangeLength[0] && val <= rangeLength[1] ? null : { 'rangeLength': true };
    };
  }

  static isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  static phoneNumberValidation(phoneNumberControl: any) {
    const phoneNumber = phoneNumberControl.value;
    if (phoneNumber) {
      if (phoneNumber.match(/^-?\d+$/)) {
        const phone = phoneNumber.split('');
        if (phone[0] !== '0' || !(phone[1] !== '7' || phone[1] !== '1' || phone[1] !== '2')) {
          return { 'invalidPhoneNumber': true };
        } else {
          return null;
        }
      } else {
        return { 'invalidPhoneNumberString': true };
      }
    }
  }
  
  /**
   * validate to ensure that the block index of each block is unique 
   */
  static blockIndexValidation(blocksIndexes: Array<Number>) {
    return (control: AbstractControl): { [key: string]: boolean } => {
      if (this.isPresent(Validators.required(control))) {
        return null;
      }
      const val: number = control.value;
      return blocksIndexes.includes(Number(val)) ? { 'notUnique': true } : null;
    }
  }

 /**
   * validate to ensure that the array has at least one field
   *** can't delete all fields
   */

   static ICD10(control:AbstractControl) {
    return !control.value.code ? {'notICD1O': true} : null;
  } 

}
