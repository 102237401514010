import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Updates } from '../../../models/narrative';
import { Attachment } from '../../../models/attachment';
import { AttachmentService } from '../../../services/attachment.service';
import { Patient } from '../../../models/patient';
import { AppSettings } from '../../../models/config';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AttachmentModal } from '../../../attachments/attachment-modal';
import { ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { RepositoryService } from '../../../services/repository.service';
import { ValidationService } from '../../../validation/validation.service';

@Component({
  selector: 'psoc-delete-updates-dialog-component',
  template: `
    <h2>Would you like to delete this update segment?</h2>
    This update segment will be deleted
    <p>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Ok</button>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">Cancel</button>
      </p>`
})
export class DeleteUpdateDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>,
    private ref: ChangeDetectorRef) { }
}

@Component({
  selector: 'psoc-update-edit',
  templateUrl: './update-edit.component.html',
})
export class UpdateEditComponent implements OnInit {
  @Input() patient: Patient;
  @Input() attachments: Attachment[];
  @Input() updates: Updates[];
  @Input() narrativeId: string;
  @Input() public updatesForm: FormGroup;
  @Input() appSettings: AppSettings;

  // track whether to display updates block or not
  showUpdatesBlock = false;

  // attachments dialog
  dialogRef: MatDialogRef<any>;
  selectedAttachment: Attachment;

  // track validity of controls for UI
  updatesBlockValid: boolean = true;

  // confirm dialog
  selectedUpdateToDelete: number = null;

  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private repositoryService: RepositoryService
  ) {

    // initialize empty updates model
    this.updates = new Array<Updates>();

    // initialize form with empty FormArray for update controls
    this.updatesForm = this._fb.group({
      updates: this._fb.array([])
    });
  }

  ngOnInit(): void {
    this.refreshOrInitializeUpdateFormControls();
  }

  isUpdatesBlockValid(): boolean {
    let fieldValid = true;
    (<FormArray>this.updatesForm.controls['updates']).controls.forEach(field => {
        if (!field.valid) { fieldValid = false }
        this.updatesBlockValid = fieldValid;
      });

    return this.updatesBlockValid;
  }

  openUpdatesAttachmentModal(segmentNum: number): void {
    this.dialogRef = this.dialog.open(AttachmentModal);
    let currentAttachment: Attachment = new Attachment();

    if (this.updates[segmentNum].attachment.attachmentTitle === '' || this.updates[segmentNum].attachment.attachmentTitle === undefined) {
      currentAttachment = null;
    } else {
      currentAttachment._id = this.updates[segmentNum].attachment._id;
      currentAttachment.attachmentTitle = this.updates[segmentNum].attachment.attachmentTitle;
      currentAttachment.dateOnAttachment = this.updates[segmentNum].attachment.dateOnAttachment;
      currentAttachment.attachmentFileName = this.updates[segmentNum].attachment.attachmentFileName;
      currentAttachment.mediatype = this.updates[segmentNum].attachment.mediatype;
      currentAttachment.studyUuid = this.updates[segmentNum].attachment.studyUuid;
      currentAttachment.size = this.updates[segmentNum].attachment.size;
      currentAttachment.dateAdded = this.updates[segmentNum].attachment.dateAdded;
    }

    this.dialogRef.componentInstance.setServices(this.attachmentService, this.authService, this.repositoryService);
    this.dialogRef.componentInstance.setAttachment(currentAttachment);
    this.dialogRef.componentInstance.setPatient(this.patient);
    this.dialogRef.componentInstance.setNarrativeId(this.narrativeId);
    this.dialogRef.componentInstance.attachments = this.attachments;
    this.dialogRef.componentInstance.setSettings(this.appSettings);

    this.dialogRef.afterClosed().subscribe(result => {
      this.selectedAttachment = result;
      if (result === null) {
        this.deleteUpdateAttachment(segmentNum);
      } else if (result === undefined) {
        // user has cancelled out, don't change anything
      } else {
        this.saveLinkToUpdateAttachment(segmentNum, result);
      }
      this.dialogRef = null;
    })
  }

  // hide or show updates block
  toggleUpdatesBlock(event: Event, showUpdatesBlock: boolean): void {
    if (showUpdatesBlock) {
      this.showUpdatesBlock = true;
    } else {
      if (this.showUpdatesBlock) {
        this.showUpdatesBlock = false;
      } else {
        this.showUpdatesBlock = true;
      }
    }
  }

  // set up form controls for each update object
  refreshOrInitializeUpdateFormControls(): void {
    this.updates.forEach(
      (updateBlockSegment) => {
        (<FormArray>this.updatesForm.controls['updates']).push(this.createNarrativeUpdate(updateBlockSegment));
      });
  }

  // create narrative updates
  createNarrativeUpdate(update: Updates): FormGroup {
    if (!update.attachment) { update.attachment = new Attachment() };
    return this._fb.group({
      updateDate: [new Date(update.updateDate),
        [ValidationService.dateFormatValid, ValidationService.dateInPastValidator, Validators.required]],
      description: [update.description, [Validators.required, Validators.minLength(5)]],
      attachmentTitle: [update.attachment.attachmentTitle],
      dateOnAttachment: [new Date(update.attachment.dateOnAttachment)],
      attachmentFileName: [update.attachment.attachmentFileName],
      attachmentFile: ['']
    });
  }

  // updates date when mdDatepicker has changes
  updateDate(item_index, $event) {
    const newDate = new Date($event);
    this.updates[item_index].updateDate = newDate;
  }

  // handles the adding of updates segment
  addUpdateSegment(event: Event, index: number) {
    event.preventDefault(); // prevent form from submitting itself

    this.toggleUpdatesBlock(event, true);
    const updatesAttachment = new Attachment;
    const emptyUpdateBlockSegment: Updates = {
      updateDate: new Date(),
      description: '',
      dateAdded: new Date(),
      attachment: updatesAttachment
    };
    this.updates.splice(index + 1, 0, emptyUpdateBlockSegment);
    (<FormArray>this.updatesForm.controls['updates']).push(this.createNarrativeUpdate(emptyUpdateBlockSegment));
  }

  // delete update segment dialog
  confirmDeleteUpdate(event: Event, index: number) {
    this.dialogRef = this.dialog.open(DeleteUpdateDialogComponent);
    event.preventDefault();

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.selectedUpdateToDelete = index;
        this.updates.splice(index, 1);
        (<FormArray>this.updatesForm.controls['updates']).removeAt(index);
        this.selectedUpdateToDelete = null;
        this.ref.detectChanges();
      } else {
        // when either cancel or nothing is selected
      }
      this.dialogRef = null;
    });
  }

  saveLinkToUpdateAttachment(x: number, attachSelected: Attachment): void {
    this.updates[x].attachment._id = attachSelected._id;
    this.updates[x].attachment.attachmentTitle = attachSelected.attachmentTitle;
    this.updates[x].attachment.dateOnAttachment = attachSelected.dateOnAttachment;
    this.updates[x].attachment.attachmentFileName = attachSelected.attachmentFileName;
    this.updates[x].attachment.mediatype = attachSelected.mediatype;
    this.updates[x].attachment.studyUuid = attachSelected.studyUuid;
    this.updates[x].attachment.size = attachSelected.size;
    this.updates[x].attachment.dateAdded = new Date();
  }

  // delete update attachment
  deleteUpdateAttachment(x: number) {
    this.attachments.forEach(att => { if (att._id === this.updates[x].attachment._id) { att.linked-- } })
    this.updates[x].attachment._id = null;
    this.updates[x].attachment.attachmentTitle = null;
    this.updates[x].attachment.dateOnAttachment = null;
    this.updates[x].attachment.attachmentFileName = null;
    this.updates[x].attachment.mediatype = null;
    this.updates[x].attachment.studyUuid = null;
    this.updates[x].attachment.size = null;
    this.updates[x].attachment.dateAdded = null;
  }
}
