import { Component, Input } from '@angular/core';
import { UltrasoundRecord } from '../../../models/obstetric-sharelink';
import moment from 'moment';

@Component({
    template: 'edit-sonographer-block'
})

export class SonographerBlockEditComponent {
    @Input() getGestationAge: any;
    @Input() obsUltrasoundData: UltrasoundRecord;


    constructor() { }

    /**
     * function to get the averae gestation age
     */
    getGestationAgeByScan() {
        const avgGestationAge = this.getGestationAge();
        if (isNaN(avgGestationAge.avg)) { // check if gestation age is a number
            this.obsUltrasoundData.dateOfDeliveryFrom = '';
            this.obsUltrasoundData.dateOfDeliveryByScan = null;
            this.obsUltrasoundData.actualGestationAge = null;
            return;
        }

        // EDD = (40.0 - avgGestationAge) + new Date();
        const remainingWeeks = (40.0 - avgGestationAge.avg);
        const today = moment(this.obsUltrasoundData.dateAdded); // TODO: Using dateAdded may not be efficient
        const edd = today.add(remainingWeeks, 'weeks').format();

        // save edd and gestation age to narrative object
        this.obsUltrasoundData.dateOfDeliveryFrom = avgGestationAge.title;
        this.obsUltrasoundData.fetalWeight = avgGestationAge.fetalWeight;
        this.obsUltrasoundData.dateOfDeliveryByScan = new Date(edd);
        this.obsUltrasoundData.actualGestationAge = avgGestationAge.weeks + ' weeks ' + avgGestationAge.days + 'days';
    }

    /** return the table min and max values */
    returnMinMaxRange(table: any): number[] {
        const tableKeys = Object.keys(table).map(s => parseFloat(s)).sort((a, b) => (a - b));
        return [tableKeys[0] * 10, tableKeys[tableKeys.length - 1] * 10];
    }

}
