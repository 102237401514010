import { Injectable } from '@angular/core';
import { apiResponse } from '../models/diagnosis';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../models/config';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DiagnosisService {
  clientId: string;
  clientSecret: string;
  token: string;
  apiUrl: string = environment.WHO_API.apiUrl;
  tokenEndpoint: string = environment.WHO_API.tokenEndpoint;
  appSettings: AppSettings = new AppSettings();
  subscription: Subscription;
  requestBody;
  public results: any
  constructor(
    private http: HttpClient) {
    // Run subscription that generates token every 1 hour
    this.subscription = interval(350000).subscribe(val => this.getToken());
  }

  /**
   * function get diagnoses data
   * @param term search term for GET param
   * @returns an array of icd10 diagnoses
   */
  getDiagnosis(term) {
    // check if search term is a string
    if (typeof term !== 'string') { term = ''; return []; }

    // get icd token from cookie
    let token = this.getCookie('token');

    // check if token is valid
    if (!token) { return []; }

    // set headers and add authorization token with type bearer
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-Version': 'v2',
      'Authorization': 'Bearer ' + token
    });

    // send GET request to WHO api
    let params = { q: term }
    return this.http.get(this.apiUrl, { headers: headers, params })
      .pipe(map((response: apiResponse) => {
        /**
         * return an array of diagnoses [{code:'',name:''}]
         * eliminate html tags using regex to match /\<(.*?)\>/gm and replace
        */
        return this.results = response.destinationEntities
          .map(diagnosis => ({ name: diagnosis.title.replace(/\<(.*?)\>/gm, ""), code: diagnosis.theCode }))
      }))
  }

  /**
   * function to assign client credentials from appSettings
   * @param specialistSettings 
   */
  assignClientDetails(specialistSettings) {
    this.clientId = specialistSettings.clientId
    this.clientSecret = specialistSettings.clientSecret

    this.getToken();
  }

  /**
   * function to generate token from WHO tokenEndpoint
   */
  getToken() {
    this.requestBody = new HttpParams()
      .set('grant_type', environment.WHO_API.grant_type)
      .set('scope', environment.WHO_API.scope)
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);
    // send POST request for oauth2 token from WHO tokenEndpoint and store in localStorage
    this.http.post(this.tokenEndpoint, this.requestBody)
      .subscribe(response => {
        if (response['access_token']) {
          /**
           * get time validity from response (currently 3600s)
           * calculate expiry time and store in local storage
           */
          const d = new Date();
          d.setTime(d.getTime() + (response['expires_in'] * 1000));
          let expires = d.toUTCString();
          this.setCookie('token', response['access_token'], expires);
        }
      }, error => console.log(error));
  }

  /**
   * function to set token as a cookie
   * @param name name of cookie
   * @param value value of cookie
   * @param expiry cookie expiry time
   */
  setCookie(name, value, expiry) {
    document.cookie = name + "=" + value + ";" + "expires=" + expiry + ";path=/";
  }

  /**
   * function to decode cookie and return cookie value 
   * @param cname cookie name used to get cookie value
   * @returns actual token from cookie value
  */
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
