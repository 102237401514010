import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../material/app-material.module';
import { MatTableModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';

import { RecordsComponent } from './records.component';
import { PatientListComponent } from './patients-list.component';
import { RecordsActiveLinks } from './records-active-links.component';
import { RecordsLinksDiagnosticsComponent } from './records-links-diagnostics.component';
import { RecordsExpiredLinks } from './records-expired-links.component';
import { RecordsCreatedComponent } from './created/records-created.component';
import { RecordsUpdatedComponent } from './updated/records-updated.component';

import { CdkTableModule } from '@angular/cdk/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    AppMaterialModule,
    FlexLayoutModule,
    MomentModule,
    CdkTableModule,
    HttpClientModule,
    RouterModule
  ],
  declarations: [
    RecordsComponent,
    PatientListComponent,
    RecordsActiveLinks,
    RecordsLinksDiagnosticsComponent,
    RecordsExpiredLinks,
    RecordsCreatedComponent,
    RecordsUpdatedComponent
  ]
})

export class RecordsModule { }
