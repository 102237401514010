import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './validation.service';

@Component({
  selector: 'validation-messages',
  template: `<div *ngIf="errorMessage !== null">{{errorMessage}}</div>`,
  styles: ['div { border-left: 3px solid red; padding-left: 5px; color: red; margin-bottom: 10px; }']
})
export class ValidationMessagesComponent {
  @Input() control: FormControl;
  constructor() { }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if ((this.control.errors.hasOwnProperty(propertyName)
        && this.control.touched)
        && (this.control.errors.hasOwnProperty(propertyName) || this.control.pristine)) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }

    return null;
  }
}
