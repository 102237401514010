import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PouchObject } from '../models/pouch-object';
import { environment } from '../../environments/environment';

import * as rawPouchDB from 'pouchdb';
import * as rawPouchDBFind from 'pouchdb-find';
import * as rawCryptoPouch from 'crypto-pouch';
import * as rawPouchDBAuthentication from 'pouchdb-authentication';
import * as rawPouchDbLoad from 'pouchdb-load';

const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
const PouchDBFind: PouchDB.Plugin = (rawPouchDBFind as any).default;
const PouchDBAuthentication: PouchDB.Plugin = (rawPouchDBAuthentication as any).default;
const CryptoPouch: PouchDB.Plugin = (rawCryptoPouch as any);
const PouchDBLoad: PouchDB.Plugin = (rawPouchDbLoad as any);

import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute } from '@angular/router';

declare let emit: Function;

export class Locator implements PouchObject {
  public static type: string = 'locator';
  public static fields = ['_id', '_rev', '_deleted', 'type', 'latitude', 'longitude', 'contact', 'ip', 'deviceInfo', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility'];
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  latitude: number;
  longitude: number;
  accuracy: number;
  contact: string;
  ip: string;
  deviceInfo: any;

  dateAdded: Date;
  dateUpdated: Date;
  updatedBy: String;
  createdBy: String;
  createFacility: string;
  updateFacility: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

const GEOLOCATION_ERRORS = {
  'errors.location.unsupportedBrowser': 'Browser does not support location services',
  'errors.location.permissionDenied': 'You have rejected access to your location',
  'errors.location.positionUnavailable': 'Unable to determine your location',
  'errors.location.timeout': 'Service timeout has been reached'
};

@Component({
  selector: 'locator',
  templateUrl: 'locator.component.html'
})
export class LocatorComponent implements OnInit {
  locationInfo: Locator;
  private remoteLocatorDb: any;

  constructor(
    private deviceService: DeviceDetectorService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loadLocatorDb();

    this.start()
  }

  start() {

    this.authenticate().then(loggedInUser => {
      this.getPosition().then(pos => {
        this.locationInfo = pos;
        this.locationInfo.type = 'locator';
        this.locationInfo.deviceInfo = this.deviceService.getDeviceInfo();
        if (loggedInUser && loggedInUser.ip) { this.locationInfo.ip = loggedInUser.ip; }
        this.locationInfo.contact = this.route.snapshot.params['contact'];

        this.saveLocation(this.locationInfo)
          .then(() => {
            console.log('Location info saved updated:', this.locationInfo);
          }).catch(error => {
            console.log('Error:', error);
            this.snackBar.open('Error saving the location info, please try again', 'Error', { duration: 6000 });
          });

      }).catch(error => console.log(error));
    }).catch(error => console.log(error));

  }

  authenticate(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.userLogin('dm', 'd')
        .then(response => {

          const user = this.authService.getUser();
          // get user ip
          this.authService.getClientIp()
            .then(ip => {
              user.ipAddress = ip;
              console.log('user IP: ' + user.ipAddress);
              this.authService.setUser(user);
              resolve(user);
            })
            .catch(err => {
              // error loading user ip, create user db anyway
              this.snackBar.open('Unable to load user ip' + err, 'Error', { duration: 6000 });
              this.authService.setUser(user);
              resolve(user);
            });

        }).catch(err => {
          // error logging in to remote db
          reject('error logging into remote db');
          this.snackBar.open(err, 'Info', { duration: 6000 });
        });
    });
  }


  // Locator DB setup
  loadLocatorDb() {
    this.remoteLocatorDb
      = new PouchDB(environment.couchURL + environment.pouchDBName + '-locator', { skip_setup: true });

    // this.remoteLocatorDb
    //   .createIndex({ index: { fields: Locator.fields, name: 'idxIdType', ddoc: 'idx' } })
    //   .catch(function (err) { console.log('Error creating index: ', err); });

  }

  /** save location info to the database */
  saveLocation(locationInfo: Locator): Promise<Locator> {
    return new Promise((resolve, reject) => {
      this.updateObject(locationInfo)
        .then((pouchObject) => {
          const updatedLocationInfo: Locator = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedLocationInfo);
        })
        .catch(error => {
          console.error('An error occurred saving location info', error);
          reject(error);
        });
    });
  }

  // save or update object to pouch db, optionally specify database
  updateObject(pouchObject: PouchObject): Promise<PouchObject> {
    return new Promise((resolve, reject) => {

      // update type
      pouchObject.type = Locator.type;

      // metadata
      // const user = this.authenticationService.getUser();
      // let eventType = 'updated';
      // // check for valid user
      // if (user === undefined || user.username === '') { reject('Valid user required to update objects'); };


      // update timestamps
      const timestamp = new Date();
      pouchObject._id = timestamp.toString();
      if (!pouchObject._rev) {
        pouchObject.dateAdded = timestamp
        // pouchObject.createdBy = user.username;
        // pouchObject.createFacility = user.facility;

        // set the tye of event to added for a new document
        // eventType = 'added';
      };
      pouchObject.dateUpdated = timestamp;
      // pouchObject.updatedBy = user.username;
      // pouchObject.updateFacility = user.facility;

      // save object
      this.remoteLocatorDb.put(pouchObject)
        .then((response: any) => {
          console.log('updateObject updated: ' + JSON.stringify(pouchObject));
          pouchObject._rev = response.rev;

          // return object updated with new _rev
          resolve(pouchObject);
        })
        .catch(error => {
          console.log('Error Updating Pouch Object:', error);
          reject(error);
        });
    });
  }

  /**
  * Obtains the geographic position, in terms of latitude and longitude coordinates, of the device.
  * @param {Object} [opts] An object literal to specify one or more of the following attributes and desired values:
  *   - enableHighAccuracy: Specify true to obtain the most accurate position possible, or false to optimize in favor of performance and power consumption.
  *   - timeout: An Integer value that indicates the time, in milliseconds, allowed for obtaining the position.
  *              If timeout is Infinity, (the default value) the location request will not time out.
  *              If timeout is zero (0) or negative, the results depend on the behavior of the location provider.
  *   - maximumAge: An Integer value indicating the maximum age, in milliseconds, of cached position information.
  *                 If maximumAge is non-zero, and a cached position that is no older than maximumAge is available, the cached position is used instead of obtaining an updated location.
  *                 If maximumAge is zero (0), watchPosition always tries to obtain an updated position, even if a cached position is already available.
  *                 If maximumAge is Infinity, any cached position is used, regardless of its age, and watchPosition only tries to obtain an updated position if no cached position data exists.
  * @returns {Observable} An observable sequence with the geographical location of the device running the client.
  */
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      const positionOption = { timeout: 10000, enableHighAccuracy: true };
      if (window.navigator && window.navigator.geolocation) {

        navigator.geolocation.getCurrentPosition(function (resp) {
          console.log(resp);
          resolve({ longitude: resp.coords.longitude, latitude: resp.coords.latitude, accuracy: resp.coords.accuracy });
        }, function (error) {
          switch (error.code) {
            case 1:
              reject(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
              break;
            case 2:
              reject(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
              break;
            case 3:
              reject(GEOLOCATION_ERRORS['errors.location.timeout']);
              break;
          }
        }, positionOption)

      } else {
        reject(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
      }

    });
  }
}
