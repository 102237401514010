import { Component, Input } from '@angular/core';
import { NumberOfFoetusBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-number-of-foetus-nurse-view',
  templateUrl: './number-foetus-nurse-view.component.html'
})

export class NumberOfFoetusNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  _numberOfFoetus: NumberOfFoetusBlock;
  @Input()
  set numberOfFoetus(numberOfFoetus: NumberOfFoetusBlock) {
    this._numberOfFoetus = numberOfFoetus;
    this.getAttachmentFilesCount(numberOfFoetus.attachmentIds);
  }
  get numberOfFoetus(): NumberOfFoetusBlock {
    return this._numberOfFoetus;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.numberOfFoetus && this.numberOfFoetus.numberOfFoetus && this.numberOfFoetus.blockStatus) ? true : false;
  }
}
