import { Component, Input } from '@angular/core';
import { Narrative } from '../../models/narrative';
import { Facility } from '../../models/facility';
import { AppSettings } from '../../models/config';
import { Mediator } from '../../models/mediators';

@Component({
  selector: 'psoc-referral-view',
  templateUrl: './referral-view.component.html',
})

export class ReferralViewComponent {
  @Input() set appSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    this.showConsultationFields = this._appSettings.narrativeSettings.consultationFields;
  } get appSettings(): AppSettings { return this._appSettings; }
  @Input() narrative: Narrative;
  @Input() referringTo: Facility;
  @Input() referringFrom: Facility;
  @Input() specialistList: Mediator[];

  _appSettings: AppSettings;
  showConsultationFields: boolean;
}
