import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Globals } from '../../services/globals';
import { AuthenticationService } from '../../services/authentication.service';
import { RepositoryService, AppTypes } from '../../services/repository.service';
import { PatientService } from '../../services/patient.service';
import { FacilityService } from '../../services/facility.service';
import { NarrativeService } from '../../services/narrative.service';

import { User } from '../../models/user';
import { Mediator } from '../../models/mediators';
import { Facility } from '../../../app/models/facility';
import { Patient } from '../../models/patient';
import { Narrative } from '../../models/narrative';
import { ShareLinks } from '../../models/sharelinks';

import * as moment from 'moment';

@Component({
  template: `
    <h2>Are you sure you would like to Expire this link?</h2>
    This Sharelink will not be accessible once expired.

    <p>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Yes</button>
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </p>`
})

export class ExpireSharelinkDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<any>
  ) { }
}

@Component({
  selector: 'psoc-add-sharelinks',
  templateUrl: 'add-sharelinks.component.html'
})

export class AddShareLinksComponent implements OnInit {
  facilities: Facility[];
  pathOrigin: string;
  patientId: number;
  patient: Patient;
  narrative: Narrative;
  shareLinksModel: ShareLinks;
  selectedSharelink: ShareLinks = null;
  forwardShareLinksForm: FormGroup;
  shareLinks: ShareLinks[];
  currentSharelink: ShareLinks = null;
  sharedFacility: string[] = [];
  public appTypes = AppTypes;

  // booleans for the progress bar
  loadingPatientShareLinks: Boolean = false;
  loadingPatientData: Boolean = false;
  loadingPatientNarratives: Boolean = false;
  // boolean for toggling forwardSharelink input
  showEmailInput = false;

  // expire sharelink dialog
  dialogRef: MatDialogRef<any>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    public repository: RepositoryService,
    private snackBar: MatSnackBar,
    private globals: Globals,
    public patientService: PatientService,
    private narrativeService: NarrativeService,
    private route: ActivatedRoute,
    private location: Location,
    private facilityService: FacilityService,
    private httpClient: HttpClient,
    private dialog: MatDialog,
  ) {
    this.shareLinksModel = new ShareLinks();
    this.forwardShareLinksForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email]],
      'specialistName': ['', [Validators.required, Validators.minLength(4)]]
    });

    // initialize patient
    this.patient = new Patient();
  }

  ngOnInit(): void {
    this.pathOrigin = window.location.origin;

    // update current global patient id
    this.globals.showShareLinkPatientId.emit(+(this.route.snapshot.params['id']));

    // load list of facilities
    this.getFacilities();

    // get this patients previous share links
    this.getPatientShareLinks();

    // get the patient for who we are generating the sharelink
    this.getPatientData();
  }

  // notify the user that sharelink has been copied to clipboard
  notifySharelinkCopied() {
    this.snackBar.open('Sharelink successfully copied to clipboard', 'Success', { duration: 6000 })
  }

  /**
   * function to get list of facilities
   */
  getFacilities() {
    this.facilityService.getFacilities()
      .then((facilities: Facility[]) => {
        this.facilities = facilities;
      })
      .catch(err => {
        console.log('Error loading facilities: ', err);
        this.snackBar.open('Error loading list of facilities', 'Error');
      });
  }

  /**
   * function to navigate to report view
   * @param sharedLinkId
   */
  viewReport(event: Event, sharedLink: ShareLinks) {
    event.preventDefault();
    if (this.patientService.isRequestLink(sharedLink._id)) {
      this.snackBar.open('Request ShareLinks has no report', 'Info', { duration: 6000 });
    } else if (!sharedLink.sonographerBlocks) {
      this.router.navigate(['narrative/report/' + sharedLink._id]);
    } else {
      this.router.navigate(['/patient/' + sharedLink.patientId + '/narrative/' + sharedLink.narrativeId + '/sharelink/'
        + sharedLink._id + '/report']);
    }
  }

  /**
   * function to get mediator facility
   */
  getFacility(facilityCode): String {
    if (!facilityCode) { return 'No Facility' }
    return this.facilities.filter((h: Facility) => h._id === facilityCode)[0].name;
  }

  /**
   * function to get the patient data
   */
  getPatientData() {
    this.loadingPatientData = true;
    this.patientId = this.route.snapshot.params['id'];

    this.patientService.getSinglePatient(this.patientId)
      .then(patient => {
        this.patient = patient;
        this.loadingPatientData = false;
      }).catch(error => {
        console.log(error);
        this.snackBar.open('Error loading patient data', 'Error');
        this.loadingPatientData = false;
      });
  }

  /**
   * get patient share links
   */
  getPatientShareLinks(): void {
    this.loadingPatientShareLinks = true;
    this.patientId = this.route.snapshot.params['id'];

    this.patientService.getPatientShareLinks(+this.patientId).then(
      shareLinks => {
        this.loadingPatientShareLinks = false;
        this.shareLinks = shareLinks;
        this.shareLinks.forEach((sharelink: ShareLinks) => {
          if (sharelink._id.split('_')[0] === 'request') {
            sharelink.shortUrl = this.pathOrigin + '/request/' + sharelink._id;
          } else {
            sharelink.shortUrl = this.pathOrigin + '/shared/' + sharelink._id;
          }
          this.getShortLink(sharelink.shortUrl)
            .then(shortUrl => sharelink.shortUrl = shortUrl)
            .catch(error => console.log(error));
        });
        // console.log(shareLinks);
      }).catch(error => {
        console.log('Error loading sharelinks: ', error);
        this.snackBar.open('Error loading sharelinks for this patient');
      });
  }

  /**
   * Use google api to get a short version of the URL
   * @param longLink link to be shortened
   */
  getShortLink(longLink: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // grab public ipaddress
      const body = { longUrl: longLink };

      this.httpClient.post('https://www.googleapis.com/urlshortener/v1/url', body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: new HttpParams().set('key', 'AIzaSyCOlI9uVJuYt3IimMFJiS5TpnyEwvJGgkw')
      })
        .toPromise()
        .then((data: any) => {
          // console.log(JSON.stringify(data));
          resolve(data.id);
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * function to redirect user back to narrative
   * @param e event
   */
  backClicked(e: Event): void {
    // TODO: better fix for backclicked
    this.location.back();
    // if (this.patientId) {
    //   this.router.navigate(['/narrative/', this.patientId]);
    // } else if (this.route.snapshot.params['id']) {
    //   this.router.navigate(['/narrative/', this.route.snapshot.params['id']]);
    // } else {
    //   // We dont have narrative,use this or use the id parameter and navigate back to it
    //   this.location.back();
    // }
  }

  /**
   * function to expire sharelinks
   * @param event event
   * @param shareLink sharelink to be expired
   */
  expireShareLink(event: Event, shareLink: ShareLinks) {
    this.dialogRef = this.dialog.open(ExpireSharelinkDialogComponent);
    event.preventDefault();

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        shareLink.expired = true;

        this.patientService.updateShareLink(shareLink)
          .then(updatedSharelink => {
            this.shareLinks = this.shareLinks.filter(h => h !== shareLink);
            this.shareLinks.push(updatedSharelink);

            this.snackBar.open('Sharelink has been expired.', 'Success', { duration: 6000 });
          })
          .catch(error => { this.snackBar.open('Error updating sharelink.', 'Error') });
      } else {
        // when either cancel or nothing is selected
      }

      this.dialogRef = null;
    })
  }

  /** function forward the case to a specialist */
  showForwardSharelinkForm(sharelink: ShareLinks, mediator: Mediator): void {
    this.selectedSharelink = sharelink;

    // get narrative
    this.narrativeService.getSingleNarrative(sharelink.narrativeId)
      .then(narrative => {
        this.narrative = narrative;
      })
      .catch(error => {
        console.log('Error: getting narrative', error);
      })
  }

  /** function to cancel forwarding the case to a specialist */
  cancelForwardSharelink(): void {
    this.selectedSharelink = null;
  }

  /**
   * function to forward to a specialist via email
   */
  forwardSharelink(): void {
    const recipientEmail = this.forwardShareLinksForm.controls['email'].value;
    const recipientName = this.forwardShareLinksForm.controls['specialistName'].value;
    const sharelinkUrl = this.selectedSharelink.shortUrl;
    let sharelinkUser: User;
    let sharelinkPatient: Patient;

    sharelinkPatient = this.selectedSharelink.patient;
    const patientAge = moment(sharelinkPatient.dateOfBirth).fromNow(true);

    this.authenticationService.getDbUser(this.selectedSharelink.assignedMediatorUsername)
      .then(user => {
        console.log('user: ' + user.username);
        sharelinkUser = user;

        // get the facility name
        const facility = this.getFacility(this.selectedSharelink.createFacility);

        let subject = ` Case of ${patientAge} old ${this.selectedSharelink.patient.sex} with ${this.narrative.presumptiveDiagnosis}
        `

        let body =
          `Dear Dr ${recipientName},

Thank you for agreeing to provide an online consultation for this case.
The mediator for this case is ${this.selectedSharelink.assignedMediatorUsername}, a ${sharelinkUser.cadre} working at ${facility}
 in Turkana County. Contact number: ${sharelinkUser.phone}.

I have outlined the steps to follow when providing a remote consultation. You can access the case summary by clicking on the link below:
${sharelinkUrl}

Steps for remote consultation:
1. Click on the link received by email (ideally using the Chrome browser)
2. Review the case
3. Optional: If you would like to call the mediator, you can use the number provided to have a discussion,
and use the 'Appointment' button to schedule the call. Alternatively you could chat with the mediator using the 'Chat' button
on the top of the page.
4. If you have all the information you need to answer the questions on the case, please click on the ‘My Report’ button on the top of
the page to write your report.
- All fields are required to be filled.
- Please press the ‘Save and Publish’ button to complete the consultation.
Do let me know if you need further information.

Best wishes`;
        subject = encodeURIComponent(subject);
        body = encodeURIComponent(body);
        window.open('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=' + recipientEmail +
          '&cc=salima@health-e-net.org,pratap@health-e-net.org + &su=' + subject + '&body=' +
          body, '_blank');

      })
      .catch(error => {
        console.log(error);
        this.snackBar.open('User loading Error', 'Error', { duration: 6000 });
      });
  }
}
