import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { PatientService } from '../../../services/patient.service';
import { Facility } from '../../../models/facility';
import { ShareLinks } from '../../../models/sharelinks';
import { NarrativeService } from '../../../services/narrative.service';
import { ObstetricNarrative, scanningIndication } from '../../../models/obstetric-narrative';
import { SonographerBlocks } from '../../../models/obstetric-sharelink';
import { FacilityService } from '../../../services/facility.service';
import { RepositoryService } from '../../../services/repository.service';
import { AppSettingsService } from '../../../services/settings.service';
import { AppSettings } from '../../../models/config';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'psoc-sonographer-pdf-report',
  templateUrl: 'sonographer-report-view.component.html'
})

export class SonographerReportViewComponent implements OnInit {
  @ViewChild('printPdf') pdfPrint: ElementRef;

  firstTrimester: Boolean = false;
  sharelinkId: string;
  sharelink: ShareLinks;
  sonographerData: SonographerBlocks;
  narrative: ObstetricNarrative;
  selectedFoetus: number = 0;
  currentSharelink: string;
  appSettings: AppSettings;
  env = environment;

  loadingSharelink: boolean = false;
  loadingNarrative: boolean = false;
  loadingAppSettings: boolean = false;

  facilities: Facility[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private facilityService: FacilityService,
    private narrativeService: NarrativeService,
    private repositoryService: RepositoryService,
    private appSettingsService: AppSettingsService
  ) {
    this.currentSharelink = this.route.snapshot.params['id'];
    this.sharelinkId = this.route.snapshot.params['sharelinkid'];
  }

  ngOnInit() {
    if (this.currentSharelink) {
      // load connection to sharelinks dbs
      this.repositoryService.loadSpecialistDb(this.currentSharelink);
      // call function to load settings file
      this.loadAppSettings();
    } else {
      this.getFacilities();
    }
    this.getSharelink();
  }

  /**
   * function to navigate back to narrative
   */
  backClicked(e: Event) {
    e.preventDefault();
    if (this.currentSharelink) {
      this.router.navigate(['/' + this.currentSharelink]);
      this.patientService.hideNav = false;
    } else {
      this.router.navigate(['/patient/' + this.sharelink.patientId + '/narrative/' + this.sharelink.narrativeId + '/view/'
        + this.narrative.narrativeType]);
    }
  }

  /**
   * function to download pdf
   */
  getPdf() {
    window.document.title = 'Sonographer' + '-' + 'report' + '-' + this.sharelink.patientId + '-' + this.sharelink.datePublished;
    window.print();
  }

  /**
   * fetch single sharelink data
   */
  getSharelink() {
    this.loadingSharelink = true;
    this.patientService.getSingleSharedLinkData(this.sharelinkId)
      .then(sharelink => {
        this.sharelink = sharelink;
        this.sonographerData = sharelink.sonographerBlocks;
        this.loadingSharelink = false;
        this.loadingNarrative = true;

        // get narrative
        this.narrativeService.getSingleNarrative(this.sharelink.narrativeId)
          .then((narrative: any) => {
            if (narrative.scanIndication === scanningIndication[0]) { this.firstTrimester = true }
            this.narrative = narrative;
            this.loadingNarrative = false;
          })
          .catch(error => {
            console.log('Error fetching Narrative:', error);
          });
      })
      .catch(error => {
        console.log('Error geting sharelink:', error);
      });
  }

  /**
   * fetch list of facilities
   */
  getFacilities() {
    this.facilityService.getFacilities()
      .then(facilities => {
        this.facilities = facilities;
      })
      .catch(error => {
        console.log('Error fetching facilities:', error);
        this.snackBar.open('Error Getting Facilities', 'Error', { duration: 6000 });
      });
  }

  /**
  * Load app settings
  */
  loadAppSettings(): void {
    this.loadingAppSettings = true;
    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        this.loadingAppSettings = false;
      }).catch(error => {
        this.loadingAppSettings = false;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }
}
