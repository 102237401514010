import { PouchObject } from './pouch-object';

export class Attachment implements PouchObject {
  public static type: string = 'attachment';
  public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'linked', 'attachmentTitle', 'attachmentFileName', 'dateOnAttachment',
  'mediatype', 'size', 'patientId', 'narrativeId', 'dateAdded', 'dateUpdated', 'file', 'dicomThumbnails', '_attachments', 'updatedBy',
  'createdBy', 'createFacility', 'updateFacility', 'sharedFacility', 'studyUuid'];
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  type: string;
  linked: number = 0; // count of times the attachment has been linked to a segment
  attachmentTitle: string;
  attachmentFileName: string;
  dateOnAttachment: Date;  // NotNull, Date, NotFutureDate
  mediatype?: string;
  size?: string;
  patientId: number;
  narrativeId: number;
  dateAdded: Date;
  dateUpdated: Date;
  file?: File;
  dicomThumbnails: string[];
  _attachments?;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;
  sharedFacility: string[];

  // dicom file references
  studyUuid?: string;
  reportLink?: string;
  attachmentType: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export interface PatientRequest {
  type: string;
  blockId: string;
  segmentNum: number;
  requestDescription: string;
  areaOfRequest: string;
}
