import { Component, Input } from '@angular/core';
import { Narrative } from '../../models/narrative';

@Component({
    selector: 'psoc-questions-view',
    templateUrl: './questions-view.component.html'
})
export class QuestionsViewComponent {
    @Input() questions: Narrative;
    @Input() referralType: string;
}
