import { Component, Input, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Attachment } from '../../../models/attachment';
import { environment } from '../../../../environments/environment';
import { DicomModalComponent } from '../../../attachments/dicom/modal/dicom-modal.component';



@Component({
  selector: 'psoc-specialist-attachments-view',
  templateUrl: 'attachments.component.html'
})

export class SpecialistAttachmentsComponent {
  @Input() attachments: Attachment[];
  @Input() patientId: number;
  selectedAttachment: Attachment;
  showEdit = false;

  dialogRef: MatDialogRef<any>;

  file: File;
  imgSrc: SafeUrl;
  imgPreviewSrc: SafeUrl;

  sharelinkId: string;
  seriesForStudy = new Array<any>();

  @ViewChild(DicomModalComponent) public dicomModal: DicomModalComponent

  /**
   *show selected attachment
  */
  selectAttachment(attachment: Attachment): void {
    this.selectedAttachment = attachment;

    if (attachment.mediatype === 'application/dicom' && !attachment._attachments) {
      this.imgSrc = true;
      this.imgPreviewSrc = 'assets/images/placeholder-dicom.png'
    } else {
      const firstFileName = Object.keys(attachment._attachments)[0];
      // sharelinkID
      this.sharelinkId = this.route.snapshot.params['id'];
      const fileUrl = environment.sharelinksCouchURL + '/' + this.sharelinkId + '/' + attachment._id + '/file';
      this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(fileUrl);
      this.imgPreviewSrc = this.imgSrc;

      if (attachment._attachments[firstFileName].content_type === 'application/pdf') {
        this.imgPreviewSrc = 'assets/images/placeholder-pdf.png'
      }
    }

  }

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { }

  // show empty form
  unselectAttachment() {
    this.selectedAttachment = null;
  }

  /**
   * Open dialog to view study
  */
  viewDicomDialog(studyUUID: string): void {
    this.dicomModal.showStudy(studyUUID)
  }
}
