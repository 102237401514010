import { Component } from '@angular/core';
import { Globals } from '../services/globals';

@Component({
  selector: 'psoc-patients',
  template: `<psoc-patient-list></psoc-patient-list>`
})

export class PatientsComponent {

  constructor(
    private globals: Globals
  ) {
    // update current global patient id
    this.globals.showShareLinkPatientId.emit(+(''));
  }

}
