import { Component, Input } from '@angular/core';
import { FoetalAbdomenBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
    selector: 'psoc-foetal-abdomen-nurse-view',
    templateUrl: './foetal-abdomen-nurse-view.component.html'
})

export class FoetalAbdomenNurseViewComponent extends ObstetricBlockViewComponent {
    // data for this block
    _foetalAbdomenBlock: FoetalAbdomenBlock;
    @Input()
    set foetalAbdomenBlock(foetalAbdomenBlock: FoetalAbdomenBlock) {
        this._foetalAbdomenBlock = foetalAbdomenBlock;
        this.getAttachmentFilesCount(foetalAbdomenBlock.attachmentIds);
    }
    get foetalAbdomenBlock(): FoetalAbdomenBlock {
        return this._foetalAbdomenBlock;
    }

    /**
     * Check if data is valid for this block
     */
    blockValid() {
        return (this.foetalAbdomenBlock && this.foetalAbdomenBlock.blockStatus) ? true : false;
    }
}
