import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { MaternalAnatomyBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockEditComponent } from '../block-edit.component';
import { NarrativeService } from '../../../../services/narrative.service';
import { AttachmentService } from '../../../../services/attachment.service';
import { Attachment } from '../../../../models/attachment';
import { Patient } from '../../../../models/patient';

@Component({
    selector: 'psoc-maternal-anatomy-edit',
    templateUrl: './maternal-anatomy-nurse-edit.component.html'
})

export class MaternalAnatomyNurseEditComponent extends ObstetricBlockEditComponent implements OnInit {
    _blockData: MaternalAnatomyBlock;
    @Input() get blockData() { return this._blockData }
    set blockData(blockData: MaternalAnatomyBlock) {
        if (blockData) { this._blockData = blockData } else { this._blockData = new MaternalAnatomyBlock(); }
        this.blockDataChange.emit(this._blockData)
        this.getAttachmentFilesCount(this._blockData.attachmentIds);
    };
    @Output() blockDataChange: EventEmitter<MaternalAnatomyBlock> = new EventEmitter<MaternalAnatomyBlock>();

    @Output() formUpdated: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() openNextBlock: EventEmitter<any> = new EventEmitter;

    blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];
    showBlock: boolean = false;
    @Input() patient: Patient;

    dialogRef: MatDialogRef<any>;
    @ViewChild('dialogAnchor', { read: ViewContainerRef }) dialogAnchor: ViewContainerRef;

    public selectedAttachment: Attachment;
    public attachments: Attachment[] = [];
    public attachmentIds: string[] = [];

    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        public narrativeService: NarrativeService,
        public attachmentService: AttachmentService,
      ) {
        super(dialog, snackBar, sanitizer, narrativeService, attachmentService);

        this.form = this.fb.group({
            blockStatus: ['', [Validators.required]],
            nurseNotes: ['']
        });
    }

    /**
     * Check if data is valid for this block
     */
    public blockValid() {
        return (this.blockData && this.blockData.blockStatus) ? true : false;
    }

    ngOnInit(): void {
        this.updateForm();  // call function to update parent form with block form
    }

}
