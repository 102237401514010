import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Narrative, Covid19Screening } from '../../../models/narrative';
import { Attachment } from '../../../models/attachment';
import { Patient } from '../../../models/patient';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { AttachmentModal } from '../../../attachments/attachment-modal';
import { AttachmentService } from '../../../services/attachment.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { environment } from '../../../../environments/environment';
import { DicomModalComponent } from '../../../attachments/dicom/modal/dicom-modal.component';
import { AppSettings } from '../../../models/config';

@Component({
  selector: 'psoc-shared-links-narrative-view',
  templateUrl: 'shared-links-narrative-view.component.html',
})

export class SharedLinksNarrativeViewComponent {

  @Input() patient: Patient;
  @Input() set myNarrative(myNarrative: Narrative) {
    this._myNarrative = myNarrative;
    if (!this.myNarrative.covid19Screening) { this.myNarrative.covid19Screening = new Covid19Screening() }
  } get myNarrative(): Narrative { return this._myNarrative }
  @Input() referredFromFacility;
  @Input() referredToFacility;
  @Input() appSettings: AppSettings;

  _myNarrative: Narrative;
  myAttachmentsModel: Attachment;
  seriesForStudy = new Array<any>();
  environment = environment;

  @ViewChild(DicomModalComponent) public dicomModal: DicomModalComponent

  // attachments dialog
  dialogRef: MatDialogRef<any>;
  selectedAttachment: Attachment;
  config: MatDialogConfig = { width: '95vw', height: '95vh' };

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService,
    private attachmentService: AttachmentService
  ) { }

  specialistViewSharedLinkReport(sharedLinkId: any): void {
    console.log('Show specialist view of shared link: ' + sharedLinkId);
    this.router.navigate(['/' + sharedLinkId + '/report/']);
  }

  specialistViewSharedLinkDischargeSummary(sharedLinkId: any): void {
    console.log('Show specialist view of shared link: ' + sharedLinkId);
    this.router.navigate(['/' + sharedLinkId + '/discharge-summary/']);
  }

  /** open the image view dialog*/
  openAttachmentModal(attachment: Attachment): void {
    this.dialogRef = this.dialog.open(AttachmentModal, this.config);
    this.dialogRef.componentInstance.setServices(this.attachmentService, this.authService);
    this.dialogRef.componentInstance.setAttachment(attachment);
    this.dialogRef.componentInstance.disableEditing = true;
    this.dialogRef.componentInstance.setDisableEdit(true);
    this.dialogRef.componentInstance.setSettings(this.appSettings);
  }

  /**
   * Open dialog to view study
  */
  viewDicomDialog(studyUUID: string): void {
    this.dicomModal.showStudy(studyUUID)
  }

}
