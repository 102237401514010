import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { Events } from '../models/events';

@Injectable()
export class EventsService {

  constructor(private repository: RepositoryService) { }

  getEvents(): Promise<Events[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Events.type, Databases.eventsDb)
        .then((result) => {
          const events: Events[] = result.docs.map((doc: any) => this.mapObjectToEvent(doc));
          resolve(events);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  getEventsByObjectId(objectId: string): Promise<Events[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(Events.type, Events.fields, 'objectId', objectId, [{ _id: 'desc' }], Databases.eventsDb)
        .then((result) => {
          const events: Events[] = result.docs.map((doc: any) => this.mapObjectToEvent(doc));
          resolve(events);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  private mapObjectToEvent(object: any): Events {
    let events: Events = new Events();
    return events = { ...object };
  }

}
