import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NumberOfFoetusBlock } from '../../../../models/obstetric-narrative';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SonographerBlockNumberOfFoetus } from '../../../../models/obstetric-sharelink';
import { SonographerBlockEditComponent } from '../block-sonographer-edit.component';

@Component({
  selector: 'psoc-number-foetuses-sonographer-edit',
  templateUrl: 'number-foetuses-sonographer-edit.component.html'
})

export class NumberFoetusesSonographerEditComponent extends SonographerBlockEditComponent {
  @Input() blockData: SonographerBlockNumberOfFoetus;
  @Input() nurseData: NumberOfFoetusBlock;
  @Output() nextBlock: EventEmitter<any> = new EventEmitter;
  form: FormGroup;
  numberOfFoetusValid = false;

  blockStatusOptions = ['Normal', 'Abnormal', 'Not Clear'];

  constructor(
    private _fb: FormBuilder
  ) {
    super();
    this.form = this._fb.group({
      numberOfFoetus: [''],
      cantConfirmNumberOfFoetus: [''],
      foetalStatus: [''],
      sonographerNotes: ['']
    });
  }

  /**
   * function to navigate to the next edit block
   */
  viewNextBlock() {
    this.nextBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    this.numberOfFoetusValid = (this.blockData.numberOfFoetus > 0 || this.blockData.cantConfirmNumberOfFoetus)
    return this.numberOfFoetusValid && this.blockData.blockStatus ? true : false;
  }

}
