import { PouchObject } from './pouch-object';
// import { CadreOptions } from './user';

export class Mediator implements PouchObject {
    public static type: string = 'mediators';
    public static fields = ['_id', '_rev', '_deleted', 'username', 'facility', 'type', 'cadre', 'dateAdded', 'dateUpdated', 'updatedBy',
        'createdBy', 'createFacility', 'updateFacility', 'userRole', 'firstName', 'lastname', 'phone', 'emailAddress', 'boardnumber', 'qualification'];
    _id: string;
    _rev: string;
    _deleted: boolean = false;
    username: string;
    firstName: string;
    lastname: string;
    emailAddress: string;
    boardnumber: string;
    qualification: string;
    userRole: string;
    phone: string;
    type: string;
    dateAdded: Date;
    facility: string;
    dateUpdated: Date;
    updatedBy: String;
    createdBy: String;
    cadre: string;
    createFacility: String;
    updateFacility: String;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}
