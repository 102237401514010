import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockHeart } from '../../../../models/obstetric-sharelink';
import { HeartBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-heart-sonographer-view',
  templateUrl: './heart-sonographer-view.component.html'
})

export class HeartSonographerViewComponent {
  @Input() nurseData: HeartBlock;
  @Input() sonographerData: SonographerBlockHeart;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() { }

  /**
   * trigger edit for current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return this.sonographerData.blockStatus &&
      (this.sonographerData.heartbeatFrequency > 0 || this.sonographerData.cantConfirmHeartbeatFrequency) ? true : false;
  }
}
