import { Component, Input, EventEmitter, Output, Inject, OnInit, ViewChild } from '@angular/core';
import { Narrative, ReferralStatus, Covid19Screening } from '../../models/narrative';
import { RepositoryService } from '../../services/repository.service';
import { NarrativeService } from '../../services/narrative.service';
import { PatientService } from '../../services/patient.service';
import { Attachment, PatientRequest } from '../../models/attachment';
import { AttachmentService } from '../../services/attachment.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Patient } from '../../models/patient';
import { AppSettings } from '../../models/config';
import { Mediator } from '../../models/mediators';
import { ShareLinks, SpecialistInfo } from '../../models/sharelinks';
import { ReferralEnumTypes } from '../../models/narrative-types';
import { MatDialog, MatDialogRef, MatDialogConfig, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AttachmentModal } from '../../attachments/attachment-modal';
import { Facility } from '../../models/facility';
import { GetUserNames } from '../../pipes/getUserNames';

import { DicomModalComponent } from '../../attachments/dicom/modal/dicom-modal.component';

@Component({
  template: `
    <h2>Are you sure you want to refer this case On?</h2>
    <form [formGroup]="referOnForm">
      <div class='form-group'>
        <label class='title'><strong>Referral From: </strong></label>
        <mat-form-field>
          <mat-select placeholder='Referral From' formControlName="from" [(ngModel)]='narrative.referral.referral_from'>
            <mat-option [value]="narrative.referral.referral_from">
             {{facility.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <label class='title'><strong>Referral To: </strong></label>
        <mat-form-field>
          <mat-select placeholder='Referral To' formControlName="to" [(ngModel)]='narrative.referral.referral_facility'>
            <mat-option [value]=''>Select To</mat-option>
            <mat-option *ngFor="let facility of facilities" [value]='facility.code'>
              {{facility.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <hr>
    <p class='uk-text-center'>
      <mat-hint>By clicking <strong>Yes (ReferOn)</strong> you agree to refer this case on.</mat-hint>
    </p>
    <div class='uk-margin-top uk-text-center'>
      <button type="button" mat-raised-button class='width45' color="primary" (click)="dialogRefer.close(true)">Yes (ReferOn)</button>
      <button type="button" mat-raised-button class='width45' color="warn" (click)="dialogRefer.close()">No (Cancel)</button>
    </div>
  `,
})
export class DialogConfirmReferOnComponent {
  narrative: Narrative;
  facility: Facility;
  facilities: Facility[];
  referOnForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthenticationService,
    private formBuilder: FormBuilder,
    public dialogRefer: MatDialogRef<any>
  ) {

    // load data
    this.narrative = this.data.narrative;
    this.facility = this.data.facility;
    this.facilities = this.data.facilities;

    this.narrative.referral.referral_from = this.facility._id;

    this.referOnForm = this.formBuilder.group({
      'from': ['', [Validators.required, Validators.maxLength(255)]],
      'to': ['', [Validators.required, Validators.maxLength(255)]]
    });
  }
}

@Component({
  selector: 'psoc-narrative-view',
  templateUrl: 'narrative-view.component.html',
  providers: [GetUserNames]
})

export class NarrativeViewComponent implements OnInit {

  pathOrigin: string;
  @Input() patient: Patient;
  @Input() appSettings: AppSettings;
  @Input() sharelinks: ShareLinks[];
  @Input() facilities: Facility[];
  @Input() sharedLinkPatientId: string;
  @Input() specialistList: Mediator[];
  @Input() patientRequests: PatientRequest[];
  @Input() set myNarrative(myNarrative: Narrative) {
    this._myNarrative = myNarrative;
    if (!this.myNarrative.covid19Screening) { this.myNarrative.covid19Screening = new Covid19Screening() }
  } get myNarrative(): Narrative { return this._myNarrative }
  @Input() shareLinksPatientId: string;
  @Output() editNarrative = new EventEmitter();

  _myNarrative: Narrative;
  shareLinksModel: ShareLinks;

  @ViewChild(DicomModalComponent) public dicomModal: DicomModalComponent

  // facility objects
  userFacility: Facility;
  referredFromFacility: Facility;
  referredToFacility: Facility;
  createFacility: Facility;
  sharedFacility: string[] = [];

  displayedMessage: string;
  disableReferButton: boolean = true;
  hideDischargeSummary: boolean = true;
  disableReferOnButton: boolean = true;
  caseShared: boolean = false;

  caseSharedMessage = 'Case has already been shared with the specialist';
  patientRequestsMessage = '';

  referralLinks: any[] = [];

  // refer on dialog
  dialogRefer: MatDialogRef<any>;

  // consultation
  disableConsultButton: boolean = true;

  seriesForStudy = new Array<any>();

  // attachments dialog
  dialogRef: MatDialogRef<any>;
  selectedAttachment: Attachment;
  config: MatDialogConfig = { width: '95vw', height: '95vh' };

  ngOnInit() {
    this.pathOrigin = window.location.origin;

    // get shareLinks
    this.getShareLinks();

    // get facilities
    this.findFacilityNames();
  }

  constructor(
    private dialog: MatDialog,
    private repositoryService: RepositoryService,
    private attachmentService: AttachmentService,
    public authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private getUserNames: GetUserNames,
    private narrativeService: NarrativeService,
    private patientService: PatientService
  ) {
    this.shareLinksModel = new ShareLinks();
  }

  /**
   * enable the edit mode of the narrative
   */
  showEditNarrative() {
    this.editNarrative.emit();
  }

  /**
   * function to get the referral shareLinks
   */
  getShareLinks() {
    if (this.myNarrative.referralType === ReferralEnumTypes.consultation) {
      // get narrative shareLink
      const shareLinks = this.sharelinks.filter(s => s.narrativeId === +this.myNarrative._id && !this.patientService.isRequestLink(s._id));

      // set the shared case is true
      if (shareLinks.length > 0) { this.caseShared = true; }
    }

    // check shareLinks if any referral
    this.sharelinks.forEach((shareLink: ShareLinks) => {
      if (shareLink.expired === false && shareLink.intent !== 'Consultation' && shareLink.comments === 'auto_referred_case') {
        this.referralLinks.push(shareLink)
      }
    })

    if (this.referralLinks.length === 0) {
      this.hideDischargeSummary = true;
    }
  }

  /**
   * find names of current facilities
   */
  findFacilityNames() {

    this.userFacility = (this.facilities.filter(facility => {
      return facility._id === this.authService.getUser().facility
    }))[0];

    if (this.myNarrative.referralType !== 'Consultation' && this.myNarrative.referral) { // check if we have a referral to deal with

      this.referredFromFacility = (this.facilities.filter(facility => {
        return this.myNarrative.referral.referral_from === facility.code;
      }))[0];

      this.referredToFacility = (this.facilities.filter(facility => {
        return this.myNarrative.referral.referral_facility === facility.code;
      }))[0];

    }

    this.createFacility = this.facilities.filter(facility => {
      return this.myNarrative.createFacility === facility.code
    })[0];

    // console.log('Facility search (for loading names) complete ',
    //   this.userFacility,
    //   this.referredFromFacility,
    //   this.referredToFacility,
    //   this.createFacility
    // )

    // get messages to display to narrative
    this.getDisplayedMessage();
  }

  /**
   * call function to edit discharge summary
   */
  showEditDischargeSummary() {
    const sharelink = this.referralLinks[this.referralLinks.length - 1];

    if (sharelink.expired === false && sharelink.intent !== 'Consultation') {
      // redirect to the specialist app
      window.open(this.pathOrigin + '/shared/' + sharelink._id, '_blank');
    } else {
      // cant open the sharelink
      this.snackBar.open('The sharelink is either Expired | Not a Referral', 'Error', { duration: 6000 });
    }
  }

  /**
   * open the attachment view dialog
   * @param {Attachment} attachment
   */
  openAttachmentModal(attachment: Attachment): void {
    this.dialogRef = this.dialog.open(AttachmentModal, this.config);
    this.dialogRef.componentInstance.setServices(this.attachmentService, this.authService);
    this.dialogRef.componentInstance.setAttachment(attachment);
    this.dialogRef.componentInstance.disableEditing = true;
    this.dialogRef.componentInstance.setDisableEdit(true);
    this.dialogRef.componentInstance.setSettings(this.appSettings);
  }

  /**
   * Open dialog to view study
   * @param {string} studyUID
   */
  viewDicomDialog(studyUID: string) {
    this.dicomModal.showStudy(studyUID)
  }

  /**
   * call function on click of refer case
   */
  confirmReferOn() {
    this.dialogRefer = this.dialog.open(DialogConfirmReferOnComponent,
      {
        data: {
          narrative: this.myNarrative,
          facilities: this.facilities,
          facility: this.userFacility
        }
      });

    this.dialogRefer.afterClosed().subscribe(result => {

      if (result === undefined || this.myNarrative.referral.referral_facility === this.userFacility._id) {
        /**
          don't change anything
          user has cancelled out or
          can't refer to your own facility
        */
        this.myNarrative.referral.referral_from = this.myNarrative.createFacility;
        this.myNarrative.referral.referral_facility = this.userFacility._id;
      } else {
        // update narrative
        this.myNarrative.referral.referral_from = this.userFacility._id;

        // call function to update all objects
        this.referCase(ReferralStatus.referredOn);
      }

      this.dialogRefer = null;
    });
  }

  /**
   * functionality to share a consultation case
   */
  consultCase() {
    this.shareLinksModel.comments = `Auto_${this.myNarrative.referralType}`;
    if (this.appSettings.narrativeSettings.consultationFields && this.myNarrative.referralType === ReferralEnumTypes.consultation) {
      if (!this.shareLinksModel.specialistInfo) { this.shareLinksModel.specialistInfo = new SpecialistInfo() }
      const specialistUserName = this.myNarrative.referral.referral_specialist;
      this.shareLinksModel.specialistInfo.name = this.getUserNames
        .transform(specialistUserName, this.specialistList);
      const specialist = this.specialistList.find(s => s.username === specialistUserName); // get specialist

      this.shareLinksModel.specialistInfo.boardNumber = specialist.boardnumber ? specialist.boardnumber : '';
      this.shareLinksModel.specialistInfo.qualifications = specialist.qualification ? specialist.qualification : '';
      this.shareLinksModel.specialistInfo.phoneNumber = specialist.phone ? specialist.phone : '';
      this.shareLinksModel.specialistInfo.email = specialist.emailAddress ? specialist.emailAddress : '';

      this.patientService.saveShareLink(this.myNarrative, this.patient, this.shareLinksModel)
        .then(savedShareLink => {
          console.log('ShareLink saved successfully', savedShareLink._id);
          this.caseShared = true; // set case shared to true

          // update narrative case status history
          this.narrativeService.updateStatusHistory(this.myNarrative, ReferralStatus.shared, 'Case shared with specialist');

          // update narrative status
          this.myNarrative.referralStatus = ReferralStatus.shared;
          this.narrativeService.updateNarrative(this.myNarrative)
            .then(updatedNarrative => {
              this.myNarrative = updatedNarrative;
            })
            .catch(error => {
              this.snackBar.open('Error Updating narrative status', 'Error', { duration: 6000 });
            })
        }).catch(error => this.snackBar.open('Error saving sharelink', 'Error', { duration: 6000 }));
    }
  }

  /**
   * functionality to refer/share case
   * @param referralStatus
   */
  referCase(referralStatus: ReferralStatus = ReferralStatus.referred) {
    event.preventDefault();
    // update the patients related objects [patient, narrative, documents, chats, appointments, sharelinks]

    if (this.myNarrative.sharedFacility === undefined || !this.myNarrative.sharedFacility.includes(this.patient.createFacility)) {
      // first time refer
      this.sharedFacility.push(this.myNarrative.referral.referral_facility);
      this.sharedFacility.push(this.patient.createFacility);
    } else {
      // update the shared facility
      if (!this.myNarrative.sharedFacility.includes(this.myNarrative.referral.referral_facility)) {
        this.myNarrative.sharedFacility.push(this.myNarrative.referral.referral_facility);
        this.sharedFacility = this.myNarrative.sharedFacility.slice();
      } else {
        this.sharedFacility = this.myNarrative.sharedFacility.slice();
        console.log('Already shared to this facility: ' + this.myNarrative.referral.referral_facility);
      }
    }

    // update narrative
    this.myNarrative.sharedFacility = this.sharedFacility.slice();
    this.myNarrative.referralStatus = referralStatus;

    // update narrative case status history
    this.narrativeService.updateStatusHistory(this.myNarrative, referralStatus, '');

    this.narrativeService.updateNarrative(this.myNarrative)
      .then(updatedNarrative => {
        this.myNarrative = updatedNarrative;

        // generate sharelink
        this.shareLinksModel.sharedFacility = this.sharedFacility.slice();
        this.shareLinksModel.comments = 'auto_referred_case';

        this.patientService.saveShareLink(updatedNarrative, this.patient, this.shareLinksModel).then(savedSharelink => {
          this.sharelinks.push(savedSharelink);
          // console.log(JSON.stringify(updatedSharelink));
          this.snackBar.open('Share link generated and saved.', 'Success', { duration: 6000 });

          // update patient
          this.patient.sharedFacility = this.sharedFacility;

          this.patientService.updatePatient(this.patient)
            .then(updatedPatient => {
              this.patient = updatedPatient;

              // update the attachments | appointments | chats of current patient
              this.patientService.referCase(+(this.patient._id), this.sharedFacility).then(() => {

                // DONE UPDATING
                console.log('DONE UPDATING ALL OBJECTS...');

                // add an event status changed
                this.repositoryService.addEvents(
                  this.myNarrative.type,
                  this.myNarrative._id,
                  'statusUpdated',
                  this.authService.getUser().username,
                  this.authService.getUser().ipAddress,
                  referralStatus,
                  this.myNarrative.patientId)

                this.findFacilityNames();

              }).catch(err => {
                console.log('Error Updating: ' + err);
                this.snackBar.open('Updating Patient Objects Error', 'Error', { duration: 300 });
              });

            }).catch(err => {
              console.log('Error Updating Patient: ' + err);
              this.snackBar.open('Updating Patient error!', 'Error', { duration: 3000 });
            });

        }).catch(error => {
          this.snackBar.open('Error updating sharelink: ' + error, 'Error');
        });

      }).catch(err => {
        this.snackBar.open('Updating Narrative error!', 'Error', { duration: 3000 });
      });
  }

  /**
   * get narrative messages banners
   */
  getDisplayedMessage() {
    if (this.myNarrative.referralType !== 'Consultation' && this.myNarrative.referral) {
      const referringFrom = (this.referredFromFacility === undefined ? ' (Not selected) ' : this.referredFromFacility.name);
      const referringTo = (this.referredToFacility === undefined ? ' (Not selected) ' : this.referredToFacility.name);

      switch (this.myNarrative.referralStatus) {
        case ReferralStatus.referred:
          this.disableReferButton = true;
          this.hideDischargeSummary = false;
          this.disableReferOnButton = false;
          this.displayedMessage = `Case has been referred from ${referringFrom} to ${referringTo}`;
          break;

        case ReferralStatus.caseViewed:
          this.disableReferButton = true
          this.hideDischargeSummary = false;
          this.disableReferOnButton = false;
          this.displayedMessage = `Case referred from ${referringFrom} to ${referringTo} has been viewed`;
          break;

        case ReferralStatus.contacted:
          this.disableReferButton = true
          this.hideDischargeSummary = false;
          this.disableReferOnButton = false;
          this.displayedMessage = `Patient has been contacted`;
          break

        case ReferralStatus.arrived:
          this.disableReferButton = true;
          this.hideDischargeSummary = false;
          this.disableReferOnButton = false;
          this.displayedMessage = `Case has arrived at ${referringTo} from ${referringFrom}`
          break;

        case ReferralStatus.noActionTaken:
          this.disableReferButton = true
          this.hideDischargeSummary = false;
          this.disableReferOnButton = true;
          this.displayedMessage = `Case referred from ${referringFrom} to ${referringTo}: No action taken`
          break;

        case ReferralStatus.notArrived:
          this.disableReferButton = true;
          this.hideDischargeSummary = true;
          this.displayedMessage = `Case referred from ${referringFrom} did not arrive at ${referringTo}`;
          break;

        case ReferralStatus.admitted:
          this.disableReferButton = true;
          this.hideDischargeSummary = false;
          this.disableReferOnButton = false;
          this.displayedMessage = `Case referred from ${referringFrom} has been admitted at ${referringTo}`;
          break;

        case ReferralStatus.dischargedFromOP:
          this.disableReferButton = true;
          this.hideDischargeSummary = true;
          this.displayedMessage = `Case referred from ${referringFrom} was discharged from OP at ${referringTo}, without Discharge Summary`;
          break;

        case ReferralStatus.dischargedWithDS:
          this.disableReferButton = true;
          this.hideDischargeSummary = true;
          this.displayedMessage = `Case referred from ${referringFrom} was discharged from ${referringTo}, with Discharge Summary`;
          break;

        case ReferralStatus.dischargedWithoutDS:
          this.disableReferButton = true;
          this.hideDischargeSummary = true;
          this.displayedMessage = `Case referred from ${referringFrom} was discharged from ${referringTo} without Discharge Summary`;
          break;

        case ReferralStatus.referredOn:
          this.disableReferButton = true;
          this.hideDischargeSummary = true;
          if (this.authService.getUser().facility === this.myNarrative.referral.referral_facility) {
            this.hideDischargeSummary = false;
            this.disableReferOnButton = false;
          }
          this.displayedMessage = `Case referred from ${this.createFacility.name} was referred on from ${referringFrom}  to ${referringTo}`;
          break;

        default:
          if (this.myNarrative.referral.referral_details_consent_share_info === 'No') {
            this.disableReferButton = true;
            this.displayedMessage = `Note: Consent required to refer this case`;
          } else {
            this.disableReferButton = false;
            this.displayedMessage = `This case is ready to be referred from ${referringFrom} to ${referringTo}`;
          }
          break;
      }
    } else if (this.myNarrative.referralType === 'Consultation' && this.appSettings.narrativeSettings.consultationFields) {
      const referringFrom = this.facilities.find(f => f.code === this.myNarrative.referral.referral_from).name;
      const specialist = this.specialistList.find(s => s.username === this.myNarrative.referral.referral_specialist);
      const shareTo = specialist ? specialist.firstName : this.myNarrative.referral.referral_specialist;
      if (this.patientRequests.length > 0) {
        this.patientRequestsMessage = `Notification to the patient to upload data has been sent`;
      }
      if (this.myNarrative.referral.referral_details_consent_share_info === 'No') {
        this.disableConsultButton = true;
        this.displayedMessage = `Note: Consent required to share this case`;
      } else {
        this.disableConsultButton = false;
        this.displayedMessage = `This case is ready to be shared from ${referringFrom} to ${shareTo}`;
      }
    }
  }
}
