import { Component, Input } from "@angular/core";
import { ShareLinks } from '../../../models/sharelinks';

@Component({
    selector: 'psoc-shared-links-specialistInfo',
    templateUrl: 'shared-links-specialistInfo.component.html'
})

export class SharedLinksSpecialistInfoComponent {
    @Input() mySharedLink: ShareLinks;
}