import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, Inject } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { Narrative, Question, ReferralStatus } from '../../../models/narrative';
import { NarrativeBlock } from '../../../models/narrative-block';
import { NarrativeService } from '../../../services/narrative.service';
import { Attachment } from '../../../models/attachment';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatOptionSelectionChange } from '@angular/material';
import { RepositoryService } from '../../../services/repository.service';
import { AttachmentModal } from '../../../attachments/attachment-modal';
import { ShareLinks, LabTest, Radiology } from '../../../models/sharelinks';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { ValidationService } from '../../../validation/validation.service';
import { AppSettings } from '../../../models/config';
import { environment } from '../../../../environments/environment';
import { AppSettingsService } from '../../../services/settings.service';

export interface DrugPrescription {
  drugName: string;
  drugClass: string;
  instructions: string;
}
@Component({
  selector: 'psoc-delete-diagnosis-dialog',
  template: `
      <h2>{{title}}</h2>
      <p>{{message}}</p>
      <p><b>Notice:</b> This action cannot be undone</p>
      <p><button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
        <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </p>`
})

export class DeleteDiagnosisDialogComponent {
  title: string;
  message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    this.title = data.title;
    this.message = data.message;
  }
}

@Component({
  selector: 'psoc-shared-links-my-report',
  templateUrl: 'shared-links-my-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SharedLinksMyReportComponent implements OnInit {

  @Input() sharedLinkPatientId: string;
  @Input() myNarrative: Narrative;
  @Input() mySharedLink: ShareLinks;
  @Output() updatedSharedLinksReport: EventEmitter<ShareLinks> = new EventEmitter<ShareLinks>();
  @Input() attachments: Attachment[];
  @Output() viewNarrative = new EventEmitter();
  form: FormGroup;
  sharelinkId: string;
  backupSharelink: ShareLinks;

  // attachments dialog
  dialogRef: MatDialogRef<AttachmentModal>;
  selectedAttachment: Attachment;

  // diagnosis dialog
  diagnosisDialog: MatDialogRef<any>

  private jsonURL = 'assets/data/';
  drugClasses: any[];

  drugList: any[];
  disableFilter: boolean;
  drugNames: string[];
  filteredDrugNames: Observable<string[]>;

  prescription: DrugPrescription = this.clearPrescription();
  displayedColumns: string[] = ['name', 'instructions'];
  dataSource = new MatTableDataSource();

  labTestGroups: any[] = [];
  labtestInfo: LabTest = new LabTest();
  disableLabtestSelect: boolean = false;

  radiology: Radiology = new Radiology();
  disableRadiologySelect: boolean = false;
  radiologies = ['CT scan', 'MRI scan', 'Ultrasound', 'X-ray', 'Mammogram', 'Bone density scan'];

  recomendationsList = [
    { 'recommendation': 'Follow-up with nurse', 'toolTip': 'Routine follow up to update case' },
    { 'recommendation': 'Follow-up with doctor', 'toolTip': 'After test results available' },
    { 'recommendation': 'Refer for face-to-face GP consultation', 'toolTip': 'If physical examination needed' },
    {
      'recommendation': 'Refer for specialist consultation',
      'toolTip': 'If specialist review  needed, either face-to-face or teleconsultation'
    },
    { 'recommendation': 'Other', 'toolTip': 'For any other recommendation' }
  ];

  // boolean to toggle nonicd fields display
  non_ICD10: boolean = false;

  // icd dropdown array
  ICD10_diagnosesOptions: any;

  // load app settings to get client credentials for diagnosis token
  env = environment;
  loadingAppSettings: boolean = true;
  appSettings: AppSettings;

  constructor(
    private _fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private repository: RepositoryService,
    private authService: AuthenticationService,
    private diagnosisService: DiagnosisService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private appSettingsService: AppSettingsService
  ) {

    /* initialize form with empty FormArray for narBlocks */
    this.form = this._fb.group({
      clinicalImpressions: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10000)]],
      diagnosis: [''],
      diagnoses: this._fb.array([]),
      additionalResources: ['', [Validators.maxLength(5000)]],
      blocks: this._fb.array([]),
      questions: this._fb.array([]),
      drugname: new FormControl({ value: '', disabled: true }),
      drugclass: [''],
      keyinName: [false],
      drugnameKeyin: ['', [Validators.minLength(3), Validators.maxLength(1000)]],
      instructions: ['', [Validators.minLength(3), Validators.maxLength(1000)]],
      labtest: [''],
      keyinLabtest: [''],
      labtestcomment: ['', [Validators.minLength(5), Validators.maxLength(1000)]],
      labtestOther: ['', [Validators.maxLength(1000)]],
      radiologyscan: [''],
      keyinRadiology: [''],
      radiologyKeyin: [''],
      onlineDelivery: [true],
      radiologyareaofinterest: [''],
      radiologycomment: ['', [Validators.maxLength(1000)]],
      recommendation: ['', [Validators.required]],
      recommendationNotes: ['', [Validators.maxLength(1000)]],
      specialistInfo: this._fb.group({
        name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
        boardnumber: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
        phonenumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15)]],
        qualifications: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]]
      })
    });
  }

  ngOnInit(): void {
    // get sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id']

    // set up databases
    this.repository.loadSpecialistDb(this.sharelinkId);

    // load sharelink data
    this.getSharelinkData();

    // load app settings
    this.loadAppSettings();

    // get the list of classes from the file
    this.getDataFile('drug-classes.json')
      .then(res => {
        this.drugClasses = res;
      }).catch(error => this.drugClasses = []);

    // get list of labtest
    this.getDataFile('labtests.json')
      .then(res => {
        this.labTestGroups = res;
      }).catch(error => this.labTestGroups = []);

    // subscribe to diagnoses valueChanges
    this.diagnoses.valueChanges.subscribe(diagnoses => {
      const nonicd_length = diagnoses.filter(diagnosis => typeof diagnosis === 'string').length
      this.non_ICD10 = nonicd_length ? true : false;
    })
  }

  /**
   * Load app settings to get client credentials for diagnosis token
   */
  loadAppSettings(): void {
    console.log('Loading app settings')
    this.loadingAppSettings = true;
    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        this.diagnosisService.assignClientDetails(this.appSettings.specialistAppSettings);
        this.loadingAppSettings = false;
      }).catch(error => {
        this.loadingAppSettings = false;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  onLabTestChange(e: MatOptionSelectionChange, groupName) {
    if (e.isUserInput) {
      this.labtestInfo.labtestGroup = groupName;
    }
  }

  /**
   * function get the data in a file
   * @param fileName name of the file to get
   */
  getDataFile(fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.jsonURL}${fileName}`).subscribe((data: any[]) => {
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log('Error getting file:', fileName);
        reject(err);
      });
    });
  }

  /**
   * function detect when drug class selection has changed
   * @param e event changed
   */
  drugClassChanged(e) {
    // enable the filter field
    this.form['controls']['drugname'].enable();

    // clear the drug name field when there are changes to the drug class fiedld after initial selection
    this.form['controls']['drugclass'].valueChanges
      .subscribe(selectedValue => {
        this.prescription.drugName = '';
        this.prescription.instructions = '';
      });

    // get drug file and initialize the filter
    this.getDataFile(e.value + '.json')
      .then(res => {
        this.initDruglist(res);
      }).catch(error => console.log(error));
  }

  /** initialize the drug list in the filter */
  initDruglist(drugs) {
    this.drugNames = drugs.map((d: any) => d.drug);
    this.filteredDrugNames = this.form['controls']['drugname'].valueChanges
      .pipe(
        startWith(''),
        map(value => this.applyDrugNameFilter(value))
      );
  }

  /** search drug by name */
  applyDrugNameFilter(val: string = ''): string[] {
    const filterValue = val.toLowerCase();

    return this.drugNames.filter(n => n.toLowerCase().includes(filterValue));
  }

  /** toggle check box to enable drug name typing in */
  toggleCheck(e) {
    this.disableFilter = e.checked
    this.prescription = this.clearPrescription();
    if (e.checked) {
      this.form['controls']['drugname'].disable(e.checked);
      this.form['controls']['drugclass'].disable(e.checked);
    } else {
      this.form['controls']['drugclass'].enable();
    }
  }

  /** toggle checkbox to enable lab test typing in */
  toggleCheckLabtest(e) {
    this.disableLabtestSelect = e.checked;
    this.labtestInfo = this.clearLabtest();
    if (e.checked) {
      this.form['controls']['labtest'].disable(e.checked);
      console.log()
    } else {
      this.form['controls']['labtest'].enable();
    }
  }

  /** clear labtest object */
  clearLabtest() {
    return {
      labtestGroup: '',
      labtest: '',
      labtestOther: '',
      comment: ''
    };
  }

  /** toogle check box to enter radiology by typing */
  toggleCheckRadiology(e) {
    this.disableRadiologySelect = e.checked;
    if (e.checked) {
      this.radiology.radiologyScan = '';
      this.radiology.radiologyScanOther = '';
    } else {
      this.radiology.radiologyScanOther = '';
    }
  }

  /** Add radiology order to  the sharelink */
  orderRadiology() {
    this.mySharedLink.radiologyInvestigations.push(this.radiology);
    this.radiology = new Radiology();
  }

  /** remove already selected radiology */
  removeRadiology(radiology: Radiology) {
    const index = this.mySharedLink.radiologyInvestigations.indexOf(radiology);
    this.mySharedLink.radiologyInvestigations.splice(index, 1);
  }

  /** Add prescription to the table */
  addPrescription() {
    if (!this.mySharedLink.prescribedDrugs) { this.mySharedLink.prescribedDrugs = [] }
    this.mySharedLink.prescribedDrugs.push(this.prescription);
    this.dataSource = new MatTableDataSource(this.mySharedLink.prescribedDrugs);
    this.prescription = this.clearPrescription();
  }

  /** Add select labtest to narrative */
  addLabTest() {
    if (!this.mySharedLink.labtestInfo) { this.mySharedLink.labtestInfo = [] }
    this.mySharedLink.labtestInfo.push(this.labtestInfo);
    this.labtestInfo = new LabTest();
  }

  /** clear prescription object */
  clearPrescription() {
    return {
      drugName: '',
      drugClass: '',
      instructions: ''
    };
  }

  /** remove a prescription from the list */
  removeDrug(prescription) {
    const index = this.mySharedLink.prescribedDrugs.indexOf(prescription);
    this.mySharedLink.prescribedDrugs.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.mySharedLink.prescribedDrugs);
  }

  /** remove labtest from the list */
  removeLabtest(labtest: LabTest) {
    const index = this.mySharedLink.labtestInfo.indexOf(labtest);
    this.mySharedLink.labtestInfo.splice(index, 1);
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  cancelClicked() {
    this.mySharedLink = new ShareLinks(this.backupSharelink);
  }

  getSharelinkData() {
    console.log('attempt load sharelink for ' + this.sharelinkId);
    this.patientService.getSingleSharedLinkData(this.sharelinkId)
      .then(sharelink => {
        this.mySharedLink = new ShareLinks(sharelink);
        if (!this.mySharedLink.published) { this.displayedColumns.push('remove') }
        if (!this.mySharedLink.labtestInfo) { this.mySharedLink.labtestInfo = [] }
        if (!this.mySharedLink.prescribedDrugs) { this.mySharedLink.prescribedDrugs = [] }
        if (!this.mySharedLink.radiologyInvestigations) { this.mySharedLink.radiologyInvestigations = [] }
        this.dataSource = this.mySharedLink.prescribedDrugs.length > 0 ? new MatTableDataSource(this.mySharedLink.prescribedDrugs) :
          new MatTableDataSource([]);
        this.backupSharelink = new ShareLinks(sharelink);
        this.refreshOrInitializeFormControls();

        // get user info to set global
        const user = new User({
          username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
          facility: sharelink.createFacility,
          userRole: 'specialist'
        })
        this.authService.setUser(user);
        // setting the specialist details to the auth
        this.authService.getClientIp()
          .then(ip => {
            user.ipAddress = ip;
            this.authService.setUser(user);
          })
          .catch(err => {
            console.log('Error getting ip address: ' + err);
          });

        // load narrative data
        this.getShareLinkNarrative(sharelink.narrativeId, navigator.onLine);
      })
      .catch(error => {
        console.log('error loading sharelink in sharelinks-my-report for ' + this.sharelinkId, error);
        this.snackBar.open('Error getting sharelink data!', 'Error');
      });
  }

  getShareLinkNarrative(narrativeId: number, online: boolean): void {
    this.narrativeService.getSingleNarrative(+(narrativeId))
      .then(narrative => {
        this.myNarrative = narrative;
        console.log('getSingleNarrative narr.id: ' + narrative._id);
        this.ref.detectChanges();
      }).catch(error => {
        console.log('getSingleNarrative: ', error)
        this.snackBar.open('Error while getting narrative data', 'Error', { duration: 3000 });
      });
  }

  // initializing the form
  refreshOrInitializeFormControls(): void {
    // fix for older sharelinks without questions block
    if (!this.mySharedLink.questions) {
      this.mySharedLink.questions = Array<Question>();
    }

    // fix for newer sharelinks without blocks block
    if (!this.mySharedLink.blocks) {
      this.mySharedLink.blocks = Array<NarrativeBlock>();
    }

    // set up blocks for old sharelinks without questions block
    this.mySharedLink.blocks.forEach(
      (po, blckidx) => {
        (<FormArray>this.form.controls['blocks']).push(this.createShareLinkBlocks(po));
        po.segments.forEach((narBlockSegment, segidx) => {
          this.form.controls['blocks']['controls'][blckidx].controls.segments.push(this.createShareLinkBlockSegments(narBlockSegment));
        });
      }
    );

    // set up blocks for new sharelinks with questions array property
    this.mySharedLink.questions.forEach(question => {
      (<FormArray>this.form.controls['questions']).push(this.createNarrativeQuestion(question));
    });

    if (!this.mySharedLink.diagnoses || this.mySharedLink.diagnoses.length < 1) {
      this.mySharedLink.diagnoses = []; this.addDiagnosis(null, true);  // initialize diagnoses if missing
    } else {
      // set up diagnoses form array
      this.mySharedLink.diagnoses.forEach((value, index) => {
        this.addDiagnosis(index, typeof value === 'object');
      });
    }
  }

  // create narrative question form controls
  createNarrativeQuestion(question: Question): FormGroup {
    return this._fb.group({
      question: [question.question, [Validators.required, Validators.minLength(5)]],
      answer: [question.answer, [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
      dateAdded: [new Date(question.dateAdded)]
    });
  }

  // CREATING THE FORM GROUP FOR THE NARRATIVE BLOCKS
  createShareLinkBlocks(narBlock) {
    return this._fb.group({
      blockid: [narBlock.blockid],
      blockname: [narBlock.blockname, [Validators.required]],
      segments: this._fb.array([])
    });

  }

  // CREATING THE FORM GROUP FOR THE NARRATIVE BLOCKS SEGMENTS
  // NEEDS TO BE SCALED DOWN
  createShareLinkBlockSegments(narBlockSegment) {
    return this._fb.group({
      segmentName: [narBlockSegment.segmentName, [Validators.required, Validators.minLength(5)]],
      segmentAnswer: [narBlockSegment.segmentAnswer, [Validators.required, Validators.minLength(3)]],
      attachmentTitle: [narBlockSegment.attachment.attachmentTitle],
      dateOnAttachment: [narBlockSegment.attachment.dateOnAttachment],
      attachmentFileName: [narBlockSegment.attachment.attachmentFileName],
      attachmentFile: ['']
    });
  }

  // CREATE FORM GROUP FOR DIAGNOSIS
  createDiagnosisFormGroup() {
    const diagnosisGroup = this._fb.group({
      diagnosis: ['', [Validators.required,
      Validators.minLength(5), ValidationService.ICD10]]
    })

    diagnosisGroup.controls.diagnosis.valueChanges.pipe(
      debounceTime(200),
      switchMap((value: string) => {
        // Get ICD10 data from diagnosis service 
        return value && typeof value === 'string' ? this.diagnosisService.getDiagnosis(value) : [];
      })).subscribe(data => {
        data.sort(function (a, b) {
          if (a.code < b.code) { return -1; }
          if (a.code > b.code) { return 1; }
          return 0;
        })
        this.ICD10_diagnosesOptions = data;
        this.ref.detectChanges();
      });

    return diagnosisGroup
  }

  // CREATE FORM GROUP FOR DIAGNOSIS TEXT
  createDiagnosisControl() {
    return this._fb.control('', [Validators.required, Validators.minLength(5)]);
  }

  // Saves a draft copy of the report, validation not required
  saveAsDraftForm(model: ShareLinks) {
    // set the username
    this.authService.getUser().username = model.specialistInfo.name ? model.specialistInfo.name : 'specialist';
    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;
        this.updatedSharedLinksReport.emit(this.mySharedLink);

        /* add an event of report published */
        this.repository.addEvents('report', sharedLink._id, 'draft', this.authService.getUser().username,
          this.authService.getUser().ipAddress);

        this.snackBar.open('Your report has been saved as a draft', 'Success', { duration: 6000 });
      }).catch(error => {
        console.log('saveAsDraftForm error: ', error);
        this.snackBar.open('Error saving the report', 'Error');
      })
  }

  /* publishes the form, adds a link to updates, form must be valid */
  saveAndPublishForm(model: ShareLinks, isValid: boolean) {
    if (!isValid) {
      this.snackBar.open('Please ensure that all required fields are correctly completed', 'Error', { duration: 6000 });
      return false;
    }

    const d = new Date();

    this.mySharedLink.datePublished = d;
    this.mySharedLink.published = true;

    this.myNarrative.updates.push({
      updateDate: d,
      description: 'A specialist report was filled by ' + this.mySharedLink.specialistInfo.name + '. The report is linked',
      dateAdded: new Date(),
      shareLinkId: this.mySharedLink._id,
      referralType: 'Consultation'
    });

    const index = this.displayedColumns.indexOf('remove');
    this.displayedColumns.splice(index, 1);
    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;
        this.snackBar.open('Your report has been saved and published.', 'Success', { duration: 6000 });
        /** update the narrative with status */
        this.myNarrative.referralStatus = ReferralStatus.reported;

        // updated narrative status
        this.narrativeService.updateStatusHistory(this.myNarrative, ReferralStatus.reported,
          `Report has been published by: ${this.mySharedLink.specialistInfo.name}`);
        this.addUpdatesToNarrative(this.myNarrative, navigator.onLine);

        /* add an event of report published */
        this.repository.addEvents('report', sharedLink._id, 'published', this.authService.getUser().username,
          this.authService.getUser().ipAddress);

      }).catch(error => {
        console.log('saveForm error: ', error);
        this.snackBar.open('Error saving the report', 'Error');
      })
  }

  /* add the update that a report has been published */
  addUpdatesToNarrative(narrative: Narrative, online: boolean): void {
    this.narrativeService.updateNarrative(narrative)
      .then(updatedNarrative => {
        this.myNarrative = updatedNarrative;
      },
        error => {
          console.log(error);
          this.snackBar.open('Error saving adding report update to narrative', 'Error');
        });
  }

  /**
   * set up diagnoses variable
   * @returns diagnoses as FormArray
   */
  get diagnoses() {
    return this.form.get('diagnoses') as FormArray;
  }

  /** get icd10 controls  @returns icd10 controls */
  get icd10Controls() { return this.diagnoses.controls.filter(control => typeof control.value === 'object'); }

  /** get non-icd10 controls  @returns non-icd10 controls */
  get non_icd10Controls() { return this.diagnoses.controls.filter(control => typeof control.value !== 'object'); }

  /**
   * Gets index of diagnosis since the array is split in the html
   * @param diagnosis control object
   * @returns indexOf diagnosis in diagnoses array
   */
  getDiagnosisIndex(diagnosis) {
    return this.diagnoses.controls.indexOf(diagnosis);
  }

  /**
   * function to add diagnoses
   * @param index index of diagnosis in diagnoses arrays
   * @param icd10 boolean to check whether item is icd10 or not
   */
  addDiagnosis(index: number, icd10: boolean) {
    const control = icd10 ? this.createDiagnosisFormGroup() : this.createDiagnosisControl();
    this.diagnoses.push(control);

    // push controls to specific array
    if (icd10) {
      this.icd10Controls.push(control);
    } else {
      this.non_icd10Controls.push(control);
    }
    // if new, add corresponding field to sharelink
    const newIndex = this.diagnoses.length + 1;
    if (index === null) { this.mySharedLink.diagnoses.splice(newIndex, 0, control.value) }
  }

  /**
   * function to display name on diagnosis input field
   * @param option object with code and name
   * @returns string code+name
   */
  getDiagnosisName(option) {
    return option.name ? `${option.code} ${option.name}` : null;
  }

  /**
  * function to confirm and delete diagnosis from array
  * @param index index to delete
  */
  confirmDeleteDiagnosis(index: number) {
    // open DeleteDiagnosisDialogComponent confirm dialog
    this.diagnosisDialog = this.dialog.open(DeleteDiagnosisDialogComponent,
      { data: { title: 'Confirm Delete Diagnosis', message: 'This diagnosis will be deleted' } });
    this.diagnosisDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.diagnoses.removeAt(index);
        this.mySharedLink.diagnoses.splice(index, 1);
        this.ref.detectChanges();
      }
    });
    this.diagnosisDialog = null;
  }

  /** function act on non-icd10 toggle
   * @param toggle boolean parameter to assign toggle
   */
  nonICD10Toggle(toggle: boolean) {
    if (toggle) {
      this.non_ICD10 = toggle;
      this.addDiagnosis(null, false)
    } else {
      this.diagnosisDialog = this.dialog.open(DeleteDiagnosisDialogComponent,
        { data: { title: 'Confirm Close', message: 'Once you uncheck this, the non icd diagnoses list will be removed.' } });
      this.diagnosisDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.non_ICD10 = toggle;
          this.diagnoses.value.forEach((diagnosis, index) => {
            if (typeof diagnosis === 'string') {
              this.diagnoses.removeAt(index);
              this.mySharedLink.diagnoses.splice(index, 1);
              this.ref.detectChanges();
            }
          })
        }
      });
      this.diagnosisDialog = null;
    }
  }
}
