import { Component, Input } from '@angular/core';
import { PlacentaBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-placenta-nurse-view',
  templateUrl: './placenta-nurse-view.component.html'
})

export class PlacentaNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  _blockData: PlacentaBlock;
  @Input()
  set blockData(blockData: PlacentaBlock) {
    this._blockData = blockData;
    this.getAttachmentFilesCount(blockData.attachmentIds);
  }
  get blockData(): PlacentaBlock {
    return this._blockData;
  }


  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }
}
