import { Component, Input, AfterViewInit, ViewChild, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { ObstetricNarrative, BlocksOrderEnum, scanningIndication } from '../../../models/obstetric-narrative';
import { SonographerBlocks } from '../../../models/obstetric-sharelink';
import { ShareLinks } from '../../../models/sharelinks';
import { Router } from '@angular/router';
import { NumberFoetusesSonographerViewComponent
} from '../../../narrative/obstetric/blocks/number-foetus/number-foetuses-sonographer-view.component';
import { FoetalLieSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-lie/foetal-lie-sonographer-view.component';
import { FoetalPresentationSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-presentation/foetal-presentation-sonographer-view.component';
import { HeartSonographerViewComponent } from '../../../narrative/obstetric/blocks/foetal-heart/heart-sonographer-view.component';
import { PlacentaSonographerViewComponent } from '../../../narrative/obstetric/blocks/foetal-placenta/placenta-sonographer-view.component';
import { AmnioticFluidSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-amniotic-fluid/amniotic-fluid-sonographer-view.component';
import { FoetalLimbsSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-limbs/foetal-limbs-sonographer-view.component';
import { FoetalAbdomenSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-abdomen/foetal-abdomen-sonographer-view.component';
import { HeadAndSpineSonographerViewComponent
} from '../../../narrative/obstetric/blocks/foetal-head-spine/head-spine-sonographer-view.component';
import { MaternalAnatomySonographerViewComponent
} from '../../../narrative/obstetric/blocks/maternal-anatomy/maternal-anatomy-sonographer-view.component';
import { RecommendationsSonographerViewComponent
} from '../../../narrative/obstetric/blocks/recommendations/recommendations-sonographer-view.component';

@Component({
  selector: 'psoc-obstetric-view',
  templateUrl: 'obstetric-view.component.html'
})

export class ObstetricViewComponent implements AfterViewInit {
  firstTrimester: Boolean = false;
  @Input() sonographerData: SonographerBlocks;
  _nurseData: ObstetricNarrative;
  @Input() set nurseData(nurseData: ObstetricNarrative) {
    if (nurseData.scanIndication === scanningIndication[0]) { this.firstTrimester = true }
    this._nurseData = nurseData;
  } get nurseData(): ObstetricNarrative {
    return this._nurseData
  }
  @Input() shareLink: ShareLinks;
  @Output() dataValid: EventEmitter<boolean> = new EventEmitter();
  blocksOrder = BlocksOrderEnum;
  selectedFoetus: number = 0; // navigate through the foetus tabs
  isDataValid: Boolean;

  @ViewChild(NumberFoetusesSonographerViewComponent) numberOfFoetusComponent: NumberFoetusesSonographerViewComponent;
  @ViewChildren(FoetalLieSonographerViewComponent) foetalLieComponents: QueryList<FoetalLieSonographerViewComponent>;
  @ViewChildren(FoetalPresentationSonographerViewComponent)
  foetalPresentationComponents: QueryList<FoetalPresentationSonographerViewComponent>;
  @ViewChildren(HeartSonographerViewComponent) heartComponents: QueryList<HeartSonographerViewComponent>;
  @ViewChildren(PlacentaSonographerViewComponent) placentaComponents: QueryList<PlacentaSonographerViewComponent>;
  @ViewChildren(AmnioticFluidSonographerViewComponent) amnioticFluidComponents: QueryList<AmnioticFluidSonographerViewComponent>;
  @ViewChildren(FoetalLimbsSonographerViewComponent) foetalLimbs: QueryList<FoetalLimbsSonographerViewComponent>;
  @ViewChildren(FoetalAbdomenSonographerViewComponent) foetalAbdomenComponents: QueryList<FoetalAbdomenSonographerViewComponent>;
  @ViewChildren(HeadAndSpineSonographerViewComponent) headAndSpineComponents: QueryList<HeadAndSpineSonographerViewComponent>;
  @ViewChild(MaternalAnatomySonographerViewComponent) maternalComponent: MaternalAnatomySonographerViewComponent;
  @ViewChild(RecommendationsSonographerViewComponent) recommendationsComponent: RecommendationsSonographerViewComponent;

  constructor(private router: Router) { }

  ngAfterViewInit() {
    this.dataValidity();
  }

  /**
   * function to edit specific block
   */
  editBlock(blockIndex: BlocksOrderEnum, foetusIndex: number = 0) {
    this.router.navigate(['/' + this.shareLink._id + '/scans'], { queryParams: { foetus: foetusIndex ,
    block: blockIndex }, queryParamsHandling: 'merge' });

  }

  /**
   * function to check for data validity of the narrative
   */
  dataValidity() {
    if (!this.numberOfFoetusComponent) { return !this.isDataValid }
    let foetusValid: boolean;
    this.sonographerData.foetuses.forEach((f, i) => {
      foetusValid = this.isFoetusValid(i);
    });

    this.isDataValid = this.numberOfFoetusComponent.blockValid() && foetusValid && this.maternalComponent.blockValid()
      && this.recommendationsComponent.blockValid();
    this.dataValid.emit(!this.isDataValid);
  }

  /**
   * function to check the the foetus blocks valid or not
   * @param i index of the foetus
   */
  isFoetusValid(i: number): boolean {
    if (!this.foetalLieComponents) { return }
    let valid = this.foetalLieComponents.toArray()[i].blockValid() &&
    this.heartComponents.toArray()[i].blockValid() &&
    this.placentaComponents.toArray()[i].blockValid() &&
    this.amnioticFluidComponents.toArray()[i].blockValid() &&
    this.foetalLimbs.toArray()[i].blockValid() &&
    this.foetalAbdomenComponents.toArray()[i].blockValid() &&
    this.headAndSpineComponents.toArray()[i].blockValid();

    if (!this.firstTrimester) {
      valid = valid && this.foetalPresentationComponents.toArray()[i].blockValid();
    }

    return valid;
  }

}
