import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SonographerBlockFoetalAbdomen } from '../../../../models/obstetric-sharelink';
import { FoetalAbdomenBlock } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-foetal-abdomen-sonographer-view',
  templateUrl: 'foetal-abdomen-sonographer-view.component.html'
})

export class FoetalAbdomenSonographerViewComponent {
  @Input() nurseData: FoetalAbdomenBlock;
  @Input() sonographerData: SonographerBlockFoetalAbdomen;
  @Output() editCurrentBlock: EventEmitter<any> = new EventEmitter;

  constructor() { }

  /**
   * trigger edit current block
  */
  editBlock() {
    this.editCurrentBlock.emit();
  }

  /**
   * function to check the block validity
   */
  blockValid(): boolean {
    return (this.sonographerData.abdominalCircumference > 0 || this.sonographerData.cantConfirmAbdominalCircumference) &&
      this.sonographerData.blockStatus ? true : false;
  }
}
