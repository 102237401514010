import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../services/globals';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { Patient } from '../../models/patient';
import { AppSettingsService } from '../../services/settings.service';
import { AppSettings } from '../../models/config';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'psoc-patient-view',
  templateUrl: 'patient-view.component.html'
})

export class PatientViewComponent implements OnInit {

  @Input() patient: Patient;
  patients: Patient[];
  selectedPatient: Patient;
  @Input() appSettings: AppSettings;
  loadingAppSettings: boolean = false;
  env = environment;

  ngOnInit(): void {
    const currentPatientId = +this.route.snapshot.paramMap.get('patientid');
    this.patientService.getSinglePatient(currentPatientId)
      .then(patient => {
        // update current global patient id
        this.globals.showShareLinkPatientId.emit(patient.id);
        this.patient = patient;
      }).catch(error => { console.log('Error loading patient', error); });

  }

  loadAppSettings(appSettingsId: string = this.env.settingsId) {
    this.loadingAppSettings = true;

    this.appSettingsService.loadAppSettings(appSettingsId)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadingAppSettings = false;
      })
      .catch(() => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings');
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  // show edit form for selected patient
  editDemographics(patient: Patient): void {
    this.selectedPatient = patient;
    this.router.navigate(['/patient/edit/', this.selectedPatient.id]);
  }

  constructor(
    private patientService: PatientService,
    private router: Router,
    private route: ActivatedRoute,
    private globals: Globals,
    private appSettingsService: AppSettingsService,
    private snackBar: MatSnackBar
  ) {
    this.loadAppSettings();
  }

}
