import '../app/rxjs-extensions';
import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppMaterialModule } from '../app/material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { DndModule } from 'ng2-dnd';
import { MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnsavedChangesGuard } from '../app/services/unsaved-changes.guard';

import { AppRoutingModule } from './app-mediator-routing.module';
import { AppMediatorComponent } from './app-mediator.component';
import { Globals } from '../app/services/globals';
import { AuthenticationModule } from '../app/auth/authentication.module';
import { RepositoryService } from '../app/services/repository.service';
import { AttachmentService } from '../app/services/attachment.service';
import { CornerstoneService } from '../app/services/cornerstone.service';
import { AuthenticationService } from '../app/services/authentication.service';
import { FacilityService } from '../app/services/facility.service';
import { PipesModule } from '../app/pipes/pipes.module';

import { PatientsModule } from '../app/patients/patients.module';
import { NarrativeModule } from '../app/narrative/narrative.module';
import { NavigationModule } from '../app/navigation/navigation.module';
import { SharedLinksModule } from '../app/specialists-app/shared-links/shared-links.module';
import { AttachmentsModule } from '../app/attachments/attachments.module';
import { ValidationModule } from '../app/validation/validation.module';
import { EventsModule } from '../app/events/events.module';
import { RecordsModule } from '../app/records/records.module';
import { UsersService } from '../app/services/users.service';
import { CdkTableModule, DataSource } from '@angular/cdk/table';
import { ChatModule } from '../app/chat/chat.module';
import { AppointmentsModule } from '../app/appointments/appointments.module';
import { AuthGuard } from '../app/services/auth.guard';

import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { MidSonoService } from '../app/services/midsono.service';
import { WebSocketService } from '../app/services/websocket.service';
import { TransactionApiService } from '../app/services/transaction-api.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppMaterialModule,
    FlexLayoutModule,
    ValidationModule,
    NarrativeModule,
    NavigationModule,
    BrowserAnimationsModule,
    PatientsModule,
    AttachmentsModule,
    SharedLinksModule,
    MomentModule,
    PipesModule,
    EventsModule,
    RecordsModule,
    ChatModule,
    AppointmentsModule,
    DndModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot(AppRoutingModule, { useHash: false }),
    MatDatepickerModule,
    MatNativeDateModule,
    CdkTableModule,
    AuthenticationModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
    BrowserModule,
    DndModule,
  ],
  declarations: [
    AppMediatorComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    AuthGuard,
    Globals,
    MidSonoService,
    WebSocketService,
    AuthenticationService,
    UsersService,
    FacilityService,
    RepositoryService,
    UnsavedChangesGuard,
    DeviceDetectorService,
    AttachmentService,
    CornerstoneService,
    TransactionApiService
  ],
  bootstrap: [AppMediatorComponent]
})
export class MediatorAppModule { }
