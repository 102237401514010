export class NotificationStatus {
  public static type: string = 'notificationStatus';
  public static fields = ['_id', '_rev', 'type', 'event', 'eventId', 'dateAdded', 'patientName', 'emails', 'sms'];
  _id: string;
  _rev: string;
  type: string;
  event: string;
  eventId: string;
  dateAdded: Date;
  patientName: string;
  emails: EmailStatus[];
  sms: SmsStatus[];
}

export class EmailStatus {
  email: string;
  status: string;
  event: string;
  userRole: string;
}

export class SmsStatus {
  number: string;
  status: string;
  event: string;
  userRole: string;
  message?: string;
  messageId?: string;
}
