import { Component, Input } from '@angular/core';
import { Patient } from '../../models/patient';
import { Narrative } from '../../models/narrative';
import { MatTableDataSource } from '@angular/material';
import { ShareLinks } from '../../models/sharelinks';

@Component({
  selector: 'psoc-radiology-view',
  templateUrl: 'radiology-view.component.html'
})

export class RadiologyViewComponent {
  @Input() set mySharedLink(mySharedLink: ShareLinks) {
    if (!mySharedLink.radiologyInvestigations) { mySharedLink.radiologyInvestigations = [] }
    this._mySharedLink = mySharedLink;
  } get mySharedLink(): ShareLinks { return this._mySharedLink }
  @Input() myNarrative: Narrative;
  @Input() patient: Patient;

  _mySharedLink: ShareLinks;
  constructor() { }
}
