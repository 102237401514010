import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ShareLinks } from '../../../models/sharelinks';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Narrative, ReferralStatus } from '../../../models/narrative';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Patient } from '../../../models/patient';
import { PatientService } from '../../../services/patient.service';
import { NarrativeService } from '../../../services/narrative.service';
import { RepositoryService } from '../../../services/repository.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { User } from '../../../models/user';
import { ValidationService } from '../../../validation/validation.service';


@Component({
  selector: 'psoc-shared-links-discharge-summary',
  templateUrl: 'shared-links-discharge-summary.component.html'
})
export class SharedLinksDischargeSummaryComponent implements OnInit {
  mySharedLink: ShareLinks;
  @Input() patient: Patient;
  @Input() myNarrative: Narrative;
  @Input() sharedLinkPatientId: string;
  @Output() updatedSharedLinksDischargeSummary: EventEmitter<ShareLinks> = new EventEmitter<ShareLinks>();
  form: FormGroup;
  maxDate = new Date();
  minDate = new Date();
  sharelinkId: string;
  loadingFacilities: Boolean = true;
  backupSharelink: ShareLinks;

  ngOnInit(): void {
    // load connection to sharelinks dbs
    this.repository.loadSpecialistDb(this.route.snapshot.params['id']);

    // get sharelinkid from the url
    this.sharelinkId = this.route.snapshot.params['id']

    // load sharelink data
    this.getSharelinkData();
  }

  // back button and go back clicked
  backClicked(e: Event): void {
    e.preventDefault();
    this.router.navigate(['/' + this.sharelinkId]);
  }

  cancelClicked() {
    this.mySharedLink = new ShareLinks(this.backupSharelink);
  }

  getSharelinkData() {
    console.log('attempt load sharelink for ' + this.sharelinkId);
    this.patientService.getSingleSharedLinkData(this.sharelinkId).then(sharelink => {
      sharelink.dateOfAdmission = (sharelink.dateOfAdmission === undefined ? new Date() : new Date(sharelink.dateOfAdmission));
      sharelink.dateOfDischarge = (sharelink.dateOfDischarge === undefined ? new Date() : new Date(sharelink.dateOfDischarge));
      this.mySharedLink = sharelink;
      this.backupSharelink = new ShareLinks(sharelink);

      // get user info to set global
      const user = new User({
        username: sharelink.specialistInfo.name ? sharelink.specialistInfo.name : 'specialist',
        facility: sharelink.createFacility,
        userRole: 'specialist'
      })
      // setting the specialist details to the auth
      this.authService.getClientIp()
        .then(ip => {
          user.ipAddress = ip;
          this.authService.setUser(user);
        })
        .catch(err => {
          // failed to get ip, set user details anyway
          console.log('Error getting ip address: ' + err)
          this.authService.setUser(user);
        });

      this.getShareLinkNarrative(sharelink.narrativeId, navigator.onLine);
    })
      .catch(error => {
        console.log('error loading sharelink in sharelink-discharge-summary for ' + this.sharelinkId, error);
        this.snackBar.open('Error getting sharelink data!', 'Error');
      });
  }

  getShareLinkNarrative(narrativeId: number, online: boolean): void {
    this.narrativeService.getSingleNarrative(+(narrativeId))
      .then(narrative => {
        this.myNarrative = narrative;
        console.log('getSingleNarrative narr.id: ' + narrative._id);
      }).catch(error => {
        console.log('getSingleNarrative: ', error)
        this.snackBar.open('Error while getting narrative data', 'Error', {
          duration: 3000
        });
      });
  }

  /** Saves a draft copy of the report, validation not required */
  saveAsDraftForm(model: ShareLinks) {
    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;
        this.updatedSharedLinksDischargeSummary.emit(this.mySharedLink);

        /** add an event of discharge saved */
        this.repository.addEvents('dischargeSummary', sharedLink._id,
          'draft', this.authService.getUser().username, this.authService.getUser().ipAddress);

        this.snackBar.open('The discharge summary was succesfully saved as draft', 'Success', { duration: 3000 });
      }).catch(error => {
        console.log('saveAsDraftForm error: ', error);
        this.snackBar.open('Error saving the discharge summary', 'Error', { duration: 3000 });
      })
  }

  /** publishes the form, adds a link to updates, form must be valid */
  saveAndPublishForm(model: ShareLinks, isValid: boolean) {
    if (!isValid) {
      this.snackBar.open('Please ensure no text field is empty!', 'Error', { duration: 3000 });
      return false;
    }
    const d = new Date();
    this.mySharedLink.datePublished = d;
    this.mySharedLink.published = true;
    this.myNarrative.updates.push({
      updateDate: d,
      description: 'A discharge summary was filled by ' + this.mySharedLink.dischargedBy + '. The discharge summary is linked',
      dateAdded: new Date(),
      shareLinkId: this.mySharedLink._id,
      referralType: this.myNarrative.referralType
    });

    this.patientService.updateShareLink(this.mySharedLink)
      .then(sharedLink => {
        this.mySharedLink = sharedLink;

        /** update the narrative with status */
        this.myNarrative.referralStatus = ReferralStatus.dischargedWithDS;
        // update the narrative status history
        this.narrativeService.updateStatusHistory(this.myNarrative, ReferralStatus.dischargedWithDS, '');

        this.addUpdatesToNarrative(this.myNarrative, navigator.onLine);

        /** add an event of report published */
        this.repository.addEvents('dischargeSummary', sharedLink._id, 'published',
          this.authService.getUser().username, this.authService.getUser().ipAddress);

      }).catch(error => {
        console.log('saveAndPublish error: ', error);
        this.snackBar.open('Error saving the discharge summary', 'Error', { duration: 3000 });
      })
  }

  addUpdatesToNarrative(narrative: Narrative, online: boolean): void {
    this.narrativeService.updateNarrative(narrative)
      .then(updatedNarrative => {
        this.myNarrative = updatedNarrative;

        /** add event for dicharged with ds */
        this.repository.addEvents(this.myNarrative.type, this.myNarrative._id, 'statusUpdated', this.authService.getUser().username,
          this.authService.getUser().ipAddress, this.myNarrative.referralStatus, this.myNarrative.patientId);

        this.snackBar.open('The discharge-summary was succesfully saved and published', 'Success', { duration: 3000 });
      })
      .catch(error => {
        this.snackBar.open('Error saving adding report update to narrative', 'Error', { duration: 3000 });
      })
  }

  constructor(
    private _fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private repository: RepositoryService,
    private authService: AuthenticationService
  ) {

    /* initialize form with empty FormArray for narBlocks */
    this.form = this._fb.group({
      dateOfAdmission: [new Date(), [ValidationService.dateFormatValid, ValidationService.dateInPastValidator, Validators.required]],
      dateOfDischarge: [new Date(), [ValidationService.dateFormatValid, ValidationService.dateInPastValidator, Validators.required]],
      dischargedBy: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      ward: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      clinic: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      clinicianInCharge: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      diagnosisOfDischarge: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
      clinicalManagmentSummary: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
      dischargeInstructions: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],
      dischargeMedication: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(1024)]],
      /* additional resources */
      additionalRes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(1024)]],
      blocks: this._fb.array([])
    });
  }
}
