import { Component, Input } from '@angular/core';
import { HeadAndSpineBlock } from '../../../../models/obstetric-narrative';
import { ObstetricBlockViewComponent } from '../block-view.component';

@Component({
  selector: 'psoc-head-spine-nurse-view',
  templateUrl: './head-spine-nurse-view.component.html'
})

export class HeadAndSpineNurseViewComponent extends ObstetricBlockViewComponent {
  // data for this block
  @Input() firstTrimester: Boolean = false;
  _blockData: HeadAndSpineBlock;
  @Input()
  set blockData(blockData: HeadAndSpineBlock) {
    this._blockData = blockData;
    this.getAttachmentFilesCount(blockData.attachmentIds);
  }
  get blockData(): HeadAndSpineBlock {
    return this._blockData;
  }


  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.blockData && this.blockData.blockStatus) ? true : false;
  }
}
