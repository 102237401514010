import { Pipe, PipeTransform } from '@angular/core';
import { Facility } from '../models/facility';

@Pipe({ name: 'getFacilityName' })
export class GetFacilityName implements PipeTransform {
  transform(facilityCode: string, facilities: Facility[]) {
    let currentFacility: Facility, currentFacilityName = '';
    if (facilities) {
      currentFacility = facilities.find(p => p._id === facilityCode);
      if (currentFacility) { currentFacilityName = currentFacility.name; }
    }
    return currentFacilityName;
  }
}
