import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { Facility } from '../../../../models/facility';
import { ShareLinks } from '../../../../models/sharelinks';
import { PatientService } from '../../../../services/patient.service';
import { ObstetricNarrative } from '../../../../models/obstetric-narrative';

@Component({
  selector: 'psoc-ultrasound-record-nurse-view',
  templateUrl: 'ultrasound-record-nurse-view.component.html'
})

export class UltrasoundRecordNurseViewComponent {
  @Input() facilities: Facility[];
  _obstetricNarrative: ObstetricNarrative;
  @Input() set obstetricNarrative(obstetricNarrative: ObstetricNarrative) {
    this._obstetricNarrative = obstetricNarrative;

    // set the scan facility
    if (this._obstetricNarrative.scanFacility) {
      this.scanFacility = this.getFacilityName(this._obstetricNarrative.scanFacility);
    } else {
      this.scanFacility = this._obstetricNarrative.otherScanFacility;
    }
  } get obstetricNarrative(): ObstetricNarrative { return this._obstetricNarrative }
  scanFacility: string;

  // display previous reports on the block, sonographer view
  @Input() currentSharelinkId: string;
  @Input() sonographerView: Boolean = false;
  @Input() publishedNarratives: ObstetricNarrative[];
  @Input() publishedSharelinks: ShareLinks[];

  constructor(
    private router: Router,
    private patientService: PatientService
  ) {}

  /**
   * navigate to selected report page
   * @param id string, sharelink id
   */
  navigateReportPage(id: string) {
    this.patientService.hideNav = true;
    this.router.navigate(['/' + this.currentSharelinkId + '/report/' + id], { queryParams: { hidenav: true }});
  }

  /**
   * function to get the facility name
   * @param facilityCodde facility code
   */
  getFacilityName(facilityCodde: string): string {
    if (!this.facilities) { return facilityCodde }

    const facility = this.facilities.find(f => f.code === facilityCodde);
    return facility.name;
  }

  /**
   * Check if data is valid for this block
   */
  blockValid() {
    return (this.obstetricNarrative && this.obstetricNarrative.scanFacility && this.obstetricNarrative.scanIndication) ? true : false;
  }
}
