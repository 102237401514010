import {
    Component, Output, Input, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, HostListener, OnDestroy,
    Inject
} from '@angular/core';

import { MatSnackBar, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatIconRegistry, MatDialogConfig } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ObstetricNarrative, BlocksOrderEnum } from './../../models/obstetric-narrative';
import { Patient } from '../../models/patient';
import { BlockSegment } from '../../models/narrative-block';
import { Attachment } from '../../models/attachment';
import { WebSocketService } from './../../services/websocket.service';
import { ValidationService } from '../../validation/validation.service';
import { MidSonoService } from '../../services/midsono.service';
import { AttachmentService } from '../../services/attachment.service';
import { CropperComponent } from '../cropper/cropper.component';

import * as PDFJS from 'pdfjs-dist/build/pdf';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate, UnsavedChangesGuard } from '../../services/unsaved-changes.guard';
import { DicomViewerComponent } from '../dicom/viewer/dicom-viewer.component';
PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.js';
@Component({
    selector: 'psoc-delete-image-dialog-component',
    template: `<h2>Confirm Delete Page</h2>
    <p>This page will be deleted.</p>
    <p><b>Notice: </b>This action cannot be undone.</p>
    <p><button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
    <button type="button" mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
  </p>`
})
export class DeleteImageDialogComponent {
    constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
    selector: 'psoc-confirm-move-copy-imagelist-edit',
    template: `
    <h2>Confirm Move/Copy Image</h2>
    <p>From: {{blockData.blockType}}</p>
    <p>To (Please select the block you would want to move/copy this image to)</p>
    <mat-form-field class="uk-display-inline-block">
    <mat-select placeholder="Select Block" (selectionChange)="blockSelectionChange($event.value)">
      <mat-option  [value]="{foetus: 0, block: narrativeData.sonographyBlocks.numberOfFoetusBlock}">Number of Foetus</mat-option>
      <mat-optgroup *ngFor="let foetus of narrativeData.sonographyBlocks.foetuses; index as i" label="Foetus {{i + 1}}">
        <mat-option  [value]="{foetus: i, block: foetus.foetalLieBlock}">Foetal Lie</mat-option>
        <mat-option *ngIf="!firstTrimester"  [value]="{foetus: i, block: foetus.foetalPresentationBlock}">Foetal Presentation</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.heartBlock}">Heart</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.headAndSpineBlock}">Head and Spine</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.foetalAbdomenBlock}">Abdomen</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.foetalLimbsBlock}">Limbs</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.placentaBlock}">Placenta</mat-option>
        <mat-option  [value]="{foetus: i, block: foetus.amnioticFluidBlock}">Amniotic fluid</mat-option>
      </mat-optgroup>
      <mat-option [value]="{foetus: 0, block: narrativeData.sonographyBlocks.maternalAnatomyBlock}">Maternal Anatomy</mat-option>
    </mat-select>
  </mat-form-field>

  <p>
    <button type="button" mat-raised-button color="warn" class="uk-margin-right"
    (click)="dialogRef.close({action: 'move', foetusIdx: selectedFoetus, selectedBlock: selectedBlock})">Move</button>
    <button type="button" mat-raised-button color="warn" class="uk-margin-right"
    (click)="dialogRef.close({action: 'copy', foetusIdx: selectedFoetus, selectedBlock: selectedBlock})">Copy</button>
    <button type="button" mat-raised-button color="primary"
    (click)="dialogRef.close()">Cancel</button>
  </p>
    `
})
export class CopyMoveImageDialogComponent {
    narrativeData: ObstetricNarrative;
    firstTrimester: Boolean;
    selectedFoetus: number;
    selectedBlock: any;
    blockData: any;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        console.log('Received Data::', data);
        this.narrativeData = data.narrativeData;
        this.firstTrimester = data.firstTrimester;
        this.blockData = data.blockData;
    }

    /**
     * function to change/view blocks on selection change
     * @param blockData selected block, selected foetus Idx
     */
    blockSelectionChange(blockData) {
        console.log('Block::', blockData);
        this.selectedBlock = blockData.block;
        this.selectedFoetus = blockData.foetus;
        console.log('FoetusIdx::', this.selectedFoetus, 'BLOCK::', this.selectedBlock);
    }
}

@Component({
    selector: 'psoc-confirm-cancel-imagelist-edit',
    template: `
      <h2>Confirm Discard Attachment Changes</h2>
      <p>The changes you made will be discarded</p>
      <p><b>Notice:</b> This action cannot be undone</p>
      <p>
        <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Discard</button>
        <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">Cancel</button>
      </p>`
})
export class CancelImageDialogComponent {
    constructor(public dialogRef: MatDialogRef<any>) { }
}


@Component({
    selector: 'psoc-imagelist-edit',
    templateUrl: 'imagelist-edit.component.html',
    styleUrls: ['imagelist.css'],
    providers: [WebSocketService, MidSonoService]
})

export class ImagelistEditComponent implements AfterViewChecked, OnDestroy, ComponentCanDeactivate {
    @Input() set patientView(patientView: boolean) {
        if (patientView) {
            this._patientView = patientView;
            this.datePlaceholder = 'Date (of report/prescription/image)';
        }
    } get patientView(): boolean { return this._patientView; }
    _patientView: boolean;
    @Input() blockSegment: BlockSegment;
    datePlaceholder = 'Date on Attachment (DD/MM/YYYY)';
    _attachment: Attachment; // edit this and return it
    uneditedAttachment: Attachment; // with unsaved changes in case user cancels
    @Input() savingAttachment: boolean;
    @Output() savingAttachmentChange: EventEmitter<Boolean> = new EventEmitter();

    attachmentForm: FormGroup; // form for attachment metadata

    displayCropper = false; // show cropper editor

    maxDate = new Date();
    files: File[]; // files to add to attachment
    thumbnails: any[]; // show the thumbnails in UI
    sideThumbnails: any[] = []; // show the thumbnails on the sidebar, contains src url for each
    currentIndex: number = 0; // index selected thumbnail

    displayThumbnails = true; // track whether to display thumbnails or not
    fillDisplay = false; // boolean to track whether to fill display with viewer
    startedScanning: boolean = false;
    measureToolEnabled: Boolean = false;
    ellipticalToolEnabled: Boolean = false;

    @ViewChild(CropperComponent) cropperComponent;
    @ViewChild('fileSelector') fileSelector: ElementRef;
    firstFilename: string

    public dialogRef: MatDialogRef<any>

    @Input() patient: Patient;
    @Input() narrativeId: string;

    @Input() set blockImageList(blockImageList: any) {
        if (!blockImageList) { return; }
        this.loadingImageList = true;
        this._blockImageList = blockImageList.images;
        if (this._blockImageList.length > 0) {
            this.imagesToLoad = this._blockImageList;
            this.imagesThumbnails = blockImageList.thumbnails;
            setTimeout(() => {
                this.imagesToLoad.forEach(imagePath => { this.midSonoService.requestFile(imagePath); });
                this.imagesThumbnails.forEach(thumbPath => { this.midSonoService.requestThumbnail(thumbPath); });
            }, 500);
        }
        this.loadingImageList = false;
    } get blockImageList(): any { return this._blockImageList }
    _blockImageList: string[];

    @Input() set attachment(attachment: Attachment) {
        if (!attachment) { attachment = new Attachment(); }
        this.uneditedAttachment = new Attachment(attachment);
        this._attachment = attachment;
        this.loadFiles();
    }; get attachment(): Attachment { return this._attachment };

    _attachmentHasChanges = false; // keep track of changes made to an attachment
    @Output() attachmentHasChanges: EventEmitter<Boolean> = new EventEmitter();
    @Output() attachmentUpdated: EventEmitter<Attachment> = new EventEmitter();
    @Output() attachmentAdded: EventEmitter<Attachment> = new EventEmitter();
    @Output() editCancelled = new EventEmitter;
    @Output() updateImageList = new EventEmitter();

    // pdf
    pdfDoc: any;
    blob: any;
    totalPages: number;
    pageNumber: number;
    srcPdf: SafeUrl;

    @ViewChild(DicomViewerComponent) dicomViewerComponent: DicomViewerComponent;
    @ViewChild('pdfCanvas') pdfCanvasRef: ElementRef;
    pdfCanvas: any;
    pageRendered: any;

    @Input() set pdfSrc(pdfSrc: any) { this.loadPdf(pdfSrc); };

    // turn on support for ultrasound functionality
    _ultrasoundSupport: boolean = false;
    @Input() set ultrasoundSupport(ultrasoundSupport: boolean) {
        this.fillDisplay = true;
        this._ultrasoundSupport = ultrasoundSupport;
    } get ultrasoundSupport(): boolean { return this._ultrasoundSupport; }

    @Input() foetusIndex: number;
    @Input() narrativeData: ObstetricNarrative;
    @Output() narrativeDataChange: EventEmitter<ObstetricNarrative> = new EventEmitter();
    @Input() firstTrimester: Boolean;
    @Input() blockData; // Block inherited from SonographerBlock

    imagesToLoad: Array<String> = [];
    imagesThumbnails: Array<String> = [];
    imagesToDelete: Array<String> = [];
    socketConnected: boolean = true;
    loadingImageList: boolean = false;
    loadingPage: boolean = false;

    /** guard against browser refresh, close, etc. */
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        if (this._attachmentHasChanges || (this.cropperComponent && this.cropperComponent.imageChanged)) { return false };
        return true;
    }

    // listen for visibility changes
    @HostListener('document:visibilitychange', ['$event'])
    handleVisibilityChange(event: any): void {
        if (!document.hidden && this.startedScanning) {
            // check for images from middleware, allow socket time to connect
            this.loadingImageList = true;
            setTimeout(() => { this.getImageList(); }, 3000)
            console.log('Visibility changed: Visible');
        }
    }
    @HostListener('mousewheel', ['$event']) onMousewheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        if (delta > 0) {
            this.displayNextImage();
        } else {
            this.displayPrevImage();
        }
    }

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        matIconRegistry: MatIconRegistry,
        private midSonoService: MidSonoService,
        private attachmentService: AttachmentService,
        private unsavedChangesGuard: UnsavedChangesGuard
    ) {
        this.unsavedChangesGuard.registerComponent(this);
        matIconRegistry.addSvgIcon('ruler', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler.svg'));
        matIconRegistry.addSvgIcon('circle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/circle.svg'));
        matIconRegistry.addSvgIcon('file_copy', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/file_copy.svg'));

        // set up attachments form
        this.attachmentForm = new FormGroup({
            segmentDesc: new FormControl({ value: '', disabled: true }),
            attachmentTitle: new FormControl('', [Validators.minLength(2)]),
            dateOnAttachment: new FormControl(new Date(),
                [ValidationService.dateFormatValid, ValidationService.dateInPastValidator, Validators.required]),
            attachmentFile: new FormControl('')
        });

        // listen to socket server and deal with results
        midSonoService.messages.subscribe(
            (msg: any) => {
                console.log('Response from websocket: ', msg);
                switch (msg.task) {
                    case 'imageResponse':
                        console.log('ImageResponse response:', msg);
                        this.loadImage(msg.data.b64, msg.data.imagePath);
                        break;
                    case 'imageDeletedResponse':
                        console.log('ImageDeletedResponse response:', msg);
                        const index = this.imagesToDelete.indexOf(msg.data);
                        this.imagesToDelete.splice(index, 1);
                        break;
                    case 'imageListResponse':
                        console.log('Imagelist response--:', msg);
                        this.loadingImageList = false;
                        if (Array.isArray(msg.data)) {
                            this.imagesToLoad = msg.data;
                            this.imagesThumbnails = msg.thumbnails;
                            if (this.imagesToLoad.length === 0) {
                                // this.loadingImages = false;
                                this.snackBar.open('No images to load from scanner.', 'Success', { duration: 6000 });
                            } else {
                                // save list to narrative
                                this.updateImageList.emit({ images: this.imagesToLoad, thumbnails: this.imagesThumbnails });
                                this.imagesToLoad.forEach(imagePath => { this.midSonoService.requestFile(imagePath); });
                                this.imagesThumbnails.forEach(thumbPath => { this.midSonoService.requestThumbnail(thumbPath); });
                                this.snackBar.open('List of images loaded successfully.', 'Success', { duration: 6000 });
                            }
                        };
                        this.ref.detectChanges();
                        break;
                    case 'thumbnailResponse':
                        console.log('Thumbnail response');
                        this.loadDicomThumbnails(msg.data.thumbB64, msg.data.imagePath);
                        break;
                    case 'pingResponse':
                        console.log('Ping reponse:', msg.message)
                        this.snackBar.open('Ping response from GabrielUSG app. ' + msg.message, 'Success', { duration: 6000 })
                        break;
                }
            },
            (error) => {
                console.log('Error from socket', error);
                this.socketConnected = false;
                if (this.ultrasoundSupport) {
                    this.snackBar.open('Error connecting to socket. Please make sure that GabrielUSG is running', 'Error',
                        { duration: 6000 });
                }
            },
            () => {
                console.log('completed');
                this.socketConnected = false;
            }
        );

    }


    /** request scan from WirelessUSG */
    startScan(obstetricNarrativeId: number = 0, blockIndex: number = 0, foetusIndex: number = 0) {
        if (!this.socketConnected) {
            this.snackBar.open('Sorry, unable to connect to WirelessUSG. Please run the GabrielUSG app.', 'Error', { duration: 6000 });
            return;
        }

        this.startedScanning = true;
        // pass navigational data to middleware
        obstetricNarrativeId = this.route.snapshot.params['narrativeid'];
        this.narrativeId = this.route.snapshot.params['narrativeid'];  // TODO: Replace this with input from obstetric components
        blockIndex = BlocksOrderEnum.numberOfFoetusBlock;
        foetusIndex = this.foetusIndex;

        this.midSonoService.requestScan(this.patient, obstetricNarrativeId, blockIndex, foetusIndex);
    }

    /**
     * function to request list of images from middleware
     */
    getImageList() {
        if (!this.socketConnected) {
            this.loadingImageList = false;
            this.startedScanning = false;
            this.snackBar.open('Sorry, unable to connect to WirelessUSG service. Please ensure the GabrielUSG app is running.', 'Error',
                { duration: 6000 });
            return;
        }
        this.loadingImageList = true;
        this.startedScanning = false;
        this.imagesToLoad = [];
        this.midSonoService.requestFileList();
    }

    /** load image string response from middleware into an attachment object and create attachment if we are done */
    loadImage(b64: string, imagePath: string) {
        console.log('load image', imagePath);
        const image = this.midSonoService.b64ToBlob(b64, 'application/dicom');
        this.addFile(new File([image], imagePath, { type: image.type }));
        this.setAttachmentChanged(true);
    }

    /** load dicom thumbnails to an array */
    loadDicomThumbnails(thumbB64: string, imagePath: string) {
        const src = 'data:image/jpg;base64,' + thumbB64;
        const srcData = this.sanitizer.bypassSecurityTrustUrl(src);
        this.sideThumbnails.push(srcData);
    }

    /** function send request to delete images from gabrielUSG */
    deleteImagesFromWUSG() {
        if (this.imagesToLoad.length < 1 || this.imagesThumbnails.length < 1) { return; }
        const delList = this.imagesToLoad.concat(this.imagesThumbnails);
        delList.forEach(path => {
            this.midSonoService.requestDeleteFile(path);
        });
    }

    /** Fix for view changed errors */
    ngAfterViewChecked() {
        this.ref.detectChanges();
        this.pdfCanvas = this.pdfCanvasRef.nativeElement;
    }

    /**
     * Cancel any editing, restore unedited file
     */
    cancelEdit() {
        if ((this.attachment._attachments && this.cropperComponent.imageChanged) || this._attachmentHasChanges) {
            // if changes, confirm if user wants to discard changes
            this.dialogRef = this.dialog.open(CancelImageDialogComponent);
            this.dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    // if discard - discard notify parent
                    this.attachment = this.uneditedAttachment;
                    this.setAttachmentChanged(false);
                    this.editCancelled.emit(this.uneditedAttachment);
                    if (this.narrativeData && this.narrativeData.imagesList.length > 0) { this.updateImageList.emit(null); }
                    this.deleteImagesFromWUSG();
                    // reset form
                    this.attachment = new Attachment();
                    this.attachmentForm.reset();
                    this.resetImageArrays();
                } else {
                    // if cancel - do nothing
                }
            })
        } else {
            // if there are no changes, don't dialog
            this.editCancelled.emit(this.uneditedAttachment);
            return;
        }
    }

    /** function reset image arrays */
    resetImageArrays() {
        this.currentIndex = 0;
        this.files = [];
        this.thumbnails = [];
        this.sideThumbnails = [];
    }

    /**
     * copy/move image to the target block
     */
    copyImage() {
        // pass data to the CopyMoveDialogComponent
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            'blockData': this.blockData,
            'narrativeData': this.narrativeData,
            'firstTrimester': this.firstTrimester,
        }

        this.dialogRef = this.dialog.open(CopyMoveImageDialogComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const targetBlock: any = result.selectedBlock;
                const selectedFoetusIdx = result.foetusIdx;
                let targetBlockAttachment: Attachment;
                const currentFoetusIndex = this.foetusIndex ? this.foetusIndex : 0;

                if (selectedFoetusIdx === currentFoetusIndex && targetBlock.blockType === this.blockData.blockType) {
                    this.snackBar.open('You cannot copy/move to the same block', 'Error', { duration: 6000 });
                    return;
                }

                if (targetBlock.attachmentIds === undefined) {
                    targetBlock.attachmentIds = [];
                }

                const selectedFile = this.files[this.currentIndex];
                // check if target block has attachment
                if (targetBlock.attachmentIds.length < 1) {
                    targetBlockAttachment = new Attachment({
                        dateOnAttachment: new Date(),
                        attachmentTitle: this.attachment.attachmentTitle ? this.attachment.attachmentTitle : 'WirelessUSG Scan',
                        attachmentFileName: this.attachment.attachmentFileName ? this.attachment.attachmentFileName : 'W_USG_Scan'
                    });
                    this.saveToTargetBlock(targetBlockAttachment, targetBlock, selectedFile, selectedFoetusIdx, result);
                } else {
                    // load attachments object for target block
                    this.attachmentService.getAttachment(targetBlock.attachmentIds[0])
                        .then(attachment => {
                            targetBlockAttachment = new Attachment(attachment);
                            this.saveToTargetBlock(targetBlockAttachment, targetBlock, selectedFile, selectedFoetusIdx, result);
                        }).catch(error => {
                            console.log('Error loading attachment', error);
                            this.snackBar.open('Error loading attachment object for target block', 'Error');
                        });
                }
            }
            this.dialogRef = null;
        });

    }

    /** Update the target block with the selected attachment file */
    saveToTargetBlock(targetBlockAttachment, targetBlock, selectedFile, selectedFoetusIdx, result) {
        if (targetBlockAttachment._attachments === undefined) {
            targetBlockAttachment._attachments = {};
        }

        const newIndex = Object.keys(targetBlockAttachment._attachments).length;
        targetBlockAttachment._attachments['file' + newIndex] = { content_type: selectedFile.type, data: selectedFile };

        if (selectedFile.type === 'application/dicom') {
            console.log('Dicom thumbnails ?', 'Yes');
            if (!targetBlockAttachment.dicomThumbnails) {
                targetBlockAttachment.dicomThumbnails = [];
            }
            targetBlockAttachment.dicomThumbnails[newIndex] = this.sideThumbnails[this.currentIndex];
        }

        // save target block attachments object
        this.saveTargetAttachment(targetBlockAttachment, this.files[this.currentIndex].type, this.files[this.currentIndex].name)
            .then(updatedTargetAttachment => {
                // update target block with new/updated attachment
                if (targetBlock.attachmentIds.indexOf(updatedTargetAttachment._id) === -1) {
                    targetBlock.attachmentIds.push(updatedTargetAttachment._id);
                }
                this.setAttachmentChanged(true);

            }).catch(err => {
                console.log('Error saving target attachment', err);
                this.snackBar.open('Error saving target attachment' + err.toString(), 'Error', { duration: 6000 });
            });

        // if moving, remove file from current attachment
        if (result.action === 'move') {
            this.deleteImage(this.currentIndex);
        }

        // update parent object
        // update correct block
        this.updateNarrative(selectedFoetusIdx, targetBlock);

        // let user know we have succeeded in moving to block ''
        const message = `Image was moved and saved to ${targetBlock.blockType}.`;
        this.snackBar.open(`${message}`, 'Success', { duration: 6000 });
    }

    /**
     * Update correct block after moving/copy image
     */
    updateNarrative(selectedFoetus: number, targetBlock: any) {
        if (targetBlock.blockType === 'numberOfFoetus') {
            this.narrativeData.sonographyBlocks.numberOfFoetusBlock = targetBlock;
        }

        if (targetBlock.blockType === 'maternalAnatomy') {
            this.narrativeData.sonographyBlocks.maternalAnatomyBlock = targetBlock;
        }

        if (targetBlock.blockType === 'foetalLie') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].foetalLieBlock = targetBlock;
        }

        if (targetBlock.blockType === 'foetalPresentation') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].foetalPresentationBlock = targetBlock;
        }

        if (targetBlock.blockType === 'heart') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].heartBlock = targetBlock;
        }

        if (targetBlock.blockType === 'placenta') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].placentaBlock = targetBlock;
        }

        if (targetBlock.blockType === 'amnioticFluid') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].amnioticFluidBlock = targetBlock;
        }

        if (targetBlock.blockType === 'foetalLimbs') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].foetalLimbsBlock = targetBlock;
        }

        if (targetBlock.blockType === 'foetalAbdomen') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].foetalAbdomenBlock = targetBlock;
        }

        if (targetBlock.blockType === 'headAndSpine') {
            this.narrativeData.sonographyBlocks.foetuses[selectedFoetus].headAndSpineBlock = targetBlock;
        }

        this.narrativeDataChange.emit(this.narrativeData);
    }

    /**
     * function to save attachment of target block(move/copy image) to the database
     */
    saveTargetAttachment(targetAttachment: Attachment, fileType: string, fileName: string): Promise<Attachment> {
        return new Promise((resolve, reject) => {

            // add id if we are dealing with new attachment
            const isNew = !targetAttachment._id ? true : false;
            if (isNew) {
                const timestamp = new Date().getTime();
                targetAttachment.id = timestamp;
                targetAttachment._id = timestamp.toString();
                targetAttachment.patientId = +this.patient._id;
                targetAttachment.narrativeId = +this.narrativeData._id;
                targetAttachment.sharedFacility = this.patient.sharedFacility ? this.patient.sharedFacility : [];
                targetAttachment.createFacility = this.patient.createFacility;

                // TODO: This needs to be rethinked!
                targetAttachment.mediatype = fileType;
                // targetAttachment.attachmentFileName = this.files[0].name;
                targetAttachment.attachmentFileName = fileName;
            }

            // attach file
            console.log('target attachment to be saved', targetAttachment);

            // save to pouch
            this.attachmentService.updateAttachment(targetAttachment)
                .then(attachment => {
                    console.log('Target attachment after updated to couch', attachment)
                    // reload attachment files from pouch to prevent 'readAsArrayBuffer' on 'FileReader' errors
                    this.attachmentService.getAttachment(attachment._id).then(reloadedAttachment => {
                        console.log('Target attachment after reload', reloadedAttachment);
                        resolve(reloadedAttachment);
                    }).catch((err) => {
                        // this.snackBar.open('Unable to reload file attachment', 'Error', { duration: 6000 });
                        console.log(err);
                        reject('Unable to reload targetd file attachment');
                    });
                })
                .catch(err => {
                    console.log('Error uploading target attachment:', err);
                    // this.snackBar.open('ERROR: Attachment was not updated', 'Error', { duration: 6000 })
                    reject('ERROR: Target attachment was not updated');
                });

        });
    }

    /**
     * Load the files saved in this attachment object
     */
    loadFiles() {
        this.currentIndex = 0;
        this.thumbnails = [];
        this.files = [];
        if (!this.attachment._attachments) { return };
        Object.keys(this.attachment._attachments).forEach((attachmentFileName, i) => {
            // get the file index
            const fileIndexArray = attachmentFileName.split('');
            const fileIndex = fileIndexArray[fileIndexArray.length - 1];
            this.attachmentService.getAttachmentFile(this.attachment._id, attachmentFileName).then((blob) => {
                this.addFile(blob);
                if (blob.type === 'application/dicom') {
                    // set the dicom thumbnails
                    const src = this.sanitizer.bypassSecurityTrustUrl(this.attachment.dicomThumbnails[fileIndex]
                        .changingThisBreaksApplicationSecurity);
                    this.sideThumbnails[i] = src;
                }
            }).catch((err) => {
                this.snackBar.open('Unable to load file attachment', 'Error', { duration: 6000 });
                console.log(err);
            });
        });
        this.loadingImageList = false;
    }

    /**
     * Fire the click event on the hidden file selector
     */
    selectFile() {
        this.fileSelector.nativeElement.click();
    }

    /**
     * User has selected a file, process it
     */
    fileSelected(event: EventTarget) {
        this.loadingPage = true;
        const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
        const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
        const files: FileList = target.files;

        // check if no file was selected
        if (files.length < 1) { this.loadingPage = false; return }

        // extract the name of first file uploaded..
        this.firstFilename = files[0].name
        console.log('file type', files[0].type, files[0])
        if (files[0].type === 'application/pdf') {
            this.addFile(files[0])
        } else {
            // compress file
            this.compressFile(files[0]);
        }
        this.setAttachmentChanged(true);
    }

    /**
     * Compress file provided
     */
    compressFile(file: File) {
        // this.snackBar.open('Compressing file', '', { duration: 3000 });
        const settings = { quality: 0.4, maxHeight: 1000, maxWidth: 1000 }
        this.attachmentService.compressFile(file, settings)
            .then(compressedFile => {
                this.addFile(compressedFile);
            }).catch((err) => {
                this.loadingPage = false;
                this.snackBar.open('File format not supported', 'Warning', { duration: 6000 });
                console.log('Error while compressing...' + err);
            });
    }

    /**
     * Deletes given file from the list of files
     */
    deleteImage(index: number) {
        if (this.currentIndex = index) { this.currentIndex = 0; }
        this.files.splice(index, 1);
        this.thumbnails.splice(index, 1);
        this.sideThumbnails.splice(index, 1);
        this.attachmentForm.controls['attachmentFile'].reset();
        this.setAttachmentChanged(true);
    }

    confirmDeleteImage(index: number) {
        this.dialogRef = this.dialog.open(DeleteImageDialogComponent);

        this.dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.deleteImage(index);
            }
            this.dialogRef = null;
        })
    }

    /**
     * Display next image in series
     */
    displayNextImage() {
        if (this.currentIndex === this.files.length - 1) { return; }
        this.currentIndex++;
        if (this.measureToolEnabled) { this.toggleMeasureTool(); } // deactivate measuretool if active
        if (this.ellipticalToolEnabled) { this.toggleEllipticalTool(); } // deactivate ellipticalTool if active
    }

    /**
     * Display previous image in series
     */
    displayPrevImage() {
        if (this.currentIndex === 0) { return; }
        this.currentIndex--;
        if (this.measureToolEnabled) { this.toggleMeasureTool(); } // deactivate measuretool if active
        if (this.ellipticalToolEnabled) { this.toggleEllipticalTool(); } // deactivate ellipticalTool if active
    }

    /**
     * Display selected thumbnail in main area
     */
    displaySelectImg(index) {
        this.currentIndex = index;
    }

    /**
     * Move image higher in list
     */
    orderImageUp(index: number) {
        if (index === 0) { return; }
        this.arrayMove(this.files, index, index - 1);
        this.arrayMove(this.thumbnails, index, index - 1);
    }

    /**
     * Move image lower in list
     */
    orderImageDown(index: number) {
        if (index === this.files.length - 1) { return; }
        this.arrayMove(this.files, index, index + 1);
        this.arrayMove(this.thumbnails, index, index + 1);
    }

    /**
     * Helper function to change an index in an array
     */
    arrayMove(arr, old_index, new_index) {
        const b = arr[new_index];
        arr[new_index] = arr[old_index];
        arr[old_index] = b;
    };

    /**
     * Add a file to the list of files for this attachment and add its thumbnail
     */
    addFile(file: File) {
        console.log(file.type)
        const url = URL.createObjectURL(file);
        if (file.type === 'application/pdf') {
            this.loadPdf(url)
        } else if (file.type === 'application/dicom') {
            this.files.push(file);
            this.loadDicom(url, file.type);
        } else {
            // add to filelist
            this.files.push(file);
            // get thumbnail
            const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
            this.thumbnails.push(imgSrc);
            this.sideThumbnails.push(imgSrc);
            // display the page we just added
            this.currentIndex = this.files.length - 1;
            this.loadingPage = false;
        }
    }

    /**
     * Load dicom files to thumbnails array
     * @param url file location path
     * @param fileType file media type
     */
    loadDicom(url: string, fileType: string) {
        const dicomFile = { url: url, mediaType: fileType };
        this.thumbnails.push(dicomFile);
    }

    /**
     * Update file that has been changed in cropper
     */
    cropperUpdateFile(imageSrcUrl, fileIndex: number) {
        // update thumbnail
        this.thumbnails[fileIndex] = imageSrcUrl;

        // add to filelist
        const imageBlob = this.attachmentService.dataURItoBlob(imageSrcUrl);
        this.files[fileIndex] = <File>imageBlob;

        // Update UI
        this.cropperComponent.cropperCancel();
        this.displayCropper = false;
        this.setAttachmentChanged(this._attachmentHasChanges || this.cropperComponent.imageChanged);
    }

    /**
     * function to save attachment to the database
     */
    saveAttachment(): void {
        console.log('Saving Attachment', this.foetusIndex, 'BLOCK DATA::', this.blockData, 'Selected Attachment::', this.attachment._id);
        this.savingAttachmentChange.emit(true);
        // add id if we are deling with new attachment
        const isNew = !this.attachment._id ? true : false;
        console.log('Attachment IsNew::', isNew);
        if (isNew) {
            const timestamp = new Date().getTime();
            this.attachment.id = timestamp;
            this.attachment._id = timestamp.toString();
            this.attachment.patientId = +this.patient._id;
            this.attachment.narrativeId = +this.narrativeId;
            this.attachment.attachmentTitle = this.patientView ? 'patient-upload_' + this.firstFilename : this.attachment.attachmentTitle;
            this.attachment.sharedFacility = this.patient.sharedFacility ? this.patient.sharedFacility : [];
            this.attachment.createFacility = this.patient.createFacility;

            // TODO: This needs to be rethinked!
            this.attachment.mediatype = this.files[0].type;
            // this.attachment.attachmentFileName = this.files[0].name;
            this.attachment.attachmentFileName = this.firstFilename;
        }

        // add attachments from file list
        this.attachment.dicomThumbnails = [];
        this.attachment._attachments = {};

        this.files.forEach((file, index) => {
            if (file.type === 'application/dicom') {
                console.log('Dicom thumbnails ?', 'Yes');
                this.attachment.dicomThumbnails[index] = this.sideThumbnails[index];
                this.attachment._attachments['file' + index] = { content_type: file.type, data: file };
            } else {
                console.log('Dicom thumbnails ?', 'No');
                this.attachment._attachments['file' + index] = { content_type: file.type, data: file };
            }
        });

        // save to pouch and notify parent component
        this.attachmentService.updateAttachment(this.attachment)
            .then(attachment => {
                this.snackBar.open('Attachment was updated.', 'Success', { duration: 6000 });

                if (this.patientView) {
                    this.resetImageArrays();
                    this.attachmentAdded.emit(attachment);
                    this.attachmentForm.controls['attachmentFile'].reset('');
                    this.attachmentForm.controls['attachmentTitle'].reset('');
                    this.attachmentForm.controls['dateOnAttachment'].reset('');
                    return;
                }

                // reload attachment files from pouch to prevent 'readAsArrayBuffer' on 'FileReader' errors
                this.attachmentService.getAttachment(attachment._id).then(reloadedAttachment => {
                    if (isNew) {
                        this.attachmentAdded.emit(reloadedAttachment);
                    } else {
                        this.attachmentUpdated.emit(reloadedAttachment);
                    }
                    this.deleteImagesFromWUSG();
                    this.attachmentForm.reset();
                }).catch((err) => {
                    this.snackBar.open('Unable to reload file attachment', 'Error', { duration: 6000 });
                    console.log(err);
                });
            })
            .catch(err => {
                console.log('Error uploading attachment:', err);
                this.snackBar.open('ERROR: Attachment was not updated', 'Error', { duration: 6000 })
            });
    }

    /**
     * Display cropper for selected image
     */
    editImage(imageIndex: number) {
        // for now disallow editing pdfs
        // if (this.files[imageIndex].type === 'application/pdf') {
        //     this.snackBar.open('Editing pdf attachments is currently not supported', 'Warning', { duration: 6000 });
        //     return;
        // }
        this.displayCropper = true;
        this.cropperComponent.cropperShow(this.thumbnails[imageIndex]);
    }

    /**
     * Cancel button has been pressed on cropper, restore image to original state
     */
    cropperCancelEditImage(imageIndex: number) {
        const url = URL.createObjectURL(this.files[imageIndex]);
        const imgSrc = this.sanitizer.bypassSecurityTrustUrl(url);
        this.thumbnails[imageIndex] = imgSrc;
        this.cropperComponent.destroyCropper();
        this.displayCropper = false;
        this.setAttachmentChanged(this._attachmentHasChanges || this.cropperComponent.imageChanged);
    }

    /**
     * Display the image viewer in fullscreen mode
     */
    toggleFullScreen() {
        this.fillDisplay = !this.fillDisplay;
    }

    /**
     * Toggle display of thumbnails bar
     */
    toggleThumbnails() {
        this.displayThumbnails = !this.displayThumbnails;
    }


    loadPdf(pdfSrc: any) {
        this.setPdfDoc(pdfSrc).then(pdfDoc => {
            console.log('pdf url', pdfSrc)
            this.pdfDoc = pdfDoc;
            this.pageNumber = 1;
            this.totalPages = this.pdfDoc.numPages;
            console.log('Total Pages', this.totalPages)
            for (let i = 1; i <= this.totalPages; i++) {
                this.generateView(i);
            }
        }).catch(e => {
            console.log(e);
            this.loadingPage = false;
            this.snackBar.open('Unable to load pdf document', 'Error', { duration: 6000 });
        });
    }
    /**
     * load pdf and return pdf document object
    */
    setPdfDoc(pdfSrc) {
        return new Promise((resolve, reject) => {
            if (!pdfSrc) { reject('PDF url is null, Please specify pdf url'); }

            PDFJS.getDocument(pdfSrc).promise.then((pdfDoc) => {
                resolve(pdfDoc);
            }, (error) => {
                reject(error);
            });

        });

    }

    /**
     * Render page on the canvas.
     * @param pageNumber
     */
    generateView(pageNumber) {
        this.pdfDoc.getPage(pageNumber).then((page) => { // split the pdf into individual pages
            const viewport = page.getViewport({ scale: 1 });
            const context = this.pdfCanvas.getContext('2d');
            this.pdfCanvas.height = viewport.height;
            this.pdfCanvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };

            this.pageRendered = page.render(renderContext) // render page onto canvas
            this.pageRendered.promise.then(convertedPage => {
                console.log('Converted Page Number', pageNumber)
                this.addPage(this.pdfCanvas)
                if (pageNumber < this.totalPages) {
                    pageNumber++
                    this.generateView(pageNumber);
                }
                this.loadingPage = false;
            }).catch(error => {
                this.loadingPage = false;
                console.log('Problem encountered while converting image', error)
            })
        });
    }

    /**
     * convert individual pdf page to image.
    */
    convertPage(pdfCanvas) {
        return new Promise((resolve, reject) => {
            if (!pdfCanvas) { reject('Canvas not rendered with page'); }
            this.pdfCanvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 0.95)
        });
    }

    addPage(pdfCanvas: any) {
        this.convertPage(pdfCanvas).then(blob => {
            this.blob = blob;
            this.files.push(this.blob)
            const srcUrl = URL.createObjectURL(this.blob)
            this.srcPdf = this.sanitizer.bypassSecurityTrustUrl(srcUrl);
            this.thumbnails.push(this.srcPdf)
            // display the page we just added
            this.currentIndex = 0;
            this.setAttachmentChanged(true);
        }).catch(e => {
            console.log(e);
            this.snackBar.open('Unable to load pdf document', 'Error', { duration: 6000 });
        });
    }

    ngOnDestroy(): void {
        this.unsavedChangesGuard.deregisterComponent(this);
    }

    /**
     * function that sets the check for when there are attachment changes, or not
     * @param changed
     */
    setAttachmentChanged(changed: boolean) {
        console.log('Attachment changes ?', changed);
        this._attachmentHasChanges = changed;
        if (!changed) {
            console.log('No changes made', changed);
            this.attachmentHasChanges.emit(changed);
        } else {
            console.log('Image attachment has changes', changed);
            this.attachmentHasChanges.emit(changed);
        }
    }

    /**
     * Toggle on and off the dicom measuretool
     */
    toggleMeasureTool() {
        this.measureToolEnabled = !this.measureToolEnabled;
        this.dicomViewerComponent.activateLengthTool(this.measureToolEnabled);
    }

    /**
     * Toggle on and off the dicom ellipticaltool
     */
    toggleEllipticalTool() {
        this.ellipticalToolEnabled = !this.ellipticalToolEnabled;
        this.dicomViewerComponent.activateEllipticalTool(this.ellipticalToolEnabled);
    }
}
