import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { FacilityService } from '../services/facility.service'
import { Facility } from '../models/facility';
import { Patient } from '../models/patient';
import { AppSettings } from '../models/config';
import { Mediator } from '../models/mediators';
import { PatientService } from '../services/patient.service';
import { Attachment, PatientRequest } from '../models/attachment';
import { ShareLinks } from '../models/sharelinks';
import { AttachmentService } from '../services/attachment.service';

import { Narrative } from '../models/narrative';
import { NarrativeEnumTypes, NarrativeInterface } from '../models/narrative-types';
import { NarrativeService } from '../services/narrative.service';
import { Globals } from '../services/globals';
import { ComponentCanDeactivate } from '../services/unsaved-changes.guard';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RepositoryObserver } from '../services/repository-observer';
import { RepositoryService } from '../services/repository.service';
import { AppSettingsService } from '../services/settings.service';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: 'narrative.component.html'
})

export class NarrativeComponent implements OnInit, OnDestroy, ComponentCanDeactivate, RepositoryObserver {
  public patientId: number;
  public sharedLinkUrl: string;
  public sharedLinkPatientId: string;
  public patient: Patient;
  narratives: NarrativeInterface[];
  narrative: NarrativeInterface;
  attachments: Attachment[];
  sharelinks: ShareLinks[];
  facilities: Facility[];
  viewMode: string = 'none'; // view, edit, or add
  loadingAttachments = false;
  loadingNarrative = false;
  loadingPatientData = false;
  loadingSharelinks = false;
  loadingFacilities = false;
  formSaved: boolean = false;
  form: FormGroup;

  narrativeType: string;
  narrativeEnumTypes = NarrativeEnumTypes;
  patientRequests: PatientRequest[] = []; // keep track of new patient requests

  selectedTab: number = 1;

  env = environment;
  loadingAppSettings: boolean = false;
  appSettings: AppSettings;

  specialistList: Mediator[];

  // this is used to show the sharelinks report view
  @Input() shareLinksModel: ShareLinks;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (!this.formSaved && this.form.dirty) { return false };
    return true;
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private narrativeService: NarrativeService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private globals: Globals,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private facilityService: FacilityService,
    private repository: RepositoryService,
    private appSettingsService: AppSettingsService
  ) {
    this.form = this.fb.group({});

    // register for change updates
    this.repository.registerObserver(this);

    // set current global patient id
    this.globals.showShareLinkPatientId.emit(+(this.route.snapshot.params['patientid']));
  }

  ngOnDestroy(): void {
    // set the view mode none
    this.viewMode = 'none';
  }

  // handle notification from repository that there has been a change
  notify(objectType: string): void {
    if (objectType === Narrative.type && this.viewMode === 'view') {
      console.log('A narrative has been updated and we are in view mode, check for updates');
      this.reloadNarrative();
    }
    if (objectType === AppSettings.type) {
      this.getAppSettings();
    }
  }

  editNewNarrative(newNarrative: any) {
    this.narrative = newNarrative;
    this.editNarrative();
    this.router.navigate(['/patient/' + this.patient._id + '/narrative/' + newNarrative._id + '/edit/' + newNarrative.narrativeType]);
  }

  viewNarrative(narrative) {
    this.narrative = narrative;
    this.viewMode = 'view';
  }

  viewPatientRequests(patientRequests: PatientRequest[]) {
    this.patientRequests = patientRequests;
  }

  editNarrative() {
    this.viewMode = 'edit';
  }

  ngOnInit(): void {
    this.initNarrative();
    this.getAppSettings();
    this.getSpecialists();
  }

  /**
   * function to load the correct ui for a selected narrative type
   */
  initNarrative() {
    this.narrativeType = this.route.snapshot.params['type'];
    const narrativeAction = this.route.snapshot.params['action'];
    this.patientId = this.route.snapshot.params['patientid'];
    const narrativeId = this.route.snapshot.params['narrativeid'];

    // load correct narrative from route
    if (narrativeId) {
      console.log('Load narrative id', narrativeId);
      this.getNarrative(narrativeId);

      // load attachments for this patient
      if (this.narrativeType !== NarrativeEnumTypes.obstetric) {
        console.log('Get attachments for ' + narrativeId);
        this.getNarrativeAttachments(+narrativeId);
      }
    } else {
      this.attachments = [];
    }

    // load patient from route
    if (this.patientId) {
      this.getPatientData(this.patientId);

      // load shareLinks for this patient
      this.getPatientSharelinks(this.patientId);

      // get patients narratives
      this.getPatientNarratives(this.patientId.toString());
    }

    // load facilities for displaying messages
    console.log('Load facilities for displaying messages');
    this.getFacilities();

    // check action based on route
    if (narrativeAction === 'view') {
      this.viewMode = 'view';
    } else if (narrativeAction === 'add') {
      this.viewMode = 'add';
      if (this.narrativeType !== NarrativeEnumTypes.obstetric) {
        this.narrative = new Narrative();
      }
    } else if (narrativeAction === 'edit') {
      this.viewMode = 'edit';
    }

  }

  /** Function get app settings */
  getAppSettings() {
    this.loadingAppSettings = true;
    this.appSettingsService.loadAppSettings(this.env.settingsId)
      .then(loadedAppSettings => {
        this.appSettings = loadedAppSettings;
        this.loadingAppSettings = false;
        this.ref.detectChanges();
      }).catch(error => {
        this.loadingAppSettings = true;
        console.log('Error loading app settings', error);
        // this.snackBar.open('Error loading app settings', 'Error', { duration: 6000 });
      });
  }

  /** A narrative has changed, reload narrative */
  reloadNarrative() {
    this.loadingNarrative = true;
    this.narrativeService.getSingleNarrative(+this.narrative._id, false)
      .then((narrative: NarrativeInterface) => {
        console.log('Reloaded narrative for this patient');
        this.narrative = narrative;
        this.loadingNarrative = false;
        this.ref.detectChanges();
      })
      .catch(err => {
        console.log('Error reloading narrative:', err);
        this.snackBar.open('Error reloading narrative', 'ERROR', { duration: 1000 });
      });
  }

  /**
   * function to get a single narrative
   * @param narrativeId narrative id
   */
  getNarrative(narrativeId) {
    this.loadingNarrative = true;
    this.narrativeService.getSingleNarrative(+narrativeId, false)
      .then((narrative: NarrativeInterface) => {
        console.log('Loaded narrative for this patient');
        this.narrative = narrative;
        this.loadingNarrative = false;
      })
      .catch(err => {
        console.log('Error getting narrative:', err);
        this.snackBar.open('Error Fetching narrative', 'ERROR', { duration: 1000 });
      });
  }

  /** Load facilities list for displaying facility names */
  getFacilities(): void {
    this.loadingFacilities = true;
    this.facilityService.getFacilities()
      .then(facilities => {
        this.facilities = facilities;
        this.loadingFacilities = false;
      })
      .catch(err => {
        console.log(err)
        const snackBarRef = this.snackBar.open('Unable to load the list of facilities', 'Retry', { duration: 5000 });
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getFacilities()
        })
      })
  }

  /** get patient's data */
  getPatientData(patientId: number): void {
    this.loadingPatientData = true;
    this.patientService.getSinglePatient(patientId)
      .then(patient => {
        if (!patient) {
          this.snackBar.open('Error loading patient (Patient id: ' + this.patientId + ')', 'Error');
          this.router.navigate(['/patients']);
        }
        this.patient = patient;
        this.loadingPatientData = false;
      }).catch(error => {
        console.log('Error loading patient data', error);
        const snackBarRef = this.snackBar.open('Error loading patient data', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getPatientData(this.patientId)
        });
      });

  }

  /** get all attachments for this patient */
  getNarrativeAttachments(narrativeId: number): void {
    this.loadingAttachments = true;
    this.attachmentService.getNarrativeAttachments(narrativeId).then(
      attachments => {
        this.attachments = attachments;
        this.loadingAttachments = false;
      }).catch(error => {
        console.log('Error loading patient attachments: ', error);
        const snackBarRef = this.snackBar.open('Error loading patient attachments', 'Retry');
        snackBarRef.onAction().subscribe(() => {
          console.log('Retrying');
          this.getNarrativeAttachments(narrativeId)
        });
      });
  }

  /** get all shareLinks for this patient */
  getPatientSharelinks(patientId: number): void {
    this.loadingSharelinks = true;
    this.patientService.getPatientShareLinks(patientId).then(sharelinks => {
      this.sharelinks = sharelinks;
      this.loadingSharelinks = false;
    }).catch(error => {
      console.log('Error loading patient sharelinks: ', error);
      const snackBarRef = this.snackBar.open('Error loading patient sharelinks', 'Retry');
      snackBarRef.onAction().subscribe(() => {
        console.log('Retrying load sharelinks');
        this.getPatientSharelinks(this.patientId)
      });
    });
  }

  /** get patient narratives */
  getPatientNarratives(patientId) {
    this.narrativeService.getPatientNarratives(patientId).then(narratives => {
      this.narratives = narratives;
    }).catch(error => this.snackBar.open('Error Loading patients narratives', 'Error', { duration: 6000 }));
  }

  /** function fetch all facility specialists from db */
  getSpecialists() {
    this.patientService.getSpecialists()
      .then(response => {
        this.specialistList = response;
      })
      .catch(error => {
        console.log(error);
        this.snackBar.open('Error loading list of specialists', 'Error', { duration: 6000 });
      });
  }
}
